import React, { Component } from "react";
import "components/tabsApps/style/style.css";
import onClickOutside from "react-onclickoutside";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";

import "components/profile/style/style.css";
import { setIdpInitiatedLogin } from "reducers/authReducer";
import { UserIcon } from "lib/icons";
import { setUserHomepage } from "reducers/appReducer";

class Profile extends Component {
  constructor(props) {
    super(props);

    this.state = {
      listOpen: false,
      items: [
        { name: "Make Current Page My Homepage", action: "makeCurrent" },
        { name: "Clear Personal Homepage", action: "clearHomePage" },
        { name: "Manage Subscriptions", action: "manageSubscriptions" },
        { name: "Logout", action: "logout" }
      ]
    };
    //this.login = this.login().bind(this);
    //this.logout = this.logout().bind(this);
    this.clickHandler = this.clickHandler.bind(this);
    this.checkForIframeClick = this.checkForIframeClick.bind(this);
    window.addEventListener("blur", this.checkForIframeClick, false);
  }

  componentWillUnmount() {
    window.removeEventListener("blur", this.checkForIframeClick, false);
  }
  checkForIframeClick = event => {
    if (document.activeElement.tagName === "IFRAME") {
      this.setState({
        listOpen: false
      });
    }
  };
  clickHandler(link) {
    switch (link) {
      case "logout":
        this.logout();
        break;
      case "makeCurrent":
        let homePage = { link: this.props.history.location.pathname };
        this.props.setUserHomepage(homePage);
        break;
      case "clearHomePage":
        this.props.setUserHomepage(null);
        break;
      case "manageSubscriptions":
        window.open("/reportSubscriptions", "_blank");
        break;
      default:
    }
    //this.props.history.push(link);
    this.toggleList();
  }
  login() {
    this.props.auth.login();
    //this.props.dispath(loginUser());
  }

  logout() {
    this.props.setIdpInitiatedLogin(false);
    this.props.auth.logout();
  }

  handleClickOutside() {
    this.setState({
      listOpen: false
    });
  }
  toggleList() {
    this.setState(prevState => ({
      listOpen: !prevState.listOpen
    }));
  }
  checker() {}
  renderDDItem(item, index) {
    // console.log(this.props);

    if (this.props.apps !== undefined && this.props.apps !== null && this.props.apps.length > 0) {
      if (item.action === "manageSubscriptions") {
        let containsRptSubscriptions = this.props.apps.filter(item => {
          return item.type === "reportSubscriptions";
        });
        if (containsRptSubscriptions.length > 0) {
          return (
            <li className="profile-dd-item" key={index} onClick={() => this.clickHandler(item.action)}>
              {item.name}
            </li>
          );
        } else {
          return null;
        }
      }
    }
    if (item.action === "makeCurrent") {
      let curUrl = this.props.location.pathname;
      var chkArray = ["files", "accubase", "acculift", "promoOpt", "mdm", "report", "tableau"];

      var allowHomePage = false;
      for (var cItem of chkArray) {
        if (curUrl.includes(cItem)) {
          allowHomePage = true;
          break;
        }
      }
      if (allowHomePage) {
        return (
          <li className="profile-dd-item" key={index} onClick={() => this.clickHandler(item.action)}>
            {item.name}
          </li>
        );
      } else {
        return (
          <li className="profile-dd-item disabled-text" style={{ color: "rgb(215, 217, 220)", cursor: "no-drop" }} key={index}>
            {item.name}
          </li>
        );
      }
    } else {
      return (
        <li className="profile-dd-item" key={index} onClick={() => this.clickHandler(item.action)}>
          {item.name}
        </li>
      );
    }
  }

  render() {
    const { listOpen, items } = this.state;
    const user = localStorage.getItem("user");
    return (
      <div className={this.props.className + " flex-container"}>
        {listOpen ? (
          <div className="ta-profile-parent profile-parent-selected" onClick={() => this.toggleList()}>
            <UserIcon color={"white"} height={"20"} className="profile-selected" />
          </div>
        ) : (
          <div className="ta-profile-parent" onClick={() => this.toggleList()}>
            <UserIcon color={"rgba(88, 89, 91, 1)"} height={"20"} />
          </div>
        )}
        {listOpen && (
          <ul className="profile-dd-items">
            {user !== "" && (
              <div className="profile-user-name">
                <li style={{ marginRight: "auto", marginLeft: "auto" }}>{user}</li>
              </div>
            )}
            {items.map((item, index) => this.renderDDItem(item, index))}
          </ul>
        )}
      </div>
    );
  }
}

const mapStateToProps = state => ({
  setAllowHomePage: state.app.setAllowHomePage,
  apps: state.app.apps
});
const mapDispatchToProps = dispatch => ({
  setIdpInitiatedLogin(value) {
    dispatch(setIdpInitiatedLogin(value));
  },
  setUserHomepage(obj) {
    dispatch(setUserHomepage(obj));
  }
});

Profile = onClickOutside(Profile);
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Profile));
