import React, { Component } from "react";

import ProjectSelector from "components/accuComponents/projectSelector";
import FilterPanel from "components/accuComponents/filterPanel";

import { addTabToNav } from "components/topLevelNav/reducers/topLevelNavReducer";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { LoadingIndicator } from "lib/loadingIndicator";
import "./style.css";
import moment from "moment";
import AcculiftList from "./acculiftList";
import ScatterPlotChart from "components/acculift/components/scatterPlotChart";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import axios from "axios";
import ReactDOM from "react-dom";
import { socket } from "routes";

import {
  Gear,
  ProjectIcon,
  LiftModelIcon,
  LatestModel,
  DeleteIcon,
  Pen,
  AliasIcon,
  BaselineIcon,
  RightChevron
} from "lib/icons";

import {
  setReinitChart,
  setFilteredData,
  getProjects,
  setSelectedProject,
  setViewSelected,
  setOldProjectName,
  setTacticAlias,
  setAlias,
  rerunLiftExport,
  selectLift,
  setSearchProjects
} from "components/acculift/reducers/acculiftReducer";
import { DotLoader } from "react-spinners";
import { toast } from "react-toastify";

//Use this to give moment the correct format so it doesn't throw warnings
//Our timestamps look like this: 2020-04-06 14:31:03.000 -0700
const timestampFormat = "YYYY-MM-DD HH:mm:ss.SSS ZZ";

class Acculift extends Component {
  constructor(props) {
    super(props);

    this.state = {
      allProjectsOpened: true,
      filterPanelOpened: true,
      viewSelected: 1,
      manageOpened: false,
      showDeleteProjectConfirmModal: false,
      showDeleteBaselineConfirmModal: false,
      showDeleteLiftConfirmModal: false,
      showTacticModal: false,
      editBaseline: false,
      selectedProject: null,
      savingTacticAliases: false,
      accessPermissions: true
    };
    this.props.addTabToNav({
      title: "Acculift",
      id: "acculift",
      type: "acculift",
      path: "/acculift",
      icon: "acculift",
      activeChildren: []
    });
    this.clickHandler = this.clickHandler.bind(this);
    this.manageClickHandler = this.manageClickHandler.bind(this);
    this.allProjectStateChange = this.allProjectStateChange.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.filterPanelStateChange = this.filterPanelStateChange.bind(this);
    this.keyDownHandler = this.keyDownHandler.bind(this);
    this.BaselineNameInput = React.createRef();
    this.Manage = React.createRef();
    this.TPROnly = React.createRef();
    this.Minor = React.createRef();
    this.Major = React.createRef();
    this.Other = React.createRef();
    this.handleClickOutside = this.handleClickOutside.bind(this);
    this.saveAliases = this.saveAliases.bind(this);
    this.liftCompleteHandler = this.liftCompleteHandler.bind(this);
    this.deleteProject = this.deleteProject.bind(this);
  }

  componentWillUnmount() {
    //this.props.setSelectedProject({});

    document.removeEventListener("mousedown", this.handleClickOutside);

    socket.off("LiftComplete", this.liftCompleteHandler);
  }
  handleClickOutside(event) {
    if (event.target.className.animVal === null || event.target.className.animVal === undefined) {
      const area = ReactDOM.findDOMNode(this.Manage.current);
      if (area !== null) {
        if (!area.parentElement.contains(event.target)) {
          this.setState({ manageOpened: false });
        }
      }
    }
  }

  componentDidUpdate() {
    if (
      !this.props.loadedProjects &&
      !this.props.loadingProjects &&
      !this.props.loadingError &&
      this.props.apps.length > 0 &&
      this.state.accessPermissions === true
    ) {
      let accessAllowed = this.props.apps.filter(item => {
        return item.type === "acculift";
      });

      if (accessAllowed.length > 0) {
        this.props.getProjects();
      } else {
        this.setState({ accessPermissions: false });
      }
    }
    if (this.props.loadedProjects && !this.state.loaded) {
      this.setState({ uploadFolders: this.props.projects, loaded: true });
    }
    if (this.props.reInitProjects) {
      this.props.setReInitProjects(false);
      this.setState({ uploadFolders: this.props.projects });
    }
  }

  componentDidMount() {
    socket.on("LiftComplete", this.liftCompleteHandler);

    let accessAllowed = this.props.apps.filter(item => {
      return item.type === "acculift";
    });
    if (accessAllowed.length > 0) {
      this.props.getProjects();
      document.addEventListener("mousedown", this.handleClickOutside);
    }
  }
  liftCompleteHandler(data) {
    this.setState({ loaded: false });
    this.props.getProjects();
  }
  allProjectStateChange() {
    const { allProjectsOpened } = this.state;
    this.setState({ allProjectsOpened: !allProjectsOpened });
  }
  filterPanelStateChange() {
    const { filterPanelOpened } = this.state;
    this.setState({ filterPanelOpened: !filterPanelOpened });
  }
  clickHandler(view) {
    this.props.setViewSelected(view);

    if (this.props.liftId !== -1) {
      if (this.props.projects[0].baselines.length > 0) {
        if (this.props.projects[0].baselines !== undefined && this.props.projects[0].baselines[0].lifts.length > 0) {
          let completeLifts = this.props.projects[0].baselines[0].lifts.filter(item => {
            return item.status === "complete";
          });
          if (completeLifts.length > 0) {
            this.props.selectLift(completeLifts[0]);
          }
          //this.props.selectLift(this.props.projects[0].baselines[0].lifts[0], 1);
        }
      }
    }
  }
  manageClickHandler() {
    this.setState({ manageOpened: !this.state.manageOpened });
  }
  handleClose() {
    this.setState({
      showDeleteLiftConfirmModal: false,
      showDeleteBaselineConfirmModal: false,
      showDeleteProjectConfirmModal: false,
      showTacticModal: false
    });
  }
  keyDownHandler(event) {
    if (event.key === "Enter") {
      // this.props.updateGroupName(
      //   this.props.selectedClient.value,
      //   this.props.group.id,
      //   this.textInput.current.value
      // );
      this.props.selectedProject.PROJECT_NAME = this.BaselineNameInput.current.value;
      this.props.setOldProjectName(this.props.selectedProject, this.BaselineNameInput.current.value);
      this.setState({
        editBaseline: false
      });
    } else if (event.key === "Escape") {
      this.props.selectedProject.PROJECT_NAME = this.props.oldProjectName; // this.setState({ groupName: this.props.group.name });
      this.setState({ editBaseline: false });
    }
  }
  deleteProject() {
    axios
      .get(`${process.env.REACT_APP_BIGHORN_SERVER}/api/accubase/deleteProject/${this.state.selectedProject}`, {
        headers: {
          ClientToken: localStorage.getItem("clientToken")
        }
      })
      .then(res => {
        this.props.getProjects();
        this.handleClose();
        this.setState({ deletingProject: false });

        toast.success("Project Deleted");
      });
  }
  renderInfoHeader() {
    const { selectedProject } = this.props;
    let totalBaselines = 0;
    let lastLift = null;
    let totalLiftModels = 0;
    if (selectedProject.baselines === undefined) {
      return <div></div>;
    }
    selectedProject.baselines.forEach(baseline => {
      if (baseline.lifts !== undefined) {
        baseline.lifts.forEach(lift => {
          if (lastLift === null) {
            lastLift = lift;
          } else if (moment(lastLift.lastModified, "MM/DD/YY").isBefore(moment(lift.lastModified, "MM/DD/YY"))) {
            lastLift = lift;
          }
        });

        totalLiftModels = totalLiftModels + baseline.lifts.length;
      }
    });
    totalBaselines = selectedProject.baselines.length;
    if (lastLift === null) {
      lastLift = {};
    }
    return (
      <div style={{}} className="project-heading">
        {this.state.editBaseline ? (
          <input
            style={{ width: "50%" }}
            defaultValue={selectedProject.PROJECT_NAME}
            ref={this.BaselineNameInput}
            onChange={evt => {
              if (evt.keyCode === 13) {
                this.setState({ editGroupName: false });
              }
            }}
            onKeyDown={event => {
              selectedProject.PROJECT_NAME = this.BaselineNameInput;
              this.keyDownHandler(event);
            }}
          />
        ) : (
          <div style={{ width: "50%" }}>
            <div style={{}} className="kpi-title">
              Project Name
            </div>
            <div style={{ fontSize: "18px", fontWeight: "600" }}>{selectedProject.PROJECT_NAME}</div>
          </div>
        )}
        <div className="tabs-vr"></div>
        <div style={{}} className="minor-kpi-container">
          <div style={{}} className="kpi-title">
            Created Date
          </div>
          <div className="minor-kpi">{moment(selectedProject.PROJECT_DATE_CREATED, timestampFormat).format("MM/DD/YY")}</div>
        </div>
        <div className="tabs-vr"></div>
        <div style={{}} className="minor-kpi-container">
          <div className="kpi-title">Total Baselines</div>
          <div className="minor-kpi">{totalBaselines}</div>
        </div>
        <div className="tabs-vr"></div>
        <div style={{}} className="minor-kpi-container">
          <div className="kpi-title">Total Lift Models</div>
          <div className="minor-kpi">{totalLiftModels}</div>
        </div>
        <div className="tabs-vr"></div>
        <div style={{ marginLeft: "8px" }}>
          <div className="kpi-title">Latest Lift Model</div>
          <div className="minor-kpi">{lastLift.lastModified ? lastLift.lastModified : "No lift models"}</div>
        </div>
        <div
          style={
            {
              // marginLeft: "auto",
              // display: "flex",
              // flexDirection: "row",
              // alignItems: "center"
            }
          }
          className="manage-menu-link">
          <div
            onClick={() => {
              this.manageClickHandler();
            }}
            style={{ cursor: "pointer", display: "flex", flexDirection: "row" }}>
            <Gear className="selectable gear" height={"20px"} width={"20px"} color={"rgba(0, 126, 167, 1)"} />
            <div style={{ color: "rgba(0, 126, 167, 1)", marginLeft: "5px" }}>Manage</div>
          </div>
          {this.state.manageOpened && (
            <div
              ref={this.Manage}
              onBlur={() => {
                this.setState({ manageOpened: false });
              }}
              className="manage-drop-down"
              style={{
                borderRadius: "0",
                border: "1px solid rgb(229, 230, 232)"
              }}>
              <div
                onClick={() => {
                  this.setState({ editBaseline: true, manageOpened: false });
                }}
                className="manage-menu-item menu-item">
                <Pen
                  height={"18px"}
                  width={"18px"}
                  color={"rgba(112, 112, 112, 1)"}
                  style={{ marginRight: "14px", cursor: "pointer" }}
                />
                Rename
              </div>
              <div
                className="manage-menu-item menu-item"
                onClick={() => {
                  this.setState({ showTacticModal: true, manageOpened: false });
                }}>
                <AliasIcon
                  height={"16px"}
                  width={"16px"}
                  color={"rgba(112, 112, 112, 1)"}
                  style={{
                    cursor: "pointer",
                    marginRight: "10px"
                  }}
                />
                Tactic Aliases
              </div>

              <div
                className="manage-menu-item menu-item"
                style={{
                  borderBottom: "none"
                }}
                onClick={() => {
                  this.setState({
                    showDeleteProjectConfirmModal: true,
                    manageOpened: false
                  });
                }}>
                <DeleteIcon
                  height={"16px"}
                  width={"16px"}
                  color={"rgba(112, 112, 112, 1)"}
                  style={{
                    cursor: "pointer",
                    marginRight: "14px"
                  }}
                />
                Delete
              </div>
            </div>
          )}
        </div>
      </div>
    );
  }
  renderProject() {
    if (this.props.selectedProject !== null) {
      return (
        <div className="accu-project-div">
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              width: "100%",
              height: "100%"
            }}>
            {this.renderInfoHeader()}
            <hr style={{ alignContent: "center", width: "100%" }} />
            <AcculiftList lifts={this.props.lifts} project={this.props.selectedProject} />
          </div>
        </div>
      );
    } else {
      return (
        <div className="accu-project-div">
          <div>No projects found. Create at least one project and one baseline in Accubase before creating lift models.</div>
        </div>
      );
    }
  }
  deleteHandler(type) {
    if (type === "lift") {
      this.setState({ deletingLift: true });
      axios
        .get(`${process.env.REACT_APP_BIGHORN_SERVER}/api/acculift/deleteLift/${this.state.selectedLift}`, {
          headers: {
            ClientToken: localStorage.getItem("clientToken")
          }
        })
        .then(res => {
          this.props.getProjects();

          this.handleClose();
          this.setState({ deletingLift: false });
          toast.success(this.state.selectedLift + " Deleted");
        });
    } else if (type === "project") {
      this.setState({ deletingProject: true });

      axios
        .get(`${process.env.REACT_APP_BIGHORN_SERVER}/api/accubase/deleteProject/${this.props.selectedProject.ID}`, {
          headers: {
            ClientToken: localStorage.getItem("clientToken")
          }
        })
        .then(res => {
          this.props.getProjects();
          this.handleClose();
          this.setState({ deletingProject: false });

          toast.success("Project Deleted");
        });
    } else {
      this.setState({ deletingBaseline: true });
      axios
        .get(`${process.env.REACT_APP_BIGHORN_SERVER}/api/accubase/deleteBaseline/${this.state.clickedBaselineId}`, {
          headers: {
            ClientToken: localStorage.getItem("clientToken")
          }
        })
        .then(res => {
          this.props.getProjects();
          this.handleClose();
          this.setState({ deletingBaseline: false });

          toast.success(this.state.clickedBaselineName + " Deleted");
        });
    }
  }
  saveAliases() {
    this.setState({ savingTacticAliases: true });
    axios
      .post(
        `${process.env.REACT_APP_BIGHORN_SERVER}/api/acculift/setTacticAlias`,
        {
          tpr: this.TPROnly.current.value,
          major: this.Major.current.value,
          minor: this.Minor.current.value,
          other: this.Other.current.value,
          projectId: this.props.selectedProject.ID
        },
        {
          headers: {
            ClientToken: localStorage.getItem("clientToken")
          }
        }
      )
      .then(res => {
        // this.props.alias.tprAlias = this.TPROnly.current.value;
        // this.props.alias.majorAlias = this.Major.current.value;
        // this.props.alias.minorAlias = this.Minor.current.value;
        // this.props.alias.otherAlias = this.Other.current.value;
        this.setState({ showTacticModal: false, savingTacticAliases: false });
        this.props.setAlias(
          this.props.selectedProject.ID,
          this.TPROnly.current.value,
          this.Major.current.value,
          this.Minor.current.value,
          this.Other.current.value
        );
        this.props.rerunLiftExport(this.props.selectedProject.ID);
      });
  }

  renderHeader() {
    const { projects } = this.props;
    const {
      showDeleteProjectConfirmModal,
      showTacticModal,
      showDeleteLiftConfirmModal,
      showDeleteBaselineConfirmModal
    } = this.state;
    let totalProjects = projects.length;

    let totalBaselines = 0;
    let lastLift = null;
    let totalLiftModels = 0;
    projects.forEach(item => {
      item.baselines.forEach(baseline => {
        if (baseline.lifts !== undefined) {
          baseline.lifts.forEach(lift => {
            if (lastLift === null) {
              lastLift = lift;
            } else if (moment(lastLift.lastModified, "MM/DD/YY").isBefore(moment(lift.lastModified, "MM/DD/YY"))) {
              lastLift = lift;
            }
          });

          totalLiftModels = totalLiftModels + baseline.lifts.length;
        }
      });
      totalBaselines = totalBaselines + item.baselines.length;
    });
    if (lastLift === null) {
      lastLift = {};
    }

    return (
      <div
        style={{
          height: "100%",
          display: "flex",
          flexDirection: "row",
          alignItems: "center"
        }}>
        <Modal isOpen={showDeleteProjectConfirmModal}>
          <ModalHeader>Delete Project</ModalHeader>
          <ModalBody>
            <div>
              Are you sure you want to delete this project and all associated input data sources, baselines, and lift models?
              WARNING: This action cannot be undone.
            </div>
          </ModalBody>

          <ModalFooter>
            {!this.state.deletingProject ? (
              <Button
                color="primary"
                onClick={() => {
                  this.deleteHandler("project");
                }}>
                Delete
              </Button>
            ) : (
              <div
                className="accubase-uploading"
                style={{
                  marginLeft: "auto",

                  display: "flex",
                  flexDirection: "row"
                }}>
                <DotLoader sizeUnit={"px"} size={15} color={"black"} loading={true} />
                <div style={{ marginLeft: "5px" }}>Deleting ...</div>
              </div>
            )}
            <Button onClick={this.handleClose}>Cancel</Button>
          </ModalFooter>
        </Modal>
        <Modal isOpen={showDeleteLiftConfirmModal}>
          <ModalHeader>Delete Lift Model</ModalHeader>
          <ModalBody>
            <div>"Are you sure you want to delete this Lift Model?"</div>
          </ModalBody>

          <ModalFooter>
            {!this.state.deletingBatch ? (
              <Button
                color="primary"
                onClick={() => {
                  this.deleteHandler("lift");
                }}>
                Delete
              </Button>
            ) : (
              <div
                className="accubase-uploading"
                style={{
                  marginLeft: "auto",

                  display: "flex",
                  flexDirection: "row"
                }}>
                <DotLoader sizeUnit={"px"} size={15} color={"black"} loading={true} />
                <div style={{ marginLeft: "5px" }}>Deleting ...</div>
              </div>
            )}
            <Button onClick={this.handleClose}>Cancel</Button>
          </ModalFooter>
        </Modal>
        <Modal isOpen={showDeleteBaselineConfirmModal}>
          <ModalHeader>Delete Baseline</ModalHeader>
          <ModalBody>
            <div>Are you sure you want to delete the selected baseline and corresponding lift models?</div>
          </ModalBody>

          <ModalFooter>
            {!this.state.deletingBaseline ? (
              <Button
                color="primary"
                onClick={() => {
                  this.deleteHandler("baseline");
                }}>
                Delete
              </Button>
            ) : (
              <div
                className="accubase-uploading"
                style={{
                  marginLeft: "auto",

                  display: "flex",
                  flexDirection: "row"
                }}>
                <DotLoader sizeUnit={"px"} size={15} color={"black"} loading={true} />
                <div style={{ marginLeft: "5px" }}>Deleting ...</div>
              </div>
            )}
            <Button onClick={this.handleClose}>Cancel</Button>
          </ModalFooter>
        </Modal>
        <Modal isOpen={showTacticModal}>
          <ModalHeader>Primary Tactics Aliases</ModalHeader>
          <ModalBody>
            <table>
              <tbody>
                <tr>
                  <th>Default</th>
                  <th>Alias</th>
                </tr>
                <tr>
                  <td>TPR Only</td>
                  <td>
                    <input defaultValue={this.props.alias.tprAlias} ref={this.TPROnly}></input>
                  </td>
                </tr>
                <tr>
                  <td>Minor</td>
                  <td>
                    <input defaultValue={this.props.alias.minorAlias} ref={this.Minor}></input>
                  </td>
                </tr>
                <tr>
                  <td>Major</td>
                  <td>
                    <input defaultValue={this.props.alias.majorAlias} ref={this.Major}></input>
                  </td>
                </tr>
                <tr>
                  <td>Other</td>
                  <td>
                    <input defaultValue={this.props.alias.otherAlias} ref={this.Other}></input>
                  </td>
                </tr>
              </tbody>
            </table>
          </ModalBody>

          <ModalFooter>
            {!this.state.savingTacticAliases ? (
              <Button
                color="primary"
                onClick={() => {
                  this.saveAliases();
                  //this.deleteHandler("baseline");
                }}>
                Save
              </Button>
            ) : (
              <div
                className="accubase-uploading"
                style={{
                  marginLeft: "auto",

                  display: "flex",
                  flexDirection: "row"
                }}>
                <DotLoader sizeUnit={"px"} size={15} color={"black"} loading={true} />
                <div style={{ marginLeft: "5px" }}>Saving ...</div>
              </div>
            )}

            <Button onClick={this.handleClose}>Cancel</Button>
          </ModalFooter>
        </Modal>

        <div className="kpi-container">
          <div className="kpi-icon">
            <ProjectIcon height={"26px"} color={"rgba(0, 128, 171, 1)"} />
          </div>
          <div className="kpi-details">
            <div className="kpi-title">Total Projects</div>
            <div className="kpi-value">{totalProjects}</div>
          </div>
        </div>
        <div className="tabs-vr"></div>
        <div className="kpi-container">
          <div className="kpi-icon">
            <BaselineIcon height={"26px"} />
          </div>
          <div className="kpi-details">
            <div className="kpi-title">Total Baselines</div>
            <div className="kpi-value">{totalBaselines}</div>
          </div>
        </div>
        <div className="tabs-vr"></div>
        <div className="kpi-container">
          <div className="kpi-icon">
            <LiftModelIcon height="26px" />
          </div>
          <div className="kpi-details">
            <div className="kpi-title">Total Lift Models</div>
            <div className="kpi-value">{totalLiftModels}</div>
          </div>
        </div>
        <div className="tabs-vr"></div>
        <div className="kpi-container" style={{ maxWidth: "20%" }}>
          {" "}
          {/*Try to keep long lift model names from expanding this section too much*/}
          <div className="kpi-icon">
            <LatestModel color={"rgba(0, 128, 171, 1)"} height="26px" />
          </div>
          <div className="kpi-details">
            <div className="kpi-title">Latest Lift Model</div>
            <div className="kpi-value" style={{ maxWidth: "95%" }}>
              {lastLift.name}
            </div>
          </div>
        </div>
      </div>
    );
  }

  renderLift() {
    const { loadedProjects, projects, searchProjects, loadedData } = this.props;
    const { allProjectsOpened, filterPanelOpened, accessPermissions } = this.state;
    if (!accessPermissions) {
      return (
        <div className="login-wrapper">
          <div className="login-msg">You do not have any permissions for this application. Contact your administrator.</div>
        </div>
      );
    }
    if (!loadedProjects) {
      return <LoadingIndicator paddingTop={"227px"} />;
    }

    if (this.props.viewSelected === 2) {
      if (loadedData) {
        return (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              overflowY: "auto",
              height: "100%"
            }}>
            {/* // <div style={{ height: "100%" }}> */}
            <div className="acculift-main">
              <div className={"all-projects " + (allProjectsOpened ? "open" : "")}>
                <ProjectSelector
                  opened={allProjectsOpened}
                  statusChangedHandler={this.allProjectStateChange}
                  setSearchProjects={this.props.setSearchProjects}
                  searchProjects={searchProjects}
                  projects={projects}
                  secondary={true}
                  type={"lift"}
                  setSelectedProject={this.props.setSelectedProject}
                  getProjects={this.props.getProjects}
                />
              </div>
              <div
                style={{
                  height: "100%",
                  width: "100%",
                  display: "flex",
                  flexDirection: "column"
                }}>
                <div className="manage-projects-is">Review Lift Models</div>
                <div
                  className={
                    "manage-projects-body " + (allProjectsOpened ? "open " : "") + (filterPanelOpened ? "" : "filter-closed")
                  }>
                  <div
                    className={"review-lift-wrapper " + (allProjectsOpened ? "open " : "") + (filterPanelOpened ? "fopen" : "")}

                    //style={{ overflowY: "visible" }}
                  >
                    <div className="lift-model-name" style={{ marginTop: "8px", marginBottom: "8px" }}>
                      {this.props.selectedLift.name}
                    </div>
                    <div className="tabs-hr" style={{ marginBottom: "8px" }}></div>

                    <ScatterPlotChart chartData={this.props.filteredData} id={"scatterPlot"} ready={this.state.agGridApi} />
                  </div>
                </div>
              </div>
              <div className={"filter-panel " + (filterPanelOpened ? "open" : "")}>
                <FilterPanel
                  opened={filterPanelOpened}
                  statusChangedHandler={this.filterPanelStateChange}
                  filters={this.props.lovs}
                />
              </div>
            </div>
          </div>
        );
      } else if (!this.props.loadingData) {
        return (
          <div className="acculift-main">
            <div className={"all-projects " + (allProjectsOpened ? "open" : "")}>
              <ProjectSelector
                opened={allProjectsOpened}
                statusChangedHandler={this.allProjectStateChange}
                setSearchProjects={this.props.setSearchProjects}
                searchProjects={searchProjects}
                projects={projects}
                type={"lift"}
                secondary={true}
                setSelectedProject={this.props.setSelectedProject}
                getProjects={this.props.getProjects}
              />
            </div>
            {/* <div style={{ height: "100%", width: "100%" }}> */}
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                width: "100%"
              }}>
              <div
                style={{
                  width: "100%"
                  //paddingLeft: "20px",
                  // borderBottom: "1px solid rgb(229, 230, 232)"
                }}
                className="manage-projects-is">
                Review Lift Models
              </div>
              <div className={"manage-projects-body " + (allProjectsOpened ? "open" : "")}>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "center",
                    paddingTop: this.state.paddingTop
                  }}>
                  <div
                    style={{
                      marginLeft: "10px",
                      marginTop: "40px",
                      fontSize: "20px",
                      textAlign: "center"
                      // color: "rgba(0, 126, 167, 1)"
                    }}>
                    No lift models found in the selected project<br></br>
                    Please choose a different project
                  </div>
                </div>
              </div>
            </div>
          </div>
        );
      } else {
        return (
          <div className="acculift-main">
            <div className={"all-projects " + (allProjectsOpened ? "open" : "")}>
              <ProjectSelector
                opened={allProjectsOpened}
                statusChangedHandler={this.allProjectStateChange}
                setSearchProjects={this.props.setSearchProjects}
                searchProjects={searchProjects}
                projects={projects}
                secondary={true}
                type={"lift"}
                setSelectedProject={this.props.setSelectedProject}
                getProjects={this.props.getProjects}
              />
            </div>
            {/* <div style={{ height: "100%", width: "100%" }}> */}
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                width: "100%"
              }}>
              <div
                style={{
                  width: "100%"
                }}
                className="manage-projects-is">
                Review Lift Models
              </div>
              <div className={"manage-projects-body " + (allProjectsOpened ? "open" : "")}>
                <LoadingIndicator paddingTop={"227px"} />
              </div>
            </div>
          </div>
        );
      }
    } else {
      return (
        <div className="acculift-main">
          <div className={"all-projects " + (allProjectsOpened ? "open" : "")}>
            <ProjectSelector
              opened={allProjectsOpened}
              statusChangedHandler={this.allProjectStateChange}
              searchProjects={searchProjects}
              setSearchProjects={this.props.setSearchProjects}
              projects={projects}
              secondary={false}
              type={"lift"}
              setSelectedProject={this.props.setSelectedProject}
              getProjects={this.props.getProjects}
            />
          </div>
          <div
            style={{
              flexDirection: "column",
              display: "flex",
              // overflowY: "auto",
              width: "100%",
              height: "100%"
            }}>
            <div className="manage-projects-is">Manage Projects and Lift Models</div>
            <div className={"manage-projects-body " + (allProjectsOpened ? "open" : "")}>
              <div
                style={{
                  height: "75px",
                  border: "1px solid rgb(217,219,221)"
                }}>
                {this.renderHeader()}
              </div>
              <div
                style={{
                  border: "1px solid rgb(217,219,221)",
                  background: "rgb(249,249,249)",
                  width: "100%",
                  height: "100%",
                  display: "flex",
                  flexDirection: "column"
                }}>
                {this.renderProject()}
              </div>
            </div>
          </div>
        </div>
      );
    }
  }
  render() {
    return (
      <div className="acculift">
        <div className="ta-second-level-nav-container" style={{ paddingLeft: "10px" }}>
          <ul className="ta-second-level-nav flex-container">
            <li className="secondary-app-title">
              <div>Acculift</div>
            </li>
            <li className="secondary-nav-chevron">
              <RightChevron height={"20px"} color={"#666"} />
            </li>
            <li>
              <div
                onClick={() => this.clickHandler(1)}
                className={"ta-acculift-nav-link " + (this.props.viewSelected === 1 ? "ta-active" : "")}>
                Manage Projects and Lift Models
              </div>
            </li>
            <li style={{ marginLeft: "30px" }}>
              <div
                onClick={() => {
                  this.clickHandler(2);
                }}
                className={"ta-acculift-nav-link " + (this.props.viewSelected === 2 ? "ta-active" : "")}>
                Review Lift Models
              </div>
            </li>
          </ul>
          <div
            style={{
              borderBottom: "1px solid lightgray",
              marginRight: "10px",
              marginLeft: "-10px"
            }}></div>
        </div>
        {this.renderLift()}
      </div>
    );
  }
}

const mapStateToProps = state => ({
  loadingProjects: state.acculift.loadingProjects,
  loadedProjects: state.acculift.loadedProjects,
  loadingError: state.acculift.loadingError,
  loadingData: state.acculift.loadingData,
  changed: state.acculift.changed,
  loadedData: state.acculift.loadedData,
  projects: state.acculift.projects,
  searchProjects: state.acculift.searchProjects,
  selectedProject: state.acculift.selectedProject,
  error: state.acculift.error,
  data: state.acculift.data,
  filteredData: state.acculift.filteredData,
  lifts: state.acculift.lifts,
  viewSelected: state.acculift.viewSelected,
  liftId: state.acculift.liftId,
  selectedLift: state.acculift.selectedLift,
  lovs: state.acculift.lovs,
  oldProjectName: state.acculift.oldProjectName,
  alias: state.acculift.alias,
  apps: state.app.apps
});
const mapDispatchToProps = dispatch => ({
  getProjects() {
    dispatch(getProjects());
  },
  addTabToNav(tab) {
    dispatch(addTabToNav(tab));
  },
  setReinitChart(state) {
    dispatch(setReinitChart(state));
  },
  setFilteredData(data) {
    dispatch(setFilteredData(data));
  },
  setSelectedProject(project) {
    dispatch(setSelectedProject(project));
  },
  setViewSelected(view) {
    dispatch(setViewSelected(view));
  },
  setOldProjectName(name) {
    dispatch(setOldProjectName(name));
  },
  setTacticAlias(id, tpr, minor, major, other) {
    dispatch(setTacticAlias(id, tpr, minor, major, other));
  },
  setAlias(id, tpr, minor, major, other) {
    dispatch(setAlias(id, tpr, minor, major, other));
  },
  rerunLiftExport(projectId) {
    dispatch(rerunLiftExport(projectId));
  },
  selectLift(lift, viewSelected) {
    dispatch(selectLift(lift, viewSelected));
  },
  setSearchProjects(filter, projects, includeSecondary) {
    dispatch(setSearchProjects(filter, projects, includeSecondary));
  }
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Acculift));
