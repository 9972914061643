import React, { Component } from "react";
import "components/accucast/components/style/style.css";
import "bootstrap/dist/css/bootstrap.css";
import SecondLevelPlanNav from "components/accucast/components/updatePlan/secondLevelPlanNav";
import PlanAccucastRouter from "components/accucast/components/updatePlan/planRoutes";

export class UpdatePlan extends Component {
  render() {
    return (
      <div style={{ height: "100%", width: "100%" }}>
        <SecondLevelPlanNav {...this.props} />
        <PlanAccucastRouter {...this.props} />
      </div>
    );
  }
}
