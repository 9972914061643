import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { Switch, Route } from "react-router-dom";
import ReportSubscriptions from "./reportSubscriptions";

class RptSubRouter extends Component {
  render() {
    return (
      <div
        className="rpt-sub-router-div"
        style={{
          width: "100%",
          height: "100%",
          paddingBottom: "10px",
          minHeight: "0px"
        }}>
        <Switch>
          <Route path={"/reportSubscriptions"} render={props => <ReportSubscriptions {...props} />} />
        </Switch>
      </div>
    );
  }
}

export default withRouter(RptSubRouter);
