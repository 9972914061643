import Callback from "./components/callback";
import React, { Component } from "react";
import Auth from "./lib/auth";
import Home from "./components/home";
import { Accucast } from "./components/accucast";
import { AccubaseWrapper } from "./components/accubase";
import { AcculiftWrapper } from "./components/acculift";
import { PromoWrapper } from "./components/promoOpt";
import { NoAuthUploadWrapper } from "./components/noAuthUpload";
import { NoAuthFileShareWrapper } from "./components/noAuthFileShare";
import { UserManagementWrapper } from "./components/userManagement";
import { RptSubscriptionWrapper } from "./components/reportSubscriptions";
import Mdm from "./components/mdm";
import { Router, Route } from "react-router-dom";
import history from "./lib/history";
import App from "./App";
import loadable from "@loadable/component";
import socketIOClient from "socket.io-client";

//import ReactAI from "react-appinsights";

// ReactAI.init(
//   { instrumentationKey: process.env.REACT_APP_INSIGHTS_KEY },
//   history
// );
// ReactAI.setAppContext({ urlReferrer: document.referrer });

const AsyncReports = loadable(() => import("./components/reports/index"));

const AsyncPowerBI = loadable(() => import("./components/powerBI"));

const AsyncFiles = loadable(() => import("./components/files"));

const AsyncPbiMgmt = loadable(() => import("./components/pbiMgmt"));

const AsyncTableau = loadable(() => import("./components/tableau"));

const AsyncStaticContent = loadable(() => import("./components/staticContent"));

var socket;
class TabsRouter extends Component {
  constructor(props) {
    super(props);
    this.state = { auth: new Auth(this.props.store) };
    socket = socketIOClient(process.env.REACT_APP_BIGHORN_SERVER, {});
  }
  handleAuthentication = ({ location }) => {
    if (/access_token|id_token|error/.test(location.hash)) {
      this.state.auth.handleAuthentication();
    } else if (/sso_token/.test(location.hash)) {
      this.state.auth.handleSSOAuth();
    }
  };
  handleSetClient = ({ location }) => {
    this.state.auth.handleSetClient();
  };
  render() {
    return (
      <Router history={history}>
        <div className="tabsRouter" style={{ height: "100%", minHeight: "100%" }}>
          <Route path="/" render={props => <App auth={this.state.auth} {...props} />} />
          <Route path="/home" render={props => <Home auth={this.state.auth} {...props} />} />
          <Route path="/accucast" render={props => <Accucast auth={this.state.auth} {...props} />} />
          <Route path="/accubase" render={props => <AccubaseWrapper auth={this.state.auth} {...props} />} />
          <Route path="/acculift" render={props => <AcculiftWrapper auth={this.state.auth} {...props} />} />
          <Route path="/promoOpt" render={props => <PromoWrapper auth={this.state.auth} {...props} />} />
          <Route
            path="/reports"
            render={props => <AsyncReports auth={this.state.auth} store={this.props.store} {...props} />}
          />
          <Route
            path="/powerBI"
            render={props => <AsyncPowerBI auth={this.state.auth} store={this.props.store} {...props} />}
          />
          <Route
            path="/tableau"
            render={props => <AsyncTableau auth={this.state.auth} store={this.props.store} {...props} />}
          />
          <Route
            path="/sc"
            render={props => <AsyncStaticContent auth={this.state.auth} store={this.props.store} {...props} />}
          />
          <Route path="/files" render={props => <AsyncFiles auth={this.state.auth} store={this.props.store} {...props} />} />
          <Route
            path="/powerBIMgmt"
            render={props => <AsyncPbiMgmt auth={this.state.auth} store={this.props.store} {...props} />}
          />
          <Route
            path="/reportSubscriptions"
            render={props => <RptSubscriptionWrapper auth={this.state.auth} store={this.props.store} {...props} />}
          />
          <Route path="/mdm" render={props => <Mdm auth={this.state.auth} store={this.props.store} {...props} />} />
          <Route
            path="/userManagement"
            render={props => <UserManagementWrapper auth={this.state.auth} store={this.props.store} {...props} />}
          />
          <Route
            path="/callback"
            render={props => {
              this.handleAuthentication(props);
              return <Callback {...props} />;
            }}
          />
          <Route
            path="/setClient"
            render={props => {
              this.handleSetClient(props);
              return <Callback {...props} />;
            }}
          />
          <Route
            path="/fileUpload"
            render={props => <NoAuthUploadWrapper auth={this.state.auth} store={this.props.store} {...props} />}
          />
          <Route
            path="/uploadFile"
            // render={props => {
            //   this.handleSetClient(props);
            //   return <Callback {...props} />;
            // }}
            render={props => {
              // this.handleSetClient(props);
              return <NoAuthUploadWrapper auth={this.state.auth} store={this.props.store} {...props} />;
            }}
          />
          <Route
            path="/tabsFileShare"
            // render={props => {
            //   this.handleSetClient(props);
            //   return <Callback {...props} />;
            // }}
            render={props => {
              // this.handleSetClient(props);
              return <NoAuthFileShareWrapper auth={this.state.auth} store={this.props.store} {...props} />;
            }}
          />
          <Route
            path="/noPermissions"
            render={props => {
              return (
                <div className="login-wrapper">
                  <div className="login-msg">
                    You do not have any permissions set for any applications. Contact your administrator.
                  </div>
                </div>
              );
            }}
          />
        </div>
      </Router>
    );
  }
}

export { TabsRouter, socket };
