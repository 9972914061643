import React, { Component } from "react";
import "components/accucast/components/style/style.css";
import "bootstrap/dist/css/bootstrap.css";
import SecondLevelLENav from "components/accucast/components/updateLE/secondLevelLENav";
import LEAccucastRouter from "components/accucast/components/updateLE/leRoutes";

export class UpdateLE extends Component {
  render() {
    return (
      <div style={{ height: "100%", width: "100%" }}>
        <SecondLevelLENav {...this.props} />
        <LEAccucastRouter {...this.props} />
      </div>
    );
  }
}
