import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { Route, Switch, Redirect } from "react-router-dom";
import { LeWeighting } from "./leWeighting";
import { LeWeightingOverrides } from "./leWeightingOverrides";
import { LeSeasonality } from "./leSeasonality";
import { LeSeasonalityOverrides } from "./leSeasonalityOverrides";
import { StoreDistribution } from "./storeDistribution";
import { IncrementalLift } from "./incrementalLift";
import { Shipments } from "./shipments";
import "components/accucast/components/style/style.css";
import { accucastLEWorkflow } from "components/accucast/components/lib";
import { setWorkflowStep } from "./reducers/secondLevelLENavReducer";
import { connect } from "react-redux";

class LEAccucastRouter extends Component {
  constructor(props) {
    super(props);
    let path = this.props.history.location.pathname.split("/")[3];
    if (path === undefined) path = "leWeighting";
    this.props.setWorkflowStep(parseFloat(accucastLEWorkflow[path]));
    this.props.history.listen((location, action) => {
      let path = location.pathname.split("/")[3];
      if (path === undefined) path = "leWeighting";
      this.props.setWorkflowStep(parseFloat(accucastLEWorkflow[path]));
    });
  }
  changeHandler() {
    //this.props.setWorkflowStep(parseFloat(accucastLEWorkflow[path]))
  }

  render() {
    const { match } = this.props;
    return (
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          height: "100%",
          minHeight: "100%"
        }}
      >
        <Switch>
          <Redirect
            from="/accucast/le"
            exact
            to={match.path + "/le/leWeighting"}
          />
          <Route
            path={match.path + "/le/leWeighting"}
            render={props => <LeWeighting {...props} />}
          />
          <Route
            path={match.path + "/le/leWeightingOverrides"}
            render={props => <LeWeightingOverrides {...props} />}
          />
          <Route
            path={match.path + "/le/leSeasonality"}
            render={props => <LeSeasonality {...props} />}
          />
          <Route
            path={match.path + "/le/leSeasonalityOverrides"}
            render={props => <LeSeasonalityOverrides {...props} />}
          />
          <Route
            path={match.path + "/le/storeDistribution"}
            render={props => <StoreDistribution {...props} />}
          />
          <Route
            path={match.path + "/le/incrementalLift"}
            render={props => <IncrementalLift {...props} />}
          />
          <Route
            path={match.path + "/le/shipments"}
            render={props => <Shipments {...props} />}
          />
        </Switch>
      </div>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  setWorkflowStep(curStep) {
    dispatch(setWorkflowStep(curStep));
  }
});
export default withRouter(
  connect(
    "",
    mapDispatchToProps
  )(LEAccucastRouter)
);
