import React, { Component } from "react";
import "components/files/style/style.css";
import {
  DeleteIcon,
  ExternalLink,
  DownloadIcon,
  FinalBaseline,
  RightChevron
} from "lib/icons";
import { DotLoader } from "react-spinners";

// import ReactTooltip from "react-tooltip";

export class BaselineFolder extends Component {
  constructor(props) {
    super(props);
    this.state = {
      collapsed: false,
      selected: false,
      path: "",
      editing: false,
      name: this.props.item.PROJECT_NAME
    };
    this.collapseHandler = this.collapseHandler.bind(this);
    this.renderCollapsible = this.renderCollapsible.bind(this);
    this.clickHandler = this.clickHandler.bind(this);
    this.keyDownHandler = this.keyDownHandler.bind(this);
    this.editFolderName = this.editFolderName.bind(this);
    this.textInput = React.createRef();
    this.closeTextInput = this.closeTextInput.bind(this);
  }
  componentDidMount() {
    if (
      this.textInput.current !== undefined &&
      this.textInput.current !== null
    ) {
      this.textInput.current.focus();
      this.textInput.current.select();

      //window.addEventListener("blur", this.closeTextInput, false);
    }
  }
  componentDidUpdate() {
    if (
      this.textInput.current !== undefined &&
      this.textInput.current !== null
    ) {
      this.textInput.current.focus();
      this.textInput.current.select();

      window.addEventListener("blur", this.closeTextInput, false);
    }
  }
  collapseHandler() {
    this.setState(prevState => ({
      collapsed: !prevState.collapsed
    }));
  }
  componentWillUnmount() {
    window.removeEventListener("blur", this.closeTextInput, false);
  }
  renderCollapsible(item) {
    if (item.collapsible) {
      return (
        <RightChevron
          transform={this.state.collapsed ? "" : "rotate(90,12,12)"}
          height={"24px"}
          width={"24px"}
          color={"rgba(88, 89, 91, 1)"}
          viewBox={"0 -2 24 24"}
        />
      );
    }
  }
  editFolderName(event, item) {
    if (item.status !== undefined && item.status === "new") {
      item.editing = true;
      this.setState({ editing: true, name: item.PROJECT_NAME });
    }
  }
  closeTextInput(event) {
    if (event.type === "blur") {
      window.removeEventListener("blur", this.closeTextInput, false);
      this.props.item.PROJECT_NAME = {};
      this.props.item.PROJECT_NAME = "";

      this.props.editName(
        this.props.item,
        this.textInput.current.value,
        this.state.name
      );
    }
  }
  keyDownHandler(event, item) {
    if (event.key === "Enter") {
      this.props.editName(
        this.props.item,
        this.textInput.current.value,
        this.state.name
      );
    } else if (event.key === "Escape") {
      this.props.item.editing = false;
      this.props.editName(this.props.item, this.state.name, this.state.name);
      this.setState({ editing: false });
    }
  }
  clickHandler(event, baseline, batch, project, target) {
    // ReactTooltip.hide(); //make sure that the tooltip doesn't hang around after clicking on the icons.
    if (this.props.clickHandler !== undefined) {
      this.props.clickHandler(event, baseline, batch, project, target);
    }
  }
  renderBaselineChildren(batch, idx) {
    if (batch.baselines.length === 0) {
      return <div key={"baseline" + idx} />;
    }

    return batch.baselines.map((baseline, i) => {
      let path = baseline.path;
      if (this.props.isDialog) {
      }
      if (baseline.status === "complete") {
        return (
          <div
            key={"bc" + i}
            className={
              "ta-av-input-data-item" +
              (this.props.selected === path ? " selected" : "")
            }
            style={{
              marginBottom: "5px",
              paddingLeft: "10px",
              display: "flex",
              flexDirection: "row"
            }}
            onClick={event => {
              this.clickHandler(
                event,
                baseline,
                batch,
                this.props.project,
                "row"
              );
            }}
          >
            {!this.props.isDialog && baseline.final === true && (
              <div data-tip="Final Baseline" data-type="info">
                <FinalBaseline
                  height={"16px"}
                  width={"16px"}
                  color={"rgba(126, 126, 126, 1)"}
                  className="final-baseline"
                  style={{
                    cursor: "pointer",
                    marginBottom: "3px",
                    marginRight: "20px"
                  }}

                  // onClick={item => {
                  // }}
                />
              </div>
            )}
            <div style={{ marginRight: "30px" }}>{baseline.name}</div>
            {!this.props.isDialog && (
              <div
                style={{
                  marginLeft: "auto",
                  display: "flex",
                  flexDirection: "row"
                }}
              >
                <div style={{ marginRight: "30px" }}>
                  In/Out Week: {baseline.inOutWeekCount}
                </div>
                <div style={{ marginRight: "30px" }}>
                  Min Unit Sales: {baseline.minUnits}
                </div>
                <div style={{ marginRight: "30px" }}>
                  Minor Sales: {(baseline.minCat * 100).toString() + "%"}
                </div>
                <div
                  className="ta-baseline-actions"
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    zIndex: "100"
                  }}
                >
                  <div
                    className="external-link interactive-svg"
                    data-type="info"
                    data-tip="Review Baseline"
                    onClick={event => {
                      this.clickHandler(
                        event,
                        baseline,
                        batch,
                        this.props.project,
                        "external-link"
                      );
                    }}
                  >
                    <ExternalLink
                      height={"16px"}
                      width={"16px"}
                      color={"rgba(0, 126, 167, 1)"}
                      style={{
                        cursor: "pointer",
                        marginRight: "10px",
                        marginBottom: "3px"
                      }}
                    />
                  </div>
                  <div
                    className="download interactive-svg"
                    data-type="info"
                    data-tip="Download Baseline"
                    onClick={event => {
                      this.clickHandler(
                        event,
                        baseline,
                        batch,
                        this.props.project,
                        "download"
                      );
                    }}
                  >
                    <DownloadIcon
                      height={"16px"}
                      width={"16px"}
                      color={"rgba(0, 126, 167, 1)"}
                      style={{
                        cursor: "pointer",
                        marginRight: "10px",
                        marginBottom: "3px"
                      }}
                      // onClick={item => {
                      // }}
                    />
                  </div>
                  <div
                    className="delete interactive-svg"
                    data-type="info"
                    data-tip="Delete Baseline"
                    onClick={event => {
                      this.clickHandler(
                        event,
                        baseline,
                        batch,
                        this.props.project,
                        "delete"
                      );
                    }}
                  >
                    <DeleteIcon
                      height={"16px"}
                      width={"16px"}
                      color={"rgba(0, 126, 167, 1)"}
                      style={{
                        cursor: "pointer",
                        marginBottom: "3px",
                        marginRight: "10px"
                      }}
                      // onClick={item => {
                      // }}
                    />
                  </div>
                  <div
                    className="final interactive-svg"
                    data-type="info"
                    data-tip="Mark Baseline as Final (only one per batch)"
                    onClick={event => {
                      this.clickHandler(
                        event,
                        baseline,
                        batch,
                        this.props.project,
                        "makeFinal"
                      );
                    }}
                  >
                    {" "}
                    <FinalBaseline
                      height={"16px"}
                      width={"16px"}
                      color={"rgba(0, 126, 167, 1)"}
                      style={{
                        cursor: "pointer",
                        marginBottom: "3px",
                        marginRight: "20px"
                      }}
                      // onClick={item => {
                      // }}
                    />
                  </div>
                </div>
              </div>
            )}
          </div>
        );
      } else if (baseline.status === "failed") {
        return (
          <div
            key={"bc" + i}
            className={
              "ta-av-input-data-item" +
              (this.props.selected === path ? " selected" : "")
            }
            style={{
              marginBottom: "5px",
              paddingLeft: "10px",

              display: "flex",
              flexDirection: "row"
            }}
            onClick={event => {
              this.clickHandler(event, baseline, batch, "row");
            }}
          >
            <div style={{ marginRight: "30px", width: "42%" }}>
              {baseline.name}
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "row"
              }}
            >
              <div>Failed</div>
            </div>
            <div
              className="ta-baseline-actions"
              style={{
                display: "flex",
                flexDirection: "row",
                zIndex: "100"
              }}
            >
              {" "}
              <div
                className="delete interactive-svg"
                data-type="info"
                data-tip="Delete Baseline"
                onClick={event => {
                  this.clickHandler(
                    event,
                    baseline,
                    batch,
                    this.props.project,
                    "delete"
                  );
                }}
              >
                <DeleteIcon
                  height={"16px"}
                  width={"16px"}
                  color={"rgba(0, 126, 167, 1)"}
                  style={{
                    cursor: "pointer",
                    marginBottom: "3px",
                    marginRight: "10px"
                  }}
                  // onClick={item => {
                  // }}
                />
              </div>
            </div>
          </div>
        );
      } else {
        return (
          <div
            key={"bc" + i}
            className={
              "ta-av-input-data-item" +
              (this.props.selected === path ? " selected" : "")
            }
            style={{
              marginBottom: "5px",
              paddingLeft: "10px",

              display: "flex",
              flexDirection: "row"
            }}
            onClick={event => {
              this.clickHandler(event, baseline, batch, "row");
            }}
          >
            <div style={{ marginRight: "30px", width: "42%" }}>
              {baseline.name}
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "row"
              }}
            >
              <DotLoader
                sizeUnit={"px"}
                size={15}
                color={"black"}
                loading={true}
              />
              <div>Calculating</div>
            </div>
          </div>
        );
      }
    });
  }

  render() {
    const { item, index } = this.props;
    const { collapsed } = this.state;

    if (collapsed) {
      return (
        <div key={index}>
          <div style={{ display: "flex", flexDirection: "row" }}>
            <div
              key={index}
              style={{ display: "flex" }}
              onClick={() => {
                this.collapseHandler(index);
              }}
            >
              <RightChevron
                //transform={this.state.collapsed ? "" : "rotate(90,12,12)"}
                height={"24px"}
                width={"24px"}
                color={"rgba(88, 89, 91, 1)"}
                viewBox={"0 -2 24 24"}
              />
            </div>
            {this.props.item.editing ? (
              <div
                onClick={event => {
                  this.clickHandler(event, item);
                }}
                onDoubleClick={event => {
                  this.editFolderName(event, item);
                }}
                className={"ta-project-title "}
              >
                <input
                  style={{ width: "100%" }}
                  className="folder-edit-text"
                  defaultValue={item.name}
                  ref={this.textInput}
                  onChange={evt => {
                    if (evt.keyCode === 13) {
                      this.props.item.editing = false;
                      this.setState({ editing: false });
                    }
                  }}
                  onBlur={this.closeTextInput}
                  onKeyDown={event => {
                    item.name = this.textInput;
                    this.keyDownHandler(event, item);
                  }}
                />
              </div>
            ) : (
              <div
                // onClick={event => {
                //   this.clickHandler(event, item);
                // }}
                onClick={() => {
                  this.collapseHandler(index);
                }}
                onDoubleClick={event => {
                  this.editFolderName(event, item);
                }}
                className={"ta-project-title "}
              >
                {item.name}
              </div>
            )}
          </div>
        </div>
      );
    }

    return (
      <div>
        <div
          className="folder-div"
          style={{ display: "flex", flexDirection: "row" }}
        >
          <div
            className="collapse"
            style={{ display: "flex" }}
            onClick={() => {
              this.collapseHandler(index);
            }}
          >
            <RightChevron
              transform={this.state.collapsed ? "" : "rotate(90,12,12)"}
              height={"24px"}
              width={"24px"}
              color={"rgba(88, 89, 91, 1)"}
              viewBox={"0 -2 24 24"}
            />
          </div>
          {this.props.item.editing ? (
            <div
              onClick={event => {
                this.clickHandler(event, item);
              }}
              onDoubleClick={event => {
                this.editFolderName(event, item);
              }}
              className={"ta-project-title "}
            >
              <input
                style={{ width: "100%" }}
                className="folder-edit-text"
                defaultValue={item.name}
                ref={this.textInput}
                onChange={evt => {
                  if (evt.keyCode === 13) {
                    this.props.item.editing = false;
                    this.setState({ editing: false });
                  }
                }}
                onBlur={this.closeTextInput}
                onKeyDown={event => {
                  item.name = this.textInput;
                  this.keyDownHandler(event, item);
                }}
              />
            </div>
          ) : (
            <div
              // onClick={event => {
              //   this.clickHandler(event, item);
              // }}
              onClick={() => {
                this.collapseHandler(index);
              }}
              onDoubleClick={event => {
                this.editFolderName(event, item);
              }}
              className={"ta-project-title "}
            >
              {item.name}
            </div>
          )}
        </div>
        <div
          style={{
            paddingLeft: "40px"
          }}
        >
          {this.renderBaselineChildren(item, index)}
        </div>
      </div>
    );
  }
}
