import axios from "axios";
import { cloneDeep, sortBy } from "lodash";
import { toast } from "react-toastify";
import moment from "moment";

const timestampFormat = "MM/DD/YY";

/* -------------------- INITIAL STATE -------------------- */
const INITIAL_STATE = {
  loadedProjects: false,
  loadingProjects: false,
  loadingError: false,
  loadingData: false,
  loadedData: false,
  projects: [],
  searchProjects: [],
  error: null,
  data: [],
  filteredData: [],

  changed: [],
  reInitChart: false,
  oldProjectName: "",
  selectedProject: null,
  selectedBaseline: {},
  reInitProjects: false,
  viewSelected: 1,
  liftId: -1,
  lifts: [],
  lovs: {},
  alias: {},
  updateFilters: false,
  selectedLift: null
};

/* -------------------- ACTION TYPES -------------------- */
const FETCH_ACCULIFT_DATA_BEGIN = "FETCH_ACCULIFT_DATA_BEGIN";
const FETCH_ACCULIFT_DATA_SUCCESS = "FETCH_ACCULIFT_DATA_SUCCESS";
const FETCH_ACCULIFT_DATA_FAILURE = "FETCH_ACCULIFT_DATA_FAILURE";
const FETCH_ACCULIFT_PROJECT_BEGIN = "FETCH_ACCULIFT_PROJECT_BEGIN";
const FETCH_ACCULIFT_PROJECT_SUCCESS = "FETCH_ACCULIFT_PROJECT_SUCCESS";
const FETCH_ACCULIFT_PROJECT_FAILURE = "FETCH_ACCULIFT_PROJECT_FAILURE";
const SET_CHART_REINIT = "SET_CHART_REINIT";
const SET_PROJ_REINIT = "SET_PROJ_REINIT";
const SET_CHART_DATA = "SET_CHART_DATA";

const SET_SEARCH_PROJECTS = "SET_SEARCH_PROJECTS";
const SET_SELECTED_PROJECT = "SET_SELECTED_PROJECT";

const SET_SELECTED_LIFT = "SET_SELECTED_LIFT";
const SET_VIEW_SELECTED = "SET_ACCULIFT_VIEW_SELECTED";
const SET_INITIAL_LOVS = "SET_INITIAL_LOVS";
const SELECT_UNSELECT_LOV = "SELECT_UNSELECT_LOV";
const SELECT_ALL_LOVS = "SELECT_ALL_LOVS";
const DESELECT_ALL_LOVS = "DESELECT_ALL_LOVS";

/* -------------------- ACTION CREATORS -------------------- */

const fetchAcculiftDataBegin = () => ({
  type: FETCH_ACCULIFT_DATA_BEGIN
});
const fetchAcculiftDataSuccess = files => {
  return { type: FETCH_ACCULIFT_DATA_SUCCESS, payload: files };
};
const fetchAcculiftDataError = error => {
  return { type: FETCH_ACCULIFT_DATA_FAILURE, payload: error };
};

const fetchAcculiftProjectBegin = () => ({
  type: FETCH_ACCULIFT_PROJECT_BEGIN
});
const fetchAcculiftProjectSuccess = (projects, selectProject) => {
  return {
    type: FETCH_ACCULIFT_PROJECT_SUCCESS,
    payload: projects,
    selectProject
  };
};
const fetchAcculiftProjectError = error => {
  return { type: FETCH_ACCULIFT_PROJECT_FAILURE, payload: error };
};
const setProjReinit = status => {
  return { type: SET_PROJ_REINIT, payload: status };
};
const setChartReinit = status => {
  return { type: SET_CHART_REINIT, payload: status };
};
const doSetfilteredData = data => {
  return { type: SET_CHART_DATA, payload: data };
};

const doSetSearchProjects = projects => {
  return { type: SET_SEARCH_PROJECTS, payload: projects };
};
const doSetSelectedProject = project => {
  return { type: SET_SELECTED_PROJECT, payload: project };
};

/* -------------------- ASYNC ACTION CREATORS -------------------- */
export const getAcculiftData = liftId => {
  return dispatch => {
    dispatch(fetchAcculiftDataBegin());
    axios
      .get(`${process.env.REACT_APP_BIGHORN_SERVER}/api/acculift/acculiftData/${liftId}`, {
        headers: {
          ClientToken: localStorage.getItem("clientToken")
        }
      })
      .then(response => {
        let data = response.data;
        dispatch(fetchAcculiftDataSuccess(data.content));

        dispatch(generateLovs(response.data.content));
      })
      .catch(error => {
        dispatch(fetchAcculiftDataError(error));
      });
  };
};

export const getProjects = (selectProject = true) => {
  return dispatch => {
    dispatch(fetchAcculiftProjectBegin());
    axios
      .get(`${process.env.REACT_APP_BIGHORN_SERVER}/api/acculift/projects`, {
        headers: {
          ClientToken: localStorage.getItem("clientToken")
        }
      })
      .then(response => {
        dispatch(fetchAcculiftProjectSuccess(response.data.projects, selectProject));
        dispatch(setProjReinit(true));
      })
      .catch(error => {
        dispatch(fetchAcculiftProjectError(error));
      });
  };
};
export const setProjects = projects => {
  return dispatch => {
    dispatch(fetchAcculiftProjectSuccess(projects));
  };
};

export const saveProjects = projects => {
  return dispatch => {
    axios
      .post(
        `${process.env.REACT_APP_BIGHORN_SERVER}/api/acculift/createProjects`,
        { projects },
        {
          headers: {
            ClientToken: localStorage.getItem("clientToken")
          }
        }
      )
      .then(response => {
        dispatch(getProjects());
      })
      .catch(error => {});
  };
};
export const setReInitProjects = state => {
  return dispatch => {
    dispatch(setProjReinit(state));
  };
};
export const setReinitChart = state => {
  return dispatch => {
    dispatch(setChartReinit(state));
  };
};
export const setFilteredData = data => {
  return dispatch => {
    dispatch(doSetfilteredData(data));
  };
};
export const generateLovs = data => {
  return dispatch => {
    dispatch({ type: "SET_INITIAL_LOVS", payload: data });
  };
};
export const rerunLiftExport = projectId => {
  return dispatch => {
    axios
      .post(
        `${process.env.REACT_APP_BIGHORN_SERVER}/api/acculift/rerunExports`,
        { projectId },
        {
          headers: {
            ClientToken: localStorage.getItem("clientToken")
          }
        }
      )
      .then(response => {
        //dispatch(getProjects());
      })
      .catch(error => {});
  };
};
export const setSearchProjects = (filter, projects, includeSecondary) => {
  return dispatch => {
    if (filter === "") {
      dispatch(doSetSearchProjects(projects));
    } else {
      var newProjects = cloneDeep(projects);
      var nProjects = [];

      if (includeSecondary) {
        nProjects = newProjects.map((newProject, newProjectIdx) => {
          newProject.baselines = newProject.baselines.map((baseline, baselineIdx) => {
            baseline.lifts = baseline.lifts.filter(item => {
              if (item.name.toLowerCase().indexOf(filter.toLowerCase()) !== -1) {
                return true;
              } else {
                return false;
              }
            });
            return baseline;
          });
          return newProject;
        });
      } else {
        newProjects.forEach((newProject, newProjectIdx) => {
          if (newProject.PROJECT_NAME.toLowerCase().indexOf(filter.toLowerCase()) !== -1) {
            nProjects.push(newProject);
          }
        });
      }
      dispatch(doSetSearchProjects(nProjects));
    }
  };
};
export const setSelectedProject = project => {
  return dispatch => {
    dispatch(doSetSelectedProject(project));
  };
};
export const setOldProjectName = (selectedProject, name) => {
  return dispatch => {
    axios
      .post(
        `${process.env.REACT_APP_BIGHORN_SERVER}/api/acculift/editProjectName`,
        { selectedProject },
        {
          headers: {
            ClientToken: localStorage.getItem("clientToken")
          }
        }
      )
      .then(response => {
        dispatch(getProjects());
      })
      .catch(error => {});
    dispatch({ type: "SET_OLD_PROJECT_NAME", payload: name });
  };
};
export const renameBaseline = (id, name, bId) => {
  return dispatch => {
    axios
      .post(
        `${process.env.REACT_APP_BIGHORN_SERVER}/api/acculift/editBaselineName`,
        { id, name, batchId: bId },
        {
          headers: {
            ClientToken: localStorage.getItem("clientToken")
          }
        }
      )
      .then(response => {
        dispatch(getProjects());
      })
      .catch(error => {});
    //dispatch({ type: "SET_OLD_PROJECT_NAME", payload: name });
  };
};
export const renameLift = (id, name, bId) => {
  return dispatch => {
    axios
      .post(
        `${process.env.REACT_APP_BIGHORN_SERVER}/api/acculift/editLiftName`,
        { id, name, baselineId: bId },
        {
          headers: {
            ClientToken: localStorage.getItem("clientToken")
          }
        }
      )
      .then(response => {
        dispatch(getProjects());
      })
      .catch(error => {});
    //dispatch({ type: "SET_OLD_PROJECT_NAME", payload: name });
  };
};
export const selectLift = (lift, viewSelected = 1) => {
  return dispatch => {
    dispatch(getAcculiftData(lift.id));
    dispatch({ type: "SET_SELECTED_LIFT", payload: lift, viewSelected });
  };
};
export const setViewSelected = view => {
  return dispatch => {
    dispatch({ type: "SET_ACCULIFT_VIEW_SELECTED", payload: view });
  };
};
export const selectUnselectItem = (item, parentName, selectOne = false) => {
  return dispatch => {
    dispatch({
      type: "SELECT_UNSELECT_LOV",
      payload: item,
      parentName,
      selectOne
    });
  };
};
export const selectAllLovs = filter => {
  return dispatch => {
    dispatch({ type: "SELECT_ALL_LOVS", payload: filter });
  };
};
export const deselectAllLovs = filter => {
  return dispatch => {
    dispatch({ type: "DESELECT_ALL_LOVS", payload: filter });
  };
};
export const setUpdateFiltersStatus = status => {
  return dispatch => {
    dispatch({ type: "SET_UPDATE_FILTER_STATUS", payload: status });
  };
};
export const createLift = (baselineId, versionName) => {
  toast.success("Starting Lift Model Calculation for Lift: " + versionName);
  return dispatch => {
    axios
      .post(
        `${process.env.REACT_APP_BIGHORN_SERVER}/api/acculift/runLift`,
        {
          versionName,
          baselineId
        },
        {
          headers: {
            ClientToken: localStorage.getItem("clientToken")
          }
        }
      )
      .then(res => {
        dispatch(getProjects());
      })
      .catch(err => {
        console.log(err);
      });
  };
};
export const setTacticAlias = (projectId, tpr, major, minor, other) => {
  return dispatch => {
    axios
      .post(
        `${process.env.REACT_APP_BIGHORN_SERVER}/api/acculift/setTacticAlias`,
        {
          tpr,
          major,
          minor,
          other,
          projectId
        },
        {
          headers: {
            ClientToken: localStorage.getItem("clientToken")
          }
        }
      )
      .then(res => {
        //dispatch(getProjects());
      });
  };
};
export const setAlias = (projectId, tpr, major, minor, other) => {
  return dispatch => {
    dispatch({ type: "SET_ALIAS", projectId, tpr, major, minor, other });
  };
};
export const selectBaseline = baseline => {
  return dispatch => {
    dispatch({ type: "SET_SELECTED_BASELINE", payload: baseline });
  };
};

/* -------------------- REDUCER -------------------- */
export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case FETCH_ACCULIFT_DATA_BEGIN:
      return {
        ...state,

        data: [],
        tpr: [],
        minor: [],
        major: [],
        other: [],
        filteredData: [],
        loadingData: true,
        loadedData: false,
        error: null
      };
    case FETCH_ACCULIFT_DATA_SUCCESS:
      let newData = sortBy(action.payload, o => {
        return o.WEEK_DATE;
      });

      return {
        ...state,
        data: newData,
        filteredData: action.payload
      };

    case FETCH_ACCULIFT_DATA_FAILURE:
      return {
        ...state,
        loadingData: false,
        loadedData: false,

        error: action.payload.error
      };

    case FETCH_ACCULIFT_PROJECT_BEGIN:
      return { ...state, loadingProjects: true, error: null };
    case FETCH_ACCULIFT_PROJECT_SUCCESS:
      if (state.selectedProject !== null) {
        let sProject = cloneDeep(action.payload);
        sProject = sProject.filter(item => {
          return item.ID === state.selectedProject.ID;
        });

        let selProject = sProject[0];
        if (sProject[0] === undefined) {
          selProject = action.payload[0];
        }

        return {
          ...state,
          projects: action.payload,
          searchProjects: action.payload,
          selectedProject: selProject,
          loadingProjects: false,
          loadedProjects: true,
          alias: {
            tprAlias: selProject.TACTIC_ALIAS_TPRONLY,
            minorAlias: selProject.TACTIC_ALIAS_MINOR,
            majorAlias: selProject.TACTIC_ALIAS_MAJOR,
            otherAlias: selProject.TACTIC_ALIAS_OTHER
          }
        };
      } else if (action.payload.length > 0) {
        let latestBaseline = null;
        action.payload.forEach((project, pIdx) => {
          project.baselines.forEach((baseline, bIdx) => {
            if (baseline.lifts !== undefined) {
              if (latestBaseline === null) {
                latestBaseline = baseline;
                latestBaseline.projectIndex = pIdx;
                latestBaseline.idx = bIdx;
              } else if (
                moment(latestBaseline.dateCreated, timestampFormat).isBefore(moment(baseline.dateCreated, timestampFormat))
              ) {
                latestBaseline = baseline;
                latestBaseline.projectIndex = pIdx;
                latestBaseline.idx = bIdx;
              }
            }
          });
        });
        return {
          ...state,
          projects: action.payload,
          searchProjects: action.payload,
          selectedProject: action.payload[latestBaseline.projectIndex],
          selectedBaseline: latestBaseline,
          loadingProjects: false,
          loadedProjects: true,
          alias: {
            tprAlias: action.payload[latestBaseline.projectIndex].TACTIC_ALIAS_TPRONLY,
            minorAlias: action.payload[latestBaseline.projectIndex].TACTIC_ALIAS_MINOR,
            majorAlias: action.payload[latestBaseline.projectIndex].TACTIC_ALIAS_MAJOR,
            otherAlias: action.payload[latestBaseline.projectIndex].TACTIC_ALIAS_OTHER
          }
        };
      } else {
        return {
          ...state,
          projects: action.payload,
          searchProjects: action.payload,
          loadingProjects: false,
          loadedProjects: true,
          alias: {
            tprAlias: action.payload.TACTIC_ALIAS_TPRONLY,
            minorAlias: action.payload.TACTIC_ALIAS_MINOR,
            majorAlias: action.payload.TACTIC_ALIAS_MAJOR,
            otherAlias: action.payload.TACTIC_ALIAS_OTHER
          }
        };
      }

    case FETCH_ACCULIFT_PROJECT_FAILURE:
      return {
        ...state,
        loadingProjects: false,
        loadedProjects: false,
        loadingError: true,
        error: action.payload.error
      };
    case SET_CHART_REINIT:
      return {
        ...state,
        reInitChart: action.payload
      };
    case SET_PROJ_REINIT:
      return {
        ...state,
        reInitProjects: action.payload
      };
    case SET_CHART_DATA:
      return {
        ...state,
        filteredData: action.payload,
        reInitChart: true
      };
    case SET_SELECTED_LIFT:
      let viewSelected = action.viewSelected;
      let liftId = -1;
      if (action.payload !== {}) {
        //viewSelected = 2;
        liftId = action.payload.id;
      }
      return {
        ...state,
        selectedLift: action.payload,
        viewSelected,
        liftId
      };
    case SET_VIEW_SELECTED:
      return {
        ...state,
        viewSelected: action.payload
      };

    case SET_SEARCH_PROJECTS:
      return { ...state, searchProjects: action.payload };
    case "SET_OLD_PROJECT_NAME":
      return { ...state, oldProjectName: action.payload };
    case "SET_ALIAS":
      return {
        ...state,
        alias: {
          tprAlias: action.tpr,
          minorAlias: action.minor,
          majorAlias: action.major,
          otherAlias: action.other
        }
      };
    case SET_SELECTED_PROJECT:
      let latestBaseline = null;

      action.payload.baselines.forEach((baseline, bIdx) => {
        if (baseline.lifts !== undefined) {
          if (latestBaseline === null) {
            latestBaseline = baseline;
            latestBaseline.idx = bIdx;
          } else if (
            moment(latestBaseline.dateCreated, timestampFormat).isBefore(moment(baseline.dateCreated, timestampFormat))
          ) {
            latestBaseline = baseline;
            latestBaseline.idx = bIdx;
          }
        }
      });

      return {
        ...state,
        selectedProject: action.payload,
        selectedBaseline: latestBaseline,
        alias: {
          tprAlias: action.payload.TACTIC_ALIAS_TPRONLY,
          minorAlias: action.payload.TACTIC_ALIAS_MINOR,
          majorAlias: action.payload.TACTIC_ALIAS_MAJOR,
          otherAlias: action.payload.TACTIC_ALIAS_OTHER
        },
        oldProjectName: action.payload.PROJECT_NAME
      };
    case SET_INITIAL_LOVS:
      var lovs = [];
      let filteredData = [];
      action.payload.forEach(item => {
        let thisItem = item;
        let exists;

        if (item["GEO_KEY"] === action.payload[0]["GEO_KEY"] && item["ITEM_KEY"] === action.payload[0]["ITEM_KEY"]) {
          filteredData.push(item);
          Object.keys(item).forEach((key, index) => {
            if (key === "GEO_KEY" || key === "ITEM_KEY" || key === "PRIMARY_TACTIC_ALIAS" || key === "ITEM_PERCENTILE") {
              exists = lovs.filter(filter => {
                return filter.name === key;
              });
              if (exists.length > 0) {
                lovs.forEach((lov, idx) => {
                  if (lov.name === key) {
                    let vExists = lov.values.filter(filter => {
                      return filter.value === thisItem[key];
                    });
                    if (vExists.length > 0) {
                      vExists = vExists[0];

                      if (!vExists.itemValue.includes(thisItem["ITEM_KEY"])) {
                        vExists.itemValue.push(thisItem["ITEM_KEY"]);
                      }
                      if (!vExists.tacticValue.includes(thisItem["PRIMARY_TACTIC_ALIAS"])) {
                        vExists.tacticValue.push(thisItem["PRIMARY_TACTIC_ALIAS"]);
                      }
                      if (!vExists.percentileValue.includes(thisItem["ITEM_PERCENTILE"])) {
                        vExists.percentileValue.push(thisItem["ITEM_PERCENTILE"]);
                      }
                      if (!vExists.geoValue.includes(thisItem["GEO_KEY"])) {
                        vExists.geoValue.push(thisItem["GEO_KEY"]);
                      }
                    } else {
                      lov.values.push({
                        value: thisItem[key],
                        geoValue: [thisItem["GEO_KEY"]],
                        itemValue: [thisItem["ITEM_KEY"]],
                        tacticValue: [thisItem["PRIMARY_TACTIC_ALIAS"]],
                        percentileValue: [thisItem["ITEM_PERCENTILE"]],
                        selected: true,
                        displayed: true
                      });
                    }
                  }
                });
              } else {
                lovs.push({
                  name: key,
                  values: [
                    {
                      value: thisItem[key],
                      geoValue: [thisItem["GEO_KEY"]],
                      itemValue: [thisItem["ITEM_KEY"]],
                      tacticValue: [thisItem["PRIMARY_TACTIC_ALIAS"]],
                      percentileValue: [thisItem["ITEM_PERCENTILE"]],
                      selected: true,
                      displayed: true
                    }
                  ]
                });
              }
            }
          });
        } else {
          lovs.forEach((lov, idx) => {
            if (lov.name === "GEO_KEY") {
              let vExists = lov.values.filter(filter => {
                return filter.value === thisItem["GEO_KEY"];
              });

              if (vExists.length > 0) {
                vExists = vExists[0];
                if (!vExists.itemValue.includes(thisItem["ITEM_KEY"])) {
                  vExists.itemValue.push(thisItem["ITEM_KEY"]);
                }
                if (!vExists.tacticValue.includes(thisItem["PRIMARY_TACTIC_ALIAS"])) {
                  vExists.tacticValue.push(thisItem["PRIMARY_TACTIC_ALIAS"]);
                }
                if (!vExists.percentileValue.includes(thisItem["ITEM_PERCENTILE"])) {
                  vExists.percentileValue.push(thisItem["ITEM_PERCENTILE"]);
                }
              } else {
                lov.values.push({
                  value: thisItem["GEO_KEY"],
                  geoValue: [thisItem["GEO_KEY"]],
                  itemValue: [thisItem["ITEM_KEY"]],
                  tacticValue: [thisItem["PRIMARY_TACTIC_ALIAS"]],
                  percentileValue: [thisItem["ITEM_PERCENTILE"]],
                  selected: false
                });
              }
            }
            if (lov.name === "ITEM_KEY") {
              let vExistsItem = lov.values.filter(filter => {
                return filter.value === thisItem["ITEM_KEY"];
              });

              if (vExistsItem.length > 0) {
                vExistsItem = vExistsItem[0];
                if (!vExistsItem.geoValue.includes(thisItem["GEO_KEY"])) {
                  vExistsItem.geoValue.push(thisItem["GEO_KEY"]);
                }
                if (!vExistsItem.tacticValue.includes(thisItem["PRIMARY_TACTIC_ALIAS"])) {
                  vExistsItem.tacticValue.push(thisItem["PRIMARY_TACTIC_ALIAS"]);
                }

                if (!vExistsItem.percentileValue.includes(thisItem["ITEM_PERCENTILE"])) {
                  vExistsItem.percentileValue.push(thisItem["ITEM_PERCENTILE"]);
                }
                //} else if (!i_geo_selected.includes(thisItem['GEO_KEY'])) {
              } else {
                lov.values.push({
                  value: thisItem["ITEM_KEY"],
                  geoValue: [thisItem["GEO_KEY"]],
                  itemValue: [thisItem["ITEM_KEY"]],
                  tacticValue: [thisItem["PRIMARY_TACTIC_ALIAS"]],
                  percentileValue: [thisItem["ITEM_PERCENTILE"]],
                  selected: false
                });
              }
            }
            if (lov.name === "ITEM_PERCENTILE") {
              let vExistsPercentile = lov.values.filter(filter => {
                return filter.value === thisItem["ITEM_PERCENTILE"];
              });

              if (vExistsPercentile.length > 0) {
                vExistsPercentile = vExistsPercentile[0];
                if (!vExistsPercentile.geoValue.includes(thisItem["GEO_KEY"])) {
                  vExistsPercentile.geoValue.push(thisItem["GEO_KEY"]);
                }
                if (!vExistsPercentile.tacticValue.includes(thisItem["PRIMARY_TACTIC_ALIAS"])) {
                  vExistsPercentile.tacticValue.push(thisItem["PRIMARY_TACTIC_ALIAS"]);
                }

                if (!vExistsPercentile.itemValue.includes(thisItem["ITEM_KEY"])) {
                  vExistsPercentile.itemValue.push(thisItem["ITEM_KEY"]);
                }
                //} else if (!i_geo_selected.includes(thisItem['GEO_KEY'])) {
              } else {
                lov.values.push({
                  value: thisItem["ITEM_PERCENTILE"],
                  geoValue: [thisItem["GEO_KEY"]],
                  itemValue: [thisItem["ITEM_KEY"]],
                  tacticValue: [thisItem["PRIMARY_TACTIC_ALIAS"]],
                  percentileValue: [thisItem["ITEM_PERCENTILE"]],
                  selected: false
                });
              }
            }
            if (lov.name === "PRIMARY_TACTIC_ALIAS") {
              let vExistsTactic = lov.values.filter(filter => {
                return filter.value === thisItem["PRIMARY_TACTIC_ALIAS"];
              });

              if (vExistsTactic.length > 0) {
                vExistsTactic = vExistsTactic[0];
                if (!vExistsTactic.geoValue.includes(thisItem["GEO_KEY"])) {
                  vExistsTactic.geoValue.push(thisItem["GEO_KEY"]);
                }

                if (!vExistsTactic.percentileValue.includes(thisItem["ITEM_PERCENTILE"])) {
                  vExistsTactic.percentileValue.push(thisItem["ITEM_PERCENTILE"]);
                }
                if (!vExistsTactic.itemValue.includes(thisItem["ITEM_KEY"])) {
                  vExistsTactic.itemValue.push(thisItem["ITEM_KEY"]);
                }
                //} else if (!i_geo_selected.includes(thisItem['GEO_KEY'])) {
              } else {
                lov.values.push({
                  value: thisItem["PRIMARY_TACTIC_ALIAS"],
                  geoValue: [thisItem["GEO_KEY"]],
                  itemValue: [thisItem["ITEM_KEY"]],
                  tacticValue: [thisItem["PRIMARY_TACTIC_ALIAS"]],
                  percentileValue: [thisItem["ITEM_PERCENTILE"]],
                  selected: false
                });
              }
            }
          });
        }
      });

      lovs = lovs.map(item => {
        item.values = sortBy(item.values, o => {
          return o.value;
        });

        if (item.name === "GEO_KEY") {
          item.values = item.values.map(value => {
            value.displayed = true;
            return value;
          });
        }
        if (item.name === "ITEM_KEY") {
          item.values = item.values.map(value => {
            if (lovs[0].values[0].itemValue.includes(value.value)) {
              // value.selected = true;
              value.displayed = true;
            } else {
              value.displayed = false;
            }
            return value;
          });
        }
        if (item.name === "ITEM_PERCENTILE") {
          item.values = item.values.map(value => {
            if (lovs[0].values[0].percentileValue.includes(value.value)) {
              // value.selected = true;
              value.displayed = true;
            } else {
              value.displayed = false;
            }
            return value;
          });
        }
        if (item.name === "PRIMARY_TACTIC_ALIAS") {
          item.values = item.values.map(value => {
            if (lovs[0].values[0].tacticValue.includes(value.value)) {
              // value.selected = true;
              value.displayed = true;
            } else {
              value.displayed = false;
            }
            return value;
          });
        }
        return item;
      });

      return {
        ...state,
        lovs,
        filteredData,
        loadingData: false,
        loadedData: true
      };
    case SELECT_ALL_LOVS:
      let a_geo_selected = [];
      let a_item_selected = [];
      let a_tactic_selected = [];
      let a_percentile_selected = [];
      let aNewFilteredData = [];
      let aItemSet = new Set();
      let aGeoSet = new Set();
      let aTacticSet = new Set();
      let aPercentileSet = new Set();

      let aLovs = state.lovs.map(filter => {
        if (filter.name === action.payload.name) {
          filter.values = action.payload.values;
        } else {
          filter.values = filter.values.filter(item => {
            if (item.id === -1) {
              return false;
            }
            return true;
          });
        }

        if (filter.name === "ITEM_KEY") {
          filter.values.forEach(item => {
            if (item.selected) {
              aItemSet.add(item.value);
              a_item_selected.push(item.value);
              item.geoValue.forEach(item => {
                aGeoSet.add(item);
              });
              item.tacticValue.forEach(item => {
                if (item !== undefined) {
                  aTacticSet.add(item);
                }
              });
              item.percentileValue.forEach(item => {
                aPercentileSet.add(item);
              });
            }
          });
        } else if (filter.name === "GEO_KEY") {
          filter.values.forEach(item => {
            if (item.selected) {
              aGeoSet.add(item.value);
              a_geo_selected.push(item.value);
              item.itemValue.forEach(item => {
                aItemSet.add(item);
              });
              item.tacticValue.forEach(item => {
                if (item !== undefined) {
                  aTacticSet.add(item);
                }
              });
              item.percentileValue.forEach(item => {
                aPercentileSet.add(item);
              });
            }
          });
        } else if (filter.name === "PRIMARY_TACTIC_ALIAS") {
          filter.values.forEach(item => {
            if (item.selected) {
              aTacticSet.add(item.value);
              a_tactic_selected.push(item.value);
              item.itemValue.forEach(item => {
                aItemSet.add(item);
              });
              item.geoValue.forEach(item => {
                aGeoSet.add(item);
              });
              item.percentileValue.forEach(item => {
                aPercentileSet.add(item);
              });
            }
          });
        } else if (filter.name === "ITEM_PERCENTILE") {
          filter.values.forEach(item => {
            if (item.selected) {
              aPercentileSet.add(item.value);
              a_percentile_selected.push(item.value);
              item.itemValue.forEach(item => {
                aItemSet.add(item);
              });
              item.geoValue.forEach(item => {
                aGeoSet.add(item);
              });
              item.tacticValue.forEach(item => {
                if (item !== undefined) {
                  aTacticSet.add(item);
                }
              });
            }
          });
        }
        return filter;
      });
      state.data.forEach(row => {
        if (
          a_item_selected.includes(row["ITEM_KEY"]) &&
          a_geo_selected.includes(row["GEO_KEY"]) &&
          a_tactic_selected.includes(row["PRIMARY_TACTIC_ALIAS"]) &&
          a_percentile_selected.includes(row["ITEM_PERCENTILE"])
        ) {
          aNewFilteredData.push(row);
        }
      });

      aLovs = aLovs.map(item => {
        if (item.name === "GEO_KEY") {
          item.values = item.values.map(lov => {
            if (aGeoSet.size > 0) {
              if (aGeoSet.has(lov.value)) {
                lov.displayed = true;
              } else {
                lov.displayed = false;
              }
            }
            return lov;
          });
        } else if (item.name === "ITEM_KEY") {
          item.values = item.values.map(lov => {
            if (aItemSet.size > 0) {
              if (aItemSet.has(lov.value)) {
                lov.displayed = true;
              } else {
                lov.displayed = false;
              }
            }
            return lov;
          });
        } else if (item.name === "PRIMARY_TACTIC_ALIAS") {
          item.values = item.values.map(lov => {
            if (aTacticSet.size > 0) {
              if (aTacticSet.has(lov.value)) {
                lov.displayed = true;
              } else {
                lov.displayed = false;
              }
            }
            return lov;
          });
        } else if (item.name === "ITEM_PERCENTILE") {
          item.values = item.values.map(lov => {
            if (aPercentileSet.size > 0) {
              if (aPercentileSet.has(lov.value)) {
                lov.displayed = true;
              } else {
                lov.displayed = false;
              }
            }
            return lov;
          });
        }
        return item;
      });

      return {
        ...state,
        lovs: aLovs,
        filteredData: aNewFilteredData,
        reInitChart: true
      };
    case DESELECT_ALL_LOVS:
      let dLovs = state.lovs.map(filter => {
        if (filter.name === action.payload.name) {
          filter.values = action.payload.values;
        }

        return filter;
      });

      if (action.payload.name === "GEO_KEY") {
        dLovs[1].values.map(item => {
          item.displayed = false;
          return item;
        });
        dLovs[1].values.push({
          id: -1,
          value: "Select a GEO to see available Items",
          selected: false,
          displayed: true
        });
        dLovs[2].values.map(item => {
          item.displayed = false;
          return item;
        });
        dLovs[2].values.push({
          id: -1,
          value: "Select a GEO to see available ITEM PERCENTILES",
          selected: false,
          displayed: true
        });
        dLovs[3].values.map(item => {
          item.displayed = false;
          return item;
        });
        dLovs[3].values.push({
          id: -1,
          value: "Select a GEO to see available Tactics",
          selected: false,
          displayed: true
        });
      } else if (action.payload.name === "ITEM_KEY") {
        dLovs[0].values.map(item => {
          item.displayed = false;
          return item;
        });
        dLovs[0].values.push({
          id: -1,
          value: "Select an ITEM to see available GEOs",
          selected: false,
          displayed: true
        });
        dLovs[2].values.map(item => {
          item.displayed = false;
          return item;
        });
        dLovs[2].values.push({
          id: -1,
          value: "Select an ITEM to see available ITEM PERCENTILES",
          selected: false,
          displayed: true
        });
        dLovs[3].values.map(item => {
          item.displayed = false;
          return item;
        });
        dLovs[3].values.push({
          id: -1,
          value: "Select an ITEM to see available Tactics",
          selected: false,
          displayed: true
        });
      }
      return {
        ...state,
        lovs: dLovs,
        filteredData: [],
        reInitChart: true
      };

    case SELECT_UNSELECT_LOV:
      let newFilteredData = [];
      let uLovs = state.lovs;
      let geo_selected = [];
      let geo_selected_objects = [];
      let item_selected = [];
      let item_selected_objects = [];
      let tactic_selected = [];
      let tactic_selected_objects = [];
      let percentile_selected = [];
      let percentile_selected_objects = [];
      let itemSet = new Set();
      //let geoSet = new Set();
      let tacticSet = new Set();

      let percentileSet = new Set();
      let selectOne = action.selectOne;

      uLovs = uLovs.map(filter => {
        if (filter.name === action.parentName) {
          filter.values = filter.values.map(value => {
            if (selectOne) {
              if (value.value === action.payload.value) {
                value.selected = !value.selected;
              } else {
                value.selected = false;
              }
            } else {
              if (value.value === action.payload.value) {
                value.selected = !value.selected;
              }
            }
            return value;
          });
        } else {
          //Remove the info filters i.e. select a thing to continue
          filter.values = filter.values.filter(item => {
            if (item.id === -1) {
              return false;
            }
            return true;
          });
          // if(action.parentName === "ITEM_PERCENTILE" && filter.name === "ITEM_KEY"){
          //   uLovs[1].values = state.lovs[1].values.map(item=>{
          //     item.selected = false;
          //     return item;
          //   });
          // }
          // filter.values = filter.values.map(value => {
      
          //   value.selected = false;
          //   return value;
          // });
        }
        return filter;
      });

      // if (removed) {
      //   newFilteredData = state.filteredData.filter(item => {
      //     return item[action.parentName] !== action.payload.value;
      //   });
      // } else {

      if (action.parentName === "GEO_KEY") {
        uLovs[1].values = uLovs[1].values.map(item => {
          if (item.value === action.payload.itemValue[0]) {
            item.selected = true;
          } else {
            item.selected = false;
          }
          return item;
        });
        uLovs[2].values = uLovs[2].values.map(item => {
          if (item.value === action.payload.percentileValue[0]) {
            item.selected = true;
          } else {
            item.selected = false;
          }
          return item;
        });
        uLovs[3].values = uLovs[3].values.map(item => {
          item.selected = true;
          return item;
        });
      } else if (action.parentName === "ITEM_PERCENTILE") {
        uLovs[1].values = uLovs[1].values.map(item => {
          item.selected = false;

          return item;
        });
        uLovs[3].values = uLovs[3].values.map(item => {
          item.selected = true;
          return item;
        });
      }

      geo_selected_objects = uLovs[0].values.filter(item => {
        return item.selected;
      });
      geo_selected = geo_selected_objects.map(item => {
        return item.value;
      });
      tactic_selected_objects = uLovs[3].values.filter(item => {
        return item.selected;
      });
      tactic_selected = tactic_selected_objects.map(item => {
        return item.value;
      });
      item_selected_objects = uLovs[1].values.filter(item => {
        return item.selected;
      });
      item_selected = item_selected_objects.map(item => {
        return item.value;
      });
      percentile_selected_objects = uLovs[2].values.filter(item => {
        return item.selected;
      });
      percentile_selected = percentile_selected_objects.map(item => {
        return item.value;
      });

      if (item_selected.length === 0) {
        for (let x = 0; x < state.data.length; x++) {
          let row = state.data[x];
          if (geo_selected.includes(row["GEO_KEY"])) {
            if (percentile_selected.includes(row["ITEM_PERCENTILE"])) {
              item_selected = row["ITEM_KEY"];
              break;
            }
          }
        }
      }
      if (tactic_selected.length === 0) {
        for (let x = 0; x < state.data.length; x++) {
          let row = state.data[x];
          if (geo_selected.includes(row["GEO_KEY"])) {
            if (percentile_selected.includes(row["ITEM_PERCENTILE"])) {
              if (item_selected.includes(row["ITEM_KEY"])) {
                tactic_selected = row["PRIMARY_TACTIC_ALIAS"];
                break;
              }
            }
          }
        }
      }
      state.data.forEach(row => {
        if (
          item_selected.includes(row["ITEM_KEY"]) &&
          geo_selected.includes(row["GEO_KEY"]) &&
          tactic_selected.includes(row["PRIMARY_TACTIC_ALIAS"]) &&
          percentile_selected.includes(row["ITEM_PERCENTILE"])
        ) {
          itemSet.add(row["ITEM_KEY"]);
          if (row["PRIMARY_TACTIC_ALIAS"] !== undefined) {
            tacticSet.add(row["PRIMARY_TACTIC_ALIAS"]);
          }
          percentileSet.add(row["ITEM_PERCENTILE"]);
          newFilteredData.push(row);
        } else if (geo_selected.includes(row["GEO_KEY"])) {
          geo_selected_objects.forEach(gItem => {
            gItem.percentileValue.forEach(item => {
              percentileSet.add(item);
            });
          });
          if (percentile_selected.includes(row["ITEM_PERCENTILE"])) {
            itemSet.add(row["ITEM_KEY"]);
            if (item_selected.includes(row["ITEM_KEY"])) {
              if (row["PRIMARY_TACTIC_ALIAS"] !== undefined) {
                tacticSet.add(row["PRIMARY_TACTIC_ALIAS"]);
              }
            }
          }
        }
      });
      // }

      uLovs = uLovs.map(item => {
        if (item.name === "GEO_KEY") {
          if (action.parentName !== "GEO_KEY") {
            item.values = item.values.map(lov => {
              lov.displayed = true;
              // if (geoSet.size > 0) {
              //   if (geoSet.has(lov.value)) {
              //     lov.displayed = true;
              //   } else {
              //     lov.displayed = false;
              //   }
              // }
              if (geo_selected.includes(lov.value)) {
                lov.selected = true;
              } else {
                lov.selected = false;
              }
              return lov;
            });
          }
        } else if (item.name === "ITEM_KEY") {
          //if (action.parentName !== "ITEM_KEY") {

          item.values = item.values.map(lov => {
            if (itemSet.size > 0) {
              if (itemSet.has(lov.value)) {
                lov.displayed = true;
              } else {
                lov.displayed = false;
              }
            }
            if (item_selected.includes(lov.value)) {
              lov.selected = true;
            } else {
              lov.selected = false;
            }
            return lov;
          });
          //}
        } else if (item.name === "PRIMARY_TACTIC_ALIAS") {
          item.values = item.values.map(lov => {
            if (tacticSet.size > 0) {
              if (tacticSet.has(lov.value)) {
                lov.displayed = true;
              } else {
                lov.displayed = false;
              }
            }
            if (tactic_selected.includes(lov.value)) {
              lov.selected = true;
            } else {
              lov.selected = false;
            }
            return lov;
          });
        } else if (item.name === "ITEM_PERCENTILE") {
          item.values = item.values.map(lov => {
            if (percentileSet.size > 0) {
              if (percentileSet.has(lov.value)) {
                lov.displayed = true;
              } else {
                lov.displayed = false;
              }
            }
            if (percentile_selected.includes(lov.value)) {
              lov.selected = true;
            } else {
              lov.selected = false;
            }
            return lov;
          });
        }
        return item;
      });

      // if (geo_selected.length === 0 && uLovs[0].values.length > 0) {
      //   uLovs[1].values.map(item => {
      //     item.displayed = false;
      //     return item;
      //   });
      //   uLovs[1].values.push({
      //     id: -1,
      //     value: "Select a GEO to see available Items",
      //     selected: false,
      //     displayed: true
      //   });
      //   uLovs[2].values.map(item => {
      //     item.displayed = false;
      //     return item;
      //   });
      //   uLovs[2].values.push({
      //     id: -1,
      //     value: "Select a GEO to see available ITEM PERCENTILES",
      //     selected: false,
      //     displayed: true
      //   });
      //   uLovs[3].values.map(item => {
      //     item.displayed = false;
      //     return item;
      //   });
      //   uLovs[3].values.push({
      //     id: -1,
      //     value: "Select a GEO to see available Tactics",
      //     selected: false,
      //     displayed: true
      //   });
      // } else if (percentile_selected.length === 0 && uLovs[2].values.length > 0) {
      //   uLovs[1].values.map(item => {
      //     item.displayed = false;
      //     return item;
      //   });
      //   uLovs[1].values.push({
      //     id: -1,
      //     value: "Select an Item Percentile to see available Items",
      //     selected: false,
      //     displayed: true
      //   });

      //   uLovs[3].values.map(item => {
      //     item.displayed = false;
      //     return item;
      //   });
      //   uLovs[3].values.push({
      //     id: -1,
      //     value: "Select an ITEM Percentile to see available Tactics",
      //     selected: false,
      //     displayed: true
      //   });
      // }
      return {
        ...state,
        lovs: uLovs,
        updateFilters: true,
        filteredData: newFilteredData,
        reInitChart: true
      };
    case "SET_UPDATE_FILTER_STATUS":
      return { ...state, updateFilters: action.payload };
    case "SET_SELECTED_BASELINE":
      return { ...state, selectedBaseline: action.payload };
    default:
      return state;
  }
};
