import React, { Component } from "react";
import "components/accucast/components/style/style.css";
import { Link } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.css";
import SecondLevelNavController from "components/accucast/components/updateLE/secondLevelLENavController";
import { connect } from "react-redux";
import { setWorkflowStep } from "components/accucast/components/updateLE/reducers/secondLevelLENavReducer";

class SecondLevelLENav extends Component {
  constructor(props) {
    super(props);
    this.clickHandler = this.clickHandler.bind(this);
  }

  clickHandler(step) {
    this.props.setWorkflowStep(step);
  }
  render() {
    return (
      <div className="ta-second-level-nav-container">
        <ul className="ta-second-level-nav flex-container">
          <div className="flex-container ta-overrides">
            <li>
              <div className="ta-circle">1</div>
              <Link
                onClick={() => this.clickHandler(1)}
                className={
                  "ta-secondary-nav-link " +
                  (this.props.workflow.currentStep === 1 ? "ta-active" : "")
                }
                to="/accucast/le/leWeighting"
              >
                LE Weighting
              </Link>
            </li>
            |
            <li>
              <Link
                onClick={() => this.clickHandler(1.1)}
                to="/accucast/le/leWeightingOverrides"
                className={
                  "ta-link-override ta-secondary-nav-link " +
                  (this.props.workflow.currentStep === 1.1 ? "ta-active" : "")
                }
              >
                Overrides
              </Link>
            </li>
          </div>
          <div className="flex-container ta-overrides">
            <li style={{ marginLeft: "30px" }}>
              <div className="ta-circle">2</div>
              <Link
                onClick={() => this.clickHandler(2)}
                className={
                  "ta-secondary-nav-link " +
                  (this.props.workflow.currentStep === 2 ? "ta-active" : "")
                }
                to="/accucast/le/leSeasonality"
              >
                LE Seasonality
              </Link>
            </li>
            |
            <li>
              <Link
                onClick={() => this.clickHandler(2.1)}
                to="/accucast/le/leSeasonalityOverrides"
                className={
                  "ta-link-override ta-secondary-nav-link " +
                  (this.props.workflow.currentStep === 2.1 ? "ta-active" : "")
                }
              >
                Overrides
              </Link>
            </li>
          </div>
          <li style={{ marginLeft: "30px" }}>
            <div className="ta-circle">3</div>
            <Link
              onClick={() => this.clickHandler(3)}
              className={
                "ta-secondary-nav-link " +
                (this.props.workflow.currentStep === 3 ? "ta-active" : "")
              }
              to="/accucast/le/storeDistribution"
            >
              Store Distribution
            </Link>
          </li>
          <li>
            <div className="ta-circle">4</div>
            <Link
              onClick={() => this.clickHandler(4)}
              className={
                "ta-secondary-nav-link " +
                (this.props.workflow.currentStep === 4 ? "ta-active" : "")
              }
              to="/accucast/le/incrementalLift"
            >
              Incremental Lift
            </Link>
          </li>
          <li>
            <div className="ta-circle">5</div>
            <Link
              onClick={() => this.clickHandler(5)}
              className={
                "ta-secondary-nav-link " +
                (this.props.workflow.currentStep === 5 ? "ta-active" : "")
              }
              to="/accucast/le/shipments"
            >
              Shipments
            </Link>
          </li>
        </ul>
        <SecondLevelNavController {...this.props} />
      </div>
    );
  }
}

const mapStateToProps = state => ({
  workflow: state.accucastLE
});
const mapDispatchToProps = dispatch => ({
  setWorkflowStep(curStep) {
    dispatch(setWorkflowStep(curStep));
  }
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SecondLevelLENav);
