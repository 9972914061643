import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { Switch, Redirect } from "react-router-dom";
import "components/accucast/components/style/style.css";

class AccucastRouter extends Component {
  render() {
    //const { match } = this.props;
    return (
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          height: "100%",
          minHeight: "100%"
        }}
      >
        <Switch>
          <Redirect from="/accucast" exact to={"/accucast/le"} />
          {/* <Route path={match.path+"/le"} render={(props) => <UpdateLE {...props} />} />
          <Route path={match.path+"/plan"} render={(props) => <UpdatePlan {...props} />} /> */}
        </Switch>
      </div>
    );
  }
}

// const mapDispatchToProps = dispatch =>({
//   setWorkflowStep(curStep){
//     dispatch(setWorkflowStep(curStep));
//   }
// });
export default withRouter(AccucastRouter);
