import React from "react";
import PropTypes from "prop-types";
// Icon
export class Bullseye extends React.Component {
  render() {
    return (
      <svg
        className={`${this.props.className}`}
        style={this.props.style}
        width={this.props.width}
        height={this.props.height}
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 512 512">
        <path
          fill={this.props.color}
          d="M256 72c101.689 0 184 82.295 184 184 0 101.689-82.295 184-184 184-101.689 0-184-82.295-184-184 0-101.689 82.295-184 184-184m0-64C119.033 8 8 119.033 8 256s111.033 248 248 248 248-111.033 248-248S392.967 8 256 8zm0 184c35.29 0 64 28.71 64 64s-28.71 64-64 64-64-28.71-64-64 28.71-64 64-64m0-64c-70.692 0-128 57.308-128 128s57.308 128 128 128 128-57.308 128-128-57.308-128-128-128z"
        />
      </svg>
    );
  }
}
Bullseye.propTypes = {
  width: PropTypes.string,
  height: PropTypes.string.isRequired,
  color: PropTypes.string.isRequired
};

export class Disk extends React.Component {
  render() {
    return (
      <svg
        width={this.props.width}
        height={this.props.height}
        aria-hidden="true"
        data-prefix="fas"
        data-icon="save"
        className={`${this.props.className}`}
        role="img"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 448 512">
        <path
          fill={this.props.color}
          d="M433.941 129.941l-83.882-83.882A48 48 0 0 0 316.118 32H48C21.49 32 0 53.49 0 80v352c0 26.51 21.49 48 48 48h352c26.51 0 48-21.49 48-48V163.882a48 48 0 0 0-14.059-33.941zM224 416c-35.346 0-64-28.654-64-64 0-35.346 28.654-64 64-64s64 28.654 64 64c0 35.346-28.654 64-64 64zm96-304.52V212c0 6.627-5.373 12-12 12H76c-6.627 0-12-5.373-12-12V108c0-6.627 5.373-12 12-12h228.52c3.183 0 6.235 1.264 8.485 3.515l3.48 3.48A11.996 11.996 0 0 1 320 111.48z"
        />
      </svg>
    );
  }
}
Disk.propTypes = {
  width: PropTypes.string,
  height: PropTypes.string.isRequired,
  color: PropTypes.string.isRequired
};

export class Gear extends React.Component {
  render() {
    return (
      <svg
        width={this.props.width}
        height={this.props.height}
        style={this.props.style}
        aria-hidden="true"
        data-prefix="fas"
        data-icon="cog"
        className={`svg-inline--fa fa-cog fa-w-16 ${this.props.className}`}
        role="img"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 24 24">
        <path
          fill={this.props.color}
          className={"gear-icon-path " + this.props.className}
          d="M21.5,14.3l2.2,1.2c0.2,0.1,0.4,0.4,0.3,0.7c-0.6,1.8-1.5,3.4-2.8,4.8c-0.2,0.2-0.5,0.3-0.7,0.1l-2.2-1.2
	c-0.9,0.8-1.9,1.4-3.1,1.8v2.5c0,0.3-0.2,0.5-0.5,0.6c-1.8,0.4-3.7,0.4-5.5,0c-0.3-0.1-0.5-0.3-0.5-0.6v-2.5c-1.1-0.4-2.2-1-3.1-1.8
	l-2.2,1.2C3.3,21.3,3,21.2,2.8,21c-1.2-1.4-2.2-3-2.8-4.8c-0.1-0.3,0-0.6,0.3-0.7l2.2-1.2c-0.2-1.2-0.2-2.4,0-3.5L0.3,9.5
	C0.1,9.4-0.1,9.1,0,8.8C0.6,7,1.5,5.4,2.8,4C3,3.8,3.3,3.8,3.5,3.9l2.2,1.2c0.9-0.8,1.9-1.4,3.1-1.8V0.9c0-0.3,0.2-0.5,0.5-0.6
	c1.8-0.4,3.7-0.4,5.5,0c0.3,0.1,0.5,0.3,0.5,0.6v2.5c1.1,0.4,2.2,1,3.1,1.8l2.2-1.2C20.7,3.8,21,3.8,21.2,4c1.2,1.4,2.2,3,2.8,4.8
	c0.1,0.3,0,0.6-0.3,0.7l-2.2,1.2C21.8,11.9,21.8,13.1,21.5,14.3L21.5,14.3z M16,12.5c0-2.2-1.8-4-4-4s-4,1.8-4,4s1.8,4,4,4
	S16,14.8,16,12.5z"
        />
      </svg>
    );
  }
}
Gear.propTypes = {
  width: PropTypes.string,
  height: PropTypes.string.isRequired,
  color: PropTypes.string.isRequired
};

export class AppsIcon extends React.Component {
  render() {
    return (
      <svg
        fill={this.props.color}
        className={`${this.props.className}`}
        height={this.props.height}
        style={this.props.style}
        viewBox={"0 0 24 24"}
        width={this.props.width}
        xmlns="http://www.w3.org/2000/svg">
        <path d="M4 8h4V4H4v4zm6 12h4v-4h-4v4zm-6 0h4v-4H4v4zm0-6h4v-4H4v4zm6 0h4v-4h-4v4zm6-10v4h4V4h-4zm-6 4h4V4h-4v4zm6 6h4v-4h-4v4zm0 6h4v-4h-4v4z" />
        <path d="M0 0h24v24H0z" fill="none" />
      </svg>
    );
  }
}
AppsIcon.propTypes = {
  width: PropTypes.string,
  height: PropTypes.string.isRequired,
  color: PropTypes.string.isRequired
};

export class RightChevron extends React.Component {
  render() {
    return (
      <svg
        fill={this.props.color}
        className={`${this.props.className}`}
        style={this.props.style}
        height={this.props.height}
        width={this.props.width}
        viewBox={this.props.viewBox}
        xmlns="http://www.w3.org/2000/svg">
        <path transform={this.props.transform} d="M10 6L8.59 7.41 13.17 12l-4.58 4.59L10 18l6-6z" />
        <path d="M0 0h24v24H0z" fill="none" />
      </svg>
    );
  }
}
RightChevron.propTypes = {
  width: PropTypes.string,
  height: PropTypes.string.isRequired,
  color: PropTypes.string.isRequired
};

export class CarrotDown extends React.Component {
  render() {
    return (
      <svg
        fill={this.props.color}
        className={this.props.className}
        style={this.props.style}
        height={this.props.height}
        width={this.props.width}
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 320 512">
        <path d="M31.3 192h257.3c17.8 0 26.7 21.5 14.1 34.1L174.1 354.8c-7.8 7.8-20.5 7.8-28.3 0L17.2 226.1C4.6 213.5 13.5 192 31.3 192z" />
      </svg>
    );
  }
}
CarrotDown.propTypes = {
  width: PropTypes.string,
  height: PropTypes.string.isRequired,
  color: PropTypes.string.isRequired
};

export class ReportSvg extends React.Component {
  render() {
    let viewbox = "0 0 24.7 18";
    if (this.props.viewBox) {
      viewbox = this.props.viewBox;
    }
    return (
      <svg
        fill={this.props.color}
        className={`${this.props.className}`}
        style={this.props.style}
        height={this.props.height}
        width={this.props.width}
        viewBox={viewbox}
        //viewBox="0 0 24.7 18"
        xmlns="http://www.w3.org/2000/svg">
        <path
          d="M14.4,18h2.7c0.4,0,0.9-0.4,0.9-0.9V7.3c0-0.4-0.4-0.9-0.9-0.9h-2.7c-0.4,0-0.9,0.4-0.9,0.9v9.8C13.5,17.5,13.9,18,14.4,18z
         M21.1,18h2.7c0.4,0,0.9-0.4,0.9-0.9V0.9c0-0.5-0.4-0.9-0.9-0.9h-2.7c-0.4,0-0.9,0.4-0.9,0.9v16.1C20.2,17.5,20.7,18,21.1,18z
         M0.9,18h2.7c0.4,0,0.9-0.4,0.9-0.9v-4.4c0-0.4-0.4-0.9-0.9-0.9H0.9c-0.4,0-0.9,0.4-0.9,0.9v4.4C0,17.5,0.4,18,0.9,18z M7.6,18h2.7
        c0.4,0,0.9-0.4,0.9-0.9V4.9c0-0.4-0.4-0.8-0.9-0.8H7.6c-0.4,0-0.9,0.4-0.9,0.8v12.1C6.7,17.5,7.2,18,7.6,18z"
        />
      </svg>
    );
  }
}
ReportSvg.propTypes = {
  width: PropTypes.string,
  height: PropTypes.string.isRequired,
  color: PropTypes.string.isRequired
};

export class EllipsisIcon extends React.Component {
  render() {
    return (
      <svg
        fill={this.props.color}
        className={`${this.props.className}`}
        style={this.props.style}
        height={this.props.height}
        width={this.props.width}
        //viewBox={this.props.viewBox}
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 512 512">
        <path
          fill={this.props.color}
          className={this.props.className}
          d="M328 256c0 39.8-32.2 72-72 72s-72-32.2-72-72 32.2-72 72-72 72 32.2 72 72zm104-72c-39.8 0-72 32.2-72 72s32.2 72 72 72 72-32.2 72-72-32.2-72-72-72zm-352 0c-39.8 0-72 32.2-72 72s32.2 72 72 72 72-32.2 72-72-32.2-72-72-72z"
        />
      </svg>
    );
  }
}
EllipsisIcon.propTypes = {
  width: PropTypes.string,
  height: PropTypes.string.isRequired,
  color: PropTypes.string.isRequired
};

export class FullScreenIcon extends React.Component {
  render() {
    return (
      <svg
        fill={this.props.color}
        className="fullscreen-svg"
        style={this.props.style}
        height={this.props.height}
        width={this.props.width}
        //viewBox={this.props.viewBox}
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 20 20">
        <path
          //fill={this.props.color}
          className="fullscreen-path"
          d="M2.9,12.9H0V20h7.1v-2.9H2.9V12.9z M0,7.1h2.9V2.9h4.3V0H0V7.1z M17.1,17.1h-4.3V20H20v-7.1h-2.9V17.1z M12.9,0v2.9h4.3v4.3
          H20V0H12.9z"
        />
      </svg>
    );
  }
}
FullScreenIcon.propTypes = {
  width: PropTypes.string,
  height: PropTypes.string.isRequired,
  color: PropTypes.string.isRequired
};

export class PrintIcon extends React.Component {
  render() {
    return (
      <svg
        fill={this.props.color}
        className={this.props.className}
        style={this.props.style}
        height={this.props.height}
        width={this.props.width}
        //viewBox={this.props.viewBox}
        xmlns="http://www.w3.org/2000/svg"
        viewBox="1 2 22 20">
        <path
          //fill={this.props.color}
          className={this.props.className}
          d="M19 8H5c-1.66 0-3 1.34-3 3v6h4v4h12v-4h4v-6c0-1.66-1.34-3-3-3zm-3 11H8v-5h8v5zm3-7c-.55 0-1-.45-1-1s.45-1 1-1 1 .45 1 1-.45 1-1 1zm-1-9H6v4h12V3z"
        />
      </svg>
    );
  }
}
PrintIcon.propTypes = {
  width: PropTypes.string,
  height: PropTypes.string.isRequired,
  color: PropTypes.string.isRequired
};

export class CloseIcon extends React.Component {
  render() {
    return (
      <svg
        fill={this.props.color}
        className={this.props.className}
        style={this.props.style}
        height={this.props.height}
        width={this.props.width}
        viewBox={this.props.viewBox}
        xmlns="http://www.w3.org/2000/svg">
        <path
          fill={this.props.color}
          className={this.props.className}
          d="M12.9,10l6.3-6.3c0.7-0.7,1.2-1.4,0.5-2.1l-1.3-1.3c-0.7-0.7-1.4-0.2-2.1,0.5L10,7.1L3.7,0.8
          C3,0.1,2.3-0.4,1.6,0.3L0.3,1.6C-0.4,2.3,0.1,3,0.8,3.7L7.1,10l-6.3,6.3c-0.7,0.7-1.2,1.4-0.5,2.1l1.3,1.3c0.7,0.7,1.4,0.2,2.1-0.5
          l6.3-6.3l6.3,6.3c0.7,0.7,1.4,1.2,2.1,0.5l1.3-1.3c0.7-0.7,0.2-1.4-0.5-2.1L12.9,10z"
        />
      </svg>
    );
  }
}
CloseIcon.propTypes = {
  width: PropTypes.string,
  height: PropTypes.string.isRequired
};

export class PowerPointIcon extends React.Component {
  render() {
    return (
      <svg
        fill={this.props.color}
        className={`${this.props.className}`}
        style={this.props.style}
        height={this.props.height}
        width={this.props.width}
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 15 20">
        <path
          className={this.props.className}
          d="M7.6,10.6c0.3,0,0.6,0.1,0.8,0.3c0.4,0.4,0.4,1.3,0,1.7C8.2,12.9,7.9,13,7.5,13H6.5v-2.4H7.6z M14.7,4.1l-3.8-3.8
	C10.7,0.1,10.5,0,10.2,0H10v5h5V4.8C15,4.5,14.9,4.3,14.7,4.1z M8.8,5.3V0H0.9C0.4,0,0,0.4,0,0.9v18.1C0,19.6,0.4,20,0.9,20h13.1
	c0.5,0,0.9-0.4,0.9-0.9V6.3H9.7C9.2,6.3,8.8,5.8,8.8,5.3z M10.8,11.8c0,3.5-3.5,3-4.3,3V17c0,0.3-0.2,0.5-0.5,0.5H4.8
	c-0.3,0-0.5-0.2-0.5-0.5V9.2c0-0.3,0.2-0.5,0.5-0.5H8C9.7,8.8,10.8,10,10.8,11.8z"
        />
      </svg>
    );
  }
}
PowerPointIcon.propTypes = {
  width: PropTypes.string,
  height: PropTypes.string.isRequired,
  color: PropTypes.string.isRequired
};

export class AddSlideIcon extends React.Component {
  render() {
    return (
      <svg
        fill={this.props.color}
        className={`${this.props.className}`}
        style={this.props.style}
        height={this.props.height}
        width={this.props.width}
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 15 20">
        <path
          className={this.props.className}
          //         d="M10,0H2C0.9,0,0,0.9,0,2l0,16c0,1.1,0.9,2,2,2h12c1.1,0,2-0.9,2-2V6L10,0z M12,14H9v3H7v-3H4v-2h3V9h2v3h3V14z M9,7V1.5
          // L14.5,7H9z"
          d="M14.45,3.83l-3.28-3.28C10.82,0.2,10.34,0,9.85,0H1.87C0.84,0,0,0.84,0,1.88v16.25C0,19.16,0.84,20,1.87,20l0,0h11.25
    c1.04,0,1.87-0.84,1.87-1.87l0,0V5.16C15,4.66,14.8,4.18,14.45,3.83z M10,2.03L12.97,5H10V2.03z M13.12,18.13H1.87V1.88h6.25v4.06
    c0,0.52,0.42,0.94,0.93,0.94c0,0,0,0,0,0h4.06V18.13z M8.4,8.74c0-0.35-0.28-0.62-0.62-0.62H7.15c-0.35,0-0.62,0.28-0.62,0.62v2.21
    H4.35c-0.35,0-0.62,0.28-0.62,0.62v0.62c0,0.35,0.28,0.62,0.62,0.62h2.17v2.19c0,0.35,0.28,0.62,0.62,0.62h0.62
    c0.35,0,0.62-0.28,0.62-0.62v-2.19h2.21c0.35,0,0.62-0.28,0.62-0.62v-0.62c0-0.35-0.28-0.62-0.62-0.62H8.4V8.74z"
        />
      </svg>
    );
  }
}
AddSlideIcon.propTypes = {
  width: PropTypes.string,
  height: PropTypes.string.isRequired,
  color: PropTypes.string.isRequired
};

export class DownloadIcon extends React.Component {
  render() {
    return (
      <svg
        fill={this.props.color}
        className={`${this.props.className}`}
        style={this.props.style}
        height={this.props.height}
        width={this.props.width}
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 20 20">
        <path
          className={this.props.className}
          d="M8.4,0h3.1c0.5,0,0.9,0.4,0.9,0.9v6.6h3.4c0.7,0,1,0.8,0.6,1.3l-5.9,5.9c-0.3,0.3-0.8,0.3-1.1,0L3.5,8.8
	C3,8.3,3.4,7.5,4.1,7.5h3.4V0.9C7.5,0.4,7.9,0,8.4,0z M20,14.7v4.4c0,0.5-0.4,0.9-0.9,0.9H0.9C0.4,20,0,19.6,0,19.1v-4.4
	c0-0.5,0.4-0.9,0.9-0.9h5.7l1.9,1.9c0.8,0.8,2.1,0.8,2.8,0l1.9-1.9h5.7C19.6,13.8,20,14.2,20,14.7z M15.2,18.1
	c0-0.4-0.4-0.8-0.8-0.8s-0.8,0.4-0.8,0.8s0.4,0.8,0.8,0.8S15.2,18.6,15.2,18.1z M17.7,18.1c0-0.4-0.4-0.8-0.8-0.8s-0.8,0.4-0.8,0.8
	s0.4,0.8,0.8,0.8S17.7,18.6,17.7,18.1z"
        />
      </svg>
    );
  }
}
DownloadIcon.propTypes = {
  width: PropTypes.string,
  height: PropTypes.string.isRequired,
  color: PropTypes.string.isRequired
};

export class DeleteIcon extends React.Component {
  render() {
    return (
      <svg
        fill={this.props.color}
        className={`${this.props.className}`}
        style={this.props.style}
        height={this.props.height}
        width={this.props.width}
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 18 20">
        <path
          className={this.props.className}
          d="M0,3.3V2.2c0-0.5,0.4-0.9,0.9-0.9h4.4l0.4-0.7C5.8,0.2,6.2,0,6.5,0H11c0.4,0,0.7,0.2,0.8,0.5l0.4,0.7h4.4
	c0.5,0,0.9,0.4,0.9,0.9v1.1c0,0.3-0.2,0.5-0.5,0.5H0.5C0.2,3.8,0,3.5,0,3.3z M16.3,5.5v12.7c0,1-0.8,1.9-1.9,1.9H3.1
	c-1,0-1.9-0.8-1.9-1.9V5.5C1.3,5.2,1.5,5,1.7,5h14.1C16,5,16.3,5.2,16.3,5.5z M5.6,8.1c0-0.3-0.3-0.6-0.6-0.6S4.4,7.8,4.4,8.1v8.8
	c0,0.3,0.3,0.6,0.6,0.6s0.6-0.3,0.6-0.6V8.1z M9.4,8.1c0-0.3-0.3-0.6-0.6-0.6S8.1,7.8,8.1,8.1v8.8c0,0.3,0.3,0.6,0.6,0.6
	s0.6-0.3,0.6-0.6V8.1z M13.1,8.1c0-0.3-0.3-0.6-0.6-0.6c-0.3,0-0.6,0.3-0.6,0.6v8.8c0,0.3,0.3,0.6,0.6,0.6c0.3,0,0.6-0.3,0.6-0.6
	V8.1z"
        />
      </svg>
    );
  }
}
DeleteIcon.propTypes = {
  width: PropTypes.string,
  height: PropTypes.string.isRequired,
  color: PropTypes.string.isRequired
};

export class FolderIcon extends React.Component {
  render() {
    return (
      <svg
        fill={this.props.color}
        className={`${this.props.className}`}
        style={this.props.style}
        height={this.props.height}
        width={this.props.width}
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 20 15">
        <path
          className={this.props.className}
          d="M18.1,2.5h-7.5L8.5,0.4C8.3,0.1,7.9,0,7.6,0H1.9C0.8,0,0,0.8,0,1.9v11.3c0,1,0.8,1.9,1.9,1.9h16.3c1,0,1.9-0.8,1.9-1.9V4.4
          C20,3.3,19.2,2.5,18.1,2.5z M18.1,13.1H1.9V1.9h5.5L9.5,4c0.2,0.2,0.6,0.4,0.9,0.4h7.8V13.1z"
        />
      </svg>
    );
  }
}
FolderIcon.propTypes = {
  width: PropTypes.string,
  height: PropTypes.string.isRequired,
  color: PropTypes.string.isRequired
};

export class CheckmarkIcon extends React.Component {
  render() {
    return (
      <svg
        fill={this.props.color}
        className={`${this.props.className}`}
        style={this.props.style}
        height={this.props.height}
        width={this.props.width}
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 20 15">
        <path
          className={this.props.className}
          d="M6.8,14.6L0.3,8.1c-0.4-0.4-0.4-1,0-1.4l1.4-1.4c0.4-0.4,1-0.4,1.4,0l4.4,4.4l9.4-9.4c0.4-0.4,1-0.4,1.4,0l1.4,1.4
          c0.4,0.4,0.4,1,0,1.4L8.2,14.6C7.8,15,7.2,15,6.8,14.6L6.8,14.6z"
        />
      </svg>
    );
  }
}
CheckmarkIcon.propTypes = {
  width: PropTypes.string,
  height: PropTypes.string.isRequired,
  color: PropTypes.string.isRequired
};

export class UserIcon extends React.Component {
  render() {
    return (
      <svg
        fill={this.props.color}
        className={`${this.props.className}`}
        style={this.props.style}
        height={this.props.height}
        width={this.props.width}
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 24 24">
        <path
          className={this.props.className}
          d="M12,0C5.4,0,0,5.4,0,12s5.4,12,12,12s12-5.4,12-12S18.6,0,12,0z M12,4.6c2.4,0,4.3,1.9,4.3,4.3s-1.9,4.3-4.3,4.3
          s-4.3-1.9-4.3-4.3S9.6,4.6,12,4.6z M12,21.3c-2.8,0-5.4-1.3-7.1-3.3c0.9-1.7,2.7-2.9,4.8-2.9c0.1,0,0.2,0,0.3,0.1
          c0.6,0.2,1.3,0.3,2,0.3s1.4-0.1,2-0.3c0.1,0,0.2-0.1,0.3-0.1c2.1,0,3.9,1.2,4.8,2.9C17.4,20,14.8,21.3,12,21.3z"
        />
      </svg>
    );
  }
}
UserIcon.propTypes = {
  width: PropTypes.string,
  height: PropTypes.string.isRequired,
  color: PropTypes.string.isRequired
};

export class ExcelIcon extends React.Component {
  render() {
    return (
      <svg
        fill={this.props.color}
        className={`${this.props.className} excel-icon`}
        style={this.props.style}
        height={this.props.height}
        width={this.props.width}
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 16 20">
        <path
          className={this.props.className}
          d="M8.8,5.3V0H0.9C0.4,0,0,0.4,0,0.9c0,0,0,0,0,0v18.1C0,19.6,0.4,20,0.9,20c0,0,0,0,0,0h13.1
          c0.5,0,0.9-0.4,0.9-0.9c0,0,0,0,0,0V6.3H9.7C9.2,6.2,8.8,5.8,8.8,5.3z M11.1,9.5l-2.4,3.7l2.4,3.7c0.1,0.2,0.1,0.5-0.1,0.6
          c-0.1,0-0.2,0.1-0.3,0.1H9.3c-0.2,0-0.3-0.1-0.4-0.2c-0.8-1.4-1.4-2.7-1.4-2.7c-0.3,0.6-0.4,0.8-1.4,2.7c-0.1,0.2-0.2,0.2-0.4,0.2
          H4.3c-0.3,0-0.5-0.2-0.5-0.5c0-0.1,0-0.2,0.1-0.3l2.4-3.7L3.9,9.5C3.8,9.3,3.8,9,4,8.8c0.1-0.1,0.2-0.1,0.3-0.1h1.4
          C5.8,8.8,6,8.8,6.1,9c1,1.9,0.8,1.3,1.4,2.7c0,0,0.2-0.5,1.4-2.7C9,8.8,9.2,8.8,9.3,8.8h1.4c0.3,0,0.5,0.2,0.5,0.5
          C11.2,9.3,11.1,9.4,11.1,9.5L11.1,9.5z M15,4.8V5h-5V0h0.2c0.2,0,0.5,0.1,0.7,0.3l3.8,3.8C14.9,4.3,15,4.5,15,4.8z"
        />
      </svg>
    );
  }
}
ExcelIcon.propTypes = {
  width: PropTypes.string,
  height: PropTypes.string.isRequired,
  color: PropTypes.string.isRequired
};

export class MaintIcon extends React.Component {
  render() {
    return (
      <svg
        fill={this.props.color}
        className={`${this.props.className}`}
        style={this.props.style}
        height={this.props.height}
        width={this.props.width}
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 20 20">
        <path
          className={this.props.className}
          d="M19.8,4.3c-0.1-0.3-0.3-0.4-0.6-0.3C19.2,4,19.1,4,19,4.1L16.1,7l-2.7-0.4L13,3.9L15.9,1
          c0.2-0.2,0.2-0.5,0-0.7c-0.1-0.1-0.1-0.1-0.2-0.1c-3-0.8-6,1.1-6.8,4.1C8.7,5.3,8.7,6.4,9,7.4l-8.3,8.3c-1,1-0.9,2.6,0.1,3.5
          c1,0.9,2.5,0.9,3.5,0l8.3-8.3c2.9,1,6.1-0.6,7.1-3.5C20,6.4,20.1,5.3,19.8,4.3L19.8,4.3z M2.5,18.5c-0.5,0-0.9-0.4-0.9-0.9
          s0.4-0.9,0.9-0.9s0.9,0.4,0.9,0.9C3.4,18,3,18.5,2.5,18.5z"
        />
      </svg>
    );
  }
}
MaintIcon.propTypes = {
  width: PropTypes.string,
  height: PropTypes.string.isRequired,
  color: PropTypes.string.isRequired
};

export class MarketingIcon extends React.Component {
  render() {
    return (
      <svg
        fill={this.props.color}
        className={`${this.props.className}`}
        style={this.props.style}
        height={this.props.height}
        width={this.props.width}
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 20 20">
        <path
          className={this.props.className}
          d="M19.8,4.3c-0.1-0.3-0.3-0.4-0.6-0.3C19.2,4,19.1,4,19,4.1L16.1,7l-2.7-0.4L13,3.9L15.9,1
          c0.2-0.2,0.2-0.5,0-0.7c-0.1-0.1-0.1-0.1-0.2-0.1c-3-0.8-6,1.1-6.8,4.1C8.7,5.3,8.7,6.4,9,7.4l-8.3,8.3c-1,1-0.9,2.6,0.1,3.5
          c1,0.9,2.5,0.9,3.5,0l8.3-8.3c2.9,1,6.1-0.6,7.1-3.5C20,6.4,20.1,5.3,19.8,4.3L19.8,4.3z M2.5,18.5c-0.5,0-0.9-0.4-0.9-0.9
          s0.4-0.9,0.9-0.9s0.9,0.4,0.9,0.9C3.4,18,3,18.5,2.5,18.5z"
        />
      </svg>
    );
  }
}
MarketingIcon.propTypes = {
  width: PropTypes.string,
  height: PropTypes.string.isRequired,
  color: PropTypes.string.isRequired
};

export class StarSolidIcon extends React.Component {
  render() {
    return (
      <svg
        fill={this.props.color}
        className={`${this.props.className}`}
        style={this.props.style}
        height={this.props.height}
        width={this.props.width}
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 20 20">
        <path
          className={this.props.className}
          d="M8.9,0.7L6.5,5.6L1,6.4c-1,0.1-1.4,1.3-0.7,2l4,3.9l-0.9,5.4c-0.2,1,0.9,1.7,1.7,1.3l4.9-2.6l4.9,2.6
          c0.9,0.5,1.9-0.3,1.7-1.3l-0.9-5.4l4-3.9c0.7-0.7,0.3-1.9-0.7-2l-5.5-0.8l-2.4-4.9C10.6-0.2,9.4-0.2,8.9,0.7L8.9,0.7z"
        />
      </svg>
    );
  }
}
StarSolidIcon.propTypes = {
  width: PropTypes.string,
  height: PropTypes.string.isRequired,
  color: PropTypes.string.isRequired
};

export class StarIcon extends React.Component {
  render() {
    return (
      <svg
        fill={this.props.color}
        className={`${this.props.className}`}
        style={this.props.style}
        height={this.props.height}
        width={this.props.width}
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 20 20">
        <path
          className={this.props.className}
          d="M19,6.4l-5.5-0.8l-2.4-4.9c-0.4-0.9-1.7-0.9-2.1,0L6.5,5.6L1,6.4c-1,0.1-1.4,1.3-0.7,2l4,3.9l-0.9,5.4
          c-0.2,1,0.9,1.7,1.7,1.3l4.9-2.6l4.9,2.6c0.9,0.5,1.9-0.3,1.7-1.3l-0.9-5.4l4-3.9C20.3,7.8,20,6.6,19,6.4L19,6.4z M13.8,11.7
          l0.9,5.2L10,14.4l-4.6,2.4l0.9-5.2L2.5,8l5.2-0.8L10,2.5l2.3,4.7L17.5,8L13.8,11.7L13.8,11.7z"
        />
      </svg>
    );
  }
}
StarIcon.propTypes = {
  width: PropTypes.string,
  height: PropTypes.string.isRequired,
  color: PropTypes.string.isRequired
};

export class Pen extends React.Component {
  render() {
    return (
      <svg
        fill={this.props.color}
        width={this.props.width}
        height={this.props.height}
        style={this.props.style}
        className={`${this.props.className}`}
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 20 20">
        <path
          d="M11.4,3.6l5,5L5.5,19.5L1,20c-0.6,0.1-1.1-0.4-1-1l0.5-4.5C0.5,14.5,11.4,3.6,11.4,3.6z M19.5,2.9l-2.3-2.3
          c-0.7-0.7-1.9-0.7-2.7,0l-2.2,2.2l5,5l2.2-2.2C20.2,4.8,20.2,3.6,19.5,2.9z"
        />
      </svg>
    );
  }
}
Pen.propTypes = {
  width: PropTypes.string,
  height: PropTypes.string.isRequired,
  color: PropTypes.string.isRequired
};

export class DefaultDisplaySize extends React.Component {
  render() {
    return (
      <svg
        width={this.props.width}
        height={this.props.height}
        style={this.props.style}
        className="default-display-svg"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 20 20">
        <path
          fill={this.props.color}
          className="default-display-path"
          d="M18.3,0H1.7C0.7,0,0,0.7,0,1.7c0,0,0,0,0,0v10c0,0.9,0.7,1.7,1.7,1.7h16.7
          c0.9,0,1.7-0.7,1.7-1.7v-10C20,0.7,19.3,0,18.3,0z M18.1,11.7H1.9c-0.1,0-0.2-0.1-0.2-0.2V1.9c0-0.1,0.1-0.2,0.2-0.2h16.3
          c0.1,0,0.2,0.1,0.2,0.2v9.6C18.3,11.6,18.2,11.7,18.1,11.7z M16.7,16.9c0,0.5-0.4,0.8-0.8,0.8c0,0,0,0,0,0H4.2
          c-0.5,0-0.8-0.4-0.8-0.8s0.4-0.8,0.8-0.8h3.4l0.6-1.9c0.1-0.2,0.2-0.3,0.4-0.3h2.7c0.2,0,0.3,0.1,0.4,0.3l0.6,1.9h3.4
          C16.3,16.1,16.7,16.5,16.7,16.9L16.7,16.9z"
        />
      </svg>
    );
  }
}
DefaultDisplaySize.propTypes = {
  width: PropTypes.string,
  height: PropTypes.string.isRequired,
  color: PropTypes.string.isRequired
};

export class ActualSize extends React.Component {
  render() {
    return (
      <svg
        width={this.props.width}
        height={this.props.height}
        style={this.props.style}
        className="actual-size-svg"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 20 20">
        <path
          fill={this.props.color}
          className="actual-size-path"
          d="M17.9,0H2.1C1,0,0,1,0,2.1v15.7C0,19,1,20,2.1,20h15.7c1.2,0,2.1-1,2.1-2.1V2.1C20,1,19,0,17.9,0z M18.6,17.9
          c0,0.4-0.3,0.7-0.7,0.7H2.1c-0.4,0-0.7-0.3-0.7-0.7V2.1c0-0.4,0.3-0.7,0.7-0.7h15.7c0.4,0,0.7,0.3,0.7,0.7V17.9z M10.7,7.6c-0.5,0-0.9,0.4-0.9,0.9c0,0.5,0.4,0.9,0.9,0.9c0.6,0,0.9-0.4,0.9-0.9C11.6,8,11.2,7.6,10.7,7.6z M13.7,6 13.9,7 15.6,6.1 15.6,6.1 15.6,14.5 16.9,14.5 16.9,4.9 15.8,4.9z M2.9,6 3.2,7 4.9,6.1 4.9,6.1 4.9,14.5 6.2,14.5 6.2,4.9 5.1,4.9z M10.7,12.8c-0.5,0-0.9,0.4-0.9,0.9c0,0.5,0.4,0.9,0.9,0.9c0.6,0,0.9-0.4,0.9-0.9
          C11.6,13.2,11.2,12.8,10.7,12.8z"
        />
      </svg>
    );
  }
}
ActualSize.propTypes = {
  width: PropTypes.string,
  height: PropTypes.string.isRequired,
  color: PropTypes.string.isRequired
};

export class FitToWidth extends React.Component {
  render() {
    return (
      <svg
        width={this.props.width}
        height={this.props.height}
        style={this.props.style}
        className="fit-to-width-svg"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 -5 20 20">
        <path
          fill={this.props.color}
          className="fit-to-width-path"
          d="M13.6,0.9c-0.2,0.2-0.2,0.5,0,0.7c0,0,0,0,0,0l2.9,2.7H3.6l2.9-2.7c0.2-0.2,0.2-0.5,0-0.7
          c0,0,0,0,0,0L5.7,0.1C5.5,0,5.2,0,5,0.1L0.1,5C0,5.2,0,5.5,0.1,5.7L5,10.5c0.2,0.2,0.5,0.2,0.7,0l0.8-0.8c0.2-0.2,0.2-0.5,0-0.7
          c0,0,0,0,0,0L3.6,6.3h12.9l-2.9,2.7c-0.2,0.2-0.2,0.5,0,0.7c0,0,0,0,0,0l0.8,0.8c0.2,0.2,0.5,0.2,0.7,0l4.9-4.9
          C20,5.5,20,5.2,19.9,5L15,0.1c-0.2-0.2-0.5-0.2-0.7,0L13.6,0.9z"
        />
      </svg>
    );
  }
}
FitToWidth.propTypes = {
  width: PropTypes.string,
  height: PropTypes.string.isRequired,
  color: PropTypes.string.isRequired
};

export class FitToPage extends React.Component {
  render() {
    return (
      <svg
        width={this.props.width}
        height={this.props.height}
        style={this.props.style}
        className="fit-to-page-svg"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 20 20">
        <path
          fill={this.props.color}
          className="fit-to-page-path"
          d="M20,13.4v6.1c0,0.3-0.2,0.5-0.5,0.5h-6.1c-0.3,0-0.5-0.2-0.5-0.5v-1.2
          c0-0.3,0.2-0.5,0.5-0.5c0,0,0,0,0,0l2.7,0.1l0.1-0.1L10,11.5l-6.2,6.2l0.1,0.1l2.7-0.1c0.3,0,0.5,0.2,0.5,0.5c0,0,0,0,0,0v1.2
          c0,0.3-0.2,0.5-0.5,0.5c0,0,0,0,0,0H0.5C0.2,20,0,19.8,0,19.5v-6.1c0-0.3,0.2-0.5,0.5-0.5h1.2c0.3,0,0.5,0.2,0.5,0.5c0,0,0,0,0,0
          l-0.1,2.7l0.1,0.1L8.5,10L2.3,3.8L2.2,3.9l0.1,2.7c0,0.3-0.2,0.5-0.5,0.5c0,0,0,0,0,0H0.5C0.2,7.1,0,6.9,0,6.6c0,0,0,0,0,0V0.5
          C0,0.2,0.2,0,0.5,0h6.1c0.3,0,0.5,0.2,0.5,0.5v1.2c0,0.3-0.2,0.5-0.5,0.5c0,0,0,0,0,0L3.9,2.2L3.8,2.3L10,8.5l6.2-6.2l-0.1-0.1
          l-2.7,0.1c-0.3,0-0.5-0.2-0.5-0.5c0,0,0,0,0,0V0.5c0-0.3,0.2-0.5,0.5-0.5c0,0,0,0,0,0h6.1C19.8,0,20,0.2,20,0.5v6.1
          c0,0.3-0.2,0.5-0.5,0.5h-1.2c-0.3,0-0.5-0.2-0.5-0.5c0,0,0,0,0,0l0.1-2.7l-0.1-0.1L11.5,10l6.2,6.2l0.1-0.1l-0.1-2.7
          c0-0.3,0.2-0.5,0.5-0.5c0,0,0,0,0,0h1.2C19.8,12.9,20,13.1,20,13.4C20,13.4,20,13.4,20,13.4z"
        />
      </svg>
    );
  }
}
FitToPage.propTypes = {
  width: PropTypes.string,
  height: PropTypes.string.isRequired,
  color: PropTypes.string.isRequired
};

export class Help extends React.Component {
  render() {
    return (
      <svg
        width={this.props.width}
        height={this.props.height}
        style={this.props.style}
        className="help-svg"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 20 20">
        <path
          fill={this.props.color}
          className="help-svg-path"
          d="M20,10c0,5.5-4.5,10-10,10S0,15.5,0,10C0,4.5,4.5,0,10,0S20,4.5,20,10z M10.3,3.3c-2.2,0-3.6,0.9-4.7,2.6
          C5.4,6.1,5.5,6.4,5.7,6.5l1.4,1.1c0.2,0.2,0.5,0.1,0.7-0.1C8.5,6.6,9,6.1,10.1,6.1c0.8,0,1.8,0.5,1.8,1.3c0,0.6-0.5,0.9-1.3,1.4
          C9.6,9.3,8.4,10,8.4,11.6v0.2c0,0.3,0.2,0.5,0.5,0.5h2.3c0.3,0,0.5-0.2,0.5-0.5v-0.1c0-1.1,3.4-1.2,3.4-4.3
          C15,5.1,12.5,3.3,10.3,3.3z M10,13.3c-1,0-1.9,0.8-1.9,1.9c0,1,0.8,1.9,1.9,1.9s1.9-0.8,1.9-1.9C11.9,14.1,11,13.3,10,13.3z"
        />
      </svg>
    );
  }
}
Help.propTypes = {
  width: PropTypes.string,
  height: PropTypes.string.isRequired,
  color: PropTypes.string.isRequired
};

export class TableauViewIcon extends React.Component {
  render() {
    return (
      <svg
        width={this.props.width}
        height={this.props.height}
        style={this.props.style}
        className="tableau-view-svg"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 20 17.5">
        <path
          fill={this.props.color}
          className="tableau-view-path viewList"
          d="M20,4.4v11.3c0,1-0.8,1.9-1.9,1.9H1.9c-1,0-1.9-0.8-1.9-1.9V4.4c0-1,0.8-1.9,1.9-1.9h3.4l0.5-1.3C6.1,0.5,6.8,0,7.5,0h4.9
          c0.8,0,1.5,0.5,1.8,1.2l0.5,1.3h3.4C19.2,2.5,20,3.3,20,4.4z M14.7,10c0-2.6-2.1-4.7-4.7-4.7S5.3,7.4,5.3,10s2.1,4.7,4.7,4.7
          S14.7,12.6,14.7,10z M13.4,10c0,1.9-1.5,3.4-3.4,3.4S6.6,11.9,6.6,10S8.1,6.6,10,6.6S13.4,8.1,13.4,10z"
        />
      </svg>
    );
  }
}
TableauViewIcon.propTypes = {
  width: PropTypes.string,
  height: PropTypes.string.isRequired,
  color: PropTypes.string.isRequired
};

export class ExternalLink extends React.Component {
  render() {
    return (
      <svg
        fill={this.props.color}
        className={`${this.props.className}`}
        style={this.props.style}
        height={this.props.height}
        width={this.props.width}
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 18 20">
        <path
          className={this.props.className}
          d="M20,2.1v15.7c0,1.2-1,2.1-2.1,2.1H2.1C1,20,0,19,0,17.9V2.1C0,1,1,0,2.1,0h15.7C19,0,20,1,20,2.1z M17.1,4.2	c0-0.8-0.6-1.4-1.4-1.4H9.9c-0.6,0-1,0.5-1,1v0.7c0,0.6,0.5,1.1,1.1,1l2.5-0.1l-9.3,9.2c-0.4,0.3-0.4,0.9,0,1.3L4,16.9 c0.3,0.3,0.9,0.3,1.3,0l9.2-9.3l-0.1,2.5c0,0.6,0.5,1.1,1,1.1h0.7c0.6,0,1-0.5,1-1V4.2z"
        />
      </svg>
    );
  }
}
ExternalLink.propTypes = {
  width: PropTypes.string,
  height: PropTypes.string.isRequired,
  color: PropTypes.string.isRequired
};

export class FinalBaseline extends React.Component {
  render() {
    return (
      <svg
        fill={this.props.color}
        className={`${this.props.className}`}
        style={this.props.style}
        height={this.props.height}
        width={this.props.width}
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 12 16">
        <path
          className={this.props.className}
          d="M3,11.3c-0.3-0.3-0.1-0.2-0.8-0.4c-0.3-0.1-0.6-0.2-0.8-0.4L0,14c-0.1,0.3,0.1,0.7,0.5,0.7l1.6-0.1l1.1,1.2
          C3.5,16.1,4,16,4.1,15.7l1.6-4C5.4,11.9,5,12,4.6,12C4,12,3.5,11.8,3,11.3z M12,14l-1.4-3.5c-0.2,0.2-0.5,0.3-0.8,0.4
          c-0.7,0.2-0.5,0.1-0.8,0.4C8.5,11.8,8,12,7.4,12c-0.4,0-0.8-0.1-1.1-0.3l1.6,4C8,16,8.5,16.1,8.7,15.8l1.1-1.2l1.6,0.1
          C11.8,14.7,12.1,14.4,12,14z M8.2,10.6c0.5-0.5,0.5-0.4,1.2-0.6c0.4-0.1,0.8-0.5,0.9-0.9c0.2-0.9,0.2-0.8,0.8-1.4
          c0.3-0.3,0.4-0.8,0.3-1.2c-0.2-0.9-0.2-0.8,0-1.7c0.1-0.4,0-0.9-0.3-1.2c-0.6-0.6-0.6-0.5-0.8-1.4c-0.1-0.4-0.5-0.8-0.9-0.9
          C8.6,1,8.7,1,8,0.4C7.7,0.1,7.2-0.1,6.8,0C5.9,0.3,6.1,0.3,5.2,0C4.8-0.1,4.3,0.1,4,0.4C3.3,1,3.4,1,2.6,1.2
          C2.1,1.3,1.8,1.7,1.7,2.1C1.4,3,1.5,2.9,0.9,3.5C0.6,3.9,0.4,4.3,0.5,4.8c0.2,0.9,0.2,0.8,0,1.7c-0.1,0.4,0,0.9,0.3,1.2
          c0.6,0.6,0.6,0.5,0.8,1.4C1.8,9.5,2.1,9.9,2.6,10c0.7,0.2,0.8,0.2,1.2,0.6c0.4,0.4,1.1,0.5,1.6,0.2c0.4-0.3,0.9-0.3,1.3,0
          C7.2,11.1,7.8,11,8.2,10.6z M3.1,5.5c0-1.7,1.3-3,2.9-3s2.9,1.3,2.9,3s-1.3,3-2.9,3S3.1,7.2,3.1,5.5L3.1,5.5z"
        />
      </svg>
    );
  }
}
FinalBaseline.propTypes = {
  width: PropTypes.string,
  height: PropTypes.string.isRequired,
  color: PropTypes.string.isRequired
};

export class ItemAttributes extends React.Component {
  render() {
    return (
      <svg
        fill={this.props.color}
        className={`${this.props.className}`}
        style={this.props.style}
        height={this.props.height}
        width={this.props.width}
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 20 20">
        <path
          className={this.props.className}
          d="M0,9.8v-8C0,0.8,0.8,0,1.9,0h8c0.5,0,1,0.2,1.3,0.5l8.3,8.3c0.7,0.7,0.7,1.9,0,2.7l-8,8c-0.7,0.7-1.9,0.7-2.7,0l-8.3-8.3
          C0.2,10.8,0,10.3,0,9.8z M4.4,2.5c-1,0-1.9,0.8-1.9,1.9s0.8,1.9,1.9,1.9s1.9-0.8,1.9-1.9S5.4,2.5,4.4,2.5z"
        />
      </svg>
    );
  }
}
ItemAttributes.propTypes = {
  width: PropTypes.string,
  height: PropTypes.string.isRequired,
  color: PropTypes.string.isRequired
};

export class GeoAttributes extends React.Component {
  render() {
    return (
      <svg
        fill={this.props.color}
        className={`${this.props.className}`}
        style={this.props.style}
        height={this.props.height}
        width={this.props.width}
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 20 20">
        <path
          className={this.props.className}
          d="M13.6,6.1C13,2.5,11.6,0,10,0S7,2.5,6.4,6.1H13.6z M6.1,10c0,0.9,0,1.8,0.1,2.6h7.5c0.1-0.8,0.1-1.7,0.1-2.6s0-1.8-0.1-2.6
          H6.3C6.2,8.2,6.1,9.1,6.1,10z M19.2,6.1c-1.2-2.7-3.5-4.9-6.4-5.7c1,1.4,1.7,3.4,2,5.7H19.2z M7.1,0.4c-2.9,0.9-5.2,3-6.4,5.7h4.4
          C5.5,3.8,6.2,1.8,7.1,0.4L7.1,0.4z M19.7,7.4H15c0.1,0.8,0.1,1.7,0.1,2.6s0,1.7-0.1,2.6h4.6c0.2-0.8,0.3-1.7,0.3-2.6
          S19.9,8.2,19.7,7.4L19.7,7.4z M4.8,10c0-0.9,0-1.7,0.1-2.6H0.3C0.1,8.2,0,9.1,0,10s0.1,1.8,0.3,2.6H5C4.9,11.7,4.8,10.9,4.8,10z
           M6.4,13.9C7,17.5,8.4,20,10,20s3-2.5,3.6-6.1H6.4z M12.9,19.6c2.9-0.9,5.2-3,6.4-5.7h-4.4C14.5,16.2,13.8,18.2,12.9,19.6z
           M0.8,13.9c1.2,2.7,3.5,4.9,6.4,5.7c-1-1.4-1.7-3.4-2-5.7C5.1,13.9,0.8,13.9,0.8,13.9z"
        />
      </svg>
    );
  }
}
GeoAttributes.propTypes = {
  width: PropTypes.string,
  height: PropTypes.string.isRequired,
  color: PropTypes.string.isRequired
};

export class XMarker extends React.Component {
  render() {
    return (
      <svg
        fill={this.props.color}
        className={`${this.props.className}`}
        style={this.props.style}
        height={this.props.height}
        width={this.props.width}
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 12 12">
        <path
          className={this.props.className}
          d="M8.275,86l3.411-3.411a1.072,1.072,0,0,0,0-1.516l-.758-.758a1.072,1.072,0,0,0-1.516,0L6,83.725,2.589,80.314a1.072,1.072,0,0,0-1.516,0l-.758.758a1.072,1.072,0,0,0,0,1.516L3.725,86,.314,89.411a1.072,1.072,0,0,0,0,1.516l.758.758a1.072,1.072,0,0,0,1.516,0L6,88.275l3.411,3.411a1.072,1.072,0,0,0,1.516,0l.758-.758a1.072,1.072,0,0,0,0-1.516Z"
        />
      </svg>
    );
  }
}
XMarker.propTypes = {
  width: PropTypes.string,
  height: PropTypes.string.isRequired,
  color: PropTypes.string.isRequired
};

export class PlusMarker extends React.Component {
  render() {
    return (
      <svg
        fill={this.props.color}
        className={`${this.props.className}`}
        style={this.props.style}
        height={this.props.height}
        width={this.props.width}
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 20 20">
        <path
          className={this.props.className}
          d="M11.143,36.714H7.286V32.857A.857.857,0,0,0,6.429,32H5.571a.857.857,0,0,0-.857.857v3.857H.857A.857.857,0,0,0,0,37.571v.857a.857.857,0,0,0,.857.857H4.714v3.857A.857.857,0,0,0,5.571,44h.857a.857.857,0,0,0,.857-.857V39.286h3.857A.857.857,0,0,0,12,38.429v-.857A.857.857,0,0,0,11.143,36.714Z"
        />
      </svg>
    );
  }
}
PlusMarker.propTypes = {
  width: PropTypes.string,
  height: PropTypes.string.isRequired,
  color: PropTypes.string.isRequired
};

export class DiamondMarker extends React.Component {
  render() {
    return (
      <svg
        fill={this.props.color}
        className={`${this.props.className}`}
        style={this.props.style}
        height={this.props.height}
        width={this.props.width}
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 10.503 12">
        <path
          className={this.props.className}
          d="M5.628.17a.564.564,0,0,0-.853,0L.087,5.608a.559.559,0,0,0,0,.734L4.775,11.78a.564.564,0,0,0,.853,0l4.688-5.438a.559.559,0,0,0,0-.734L5.628.17Z"
        />
      </svg>
    );
  }
}
DiamondMarker.propTypes = {
  width: PropTypes.string,
  height: PropTypes.string.isRequired,
  color: PropTypes.string.isRequired
};

export class TriangleMarker extends React.Component {
  render() {
    return (
      <svg
        fill={this.props.color}
        className={`${this.props.className}`}
        style={this.props.style}
        height={this.props.height}
        width={this.props.width}
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 12 10.666">
        <path
          className={this.props.className}
          d="M6.893.5A1,1,0,0,0,5.159.5l-5,8.666a1,1,0,0,0,.867,1.5h10a1,1,0,0,0,.867-1.5L6.893.5Z"
        />
      </svg>
    );
  }
}
TriangleMarker.propTypes = {
  width: PropTypes.string,
  height: PropTypes.string.isRequired,
  color: PropTypes.string.isRequired
};

export class ProjectIcon extends React.Component {
  render() {
    return (
      <svg
        fill={this.props.color}
        className={`${this.props.className}`}
        style={this.props.style}
        height={this.props.height}
        width={this.props.width}
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 26.591 26.591">
        <path
          className={this.props.className}
          d="M.791,7.855l11.966,5.317a1.317,1.317,0,0,0,1.077,0L25.8,7.855a1.327,1.327,0,0,0,0-2.428L13.834.107a1.324,1.324,0,0,0-1.077,0L.791,5.427a1.327,1.327,0,0,0,0,2.428Z"
        />
        <path
          className={this.props.className}
          d="M24.726,44.751,13.3,49.83,1.87,44.751A1.328,1.328,0,0,0,.793,47.179L12.759,52.5a1.317,1.317,0,0,0,1.077,0L25.8,47.182a1.329,1.329,0,0,0-1.077-2.431Z"
          transform="translate(-0.002 -32.678)"
        />
        <path
          className={this.props.className}
          d="M24.726,69.564,13.3,74.64,1.87,69.564A1.328,1.328,0,0,0,.793,71.993L12.759,77.31a1.317,1.317,0,0,0,1.077,0L25.8,71.993a1.328,1.328,0,0,0-1.077-2.428Z"
          transform="translate(-0.002 -50.842)"
        />
      </svg>
    );
  }
}
ProjectIcon.propTypes = {
  width: PropTypes.string,
  height: PropTypes.string.isRequired,
  color: PropTypes.string.isRequired
};

export class InputSources extends React.Component {
  render() {
    return (
      <svg
        // fill={this.props.color}
        className={`${this.props.className}`}
        style={this.props.style}
        height={this.props.height}
        width={this.props.width}
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 31.908 29.844">
        <path
          d="M25.154,13.755a.91.91,0,0,0-.91.91v6.5l-10.3,5.712V13.986l4.637-2.572a.911.911,0,0,0-.882-1.6l-4.665,2.588L2.9,6.787,13.032,1.919,17.58,4.1a.911.911,0,1,0,.787-1.643L13.425.085a.908.908,0,0,0-.79,0L.517,5.908s0,0-.006,0a1.047,1.047,0,0,0-.1.057L.4,5.978a.772.772,0,0,0-.079.063A.069.069,0,0,0,.3,6.054a1.011,1.011,0,0,0-.082.082s0,.006-.006.006a.785.785,0,0,0-.06.082l-.019.029a.129.129,0,0,0-.016.029C.1,6.3.1,6.321.089,6.34a.067.067,0,0,0-.01.022.621.621,0,0,0-.038.108c0,.006,0,.013-.006.019a.893.893,0,0,0-.022.1c0,.006,0,.016,0,.025A.693.693,0,0,0,0,6.727V21.706a.911.911,0,0,0,.469.8l12.122,6.721h0a.7.7,0,0,0,.1.048c.006,0,.013,0,.016.006a.819.819,0,0,0,.082.025.1.1,0,0,0,.025.006.7.7,0,0,0,.079.016c.01,0,.019,0,.025,0,.035,0,.07.006.1.006s.07,0,.1-.006c.01,0,.019,0,.025,0a.7.7,0,0,0,.079-.016c.01,0,.019,0,.025-.006a.626.626,0,0,0,.082-.025c.006,0,.013,0,.016-.006a.562.562,0,0,0,.1-.048h0L25.594,22.5a.915.915,0,0,0,.469-.8V14.668A.909.909,0,0,0,25.154,13.755ZM12.122,26.879,1.82,21.167V8.278l10.3,5.712Z"
          transform="translate(0 0)"
          fill="#0080ab"
          stroke="#0080ab"
          stroke-width="0.5"
        />
        <path
          d="M65.3.72a6.5,6.5,0,1,0,6.5,6.5A6.512,6.512,0,0,0,65.3.72Zm0,11.186a4.681,4.681,0,1,1,4.681-4.681A4.686,4.686,0,0,1,65.3,11.906Z"
          transform="translate(-40.151 -0.495)"
          fill="rgba(244,165,40,1)"
        />
      </svg>
    );
  }
}
InputSources.propTypes = {
  width: PropTypes.string,
  height: PropTypes.string.isRequired
};

export class LiftModelIcon extends React.Component {
  render() {
    let fillAxis = "#666";
    let fillPoints = "#cc7e00";
    if (this.props.color !== undefined) {
      fillAxis = this.props.color;
      fillPoints = this.props.color;
    }
    return (
      <svg
        // fill={this.props.color}
        className={`${this.props.className}`}
        style={this.props.style}
        height={this.props.height}
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 26 26">
        <path
          d="M25.94,23.42H9.48C16.74,20.91,26,13.02,26,1.35h-1.41c0,13.09-12.56,21.91-22.06,22.08V0H0
            v25.39l0,0V26h25.94V23.42z"
          fill={fillAxis}
        />
        <path
          d="M6.93,21.2c1.81,0,3.27-1.46,3.27-3.27s-1.46-3.27-3.27-3.27s-3.27,1.46-3.27,3.27l0,0
            C3.66,19.74,5.12,21.2,6.93,21.2z"
          fill={fillPoints}
        />
        <path
          d="M20.04,8.43c1.81,0,3.27-1.46,3.27-3.27c0-1.81-1.46-3.27-3.27-3.27
          c-1.81,0-3.27,1.46-3.27,3.27C16.77,6.96,18.24,8.43,20.04,8.43z"
          fill={fillPoints}
        />
        <path
          d="M22.4,21.59c1.81,0,3.27-1.46,3.27-3.27s-1.46-3.27-3.27-3.27s-3.27,1.46-3.27,3.27
            S20.59,21.59,22.4,21.59z"
          fill={fillPoints}
        />
        <path
          d="M15.5,17.43c1.81,0,3.27-1.46,3.27-3.27s-1.46-3.27-3.27-3.27s-3.27,1.46-3.27,3.27
            S13.69,17.43,15.5,17.43z"
          fill={fillPoints}
        />
      </svg>
    );
  }
}
LiftModelIcon.propTypes = {
  height: PropTypes.string.isRequired
};

export class LatestModel extends React.Component {
  render() {
    return (
      <svg
        fill={this.props.color}
        className={`${this.props.className}`}
        style={this.props.style}
        height={this.props.height}
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 26 26">
        <path
          d="M13,0C5.82,0,0,5.82,0,13s5.82,13,13,13s13-5.82,13-13S20.18,0,13,0z M13,23.48
	C7.21,23.48,2.52,18.79,2.52,13S7.21,2.52,13,2.52S23.48,7.21,23.48,13C23.49,18.79,18.79,23.48,13,23.48
	C13,23.48,13,23.48,13,23.48z M16.24,18.01l-4.45-3.23c-0.16-0.12-0.26-0.31-0.26-0.51V5.66c0-0.35,0.28-0.63,0.63-0.63h1.68
	c0.35,0,0.63,0.28,0.63,0.63v7.43l3.5,2.55c0.28,0.2,0.34,0.6,0.14,0.88c0,0,0,0,0,0l-0.99,1.36
	C16.91,18.15,16.52,18.21,16.24,18.01L16.24,18.01z"
        />
      </svg>
    );
  }
}
LatestModel.propTypes = {
  height: PropTypes.string.isRequired
};

export class FilterIcon extends React.Component {
  render() {
    return (
      <svg
        fill={this.props.color}
        className={`${this.props.className}`}
        style={this.props.style}
        height={this.props.height}
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 26 26">
        <path d="M23.56,0H2.44A2.439,2.439,0,0,0,.717,4.161l8.221,8.224v8.74a2.721,2.721,0,0,0,.975,2.031l3.25,2.39a2.461,2.461,0,0,0,3.9-1.95V12.385l8.221-8.224A2.439,2.439,0,0,0,23.56,0ZM14.625,11.375V23.563l-3.25-2.438v-9.75L2.438,2.438H23.563Z" />
      </svg>
    );
  }
}
FilterIcon.propTypes = {
  height: PropTypes.string.isRequired
};

export class AliasIcon extends React.Component {
  render() {
    return (
      <svg
        fill={this.props.color}
        className={`${this.props.className}`}
        style={this.props.style}
        height={this.props.height}
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 25.6 20">
        <path
          d="M0,5.6V4.8c0-0.66,0.54-1.2,1.2-1.2c0,0,0,0,0,0h18V1.2c0-0.66,0.54-1.2,1.2-1.2
	c0.32,0,0.62,0.13,0.85,0.35l4,4c0.47,0.47,0.47,1.23,0,1.7c0,0,0,0,0,0l-4,4c-0.47,0.47-1.23,0.47-1.7,0
	C19.33,9.83,19.2,9.52,19.2,9.2V6.8h-18C0.54,6.8,0,6.27,0,5.6C0,5.6,0,5.6,0,5.6z M24.4,13.2h-18v-2.4c0-0.66-0.54-1.2-1.2-1.2
	c-0.32,0-0.62,0.13-0.85,0.35l-4,4c-0.47,0.47-0.47,1.23,0,1.7c0,0,0,0,0,0l4,4c0.47,0.47,1.23,0.47,1.7,0
	c0.23-0.23,0.35-0.53,0.35-0.85v-2.4h18c0.66,0,1.2-0.54,1.2-1.2v-0.8C25.6,13.73,25.06,13.2,24.4,13.2L24.4,13.2z"
        />
      </svg>
    );
  }
}
AliasIcon.propTypes = {
  height: PropTypes.string.isRequired
};

export class BaselineIcon extends React.Component {
  render() {
    let fillAxis = "#666";
    let fillLine = "#045976";
    let lineStroke = "#0080AB";
    let fillPoints = "#cc7e00";
    if (this.props.color !== undefined) {
      fillAxis = this.props.color;
      fillPoints = this.props.color;
      fillLine = this.props.color;
      lineStroke = this.props.color;
    }
    return (
      <svg
        // fill={this.props.color}
        className={`${this.props.className}`}
        style={this.props.style}
        height={this.props.height}
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 26 26">
        <rect fill={fillAxis} width="2.38" height="26" />
        <rect fill={fillAxis} y="23.62" width="26" height="2.38" />
        <path fill={fillLine} stroke={lineStroke} d="M4.2,22.92H3.65l5.47-6.47h15.7v0.45H9.23L4.2,22.92z" />
        <ellipse fill={fillPoints} cx="7.2" cy="16.58" rx="3.04" ry="3.04" />
        <ellipse fill={fillPoints} className="st2" cx="14.96" cy="16.58" rx="3.04" ry="3.04" />
        <ellipse fill={fillPoints} className="st2" cx="22.72" cy="16.58" rx="3.04" ry="3.04" />
      </svg>
    );
  }
}
BaselineIcon.propTypes = {
  height: PropTypes.string.isRequired
};

export class EditPromoIcon extends React.Component {
  render() {
    return (
      <svg
        fill={this.props.color}
        className={`${this.props.className}`}
        style={this.props.style}
        height={this.props.height}
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 22.5 20">
        <path
          d="M15.73,3.25l3.52,3.52c0.15,0.15,0.15,0.39,0,0.54l-8.53,8.53l-3.63,0.4c-0.48,0.05-0.89-0.36-0.84-0.84l0.4-3.63l8.53-8.53
          C15.34,3.1,15.58,3.1,15.73,3.25L15.73,3.25z M22.06,2.35l-1.91-1.91c-0.59-0.59-1.56-0.59-2.16,0l-1.38,1.38
          c-0.15,0.15-0.15,0.39,0,0.54l3.52,3.52c0.15,0.15,0.39,0.15,0.54,0l1.38-1.38C22.65,3.91,22.65,2.95,22.06,2.35z M15,13.52v3.98
          H2.5V5h8.98c0.13,0,0.24-0.05,0.33-0.14l1.56-1.56c0.3-0.3,0.09-0.8-0.33-0.8H1.88C0.84,2.5,0,3.34,0,4.37v13.75
          C0,19.16,0.84,20,1.88,20h13.75c1.04,0,1.88-0.84,1.88-1.88v-6.17c0-0.42-0.5-0.63-0.8-0.33l-1.56,1.56
          C15.05,13.28,15,13.4,15,13.52z"
        />
      </svg>
    );
  }
}
EditPromoIcon.propTypes = {
  height: PropTypes.string.isRequired
};

export class CalendarIcon extends React.Component {
  render() {
    return (
      <svg
        fill={this.props.color}
        className={`${this.props.className}`}
        style={this.props.style}
        height={this.props.height}
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 20 22.86">
        <path
          d="M6.61,12.86H4.82c-0.29,0-0.54-0.24-0.54-0.54v-1.79c0-0.29,0.24-0.54,0.54-0.54h1.79c0.29,0,0.54,0.24,0.54,0.54v1.79
          C7.14,12.62,6.9,12.86,6.61,12.86z M11.43,12.32v-1.79c0-0.29-0.24-0.54-0.54-0.54H9.11c-0.29,0-0.54,0.24-0.54,0.54v1.79
          c0,0.29,0.24,0.54,0.54,0.54h1.79C11.19,12.86,11.43,12.62,11.43,12.32z M15.71,12.32v-1.79c0-0.29-0.24-0.54-0.54-0.54h-1.79
          c-0.29,0-0.54,0.24-0.54,0.54v1.79c0,0.29,0.24,0.54,0.54,0.54h1.79C15.47,12.86,15.71,12.62,15.71,12.32z M11.43,16.61v-1.79
          c0-0.29-0.24-0.54-0.54-0.54H9.11c-0.29,0-0.54,0.24-0.54,0.54v1.79c0,0.29,0.24,0.54,0.54,0.54h1.79
          C11.19,17.14,11.43,16.9,11.43,16.61z M7.14,16.61v-1.79c0-0.29-0.24-0.54-0.54-0.54H4.82c-0.29,0-0.54,0.24-0.54,0.54v1.79
          c0,0.29,0.24,0.54,0.54,0.54h1.79C6.9,17.14,7.14,16.9,7.14,16.61z M15.71,16.61v-1.79c0-0.29-0.24-0.54-0.54-0.54h-1.79
          c-0.29,0-0.54,0.24-0.54,0.54v1.79c0,0.29,0.24,0.54,0.54,0.54h1.79C15.47,17.14,15.71,16.9,15.71,16.61z M20,5v15.71
          c0,1.18-0.96,2.14-2.14,2.14H2.14C0.96,22.86,0,21.9,0,20.71V5c0-1.18,0.96-2.14,2.14-2.14h2.14V0.54C4.29,0.24,4.53,0,4.82,0h1.79
          C6.9,0,7.14,0.24,7.14,0.54v2.32h5.71V0.54C12.86,0.24,13.1,0,13.39,0h1.79c0.29,0,0.54,0.24,0.54,0.54v2.32h2.14
          C19.04,2.86,20,3.82,20,5z M17.86,20.45V7.14H2.14v13.3c0,0.15,0.12,0.27,0.27,0.27h15.18C17.74,20.71,17.86,20.59,17.86,20.45z"
        />
      </svg>
    );
  }
}
CalendarIcon.propTypes = {
  height: PropTypes.string.isRequired
};

export class InputSourceIcon extends React.Component {
  render() {
    let mainColor = "#a6a6a6";
    let topDisc = "#cc7e00";
    if (this.props.color !== undefined) {
      mainColor = this.props.color;
      topDisc = this.props.color;
    }
    return (
      <svg
        className={`${this.props.className}`}
        style={this.props.style}
        height={this.props.height}
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 22.75 26">
        <path
          fill={topDisc}
          d="M22.75,3.71v2.32c0,2.05-5.09,3.71-11.38,3.71S0,8.08,0,6.04V3.71C0,1.67,5.09,0,11.38,0S22.75,1.67,22.75,3.71
	z"
        />
        <path
          fill={mainColor}
          d="M0,17.06v5.22C0,24.33,5.09,26,11.38,26s11.38-1.67,11.38-3.71v-5.22c-2.44,1.68-6.92,2.47-11.38,2.47S2.44,18.75,0,17.06z
	 M11.38,11.4C6.92,11.4,2.44,10.62,0,8.94v5.22c0,2.05,5.09,3.71,11.38,3.71s11.38-1.67,11.38-3.71V8.94
	C20.31,10.62,15.83,11.4,11.38,11.4z"
        />
      </svg>
    );
  }
}
InputSourceIcon.propTypes = {
  height: PropTypes.string.isRequired
};

export class PromoPlanIcon extends React.Component {
  render() {
    let mainColor = "#666";
    let sound = "#cc7e00";
    if (this.props.color !== undefined) {
      sound = this.props.color;
      mainColor = this.props.color;
    }
    return (
      <svg
        // fill={this.props.color}
        className={`${this.props.className}`}
        style={this.props.style}
        height={this.props.height}
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 26 21.47">
        <path
          fill={mainColor}
          d="M13.07,2.07l-0.45,0.3c-0.25,0.17-0.31,0.5-0.15,0.75l0.25,0.36L1.35,15.75c-0.18-0.17-0.45-0.2-0.66-0.06l-0.45,0.3
		c-0.25,0.17-0.31,0.5-0.15,0.75l3.04,4.49c0.17,0.25,0.5,0.31,0.75,0.15l0.45-0.3c0.21-0.14,0.28-0.41,0.19-0.64L8.26,19
		c0.08,0.18,0.16,0.37,0.27,0.54c1.01,1.49,3.03,1.88,4.52,0.87c1.24-0.84,1.7-2.38,1.24-3.73l5.84-2.25l0.25,0.36
		c0.17,0.25,0.5,0.31,0.75,0.15l0.45-0.3c0.25-0.17,0.31-0.5,0.15-0.75l-7.9-11.67C13.66,1.97,13.32,1.9,13.07,2.07z M12.14,19.07
		c-0.74,0.5-1.76,0.31-2.26-0.44c-0.05-0.07-0.06-0.14-0.1-0.22l2.99-1.15C12.97,17.92,12.74,18.66,12.14,19.07z M3.61,19.05
		L2.3,17.11L13.66,4.87l5.54,8.18L3.61,19.05z"
        />
        <rect
          x="18.57"
          y="4.19"
          transform="matrix(0.8282 -0.5605 0.5605 0.8282 0.8764 13.2271)"
          width="6.88"
          height="1.99"
          fill={sound}
        />
        <rect
          x="16.16"
          y="1.86"
          transform="matrix(-0.5866 0.8099 -0.8099 -0.5866 32.3881 -10.8327)"
          width="5.6"
          height="1.99"
          fill={sound}
        />
        <rect
          x="20.24"
          y="7.89"
          transform="matrix(0.9699 -0.2434 0.2434 0.9699 -1.4697 5.8757)"
          width="5.6"
          height="1.99"
          fill={sound}
        />
      </svg>
    );
  }
}
PromoPlanIcon.propTypes = {
  height: PropTypes.string.isRequired
};

export class CollectionIcon extends React.Component {
  render() {
    return (
      <svg
        fill={this.props.color}
        className={`${this.props.className}`}
        style={this.props.style}
        height={this.props.height}
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 26 26">
        <path
          d="M25.19,5.28c0-0.67-0.55-1.22-1.22-1.22H2.03c-0.67,0-1.22,0.55-1.22,1.22V6.5h24.37V5.28z M24.37,1.22
	c0-0.67-0.55-1.22-1.22-1.22H2.84C2.17,0,1.63,0.55,1.63,1.22v1.22h22.75V1.22z M24.37,8.12H1.63C0.73,8.12,0,8.85,0,9.75
	C0,9.8,0,9.85,0.01,9.9l1.33,14.62C1.41,25.36,2.11,26,2.95,26h20.09c0.84,0,1.54-0.64,1.62-1.48L25.99,9.9
	c0.08-0.89-0.58-1.68-1.47-1.77C24.47,8.13,24.42,8.12,24.37,8.12z M22.3,23.56H3.7l-1.18-13h20.97L22.3,23.56z"
        />
      </svg>
    );
  }
}
CollectionIcon.propTypes = {
  height: PropTypes.string.isRequired
};

export class PBIExportIcon extends React.Component {
  render() {
    return (
      <svg
        fill={this.props.color}
        className={`${this.props.className}`}
        style={this.props.style}
        height={this.props.height}
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 15 20">
        <path
          d="M8.44,9.22c0-0.26-0.21-0.47-0.47-0.47c0,0,0,0,0,0H7.03c-0.26,0-0.47,0.21-0.47,0.47
	v3.28H4.65c-0.26,0-0.47,0.21-0.47,0.47c0,0.12,0.05,0.24,0.14,0.33l2.83,2.8c0.19,0.19,0.51,0.19,0.7,0l2.83-2.8
	c0.18-0.18,0.18-0.48,0-0.66c-0.09-0.09-0.21-0.14-0.33-0.14H8.44V9.22z M14.45,3.83l-3.28-3.28C10.82,0.2,10.34,0,9.85,0H1.88
	C0.84,0,0,0.84,0,1.88v16.25C0,19.16,0.84,20,1.88,20l0,0h11.25c1.04,0,1.88-0.84,1.88-1.88l0,0V5.16C15,4.66,14.8,4.18,14.45,3.83z
	 M10,2.03L12.97,5H10V2.03z M13.13,18.13H1.88V1.88h6.25v4.06c0,0.52,0.42,0.94,0.93,0.94c0,0,0,0,0.01,0h4.06V18.13z"
        />
      </svg>
    );
  }
}
PBIExportIcon.propTypes = {
  height: PropTypes.string.isRequired
};

export class AddToPPTIcon extends React.Component {
  render() {
    return (
      <svg
        fill={this.props.color}
        className={`${this.props.className}`}
        style={this.props.style}
        height={this.props.height}
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 15 20">
        <path
          d="M14.45,3.83l-3.28-3.28C10.82,0.2,10.34,0,9.85,0H1.88C0.84,0,0,0.84,0,1.88v16.25
	C0,19.16,0.84,20,1.88,20l0,0h11.25c1.04,0,1.88-0.84,1.88-1.87c0,0,0,0,0,0V5.15C15,4.66,14.8,4.18,14.45,3.83L14.45,3.83z
	 M12.97,5H10V2.03L12.97,5z M1.88,18.13V1.88h6.25v4.06c0,0.52,0.42,0.94,0.93,0.94c0,0,0,0,0,0h4.06v11.25H1.88z M11.88,11.41v0.94
	c0,0.26-0.21,0.47-0.47,0.47H8.44v2.97c0,0.26-0.21,0.47-0.47,0.47H7.03c-0.26,0-0.47-0.21-0.47-0.47v-2.97H3.59
	c-0.26,0-0.47-0.21-0.47-0.47v-0.94c0-0.26,0.21-0.47,0.47-0.47h2.97V7.97c0-0.26,0.21-0.47,0.47-0.47c0,0,0,0,0,0h0.94
	c0.26,0,0.47,0.21,0.47,0.47v2.97h2.96C11.66,10.94,11.87,11.14,11.88,11.41C11.87,11.41,11.87,11.41,11.88,11.41L11.88,11.41z"
        />
      </svg>
    );
  }
}
AddToPPTIcon.propTypes = {
  height: PropTypes.string.isRequired
};

export class DownloadPPTIcon extends React.Component {
  render() {
    return (
      <svg
        fill={this.props.color}
        className={`${this.props.className}`}
        style={this.props.style}
        height={this.props.height}
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 15 20">
        <path
          d="M14.45,3.83l-3.28-3.28C10.82,0.2,10.34,0,9.85,0H1.88C0.84,0,0,0.84,0,1.88v16.25
	C0,19.16,0.84,20,1.88,20l0,0h11.25c1.04,0,1.88-0.84,1.88-1.87c0,0,0,0,0,0V5.15C15,4.66,14.8,4.18,14.45,3.83L14.45,3.83z
	 M12.97,5H10V2.03L12.97,5z M1.88,18.13V1.88h6.25v4.06c0,0.52,0.42,0.94,0.93,0.94c0,0,0,0,0,0h4.06v11.25H1.88z M4.69,15.78V9.22
	c0-0.26,0.21-0.47,0.47-0.47h2.7c1.33-0.02,2.43,1.04,2.46,2.37c0,0.07,0,0.15-0.01,0.22c0,2.9-2.68,2.6-3.73,2.6v1.84
	c0,0.26-0.21,0.47-0.47,0.47H5.16C4.9,16.25,4.69,16.04,4.69,15.78z M6.58,12.37h0.9c0.27,0.02,0.53-0.09,0.71-0.28
	c0.33-0.44,0.33-1.04,0-1.48c-0.18-0.19-0.42-0.29-0.68-0.27H6.58L6.58,12.37L6.58,12.37z"
        />
      </svg>
    );
  }
}
DownloadPPTIcon.propTypes = {
  height: PropTypes.string.isRequired
};

export class DownloadPDFIcon extends React.Component {
  render() {
    return (
      <svg
        fill={this.props.color}
        className={`${this.props.className}`}
        style={this.props.style}
        height={this.props.height}
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 15 20">
        <path
          d="M14.45,3.83l-3.28-3.28C10.82,0.2,10.34,0,9.85,0H1.88C0.84,0,0,0.84,0,1.88v16.25
	C0,19.16,0.84,20,1.88,20l0,0h11.25c1.04,0,1.88-0.84,1.88-1.87c0,0,0,0,0,0V5.15C15,4.66,14.8,4.18,14.45,3.83L14.45,3.83z
	 M12.97,5H10V2.03L12.97,5z M1.88,18.13V1.88h6.25v4.06c0,0.52,0.42,0.94,0.93,0.94c0,0,0,0,0,0h4.06v11.25H1.88z M11.65,12.51
	c-0.48-0.47-1.84-0.34-2.52-0.25c-0.68-0.42-1.18-1.06-1.44-1.81C7.93,9.75,8,9,7.9,8.26C7.84,7.79,7.4,7.45,6.93,7.51
	C6.62,7.55,6.36,7.75,6.24,8.03c-0.12,0.88-0.02,1.78,0.27,2.62c-0.41,0.99-0.87,1.96-1.38,2.91c-0.78,0.4-1.84,1.02-1.99,1.8
	c-0.13,0.62,1.01,2.16,2.97-1.22c0.87-0.33,1.76-0.59,2.67-0.78c0.67,0.38,1.41,0.61,2.18,0.66c0.5,0.01,0.91-0.38,0.92-0.88
	C11.89,12.91,11.8,12.68,11.65,12.51L11.65,12.51z M3.91,15.55c0.3-0.53,0.71-0.99,1.19-1.37C4.36,15.37,3.91,15.58,3.91,15.55
	L3.91,15.55z M7.1,8.11c0.29,0,0.26,1.25,0.07,1.59C7.02,9.18,6.99,8.64,7.1,8.11z M6.15,13.44c0.38-0.69,0.7-1.4,0.97-2.14
	c0.29,0.54,0.69,1.01,1.18,1.39C7.55,12.88,6.84,13.13,6.15,13.44L6.15,13.44z M11.29,13.24c0,0-0.2,0.23-1.46-0.3
	C11.2,12.84,11.42,13.15,11.29,13.24L11.29,13.24z"
        />
      </svg>
    );
  }
}
DownloadPDFIcon.propTypes = {
  height: PropTypes.string.isRequired
};

export class DownloadAsImagesIcon extends React.Component {
  render() {
    return (
      <svg
        fill={this.props.color}
        className={`${this.props.className}`}
        style={this.props.style}
        height={this.props.height}
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 15 20">
        <path
          d="M14.45,3.83l-3.28-3.28C10.82,0.2,10.34,0,9.85,0H1.88C0.84,0,0,0.84,0,1.88v16.25
	C0,19.16,0.84,20,1.88,20l0,0h11.25c1.04,0,1.88-0.84,1.88-1.87c0,0,0,0,0,0V5.15C15,4.66,14.8,4.18,14.45,3.83L14.45,3.83z
	 M12.97,5H10V2.03L12.97,5z M1.88,18.13V1.88h6.25v4.06c0,0.52,0.42,0.94,0.93,0.94c0,0,0,0,0,0h4.06v11.25H1.88z M3.13,16.25h8.75
	v-5l-0.92-0.92c-0.18-0.18-0.48-0.18-0.66,0c0,0,0,0,0,0l-3.42,3.42l-1.54-1.54c-0.18-0.18-0.48-0.18-0.66,0c0,0,0,0,0,0l-1.54,1.54
	L3.13,16.25z M5,6.88c-1.04,0-1.88,0.84-1.88,1.87c0,1.04,0.84,1.88,1.87,1.88c1.04,0,1.88-0.84,1.88-1.87c0,0,0,0,0,0
	C6.87,7.72,6.03,6.88,5,6.88z"
        />
      </svg>
    );
  }
}
DownloadAsImagesIcon.propTypes = {
  height: PropTypes.string.isRequired
};

export class NotificationsIcon extends React.Component {
  render() {
    return (
      <svg
        fill={this.props.color}
        className={`${this.props.className}`}
        style={this.props.style}
        height={this.props.height}
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 20 20">
        <path
          d="M17.5,0h-15C1.12,0,0,1.12,0,2.5v11.25c0,1.38,1.12,2.5,2.5,2.5h3.75v3.28
	c0,0.26,0.21,0.47,0.47,0.47c0,0,0,0,0,0c0.1,0,0.2-0.03,0.28-0.09l4.88-3.66h5.62c1.38,0,2.5-1.12,2.5-2.5V2.5
	C20,1.12,18.88,0,17.5,0z M18.75,13.75c0,0.69-0.56,1.25-1.25,1.25h-6.04l-0.33,0.25L7.5,17.97V15h-5c-0.69,0-1.25-0.56-1.25-1.25
	V2.5c0-0.69,0.56-1.25,1.25-1.25h15c0.69,0,1.25,0.56,1.25,1.25V13.75z M10.94,9.37H5.31C5.14,9.38,5,9.52,5,9.69v0.63
	c0,0.17,0.14,0.31,0.31,0.31h5.62c0.17,0,0.31-0.14,0.31-0.31V9.69C11.25,9.52,11.11,9.38,10.94,9.37z M14.69,5.62H5.31
	C5.14,5.63,5,5.77,5,5.94v0.62c0,0.17,0.14,0.31,0.31,0.31h9.37c0.17,0,0.31-0.14,0.31-0.31V5.94C15,5.77,14.86,5.63,14.69,5.62z"
        />
      </svg>
    );
  }
}
NotificationsIcon.propTypes = {
  height: PropTypes.string.isRequired
};

export class DownloadAsCSVIcon extends React.Component {
  render() {
    return (
      <svg
        fill={this.props.color}
        className={`${this.props.className}`}
        style={this.props.style}
        height={this.props.height}
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 15 20">
        <path d="M14.449,3.827,11.173.551A1.875,1.875,0,0,0,9.849,0H1.875A1.881,1.881,0,0,0,0,1.879V18.125A1.875,1.875,0,0,0,1.875,20H13.125A1.875,1.875,0,0,0,15,18.125V5.155a1.884,1.884,0,0,0-.551-1.328ZM10,1.273a.615.615,0,0,1,.289.164l3.277,3.277A.615.615,0,0,1,13.731,5H10ZM13.75,18.125a.627.627,0,0,1-.625.625H1.875a.627.627,0,0,1-.625-.625V1.879a.63.63,0,0,1,.625-.629H8.751V5.315a.933.933,0,0,0,.938.935H13.75ZM4.688,10H4.375A1.875,1.875,0,0,0,2.5,11.875v1.25A1.875,1.875,0,0,0,4.375,15h.313A.312.312,0,0,0,5,14.688v-.625a.312.312,0,0,0-.312-.312H4.375a.625.625,0,0,1-.625-.625v-1.25a.625.625,0,0,1,.625-.625h.313A.312.312,0,0,0,5,10.938v-.625A.312.312,0,0,0,4.688,10Zm4.375,0a.312.312,0,0,0-.312.313v.813A5.373,5.373,0,0,0,10.167,14.8a.625.625,0,0,0,.916,0A5.371,5.371,0,0,0,12.5,11.125v-.812A.312.312,0,0,0,12.188,10h-.625a.312.312,0,0,0-.312.313v.813a4.258,4.258,0,0,1-.625,2.222A4.257,4.257,0,0,1,10,11.125v-.812A.312.312,0,0,0,9.688,10ZM7.01,11.659a.207.207,0,0,1-.083-.15c0-.122.174-.259.407-.259h.479a.312.312,0,0,0,.313-.312v-.625A.312.312,0,0,0,7.813,10H7.333a1.59,1.59,0,0,0-1.657,1.509,1.45,1.45,0,0,0,.521,1.1l.855.733a.207.207,0,0,1,.083.15c0,.122-.174.259-.407.259H6.25a.312.312,0,0,0-.312.313v.625A.312.312,0,0,0,6.25,15h.479a1.59,1.59,0,0,0,1.657-1.509,1.45,1.45,0,0,0-.521-1.1l-.855-.733Z" />
      </svg>
    );
  }
}
DownloadAsCSVIcon.propTypes = {
  height: PropTypes.string.isRequired
};

export class RefreshIcon extends React.Component {
  render() {
    return (
      <svg
        fill={this.props.color}
        className={`${this.props.className}`}
        style={this.props.style}
        height={this.props.height}
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 20 20">
        <path
          d="M17.21,0.49l0.16,3.23c-1.84-2.17-4.53-3.41-7.38-3.41c-4.73,0-8.67,3.4-9.51,7.88C0.43,8.45,0.6,8.7,0.86,8.74
          c0.03,0.01,0.06,0.01,0.09,0.01h1.92c0.22,0,0.41-0.15,0.46-0.36C4.2,4.7,7.91,2.43,11.6,3.32c1.67,0.4,3.13,1.42,4.09,2.85
          l-3.96-0.19c-0.26-0.01-0.48,0.19-0.49,0.45c0,0.01,0,0.02,0,0.02v1.85c0,0.26,0.21,0.47,0.47,0.47h7.82c0.26,0,0.47-0.21,0.47-0.47
          V0.47C20,0.21,19.79,0,19.53,0h-1.85c-0.26,0-0.47,0.21-0.47,0.47C17.21,0.48,17.21,0.48,17.21,0.49z M9.99,16.88
          c-2.29,0-4.43-1.14-5.7-3.04l3.98,0.19c0.26,0.01,0.48-0.19,0.49-0.45c0-0.01,0-0.02,0-0.02v-1.85c0-0.26-0.21-0.47-0.47-0.47H0.47
          C0.21,11.24,0,11.45,0,11.71v7.83C0,19.79,0.21,20,0.47,20h1.85c0.26,0,0.47-0.21,0.47-0.47c0-0.01,0-0.02,0-0.02l-0.16-3.23
          c1.84,2.16,4.53,3.41,7.37,3.41c4.73,0,8.67-3.4,9.51-7.88c0.05-0.25-0.12-0.5-0.38-0.55c-0.03-0.01-0.06-0.01-0.09-0.01h-1.92
          c-0.22,0-0.41,0.15-0.46,0.36C15.93,14.7,13.17,16.88,9.99,16.88z"
        />
      </svg>
    );
  }
}
RefreshIcon.propTypes = {
  height: PropTypes.string.isRequired
};

export class CropPPTIcon extends React.Component {
  render() {
    return (
      <svg
        fill={this.props.color}
        className={`${this.props.className}`}
        style={this.props.style}
        height={this.props.height}
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 16.5 22">
        <path
          d="M15.89,4.21l-3.6-3.6C11.9,0.22,11.38,0,10.83,0H2.06C0.92,0,0,0.93,0,2.07v17.87C0,21.08,0.92,22,2.06,22h12.37
        c1.14,0,2.06-0.92,2.06-2.06V5.67C16.5,5.13,16.28,4.6,15.89,4.21z M14.27,5.5H11V2.23L14.27,5.5z M2.06,19.94V2.07h6.87v4.47
        c0,0.57,0.46,1.03,1.03,1.03h4.47v12.37H2.06z"
        />
        <path
          d="M6.27,8.06c0-0.19-0.15-0.34-0.34-0.34H5.24c-0.19,0-0.34,0.15-0.34,0.34v1.72H3.18c-0.19,0-0.34,0.15-0.34,0.34v0.69
        c0,0.19,0.15,0.34,0.34,0.34H4.9v4.81c0,0.38,0.31,0.69,0.69,0.69h4.13v-1.38H6.27V8.06z M13.49,15.28h-1.72v-4.81
        c0-0.38-0.31-0.69-0.69-0.69H6.96v1.38h3.44v7.22c0,0.19,0.15,0.34,0.34,0.34h0.69c0.19,0,0.34-0.15,0.34-0.34v-1.72h1.72
        c0.19,0,0.34-0.15,0.34-0.34v-0.69C13.83,15.43,13.68,15.28,13.49,15.28z"
        />
      </svg>
    );
  }
}
CropPPTIcon.propTypes = {
  height: PropTypes.string.isRequired
};

export class ColorCheckbox extends React.Component {
  render() {
    return (
      <svg
        fill={this.props.color}
        className={`${this.props.className}`}
        style={this.props.style}
        height={this.props.height}
        x={this.props.x}
        y={this.props.y}
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 24 24"
        onClick={this.props.onClick}>
        <path d="M9.87,19.23l-7.12-7.54l1.99-2.04l5.09,5.32L19.18,5l2.07,2L9.87,19.23z M24,0H0v24h24V0z" />
      </svg>
    );
  }
}
ColorCheckbox.propTypes = {
  height: PropTypes.string.isRequired
};

export class AppPie extends React.Component {
  render() {
    return (
      <svg
        fill={this.props.color}
        className={`${this.props.className}`}
        style={this.props.style}
        height={this.props.height}
        x={this.props.x}
        y={this.props.y}
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 25.5 24"
        onClick={this.props.onClick}>
        <path
          d="M24.7,13.5H13.6l7.4,7.4c0.3,0.3,0.7,0.3,1,0c1.8-1.7,3.1-4,3.4-6.6C25.6,13.9,25.2,13.5,24.7,13.5L24.7,13.5z M24,10.5
	C23.6,4.9,19.1,0.4,13.5,0c-0.4,0-0.8,0.3-0.8,0.8v10.5h10.5C23.7,11.2,24,10.9,24,10.5L24,10.5z M10.5,13.5V2.4
	c0-0.4-0.4-0.8-0.8-0.8C4.1,2.4-0.2,7.3,0,13.1c0.2,6,5.4,10.9,11.4,10.9c2.4,0,4.5-0.8,6.3-2.1c0.4-0.3,0.4-0.8,0.1-1.1L10.5,13.5z"
        />
      </svg>
    );
  }
}
AppPie.propTypes = {
  height: PropTypes.string.isRequired
};

export class AppChartLineDown extends React.Component {
  render() {
    return (
      <svg
        fill={this.props.color}
        className={`${this.props.className}`}
        style={this.props.style}
        height={this.props.height}
        x={this.props.x}
        y={this.props.y}
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 32 24"
        onClick={this.props.onClick}>
        <path
          d="M11.3,11.4c0.4,0.4,1,0.4,1.4,0L18,6.1l5.5,5.5l-1.8,1.8c-0.9,0.9-0.3,2.6,1.1,2.6H29c0.6,0,1-0.4,1-1V8.7
	c0-1.3-1.6-2-2.6-1.1l-1.8,1.8l-6.9-6.9c-0.4-0.4-1-0.4-1.4,0L12,7.9L8.8,4.6c-0.4-0.4-1-0.4-1.4,0L6.6,5.4c-0.4,0.4-0.4,1,0,1.4
	L11.3,11.4z M31,21H3V1c0-0.6-0.4-1-1-1H1C0.4,0,0,0.4,0,1v21c0,1.1,0.9,2,2,2h29c0.6,0,1-0.4,1-1v-1C32,21.4,31.6,21,31,21z"
        />
      </svg>
    );
  }
}
AppChartLineDown.propTypes = {
  height: PropTypes.string.isRequired
};

export class AppChartLine extends React.Component {
  render() {
    return (
      <svg
        fill={this.props.color}
        className={`${this.props.className}`}
        style={this.props.style}
        height={this.props.height}
        x={this.props.x}
        y={this.props.y}
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 32 24"
        onClick={this.props.onClick}>
        <path
          d="M7.4,13.4c0.4,0.4,1,0.4,1.4,0l3.2-3.2l5.3,5.3c0.4,0.4,1,0.4,1.4,0l6.9-6.9l1.8,1.8c0.9,0.9,2.6,0.3,2.6-1.1V3
	c0-0.6-0.4-1-1-1h-6.3c-1.3,0-2,1.6-1.1,2.6l1.8,1.8L18,11.9l-5.3-5.3c-0.4-0.4-1-0.4-1.4,0l-4.6,4.6c-0.4,0.4-0.4,1,0,1.4
	C6.6,12.6,7.4,13.4,7.4,13.4z M31,21H3V1c0-0.6-0.4-1-1-1H1C0.4,0,0,0.4,0,1v21c0,1.1,0.9,2,2,2h29c0.6,0,1-0.4,1-1v-1
	C32,21.4,31.6,21,31,21z"
        />
      </svg>
    );
  }
}
AppChartLine.propTypes = {
  height: PropTypes.string.isRequired
};

export class AppChartBar extends React.Component {
  render() {
    return (
      <svg
        fill={this.props.color}
        className={`${this.props.className}`}
        style={this.props.style}
        height={this.props.height}
        x={this.props.x}
        y={this.props.y}
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 32 24"
        onClick={this.props.onClick}>
        <path
          d="M24.8,18h1.4c0.4,0,0.8-0.4,0.8-0.8V2.8C27,2.4,26.6,2,26.2,2h-1.4C24.4,2,24,2.4,24,2.8v14.4C24,17.6,24.4,18,24.8,18z
	 M12.8,18h1.4c0.4,0,0.8-0.4,0.8-0.8V4.8C15,4.4,14.6,4,14.2,4h-1.4C12.4,4,12,4.4,12,4.8v12.4C12,17.6,12.4,18,12.8,18z M18.8,18
	h1.4c0.4,0,0.8-0.4,0.8-0.8V8.8C21,8.4,20.6,8,20.2,8h-1.4C18.4,8,18,8.4,18,8.8v8.4C18,17.6,18.4,18,18.8,18z M31,21H3V1
	c0-0.6-0.4-1-1-1H1C0.4,0,0,0.4,0,1v21c0,1.1,0.9,2,2,2h29c0.6,0,1-0.4,1-1v-1C32,21.4,31.6,21,31,21z M6.8,18h1.4
	C8.6,18,9,17.6,9,17.2v-4.4C9,12.4,8.6,12,8.2,12H6.8C6.4,12,6,12.4,6,12.8v4.4C6,17.6,6.4,18,6.8,18z"
        />
      </svg>
    );
  }
}
AppChartBar.propTypes = {
  height: PropTypes.string.isRequired
};

export class AppChartUser extends React.Component {
  render() {
    return (
      <svg
        fill={this.props.color}
        className={`${this.props.className}`}
        style={this.props.style}
        height={this.props.height}
        x={this.props.x}
        y={this.props.y}
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 32 24"
        onClick={this.props.onClick}>
        <path
          d="M9.8,16.5H9.6c-0.7,0.2-1.3,0.4-2.1,0.4s-1.4-0.1-2.1-0.4H5.3c-2.9,0-5.3,2.4-5.3,5.3C0,23,1,24,2.3,24h10.5
	c1.2,0,2.3-1,2.3-2.3C15,18.9,12.6,16.5,9.8,16.5z M27.8,0h-18C8.5,0,7.5,1,7.5,2.3v2.2c1.1,0,2.1,0.3,3,0.8V3H27v13.5H14.4
	c0.9,0.8,1.6,1.8,1.9,3h11.5c1.2,0,2.3-1,2.3-2.3V2.3C30,1,29,0,27.8,0z M7.5,15c2.5,0,4.5-2,4.5-4.5S10,6,7.5,6S3,8,3,10.5
	S5,15,7.5,15z M15.4,7.8c-0.4-0.4-1.2-0.4-1.6,0l-0.7,0.7c0.2,0.6,0.4,1.3,0.4,2c0,0.3,0,0.6-0.1,0.9l1.2-1.2l2.6,2.6
	c0.4,0.4,1.2,0.4,1.6,0l3.4-3.4l1.1,1.1c0.5,0.5,1.4,0.2,1.4-0.6V5.8c0-0.3-0.3-0.6-0.6-0.6H20c-0.8,0-1.1,0.9-0.6,1.4l1.1,1.1
	L18,10.4L15.4,7.8z"
        />
      </svg>
    );
  }
}
AppChartUser.propTypes = {
  height: PropTypes.string.isRequired
};

export class AppChartAnalytics extends React.Component {
  render() {
    return (
      <svg
        fill={this.props.color}
        className={`${this.props.className}`}
        style={this.props.style}
        height={this.props.height}
        x={this.props.x}
        y={this.props.y}
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 27 24"
        onClick={this.props.onClick}>
        <path
          d="M23.9,4.3c0.3,0.1,0.5,0.2,0.8,0.2c1.2,0,2.3-1,2.3-2.3S26,0,24.8,0s-2.3,1-2.3,2.3c0,0.1,0,0.2,0,0.3l-4.5,3.6
	C17.8,6.1,17.5,6,17.2,6s-0.6,0.1-0.8,0.2L12,2.6c0-0.1,0-0.2,0-0.3C12,1,11,0,9.8,0S7.5,1,7.5,2.3c0,0.2,0,0.4,0.1,0.6L2.8,7.6
	c-0.2,0-0.4-0.1-0.6-0.1C1,7.5,0,8.5,0,9.8S1,12,2.3,12s2.3-1,2.3-2.3c0-0.2,0-0.4-0.1-0.6l4.8-4.8c0.2,0,0.4,0.1,0.6,0.1
	c0.3,0,0.6-0.1,0.8-0.2L15,7.9c0,0.1,0,0.2,0,0.3c0,1.2,1,2.3,2.3,2.3s2.3-1,2.3-2.3c0-0.1,0-0.2,0-0.3L23.9,4.3L23.9,4.3z M18.8,15
	h-3c-0.4,0-0.8,0.3-0.8,0.8v7.5c0,0.4,0.3,0.8,0.8,0.8h3c0.4,0,0.8-0.3,0.8-0.8v-7.5C19.5,15.3,19.2,15,18.8,15z M26.3,9h-3
	c-0.4,0-0.8,0.3-0.8,0.8v13.5c0,0.4,0.3,0.8,0.8,0.8h3c0.4,0,0.8-0.3,0.8-0.8V9.8C27,9.3,26.7,9,26.3,9z M11.3,9h-3
	C7.8,9,7.5,9.3,7.5,9.8v13.5c0,0.4,0.3,0.8,0.8,0.8h3c0.4,0,0.8-0.3,0.8-0.8V9.8C12,9.3,11.7,9,11.3,9z M3.8,16.5h-3
	c-0.4,0-0.8,0.3-0.8,0.8v6C0,23.7,0.3,24,0.8,24h3c0.4,0,0.8-0.3,0.8-0.8v-6C4.5,16.8,4.2,16.5,3.8,16.5z"
        />
      </svg>
    );
  }
}
AppChartAnalytics.propTypes = {
  height: PropTypes.string.isRequired
};

export class ListViewIcon extends React.Component {
  render() {
    return (
      <svg
        fill={this.props.color}
        className={`${this.props.className}`}
        style={this.props.style}
        height={this.props.height}
        x={this.props.x}
        y={this.props.y}
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 30 24.4"
        onClick={this.props.onClick}>
        <path
          d="M4.7,18.8H0.9c-0.5,0-0.9,0.4-0.9,0.9v3.8c0,0.5,0.4,0.9,0.9,0.9h3.8c0.5,0,0.9-0.4,0.9-0.9v-3.8
	C5.6,19.2,5.2,18.8,4.7,18.8z M4.7,0H0.9C0.4,0,0,0.4,0,0.9v3.8c0,0.5,0.4,0.9,0.9,0.9h3.8c0.5,0,0.9-0.4,0.9-0.9V0.9
	C5.6,0.4,5.2,0,4.7,0z M4.7,9.4H0.9C0.4,9.4,0,9.8,0,10.3v3.8C0,14.6,0.4,15,0.9,15h3.8c0.5,0,0.9-0.4,0.9-0.9v-3.8
	C5.6,9.8,5.2,9.4,4.7,9.4z M29.1,19.7H10.3c-0.5,0-0.9,0.4-0.9,0.9v1.9c0,0.5,0.4,0.9,0.9,0.9h18.8c0.5,0,0.9-0.4,0.9-0.9v-1.9
	C30,20.1,29.6,19.7,29.1,19.7z M29.1,0.9H10.3c-0.5,0-0.9,0.4-0.9,0.9v1.9c0,0.5,0.4,0.9,0.9,0.9h18.8c0.5,0,0.9-0.4,0.9-0.9V1.9
	C30,1.4,29.6,0.9,29.1,0.9z M29.1,10.3H10.3c-0.5,0-0.9,0.4-0.9,0.9v1.9c0,0.5,0.4,0.9,0.9,0.9h18.8c0.5,0,0.9-0.4,0.9-0.9v-1.9
	C30,10.7,29.6,10.3,29.1,10.3z"
        />
      </svg>
    );
  }
}
ListViewIcon.propTypes = {
  height: PropTypes.string.isRequired
};

export class GroupingIcon extends React.Component {
  render() {
    return (
      <svg
        fill={this.props.color}
        className={`${this.props.className}`}
        style={this.props.style}
        height={this.props.height}
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 30 24">
        <path
          d="M27.8,3h-9l-2.6-2.6C15.9,0.2,15.5,0,15.1,0H8.2C7,0,6,1,6,2.2V6H2.2C1,6,0,7,0,8.2v13.5C0,23,1,24,2.2,24h19.5
          c1.2,0,2.2-1,2.2-2.2V18h3.8c1.2,0,2.2-1,2.2-2.2V5.2C30,4,29,3,27.8,3z M22.5,21.8c0,0.4-0.3,0.8-0.8,0.8H2.2
          c-0.4,0-0.8-0.3-0.8-0.8V8.2c0-0.4,0.3-0.8,0.8-0.8H6v8.2C6,17,7,18,8.2,18h14.2V21.8z M28.5,15.8c0,0.4-0.3,0.8-0.8,0.8H8.2
          c-0.4,0-0.8-0.3-0.8-0.8V2.2c0-0.4,0.3-0.8,0.8-0.8h6.9l2.6,2.6c0.3,0.3,0.7,0.4,1.1,0.4h9c0.4,0,0.8,0.3,0.8,0.8V15.8z"
        />
      </svg>
    );
  }
}
GroupingIcon.propTypes = {
  height: PropTypes.string.isRequired
};

export class BarMenuIcon extends React.Component {
  render() {
    return (
      <svg
        fill={this.props.color}
        className={`${this.props.className}`}
        style={this.props.style}
        height={this.props.height}
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 24 21">
        <path
          d="M0.9,3.9h22.3C23.6,3.9,24,3.5,24,3V0.9C24,0.4,23.6,0,23.1,0H0.9C0.4,0,0,0.4,0,0.9V3C0,3.5,0.4,3.9,0.9,3.9z M0.9,12.4
          h22.3c0.5,0,0.9-0.4,0.9-0.9V9.4c0-0.5-0.4-0.9-0.9-0.9H0.9C0.4,8.6,0,9,0,9.4v2.1C0,12,0.4,12.4,0.9,12.4z M0.9,21h22.3
          c0.5,0,0.9-0.4,0.9-0.9V18c0-0.5-0.4-0.9-0.9-0.9H0.9C0.4,17.1,0,17.5,0,18v2.1C0,20.6,0.4,21,0.9,21z"
        />
      </svg>
    );
  }
}
BarMenuIcon.propTypes = {
  height: PropTypes.string.isRequired
};

export class FolderMgmtIcon extends React.Component {
  render() {
    return (
      <svg
        fill={this.props.color}
        className={`${this.props.className}`}
        style={this.props.style}
        height={this.props.height}
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 32 24">
        <path
          d="M12.2,2l3.4,3.4C16,5.8,16.5,6,17,6h12c0.6,0,1,0.4,1,1v14c0,0.6-0.4,1-1,1H3c-0.6,0-1-0.4-1-1V3c0-0.6,0.4-1,1-1H12.2 M3,0
	C1.3,0,0,1.3,0,3v18c0,1.7,1.3,3,3,3h26c1.7,0,3-1.3,3-3V7c0-1.7-1.3-3-3-3H17l-3.4-3.4C13.2,0.2,12.7,0,12.2,0C12.2,0,3,0,3,0z"
        />
        <path
          d="M17.6,13.8L16.3,13c0.1-0.7,0.1-1.4,0-2.1l1.3-0.7c0.1-0.1,0.2-0.3,0.2-0.4c-0.3-1-0.9-2-1.6-2.8C16,6.9,15.8,6.9,15.7,7
	l-1.3,0.7c-0.5-0.5-1.1-0.8-1.8-1V5.2c0-0.2-0.1-0.3-0.3-0.3c-1.1-0.2-2.2-0.2-3.2,0C9,4.9,8.9,5,8.9,5.2v1.5
	c-0.7,0.2-1.3,0.6-1.8,1L5.8,7C5.7,6.9,5.5,6.9,5.4,7C4.7,7.8,4.1,8.8,3.8,9.8c-0.1,0.2,0,0.3,0.2,0.4L5.2,11
	c-0.1,0.7-0.1,1.4,0,2.1l-1.3,0.7c-0.1,0.1-0.2,0.3-0.2,0.4c0.3,1,0.9,2,1.6,2.8c0.1,0.1,0.3,0.2,0.4,0.1l1.3-0.7
	c0.5,0.5,1.1,0.8,1.8,1v1.5c0,0.2,0.1,0.3,0.3,0.3c1.1,0.2,2.2,0.2,3.2,0c0.2,0,0.3-0.2,0.3-0.3v-1.5c0.7-0.2,1.3-0.6,1.8-1l1.3,0.7
	c0.1,0.1,0.3,0.1,0.4-0.1c0.7-0.8,1.3-1.7,1.6-2.8C17.8,14,17.7,13.8,17.6,13.8z M10.8,14.4c-1.3,0-2.4-1.1-2.4-2.4s1.1-2.4,2.4-2.4
	s2.4,1.1,2.4,2.4S12.1,14.4,10.8,14.4z"
        />
      </svg>
    );
  }
}
FolderMgmtIcon.propTypes = {
  height: PropTypes.string.isRequired
};

export class StaticContentIcon extends React.Component {
  render() {
    return (
      <svg
        fill={this.props.color}
        className={`${this.props.className}`}
        style={this.props.style}
        height={this.props.height}
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 29.4 24">
        <path
          class="st0"
          d="M1,23.5c-0.3,0-0.5-0.2-0.5-0.5V1c0-0.3,0.2-0.5,0.5-0.5h27.4c0.3,0,0.5,0.2,0.5,0.5v22
			c0,0.3-0.2,0.5-0.5,0.5H1z"
        />
        <path
          d="M28.4,1v22H1V1H28.4 M28.4,0H1C0.4,0,0,0.4,0,1v22c0,0.6,0.4,1,1,1h27.4c0.6,0,1-0.4,1-1V1C29.4,0.4,28.9,0,28.4,0L28.4,0
			z"
        />
        <path
          d="M18.9,12.2v-0.4c0-0.4,0.3-0.6,0.6-0.6h7.4c0.4,0,0.6,0.3,0.6,0.6v0.4c0,0.4-0.3,0.6-0.6,0.6h-7.4
			C19.2,12.9,18.9,12.6,18.9,12.2z"
        />
        <path d="M18.9,9V8.6c0-0.4,0.3-0.6,0.6-0.6h7.4c0.4,0,0.6,0.3,0.6,0.6V9c0,0.4-0.3,0.6-0.6,0.6h-7.4C19.2,9.7,18.9,9.4,18.9,9z" />
        <path
          d="M18.9,2.7V2.2c0-0.4,0.3-0.6,0.6-0.6h7.4c0.4,0,0.6,0.3,0.6,0.6v0.4c0,0.4-0.3,0.6-0.6,0.6h-7.4C19.2,3.3,18.9,3,18.9,2.7
			z"
        />
        <path
          d="M18.9,5.8V5.4c0-0.4,0.3-0.6,0.6-0.6h7.4c0.4,0,0.6,0.3,0.6,0.6v0.4c0,0.4-0.3,0.6-0.6,0.6h-7.4
			C19.2,6.5,18.9,6.2,18.9,5.8z"
        />
        <path
          d="M1.8,15.4V15c0-0.4,0.3-0.6,0.6-0.6h24.5c0.4,0,0.6,0.3,0.6,0.6v0.4c0,0.4-0.3,0.6-0.6,0.6H2.4
			C2.1,16.1,1.8,15.8,1.8,15.4z"
        />
        <path
          d="M1.8,18.6v-0.4c0-0.4,0.3-0.6,0.6-0.6h24.5c0.4,0,0.6,0.3,0.6,0.6v0.4c0,0.4-0.3,0.6-0.6,0.6H2.4
			C2.1,19.2,1.8,19,1.8,18.6z"
        />
        <path
          d="M1.8,21.8v-0.4c0-0.4,0.3-0.6,0.6-0.6h24.5c0.4,0,0.6,0.3,0.6,0.6v0.4c0,0.4-0.3,0.6-0.6,0.6H2.4
			C2.1,22.4,1.8,22.1,1.8,21.8z"
        />
        <path
          d="M15.8,12.8H3.2c-0.8,0-1.4-0.6-1.4-1.4V3c0-0.8,0.6-1.4,1.4-1.4h12.5c0.8,0,1.4,0.6,1.4,1.4v8.5
			C17.2,12.2,16.6,12.8,15.8,12.8z M5.2,3.2C4.2,3.2,3.5,4,3.5,4.9s0.8,1.6,1.7,1.6s1.7-0.7,1.7-1.6S6.1,3.2,5.2,3.2z M3.7,11h11.6
			V7.7l-2.6-2.6c-0.1-0.1-0.4-0.1-0.5,0l-4.1,4L6.4,7.5C6.3,7.3,6,7.3,5.9,7.5L3.7,9.6V11z"
        />
      </svg>
    );
  }
}
StaticContentIcon.propTypes = {
  height: PropTypes.string.isRequired
};

export class CloseReportIcon extends React.Component {
  render() {
    return (
      <svg
        fill={this.props.color}
        className={`${this.props.className}`}
        style={this.props.style}
        height={this.props.height}
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 20 20">
        <path
          d="M10,0C4.5,0,0,4.5,0,10s4.5,10,10,10s10-4.5,10-10S15.5,0,10,0z M10,18.7c-4.8,0-8.7-3.9-8.7-8.7c0-4.8,3.9-8.7,8.7-8.7
          c4.8,0,8.7,3.9,8.7,8.7C18.7,14.8,14.8,18.7,10,18.7z M13.8,7.2L11,10l2.8,2.8c0.2,0.2,0.2,0.5,0,0.7l-0.3,0.3
          c-0.2,0.2-0.5,0.2-0.7,0L10,11l-2.8,2.8C7,14,6.7,14,6.5,13.8l-0.3-0.3C6,13.3,6,13,6.2,12.8L9,10L6.2,7.2C6,7,6,6.7,6.2,6.5
          l0.3-0.3C6.7,6,7,6,7.2,6.2L10,9l2.8-2.8C13,6,13.3,6,13.5,6.2l0.3,0.3C14,6.7,14,7,13.8,7.2L13.8,7.2z"
        />
      </svg>
    );
  }
}
CloseReportIcon.propTypes = {
  height: PropTypes.string.isRequired
};

export class ClearIcon extends React.Component {
  render() {
    return (
      <svg
        fill={this.props.color}
        className={`${this.props.className}`}
        style={this.props.style}
        height={this.props.height}
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 24 24">
        <path
          d="M23.9,0.7l-0.6-0.6c-0.2-0.2-0.4-0.2-0.6,0l-4,4C14.3,0.5,7.8,1,4.2,5.3
          c-3.2,3.8-3.2,9.5,0,13.3l-4,4c-0.2,0.2-0.2,0.4,0,0.6l0.6,0.6c0.2,0.2,0.4,0.2,0.6,0l4-4c4.3,3.7,10.8,3.1,14.5-1.2
          c3.2-3.8,3.2-9.5,0-13.3l4-4C24,1.2,24,0.9,23.9,0.7C23.9,0.7,23.9,0.7,23.9,0.7z M3.4,12c0-4.7,3.8-8.6,8.6-8.6c2,0,3.9,0.7,5.4,2
          l-12,12C4.1,15.9,3.4,14,3.4,12z M20.6,12c0,4.7-3.8,8.6-8.6,8.6c-2,0-3.9-0.7-5.4-2l12-12C19.9,8.1,20.6,10,20.6,12L20.6,12z"
        />
      </svg>
    );
  }
}
ClearIcon.propTypes = {
  height: PropTypes.string.isRequired
};

export class AdvSearchIcon extends React.Component {
  render() {
    return (
      <svg
        fill={this.props.color}
        className={`${this.props.className}`}
        style={this.props.style}
        height={this.props.height}
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 22.86 20">
        <path
          d="M22.1,1.8h-9.3V0.7c0-0.4-0.3-0.7-0.7-0.7h-1.4C10.3,0,10,0.3,10,0.7v1.1H0.7
          C0.3,1.8,0,2.1,0,2.5v0.7c0,0.4,0.3,0.7,0.7,0.7H10V5c0,0.4,0.3,0.7,0.7,0.7h1.4c0.4,0,0.7-0.3,0.7-0.7V3.9h9.3
          c0.4,0,0.7-0.3,0.7-0.7V2.5C22.9,2.1,22.5,1.8,22.1,1.8z M22.1,16.1h-15V15c0-0.4-0.3-0.7-0.7-0.7H5c-0.4,0-0.7,0.3-0.7,0.7v1.1H0.7
          c-0.4,0-0.7,0.3-0.7,0.7v0.7c0,0.4,0.3,0.7,0.7,0.7h3.6v1.1C4.3,19.7,4.6,20,5,20h1.4c0.4,0,0.7-0.3,0.7-0.7v-1.1h15
          c0.4,0,0.7-0.3,0.7-0.7v-0.7C22.9,16.4,22.5,16.1,22.1,16.1z M22.1,8.9h-3.6V7.9c0-0.4-0.3-0.7-0.7-0.7h-1.4c-0.4,0-0.7,0.3-0.7,0.7
          v1.1h-15C0.3,8.9,0,9.2,0,9.6v0.7c0,0.4,0.3,0.7,0.7,0.7h15v1.1c0,0.4,0.3,0.7,0.7,0.7h1.4c0.4,0,0.7-0.3,0.7-0.7v-1.1h3.6
          c0.4,0,0.7-0.3,0.7-0.7V9.6C22.9,9.2,22.5,8.9,22.1,8.9L22.1,8.9z"
        />
      </svg>
    );
  }
}
AdvSearchIcon.propTypes = {
  height: PropTypes.string.isRequired
};

export class InfoIcon extends React.Component {
  render() {
    return (
      <svg
        fill={this.props.color}
        className={`${this.props.className}`}
        style={this.props.style}
        height={this.props.height}
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 20 20">
        <path
          d="M10,1.3c4.8,0,8.7,3.9,8.7,8.7s-3.9,8.7-8.7,8.7S1.3,14.8,1.3,10C1.3,5.2,5.2,1.3,10,1.3
          M10,0C4.5,0,0,4.5,0,10s4.5,10,10,10s10-4.5,10-10S15.5,0,10,0z M8.5,13.9H9V9H8.5C8.3,9,8.1,8.8,8.1,8.5V8.2
         c0-0.3,0.2-0.5,0.5-0.5h1.9C10.7,7.7,11,8,11,8.2v5.6h0.5c0.3,0,0.5,0.2,0.5,0.5v0.3c0,0.3-0.2,0.5-0.5,0.5H8.5
         c-0.3,0-0.5-0.2-0.5-0.5v-0.3C8.1,14.1,8.3,13.9,8.5,13.9L8.5,13.9z M10,4.2c-0.7,0-1.3,0.6-1.3,1.3c0,0.7,0.6,1.3,1.3,1.3
         c0.7,0,1.3-0.6,1.3-1.3l0,0C11.3,4.8,10.7,4.2,10,4.2z"
        />
      </svg>
    );
  }
}
InfoIcon.propTypes = {
  height: PropTypes.string.isRequired
};

export class EnvelopeIcon extends React.Component {
  render() {
    return (
      <svg
        fill={this.props.color}
        className={`${this.props.className}`}
        style={this.props.style}
        height={this.props.height}
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 26.7 20">
        <path
          d="M26.2,6.6c0.1-0.1,0.3-0.1,0.4,0.1c0,0.1,0.1,0.1,0.1,0.2v10.7c0,1.4-1.1,2.5-2.5,2.5l0,0H2.5
          C1.1,20,0,18.9,0,17.5l0,0V6.9c0-0.2,0.1-0.3,0.3-0.3c0.1,0,0.1,0,0.2,0.1c1.2,0.9,2.7,2.1,8,5.9c1.1,0.8,3,2.5,4.8,2.5
          c1.9,0,3.7-1.7,4.8-2.5C23.5,8.7,25,7.5,26.2,6.6z M13.3,13.3c1.2,0,2.9-1.5,3.8-2.2c6.9-5,7.4-5.5,9-6.7c0.3-0.2,0.5-0.6,0.5-1v-1
          c0-1.4-1.1-2.5-2.5-2.5H2.5C1.1,0,0,1.1,0,2.5v1c0,0.4,0.2,0.7,0.5,1c1.6,1.2,2.1,1.7,9,6.7C10.4,11.8,12.1,13.4,13.3,13.3z"
        />
      </svg>
    );
  }
}
EnvelopeIcon.propTypes = {
  height: PropTypes.string.isRequired
};

export class WarningTriangleIcon extends React.Component {
  render() {
    return (
      <svg
        fill={this.props.color}
        className={`${this.props.className}`}
        style={this.props.style}
        height={this.props.height}
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 24 21.3">
        <path
          d="M10.4,8.5l0.3,4.7c0,0.3,0.2,0.5,0.5,0.5h1.7c0.3,0,0.5-0.2,0.5-0.5l0.3-4.7
	c0-0.3-0.2-0.5-0.5-0.5c0,0,0,0,0,0h-2.3C10.6,8,10.4,8.2,10.4,8.5C10.4,8.5,10.4,8.5,10.4,8.5z M13.7,16c0,1-0.8,1.7-1.8,1.7
	c-1,0-1.7-0.8-1.7-1.8c0-1,0.8-1.7,1.7-1.7C13,14.3,13.7,15,13.7,16C13.7,16,13.7,16,13.7,16z M13.7,1C13.2,0,12-0.3,11,0.3
	c-0.3,0.2-0.6,0.4-0.7,0.7l-10,17.3c-0.6,1-0.2,2.2,0.7,2.7c0.3,0.2,0.6,0.3,1,0.3h20c1.1,0,2-0.9,2-2c0-0.4-0.1-0.7-0.3-1L13.7,1
	L13.7,1z M2.2,19l9.6-16.6c0.1-0.1,0.2-0.2,0.3-0.1c0,0,0.1,0.1,0.1,0.1L21.8,19c0.1,0.1,0,0.3-0.1,0.3c0,0-0.1,0-0.1,0H2.4
	c-0.1,0-0.2-0.1-0.3-0.2C2.2,19,2.2,19,2.2,19z"
        />
      </svg>
    );
  }
}
WarningTriangleIcon.propTypes = {
  height: PropTypes.string.isRequired
};

export class ClearFiltersIcon extends React.Component {
  render() {
    return (
      <svg
        fill={this.props.color}
        className={`${this.props.className}`}
        style={this.props.style}
        height={this.props.height}
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 20 20">
        <path
          d="M254.27,254.82a6.43,6.43,0,0,1,9.75-5.51l1.7-1.71a.94.94,0,0,0-.66-1.6H246.94a.94.94,0,0,0-.66,1.6l7.22,7.23v8a.93.93,0,0,0,.4.76l3.13,2.19a.94.94,0,0,0,1.47-.77v-4.19A6.44,6.44,0,0,1,254.27,254.82Z"
          transform="translate(-246 -246)"
        />
        <path
          d="M260.7,249.65a5.18,5.18,0,1,0,5.18,5.17A5.17,5.17,0,0,0,260.7,249.65Zm2.54,6.53a.25.25,0,0,1,0,.36l-.82.82a.25.25,0,0,1-.36,0L260.7,256l-1.36,1.37a.24.24,0,0,1-.35,0l-.83-.82a.27.27,0,0,1,0-.36l1.37-1.36-1.37-1.35a.27.27,0,0,1,0-.36l.83-.83a.25.25,0,0,1,.36,0l1.35,1.38,1.36-1.37a.25.25,0,0,1,.36,0l.83.82a.27.27,0,0,1,0,.36l-1.38,1.35Z"
          transform="translate(-246 -246)"
        />
      </svg>
    );
  }
}
ClearFiltersIcon.propTypes = {
  height: PropTypes.string.isRequired
};

export class ManageUsersIcon extends React.Component {
  render() {
    return (
      <svg
        fill={this.props.color}
        className={`${this.props.className}`}
        style={this.props.style}
        height={this.props.height}
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 26 18.2">
        <path
          d="M3.9,7.8c1.4,0,2.6-1.2,2.6-2.6S5.3,2.6,3.9,2.6S1.3,3.8,1.3,5.2S2.5,7.8,3.9,7.8z M22.1,7.8c1.4,0,2.6-1.2,2.6-2.6
	s-1.2-2.6-2.6-2.6c-1.4,0-2.6,1.2-2.6,2.6S20.7,7.8,22.1,7.8z M23.4,9.1h-2.6c-0.7,0-1.4,0.3-1.8,0.8c1.6,0.9,2.8,2.5,3.1,4.4h2.7
	c0.7,0,1.3-0.6,1.3-1.3v-1.3C26,10.3,24.8,9.1,23.4,9.1z M13,9.1c2.5,0,4.5-2,4.5-4.6S15.5,0,13,0S8.4,2,8.4,4.6S10.5,9.1,13,9.1z
	 M16.1,10.4h-0.3c-0.8,0.4-1.8,0.7-2.8,0.7s-1.9-0.2-2.8-0.7H9.9c-2.6,0-4.7,2.1-4.7,4.7v1.2c0,1.1,0.9,2,2,2h11.7
	c1.1,0,1.9-0.9,1.9-2v-1.2C20.8,12.5,18.7,10.4,16.1,10.4z M7,9.9C6.6,9.4,5.9,9.1,5.2,9.1H2.6C1.2,9.1,0,10.3,0,11.7V13
	c0,0.7,0.6,1.3,1.3,1.3H4C4.2,12.4,5.4,10.8,7,9.9L7,9.9z"
        />
      </svg>
    );
  }
}
ManageUsersIcon.propTypes = {
  height: PropTypes.string.isRequired
};

export class BookmarkIcon extends React.Component {
  render() {
    return (
      <svg
        fill={this.props.color}
        className={`${this.props.className}`}
        style={this.props.style}
        height={this.props.height}
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 30 30">
        <path
          d="M23.4,0H6.6C5,0,3.8,1.3,3.8,2.8c0,0,0,0,0,0V30L15,23.4L26.2,30V2.8C26.2,1.3,25,0,23.4,0
          C23.4,0,23.4,0,23.4,0z M23.4,25.1L15,20.2l-8.4,4.9V3.2c0-0.2,0.2-0.4,0.3-0.4c0,0,0,0,0,0h16.2c0.2,0,0.4,0.2,0.4,0.4c0,0,0,0,0,0
          L23.4,25.1z"
        />
      </svg>
    );
  }
}
BookmarkIcon.propTypes = {
  height: PropTypes.string.isRequired
};

export class DoubleChevronRightIcon extends React.Component {
  render() {
    return (
      <svg
        fill={this.props.color}
        className={`${this.props.className}`}
        style={this.props.style}
        height={this.props.height}
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 30 30">
        <path
          d="M3.7,0.2l14.2,14.2c0.3,0.3,0.3,0.8,0,1.2L3.7,29.8c-0.3,0.3-0.8,0.3-1.2,0l-1.3-1.3
          c-0.3-0.3-0.3-0.8,0-1.2L13.5,15L1.2,2.7c-0.3-0.3-0.3-0.8,0-1.2l1.3-1.3C2.9-0.1,3.4-0.1,3.7,0.2C3.7,0.2,3.7,0.2,3.7,0.2z
           M13.4,0.2l-1.3,1.3c-0.3,0.3-0.3,0.8,0,1.2L24.3,15L12.1,27.3c-0.3,0.3-0.3,0.8,0,1.2l1.3,1.3c0.3,0.3,0.8,0.3,1.2,0l14.2-14.2
          c0.3-0.3,0.3-0.8,0-1.2L14.6,0.2C14.3-0.1,13.7-0.1,13.4,0.2z"
        />
      </svg>
    );
  }
}
DoubleChevronRightIcon.propTypes = {
  height: PropTypes.string.isRequired
};

export class PlusCircleIcon extends React.Component {
  render() {
    return (
      <svg
        fill={this.props.color}
        className={`${this.props.className}`}
        style={this.props.style}
        height={this.props.height}
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 30 30">
        <path
          d="M15,0C6.7,0,0,6.7,0,15s6.7,15,15,15s15-6.7,15-15S23.3,0,15,0z M23.7,16.7c0,0.4-0.3,0.7-0.7,0.7h-5.6V23
          c0,0.4-0.3,0.7-0.7,0.7h-3.4c-0.4,0-0.7-0.3-0.7-0.7v-5.6H7c-0.4,0-0.7-0.3-0.7-0.7v-3.4c0-0.4,0.3-0.7,0.7-0.7h5.6V7
          c0-0.4,0.3-0.7,0.7-0.7h3.4c0.4,0,0.7,0.3,0.7,0.7v5.6H23c0.4,0,0.7,0.3,0.7,0.7V16.7z"
        />
      </svg>
    );
  }
}
PlusCircleIcon.propTypes = {
  height: PropTypes.string.isRequired
};

export class ResetIcon extends React.Component {
  render() {
    return (
      <svg
        fill={this.props.color}
        className={`${this.props.className}`}
        style={this.props.style}
        height={this.props.height}
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 30 30">
        <path d="m14.97,0c-3.85,0-7.55,1.49-10.33,4.15L2.48,1.99c-.57-.57-1.49-.57-2.05,0C.15,2.27,0,2.64,0,3.02v8.11c0,.8.65,1.45,1.45,1.45h8.11c.8,0,1.45-.65,1.45-1.45,0-.39-.15-.76-.43-1.03l-2.52-2.52c4.1-3.83,10.53-3.61,14.36.48,3.83,4.1,3.61,10.53-.48,14.36-3.81,3.56-9.7,3.66-13.63.22-.29-.25-.72-.24-.99.03l-2.4,2.4c-.28.28-.28.74,0,1.03,0,0,.02.02.03.03,6.15,5.55,15.63,5.07,21.19-1.08,5.55-6.15,5.07-15.63-1.08-21.19C22.29,1.37,18.7,0,14.97,0Z" />
      </svg>
    );
  }
}
ResetIcon.propTypes = {
  height: PropTypes.string.isRequired
};

export class LinkIcon extends React.Component {
  render() {
    return (
      <svg
        fill={this.props.color}
        className={`${this.props.className}`}
        style={this.props.style}
        height={this.props.height}
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 30 30">
        <path d="m19.14,10.86c3.5,3.5,3.45,9.12.02,12.57,0,0-.01.01-.02.02l-3.94,3.94c-3.47,3.47-9.12,3.47-12.6,0-3.47-3.47-3.47-9.12,0-12.6l2.17-2.17c.58-.58,1.57-.19,1.6.62.04,1.04.22,2.08.57,3.09.12.34.03.72-.22.97l-.77.77c-1.64,1.64-1.69,4.32-.07,5.97,1.64,1.67,4.34,1.68,6,.03l3.94-3.94c1.65-1.65,1.64-4.32,0-5.97-.22-.22-.44-.38-.61-.5-.24-.17-.4-.44-.41-.74-.02-.62.2-1.26.69-1.75l1.23-1.23c.32-.32.83-.36,1.21-.1.43.3.83.64,1.2,1.01h0ZM27.4,2.6c-3.47-3.47-9.12-3.47-12.6,0l-3.94,3.94s-.01.01-.02.02c-3.43,3.45-3.48,9.07.02,12.57.37.37.77.71,1.2,1.01.38.26.88.22,1.21-.1l1.23-1.23c.49-.49.71-1.13.69-1.75-.01-.3-.16-.57-.41-.74-.17-.12-.39-.29-.61-.5-1.64-1.64-1.65-4.31,0-5.97l3.94-3.94c1.65-1.65,4.35-1.64,6,.03,1.63,1.66,1.57,4.33-.07,5.97l-.77.77c-.25.25-.34.63-.22.97.34,1.01.53,2.05.57,3.09.03.81,1.02,1.2,1.6.62l2.17-2.17c3.47-3.47,3.47-9.12,0-12.6h0Z" />
      </svg>
    );
  }
}
LinkIcon.propTypes = {
  height: PropTypes.string.isRequired
};

export class GroupPermissionsIcon extends React.Component {
  render() {
    return (
      <svg
        fill={this.props.color}
        className={`${this.props.className}`}
        style={this.props.style}
        height={this.props.height}
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 43.1 30">
        <path
          d="M12.9,15.1c4.2,0,7.5-3.3,7.5-7.5S17.1,0,12.9,0S5.5,3.3,5.5,7.6S8.8,15.1,12.9,15.1z M18.1,17.2h-0.6
          c-1.4,0.7-3,1.1-4.6,1.1s-3.2-0.4-4.6-1.1H7.8C3.4,17.2,0,20.7,0,25v1.9C0,28.6,1.4,30,3.2,30h19.4c1.7,0,3.2-1.4,3.2-3.2V25
          C25.8,20.7,22.4,17.2,18.1,17.2z M32.3,15.1c3.6,0,6.5-2.9,6.5-6.5s-2.9-6.5-6.5-6.5c-3.6,0-6.5,2.9-6.5,6.5S28.7,15.1,32.3,15.1z
           M35.6,17.2h-0.3c-1,0.3-1.9,0.6-3,0.6c-1.1,0-2-0.3-3-0.6H29c-1.4,0-2.6,0.4-3.7,1C26.8,20,28,22.4,28,25v2.6c0,0.1,0,0.3,0,0.4
          h11.9c1.7,0,3.2-1.4,3.2-3.2C43.1,20.5,39.8,17.2,35.6,17.2z"
        />
      </svg>
    );
  }
}
GroupPermissionsIcon.propTypes = {
  height: PropTypes.string.isRequired
};

export class DownChevronCompact extends React.Component {
  render() {
    return (
      <svg
        fill={this.props.color}
        className={`${this.props.className}`}
        style={this.props.style}
        height={this.props.height}
        width={this.props.width}
        viewBox="0 0 30 30"
        xmlns="http://www.w3.org/2000/svg">
        <path
          transform={this.props.transform}
          d="M29.8,8l-1.3-1.3c-.3-.3-.8-.3-1.1,0l-12.3,12.2L2.7,6.7c-.3-.3-.8-.3-1.1,0l-1.3,1.3c-.3.3-.3.8,0,1.1l14.2,14.2c.3.3.8.3,1.1,0l14.2-14.2c.3-.3.3-.8,0-1.1Z"
        />
      </svg>
    );
  }
}
DownChevronCompact.propTypes = {
  width: PropTypes.string,
  height: PropTypes.string.isRequired,
  color: PropTypes.string.isRequired
};

export class FolderOpenIcon extends React.Component {
  render() {
    return (
      <svg
        fill={this.props.color}
        className={`${this.props.className}`}
        style={this.props.style}
        height={this.props.height}
        width={this.props.width}
        viewBox="0 0 30 30"
        xmlns="http://www.w3.org/2000/svg">
        <path d="M27.5,13.3h-2.5v-2.5c0-1.4-1.1-2.5-2.5-2.5h-8.3l-3.3-3.3H2.5c-1.4,0-2.5,1.1-2.5,2.5v15c0,1.4,1.1,2.5,2.5,2.5h20.1c1.5,0,2.8-.8,3.6-2l3.5-5.9c1-1.7-.2-3.8-2.1-3.8ZM2.5,6.7h7.6l3.3,3.3h9c.5,0,.8.4.8.8v2.5h-14.4c-1.5,0-2.8.8-3.6,2l-3.7,6.1V7.5c0-.5.4-.8.8-.8ZM28.2,16.3l-3.5,5.9c-.5.8-1.3,1.2-2.1,1.2H2.3l4.3-7.1c.5-.8,1.3-1.2,2.1-1.2h18.7c.6,0,1,.7.7,1.3h0Z" />
      </svg>
    );
  }
}

FolderOpenIcon.propTypes = {
  width: PropTypes.string,
  height: PropTypes.string.isRequired,
  color: PropTypes.string.isRequired
};

export class FolderClosedIconLight extends React.Component {
  render() {
    return (
      <svg
        fill={this.props.color}
        className={`${this.props.className}`}
        style={this.props.style}
        height={this.props.height}
        width={this.props.width}
        viewBox="0 0 30 30"
        xmlns="http://www.w3.org/2000/svg">
        <path d="M11.4,5.6l3.2,3.2c.4.4.8.5,1.3.5h11.2c.5,0,.9.4.9.9v13.1c0,.5-.4.9-.9.9H2.8c-.5,0-.9-.4-.9-.9V6.6c0-.5.4-.9.9-.9h8.6M2.8,3.8c-1.6,0-2.8,1.3-2.8,2.8v16.9c0,1.6,1.3,2.8,2.8,2.8h24.4c1.6,0,2.8-1.3,2.8-2.8v-13.1c0-1.6-1.3-2.8-2.8-2.8h-11.2l-3.2-3.2c-.4-.4-.8-.5-1.3-.5,0,0-8.6,0-8.6,0Z" />
      </svg>
    );
  }
}

FolderClosedIconLight.propTypes = {
  width: PropTypes.string,
  height: PropTypes.string.isRequired,
  color: PropTypes.string.isRequired
};
