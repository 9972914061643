import React, { Component } from "react";

import NumericEditor from "components/accucast/components/editors/numericEditor";
import TotalRenderer from "components/accucast/components/renderers/totalRenderer";
import { AccucastGrid } from "components/accucast/components/lib/accucastGrid";
import { floatFormatter } from "components/accucast/components/formatters";
import { numberNewValueHandler } from "components/accucast/components/handlers";
import { CustomHeaderRenderer } from "components/accucast/components/renderers/customHeaderRenderer";
import { EditableCellFloatRenderer } from "components/accucast/components/renderers/editableCellFloatRenderer";
import { GroupFilterer } from "components/accucast/components/filterers/groupFilterer";
import { brandSizeComparator } from "components/accucast/components/comparators";
import axios from "axios";

var getRowClass = params => {
  var idx = params.node.rowIndex;
  var classes = [];
  switch (idx % 8) {
    case 0:
    case 1:
    case 2:
      classes.push("ta-even-row");
      break;
    case 3:
      classes.push("ta-final-group-row-even");
      break;
    case 4:
    case 5:
    case 6:
      classes.push("ta-odd-row");
      break;
    case 7:
      classes.push("ta-final-group-row-odd");
      break;
    default:
      classes.push("");
      break;
  }

  return classes.join(" ");
};

export class LeSeasonality extends Component {
  constructor(props) {
    super(props);

    this.state = {
      columnDefs: [],
      rowData: this.createRows(),
      frameworkComponents: {
        numericEditor: NumericEditor,
        totalRenderer: TotalRenderer,
        customHeaderRenderer: CustomHeaderRenderer,
        editableCellFloatRenderer: EditableCellFloatRenderer,
        groupFilterer: GroupFilterer
      }
    };
  }

  componentDidMount() {
    var baseConfig = [
      {
        id: "0",
        headerName: "Brand Size",
        pinned: "left",
        field: "bs",
        tooltipField: "bs",
        comparator: brandSizeComparator,
        filter: "groupFilterer",
        suppressMovable: true,
        menuTabs: ["filterMenuTab"]
      },
      {
        id: "1",
        headerName: "Type",
        pinned: "left",
        field: "type",
        tooltipField: "type",
        suppressFilter: true,
        suppressSorting: true,
        suppressMovable: true,
        menuTabs: []
      }
    ];
    var defaultWkCellConf = {
      width: 95,
      suppressMovable: true,
      menuTabs: ["filterMenuTab"],
      cellEditor: "numericEditor",
      editable: true,
      valueFormatter: floatFormatter,
      newValueHandler: numberNewValueHandler,
      headerComponent: "customHeaderRenderer",
      cellClass: "cellCenterClass",
      cellRenderer: "editableCellFloatRenderer"
    };
    axios
      .get("http://localhost:4000/api/accucast/config/seasonality", {
        headers: {
          ClientToken: localStorage.getItem("clientToken")
        }
      })
      .then(res => {
        if (res.data.config.length !== 0) {
          let config = res.data.config.map(conf => {
            let id = parseInt(conf.id, 10);
            if (id === 0 || id === 1) {
              let pinConf = baseConfig[id];
              pinConf.cellClassRules = conf.cellClassRules;
              return pinConf;
            } else {
              conf = { ...conf, ...defaultWkCellConf };
              return conf;
            }
          });
          this.setState({ columnDefs: config });
        }
      });
  }

  createRows = () => {
    let rows = [];
    rows.push({
      fbs: "Bar",
      bs: "Bar",
      type: "Baseline",
      wk21: "1.2",
      wk22: "1.2",
      wk23: "1.2",
      wk24: "1.2",
      wk25: "1.2",
      wk26: "1.2",
      wk27: "1.2",
      wk28: "1.2",
      wk29: "1.2",
      wk30: "1.2",
      wk31: "1.2"
    });
    rows.push({
      fbs: "Bar",
      bs: "",
      type: "Shock Factor",
      wk21: "1.2",
      wk22: "1.2",
      wk23: "1.2",
      wk24: "1.2",
      wk25: "1.2",
      wk26: "1.2",
      wk27: "1.2",
      wk28: "1.2",
      wk29: "1.2",
      wk30: "1.2",
      wk31: "1.2"
    });
    rows.push({
      fbs: "Bar",
      bs: "",
      type: "Drift Factor",
      wk21: "1.2",
      wk22: "1.2",
      wk23: "1.2",
      wk24: "1.2",
      wk25: "1.2",
      wk26: "1.2",
      wk27: "1.2",
      wk28: "1.2",
      wk29: "1.2",
      wk30: "1.2",
      wk31: "1.2"
    });
    rows.push({
      fbs: "Bar",
      bs: "",
      type: "Adjusted Seasonality",
      wk21: "1.2",
      wk22: "1.2",
      wk23: "1.2",
      wk24: "1.2",
      wk25: "1.2",
      wk26: "1.2",
      wk27: "1.2",
      wk28: "1.2",
      wk29: "1.2",
      wk30: "1.2",
      wk31: "1.2"
    });
    rows.push({
      fbs: "Kit",
      bs: "Kit",
      type: "Baseline",
      wk21: ".96",
      wk22: ".96",
      wk23: ".96",
      wk24: ".96",
      wk25: ".96",
      wk26: ".96",
      wk27: ".96",
      wk28: ".96",
      wk29: ".96",
      wk30: ".96",
      wk31: ".96"
    });
    rows.push({
      fbs: "Kit",
      bs: "",
      type: "Shock Factor",
      wk21: ".96",
      wk22: ".96",
      wk23: ".96",
      wk24: ".96",
      wk25: ".96",
      wk26: ".96",
      wk27: ".96",
      wk28: ".96",
      wk29: ".96",
      wk30: ".96",
      wk31: ".96"
    });
    rows.push({
      fbs: "Kit",
      bs: "",
      type: "Drift Factor",
      wk21: ".96",
      wk22: ".96",
      wk23: ".96",
      wk24: ".96",
      wk25: ".96",
      wk26: ".96",
      wk27: ".96",
      wk28: ".96",
      wk29: ".96",
      wk30: ".96",
      wk31: ".96"
    });
    rows.push({
      fbs: "Kit",
      bs: "",
      type: "Adjusted Seasonality",
      wk21: ".96",
      wk22: ".96",
      wk23: ".96",
      wk24: ".96",
      wk25: ".96",
      wk26: ".96",
      wk27: ".96",
      wk28: ".96",
      wk29: ".96",
      wk30: ".96",
      wk31: ".96"
    });
    rows.push({
      fbs: "Meal",
      bs: "Meal",
      type: "Baseline",
      wk21: "1.2",
      wk22: "1.2",
      wk23: "1.2",
      wk24: "1.2",
      wk25: "1.2",
      wk26: "1.2",
      wk27: "1.2",
      wk28: "1.2",
      wk29: "1.2",
      wk30: "1.2",
      wk31: "1.2"
    });
    rows.push({
      fbs: "Meal",
      bs: "",
      type: "Shock Factor",
      wk21: "1.2",
      wk22: "1.2",
      wk23: "1.2",
      wk24: "1.2",
      wk25: "1.2",
      wk26: "1.2",
      wk27: "1.2",
      wk28: "1.2",
      wk29: "1.2",
      wk30: "1.2",
      wk31: "1.2"
    });
    rows.push({
      fbs: "Meal",
      bs: "",
      type: "Drift Factor",
      wk21: "1.2",
      wk22: "1.2",
      wk23: "1.2",
      wk24: "1.2",
      wk25: "1.2",
      wk26: "1.2",
      wk27: "1.2",
      wk28: "1.2",
      wk29: "1.2",
      wk30: "1.2",
      wk31: "1.2"
    });
    rows.push({
      fbs: "Meal",
      bs: "",
      type: "Adjusted Seasonality",
      wk21: "1.2",
      wk22: "1.2",
      wk23: "1.2",
      wk24: "1.2",
      wk25: "1.2",
      wk26: "1.2",
      wk27: "1.2",
      wk28: "1.2",
      wk29: "1.2",
      wk30: "1.2",
      wk31: "1.2"
    });
    rows.push({
      fbs: "Salty Snack",
      bs: "Salty Snack",
      type: "Baseline",
      wk21: ".96",
      wk22: ".96",
      wk23: ".96",
      wk24: ".96",
      wk25: ".96",
      wk26: ".96",
      wk27: ".96",
      wk28: ".96",
      wk29: ".96",
      wk30: ".96",
      wk31: ".96"
    });
    rows.push({
      fbs: "Salty Snack",
      bs: "",
      type: "Shock Factor",
      wk21: ".96",
      wk22: ".96",
      wk23: ".96",
      wk24: ".96",
      wk25: ".96",
      wk26: ".96",
      wk27: ".96",
      wk28: ".96",
      wk29: ".96",
      wk30: ".96",
      wk31: ".96"
    });
    rows.push({
      fbs: "Salty Snack",
      bs: "",
      type: "Drift Factor",
      wk21: ".96",
      wk22: ".96",
      wk23: ".96",
      wk24: ".96",
      wk25: ".96",
      wk26: ".96",
      wk27: ".96",
      wk28: ".96",
      wk29: ".96",
      wk30: ".96",
      wk31: ".96"
    });
    rows.push({
      fbs: "Salty Snack",
      bs: "",
      type: "Adjusted Seasonality",
      wk21: ".96",
      wk22: ".96",
      wk23: ".96",
      wk24: ".96",
      wk25: ".96",
      wk26: ".96",
      wk27: ".96",
      wk28: ".96",
      wk29: ".96",
      wk30: ".96",
      wk31: ".96"
    });
    rows.push({
      fbs: "Shake",
      bs: "Shake",
      type: "Baseline",
      wk21: ".96",
      wk22: ".96",
      wk23: ".96",
      wk24: ".96",
      wk25: ".96",
      wk26: ".96",
      wk27: ".96",
      wk28: ".96",
      wk29: ".96",
      wk30: ".96",
      wk31: ".96"
    });
    rows.push({
      fbs: "Shake",
      bs: "",
      type: "Shock Factor",
      wk21: ".96",
      wk22: ".96",
      wk23: ".96",
      wk24: ".96",
      wk25: ".96",
      wk26: ".96",
      wk27: ".96",
      wk28: ".96",
      wk29: ".96",
      wk30: ".96",
      wk31: ".96"
    });
    rows.push({
      fbs: "Shake",
      bs: "",
      type: "Drift Factor",
      wk21: ".96",
      wk22: ".96",
      wk23: ".96",
      wk24: ".96",
      wk25: ".96",
      wk26: ".96",
      wk27: ".96",
      wk28: ".96",
      wk29: ".96",
      wk30: ".96",
      wk31: ".96"
    });
    rows.push({
      fbs: "Shake",
      bs: "",
      type: "Adjusted Seasonality",
      wk21: ".96",
      wk22: ".96",
      wk23: ".96",
      wk24: ".96",
      wk25: ".96",
      wk26: ".96",
      wk27: ".96",
      wk28: ".96",
      wk29: ".96",
      wk30: ".96",
      wk31: ".96"
    });

    return rows;
  };

  render() {
    return (
      <div style={{ height: "100%" }}>
        <AccucastGrid
          columnDefs={this.state.columnDefs}
          rowData={this.state.rowData}
          getRowClass={getRowClass}
          frameworkComponents={this.state.frameworkComponents}
        />
      </div>
    );
  }
}
