import React, { Component } from "react";
import { Gear } from "lib/icons";
import "./style.css";
import { DeleteIcon, Pen } from "lib/icons";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import { DotLoader } from "react-spinners";
import { connect } from "react-redux";

import axios from "axios";
import ReactDOM from "react-dom";
import { toast } from "react-toastify";

import { renameBatch, getProjects } from "components/accubase/reducers/accubaseReducer";

class ActionCellRenderer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      opened: false,
      showRenameModal: false,
      showDeleteBatchConfirmModal: false
    };

    this.BatchNameInput = React.createRef();
    this.clickHandler = this.clickHandler.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.deleteHandler = this.deleteHandler.bind(this);
    this.Manage = React.createRef();
    this.handleClickOutside = this.handleClickOutside.bind(this);
  }

  componentWillUnmount() {
    document.removeEventListener("mousedown", this.handleClickOutside);
  }
  handleClickOutside(event) {
    const area = ReactDOM.findDOMNode(this.Manage.current);
    if (area !== null) {
      if (!area.parentElement.contains(event.target)) {
        this.setState({ opened: false });
      }
    }
  }
  componentDidMount() {
    document.addEventListener("mousedown", this.handleClickOutside);
  }

  deleteHandler() {
    this.setState({ deletingBatch: true });
    axios
      .get(`${process.env.REACT_APP_BIGHORN_SERVER}/api/accubase/deleteInputData/${this.props.data.id}`, {
        headers: {
          ClientToken: localStorage.getItem("clientToken")
        }
      })
      .then(res => {
        this.props.getProjects(false);

        this.handleClose();
        this.setState({ deletingBatch: false });
        toast.success(this.props.data.name + " Deleted");
      });
  }
  handleClose() {
    this.setState({
      showRenameModal: false,
      showDeleteBatchConfirmModal: false
    });
  }
  clickHandler() {
    this.setState({ opened: !this.state.opened });
  }

  renameHandler(id, projectId) {
    this.props.renameBatch(id, this.BatchNameInput.current.value, projectId);
    this.handleClose();
  }

  render() {
    const { showRenameModal, showDeleteBatchConfirmModal } = this.state;
    return (
      <div className="dropdown-wrapper">
        <Modal isOpen={showRenameModal}>
          <ModalHeader>Rename Batch</ModalHeader>
          <ModalBody>
            <div>
              <input style={{ width: "100%" }} defaultValue={this.props.data.name} ref={this.BatchNameInput} />
            </div>
          </ModalBody>

          <ModalFooter>
            {!this.state.deletingProject ? (
              <Button
                color="primary"
                onClick={() => {
                  this.renameHandler(this.props.data.id, this.props.selectedProject.ID);
                }}>
                Rename
              </Button>
            ) : (
              <div
                className="accubase-uploading"
                style={{
                  marginLeft: "auto",

                  display: "flex",
                  flexDirection: "row"
                }}>
                <DotLoader sizeUnit={"px"} size={15} color={"black"} loading={true} />
                <div style={{ marginLeft: "5px" }}>Updating ...</div>
              </div>
            )}
            <Button onClick={this.handleClose}>Cancel</Button>
          </ModalFooter>
        </Modal>
        <Modal isOpen={showDeleteBatchConfirmModal}>
          <ModalHeader>Delete Input Source</ModalHeader>
          <ModalBody>
            <div>
              Are you sure you want to delete this input source ({this.props.data.name})? All related baselines will also be
              deleted. WARNING: This cannot be undone.
            </div>
          </ModalBody>

          <ModalFooter>
            {!this.state.deletingBatch ? (
              <Button
                color="primary"
                onClick={() => {
                  this.deleteHandler("batch");
                }}>
                Delete
              </Button>
            ) : (
              <div
                className="accubase-uploading"
                style={{
                  marginLeft: "auto",

                  display: "flex",
                  flexDirection: "row"
                }}>
                <DotLoader sizeUnit={"px"} size={15} color={"black"} loading={true} />
                <div style={{ marginLeft: "5px" }}>Deleting ...</div>
              </div>
            )}
            <Button onClick={this.handleClose}>Cancel</Button>
          </ModalFooter>
        </Modal>

        <div
          style={{ cursor: "pointer" }}
          onClick={() => {
            this.clickHandler();
          }}>
          <Gear className="selectable gear" height={"20px"} width={"20px"} color={"rgba(165, 165, 165, 1)"} />
        </div>
        {this.state.opened && (
          <ul
            className="dropdown-menu"
            ref={this.Manage}
            style={{
              left: "-128px",
              borderRadius: "0",
              borderBottom: "1px solid rgb(229, 230, 232)",
              position: "absolute",
              display: "inline-block",
              paddingTop: "0px",
              paddingBottom: "0px",
              boxShadow: "0px 3px 6px rgba(0, 0, 0, 0.16)"
            }}>
            <li
              style={{
                cursor: "pointer",
                borderBottom: "1px solid rgb(229, 230, 232)",
                paddingLeft: "10px",
                paddingRight: "10px"
              }}
              className="menu-item"
              onClick={() => {
                this.setState({ showRenameModal: true, opened: false });
              }}>
              <Pen
                height={"16px"}
                width={"16px"}
                color={"rgba(165, 165, 165, 1)"}
                style={{ marginRight: "10px", cursor: "pointer" }}
              />
              Rename
            </li>
            <li
              style={{
                cursor: "pointer",
                paddingLeft: "10px",
                paddingRight: "10px"
              }}
              className="menu-item"
              onClick={() => {
                this.setState({
                  showDeleteBatchConfirmModal: true,
                  opened: false
                });
              }}>
              <DeleteIcon
                height={"16px"}
                width={"16px"}
                color={"rgba(165, 165, 165, 1)"}
                style={{
                  cursor: "pointer",
                  marginBottom: "3px",
                  marginRight: "10px"
                }}
              />
              Delete
            </li>
          </ul>
        )}
      </div>
    );
  }
}

const mapStateToProps = state => ({
  selectedProject: state.accubase.selectedProject
});
const mapDispatchToProps = dispatch => ({
  renameBatch(id, name, pId) {
    dispatch(renameBatch(id, name, pId));
  },
  getProjects(selectProject = true) {
    dispatch(getProjects(selectProject));
  }
});

export default connect(mapStateToProps, mapDispatchToProps)(ActionCellRenderer);
