import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { setActiveReport } from "components/reports/components/reportList/reducers/reportListReducer";
import { setDisplayedFeaturedReports } from "components/reports/components/reportList/reducers/reportListReducer";
import { addTabToNav, removeTabFromNav, setSelectedTab } from "components/topLevelNav/reducers/topLevelNavReducer";

import "./style/style.css";

import {
  LiftModelIcon,
  PromoPlanIcon,
  BaselineIcon,
  InputSourceIcon,
  ReportSvg,
  FolderMgmtIcon,
  AppChartAnalytics,
  AppChartUser,
  AppChartBar,
  AppChartLine,
  AppChartLineDown,
  AppPie,
  FolderIcon,
  RightChevron,
  CloseIcon
} from "lib/icons";
import { filterApps, setPerformCollapse, setReportAppStatus, setReportGroup, setCustomGroup } from "reducers/appReducer";

import PowerBIFolder from "./powerBIFolder";

class ReportItem extends Component {
  constructor(props) {
    super(props);
    this.state = {
      collapsed: this.props.collapseFolders
    };
    this.clickReportHandler = this.clickReportHandler.bind(this);
    this.collapseHandler = this.collapseHandler.bind(this);
    this.renderCollapsible = this.renderCollapsible.bind(this);
    this.appClickHandler = this.appClickHandler.bind(this);
    this.goTo = this.goTo.bind(this);
  }

  collapseHandler() {
    this.setState(prevState => ({
      collapsed: !prevState.collapsed
    }));
  }
  componentDidUpdate() {
    const { performCollapseAction } = this.props;
    if (performCollapseAction) {
      this.setState({ collapsed: this.props.collapseFolders });
      this.props.setPerformCollapse(false);
    }
  }
  renderCollapsible() {
    return (
      <div className="menu-list-collapse">
        <RightChevron
          transform={this.state.collapsed ? "" : "rotate(90,12,12)"}
          height={"20px"}
          // width={"24px"}
          color={"rgba(88, 89, 91, 1)"}
          viewBox={"-1 2 24 24"}
        />
      </div>
    );
    //}
  }
  clickReportHandler(event, wIndex, rIndex) {
    const { workspaces } = this.props;

    if (event !== "") {
      event.stopPropagation();
    }
    this.props.filterApps("", this.props.apps);

    //let rptIndex = rIndex;
    // wIndex.reports.forEach(rpt => {
    //   if (rpt.name === event.target.innerText) {
    //     rptIndex = rpt;
    //   }
    // });
    //TODO: when we get different groups change this to handle them
    //currently it only does power bi reports
    this.props.setActiveReport(wIndex, rIndex);
    // if (rIndex.type === "staticContent") {
    //   this.props.history.push(
    //     "/sc/report/" + wIndex.workspaceId + "+" + rIndex.id
    //   );
    //   this.props.addTabToNav({
    //     type: "staticContent",
    //     title: rIndex.name,
    //     path: "/sc/report/" + wIndex.workspaceId + "+" + rIndex.id,
    //     id: wIndex.workspaceId + "+" + rIndex.id
    //   });
    // } else if (workspace.type === "tableau") {
    //   this.props.history.push(
    //     "/tableau/report/" + wIndex.workspaceId + "+" + rIndex.id
    //   );
    //   this.props.addTabToNav({
    //     type: "tableau",
    //     title: rIndex.name,
    //     path: "/tableau/report/" + wIndex.workspaceId + "+" + rIndex.id,
    //     id: wIndex.workspaceId + "+" + rIndex.id,
    //     workspaceName: wIndex.name,
    //     workspaceId: wIndex.workspaceId
    //   });
    // } else {
    //   this.props.history.push(
    //     "/powerBI/report/" + wIndex.workspaceId + "+" + rIndex.id
    //   );
    //   this.props.addTabToNav({
    //     type: "powerBI",
    //     title: rIndex.name,
    //     path: "/powerBI/report/" + wIndex.workspaceId + "+" + rIndex.id,
    //     id: wIndex.workspaceId + "+" + rIndex.id,
    //     workspaceName: wIndex.name,
    //     children: wIndex.items,
    //     workspaceId: wIndex.workspaceId,
    //     workspaces
    //   });
    // }
    this.props.history.push("/reports/landing/" + wIndex.workspaceId);
    let icon = wIndex.icon;
    if (icon === undefined) {
      icon = wIndex.type;
    }

    let findWS = this.props.workspaces.filter(item => {
      if (item.workspaceId !== undefined && wIndex.workspaceId.includes("-")) {
        return item.workspaceId === wIndex.workspaceId;
      }
      return item._id === wIndex.workspaceId;
    });
    findWS = findWS[0];

    let featuredRpts = findWS.reports.filter(item => {
      return item.featured === true;
    });
    this.props.setDisplayedFeaturedReports(featuredRpts);
    this.props.addTabToNav({
      type: wIndex.type,
      title: rIndex.name,
      path: "/reports/landing" + wIndex.workspaceId,
      icon,
      id: wIndex.workspaceId,
      workspaceName: wIndex.name,
      children: wIndex.items,
      workspaceId: wIndex.workspaceId,
      workspaces,
      alias: wIndex.alias
    });

    this.props.handleClickOutside();
  }

  renderWorkspace(workspace) {
    const { collapsed } = this.state;

    if (!collapsed) {
      return workspace.items.map((report, rIndex) => {
        if (report.fileType === "folder") {
          return (
            <PowerBIFolder clickReportHandler={this.clickReportHandler} key={rIndex} report={report} workspace={workspace} />
          );
        }
        return (
          <div className="ta-report-name" key={rIndex} onClick={event => this.clickReportHandler(event, workspace, report)}>
            <ReportSvg height={"16px"} className={"ta-pbi-icon"} color={"rgba(121, 121, 121, 1)"} viewBox={"0 -1 24 24"} />
            {report.name}
          </div>
        );
      });
    }
    //return appArr;
  }
  appClickHandler(app) {
    this.props.setReportAppStatus(false);
    this.props.setReportGroup(app.workspaceId);
    this.props.setCustomGroup("");
    // this.clickReportHandler("", app, -1);

    this.props.history.push("/reports/landing/" + app.workspaceId);
    this.props.handleClickOutside();
  }
  goTo(route) {
    this.props.handleClickOutside();
    this.props.filterApps("", this.props.apps);
    this.props.history.push(route);
  }
  closeReportGroup(e, item) {
    e.preventDefault();
    e.stopPropagation();
    this.props.removeTabFromNav(item, 1);

    if (this.props.tabs.length >= 2) {
      let tabLocation = 1;
      this.props.tabs.forEach((tab, idx) => {
        if (tab.workspaceId === undefined) {
          if (item.type === tab.type) {
            tabLocation = idx;
          }
        } else {
          if (item.workspaceId === tab.workspaceId) {
            tabLocation = idx;
          }
        }
      });
      if (tabLocation === 0 && this.props.tabs.length > 2) {
        this.props.setSelectedTab([1, 0]);
        this.goTo(this.props.tabs[1].path);
      } else if (tabLocation === 0) {
        this.props.setSelectedTab([0, 0]);
        this.goTo(this.props.tabs[1].path);
      } else {
        if (this.props.tabs[tabLocation - 1].path === undefined) {
          let ntWorkspaceId = this.props.tabs[tabLocation - 1].workspaceId;
          // let wksFlt = this.props.workspaces.filter(item => {
          //   return item.workspaceId === ntWorkspaceId;
          // });
          // wksFlt = wksFlt[0];
          this.goTo("/reports/landing/" + ntWorkspaceId);
          this.props.setReportGroup(ntWorkspaceId);
        } else {
          this.goTo(this.props.tabs[tabLocation - 1].path);
        }
        this.props.setSelectedTab([tabLocation - 1, 0]);
      }
    } else {
      this.props.setReportGroup("");
      this.props.setCustomGroup("");
      this.props.setSelectedTab([-1, -1]);

      this.goTo("/reports/landing");
    }
    // e.stopPropagation();
    // e.preventDefault();

    // let rmGroup = reportGroup;
    // rmGroup.index = this.props.selectedTab[0];
    // this.props.removeTabFromNav(rmGroup);
  }
  render() {
    const { wIndex, workspace, tabs, selectedTab } = this.props;
    let name = workspace.name;

    if (workspace.alias !== undefined && workspace.alias !== "") {
      name = workspace.alias;
    }
    if (name === undefined || name === null) {
      name = workspace.workspaceName;
    }
    let icon;

    if (workspace.icon !== undefined) {
      switch (workspace.icon) {
        case 1:
          icon = (
            <div className="dd-icon-list-item">
              <AppChartAnalytics
                height={"18px"}
                // width={"24px"}
                color={"#2E5CA6"}
                viewBox={"-1 2 24 24"}
              />
            </div>
          );
          break;
        case 2:
          icon = (
            <div className="dd-icon-list-item">
              <AppChartUser
                height={"18px"}
                // width={"24px"}
                color={"#2E5CA6"}
                viewBox={"-1 2 24 24"}
              />
            </div>
          );
          break;
        case 3:
          icon = (
            <div className="dd-icon-list-item">
              <AppChartBar
                height={"18px"}
                // width={"24px"}
                color={"#2E5CA6"}
                viewBox={"-1 2 24 24"}
              />
            </div>
          );
          break;
        case 4:
          icon = (
            <div className="dd-icon-list-item">
              <AppChartLine
                height={"18px"}
                // width={"24px"}
                color={"#2E5CA6"}
                viewBox={"-1 2 24 24"}
              />
            </div>
          );
          break;
        case 5:
          icon = (
            <div className="dd-icon-list-item">
              <AppChartLineDown
                height={"18px"}
                // width={"24px"}
                color={"#2E5CA6"}
                viewBox={"-1 2 24 24"}
              />
            </div>
          );
          break;
        case 6:
          icon = (
            <div className="dd-icon-list-item">
              <AppPie
                height={"18px"}
                // width={"24px"}
                color={"#2E5CA6"}
                viewBox={"-1 2 24 24"}
              />
            </div>
          );
          break;
        case 7:
        case "files":
          icon = (
            <div className="dd-icon-list-item">
              <FolderIcon
                height={"18px"}
                // width={"24px"}
                color={"#2E5CA6"}
                viewBox={"-1 2 24 24"}
              />
            </div>
          );
          break;
        case 8:
        case "acculift":
          icon = (
            <div className="dd-icon-list-item">
              <LiftModelIcon
                height={"18px"}
                // width={"24px"}
                color={"#2E5CA6"}
                viewBox={"-1 2 24 24"}
              />
            </div>
          );
          break;
        case 9:
        case "promoOpt":
          icon = (
            <div className="dd-icon-list-item">
              <PromoPlanIcon
                height={"18px"}
                // width={"24px"}
                color={"#2E5CA6"}
                fill={"#2E5CA6"}
                viewBox={"-1 2 24 24"}
              />
            </div>
          );
          break;
        case 10:
        case "accubase":
          icon = (
            <div className="dd-icon-list-item">
              <BaselineIcon
                height={"18px"}
                // width={"24px"}
                color={"#2E5CA6"}
                viewBox={"-1 2 24 24"}
              />
            </div>
          );
          break;
        case 11:
        case "mdm":
          icon = (
            <div className="dd-icon-list-item">
              <InputSourceIcon
                height={"18px"}
                // width={"24px"}
                color={"#2E5CA6"}
                fill={"#2E5CA6"}
                viewBox={"-1 2 24 24"}
              />
            </div>
          );
          break;
        case 12:
        case "powerBI":
        case "tableau":
          icon = (
            <div className="dd-icon-list-item" style={{ marginTop: "-3px" }}>
              <ReportSvg
                height={"16px"}
                // width={"24px"}
                color={"#2E5CA6"}
                fill={"#2E5CA6"}
                // viewBox={"0 -1 24 24"}
              />
            </div>
          );
          break;
        case "powerBIMgmt":
          icon = (
            <div className="dd-icon-list-item" style={{ marginTop: "1px" }}>
              <FolderMgmtIcon
                height={"18px"}
                // width={"24px"}
                color={"#2E5CA6"}
                fill={"#2E5CA6"}
                viewBox={"-1 2 24 24"}
              />
            </div>
          );
          break;
        default:
          break;
      }
    }
    let itemIsActive, itemIsSelected;
    if (selectedTab[0] === -1 || tabs.length === 0) {
      if (this.props.tabs.length === 0) {
        itemIsActive = [];
        itemIsSelected = false;
      } else {
        itemIsSelected = false;
        itemIsActive = tabs.filter(item => {
          return item.workspaceId === workspace.workspaceId;
        });
      }
    } else {
      if (tabs[selectedTab[0]].workspaceId === undefined) {
        itemIsSelected = false;
      } else {
        itemIsSelected = tabs[selectedTab[0]].workspaceId === workspace.workspaceId;
      }
      itemIsActive = tabs.filter(item => {
        return item.workspaceId === workspace.workspaceId;
      });
    }
    if (workspace.hidden) {
      return <div />;
    }
    if (itemIsSelected) {
      return (
        <div key={wIndex}>
          <div
            className="ta-workspace-name"
            style={{
              fontStyle: "italic",
              color: "#4285f4",
              paddingRight: "5px"
            }}
            onClick={() => this.appClickHandler(workspace)}>
            {icon === null || icon === undefined ? (
              <div className="dd-icon-list-item">
                <ReportSvg height={"14px"} color={"#2E5CA6"} className="noHover" />
              </div>
            ) : (
              icon
            )}
            {name}
            <div
              onClick={e => {
                this.closeReportGroup(e, workspace);
              }}
              className="close-x-subtitle">
              <CloseIcon height={"10px"} viewBox={"0 0 20 20"} className="close-icon" />
            </div>
          </div>
          {/* <div className="parent-folder">{this.renderWorkspace(workspace)}</div> */}
        </div>
      );
    } else if (itemIsActive.length > 0) {
      return (
        <div key={wIndex}>
          <div
            className="ta-workspace-name"
            style={{
              fontStyle: "italic",
              paddingRight: "5px"
            }}
            onClick={() => this.appClickHandler(workspace)}>
            {icon === null || icon === undefined ? (
              <div className="dd-icon-list-item">
                <ReportSvg height={"14px"} color={"#2E5CA6"} className="noHover" />
              </div>
            ) : (
              icon
            )}
            {name}
            <div
              onClick={e => {
                this.closeReportGroup(e, workspace);
              }}
              className="close-x-subtitle">
              <CloseIcon height={"10px"} viewBox={"0 0 20 20"} className="close-icon" />
            </div>
          </div>
          {/* <div className="parent-folder">{this.renderWorkspace(workspace)}</div> */}
        </div>
      );
    } else {
      return (
        <div key={wIndex}>
          <div className="ta-workspace-name" onClick={() => this.appClickHandler(workspace)}>
            {icon === null || icon === undefined ? (
              <div className="dd-icon-list-item">
                <ReportSvg height={"14px"} color={"#2E5CA6"} className="noHover" />
              </div>
            ) : (
              icon
            )}
            {name}
          </div>
          {/* <div className="parent-folder">{this.renderWorkspace(workspace)}</div> */}
        </div>
      );
    }
  }
}

const mapStateToProps = state => ({
  workspaces: state.reportList.workspaces,
  apps: state.app.apps,
  performCollapseAction: state.app.performCollapseAction,
  collapseFolders: state.app.collapseFolders,
  selectedTab: state.topLevelNav.selectedTab,
  tabs: state.topLevelNav.tabs,
  reportGroup: state.app.reportGroup
});
const mapDispatchToProps = dispatch => ({
  setActiveReport(wIndex, rIndex) {
    dispatch(setActiveReport(wIndex, rIndex));
  },
  addTabToNav(tab) {
    dispatch(addTabToNav(tab));
  },
  filterApps(filter, apps) {
    dispatch(filterApps(filter, apps));
  },
  setPerformCollapse(status) {
    dispatch(setPerformCollapse(status));
  },
  setReportAppStatus(status) {
    dispatch(setReportAppStatus(status));
  },
  setReportGroup(group) {
    dispatch(setReportGroup(group));
  },
  removeTabFromNav(tab) {
    dispatch(removeTabFromNav(tab));
  },
  setSelectedTab(tab) {
    dispatch(setSelectedTab(tab));
  },

  setCustomGroup(group) {
    dispatch(setCustomGroup(group));
  },
  setDisplayedFeaturedReports(featured) {
    dispatch(setDisplayedFeaturedReports(featured));
  }
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ReportItem));
