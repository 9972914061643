import React, { Component } from "react";

import "./style.css";
import { ItemAttributes } from "lib/icons";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";

import { connect } from "react-redux";
import axios from "axios";
import { getProjects } from "components/accubase/reducers/accubaseReducer";
import { DotLoader } from "react-spinners";

class ItemCellRenderer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      opened: false,
      showUploadErrorDialog: false,
      uploadingItem: false,
      batchName: "",
      batchId: -1
    };
    this.uploadFile = this.uploadFile.bind(this);
    this.handleClose = this.handleClose.bind(this);
  }

  handleClose() {
    this.setState({ showUploadErrorDialog: false });
  }

  uploadFile(type, event) {
    var batchName = this.props.data.name;
    var batchId = this.props.data.id;

    const { selectedProject } = this.props;

    event.preventDefault();
    if (
      event.target.files[0].type === "text/csv" ||
      event.target.files[0].type === "application/vnd.ms-excel"
    ) {
      this.setState({ uploadingItem: true });

      const data = new FormData();
      data.append("file", event.target.files[0]);
      data.append("projectId", selectedProject.ID);
      data.append("batchName", batchName);
      data.append("batchId", batchId);
      data.append("type", type);

      axios
        .post(
          `${process.env.REACT_APP_BIGHORN_SERVER}/api/accubase/uploadInputFile`,
          data,
          {
            headers: {
              ClientToken: localStorage.getItem("clientToken")
            }
          }
        )
        .then(res => {
          console.log(res);

          this.setState({ uploadingItem: false });

          this.props.getProjects(false);
        })
        .catch(err => {
          this.setState({ uploadingItem: false });

          if (err.response !== undefined) {
            this.setState({
              showUploadErrorDialog: true,
              uploadError: err.response.data.message
            });
          }
        });
    } else {
      this.setState({
        showUploadErrorDialog: true,
        uploadError: "The upload file must be a csv"
      });
    }
  }
  render() {
    const { value } = this.props;
    const { showUploadErrorDialog, uploadingItem } = this.state;
    if (uploadingItem) {
      return (
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            width: "130px"
          }}
        >
          <DotLoader sizeUnit={"px"} size={15} color={"black"} loading={true} />
          <div style={{ marginLeft: "5px", width: "100px" }}>Uploading...</div>
        </div>
      );
    } else {
      return (
        <div>
          <label
            className={!value ? "template-instruct-dialog-open" : ""}
            htmlFor={"uploadItem" + this.props.data.id}
            style={{
              display: "flex",
              flexDirection: "row",
              paddingLeft: "3px",
              width: "97px",
              cursor: "pointer"
            }}
          >
            <Modal isOpen={showUploadErrorDialog}>
              <ModalHeader>Upload Error</ModalHeader>
              <ModalBody>
                <div>{this.state.uploadError}</div>
              </ModalBody>

              <ModalFooter>
                <Button onClick={this.handleClose}>Ok</Button>
              </ModalFooter>
            </Modal>
            <div>
              <ItemAttributes
                height={"15px"}
                width={"15px"}
                color={"rgb(54, 127, 167)"}
              />
            </div>

            {value ? (
              <div style={{ marginLeft: "3px", color: "rgb(54, 127, 167)" }}>
                Item Uploaded
              </div>
            ) : (
              <div style={{ marginLeft: "3px" }} className="">
                Upload Item
              </div>
            )}
          </label>
          <input
            hidden={true}
            id={"uploadItem" + this.props.data.id}
            ref
            type="file"
            name="Upload"
            onChange={event => {
              this.uploadFile("item", event);
            }}
            onClick={event => {
              event.target.value = null;
            }}
          />
        </div>
      );
    }
  }
}

const mapStateToProps = state => ({
  selectedProject: state.accubase.selectedProject
});
const mapDispatchToProps = dispatch => ({
  getProjects(selectProject = true) {
    dispatch(getProjects(selectProject));
  }
});

export default connect(mapStateToProps, mapDispatchToProps)(ItemCellRenderer);
