/* -------------------- INITIAL STATE -------------------- */
const INITIAL_STATE = {
  loaded: false,
  idpLogin: false,
  isAuthed: false,
  loginErrors: {},
  userInfo: {}
};

/* -------------------- ACTION TYPES -------------------- */
const SET_LOADED = "SET_LOADED";
const SET_IDP_LOGIN = "SET_IDP_LOGIN";
const SET_LOGOUT = "SET_LOGOUT";

/* -------------------- ACTION CREATORS -------------------- */
const setLoaded = value => {
  return { type: SET_LOADED, payload: value };
};
const setIdpLogin = value => {
  return { type: SET_IDP_LOGIN, payload: value };
};
const setLogout = () => {
  return { type: SET_LOGOUT };
};

/* -------------------- ASYNC ACTION CREATORS -------------------- */
export const setAuthLoaded = value => {
  return dispatch => {
    dispatch(setLoaded(value));
  };
};
export const logout = () => {
  return dispatch => {
    dispatch(setLogout());
  };
};
export const setIdpInitiatedLogin = value => {
  return dispatch => {
    dispatch(setIdpLogin(value));
  };
};

/* -------------------- REDUCER -------------------- */
export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case SET_LOADED:
      return { ...state, loaded: action.payload, isAuthed: true };
    case SET_LOGOUT:
      return { ...state, isAuthed: false };
    case SET_IDP_LOGIN:
      return { ...state, idpLogin: action.payload };
    default:
      return state;
  }
};
