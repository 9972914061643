import React, { Component } from "react";
import "components/accucast/components/style/style.css";
import { connect } from "react-redux";
import {
  nextWorkflowStep,
  resetWorkflowSteps
} from "components/accucast/components/updateLE/reducers/secondLevelLENavReducer";

class SecondLevelNavController extends Component {
  constructor(props) {
    super(props);
    this.nextClickHandler = this.nextClickHandler.bind(this);
    this.resetClickHandler = this.resetClickHandler.bind(this);
  }
  nextClickHandler() {
    this.props.nextWorkflowStep(this.props.workflow.currentStep);
  }
  resetClickHandler() {
    this.props.resetWorkflowSteps();
  }

  render() {
    return (
      <div className="ta-second-nav-cont flex-container">
        <div className="ta-second-nav-cont-action">
          <button className="ta-button ta-button-left">SAVE</button>
          <button className="ta-button" onClick={this.resetClickHandler}>
            RESET
          </button>
          <button
            className="ta-button ta-button-right"
            onClick={this.nextClickHandler}
          >
            NEXT >
          </button>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  workflow: state.accucastLE
});
const mapDispatchToProps = dispatch => ({
  nextWorkflowStep(curStep) {
    dispatch(nextWorkflowStep(curStep));
  },
  resetWorkflowSteps() {
    dispatch(resetWorkflowSteps());
  }
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SecondLevelNavController);
