export function numberNewValueHandler(params) {
  //var valueAsNumber = parseFloat(params.newValue);
  if (params.oldValue !== params.newValue) {
    //console.log(params);
    params.data[params.column.colId + "Changed"] = true;
    var field = params.colDef.field;
    var data = params.data;
    data[field] = params.newValue;
  }
}
