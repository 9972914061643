import React, { Component } from "react";

import "components/powerBI/components/report/style/style.css";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { addTabToNav } from "components/topLevelNav/reducers/topLevelNavReducer";
import {
  getMdmConfig,
  getMdmData,
  addNewRow,
  removeRows,
  setMdmData,
  getActions,
  setFullLoad,
  setRefresh,
  resetInfo,
  resetError,
  setExternalFilter,
  setLoadedData,
  doSetInfoDialog,
  doSetErrorDialog
} from "components/mdm/reducers/mdmReducer";
import { Link } from "react-router-dom";
import DMGrid from "./dmGrid";

import { cloneDeep } from "lodash";
import "../../style.css";
import axios from "axios";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";

import { DotLoader } from "react-spinners";
import { LoadingIndicator } from "lib/loadingIndicator";
import { socket } from "routes";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Select, { components } from "react-select";
import { RefreshIcon } from "lib/icons";
import saveAs from "file-saver";

const MultiValue = props => {
  let labelToBeDisplayed = `${props.data.label} `;
  if (props.selectProps.value.length > 1) {
    labelToBeDisplayed = "multiple selections";
    return (
      <components.MultiValue {...props}>
        <span style={{ fontStyle: "italic" }}>{labelToBeDisplayed}</span>
      </components.MultiValue>
    );
  }

  return (
    <components.MultiValue {...props}>
      <span>{labelToBeDisplayed}</span>
    </components.MultiValue>
  );
};

const ValueContainer = ({ children, ...props }) => {
  // const currentValues = props.getValue();
  let toBeRendered = children;

  if (props.selectProps.value !== null && props.selectProps.value.length > 1) {
    toBeRendered = [[children[0][0]], children[1]];
  }

  return <components.ValueContainer {...props}>{toBeRendered}</components.ValueContainer>;
};
class Option extends Component {
  render() {
    return (
      <div>
        <components.Option {...this.props}>
          <input type="checkbox" checked={this.props.isSelected} onChange={e => null} /> <label>{this.props.value} </label>
        </components.Option>
      </div>
    );
  }
}
class DataManagement extends Component {
  constructor(props) {
    super(props);
    const CancelToken = axios.CancelToken;
    const CancelDataToken = axios.CancelToken;
    this.nrowsPerQuery = 5000;
    this.state = {
      modifiedRows: [],
      deletedRows: [],
      currentTable: [],
      oldData: [],

      activeIdx: 0,
      currentAppIdx: 1,
      showDeleteConfirmModal: false,
      showUnsavedChangesModal: false,
      showInvalidSave: false,
      showInfoModal: false,
      dirty: false,
      desc: "",
      cancelAppendToken: CancelToken.source(),
      cancelDataToken: CancelDataToken.source(),
      info: [],
      errorMessage: "",
      showErrorModal: false,
      saving: false,
      runningAction: false,
      navClickedIdx: null,
      selectedQuickFilters: null,
      activeProject: null,
      activeProjectIdx: 0,
      loaded: false,
      showUploadModal: false,
      primaryTable: null,
      importStatus: "",
      importErrors: [],
      showProgressModal: false,
      importId: "",
      runUpdate: false
    };
    this.agGrid = React.createRef();
    this.gridReady = this.gridReady.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.removeRows = this.removeRows.bind(this);
    this.setCancelToken = this.setCancelToken.bind(this);
    this.setDirty = this.setDirty.bind(this);
    this.quickFilterClick = this.quickFilterClick.bind(this);
    this.setQuickFilterClass = this.setQuickFilterClass.bind(this);
    this.actionRunResultHandler = this.actionRunResultHandler.bind(this);
    this.importStatusHandler = this.importStatusHandler.bind(this);
    this.handleProjectChange = this.handleProjectChange.bind(this);
    this.handleselectedFile = this.handleselectedFile.bind(this);
    this.setShowUploadModal = this.setShowUploadModal.bind(this);
    this.uploadFile = this.uploadFile.bind(this);
    this.getErrorCSV = this.getErrorCSV.bind(this);
    this.renderImportDataStatus = this.renderImportDataStatus.bind(this);
  }
  componentDidMount() {
    const { tabs, loadedData, mdmConfig } = this.props;
    // const { info } = this.state;

    socket.on("ActionRunResult", this.actionRunResultHandler);
    socket.on("ImportStatus", this.importStatusHandler);

    // if (!loadedConfig) {
    //   this.props.getMdmConfig(
    //     this.props.location.pathname.split("/")[3].split("+")[0],
    //     this.props.location.pathname.split("/")[3].split("+")[1]
    //   );
    // }

    let table = this.props.location.pathname.split("/")[3].split("+")[2];

    if (table !== undefined) {
      let currentTable;
      if (loadedData) {
        this.props.setRefresh(true);
        currentTable = mdmConfig[table].data;
      }
      this.setState({
        activeIdx: table,
        currentAppIdx: this.props.location.pathname.split("/")[3].split("+")[0],
        activeProjectIdx: this.props.location.pathname.split("/")[3].split("+")[1],
        currentTable
      });
    } else {
      this.props.history.push("/mdm/dataManagement/1+0+0");
    }

    if (tabs.length === 0) {
    }
  }
  componentDidUpdate() {
    const { loadedConfig, loadedData, mdmConfig, info, errorMessage, loadingData, mdmApps } = this.props;
    const { activeIdx, cancelDataToken, currentAppIdx, cancelAppendToken, activeProjectIdx, loaded } = this.state;

    let primaryTables = [];
    let findIdx = 0;
    if (!loaded && mdmApps.length > 0) {
      findIdx = 0;
      mdmApps.forEach((item, idx) => {
        if (item.ID === parseInt(currentAppIdx, 10)) {
          findIdx = idx;
        }
      });

      this.props.getMdmConfig(mdmApps[findIdx].ID, 0);

      let project = this.props.history.location.pathname.split("+")[1];
      let table = this.props.history.location.pathname.split("+")[2];
      if (project === undefined) {
        project = 0;
      }
      if (table === undefined) {
        table = 0;
      }
      this.props.history.replace("/mdm/dataManagement/" + mdmApps[findIdx].ID + "+" + project + "+" + table);
      //TODO MAKE SURE THIS IS WORKIGN ... chagned icon from mdm to MDH
      this.props.addTabToNav({
        mdmIdx: findIdx,
        appId: mdmApps[findIdx].ID,
        title: mdmApps[findIdx].APP_NAME,
        id: mdmApps[findIdx].ID + "+0+0",
        type: "mdh",
        icon: "mdh",
        path: "/mdm/dataManagement/" + mdmApps[findIdx].ID + "+" + project + "+" + table
      });
      this.setState({ loaded: true, currentAppIdx: mdmApps[findIdx].ID });
    }

    var currentApp = mdmApps.filter(item => {
      return item.ID === parseInt(currentAppIdx, 10);
    });
    currentApp = currentApp[0];
    if (mdmApps.length > 0) {
      let newApp = this.props.history.location.pathname.split("+")[0];
      newApp = parseInt(newApp.split("dataManagement/")[1], 10);

      if (currentApp === undefined || currentApp.ID !== newApp) {
        findIdx = 0;
        mdmApps.forEach((item, idx) => {
          if (item.ID === parseInt(newApp, 10)) {
            findIdx = idx;
          }
        });
        this.props.getMdmConfig(mdmApps[findIdx].ID, 0);

        this.setState({ currentAppIdx: mdmApps[findIdx].ID });
      }
    }
    if (loadedConfig && !loadedData && !loadingData) {
      var currentProject = currentApp.PROJECTS.filter(item => {
        return item.PROJECT_ID === parseInt(activeProjectIdx, 10);
      });
      currentProject = currentProject[0];
      if (currentProject === undefined) {
        currentProject = currentApp.PROJECTS[0];
      }

      this.setState({
        activeProject: {
          id: currentProject.PROJECT_ID,
          label: currentProject.PROJECT_NAME,
          value: currentProject.PROJECT_NAME
        }
      });
      primaryTables = mdmConfig.filter(item => {
        return item.primaryTable;
      });
      if (activeIdx < mdmConfig.length) {
        if (
          (activeIdx === "0" && primaryTables[0].idx !== 0) ||
          (activeIdx === "0" && currentApp.PROJECTS[0].PROJECT_ID !== 0)
        ) {
          this.props.history.push(`/mdm/dataManagement/${currentAppIdx}+${currentProject.PROJECT_ID}+${primaryTables[0].idx}`);
          const CancelDataToken = axios.CancelToken;
          const newCancelDataToken = CancelDataToken.source();
          cancelAppendToken.cancel("Cancelled By Nav Click");
          cancelDataToken.cancel("Cancelled Data by Nav Click");
          this.props.getMdmData(currentProject.PROJECT_ID, primaryTables[0].id, newCancelDataToken);
          this.props.getActions(primaryTables[0].id, currentProject.PROJECT_ID);
          this.props.setFullLoad(false);
          this.setState({
            currentTable: primaryTables[0].data,
            desc: primaryTables[0].desc,
            activeIdx: primaryTables[0].idx,
            activeProjectIdx: currentProject.PROJECT_ID,
            primaryTable: primaryTables[0].id,
            cancelDataToken: newCancelDataToken
          });
        } else {
          this.props.getMdmData(currentProject.PROJECT_ID, mdmConfig[activeIdx].id, cancelDataToken);
          this.props.getActions(mdmConfig[activeIdx].id, currentProject.PROJECT_ID);
          this.props.setFullLoad(false);
          this.setState({
            currentTable: mdmConfig[activeIdx].data,
            desc: mdmConfig[activeIdx].desc,
            primaryTable: mdmConfig[activeIdx].id,
            runningAction: false
          });
        }
      }
    }

    if (info !== "") {
      this.props.resetInfo();
      toast.success(info);
      //this.setState({ showInfoModal: true, info });
    }
    if (errorMessage !== "") {
      this.props.resetError();
      this.setState({ showErrorModal: true, errorMessage });
    }
  }
  componentWillUnmount() {
    this.props.setLoadedData(false);

    socket.off("ActionRunResult", this.actionRunResultHandler);
    socket.off("ImportStatus", this.importStatusHandler);
  }
  async importStatusHandler(data, importObj) {
    console.log("STATUS DATA");
    console.log(data);
    console.log(importObj);
    const { mdmConfig } = this.props;
    const { cancelAppendToken, cancelDataToken, activeProject, activeIdx, runUpdate } = this.state;
    if (data === "complete" && runUpdate) {
      const CancelDataToken = axios.CancelToken;
      const newCancelDataToken = CancelDataToken.source();
      cancelAppendToken.cancel("Cancelled By Nav Click");
      cancelDataToken.cancel("Cancelled Data by Nav Click");

      this.props.getMdmData(activeProject.id, mdmConfig[activeIdx].id, newCancelDataToken);
      this.props.setFullLoad(false);
      this.setState({ runUpdate: false });
    }

    this.setState({
      importStatus: data,
      importErrors: importObj.error,
      importId: importObj.importId
    });
  }
  actionRunResultHandler(data) {
    const { mdmConfig } = this.props;
    let message = this.renderInfoMessage(data);

    if (typeof message === "object") {
      message = JSON.stringify(message);
    }

    if (message.includes("ERROR") || message.includes("Error")) {
      toast.error(message);
    } else {
      toast.success(message);
    }
    const { cancelAppendToken, cancelDataToken, activeProject, activeIdx } = this.state;
    const CancelDataToken = axios.CancelToken;
    const newCancelDataToken = CancelDataToken.source();
    cancelAppendToken.cancel("Cancelled By Nav Click");
    cancelDataToken.cancel("Cancelled Data by Nav Click");

    this.props.getMdmData(activeProject.id, mdmConfig[activeIdx].id, newCancelDataToken);
    this.props.setFullLoad(false);
    this.setState({
      showInfoModal: false,
      info: data,
      runningAction: false,
      primaryTable: mdmConfig[activeIdx].id,
      cancelDataToken,
      cancelAppendToken
    });
  }
  saveHandler() {
    const { activeIdx, deletedRows, cancelDataToken, activeProject } = this.state;
    const { data, mdmConfig } = this.props;
    this.setState({ saving: true });

    let savedRows = data.filter(row => {
      if (row.crudType === undefined) {
        return false;
      } else if (row.crudType === "empty") {
        return false;
      } else {
        return true;
      }
    });
    let continueWithSave = true;
    savedRows = savedRows.map(row => {
      let rowData = [];

      if (row.crudType === "delete") {
        rowData = row.rowData;
        //     ID = row.idx;
      }
      let saveColumns = mdmConfig[activeIdx].data.filter(conf => {
        return conf.editable === true;
      });
      saveColumns = saveColumns.map(col => {
        return col.field;
      });
      rowData = Object.entries(row).filter(item => {
        if (item[0] === "ID") {
          return true;
        } else if (saveColumns.includes(item[0])) {
          return true;
        }
        return false;
      });

      let updateRD = row.updated.reduce((obj, key) => {
        obj[key.name] = key.newValue;
        return obj;
      }, {});

      rowData = rowData.map(item => {
        if (item[0] !== "ID" && updateRD[item[0]] !== undefined) {
          console.log(updateRD);
          item[1] = updateRD[item[0]];

          if (updateRD[item[0]].status !== undefined) {
            if (updateRD[item[0]].status === "invalid") {
              continueWithSave = false;
            }
          }
          //item[1] === row.
        }
        if (item[0].includes("_FK")) {
          if (item[1] !== null && item[1] !== undefined) {
            if (item[1].id === undefined) {
              let fkColumn = this.props.mdmConfig[this.state.activeIdx].data.filter(fkItem => {
                return fkItem.field === item[0];
              });

              let fkFillIn = fkColumn[0].cellEditorParams.values.filter(value => {
                return value.name === item[1].name;
              });
              if (fkFillIn.length > 0) {
                item[1] = fkFillIn[0].id;
              } else {
                item[1] = null;
              }
            } else {
              item[1] = item[1].id;
            }
          }
        }
        if (typeof item[1] === "string") {
          item[1] = item[1].replace(/\\/g, "\\\\\\\\");
        }

        return item;
      }, this);
      rowData = rowData.reduce((obj, key) => {
        obj[key[0]] = key[1];
        return obj;
      }, {});

      let type = row.crudType;
      delete rowData.crudType;
      delete rowData.updated;
      return {
        id: row.ID,
        type,
        rowData
        // data: filteredNew,
        // changedColumns: row.changedColumns,
        // oldData: filteredOld
      };
    }, this);
    deletedRows.forEach(row => {
      savedRows.push({
        id: row.rowData.ID,
        type: "delete",
        rowData: { ID: row.rowData.ID }
      });
    });

    if (continueWithSave) {
      axios
        .post(
          `${process.env.REACT_APP_BIGHORN_SERVER}/api/mdm/saveMdmData`,
          { table: mdmConfig[activeIdx].saveTable, saveObj: savedRows },
          {
            headers: {
              ClientToken: localStorage.getItem("clientToken")
            }
          }
        )
        .then(response => {
          this.setState({
            modifiedRows: [],
            oldData: [],
            dirty: false,
            saving: false
          });

          if (response.data.success === false) {
            this.setState({
              showErrorModal: true,
              saving: false,
              errorMessage: response.data.message
            });
          } else if (response.data.update) {
            this.props.getMdmData(activeProject.id, mdmConfig[activeIdx].id, cancelDataToken);
            this.setState({
              saveObj: [],
              primaryTable: mdmConfig[activeIdx].id
            });
          } else {
            this.setState({ saveoObj: [] });
          }
        })
        .catch(error => {
          console.log(error);
          if (error.response !== undefined) {
            this.setState({
              showErrorModal: true,
              saving: false,
              errorMessage: error.response.data.error
            });
          } else {
            this.setState({
              saving: false
            });
          }
        });
    } else {
      this.setState({ saving: false, showInvalidSave: true });
    }
  }

  secondaryNavClickHandler(item, idx, overrideDirty = false) {
    const { mdmConfig } = this.props;
    const { dirty, cancelAppendToken, cancelDataToken, activeProject } = this.state;
    if (!dirty || overrideDirty) {
      const CancelDataToken = axios.CancelToken;
      const newCancelDataToken = CancelDataToken.source();
      cancelAppendToken.cancel("Cancelled By Nav Click");
      cancelDataToken.cancel("Cancelled Data by Nav Click");
      this.props.getActions(mdmConfig[idx].id, activeProject.id);
      this.props.getMdmData(activeProject.id, mdmConfig[idx].id, newCancelDataToken);
      this.props.setFullLoad(false);

      this.setState({
        currentTable: mdmConfig[idx].data,
        activeIdx: idx,
        desc: mdmConfig[idx].desc,
        cancelDataToken: newCancelDataToken,
        runningAction: false,
        selectedAction: null,
        primaryTable: mdmConfig[idx].id,
        selectedSecondaryTable: null,
        saving: false
      });
    } else {
      this.setState({ showUnsavedChangesModal: true, navClickedIdx: idx });
    }
  }

  cancelHandler(runSecondaryNavClick = false) {
    const { rollBackData } = this.props;
    let idx = this.state.navClickedIdx;
    this.props.setMdmData(rollBackData);
    this.props.setRefresh(true);
    this.setState({ dirty: false });

    if (runSecondaryNavClick) {
      this.secondaryNavClickHandler(null, idx, true);
      this.setState({ showUnsavedChangesModal: false, navClickedIdx: null });
    }
  }
  handleChange = selectedOption => {
    this.setState({
      selectedAction: selectedOption
    });
  };

  handleProjectChange(selectedOption) {
    const { activeIdx, currentAppIdx } = this.state;

    this.props.getMdmConfig(this.state.currentAppIdx, selectedOption.id);
    this.props.history.push(`/mdm/dataManagement/${currentAppIdx}+${selectedOption.id}+${activeIdx}`);

    this.setState({
      activeProject: selectedOption,
      activeProjectIdx: selectedOption.id
    });
  }

  handleSecondaryChange = selectedTable => {
    const { dirty, currentAppIdx, cancelAppendToken, cancelDataToken, activeProject } = this.state;
    const { mdmConfig } = this.props;
    if (!dirty) {
      const CancelDataToken = axios.CancelToken;
      const newCancelDataToken = CancelDataToken.source();
      this.props.history.push(`/mdm/dataManagement/${currentAppIdx}+${activeProject.id}+${selectedTable.idx}`);
      cancelAppendToken.cancel("Cancelled By Next Click");
      cancelDataToken.cancel("Cancelled Data by Nav Click");
      this.props.getMdmData(activeProject.id, selectedTable.id, newCancelDataToken);
      this.setState({
        currentTable: mdmConfig[selectedTable.idx].data,
        activeIdx: selectedTable.idx,
        cancelDataToken: newCancelDataToken,
        selectedSecondaryTable: selectedTable,
        primaryTable: selectedTable.id,
        runningAction: false,
        saving: false
      });

      //this.setState({ selectedSecondaryTable: selectedTable });
    } else {
      this.props.history.push(`/mdm/dataManagement/${currentAppIdx}+${activeProject.id}+${selectedTable.idx}`);
      this.setState({
        showUnsavedChangesModal: true,
        navClickedIdx: selectedTable.idx
      });
    }
  };
  runAction() {
    const { selectedAction, activeIdx, activeProjectIdx, activeProject } = this.state;

    this.setState({ runningAction: true });
    console.log("ACTIVE PROJECT");
    console.log(activeProject);
    console.log("active idx");
    console.log(activeIdx);
    console.log("active Project Index");
    console.log(activeProjectIdx);
    socket.emit("runAction", {
      token: localStorage.getItem("clientToken"),
      action: selectedAction.id,
      table: this.props.mdmConfig[activeIdx].name,
      project: activeProject.id
    });
  }
  quickFilterClick(item) {
    const { externalFilter } = this.props;
    let newExternalFilter = [];
    if (item.length === 0) {
    } else {
      newExternalFilter = cloneDeep(externalFilter);
      if (newExternalFilter.length === 0) {
        newExternalFilter = item;
      } else {
        newExternalFilter = newExternalFilter.filter(filter => {
          return filter.field !== item.field;
        });
      }
    }
    this.props.setExternalFilter(newExternalFilter);

    this.setState({ selectedQuickFilters: item });
    this.props.setRefresh(true);
  }
  setQuickFilterClass(item) {
    const { externalFilter } = this.props;

    let filterExists = externalFilter.filter(filter => {
      return filter.field === item.field;
    });
    if (filterExists.length === 0) {
      return true;
    } else {
      return false;
    }
  }
  renderProgress() {
    if (!this.props.fullLoad && this.props.nextRowToLoad !== null) {
      if (this.props.nextRowToLoad < this.props.rowCount) {
        return (
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              marginLeft: "auto",
              marginRight: "30px"
            }}>
            <DotLoader sizeUnit={"px"} size={15} color={"rgba(0, 126, 167, 1)"} style={{ top: "4px" }} loading={true} />
            <div
              style={{
                color: "rgba(0, 126, 167, 1)",
                fontStyle: "italic",
                marginLeft: "5px"
              }}>{`Loading ${this.props.nextRowToLoad}/${this.props.rowCount} Rows`}</div>
          </div>
        );
      }
    } else if (this.props.fullLoad) {
      return <div style={{ marginRight: "30px" }}>{`Completed ${this.props.rowCount} Rows`}</div>;
    }
  }
  handleselectedFile = event => {
    event.preventDefault();

    //var data = new FormData();

    this.setState({ uploadFile: event.target.files[0] });

    // axios
    //   .post(
    //     `${process.env.REACT_APP_BIGHORN_SERVER}/api/files/uploadFile`,
    //     data,
    //     {
    //       headers: {
    //         ClientToken: localStorage.getItem("clientToken")
    //       }
    //     }
    //   )
    //   .then(res => {
    //     console.log(res.statusText);
    //   });
  };
  uploadFile() {
    const { uploadFile, activeIdx, activeProject } = this.state;
    const { mdmConfig } = this.props;
    if (uploadFile !== null) {
      var data = new FormData();
      data.append("file", uploadFile);
      data.append("appId", this.state.currentAppIdx);
      data.append("activeIdx", this.state.activeIdx);
      data.append("primaryTable", this.state.primaryTable);
      data.append("saveTable", mdmConfig[activeIdx].saveTable);
      data.append("project", activeProject.id);
      axios
        .post(`${process.env.REACT_APP_BIGHORN_SERVER}/api/mdm/importData`, data, {
          headers: {
            ClientToken: localStorage.getItem("clientToken")
          }
        })
        .then(res => {
          console.log(res.statusText);
          socket.emit("getImportStatus", {
            token: localStorage.getItem("clientToken"),
            importId: res.data.runId
          });
          this.handleClose();
          this.setState({
            runUpdate: true,
            showProgressModal: true,
            importId: res.data.runId
          });
        });
    }
  }
  setShowUploadModal(state) {
    this.setState({ showUploadModal: state });
  }
  renderSecondaryNav() {
    const { mdmConfig, actions, mdmApps } = this.props;
    const { activeIdx, currentAppIdx, selectedAction, selectedSecondaryTable, dirty, activeProject } = this.state;

    let primaryTables = mdmConfig.filter(item => {
      return item.primaryTable;
    });
    let secondaryTables = mdmConfig.filter(item => {
      return !item.primaryTable;
    });
    var currentApp = mdmApps.filter(item => {
      return item.ID === parseInt(currentAppIdx, 10);
    });
    currentApp = currentApp[0];
    if (currentApp !== undefined) {
      let projects = currentApp.PROJECTS.map(item => {
        return {
          id: item.PROJECT_ID,
          label: item.PROJECT_NAME,
          value: item.PROJECT_NAME,
          batchId: item.AB_BATCH_ID
        };
      });
      secondaryTables = secondaryTables.map(item => {
        return {
          label: item.name,
          value: item.name,
          idx: item.idx,
          id: item.id
        };
      });
      let currentProject = activeProject;
      if (currentProject === null) {
        currentProject = projects[0];
      }

      let names = primaryTables.map((conf, idx) => {
        return (
          <div
            style={{ display: "flex", border: "none" }}
            key={conf.idx}
            className={parseInt(activeIdx, 10) === conf.idx ? "mdm-active" : ""}>
            <div className={parseInt(activeIdx, 10) === conf.idx ? "mdm-circle-selected" : "mdm-circle"}>{idx + 1}</div>
            <Link
              onClick={item => this.secondaryNavClickHandler(item, conf.idx)}
              className={"mdm-nav-link " + (parseInt(activeIdx, 10) === conf.idx ? "mdm-active" : "")}
              to={`/mdm/dataManagement/${currentAppIdx}+${currentProject.id}+${conf.idx}`}>
              {conf.name}
            </Link>
          </div>
        );
      });

      let quickFilters = this.props.mdmConfig[this.state.activeIdx].data.filter(item => {
        if (item.cellRenderer !== null && item.cellRenderer !== undefined) {
          if (item.cellRenderer === "checkboxCellRenderer") {
            return true;
          }
        }
        return false;
      });
      quickFilters = quickFilters.map((filter, idx) => {
        filter.value = filter.headerName;
        filter.label = filter.headerName;
        return filter;
      });

      return (
        <div>
          <div style={{ display: "flex", flexDirection: "column" }} className="mdm-nav-buttons">
            {projects.length > 1 && (
              <Select
                className="project-dropdown"
                classNamePrefix="react-select"
                placeholder={"Select Project"}
                value={
                  activeProject //isClearable
                }
                onChange={this.handleProjectChange}
                // onInputChange={this.onInputChange.bind(this)}
                options={projects}
                // onCreateOption={
                //   this.handleCreate //autoLoad={true}
                // }
              />
            )}
            <div style={{ display: "flex", marginLeft: "auto" }}>
              <div
                className="refresh-icon"
                style={{ marginRight: "10px", marginTop: "12px" }}
                onClick={() => {
                  const { cancelAppendToken, cancelDataToken, activeProject, activeIdx } = this.state;
                  const CancelDataToken = axios.CancelToken;
                  const newCancelDataToken = CancelDataToken.source();
                  cancelAppendToken.cancel("Cancelled By Nav Click");
                  cancelDataToken.cancel("Cancelled Data by Nav Click");

                  this.props.getMdmData(activeProject.id, mdmConfig[activeIdx].id, newCancelDataToken);
                  this.props.setFullLoad(false);
                }}>
                <RefreshIcon color={"rgba(88, 89, 91, 1)"} height={"20"} className="" />
              </div>
              {!this.state.runningAction ? (
                <div style={{ display: "flex", flexDirection: "row" }}>
                  <div className="run-action">Run Action:</div>
                  <Select
                    className="action-dropdown"
                    classNamePrefix="react-select"
                    placeholder={"Actions"}
                    value={
                      selectedAction //isClearable
                    }
                    onChange={this.handleChange}
                    // onInputChange={this.onInputChange.bind(this)}
                    options={actions}
                    // onCreateOption={
                    //   this.handleCreate //autoLoad={true}
                    // }
                  />
                  {!this.state.saving ? (
                    <button
                      className={"run-button " + (!dirty ? "" : "disabled")}
                      onClick={() => {
                        this.runAction();
                      }}>
                      Run
                    </button>
                  ) : (
                    <button className={"run-button disabled"}>Run</button>
                  )}
                </div>
              ) : (
                <div className="running-action" style={{ display: "flex", flexDirection: "row" }}>
                  <DotLoader sizeUnit={"px"} size={15} color={"black"} loading={true} />
                  <div style={{ marginLeft: "5px" }}>Running</div>
                </div>
              )}
              <div
                onClick={() => {
                  this.setShowUploadModal(true);
                }}
                style={{
                  paddingLeft: "15px",
                  paddingRight: "15px",
                  marginTop: "10px",
                  marginRight: "15px",
                  cursor: "pointer"
                }}
                className="ta-button ta-button-primary">
                Import Data
              </div>
              {/* <input
                hidden={true}
                id="files"
                ref
                type="file"
                name="Upload"
                onChange={this.handleselectedFile}
                onClick={event => {
                  event.target.value = null;
                }}
              /> */}

              {!this.state.saving ? (
                <div>
                  {!this.state.runningAction ? (
                    <button
                      className="mdm-button"
                      onClick={() => this.saveHandler()}
                      style={{
                        display: "flex",
                        marginLeft: "auto",
                        marginRight: "15px"
                      }}>
                      Save
                    </button>
                  ) : (
                    <button
                      className="mdm-button disabled"
                      //onClick={() => this.saveHandler()}
                      style={{
                        display: "flex",

                        marginRight: "15px"
                      }}>
                      Save
                    </button>
                  )}
                </div>
              ) : (
                <div
                  className="mdm-save"
                  style={{
                    marginLeft: "auto",

                    display: "flex",
                    flexDirection: "row"
                  }}>
                  <DotLoader sizeUnit={"px"} size={15} color={"black"} loading={true} />
                  <div style={{ marginLeft: "5px" }}>Saving</div>
                </div>
              )}
              <button
                className="mdm-button"
                onClick={() => {
                  this.cancelHandler();
                }}>
                Cancel
              </button>
            </div>
            <div style={{ display: "flex" }}>
              <div className="dm-secondary-nav-cont">{names}</div>
              <div style={{ marginLeft: "auto" }}>
                <Select
                  className="reference-tables"
                  classNamePrefix="react-select"
                  placeholder={"Reference Tables"}
                  value={selectedSecondaryTable}
                  onChange={this.handleSecondaryChange}
                  // onInputChange={this.onInputChange.bind(this)}
                  options={secondaryTables}

                  // onCreateOption={
                  //   this.handleCreate //autoLoad={true}
                  // }
                />
              </div>
            </div>
          </div>
          <div>{this.state.desc}</div>
          <div style={{ display: "flex", flexDirection: "row" }}>
            <div>
              {quickFilters.length > 0 && (
                <div
                  style={{
                    marginTop: "10px",
                    display: "flex",
                    flexDirection: "row"
                  }}>
                  <div>Filter:</div>
                  <div>
                    <Select
                      className="quick-filter-dropdown"
                      classNamePrefix="react-select-qf"
                      placeholder={"none"}
                      value={
                        this.state.selectedQuickFilters //isClearable
                      }
                      onChange={event => {
                        this.quickFilterClick(event);
                      }}
                      // onInputChange={this.onInputChange.bind(this)}
                      options={quickFilters}
                      closeMenuOnSelect={false}
                      isMulti={true}
                      components={{ Option, MultiValue, ValueContainer }}
                      hideSelectedOptions={false}
                      backspaceRemovesValue={false}
                      isClearable={false}
                      // onCreateOption={
                      //   this.handleCreate //autoLoad={true}
                      // }
                    />
                    {/* {quickFilters.map((item, index) => {
                    return (
                      <div
                        key={index}
                        className={
                          this.setQuickFilterClass(item)
                            ? "mdm-filter-button"
                            : "mdm-action-button"
                        }
                        onClick={event => {
                          this.quickFilterClick(event, item);
                        }}
                      >
                        {item.headerName}
                      </div>
                    );
                  })} */}
                  </div>
                </div>
              )}
            </div>
            <div
              className="pull-right action-buttons"
              style={{
                display: "inline-flex",
                marginLeft: "auto",
                marginBottom: "10px",
                marginTop: "10px"
              }}>
              {this.renderProgress()}

              <div
                onClick={() => {
                  if (mdmConfig[activeIdx].allowNewRows) this.addRow();
                }}
                className={"mdm-action-button " + (mdmConfig[activeIdx].allowNewRows ? "" : "disabled")}
                style={{
                  marginLeft: "auto",
                  marginRight: "20px",
                  width: "92px"
                }}>
                +Add Row
              </div>
              <div
                style={{ width: "100px" }}
                onClick={() => {
                  if (mdmConfig[activeIdx].allowNewRows && this.agGrid.current.checkRemoveStatus()) {
                    this.deleteModal();
                  }
                }}
                className={"mdm-action-button " + (mdmConfig[activeIdx].allowNewRows ? "" : "disabled")}>
                Delete Row
              </div>
            </div>
          </div>
        </div>
      );
    } else {
      return <div />;
    }
  }
  removeRows(rows) {
    const { deletedRows } = this.state;

    let dRows = cloneDeep(deletedRows);

    rows.forEach(row => {
      dRows.push({
        type: "delete",
        //Index is data.length because the new record has not yet been added to data
        idx: row.rowId,
        changedColumns: Object.entries(row.data).map(obj => {
          return obj[0];
        }),
        oldData: row.data,
        rowData: row.data
      });
    });
    rows.forEach(row => {
      if (row.rowId < this.props.data.length - 1) {
        dRows = dRows.map(mRow => {
          if (mRow.idx >= row.rowId && dRows.type !== "delete") {
            mRow.idx = mRow.idx - 1;
          }
          return mRow;
        });
      }
    });

    this.props.removeRows(rows);
    this.props.setRefresh(true);
    this.setState({ deletedRows: dRows, dirty: true });
    this.handleClose();
  }
  addRow() {
    const { data, lastId } = this.props;

    let newData = null;
    if (data.length > 0) {
      newData = cloneDeep(data[0]);

      newData = Object.entries(newData);
      newData = newData.reduce((obj, key) => {
        if (key[0] === "ID") {
          obj[key[0]] = lastId + 1;
        } else {
          obj[key[0]] = null;
        }
        return obj;
      }, {});
    } else {
      newData = this.state.currentTable.reduce((obj, item) => {
        if (item.headerName === "ID") {
          obj[item.headerName] = 1;
        } else {
          obj[item.headerName] = null;
        }
        return obj;
      }, {});

      // newData = newData.reduce((obj, item) => {
      //   obj[item.id] = item;
      //   return obj;
      // }, {});
    }

    newData.crudType = "insert";

    this.props.addNewRow(newData);
    this.setState({ dirty: true });
  }

  handleClose = () => {
    const { activeIdx, currentAppIdx, activeProject } = this.state;
    let idx = parseInt(activeIdx, 10);
    this.props.history.push(`/mdm/dataManagement/${currentAppIdx}+${activeProject.id}+${idx}`);
    this.setState({
      showDeleteConfirmModal: false,
      showUnsavedChangesModal: false,
      showInvalidSave: false,
      showInfoModal: false,
      showErrorModal: false,
      showUploadModal: false,
      errorMessage: "",
      showProgressModal: false,
      importErrors: []
    });
  };
  deleteModal = e => {
    this.setState({ showDeleteConfirmModal: true });
  };

  setDirty(state) {
    this.setState({ dirty: state });
  }
  setCancelToken(token) {
    this.setState({ cancelAppendToken: token });
  }
  gridReady(agGrid) {
    this.agGrid = agGrid;
  }
  renderInfoMessage(data) {
    if (data !== undefined && data !== null && data.length > 0) {
      if (typeof data === "string") {
        //return <div>{this.state.info}</div>;
        return data;
      } else {
        return data.map((item, index) => {
          if (typeof item === "object") {
            // return (
            //   <div key={index} style={{ marginTop: "20px" }}>
            //     {JSON.stringify(item)}
            //   </div>
            // );
            return JSON.stringify(item);
          } else {
            // return (
            //   <div key={index} style={{ marginTop: "20px" }}>
            //     {item}
            //   </div>
            // );
            return item;
          }
        });
      }
    }
  }
  getErrorCSV() {
    const { importId } = this.state;
    axios
      .post(
        `${process.env.REACT_APP_BIGHORN_SERVER}/api/mdm/getImportErrors`,
        { importId },
        {
          headers: {
            ClientToken: localStorage.getItem("clientToken")
          }
        }
      )
      .then(async response => {
        console.log(response);

        const blob = new Blob([response.data.csv], {
          type: "text/csv;charset=utf-8"
        });
        saveAs(blob, "errors.csv");
      });
  }
  renderImportDataStatus() {
    if (this.state.importErrors.length === 0)
      if (this.state.importStatus === "complete") {
        return (
          <ModalBody>
            <div>Status: {this.state.importStatus}</div>
          </ModalBody>
        );
      } else {
        return (
          <ModalBody>
            <div>Status: {this.state.importStatus}</div>
            <DotLoader sizeUnit={"px"} size={15} color={"black"} loading={true} />
          </ModalBody>
        );
      }
    else {
      return (
        <ModalBody>
          <div>There were errors importing your data:</div>
          {this.state.importErrors.length === 1 ? (
            <div>{this.state.importErrors.length} Row had errors</div>
          ) : (
            <div>{this.state.importErrors.length} Rows had errors</div>
          )}
          <div
            onClick={() => {
              this.getErrorCSV();
            }}
            style={{
              color: " rgba(0, 126, 167, 1)",
              textDecoration: "underline",
              cursor: "pointer"
            }}>
            Download Error Report
          </div>
        </ModalBody>
      );
    }
  }
  renderOkButton() {
    if (this.state.importStatus === "complete" || this.state.importStatus === "Failed") {
      return (
        <Button
          color="primary"
          onClick={() => {
            if (this.state.importStatus === "complete" || this.state.importStatus === "Failed") {
              this.handleClose();
            }
          }}>
          OK
        </Button>
      );
    } else {
      return <Button style={{ cursor: "not-allowed" }}>OK</Button>;
    }
  }
  render() {
    const {
      currentTable,
      showDeleteConfirmModal,
      showUnsavedChangesModal,
      showInfoModal,
      showErrorModal,
      errorMessage,
      activeProjectIdx,
      showInvalidSave,
      showUploadModal,
      showProgressModal
    } = this.state;
    const { loadingConfig, loadedConfig, loadingData, loadedData, error } = this.props;

    if (loadingConfig || (!loadedConfig && error !== null)) {
      return <LoadingIndicator paddingTop={"227px"} />;
    } else if (error !== null) {
      return <div>{error}</div>;
    } else if (!loadedData || loadingData) {
      return (
        <div>
          {this.renderSecondaryNav()}
          <LoadingIndicator />
        </div>
      );
    }

    return (
      <div style={{ height: "100%" }}>
        <Modal isOpen={showProgressModal}>
          <ModalHeader>Importing Data</ModalHeader>
          {this.renderImportDataStatus()}

          <ModalFooter>{this.renderOkButton()}</ModalFooter>
        </Modal>

        <Modal isOpen={showUploadModal}>
          <ModalHeader>Upload File</ModalHeader>

          <ModalBody>
            <div style={{ display: "flex", flexDirection: "column" }}>
              <div>
                Import files must use an export from the current table. Column headers and row IDs must be present in the
                uploaded XLSX file.
              </div>
              <div style={{ marginTop: "20px" }}>Select a XLSX import file</div>
              <div>
                {/* <label htmlFor="files" className="ta-button ta-button-primary">
                  Upload
                </label> */}
                <input
                  //hidden={true}
                  id="files"
                  ref
                  type="file"
                  name="Upload"
                  onChange={this.handleselectedFile}
                  onClick={event => {
                    event.target.value = null;
                  }}
                />
              </div>
            </div>
          </ModalBody>

          <ModalFooter>
            <Button color="primary" onClick={this.uploadFile}>
              Import Data
            </Button>
            <Button onClick={this.handleClose}>Cancel</Button>
          </ModalFooter>
        </Modal>

        <Modal isOpen={showInvalidSave}>
          <ModalHeader>Save Error</ModalHeader>
          <ModalBody>
            <div>There are some invalid entries (in red) and this table cannot be saved. Fix these issues and resave.</div>
          </ModalBody>

          <ModalFooter>
            <Button onClick={this.handleClose}>Close</Button>
          </ModalFooter>
        </Modal>

        <Modal isOpen={showDeleteConfirmModal}>
          <ModalHeader>Delete Row</ModalHeader>
          <ModalBody>
            <div>Are you sure your want to delete the selected row? This cannot be undone.</div>
          </ModalBody>

          <ModalFooter>
            <Button
              bsstyle="primary"
              onClick={() => {
                this.agGrid.current.checkRemove();
              }}>
              Delete
            </Button>
            <Button onClick={this.handleClose}>Cancel</Button>
          </ModalFooter>
        </Modal>
        <Modal isOpen={showInfoModal}>
          <ModalHeader>Info</ModalHeader>
          <ModalBody>{this.renderInfoMessage()}</ModalBody>

          <ModalFooter>
            <Button onClick={this.handleClose}>Ok</Button>
          </ModalFooter>
        </Modal>
        <Modal isOpen={showUnsavedChangesModal}>
          <ModalHeader>Unsaved Changes</ModalHeader>
          <ModalBody>
            <div>
              You have unsaved changes. These changes will be lost if you navigate away from this table. Do you want to
              continue?
            </div>
          </ModalBody>

          <ModalFooter>
            <Button
              onClick={() => {
                this.cancelHandler(true);
              }}>
              Yes
            </Button>
            <Button onClick={this.handleClose}>No</Button>
          </ModalFooter>
        </Modal>
        <Modal style={{ maxWidth: "80%", maxHeight: "50%" }} isOpen={showErrorModal}>
          <ModalHeader>Error</ModalHeader>
          <ModalBody>
            <div>{errorMessage}</div>
          </ModalBody>

          <ModalFooter>
            <Button onClick={this.handleClose}>OK</Button>
          </ModalFooter>
        </Modal>

        {this.renderSecondaryNav()}

        <DMGrid
          setDirty={this.setDirty}
          removeRows={this.removeRows}
          currentTable={currentTable}
          setCancelToken={this.setCancelToken}
          activeIdx={this.state.activeIdx}
          gridReady={this.gridReady}
          currentProject={activeProjectIdx}
          externalFilter={this.state.externalFilter}
        />
      </div>
    );
  }
}

const mapStateToProps = state => ({
  mdmConfig: state.mdm.mdmConfig,
  mdmApps: state.mdm.mdmApps,
  loadingConfig: state.mdm.loadingConfig,
  loadedConfig: state.mdm.loadedConfig,
  loadedData: state.mdm.loadedData,
  loadingData: state.mdm.loadingData,
  actions: state.mdm.actions,
  loadingAppendData: state.mdm.loadingAppendData,
  nextRowToLoad: state.mdm.nextRowToLoad,
  rowCount: state.mdm.rowCount,
  lastId: state.mdm.lastId,
  error: state.mdm.error,
  data: state.mdm.data,
  info: state.mdm.info,
  externalFilter: state.mdm.externalFilter,
  errorMessage: state.mdm.errorMessage,
  rollBackData: state.mdm.rollBackData,
  fullLoad: state.mdm.fullLoad,
  tabs: state.topLevelNav.tabs,
  apps: state.app.apps
});
const mapDispatchToProps = dispatch => ({
  addTabToNav(tab) {
    dispatch(addTabToNav(tab));
  },
  getMdmConfig(appId, projectId) {
    dispatch(getMdmConfig(appId, projectId));
  },
  getMdmData(project, table, source) {
    dispatch(getMdmData(project, table, source));
  },
  addNewRow(row) {
    dispatch(addNewRow(row));
  },
  removeRows(rows) {
    dispatch(removeRows(rows));
  },
  setMdmData(data) {
    dispatch(setMdmData(data));
  },
  getActions(table, project) {
    dispatch(getActions(table, project));
  },
  setFullLoad(status) {
    dispatch(setFullLoad(status));
  },
  setRefresh(status) {
    dispatch(setRefresh(status));
  },
  resetInfo() {
    dispatch(resetInfo());
  },
  resetError() {
    dispatch(resetError());
  },
  setExternalFilter(filter) {
    dispatch(setExternalFilter(filter));
  },
  setLoadedData(status) {
    dispatch(setLoadedData(status));
  },
  doSetErrorDialog(message) {
    dispatch(doSetErrorDialog(message));
  },
  doSetInfoDialog(message) {
    dispatch(doSetInfoDialog(message));
  }
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(DataManagement));
