import React, { Component } from "react";
import LiftCard from "./liftCard";
import { connect } from "react-redux";
import { Grid, Row } from "react-flexbox-grid";
import moment from "moment";
import { createLift } from "components/acculift/reducers/acculiftReducer";
import { selectBaseline } from "components/accubase/reducers/accubaseReducer";

class LiftDetailCellRenderer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      lifts: props.data.lifts,
      showNewLiftDialog: false,
      selectedRadioOption: 1,
      liftName: "",
      errorMessage: ""
    };
    this.LiftName = React.createRef();
    this.handleInputChange = this.handleInputChange.bind(this);
    this.createLift = this.createLift.bind(this);
  }
  componentDidMount() {
    if (this.props.selectedBaseline.id === undefined) {
      this.props.selectBaseline(this.props.data, 1);
    }
  }
  componentDidUpdate() {
    //console.log(this.props.lifts.length);
  }

  handleInputChange() {
    this.setState({ liftName: this.LiftName.current.value });
  }
  createLift() {
    if (this.LiftName.current.value !== "") {
      let dupe = this.state.lifts.filter(item => {
        return item.name.toLowerCase() === this.LiftName.current.value.toLowerCase();
      });
      if (dupe.length > 0) {
        this.setState({
          errorMessage: "There already is a lift with this name"
        });
      } else {
        this.props.data.lifts.push({
          dateCreated: moment().format("MM/DD/YY"),
          lastModified: moment().format("MM/DD/YY"),
          name: this.LiftName.current.value,
          status: "running",
          id: -1
        });
        this.props.data.liftCount = this.props.data.liftCount + 1;
        this.props.createLift(this.props.data.id, this.LiftName.current.value);
        this.setState({ showNewLiftDialog: false });
      }
    }
  }
  render() {
    const { lifts } = this.state;
    return (
      <div
        style={{
          display: "flex",
          height: "100%",
          flexDirection: "column",
          padding: "20px",
          background: "rgb(247,248,250)"
        }}>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            fontWeight: "bold",
            fontSize: "14px"
          }}>
          <div>Select an available lift model to review</div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              width: "100%"
            }}>
            <div
              onClick={() => {
                if (this.props.data.status === "complete") {
                  this.setState({
                    showNewLiftDialog: !this.state.showNewLiftDialog
                  });
                }
              }}
              className={this.props.data.status === "complete" ? "btn btn-high-viz" : "btn disableLift"}
              style={{
                marginLeft: "auto",
                cursor: "pointer",
                // fontWeight: "bold",
                // color: "#0080AB",
                fontSize: "14px"
              }}>
              + Add New Lift Model
            </div>
            {this.state.showNewLiftDialog && (
              <div style={{}}>
                <div className="add-new-lift-container">
                  {this.state.errorMessage !== "" && (
                    <div style={{ display: "flex", flexDirection: "row" }}>
                      <div style={{ fontSize: "11px" }}>{this.state.errorMessage}</div>
                      <div
                        style={{ display: "pointer", marginLeft: "10px" }}
                        onClick={() => {
                          this.setState({ errorMessage: "" });
                        }}>
                        X
                      </div>
                    </div>
                  )}
                  <div>Lift Model Name</div>
                  <input ref={this.LiftName} onChange={this.handleInputChange} style={{ width: "100%", marginBottom: "5px" }} />

                  {/* <div>Lift Model Outputs</div>
                  <div style={{ display: "flex", flexDirection: "column" }}>
                    <label style={{ marginBottom: "5px" }} key={"label" + 1}>
                      <input
                        checked={this.state.selectedRadioOption === 1}
                        onChange={this.handleOptionChange}
                        type="radio"
                        style={{ marginRight: "5px" }}
                        value={1}
                      />
                      Histogram (5-point buckets)
                    </label>
                    <label key={"label" + 2}>
                      <input
                        checked={this.state.selectedRadioOption === 2}
                        onChange={this.handleOptionChange}
                        type="radio"
                        style={{ marginRight: "5px" }}
                        value={2}
                      />
                      Histogram (10-point buckets)
                    </label>
                  </div> */}
                  <hr />
                  <div
                    style={{
                      justifyContent: "center",
                      paddingLeft: "15px",
                      display: "flex",
                      flexDirection: "row"
                    }}>
                    <div
                      className="ta-button"
                      style={{
                        height: "25px",
                        width: "60px",
                        textAlign: "center",
                        marginRight: "20px",
                        paddingTop: "4px"
                      }}
                      onClick={() => {
                        this.createLift();
                      }}>
                      Create
                    </div>
                    <div
                      style={{
                        height: "25px",
                        width: "60px",
                        textAlign: "center",
                        marginRight: "20px",
                        paddingTop: "4px"
                      }}
                      className="cancel-button"
                      onClick={() => {
                        this.setState({ showNewLiftDialog: false });
                      }}>
                      Cancel
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
        <hr style={{ width: "100%", height: "5px" }} />
        <div
          className="full-width-panel"
          style={{
            display: "flex",
            flexDirection: "row",
            width: "100%"
          }}>
          <Grid fluid className="report-icon-list">
            <Row center="xl" style={{ width: "100%" }}>
              {lifts.map((item, idx) => {
                return <LiftCard baseline={this.props.data} lift={item} key={idx} />;
              })}
            </Row>
          </Grid>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  lifts: state.acculift.lifts,
  projects: state.acculift.projects,
  searchProjects: state.acculift.searchProjects,
  selectedBaseline: state.accubase.selectedBaseline
});
const mapDispatchToProps = dispatch => ({
  createLift(baselineId, versionName) {
    dispatch(createLift(baselineId, versionName));
  },
  selectBaseline(baseline, viewSelected) {
    dispatch(selectBaseline(baseline, viewSelected));
  }
});

export default connect(mapStateToProps, mapDispatchToProps)(LiftDetailCellRenderer);
