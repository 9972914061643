/* eslint-disable indent */
import { save } from "save-file";
import React, { Component } from "react";
import { DeleteIcon, DownloadIcon } from "lib/icons";
import axios from "axios";
import moment from "moment-timezone";
import { toast } from "react-toastify";

export class NotificationsItem extends Component {
  constructor(props) {
    super(props);
    this.downloadHandler = this.downloadHandler.bind(this);
    this.renderText = this.renderText.bind(this);
    this.removeNotification = this.removeNotification.bind(this);
  }
  downloadHandler() {
    const { item } = this.props;
    let iId = item._id;
    toast.success("Downloading File ...");
    axios
      .post(
        `${process.env.REACT_APP_BIGHORN_SERVER}/api/app/downloadNotifcationFile`,
        {
          fileId: iId
        },
        {
          headers: {
            ClientToken: localStorage.getItem("clientToken")
          }
        }
      )
      .then(response => {
        save(response.data, item.fileName);
      })
      .catch(error => {
        //dispatch(fetchReportListError(error));
      });
  }
  removeNotification() {
    const { item } = this.props;
    let iId = item._id;

    axios
      .post(
        `${process.env.REACT_APP_BIGHORN_SERVER}/api/app/removeNotifcation`,
        {
          fileId: iId
        },
        {
          headers: {
            ClientToken: localStorage.getItem("clientToken")
          }
        }
      )
      .then(response => {})
      .catch(error => {
        //dispatch(fetchReportListError(error));
      });
  }
  renderText() {
    const { item } = this.props;
    let fileType = "Powerpoint";
    if (item.notifyType !== "IMAGE") {
      if (item.notifyType === "PDF") {
        fileType = "PDF";
      } else if (item.notifyType === "PNG") {
        fileType = "PNG Zip";
      }
      if (item.status === "complete") {
        return (
          <div style={{ display: "flex", flexDirection: "row", fontSize: "14px" }}>
            {moment(item.created).format("MM/DD/YY hh:mm A")}{" "}
            <i style={{ marginLeft: "5px", marginRight: "5px" }}>{item.name}</i> {fileType} is ready for download
          </div>
        );
      } else if (item.status === "error") {
        return (
          <div style={{ display: "flex", flexDirection: "row", fontSize: "14px" }}>
            {moment(item.created).format("MM/DD/YY hh:mm A")}{" "}
            <i style={{ marginLeft: "5px", marginRight: "5px" }}>{item.name}</i> <b>failed to export</b>
          </div>
        );
      } else {
        return (
          <div style={{ display: "flex", flexDirection: "row", fontSize: "14px" }}>
            {moment(item.created).format("MM/DD/YY hh:mm A")}{" "}
            <i style={{ marginLeft: "5px", marginRight: "5px" }}>{item.name}</i> is currently processing
          </div>
        );
      }
    } else {
      fileType = "Image";
      if (item.status === "complete") {
        return (
          <div style={{ display: "flex", flexDirection: "row", fontSize: "14px" }}>
            {moment(item.created).format("MM/DD/YY hh:mm A")}{" "}
            <i style={{ marginLeft: "5px", marginRight: "5px" }}>{item.name}</i> {fileType} added to powerpoint builder
          </div>
        );
      } else {
        return (
          <div style={{ display: "flex", flexDirection: "row", fontSize: "14px" }}>
            {moment(item.created).format("MM/DD/YY hh:mm A")}{" "}
            <i style={{ marginLeft: "5px", marginRight: "5px" }}>{item.name}</i> is currently processing
          </div>
        );
      }
    }
  }
  render() {
    const { item } = this.props;
    return (
      <div style={{ width: "98%" }}>
        <div style={{ display: "flex", flexDirection: "row", fontSize: "14px" }}>
          {this.renderText()}
          <div
            style={{
              marginLeft: "auto",
              display: "flex",
              flexDirection: "row"
            }}>
            {item.status === "complete" &&
              (item.noDownload === null || item.noDownload === undefined || item.noDownload === false) && (
                <div
                  style={{ marginLeft: "auto", color: "rgba(0, 126, 167, 1)" }}
                  onClick={() => {
                    this.downloadHandler();
                  }}>
                  <DownloadIcon
                    height={"16px"}
                    width={"16px"}
                    color={"rgba(88, 89, 91, 1)"}
                    style={{
                      cursor: "pointer",
                      marginRight: "10px",
                      marginBottom: "3px"
                    }}
                  />
                </div>
              )}
            <div
              onClick={() => {
                this.removeNotification();
              }}>
              <DeleteIcon style={{ marginTop: "-2px", marginLeft: "auto" }} height={"16"} color={"rgba(88, 89, 91, 1)"} />
            </div>
          </div>
        </div>
        <hr />
      </div>
    );
  }
}
