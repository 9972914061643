import axios from "axios";
import { cloneDeep } from "lodash";
import { removeNewTab } from "components/topLevelNav/reducers/topLevelNavReducer";
import { getReports } from "components/reports/components/reportList/reducers/reportListReducer";

/* -------------------- INITIAL STATE -------------------- */
const INITIAL_STATE = {
  loading: true,
  error: null,
  folders: [],
  searchFolders: [],
  modalFolders: [],
  modalSearchFolders: [],
  sasToken: "",
  selected: [],
  forceLoad: false
};

/* -------------------- ACTION TYPES -------------------- */
const FETCH_FOLDER_LIST_BEGIN = "FETCH_FOLDER_LIST_BEGIN";
const FETCH_FOLDER_LIST_SUCCESS = "FETCH_FOLDER_LIST_SUCCESS";
const FETCH_FOLDER_LIST_FAILURE = "FETCH_FOLDER_LIST_FAILURE";
const SET_SEARCH_FOLDERS = "SET_SEARCH_FOLDERS";
const SET_MODAL_SEARCH_FOLDERS = "SET_MODAL_SEARCH_FOLDERS";
const SET_FORCE_LOAD = "SET_FORCE_LOAD";
const SET_BASE_FOLDERS = "SET_BASE_FOLDERS";
const SET_SELECTED_FOLDERS = "SET_SELECTED_FOLDERS";

/* -------------------- ACTION CREATORS -------------------- */

const fetchFolderListBegin = () => ({
  type: FETCH_FOLDER_LIST_BEGIN
});
const fetchFolderListSuccess = folders => {
  return { type: FETCH_FOLDER_LIST_SUCCESS, payload: folders };
};
const setSearchFolders = folders => {
  return { type: SET_SEARCH_FOLDERS, payload: folders };
};
const setModalSearchFolders = folders => {
  return { type: SET_MODAL_SEARCH_FOLDERS, payload: folders };
};

const fetchFolderListError = error => ({
  type: FETCH_FOLDER_LIST_FAILURE,
  payload: { error }
});

/* -------------------- ASYNC ACTION CREATORS -------------------- */
export const getFolderList = () => {
  return dispatch => {
    dispatch(fetchFolderListBegin());
    axios
      .post(
        `${process.env.REACT_APP_BIGHORN_SERVER}/api/powerBI/getReportFolders`,
        {
          // client
          //idToken: localStorage.getItem("id_token")
        },
        {
          headers: {
            ClientToken: localStorage.getItem("clientToken")
          }
        }
      )
      .then(response => {
        // let reports = response.data.workspaces.map(item => {
        //   return item.reports;
        // });
        let workspaces = response.data.workspaces.filter(item => {
          return !item.hidden;
        });
        dispatch(fetchFolderListSuccess(workspaces));
      })
      .catch(error => {
        dispatch(fetchFolderListError(error));
      });
  };
};
export const getFolderListNoLoading = () => {
  return dispatch => {
    axios
      .post(
        `${process.env.REACT_APP_BIGHORN_SERVER}/api/powerBI/getReportFolders`,
        {
          // client
          //idToken: localStorage.getItem("id_token")
        },
        {
          headers: {
            ClientToken: localStorage.getItem("clientToken")
          }
        }
      )
      .then(response => {
        let workspaces = response.data.workspaces.filter(item => {
          return !item.hidden;
        });
        dispatch(fetchFolderListSuccess(workspaces));
        dispatch(setForceLoad(true));
      })
      .catch(error => {
        dispatch(fetchFolderListError(error));
      });
  };
};
export const createFolder = (folder, ws) => {
  return dispatch => {
    axios
      .post(
        `${process.env.REACT_APP_BIGHORN_SERVER}/api/powerBI/createFolder`,
        {
          folder,
          ws
        },
        {
          headers: {
            ClientToken: localStorage.getItem("clientToken")
          }
        }
      )
      .then(response => {
        dispatch(getFolderListNoLoading());
      });
  };
};
export const deleteFolder = path => {
  return dispatch => {
    axios
      .post(
        `${process.env.REACT_APP_BIGHORN_SERVER}/api/powerBI/deleteFolder`,
        {
          path
        },
        {
          headers: {
            ClientToken: localStorage.getItem("clientToken")
          }
        }
      )
      .then(response => {
        dispatch(getFolderListNoLoading());
      });
  };
};
export const setForceLoad = status => {
  return dispatch => {
    dispatch({ type: SET_FORCE_LOAD, payload: status });
  };
};
export const moveReport = (rpt, target) => {
  return dispatch => {
    axios
      .post(
        `${process.env.REACT_APP_BIGHORN_SERVER}/api/powerBI/moveReport`,
        {
          rpt,
          target
        },
        {
          headers: {
            ClientToken: localStorage.getItem("clientToken")
          }
        }
      )
      .then(response => {
        console.log(response);
        dispatch(removeNewTab(rpt, target));
        dispatch(getFolderList());
        dispatch(getReports());
      });
  };
};
export const filterPBIFolders = (filter, folders, modal = false) => {
  return dispatch => {
    if (filter === "") {
      if (modal) {
        dispatch(setModalSearchFolders(folders));
      } else {
        dispatch(setSearchFolders(folders));
      }
    } else {
      let newFolders = cloneDeep(folders);
      if (modal) {
        dispatch(setModalSearchFolders(searchFilter(newFolders, filter, true)));
      } else {
        dispatch(setSearchFolders(searchFilter(newFolders, filter, false)));
      }
    }
  };
};
export const selectFolders = (folders, select) => {
  return { type: SET_SELECTED_FOLDERS, folders, select };
};

export const setFolderList = folders => {
  return dispatch => {
    let sFolder = folders;
    if (typeof folders === "object") {
      sFolder = folders.treeData;
    }
    if (sFolder !== undefined && sFolder !== null) {
      let workspaces = sFolder.filter(item => {
        return !item.hidden;
      });
      dispatch(fetchFolderListSuccess(workspaces));
    }
    //dispatch(setSearchFolders(folders.treeData));
  };
};
export const setBaseFolders = folders => {
  return dispatch => {
    dispatch({ type: SET_BASE_FOLDERS, payload: folders });
  };
};

function removeFiles(filterFolders) {
  filterFolders.forEach((item, idx) => {
    let hasFolders;
    if (item.children !== undefined) {
      hasFolders = item.children.filter(item => {
        return item.fileType === "folder";
      });
    }
    if (item.children !== undefined && item.children.length > 0 && hasFolders.length > 0) {
      item.children = hasFolders;
      return removeFiles(item.children);
    } else {
      return null;
    }
  });
}
function searchFilter(newFolders, filter, modal) {
  return newFolders.map(newFolder => {
    if (newFolder.children === undefined) {
      newFolder.reports = newFolder.reports.filter(report => {
        return newFolder.name.toLowerCase().indexOf(filter.toLowerCase()) !== -1;
      });
    } else {
      newFolder.children = newFolder.children.filter((folder, index) => {
        if (folder.children === undefined) {
          return folder.name.toLowerCase().indexOf(filter.toLowerCase()) !== -1;
        } else {
          folder.children = folder.children.filter(child => {
            if (child.fileType === "folder") {
              if (modal) {
                return child.name.toLowerCase().indexOf(filter.toLowerCase()) !== -1;
              } else {
                child.children = child.children.filter(gChild => {
                  return gChild.name.toLowerCase().indexOf(filter.toLowerCase()) !== -1;
                });
                if (child.children.length > 0) {
                  return true;
                } else {
                  return false;
                }
              }
            } else {
              return child.name.toLowerCase().indexOf(filter.toLowerCase()) !== -1;
            }
          });
          if (folder.children.length > 0 || folder.name.toLowerCase().indexOf(filter.toLowerCase()) !== -1) {
            return true;
          } else {
            return false;
          }
        }
      });
    }
    return newFolder;
  });
}

/* -------------------- REDUCER -------------------- */
export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case FETCH_FOLDER_LIST_BEGIN:
      return { ...state, loading: true, error: null };
    case FETCH_FOLDER_LIST_SUCCESS:
      let fFolders = cloneDeep(action.payload);
      if (!Array.isArray(action.payload)) {
        fFolders = fFolders.treeData;
        console.log("IT IS TREE NODE");
      }

      removeFiles(fFolders);

      if (Array.isArray(action.payload)) {
        return {
          ...state,
          folders: action.payload,
          searchFolders: action.payload,
          modalFolders: fFolders,
          modalSearchFolders: fFolders,
          loading: false
        };
      } else {
        return {
          ...state,
          folders: action.payload.treeData,
          searchFolders: action.payload.treeData,
          modalFolders: fFolders,
          modalSearchFolders: fFolders,
          loading: false
        };
      }

    case FETCH_FOLDER_LIST_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
        reportConfig: []
      };
    case SET_SEARCH_FOLDERS:
      return {
        ...state,
        searchFolders: action.payload
      };
    case SET_MODAL_SEARCH_FOLDERS:
      return {
        ...state,
        modalSearchFolders: action.payload
      };

    case SET_BASE_FOLDERS:
      return {
        ...state,
        modalFolders: action.payload,
        modalSearchFolders: action.payload
      };
    case SET_FORCE_LOAD:
      return { ...state, forceLoad: action.payload };
    case SET_SELECTED_FOLDERS:
      let newSelected = cloneDeep(state.selected);
      let newFolders = cloneDeep(state.folders);
      newFolders = newFolders.map(folder => {
        if (folder.name === action.folders.name) {
          folder.selected = action.select;
        }
        return folder;
      });
      action.folders.selected = action.select;
      if (action.select) {
        newSelected.push(action.folders);
      } else {
        if (Array.isArray(action.folders)) {
          let aFolders = action.folders.map(folder => {
            return folder.path;
          });
          newSelected = newSelected.filter(folder => {
            return aFolders.indexOf(folder.path) === -1;
          });
        } else {
          newSelected = newSelected.filter(folder => {
            return folder.path !== action.folders.path;
          });
        }
      }
      return {
        ...state,
        selected: newSelected,
        folders: newFolders
      };
    default:
      return state;
  }
};
