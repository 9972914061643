import React, { Component } from "react";
import { connect } from "react-redux";
import { addTabToNav } from "components/topLevelNav/reducers/topLevelNavReducer";
import {
  getReportSubscriptions,
  deleteReportSubscription,
  selectAllSubscriptions
} from "components/reportSubscriptions/reducers/reportSubscriptionReducer";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";

import Checkbox from "rc-checkbox";
import "./style.css";
import RptSubscriptionItem from "components/reportSubscriptions/components/reportSubscriptionItem";
import axios from "axios";
import { toast } from "react-toastify";

class ReportSubscriptions extends Component {
  constructor(props) {
    super(props);
    let user;
    if (localStorage.getItem("user").includes("#")) {
      user = localStorage.getItem("user").split("#")[1];
    } else {
      user = localStorage.getItem("user");
    }
    this.state = {
      showDeleteReportSubscription: false,
      deleteRptSubItem: {},
      selectAll: false,
      user
    };
    this.props.addTabToNav({
      title: "Report Subscriptions",
      id: "reportSubscriptions",
      icon: "reportSubscriptions",
      type: "reportSubscriptions",
      path: "/reportSubscriptions"
    });
    this.props.getReportSubscriptions(null, user);
    this.handleClose = this.handleClose.bind(this);
  }
  componentDidMount() {}

  renderRptSubTable() {
    const { reportSubscriptions } = this.props;

    return reportSubscriptions.map((item, idx) => {
      return (
        <RptSubscriptionItem
          key={idx}
          idx={idx}
          item={item}
          selected={item.selected}
        />
      );
    });
  }
  handleClose() {
    this.setState({
      showDeleteReportSubscription: false
    });

    this.props.getReportSubscriptions(false, this.state.user);
  }
  render() {
    const { showDeleteReportSubscription } = this.state;
    return (
      <div style={{ margin: "20px", paddingTop: "60px" }}>
        <Modal isOpen={showDeleteReportSubscription}>
          <ModalHeader>Delete Report Subscription</ModalHeader>
          <ModalBody>
            <div>
              Are you sure your want to delete this report subscription?
            </div>
          </ModalBody>

          <ModalFooter>
            <Button
              // bsstyle="primary"
              className="ta-button ta-button-danger"
              onClick={() => {
                this.props.deleteReportSubscription(
                  this.state.deleteRptSubItem._id,
                  this.state.user
                );

                this.handleClose();
              }}
            >
              Delete
            </Button>
            <Button
              className="ta-button ta-button-secondary"
              onClick={() => {
                this.handleClose();
              }}
            >
              Cancel
            </Button>
          </ModalFooter>
        </Modal>
        <Button
          // bsstyle="primary"
          className="ta-button ta-button-primary"
          style={{ marginBottom: "20px" }}
          onClick={() => {
            const { reportSubscriptions } = this.props;
            let runIds = reportSubscriptions.filter(item => {
              return item.selected === true;
            });
            runIds = runIds.map(item => {
              return item._id;
            });

            axios
              .post(
                `${process.env.REACT_APP_BIGHORN_SERVER}/api/powerBI/runRptSubscriptions`,
                {
                  rptSubscriptions: runIds,
                  email: this.state.user
                },
                {
                  headers: {
                    ClientToken: localStorage.getItem("clientToken")
                  }
                }
              )
              .then(res => {
                if (runIds.length === 1) {
                  toast.success("Running subscription...");
                } else if (runIds.length > 1) {
                  toast.success("Running subscriptions");
                }
              });
          }}
        >
          Run Selected
        </Button>
        <table className="rpt-sub-table" style={{ width: "100%" }}>
          <tbody>
            <tr className="rpt-sub-header">
              <th>
                <Checkbox
                  style={{ marginRight: "10px" }}
                  onChange={() => {
                    this.props.selectAllSubscriptions(!this.state.selectAll);
                    this.setState({ selectAll: !this.state.selectAll });
                  }}
                  checked={this.state.selectAll}
                  className="select-all"
                />
                Report Subscription
              </th>
              <th>Path</th>
              <th>Bookmark</th>
              <th>Status</th>
              <th></th>
              <th></th>
            </tr>
            {this.renderRptSubTable()}
          </tbody>
        </table>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  reportSubscriptions: state.rptSubscriptions.reportSubscriptions,
  reportSubLoading: state.rptSubscriptions.loading
});

const mapDispatchToProps = dispatch => ({
  addTabToNav(tab) {
    dispatch(addTabToNav(tab));
  },
  getReportSubscriptions(loading, user) {
    dispatch(getReportSubscriptions(loading, null, user));
  },
  deleteReportSubscription(rptSubId, user) {
    dispatch(deleteReportSubscription(rptSubId, null, user));
  },
  selectAllSubscriptions(selectAll) {
    dispatch(selectAllSubscriptions(selectAll));
  }
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ReportSubscriptions);
