import React, { Component } from "react";

export class CustomHeaderRenderer extends Component {
  constructor(props) {
    super(props);
    props.reactContainer.style.display = "inline-block";
    this.state = {
      firstLine: this.props.displayName.split("%D")[0],
      secondLine: this.props.displayName.split("%D")[1]
    };
  }

  render() {
    return (
      <div>
        <div
          className="customHeaderLabel"
          style={{ textAlign: "center", lineHeight: "25px" }}
        >
          {this.state.firstLine}
        </div>
        <div
          style={{ textAlign: "center", fontSize: "11px", lineHeight: "25px" }}
        >
          {this.state.secondLine}
        </div>
      </div>
    );
  }
}
