import React, { Component } from "react";
import ReactDOM from "react-dom";

export class GroupFilterer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      text: "",
      columnDef: props.colDef
    };
    this.valueGetter = this.valueGetter.bind(this);

    this.onChange = this.onChange.bind(this);
  }
  valueGetter(node) {
    return node.data["f" + this.state.columnDef.field];
  }
  changedFilter() {
    this.props.filterChangedCallback();
    this.props.api.redrawRows();
  }
  isFilterActive() {
    return (
      this.state.text !== null &&
      this.state.text !== undefined &&
      this.state.text !== ""
    );
  }

  doesFilterPass(params) {
    return this.state.text
      .toLowerCase()
      .split(" ")
      .every(filterWord => {
        let value = this.valueGetter(params.node)
          .toString()
          .toLowerCase();
        return value.indexOf(filterWord) >= 0;
      });
  }

  getModel() {
    return { value: this.state.text };
  }

  setModel(model) {
    this.setState({ text: model ? model.value : "" });
  }

  afterGuiAttached(params) {
    this.focus();
  }

  focus() {
    setTimeout(() => {
      let container = ReactDOM.findDOMNode(this.refs.input);
      if (container) {
        container.focus();
      }
    });
  }

  componentMethod(message) {
    alert(`Alert from PartialMatchFilterComponent ${message}`);
  }

  onChange(event) {
    let newValue = event.target.value;
    if (this.state.text !== newValue) {
      this.setState(
        {
          text: newValue
        },
        () => {
          this.changedFilter();
        }
      );
    }
  }

  render() {
    let style = {
      //border: "2px solid #22ff22",
      borderRadius: "5px",
      //backgroundColor: "#bbffbb",
      width: "200px",
      height: "50px"
    };

    return (
      <div style={style}>
        Filter:{" "}
        <input
          style={{ height: "20px" }}
          ref="input"
          value={this.state.text}
          onChange={this.onChange}
          className="form-control"
        />
      </div>
    );
  }
}
