import React from "react";
import { connect } from "react-redux";
import axios from "axios";
import { toast } from "react-toastify";

//import { userActions } from "../_actions";

class Login extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      username: "",
      submitted: false
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }
  handleChange(e) {
    const { name, value } = e.target;
    this.setState({ [name]: value });
  }

  handleSubmit(e) {
    e.preventDefault();

    this.setState({ submitted: true });
    const { username } = this.state;
    axios
      .post(`${process.env.REACT_APP_BIGHORN_SERVER}/api/methodLogin`, {
        domain: username.split("@")[1]
      })
      .then(result => {
        console.log(result.data.type);
        let type = result.data.type;
        this.props.auth.login(username, type);
      })
      .catch(err => {
        console.log(err);
        toast.error("There was a problem logging you in. Please check your user id and try again");
      });

    //if (username && password) {
    //  dispatch(userActions.login(username, password));
    //}
  }
  render() {
    const { username, submitted } = this.state;
    return (
      <div>
        <form name="form" onSubmit={this.handleSubmit}>
          <div className={"form-group" + (submitted && !username ? " has-error" : "")}>
            <label htmlFor="username" style={{ fontWeight: 500, fontSize: "18px" }}>
              Username
            </label>
            <input type="text" className="form-control" name="username" value={username} onChange={this.handleChange} />
            {submitted && !username && <div className="help-block">Username is required</div>}
          </div>
          <div className="form-group">
            <button id="login-button" style={{ background: "rgba(0, 126, 167, 1)" }} className="btn btn-primary">
              Log In
            </button>
          </div>
        </form>
      </div>
    );
  }
}

const mapStateToProps = state => ({});
const mapDispatchToProps = dispatch => ({});

export default connect(mapStateToProps, mapDispatchToProps)(Login);
