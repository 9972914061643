import React, { Component } from "react";
import { connect } from "react-redux";
import { setViewSelected, selectBaseline, renameBaseline, getProjects } from "components/accubase/reducers/accubaseReducer";
import ReactDOM from "react-dom";
import { Gear, BaselineIcon, DeleteIcon, Pen, DownloadIcon, FinalBaseline } from "lib/icons";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import axios from "axios";
import { toast } from "react-toastify";
import { extractFileName } from "lib/lib";
import { save } from "save-file";
import { DotLoader } from "react-spinners";
import { Col } from "react-flexbox-grid";
import { OverlayTrigger, Tooltip } from "react-bootstrap";

class BaselineCard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      manageOpened: false,
      editBaseline: false,
      deletingBaseline: false,
      showDeleteBaselineConfirmModal: false
    };

    this.manageClickHandler = this.manageClickHandler.bind(this);
    this.Manage = React.createRef();
    this.baselineNameInput = React.createRef();
    this.handleClickOutside = this.handleClickOutside.bind(this);
    this.handleClose = this.handleClose.bind(this);
  }
  componentWillUnmount() {
    document.removeEventListener("mousedown", this.handleClickOutside);
  }
  handleClickOutside(event) {
    const area = ReactDOM.findDOMNode(this.Manage.current);
    if (area !== null) {
      if (!area.parentElement.contains(event.target)) {
        this.setState({ manageOpened: false });
      }
    }
  }
  handleClose() {
    this.setState({
      showDeleteBaselineConfirmModal: false,
      showCannotDeleteModal: false
    });
  }
  componentDidMount() {
    document.addEventListener("mousedown", this.handleClickOutside);
  }

  manageClickHandler() {
    this.setState({ manageOpened: !this.state.manageOpened });
  }

  keyDownHandler(event) {
    if (event.key === "Enter") {
      let batchId = this.props.baseline.path.split("/")[1];

      this.props.renameBaseline(this.props.baseline.id, this.baselineNameInput.current.value, batchId);

      this.setState({
        editBaseline: false
      });
    } else if (event.key === "Escape") {
      this.setState({ editBaseline: false });
    }
  }
  deleteHandler(type) {
    if (type === "baseline") {
      this.setState({ deletingBaseline: true });
      axios
        .get(`${process.env.REACT_APP_BIGHORN_SERVER}/api/accubase/deleteBaseline/${this.props.baseline.id}`, {
          headers: {
            ClientToken: localStorage.getItem("clientToken")
          }
        })
        .then(res => {
          this.props.getProjects(false);

          this.setState({
            deletingBaseline: false,
            showDeleteBaselineConfirmModal: false
          });
          toast.success("Baseline: " + this.props.baseline.name + " Deleted");
        });
    }
  }

  downloadHandler() {
    toast.success("Preparing Baseline Download");
    axios
      .post(
        `${process.env.REACT_APP_BIGHORN_SERVER}/api/accubase/downloadBaseline`,
        {
          baselineId: this.props.baseline.id,
          baselineName: this.props.baseline.name
        },
        {
          headers: {
            ClientToken: localStorage.getItem("clientToken")
          }
        }
      )
      .then(res => {
        var filename = extractFileName(res.headers["content-disposition"]);
        console.log("File name", filename);
        this.setState({ manageOpened: false });
        //invoke 'Save' dialog
        save(res.data, filename);
      });
  }
  render() {
    const { baseline, selectedBatch } = this.props;
    const { showDeleteBaselineConfirmModal, showCannotDeleteModal } = this.state;
    let finalCount = {};
    if (selectedBatch !== null) {
      if (selectedBatch.baselines !== undefined) {
        finalCount = selectedBatch.baselines.filter(item => {
          return item.final === true;
        });
      }
    }

    //this.props.getAccubaseData(baseline.id);
    return (
      <Col key={this.props.index} className="card baseline-card">
        <Modal isOpen={showCannotDeleteModal}>
          <ModalHeader>Cannot Delete Baseline</ModalHeader>
          <ModalBody>
            <div>Cannot delete a baseline that is marked as final. Remove the final flag and try again.</div>
          </ModalBody>

          <ModalFooter>
            <Button onClick={this.handleClose}>Close</Button>
          </ModalFooter>
        </Modal>
        <Modal isOpen={showDeleteBaselineConfirmModal}>
          <ModalHeader>Delete Baseline</ModalHeader>
          <ModalBody>
            <div>Are you sure you want to delete this baseline and the data associated with it?</div>
          </ModalBody>

          <ModalFooter>
            {!this.state.deletingBaseline ? (
              <Button
                color="primary"
                onClick={() => {
                  this.deleteHandler("baseline");
                }}>
                Delete
              </Button>
            ) : (
              <div
                className="accubase-uploading"
                style={{
                  marginLeft: "auto",

                  display: "flex",
                  flexDirection: "row"
                }}>
                <DotLoader sizeUnit={"px"} size={15} color={"black"} loading={true} />
                <div style={{ marginLeft: "5px" }}>Deleting ...</div>
              </div>
            )}
            <Button onClick={this.handleClose}>Cancel</Button>
          </ModalFooter>
        </Modal>

        <div
          style={{
            width: "100%",
            height: "30px",
            display: "flex",
            flexDirection: "row",
            paddingTop: "2px",
            position: "relative"
          }}>
          {baseline.status === "running" && (
            <div
              style={{
                color: "rgb(196,152,108)",
                border: "1px solid rgb(251,238,223)",
                paddingTop: "3px",
                height: "23px",
                marginLeft: "-8px",
                paddingLeft: "5px",
                width: "136px",
                background: "rgb(251,238,223)"
              }}>
              CALC IN PROGRESS
            </div>
          )}
          {(baseline.status === "failed" || baseline.status === null) && (
            <div
              className="FAILED"
              style={{
                color: "#721c24",
                border: "1px solid #f5c6cb",
                paddingTop: "3px",
                height: "23px",
                marginLeft: "-8px",
                paddingLeft: "5px",
                width: "50px",
                background: "#f8d7da"
              }}>
              Failed
            </div>
          )}
          {baseline.final === true && (
            <OverlayTrigger key={"final-tip"} placement={"left"} overlay={<Tooltip id={"final-tip"}>Final Baseline</Tooltip>}>
              <div>
                <FinalBaseline
                  height={"20px"}
                  width={"20px"}
                  color={"rgba(126, 126, 126, 1)"}
                  className="final-baseline"
                  style={{
                    marginBottom: "3px",
                    marginRight: "20px"
                  }}

                  // onClick={item => {
                  // }}
                />
              </div>
            </OverlayTrigger>
          )}
          <div
            onClick={() => {
              this.manageClickHandler();
            }}
            style={{
              marginLeft: "auto",
              cursor: "pointer",
              display: "flex",
              paddingTop: "1px",
              flexDirection: "row"
            }}>
            <Gear className="selectable gear" height={"20px"} width={"20px"} color={"rgba(165, 165, 165, 1)"} />
          </div>
          {this.state.manageOpened && (
            <div
              className="gear-menu"
              ref={this.Manage}
              style={{
                right: "-4px",
                top: "25px",
                position: "absolute",
                zIndex: 1,
                background: "white",
                borderRadius: "0",
                border: "1px solid rgb(229, 230, 232)"
              }}>
              <div
                style={{
                  padding: "5px",
                  cursor: "pointer",
                  borderBottom: "1px solid rgb(229, 230, 232)"
                }}
                className={"menu-item " + (this.props.baseline.status === "complete" ? "" : "disabled-card-menu-item")}
                onClick={() => {
                  if (this.props.baseline.status === "complete") {
                    this.downloadHandler();
                    this.setState({ manageOpened: false });
                  }
                }}>
                <DownloadIcon
                  height={"16px"}
                  width={"16px"}
                  color={"rgba(165, 165, 165, 1)"}
                  style={{
                    cursor: "pointer",
                    marginRight: "10px",
                    marginBottom: "3px"
                  }}
                />
                Download
              </div>
              <div
                style={{
                  cursor: "pointer",
                  padding: "5px",
                  borderBottom: "1px solid rgb(229, 230, 232)"
                }}
                className="menu-item"
                onClick={() => {
                  this.setState({ editBaseline: true, manageOpened: false });
                }}>
                <Pen
                  height={"16px"}
                  width={"16px"}
                  color={"rgba(165, 165, 165, 1)"}
                  style={{ marginRight: "10px", cursor: "pointer" }}
                />
                Rename
              </div>

              <div
                style={{
                  cursor: "pointer",
                  padding: "5px",
                  borderBottom: "1px solid rgb(229, 230, 232)"
                }}
                onClick={() => {
                  if (baseline.final) {
                    this.setState({
                      showCannotDeleteModal: true,
                      manageOpened: false
                    });
                  } else {
                    this.setState({
                      showDeleteBaselineConfirmModal: true,
                      managedOpened: false
                    });
                  }
                }}
                className="menu-item">
                <DeleteIcon
                  height={"16px"}
                  width={"16px"}
                  color={"rgba(165, 165, 165, 1)"}
                  style={{
                    cursor: "pointer",
                    marginBottom: "3px",
                    marginRight: "10px"
                  }}
                />
                Delete
              </div>
              {baseline.final === false ? (
                <div
                  style={{
                    cursor: "pointer",
                    padding: "5px"
                  }}
                  className={"menu-item " + (finalCount.length === 0 ? "" : "disabled-card-menu-item")}
                  onClick={() => {
                    if (finalCount.length === 0) {
                      this.props.baseline.final = true;
                      this.setState({ manageOpened: false });

                      axios
                        .post(
                          `${process.env.REACT_APP_BIGHORN_SERVER}/api/accubase/makeFinal`,
                          { baselineId: this.props.baseline.id, status: true },
                          {
                            headers: {
                              ClientToken: localStorage.getItem("clientToken")
                            }
                          }
                        )
                        .then(res => {
                          //this.setState({ manageOpened: false });
                          //this.props.getProjects();
                        });
                    }
                  }}>
                  <FinalBaseline
                    height={"16px"}
                    width={"16px"}
                    color={"rgba(165, 165, 165, 1)"}
                    style={{
                      cursor: "pointer",
                      marginBottom: "3px",
                      marginRight: "10px"
                    }}
                  />
                  Make Final
                </div>
              ) : (
                <div
                  style={{
                    cursor: "pointer",
                    padding: "5px"
                  }}
                  className="menu-item"
                  onClick={() => {
                    this.props.baseline.final = false;
                    this.setState({ manageOpened: false });
                    axios
                      .post(
                        `${process.env.REACT_APP_BIGHORN_SERVER}/api/accubase/makeFinal`,
                        { baselineId: this.props.baseline.id, status: false },
                        {
                          headers: {
                            ClientToken: localStorage.getItem("clientToken")
                          }
                        }
                      )
                      .then(res => {
                        //this.props.getProjects();
                      });
                  }}>
                  <FinalBaseline
                    height={"16px"}
                    width={"16px"}
                    color={"rgba(165, 165, 165, 1)"}
                    style={{
                      cursor: "pointer",
                      marginBottom: "3px",
                      marginRight: "10px"
                    }}
                  />
                  Unset Final
                </div>
              )}
            </div>
          )}
        </div>
        <div style={{ display: "flex", flexDirection: "row" }}>
          <div style={{}}>
            <BaselineIcon height="20px" />
          </div>
          <div
            style={{
              flexDirection: "column",
              marginLeft: "10px",
              overflow: "hidden",
              width: "100%"
            }}>
            {this.state.editBaseline ? (
              <input
                style={{ width: "100%" }}
                defaultValue={baseline.name}
                ref={this.baselineNameInput}
                onChange={evt => {
                  if (evt.keyCode === 13) {
                    this.setState({ editBaseline: false });
                  }
                }}
                onKeyDown={event => {
                  baseline.name = this.baselineNameInput.current.value;
                  this.keyDownHandler(event);
                }}
              />
            ) : (
              <div className="card-title">{baseline.name}</div>
            )}
            <div className="card-details">
              <span className="card-detail-heading">In/Out week:</span>
              <span className="card-detail-value">{baseline.inOutWeekCount}</span>
              <span className="card-detal-suffix"></span>
            </div>
            <div className="card-details">
              <span className="card-detail-heading">Min unit sales:</span>
              <span className="card-detail-value">{baseline.minUnits}</span>
              <span className="card-detal-suffix"></span>
            </div>
            {/* <div style={{display:"flex",flexDirection:"row"}}> */}
            <div className="card-details">
              <span className="card-detail-heading">Min sales:</span>
              <span className="card-detail-value">{(baseline.minCat * 100).toFixed(1).toString()}</span>
              <span className="card-detal-suffix">%</span>
            </div>
            {/* <div>%</div>
            </div> */}
          </div>
        </div>
        <div
          style={{
            borderBottom: "1px solid #DBDDDE",
            marginTop: "10px",
            marginBottom: "10px"
          }}></div>
        {/* <hr /> */}
        <div
          style={{
            cursor: "pointer",
            textAlign: "center",
            fontSize: "14px",
            fontWeight: "bold",
            color: "#0080AB"
          }}
          className={this.props.baseline.status === "complete" ? "" : "disabled-card"}
          onClick={() => {
            //this.props.getAccubaseData(baseline.id);
            if (this.props.baseline.status === "complete") {
              this.props.selectBaseline(baseline, 2);
            }
          }}>
          Review
        </div>
      </Col>
    );
  }
}

const mapStateToProps = state => ({
  selectedBatch: state.accubase.selectedBatch
});
const mapDispatchToProps = dispatch => ({
  getProjects(selectProject = true) {
    dispatch(getProjects(selectProject));
  },
  setViewSelected(view) {
    dispatch(setViewSelected(view));
  },
  selectBaseline(baseline, viewSelected) {
    dispatch(selectBaseline(baseline, viewSelected));
  },
  renameBaseline(id, name, batchId) {
    dispatch(renameBaseline(id, name, batchId));
  }
});

export default connect(mapStateToProps, mapDispatchToProps)(BaselineCard);
