import React, { Component } from "react";
import "components/files/style/style.css";
import {
  DeleteIcon,
  ItemAttributes,
  GeoAttributes,
  RightChevron,
  CalendarIcon
} from "lib/icons";
import { BaselineFolder } from "./baselineFolder";

export class ProjectFolder extends Component {
  constructor(props) {
    super(props);
    this.state = {
      collapsed: false,
      selected: false,
      path: "",
      editing: false,
      name: this.props.item.PROJECT_NAME
    };
    this.collapseHandler = this.collapseHandler.bind(this);
    this.renderCollapsible = this.renderCollapsible.bind(this);
    this.clickHandler = this.clickHandler.bind(this);
    this.keyDownHandler = this.keyDownHandler.bind(this);
    this.editFolderName = this.editFolderName.bind(this);
    this.textInput = React.createRef();
    this.closeTextInput = this.closeTextInput.bind(this);
  }
  componentDidMount() {
    if (
      this.textInput.current !== undefined &&
      this.textInput.current !== null
    ) {
      this.textInput.current.focus();
      this.textInput.current.select();

      //window.addEventListener("blur", this.closeTextInput, false);
    }
  }
  componentDidUpdate() {
    if (
      this.textInput.current !== undefined &&
      this.textInput.current !== null
    ) {
      this.textInput.current.focus();
      this.textInput.current.select();

      window.addEventListener("blur", this.closeTextInput, false);
    }
  }
  collapseHandler() {
    this.setState(prevState => ({
      collapsed: !prevState.collapsed
    }));
  }
  componentWillUnmount() {
    window.removeEventListener("blur", this.closeTextInput, false);
  }
  renderCollapsible(item) {
    if (item.collapsible) {
      return (
        <RightChevron
          transform={this.state.collapsed ? "" : "rotate(90,12,12)"}
          height={"24px"}
          width={"24px"}
          color={"rgba(88, 89, 91, 1)"}
          viewBox={"0 -2 24 24"}
        />
      );
    }
  }
  editFolderName(event, item) {
    if (item.status !== undefined && item.status === "new") {
      item.editing = true;
      this.setState({ editing: true, name: item.PROJECT_NAME });
    }
  }
  closeTextInput(event) {
    if (event.type === "blur") {
      window.removeEventListener("blur", this.closeTextInput, false);
      this.props.item.PROJECT_NAME = {};
      this.props.item.PROJECT_NAME = "";

      if (this.textInput.current !== null) {
        this.props.selectFolder(this.textInput.current.value);
        this.props.editName(
          this.props.item,
          this.textInput.current.value,
          this.state.name
        );

        if (this.props.folderClick !== undefined) {
          this.props.folderClick(this.props.item);
        }
      }
    }
  }
  keyDownHandler(event, item) {
    if (event.key === "Enter") {
      this.props.editName(
        this.props.item,
        this.textInput.current.value,
        this.state.name
      );
      this.props.selectFolder(this.textInput.current.value);
      if (this.props.folderClick !== undefined) {
        this.props.folderClick(this.props.item);
      }
    } else if (event.key === "Escape") {
      this.props.item.editing = false;
      this.props.editName(this.props.item, this.state.name, this.state.name);
      this.setState({ editing: false });
    }
  }
  clickHandler(event, batch, project, target) {
    if (this.props.clickHandler !== undefined) {
      this.props.clickHandler(event, batch, project, target);
    }
  }

  renderProjectChildren(item, idx, baseline) {
    if (item.batch.length === 0) {
      return <div key={idx} />;
    }

    return item.batch.map((batch, i) => {
      let path = batch.path;
      if (baseline) {
        return (
          <BaselineFolder
            item={batch}
            project={item}
            isDialog={this.props.isDialog}
            index={i}
            key={"blf" + i}
            selected={this.props.selected}
            clickHandler={this.props.baselineClickHandler}
          />
        );
      } else {
        return (
          <div
            key={i}
            className={
              "ta-av-input-data-item" +
              (this.props.selected === path ? " selected" : "")
            }
            style={{
              marginBottom: "5px",
              paddingLeft: "10px",
              display: "flex",
              flexDirection: "row"
            }}
            onClick={event => {
              this.clickHandler(event, batch, item, "batch");
            }}
          >
            <div style={{ marginRight: "30px" }}>{batch.name}</div>

            <div
              style={{
                marginLeft: "auto",
                display: "flex",
                flexDirection: "row"
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  marginRight: "30px"
                }}
              >
                {batch.geoLoaded && (
                  <div
                    style={{}}
                  >
                    <GeoAttributes
                      height={"16px"}
                      width={"16px"}
                      color={"rgba(126, 126, 126, 1)"}
                      style={{
                        marginBottom: "3px"
                      }}
                    />
                  </div>
                )}
                {batch.itemLoaded && (
                  <div
                    style={{ marginLeft: "20px" }}
                  >
                    <ItemAttributes
                      height={"16px"}
                      width={"16px"}
                      color={"rgba(126, 126, 126, 1)"}
                      style={{
                        marginBottom: "3px"
                      }}
                    />
                  </div>
                )}
              </div>
              <div style={{ marginRight: "30px" }}>Rows: {batch.rowCount}</div>
              <div style={{ marginRight: "30px" }}>
                Weeks: {batch.periodCount}
              </div>
              <div style={{ marginRight: "30px" }}>
                Period End: {batch.periodEnd}
              </div>
              <div
                className="delete interactive-svg"
                onClick={event => {
                  this.clickHandler(event, batch, item, "delete");
                }}
              >
                <DeleteIcon
                  height={"16px"}
                  width={"16px"}
                  // color={"rgba(0, 126, 167, 1)"}
                  color="white"
                  style={{
                    cursor: "pointer",
                    marginTop: "-3px",
                    marginLeft: "auto",
                    marginRight: "20px"
                  }}
                />
              </div>
            </div>
          </div>
        );
      }
    }, this);
  }
  render() {
    const { item, index, collapsible, project, baseline } = this.props;
    const { collapsed } = this.state;
    if (item.PROJECT_NAME === "") {
      item.PROJECT_NAME = item.name;
    }
    let path = item.path;
    if (collapsed) {
      return (
        <div key={index}>
          <div style={{ display: "flex", flexDirection: "row" }}>
            <div
              key={index}
              style={{ display: "flex" }}
              onClick={() => {
                this.collapseHandler(index);
              }}
            >
              <RightChevron
                //transform={this.state.collapsed ? "" : "rotate(90,12,12)"}
                height={"24px"}
                width={"24px"}
                color={"rgba(88, 89, 91, 1)"}
                viewBox={"0 -2 24 24"}
              />
            </div>
            {this.props.item.editing ? (
              <div
                onClick={event => {
                  this.clickHandler(event, item);
                }}
                onDoubleClick={event => {
                  this.editFolderName(event, item);
                }}
                className={
                  "ta-project-title " +
                  (this.props.selected === path ? "selected" : "")
                }
              >
                <input
                  style={{ width: "100%" }}
                  className="folder-edit-text"
                  defaultValue={item.name}
                  ref={this.textInput}
                  onChange={evt => {
                    if (evt.keyCode === 13) {
                      this.props.item.editing = false;
                      this.setState({ editing: false });
                    }
                  }}
                  onBlur={this.closeTextInput}
                  onKeyDown={event => {
                    item.name = this.textInput;
                    this.keyDownHandler(event, item);
                  }}
                />
              </div>
            ) : (
              <div>
                <div
                  // onClick={event => {
                  //   this.clickHandler(event, item);
                  // }}
                  onClick={() => {
                    this.collapseHandler(index);
                  }}
                  onDoubleClick={event => {
                    this.editFolderName(event, item);
                  }}
                  className={
                    "ta-project-title " +
                    (this.props.selected === path ? "selected" : "")
                  }
                >
                  {item.PROJECT_NAME}
                </div>
              </div>
            )}
          </div>
        </div>
      );
    }
    if (project || baseline) {
      return (
        <div>
          <div
            className="folder-div"
            style={{ display: "flex", flexDirection: "row" }}
          >
            <div
              className="collapse"
              style={{ display: "flex" }}
              onClick={() => {
                this.collapseHandler(index);
              }}
            >
              <RightChevron
                transform={this.state.collapsed ? "" : "rotate(90,12,12)"}
                height={"24px"}
                width={"24px"}
                color={"rgba(88, 89, 91, 1)"}
                viewBox={"0 -2 24 24"}
              />
            </div>
            {this.props.item.editing ? (
              <div
                onClick={event => {
                  this.clickHandler(event, item);
                }}
                onDoubleClick={event => {
                  this.editFolderName(event, item);
                }}
                className={"ta-project-title "}
              >
                <input
                  style={{ width: "100%" }}
                  className="folder-edit-text"
                  defaultValue={item.name}
                  ref={this.textInput}
                  onChange={evt => {
                    if (evt.keyCode === 13) {
                      this.props.item.editing = false;
                      this.setState({ editing: false });
                    }
                  }}
                  onBlur={this.closeTextInput}
                  onKeyDown={event => {
                    item.name = this.textInput;
                    this.keyDownHandler(event, item);
                  }}
                />
              </div>
            ) : (
              <div
                className="ta-project-title-parent collapse"
                style={{ display: "flex", flexDirection: "row" }}
              >
                <div
                  // onClick={event => {
                  //   this.clickHandler(event, item);
                  // }}
                  onClick={() => {
                    this.collapseHandler(index);
                  }}
                  onDoubleClick={event => {
                    this.editFolderName(event, item);
                  }}
                  className={"ta-project-title "}
                >
                  {item.PROJECT_NAME}
                </div>

                {/* <div
                  className="interactive-svg"
                  data-type="info"
                  data-tip="Edit Week End"
                  onClick={event => {
                    this.clickHandler(event, null, item, 'editweekend');
                  }}
                  style={{ marginLeft: '20px' }}
                >
                  <ItemAttributes
                    height={'16px'}
                    width={'16px'}
                    // color={"rgba(0, 126, 167, 1)"}
                    color="white"
                    style={{
                      cursor: 'pointer',
                      marginLeft: 'auto',

                      marginTop: '-3px'
                    }}
                  />
                </div> */}
                <div
                  className="calendar interactive-svg"
                  onClick={event => {
                    this.clickHandler(event, null, item, "calendar");
                  }}
                  style={{ marginLeft: "20px" }}
                >
                  <CalendarIcon
                    height={"16px"}
                    width={"16px"}
                    // color={"rgba(0, 126, 167, 1)"}
                    color="white"
                    style={{
                      cursor: "pointer",

                      marginRight: "5px",
                      marginTop: "-3px"
                    }}
                  />
                </div>
                <div
                  className="delete interactive-svg"
                  onClick={event => {
                    this.clickHandler(event, null, item, "delete");
                  }}
                  style={{ marginLeft: "20px" }}
                >
                  <DeleteIcon
                    height={"16px"}
                    width={"16px"}
                    // color={"rgba(0, 126, 167, 1)"}
                    color="white"
                    style={{
                      cursor: "pointer",

                      marginRight: "20px",
                      marginTop: "-3px"
                    }}
                  />
                </div>
              </div>
            )}
          </div>
          <div
            style={{
              paddingLeft: "40px"
            }}
          >
            {baseline
              ? this.renderProjectChildren(item, index, true)
              : this.renderProjectChildren(item, index, false)}
          </div>
        </div>
      );
    }
    if (collapsible) {
      return (
        <div>
          <div
            className="folder-div"
            style={{ display: "flex", flexDirection: "row" }}
          >
            <div
              className="collapse"
              style={{ display: "flex" }}
              onClick={() => {
                this.collapseHandler(index);
              }}
            >
              <RightChevron
                transform={this.state.collapsed ? "" : "rotate(90,12,12)"}
                height={"24px"}
                width={"24px"}
                color={"rgba(88, 89, 91, 1)"}
                viewBox={"0 -2 24 24"}
              />
            </div>
            {this.props.item.editing ? (
              <div
                onClick={event => {
                  this.clickHandler(event, item);
                }}
                onDoubleClick={event => {
                  this.editFolderName(event, item);
                }}
                className={
                  "ta-project-title " +
                  (this.props.selected === item.PROJECT_NAME ? "selected" : "")
                }
              >
                <input
                  style={{ width: "100%" }}
                  className="folder-edit-text"
                  defaultValue={item.name}
                  ref={this.textInput}
                  onChange={evt => {
                    if (evt.keyCode === 13) {
                      this.props.item.editing = false;
                      this.setState({ editing: false });
                    }
                  }}
                  onBlur={this.closeTextInput}
                  onKeyDown={event => {
                    item.name = this.textInput;
                    this.keyDownHandler(event, item);
                  }}
                />
              </div>
            ) : (
              <div
                // onClick={event => {
                //   this.clickHandler(event, item);
                // }}
                onClick={() => {
                  this.collapseHandler(index);
                }}
                onDoubleClick={event => {
                  this.editFolderName(event, item);
                }}
                className={
                  "ta-project-title collapse" +
                  (this.props.selected === item.PROJECT_NAME ? "selected" : "")
                }
              >
                {item.PROJECT_NAME}
              </div>
            )}
          </div>
          <div
            style={{
              paddingLeft: "40px"
            }}
          >
            {this.props.children}
          </div>
        </div>
      );
    }
    //Return for non collapsible

    return (
      <div>
        <div
          className="folder-div"
          style={{ display: "flex", flexDirection: "row", paddingLeft: "5px" }}
        >
          {this.props.item.editing ? (
            <div
              onClick={event => {
                this.clickHandler(event, item);
              }}
              onDoubleClick={event => {
                this.editFolderName(event, item);
              }}
              className={
                "ta-project-title " +
                (this.props.selected === item.PROJECT_NAME ? "selected" : "")
              }
            >
              <input
                style={{ width: "100%" }}
                className="folder-edit-text"
                defaultValue={item.PROJECT_NAME}
                ref={this.textInput}
                onChange={evt => {
                  if (evt.keyCode === 13) {
                    this.props.item.editing = false;

                    this.setState({ editing: false });
                  }
                }}
                onBlur={this.closeTextInput}
                onKeyDown={event => {
                  item.name = this.textInput;
                  this.keyDownHandler(event, item);
                }}
              />
            </div>
          ) : (
            <div
              onClick={event => {
                this.clickHandler(event, item);
              }}
              onDoubleClick={event => {
                this.editFolderName(event, item);
              }}
              className={
                "ta-project-title " +
                (this.props.selected === item.PROJECT_NAME ? "selected" : "")
              }
            >
              {item.PROJECT_NAME}
            </div>
          )}
        </div>
        <div
          style={{
            paddingLeft: "40px"
          }}
        >
          {this.props.children}
        </div>
      </div>
    );
  }
}
