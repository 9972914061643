import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { createStore, applyMiddleware } from "redux";

import "./index.css";

import * as serviceWorker from "./serviceWorker";
import ReduxThunk from "redux-thunk";
//import { createLogger } from "redux-logger";
import rootReducer from "reducers/rootReducer";
import {TabsRouter} from "./routes";
import CacheBuster from "./cacheBuster";

import "bootstrap/dist/css/bootstrap.css";

function configureStore(initialState = {}) {
  //const reduxMiddleware = applyMiddleware(ReduxThunk, createLogger());
  const reduxMiddleware = applyMiddleware(ReduxThunk);
  return createStore(rootReducer, initialState, reduxMiddleware);
}
let configuredStore = configureStore();

ReactDOM.render(
  <Provider store={configuredStore}>
    <CacheBuster>
      {({ loading, isLatestVersion, refreshCacheAndReload }) => {
        if (loading) return null;
        if (!loading && !isLatestVersion) {
          refreshCacheAndReload();
        }
        return <TabsRouter store={configuredStore} />;
      }}
    </CacheBuster>
  </Provider>,
  document.getElementById("root")
);
serviceWorker.unregister();
