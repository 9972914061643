import React, { Component } from "react";
import "./style.css";

import { connect } from "react-redux";
import {
  selectUnselectItem,
  selectAllLovs,
  deselectAllLovs,
  setUpdateFiltersStatus
} from "components/acculift/reducers/acculiftReducer";
import { cloneDeep } from "lodash";

class FilterItem extends Component {
  constructor(props) {
    super(props);
    this.state = {
      filter: this.props.filter
    };
    this.selectDeselectAll = this.selectDeselectAll.bind(this);
  }
  filterClick(e,item, parent) {
    
    if(e.metaKey || e.ctrlKey){
      this.props.selectUnselectItem(item, parent,false);
    }else{
      this.props.selectUnselectItem(item,parent,true);
    }
  }
  componentDidUpdate() {
    if (this.search.value !== "" && this.props.updateFilters) {
      //use the search handler to filter out things we dont want to see
      this.handleInputChange("", "");
      this.props.setUpdateFiltersStatus(false);
    }
  }
  renderFilterValues(values) {
    return values.map((item, idx) => {
      if (item.displayed === null || item.displayed === true) {
        return (
          <div
            key={idx}
            onClick={(e) => {
              this.filterClick(e,item, this.props.filter.name);
            }}
            className={"slicer " + (item.selected ? "slicer-selected" : "")}
            style={{ cursor: "pointer" }}
          >
            {item.value}
          </div>
        );
      } else {
        return null;
      }
    });
  }
  selectDeselectAll(filter, selectAll) {
    if (selectAll) {
      filter.values = filter.values.map(item => {
        item.selected = true;
        return item;
      });
      this.props.selectAllLovs(filter);
    } else {
      filter.values = filter.values.map(item => {
        item.selected = false;
        return item;
      });
      this.props.deselectAllLovs(filter);
    }
  }
  handleInputChange(event, item) {
    if (this.search.value === "") {
      this.setState({ filter: this.props.filter });
    } else {
      let newFilter = cloneDeep(this.props.filter);
      let nLovs = [];
      let filter = this.search.value;

      newFilter.values.forEach((item, idx) => {
        if (item.value.toLowerCase().indexOf(filter.toLowerCase()) !== -1)
          return nLovs.push(item);
      });
      newFilter.values = nLovs;
      this.setState({ filter: newFilter });
    }

    //this.validateInputs(item, event.target.value);
  }
  filterTitleFriendlyName(filterName) {
    switch (filterName) {
      case "PRIMARY_TACTIC_ALIAS":
        return "Primary Tactic";
      case "ITEM_PERCENTILE":
        return "Item Percentile";
      case "GEO_KEY":
        return "GEO";
      case "ITEM_KEY":
        return "Item";
      default:
        return filterName;
    }
  }
  render() {
    return (
      <div
        style={{
          height: "200px",
          marginTop: "10px",
          padding: "2px 6px 4px 6px",
          border: "1px solid rgb(229, 230, 232)",
          display: "flex",
          flexDirection: "column"
        }}
      >
        <div style={{ fontWeight: "600" }}>
          {this.filterTitleFriendlyName(this.props.filter.name)}
        </div>
        <div className="tabs-hr" style={{ marginBottom: "4px" }}></div>
        <div style={{ display: "flex", flexDirection: "row" }}>
          <div
            style={{ display: "flex", flexDirection: "column", width: "100%" }}
          >
            <input
              className="input-box"
              placeholder="search"
              ref={input => (this.search = input)}
              onChange={event => {
                this.handleInputChange(event, "search");
              }}
              style={{
                // width: '350px',
                position: "relative",
                marginTop: "6px",
                marginBottom: "10px"
              }}
            />
            <div style={{ display: "flex", flexDirection: "row" }}>
              <div
                style={{
                  cursor: "pointer",
                  fontSize: "11px",
                  color: "rgba(0, 126, 167, 1)"
                }}
                onClick={() => {
                  this.selectDeselectAll(this.props.filter, true);
                }}
              >
                Select All
              </div>
              <div
                style={{
                  cursor: "pointer",
                  paddingLeft: "10px",
                  fontSize: "11px",
                  color: "rgba(0, 126, 167, 1)"
                }}
                onClick={() => {
                  this.selectDeselectAll(this.props.filter, false);
                }}
              >
                Deselect All
              </div>
            </div>
          </div>
        </div>
        <div style={{ overflowY: "auto", height: "100%" }}>
          {this.renderFilterValues(this.state.filter.values)}
        </div>
      </div>
    );
  }
}
const mapStateToProps = state => ({
  config: state.acculift.config,
  lovs: state.acculift.lovs,
  updateFilters: state.acculift.updateFilters
});
const mapDispatchToProps = dispatch => ({
  selectUnselectItem(item, parentName,selectOne) {
    dispatch(selectUnselectItem(item, parentName,selectOne));
  },
  selectAllLovs(filter) {
    dispatch(selectAllLovs(filter));
  },
  deselectAllLovs(filter) {
    dispatch(deselectAllLovs(filter));
  },
  setUpdateFiltersStatus(status) {
    dispatch(setUpdateFiltersStatus(status));
  }
});

export default connect(mapStateToProps, mapDispatchToProps)(FilterItem);
