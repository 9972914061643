import React, { Component } from "react";

export default class TotalRenderer extends Component {
  constructor(props) {
    super(props);

    this.state = {
      value: props.value
    };
  }

  // noinspection JSUnusedGlobalSymbols
  refresh(params) {
    if (params.value !== this.state.value) {
      this.setState({
        value: params.value
      });
    }
    return true;
  }

  render() {
    let { value } = this.state;
    let returned;
    if (value <= 100) {
      returned = <span>{value}%</span>;
    } else {
      returned = <span style={{ color: "red" }}>{value}%</span>;
    }
    return <div>{returned}</div>;
  }
}
