export class Enumeration {
  constructor(obj) {
    // eslint-disable-next-line
    for (const key in obj) {
      this[key] = obj[key];
    }
    return Object.freeze(this);
  }
  has = key => {
    return this.hasOwnProperty(key);
  };
}

export const accucastLEWorkflow = new Enumeration({
  1: "leWeighting",
  1.1: "leWeightingOverrides",
  2: "leSeasonality",
  2.1: "leSeasonalityOverrides",
  3: "storeDistribution",
  4: "incrementalLift",
  5: "shipments",
  prefix: "accucast/le",
  leWeighting: "1",
  leWeightingOverrides: "1.1",
  leSeasonality: "2",
  leSeasonalityOverrides: "2.1",
  storeDistribution: "3",
  incrementalLift: "4",
  shipments: "5"
});

export const accucastPlanWorkflow = new Enumeration({
  1: "itemInput",
  2: "organicGrowth",
  3: "seasonality",
  3.1: "seasonalityOverrides",
  4: "storeDistribution",
  5: "incrementalLift",
  6: "itemDistribution",
  7: "pricing",
  8: "shipments",
  prefix: "accucast/plan",
  itemInput: "1",
  organicGrowth: "2",
  seasonality: "3",
  seasonalityOverrides: "3.1",
  storeDistribution: "4",
  incrementalLift: "5",
  itemDistribution: "6",
  pricing: "7",
  shipments: "8"
});
