import React, { Component } from "react";
import NoAuthFileShareRouter from "./components/routes";
import TopLevelNav from "components/topLevelNav";

export class NoAuthFileShareWrapper extends Component {
  login() {
    this.props.auth.login();
  }
  render() {
    const { isAuthenticated } = this.props.auth;

    return (
      <div
        style={{
          // height: "100%",
          // minHeight: "100%",
          // position: "relative",
          // top: "45px"
          position: "absolute",
          top: "44px",
          bottom: "0px",
          left: "0px",
          right: "0px"
        }}>
        {isAuthenticated() && (
          //<div style={{ height: "100%", minHeight: "100%" }}>
          <div style={{ height: "100%" }}>
            <TopLevelNav />
            <NoAuthFileShareRouter />
          </div>
        )}
      </div>
    );
  }
}
