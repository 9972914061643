import React, { Component } from "react";

import ProjectSelector from "components/accuComponents/projectSelector";
//import FilterPanel from "components/accuComponents/filterPanel";

import { addTabToNav } from "components/topLevelNav/reducers/topLevelNavReducer";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { LoadingIndicator } from "lib/loadingIndicator";
import "./style.css";
import moment from "moment";
import AccubaseList from "./accubaseList";
import { cloneDeep } from "lodash";
import { AccubaseGrid } from "components/accubase/components/accubaseGrid";
import {
  EditableCellRenderer,
  EditableCurrencyCellRenderer,
  EditableNumberCellRenderer,
  EditableBooleanCellRenderer,
  EditableCheckboxCellRenderer
} from "components/agGrid/renderers";

import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import axios from "axios";

import ReactDOM from "react-dom";
import DualAxisLineChart from "./dualAxisLineChart";
import { ProjectFolder } from "components/accubase/components/projectFolder";
// import Select from "react-select";
// import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
// import Checkbox from "rc-checkbox";
import { socket } from "routes";
import {
  Gear,
  ProjectIcon,
  BaselineIcon,
  InputSourceIcon,
  LatestModel,
  DeleteIcon,
  Pen,
  RightChevron
  //DownloadIcon
} from "lib/icons";

import {
  setReinitChart,
  setFilteredData,
  getProjects,
  setSelectedProject,
  setViewSelected,
  setOldProjectName,
  setReInitProjects,
  setChartData,
  setChangedData,
  selectBaseline,
  setAccubaseData,
  setSearchProjects
} from "components/accubase/reducers/accubaseReducer";
import { DotLoader } from "react-spinners";
import { toast } from "react-toastify";

//Use this to give moment the correct format so it doesn't throw warnings
//Our timestamps look like this: 2020-04-06 14:31:03.000 -0700
const timestampFormat = "YYYY-MM-DD HH:mm:ss.SSS ZZ";

class Accubase extends Component {
  constructor(props) {
    super(props);

    this.state = {
      allProjectsOpened: true,
      filterPanelOpened: true,
      viewSelected: 1,
      agGridApi: null,
      manageOpened: false,
      showDeleteProjectConfirmModal: false,
      showDeleteBaselineConfirmModal: false,
      editBaseline: false,
      selectedProject: null,
      // showCreateNewProjModal:false,
      showTemplateModal: false,
      showUploadInputBatchModal: false,
      selectedRadioOption: 2,
      startDate: moment().startOf("year")._d,
      makeDefault: false,
      dupe: "",
      invalidFileMessage: "",
      // actions: [
      //   { label: "Ends", value: 1, id: 1 },
      //   { label: "Begins", value: 2, id: 2 }
      // ],
      // selectedAction: { label: "Ends", value: "Ends", id: 1 },
      uploadingStatus: false,
      saving: false,
      showSaveErrorModal: false,
      savingError: "",
      activeUploadButton: false,
      fileSet: false,
      accessPermissions: true
    };
    this.props.addTabToNav({
      title: "Accubase",
      name: "Accubase",
      id: "accubase",
      type: "accubase",
      path: "/accubase",
      icon: "accubase",
      activeChildren: []
    });
    this.clickHandler = this.clickHandler.bind(this);
    this.manageClickHandler = this.manageClickHandler.bind(this);
    this.allProjectStateChange = this.allProjectStateChange.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.filterPanelStateChange = this.filterPanelStateChange.bind(this);
    this.keyDownHandler = this.keyDownHandler.bind(this);
    this.BaselineNameInput = React.createRef();
    this.Manage = React.createRef();
    this.TemplateDialog = React.createRef();
    this.ProjectName = React.createRef();
    this.handleClickOutside = this.handleClickOutside.bind(this);
    // this.handleOptionChange = this.handleOptionChange.bind(this);
    // this.checkBoxChanged = this.checkBoxChanged.bind(this);
    this.removeErrorDiv = this.removeErrorDiv.bind(this);
    this.renderInvalidMessage = this.renderInvalidMessage.bind(this);
    this.uploadFile = this.uploadFile.bind(this);
    this.saveAccubase = this.saveAccubase.bind(this);
    this.cellValueChanged = this.cellValueChanged.bind(this);
    this.baseLineCompleteHandler = this.baseLineCompleteHandler.bind(this);
    this.filterChanged = this.filterChanged.bind(this);
  }

  componentWillUnmount() {
    //this.props.setSelectedProject({});

    document.removeEventListener("mousedown", this.handleClickOutside);

    socket.off("BaselineComplete", this.baseLineCompleteHandler);
  }
  handleClickOutside(event) {
    const area = ReactDOM.findDOMNode(this.Manage.current);
    if (area !== null) {
      if (!area.parentElement.contains(event.target)) {
        this.setState({ manageOpened: false, showTemplateModal: false });
      }
    }
    //Click outside handler for the Template Dialog
    const templateArea = ReactDOM.findDOMNode(this.TemplateDialog.current);
    // console.log(templateArea);
    if (templateArea !== null) {
      if (!templateArea.parentElement.contains(event.target)) {
        this.setState({ manageOpened: false, showTemplateModal: false });
      }
    }
  }

  componentDidUpdate() {
    if (
      !this.props.loadedProjects &&
      !this.props.loadingProjects &&
      !this.props.loadingError &&
      this.props.apps.length > 0 &&
      this.state.accessPermissions === true
    ) {
      let accessAllowed = this.props.apps.filter(item => {
        return item.type === "accubase";
      });

      if (accessAllowed.length > 0) {
        this.props.getProjects();
      } else {
        this.setState({ accessPermissions: false });
      }
    }
    if (this.props.loadedProjects && !this.state.loaded) {
      if (this.props.selectedProject === null) {
        this.props.setSelectedProject(this.props.projects[0]);
      }
      this.setState({ uploadFolders: this.props.projects, loaded: true });
    }
    if (this.props.reInitProjects) {
      this.props.setReInitProjects(false);
      this.setState({ uploadFolders: this.props.projects });
    }
  }

  componentDidMount() {
    socket.on("BaselineComplete", this.baseLineCompleteHandler);

    let accessAllowed = this.props.apps.filter(item => {
      return item.type === "accubase";
    });

    if (accessAllowed.length > 0) {
      this.props.getProjects();
      document.addEventListener("mousedown", this.handleClickOutside);
    }
  }

  baseLineCompleteHandler(data) {
    this.setState({ loaded: false });
    this.props.getProjects(false);
  }

  allProjectStateChange() {
    const { allProjectsOpened } = this.state;
    this.setState({ allProjectsOpened: !allProjectsOpened });
  }
  filterPanelStateChange() {
    const { filterPanelOpened } = this.state;
    this.setState({ filterPanelOpened: !filterPanelOpened });
  }
  clickHandler(view) {
    this.props.setViewSelected(view);
    //this.setState({ viewSelected: view });
    if (this.props.baselineId === -1) {
      if (this.props.projects[0].batch.length > 0) {
        if (this.props.projects[0].batch[0].baselines.length > 0) {
          let completeBaselines = this.props.projects[0].batch[0].baselines.filter(item => {
            return item.status === "complete";
          });
          if (completeBaselines.length > 0) {
            this.props.selectBaseline(completeBaselines[0], 2);
          }
        }
      }
    }
  }
  renderDownloadBaseline(isDialog) {
    const { projects } = this.props;
    let styles = {
      paddingTop: "10px",
      marginTop: "10px",
      border: "1px solid lightgray",
      height: "45%",
      width: "100%",
      marginBottom: "20px",
      maxHeight: "400px",
      overflow: "auto"
    };
    if (projects.length > 0) {
      if (isDialog) {
        styles = {
          paddingTop: "10px",
          marginTop: "10px",

          width: "100%"
        };
      }

      return (
        <div style={styles}>
          {projects.map((item, idx) => {
            return (
              <ProjectFolder
                collapsible={true}
                item={item}
                index={idx}
                key={idx}
                project={false}
                baseline={true}
                isDialog={isDialog}
                selected={this.state.baselineSelectedPath}
                clickHandler={this.batchClickHandler}
                baselineClickHandler={this.baselineClickHandler}
              />
            );
          })}
        </div>
      );
    } else {
      return <div style={styles} />;
    }
  }
  manageClickHandler() {
    this.setState({ manageOpened: !this.state.manageOpened });
  }
  handleClose() {
    this.setState({
      showDeleteBaselineConfirmModal: false,
      showDeleteProjectConfirmModal: false,
      // showCreateNewProjModal:false,
      selectedRadioOption: 2,
      showTemplateModal: false,
      showUploadInputBatchModal: false,
      showSaveErrorModal: false,
      selectedUploadFile: "",
      activeUploadButton: false,
      fileSet: false
    });
  }
  keyDownHandler(event) {
    if (event.key === "Enter") {
      // this.props.updateGroupName(
      //   this.props.selectedClient.value,
      //   this.props.group.id,
      //   this.textInput.current.value
      // );
      this.props.selectedProject.PROJECT_NAME = this.BaselineNameInput.current.value;
      this.props.setOldProjectName(this.props.selectedProject, this.BaselineNameInput.current.value);
      this.setState({
        editBaseline: false
      });
    } else if (event.key === "Escape") {
      this.props.selectedProject.PROJECT_NAME = this.props.oldProjectName; // this.setState({ groupName: this.props.group.name });
      this.setState({ editBaseline: false });
    }
  }
  renderInfoHeader() {
    const { selectedProject } = this.props;
    let totalBaselines = 0;
    let lastBaseline = null;
    let totalDataSources = 0;
    if (selectedProject === undefined || selectedProject.batch === undefined) {
      return <div></div>;
    }
    selectedProject.batch.forEach(sBatch => {
      if (sBatch.baselines !== undefined) {
        sBatch.baselines.forEach(baseline => {
          if (lastBaseline === null) {
            lastBaseline = baseline.lastModified;
          } else if (moment(lastBaseline, timestampFormat).isBefore(moment(baseline.lastModified, timestampFormat))) {
            lastBaseline = baseline.lastModified;
          }
        });

        totalBaselines = totalBaselines + sBatch.baselines.length;
      }
    });
    totalDataSources = totalDataSources + selectedProject.batch.length;

    return (
      <div style={{}} className="project-heading">
        {this.state.editBaseline ? (
          <input
            style={{ width: "50%" }}
            defaultValue={selectedProject.PROJECT_NAME}
            ref={this.BaselineNameInput}
            onChange={evt => {
              if (evt.keyCode === 13) {
                this.setState({ editGroupName: false });
              }
            }}
            onKeyDown={event => {
              selectedProject.PROJECT_NAME = this.BaselineNameInput;
              this.keyDownHandler(event);
            }}
          />
        ) : (
          <div style={{ width: "50%" }} className="">
            <div style={{}} className="kpi-title">
              Project Name
            </div>
            <div style={{ fontSize: "18px", fontWeight: "600" }}>{selectedProject.PROJECT_NAME}</div>
          </div>
        )}
        <div className="tabs-vr"></div>
        <div style={{}} className="minor-kpi-container">
          <div style={{}} className="kpi-title">
            Created Date
          </div>
          <div className="minor-kpi">{moment(selectedProject.PROJECT_DATE_CREATED, timestampFormat).format("MM/DD/YY")}</div>
        </div>
        <div className="tabs-vr"></div>
        <div style={{}} className="minor-kpi-container">
          <div className="kpi-title">Data Sources</div>
          <div className="minor-kpi">{totalDataSources}</div>
        </div>
        <div className="tabs-vr"></div>
        <div style={{}} className="minor-kpi-container">
          <div className="kpi-title">Total Baselines</div>
          <div className="minor-kpi">{totalBaselines}</div>
        </div>

        <div className="tabs-vr"></div>
        <div style={{ marginLeft: "8px" }}>
          <div className="kpi-title">Latest Baseline</div>
          <div className="minor-kpi">
            {lastBaseline ? (
              <span>{moment(lastBaseline, timestampFormat).format("MM/DD/YY")}</span>
            ) : (
              <span>No baselines found</span>
            )}
          </div>
        </div>
        <div
          style={
            {
              // marginLeft: "auto",
              // display: "flex",
              // flexDirection: "row",
              // alignItems: "center"
            }
          }
          className="manage-menu-link">
          <div
            onClick={() => {
              this.manageClickHandler();
            }}
            style={{ cursor: "pointer", display: "flex", flexDirection: "row" }}>
            <Gear className="selectable gear" height={"20px"} width={"20px"} color={"rgba(0, 126, 167, 1)"} />
            <div style={{ color: "rgba(0, 126, 167, 1)", marginLeft: "5px" }}>Manage</div>
          </div>
          {this.state.manageOpened && (
            <div
              ref={this.Manage}
              onBlur={() => {
                this.setState({ manageOpened: false });
              }}
              className="manage-drop-down"
              style={{
                borderRadius: "0",
                border: "1px solid rgb(229, 230, 232)"
              }}>
              <div
                onClick={() => {
                  this.setState({ editBaseline: true, manageOpened: false });
                }}
                className="manage-menu-item menu-item">
                <Pen
                  height={"16px"}
                  width={"16px"}
                  color={"rgba(165, 165, 165, 1)"}
                  style={{ marginRight: "5px", cursor: "pointer" }}
                />
                Rename
              </div>

              <div
                className="manage-menu-item menu-item"
                onClick={() =>
                  this.setState({
                    showDeleteProjectConfirmModal: true,
                    manageOpened: false
                  })
                }>
                <DeleteIcon
                  height={"16px"}
                  width={"16px"}
                  color={"rgba(165, 165, 165, 1)"}
                  style={{
                    cursor: "pointer",
                    marginBottom: "3px",
                    marginRight: "10px"
                  }}
                />
                Delete
              </div>
            </div>
          )}
        </div>
      </div>
    );
  }
  renderProject() {
    if (this.props.selectedProject !== null) {
      return (
        <div className="accu-project-div">
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              width: "100%",
              height: "100%"
            }}>
            {this.renderInfoHeader()}
            <hr style={{ alignContent: "center", width: "100%" }} />
            <div style={{ display: "flex", flexDirection: "row" }}>
              <div style={{ display: "flex", flexDirection: "column" }}>
                <div
                  style={{
                    fontWeight: "bold",
                    fontSize: "medium"
                  }}>
                  Input Batches
                </div>
                <div style={{ fontSize: "small" }}>Select an available input batch below to create a new baseline</div>
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  marginLeft: "auto",
                  alignItems: "center",
                  paddingBottom: "13px"
                }}>
                <div style={{ display: "flex", position: "relative" }}>
                  <div
                    className={
                      "template-instruct-button " + (this.state.showTemplateModal ? "template-instruct-dialog-open" : "")
                    }
                    onClick={() => {
                      const { showTemplateModal } = this.state;
                      this.setState({ showTemplateModal: !showTemplateModal });
                    }}
                    style={{ color: "rgb(54, 127, 167)", cursor: "pointer" }}>
                    Template & Loading Instructions
                  </div>
                  {this.state.showTemplateModal && (
                    <div
                      ref={this.TemplateDialog}
                      className="template-instruct-dialog"
                      style={{ display: "flex", flexDirection: "column" }}>
                      <div
                        style={{
                          width: "100%",
                          textAlign: "center",
                          fontWeight: "bold"
                        }}>
                        File Templates & Instructions
                      </div>
                      <div style={{ marginTop: "10px", marginBottom: "10px" }} className="info-text">
                        Review the instructions below to learn how to prepare your data for upload and then download the csv
                        templates for a jumpstart on creating your files.
                      </div>
                      <a
                        className="file-instructions"
                        href="https://tabsportal.blob.core.windows.net/public-folder/Preparing_Files_For_Upload.pdf"
                        target="blank">
                        How to prepare files for upload (pdf document)
                      </a>
                      <hr style={{ width: "100%" }} />
                      <a
                        style={{ marginBottom: "10px" }}
                        href="https://tabsportal.blob.core.windows.net/public-folder/Baseline_Input_Template.csv">
                        Download Input Batch Template (csv example)
                      </a>
                      <a
                        style={{ marginBottom: "10px" }}
                        href="https://tabsportal.blob.core.windows.net/public-folder/Item_Attribute_Template.csv">
                        Download Item Attribute Template (csv example)
                      </a>
                      <a href="https://tabsportal.blob.core.windows.net/public-folder/Geo_Attribute_Template.csv">
                        Download Geo Attribute Template (csv example)
                      </a>
                    </div>
                  )}
                </div>
                <div
                  onClick={() => {
                    this.setState({ showUploadInputBatchModal: true });
                  }}
                  style={{
                    marginLeft: "40px",
                    cursor: "pointer"
                  }}
                  className="btn btn-high-viz">
                  + Add Input Batch
                </div>
              </div>
            </div>
            <AccubaseList project={this.props.selectedProject} />
          </div>
        </div>
      );
    } else {
      return (
        <div className="accu-project-div">
          <div>Please Select a Project to continue</div>
        </div>
      );
    }
  }
  deleteHandler(type) {
    if (type === "project") {
      this.setState({ deletingProject: true });
      axios
        .get(`${process.env.REACT_APP_BIGHORN_SERVER}/api/accubase/deleteProject/${this.props.selectedProject.ID}`, {
          headers: {
            ClientToken: localStorage.getItem("clientToken")
          }
        })
        .then(res => {
          this.props.getProjects();
          this.handleClose();
          this.setState({ deletingProject: false });

          toast.success("Project Deleted");
        });
    } else {
      this.setState({ deletingBaseline: true });
      axios
        .get(`${process.env.REACT_APP_BIGHORN_SERVER}/api/accubase/deleteBaseline/${this.state.clickedBaselineId}`, {
          headers: {
            ClientToken: localStorage.getItem("clientToken")
          }
        })
        .then(res => {
          this.props.getProjects();
          this.handleClose();
          this.setState({ deletingBaseline: false });

          toast.success(this.state.clickedBaselineName + " Deleted");
        });
    }
  }
  handleSelectedFile = async event => {
    event.preventDefault();

    let evtTarget = event.target.files[0];
    if (event.target.files[0].type === "text/csv" || event.target.files[0].type === "application/vnd.ms-excel") {
      let uploadState = false;
      if (this.state.batchName !== "") {
        uploadState = true;
      }
      this.setState({
        selectedUploadFile: event.target.files[0].name,
        uploadFile: evtTarget,
        activeUploadButton: uploadState,
        fileSet: true
      });
    } else {
      this.setState({
        invalidFileMessage: ["The upload file must be a csv"]
      });
    }
  };
  uploadFile(type, event) {
    const { uploadFile, batchName } = this.state;
    const { selectedProject } = this.props;

    if (event === undefined || event === null) {
      if (uploadFile !== null && batchName !== "") {
        this.setState({ uploadingStatus: true });
        const data = new FormData();

        data.append("file", uploadFile);
        data.append("projectId", selectedProject.ID);
        data.append("batchName", batchName);
        data.append("type", type);

        axios
          .post(`${process.env.REACT_APP_BIGHORN_SERVER}/api/accubase/uploadInputFile`, data, {
            headers: {
              ClientToken: localStorage.getItem("clientToken")
            }
          })
          .then(res => {
            console.log(res);
            this.setState({ uploadingStatus: false });

            this.props.getProjects(false);
            this.handleClose();
            //this.props.setReInitProjects(true);
            //this.saveProjects(false);
          })
          .catch(err => {
            if (err.response !== undefined) {
              this.setState({
                invalidFileMessage: err.response.data.message
              });
            }
            this.setState({ uploadingStatus: false });
          });
      } else {
        this.setState({ showNeedFolderAndInputDialog: true });
      }
    } else {
      event.preventDefault();
      if (event.target.files[0].type === "text/csv" || event.target.files[0].type === "application/vnd.ms-excel") {
        if (type === "geo") {
          this.setState({ uploadingGeo: true });
        } else if (type === "item") {
          this.setState({ uploadingItem: true });
        }

        const data = new FormData();
        data.append("file", event.target.files[0]);
        data.append("projectId", selectedProject.ID);
        data.append("batchName", batchName);
        data.append("batchId", this.state.selectedBatchId);
        data.append("type", type);

        axios
          .post(`${process.env.REACT_APP_BIGHORN_SERVER}/api/accubase/uploadInputFile`, data, {
            headers: {
              ClientToken: localStorage.getItem("clientToken")
            }
          })
          .then(res => {
            console.log(res);
            if (type === "geo") {
              this.setState({ uploadingGeo: false });
            } else if (type === "item") {
              this.setState({ uploadingItem: false });
            }

            this.props.getProjects();
            this.handleClose();
          })
          .catch(err => {
            this.setState({ uploadingGeo: false, uploadingItem: false });

            if (err.response !== undefined) {
              this.setState({
                showUploadErrorDialog: true,
                uploadError: err.response.data.message
              });
            }
          });
      } else {
        this.setState({
          showUploadErrorDialog: true,
          uploadError: "The upload file must be a csv"
        });
      }
    }
  }
  renderInvalidMessage() {
    const { invalidFileMessage } = this.state;

    if (invalidFileMessage !== undefined && invalidFileMessage.length > 0) {
      return invalidFileMessage.map((item, index) => {
        if (item === " ") {
          return (
            <div style={{ height: "25px" }} key={index}>
              {item}
            </div>
          );
        } else if (item.startsWith("error-row ")) {
          let errorVal = item.split("error-row ", 2);
          return (
            <div className="error-row" key={index}>
              {errorVal[1]}
            </div>
          );
        } else {
          return (
            <div style={{ paddingBottom: "2px" }} key={index}>
              {item}
            </div>
          );
        }
      });
    }
  }
  renderHeader() {
    const { projects } = this.props;
    const {
      showDeleteProjectConfirmModal,
      showDeleteBaselineConfirmModal,
      showUploadInputBatchModal,
      activeUploadButton
    } = this.state;
    let totalProjects = projects.length;

    let totalBaselines = 0;
    let lastBaseline = null;
    let totalDataSources = 0;

    projects.forEach(item => {
      item.batch.forEach(sBatch => {
        if (sBatch.baselines !== undefined) {
          sBatch.baselines.forEach(baseline => {
            if (lastBaseline === null) {
              lastBaseline = baseline;
            } else if (
              moment(lastBaseline.lastModified, timestampFormat).isBefore(moment(baseline.lastModified, timestampFormat))
            ) {
              lastBaseline = baseline;
            }
          });

          totalBaselines = totalBaselines + sBatch.baselines.length;
        }
      });
      totalDataSources = totalDataSources + item.batch.length;
    });
    if (lastBaseline === null) {
      lastBaseline = { name: "No Baselines" };
    }
    return (
      <div
        style={{
          height: "100%",
          display: "flex",
          flexDirection: "row",
          alignItems: "center"
        }}>
        <Modal isOpen={showDeleteProjectConfirmModal}>
          <ModalHeader>Delete Project</ModalHeader>
          <ModalBody>
            <div>
              Are you sure you want to delete this project and all associated input data sources, baselines, and lift models?
              WARNING: This action cannot be undone.
            </div>
          </ModalBody>

          <ModalFooter>
            {!this.state.deletingProject ? (
              <Button
                color="primary"
                onClick={() => {
                  this.deleteHandler("project");
                }}>
                Delete
              </Button>
            ) : (
              <div
                className="accubase-uploading"
                style={{
                  marginLeft: "auto",

                  display: "flex",
                  flexDirection: "row"
                }}>
                <DotLoader sizeUnit={"px"} size={15} color={"black"} loading={true} />
                <div style={{ marginLeft: "5px" }}>Deleting ...</div>
              </div>
            )}
            <Button onClick={this.handleClose}>Cancel</Button>
          </ModalFooter>
        </Modal>
        <Modal isOpen={showDeleteBaselineConfirmModal}>
          <ModalHeader>Delete Baseline</ModalHeader>
          <ModalBody>
            <div>Are you sure you want to delete the selected baseline and corresponding lift models?</div>
          </ModalBody>

          <ModalFooter>
            {!this.state.deletingBaseline ? (
              <Button
                color="primary"
                onClick={() => {
                  this.deleteHandler("baseline");
                }}>
                Delete
              </Button>
            ) : (
              <div
                className="accubase-uploading"
                style={{
                  marginLeft: "auto",

                  display: "flex",
                  flexDirection: "row"
                }}>
                <DotLoader sizeUnit={"px"} size={15} color={"black"} loading={true} />
                <div style={{ marginLeft: "5px" }}>Deleting ...</div>
              </div>
            )}
            <Button onClick={this.handleClose}>Cancel</Button>
          </ModalFooter>
        </Modal>
        <Modal isOpen={showUploadInputBatchModal}>
          <ModalHeader>Upload Input Batch File</ModalHeader>
          <ModalBody>
            {this.state.invalidFileMessage.length > 0 && (
              <div
                className="error-div validation-error-div"
                style={{
                  position: "absolute",
                  display: "flex",
                  width: "93%",
                  padding: "5px",
                  zIndex: "1",
                  flexDirection: "column"
                }}>
                <div style={{ marginLeft: "auto", cursor: "pointer" }} onClick={this.removeErrorDiv}>
                  x
                </div>
                {this.renderInvalidMessage()}
              </div>
            )}
            <div style={{ display: "flex", flexDirection: "column" }}>
              <div>
                Batch Name <i>(required)</i>
              </div>
              <i style={{ fontSize: "11px" }}>Provide a descriptive name for your input batch</i>
              <input
                className="batch-name"
                ref={input => (this.batchNameRef = input)}
                onChange={event => {
                  if (this.state.fileSet) {
                    if (event.target.value === "") {
                      this.setState({
                        batchName: event.target.value,
                        activeUploadButton: false
                      });
                    } else {
                      this.setState({
                        batchName: event.target.value,
                        activeUploadButton: true
                      });
                    }
                  } else {
                    this.setState({ batchName: event.target.value });
                  }
                }}
              />
              <div style={{ marginTop: "15px" }}>Upload a File</div>
              <i style={{ fontSize: "11px" }}>Select a local file to upload as your input batch to the selected project</i>
              <input
                //hidden={true}
                id="files"
                ref
                type="file"
                name="Upload"
                onChange={this.handleSelectedFile}
                onClick={event => {
                  event.target.value = null;
                }}
              />
            </div>
          </ModalBody>

          <ModalFooter>
            {!this.state.uploadingStatus ? (
              <Button
                color="primary"
                className={"" + (activeUploadButton ? "btn-primary" : "disabled")}
                onClick={() => {
                  if (activeUploadButton) {
                    this.uploadFile("base");
                  }
                }}>
                Upload
              </Button>
            ) : (
              <div
                className="accubase-uploading"
                style={{
                  marginLeft: "auto",

                  display: "flex",
                  flexDirection: "row"
                }}>
                <DotLoader sizeUnit={"px"} size={15} color={"black"} loading={true} />
                <div style={{ marginLeft: "5px" }}>Uploading...</div>
              </div>
            )}
            <Button onClick={this.handleClose}>Cancel</Button>
          </ModalFooter>
        </Modal>

        <div className="kpi-container">
          <div className="kpi-icon">
            <ProjectIcon height={"26px"} color={"rgba(0, 128, 171, 1)"} />
          </div>
          <div className="kpi-details">
            <div className="kpi-title">Total Projects</div>
            <div className="kpi-value">{totalProjects}</div>
          </div>
        </div>
        <div className="tabs-vr"></div>
        <div className="kpi-container">
          <div className="kpi-icon">
            <InputSourceIcon height="26px" />
          </div>
          <div className="kpi-details">
            <div className="kpi-title">Input Sources</div>
            <div className="kpi-value">{totalDataSources}</div>
          </div>
        </div>
        <div className="tabs-vr"></div>
        <div className="kpi-container">
          <div className="kpi-icon">
            <BaselineIcon height={"26px"} />
          </div>
          <div className="kpi-details">
            <div className="kpi-title">Total Baselines</div>
            <div className="kpi-value">{totalBaselines}</div>
          </div>
        </div>
        <div className="tabs-vr"></div>
        <div className="kpi-container" style={{ maxWidth: "20%" }}>
          {" "}
          {/*Try to keep long lift model names from expanding this section too much*/}
          <div className="kpi-icon">
            <LatestModel color={"rgba(0, 128, 171, 1)"} height="26px" />
          </div>
          <div className="kpi-details">
            <div className="kpi-title">Latest Baseline</div>
            <div className="kpi-value" style={{ maxWidth: "95%" }}>
              {lastBaseline.name}
            </div>
          </div>
        </div>
      </div>
    );
  }
  saveAccubase() {
    const { changed } = this.props;
    let saveObj = cloneDeep(changed);

    this.setState({ saving: true });
    this.props.setChangedData([]);

    axios
      .post(
        `${process.env.REACT_APP_BIGHORN_SERVER}/api/accubase/saveAccubaseChanges`,
        { saveObj, baselineId: this.props.baselineId },
        {
          headers: {
            ClientToken: localStorage.getItem("clientToken")
          }
        }
      )
      .then(res => {
        if (res.data.success === false) {
          this.setState({
            saving: false,
            savingError: res.data.error,
            showSaveErrorModal: true
          });
        } else {
          this.setState({ saving: false });
        }
      })
      .catch(err => {
        this.setState({ saving: false, savingError: "Error while saving." });
      });
  }
  cellValueChanged(item) {
    console.log("cell value changed");

    var changed = [];
    if (item.node.data.BASE_UNITS === "") {
      item.node.data.BASE_UNITS = 0;
    }
    if (item.node.data.BASE_EDRP === "") {
      item.node.data.BASE_EDRP = 0;
    }
    changed.push(item.node.data);
    this.props.chartData[item.rowIndex] = item.node.data;
    // this.props.setAccubaseData(
    //   this.props.data,
    //   this.props.chartData,
    //   this.props.config,
    //   changed
    // );
    this.props.setReinitChart(true);
    this.props.setChangedData(changed);
  }
  filterChanged(e) {
    let newData = [];
    e.api.forEachNodeAfterFilterAndSort(function(rowNode, index) {
      newData.push(rowNode.data);
      // console.log(
      //   "node " + rowNode + " passes the filter and is in this order"
      // );
    });
    this.props.setChartData(newData);
    this.props.setReinitChart(true);
    //this.setState({ chartData: newData });
  }
  renderBaseline() {
    const { loadedProjects, projects, searchProjects, loadedData, changed } = this.props;
    const { allProjectsOpened, filterPanelOpened, accessPermissions } = this.state;
    if (!accessPermissions) {
      return (
        <div className="login-wrapper">
          <div className="login-msg">You do not have any permissions for this application. Contact your administrator.</div>
        </div>
      );
    }
    if (!loadedProjects) {
      return <LoadingIndicator paddingTop={"227px"} />;
    }
    if (this.props.viewSelected === 2) {
      if (loadedData) {
        return (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              //overflowY: "auto",
              height: "100%"
            }}>
            {/* // <div style={{ height: "100%" }}> */}
            <div className="acculift-main">
              <div className={"all-projects " + (allProjectsOpened ? "open" : "")}>
                <ProjectSelector
                  opened={allProjectsOpened}
                  statusChangedHandler={this.allProjectStateChange}
                  setSearchProjects={this.props.setSearchProjects}
                  searchProjects={searchProjects}
                  projects={projects}
                  type={"baseline"}
                  secondary={true}
                  setSelectedProject={this.props.setSelectedProject}
                  getProjects={this.props.getProjects}
                />
              </div>
              <div
                style={{
                  height: "100%",
                  width: "100%",
                  display: "flex",
                  flexDirection: "column"
                }}>
                <div className="manage-projects-is">Review Baselines</div>
                <div
                  className={
                    "manage-projects-body " + (allProjectsOpened ? "open " : "") + (filterPanelOpened ? "" : "filter-closed")
                  }>
                  <div
                    className={"review-lift-wrapper " + (allProjectsOpened ? "open " : "") + (filterPanelOpened ? "fopen" : "")}
                    style={{ overflowY: "visible" }}>
                    <div
                      className="lift-model-name"
                      style={{
                        marginTop: "8px",
                        marginBottom: "8px",
                        display: "flex",
                        flexDirection: "row"
                      }}>
                      {this.props.selectedBaseline.name}
                      <div
                        style={{
                          marginLeft: "auto",
                          display: "flex",
                          flexDirection: "row",
                          alignItems: "center"
                        }}>
                        {changed.length > 0 && (
                          <div style={{ fontSize: "18px", fontStyle: "italic" }}>You have unsaved changes</div>
                        )}
                        {!this.state.saving ? (
                          <div
                            style={{ fontSize: "18px", marginLeft: "20px" }}
                            className={"ta-baseline-button " + (changed.length === 0 ? "disabled" : "")}
                            onClick={() => {
                              if (changed.length > 0) {
                                this.saveAccubase();
                              }
                            }}>
                            Save Changes
                          </div>
                        ) : (
                          <div className="baseline-save">
                            <DotLoader sizeUnit={"px"} size={15} color={"black"} loading={true} />
                            <div style={{ marginLeft: "5px" }}>Saving</div>
                          </div>
                        )}
                      </div>
                    </div>

                    <div className="tabs-hr" style={{ marginBottom: "8px" }}></div>

                    <div className="review-bl-wrapper" style={{ overflowY: "visible" }}>
                      <DualAxisLineChart id={"dualAxis"} />
                      <AccubaseGrid
                        columnDefs={this.props.config}
                        rowData={this.props.data}
                        resizable={true}
                        onFilterChanged={this.filterChanged}
                        getRowNodeId={data => {
                          return data.ID;
                        }}
                        suppressContextMenu={false}
                        deltaRowDataMode={true}
                        components={{
                          EditableCellRenderer: EditableCellRenderer,
                          EditableCurrencyCellRenderer: EditableCurrencyCellRenderer,
                          EditableNumberCellRenderer: EditableNumberCellRenderer,
                          EditableCheckboxCellRenderer: EditableCheckboxCellRenderer,
                          EditableBooleanCellRenderer: EditableBooleanCellRenderer
                        }}
                        onCellValueChanged={item => {
                          if (
                            item.colDef.cellRenderer === "EditableNumberCellRenderer" ||
                            item.colDef.cellRenderer === "EditableCurrencyCellRenderer"
                          ) {
                            if (parseFloat(item.oldValue, 10) !== parseFloat(item.newValue, 10)) {
                              this.cellValueChanged(item);
                            }
                          } else {
                            this.cellValueChanged(item);
                          }
                        }}
                        className="review-bl-grid"
                      />
                    </div>
                  </div>
                </div>
              </div>
              {/* <div
                className={"filter-panel " + (filterPanelOpened ? "open" : "")}
              >
                <FilterPanel
                  opened={filterPanelOpened}
                  statusChangedHandler={this.filterPanelStateChange}
                  filters={this.props.lovs}
                />
              </div> */}
            </div>
          </div>
        );
      } else if (!this.props.loadingData) {
        return (
          <div className="acculift-main">
            <div className={"all-projects " + (allProjectsOpened ? "open" : "")}>
              <ProjectSelector
                opened={allProjectsOpened}
                statusChangedHandler={this.allProjectStateChange}
                setSearchProjects={this.props.setSearchProjects}
                searchProjects={searchProjects}
                projects={projects}
                type={"baseline"}
                secondary={true}
                setSelectedProject={this.props.setSelectedProject}
                getProjects={this.props.getProjects}
              />
            </div>
            {/* <div style={{ height: "100%", width: "100%" }}> */}
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                width: "100%"
              }}>
              <div
                style={{
                  width: "100%"
                  //paddingLeft: "20px",
                  // borderBottom: "1px solid rgb(229, 230, 232)"
                }}
                className="manage-projects-is">
                Review Baselines
              </div>
              <div className={"manage-projects-body " + (allProjectsOpened ? "open" : "")}>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "center",
                    paddingTop: this.state.paddingTop
                  }}>
                  <div
                    style={{
                      marginLeft: "10px",
                      fontSize: "50px",
                      color: "rgba(0, 126, 167, 1)"
                    }}>
                    No Baseline Found
                  </div>
                </div>
              </div>
            </div>
          </div>
        );
      } else {
        return (
          <div className="acculift-main">
            <div className={"all-projects " + (allProjectsOpened ? "open" : "")}>
              <ProjectSelector
                opened={allProjectsOpened}
                statusChangedHandler={this.allProjectStateChange}
                setSearchProjects={this.props.setSearchProjects}
                searchProjects={searchProjects}
                projects={projects}
                type={"baseline"}
                secondary={true}
                setSelectedProject={this.props.setSelectedProject}
                getProjects={this.props.getProjects}
              />
            </div>
            {/* <div style={{ height: "100%", width: "100%" }}> */}
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                width: "100%"
              }}>
              <div
                style={{
                  width: "100%"
                  //paddingLeft: "20px",
                  // borderBottom: "1px solid rgb(229, 230, 232)"
                }}
                className="manage-projects-is">
                Review Baselines
              </div>
              <div className={"manage-projects-body " + (allProjectsOpened ? "open" : "")}>
                <LoadingIndicator paddingTop={"227px"} />
              </div>
            </div>
          </div>
        );
      }
    } else {
      return (
        <div className="acculift-main">
          <div className={"all-projects " + (allProjectsOpened ? "open" : "")}>
            <ProjectSelector
              opened={allProjectsOpened}
              statusChangedHandler={this.allProjectStateChange}
              setSearchProjects={this.props.setSearchProjects}
              searchProjects={searchProjects}
              projects={projects}
              type={"baseline"}
              secondary={false}
              setSelectedProject={this.props.setSelectedProject}
              getProjects={this.props.getProjects}
            />
          </div>
          <div
            style={{
              flexDirection: "column",
              display: "flex",
              // overflowY: "auto",
              width: "100%",
              height: "100%"
            }}>
            <div className="manage-projects-is">Manage Projects and Input Sources</div>
            <div className={"manage-projects-body " + (allProjectsOpened ? "open" : "")}>
              <div
                style={{
                  height: "75px",
                  border: "1px solid rgb(217,219,221)"
                }}>
                {this.renderHeader()}
              </div>
              <div
                style={{
                  border: "1px solid rgb(217,219,221)",
                  background: "rgb(249,249,249)",
                  width: "100%",
                  height: "100%",
                  display: "flex",
                  flexDirection: "column"
                }}>
                <div style={{ display: "flex", flexDirection: "row" }}>
                  {/* <div onClick={()=>{
                    this.setState({showCreateNewProjModal:true});
                  }} className="create-project" style={{color: "rgb(54, 127, 167)",marginLeft:"auto",cursor:"pointer"}}>+ Create Project</div> */}
                </div>
                {/* <hr /> */}
                {this.renderProject()}
              </div>
            </div>
          </div>
        </div>
      );
    }
  }

  removeErrorDiv() {
    this.setState({
      dupe: "",
      showNeedFolderAndInputDialog: false,
      invalidFileMessage: []
    });
  }
  handleCalendarChange = date => {
    this.setState({
      startDate: date
    });
  };
  checkBoxChanged() {
    const { makeDefault } = this.state;
    this.setState({ makeDefault: !makeDefault });
  }
  render() {
    const { showSaveErrorModal, savingError } = this.state;

    return (
      <div className="acculift">
        <Modal style={{ width: "550px", maxWidth: "550px" }} isOpen={showSaveErrorModal}>
          <ModalHeader>Error While Saving</ModalHeader>
          <ModalBody>
            <div>{savingError}</div>
          </ModalBody>
          <ModalFooter className="project-dialog">
            <Button
              onClick={() => {
                this.handleClose();
              }}
              style={{ border: "none" }}>
              Ok
            </Button>
          </ModalFooter>
        </Modal>
        <div className="ta-second-level-nav-container" style={{ paddingLeft: "10px" }}>
          <ul className="ta-second-level-nav flex-container">
            <li className="secondary-app-title">
              <div>Accubase</div>
            </li>
            <li className="secondary-nav-chevron">
              <RightChevron height={"20px"} color={"#666"} />
            </li>
            <li>
              <div
                onClick={() => this.clickHandler(1)}
                className={"ta-acculift-nav-link " + (this.props.viewSelected === 1 ? "ta-active" : "")}>
                Manage Projects and Input Sources
              </div>
            </li>
            <li style={{ marginLeft: "30px" }}>
              <div
                onClick={() => {
                  this.clickHandler(2);
                }}
                className={
                  "ta-acculift-nav-link " + (this.props.viewSelected === 2 ? "ta-active" : "") //+
                  // (this.props.baselineId !== -1 ? "" : " nav-disabled")
                }>
                Review Baselines
              </div>
            </li>
          </ul>
          <div
            style={{
              borderBottom: "1px solid lightgray",
              marginRight: "10px",
              marginLeft: "-10px"
            }}></div>
        </div>
        {this.renderBaseline()}
      </div>
    );
  }
}

const mapStateToProps = state => ({
  loadingProjects: state.accubase.loadingProjects,
  loadedProjects: state.accubase.loadedProjects,
  loadingError: state.accubase.loadingError,
  loadingData: state.accubase.loadingData,
  changed: state.accubase.changed,
  config: state.accubase.config,
  loadedData: state.accubase.loadedData,
  projects: state.accubase.projects,
  searchProjects: state.accubase.searchProjects,
  selectedProject: state.accubase.selectedProject,
  error: state.accubase.error,
  data: state.accubase.data,
  filteredData: state.accubase.filteredData,
  defaultCalendarExists: state.accubase.defaultCalendarExists,
  viewSelected: state.accubase.viewSelected,
  baselineId: state.accubase.baselineId,
  selectedBaseline: state.accubase.selectedBaseline,
  chartData: state.accubase.chartData,
  lovs: state.accubase.lovs,
  oldProjectName: state.accubase.oldProjectName,
  apps: state.app.apps
});
const mapDispatchToProps = dispatch => ({
  getProjects(selectProject = true) {
    dispatch(getProjects(selectProject));
  },
  addTabToNav(tab) {
    dispatch(addTabToNav(tab));
  },
  setReinitChart(state) {
    dispatch(setReinitChart(state));
  },
  setFilteredData(data) {
    dispatch(setFilteredData(data));
  },
  setSelectedProject(project) {
    dispatch(setSelectedProject(project));
  },
  setViewSelected(view) {
    dispatch(setViewSelected(view));
  },
  setOldProjectName(name) {
    dispatch(setOldProjectName(name));
  },
  setReInitProjects(state) {
    dispatch(setReInitProjects(state));
  },
  setChangedData(data) {
    dispatch(setChangedData(data));
  },
  setChartData(data) {
    dispatch(setChartData(data));
  },
  selectBaseline(baseline, viewSelected) {
    dispatch(selectBaseline(baseline, viewSelected));
  },
  setAccubaseData(data, config, changed) {
    dispatch(setAccubaseData(data, config, changed));
  },
  setSearchProjects(filter, projects, includeSecondary) {
    dispatch(setSearchProjects(filter, projects, includeSecondary));
  }
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Accubase));
