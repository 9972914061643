import { AuthenticationContext, adalFetch, withAdalLogin } from "react-adal";
import { AUTH_CONFIG } from "./auth0Variables";
//console.log("AUTH_CONFIG" + JSON.stringify(AUTH_CONFIG));
export const adalConfig = {
  tenant: AUTH_CONFIG.TENANT,
  popup: false,
  clientId: AUTH_CONFIG.CLIENT_ID,
  endpoints: {
    //api: "https://pstgc.onmicrosoft.com/db2a3508-ff2d-4d3c-87c7-11fa7a2b3e55",
    //graphApiUri: "https://graph.microsoft.com"
    endpoints: {
      "https://graph.microsoft.com/v1.0/me": "https://graph.microsoft.com"
    },
    navigateToLoginRequestUrl: false
  },
  extraQueryParameter: "",
  postLogoutRedirectUri: window.location.origin,
  redirectUri: AUTH_CONFIG.callbackUrl,
  cacheLocation: "sessionStorage"
};
//console.log(process.env.REACT_APP_AUTH0_CALLBACK);

export const authContext = new AuthenticationContext(adalConfig);
export const adalApiFetch = (fetch, url, options) =>
  adalFetch(authContext, adalConfig.endpoints.api, fetch, url, options);

export const withAdalLoginApi = withAdalLogin(
  authContext,
  adalConfig.endpoints.api
);
export const getToken = () => {
  return authContext.getCachedToken(authContext.config.clientId);
};
