import history from "./history";
import { logout } from "reducers/authReducer";
import { parseToken } from "./helpers";
import { runWithAdal } from "react-adal";
import { authContext } from "lib/config";
import axios from "axios";

import moment from "moment-timezone";

class Auth {
  constructor(store) {
    this.store = store;
    this.login = this.login.bind(this);
    this.logout = this.logout.bind(this);
    this.handleAuthentication = this.handleAuthentication.bind(this);
    this.isAuthenticated = this.isAuthenticated.bind(this);
    window.AuthenticationContext = authContext;
    window.authContext = authContext;
  }

  login(username, type) {
    if (type === "saml") {
      // console.log(username);
      var element = window.document.createElement("a");
      element.setAttribute("href", process.env.REACT_APP_BIGHORN_SERVER + "/api/samlLogin?" + username.split("@")[1]);

      element.style.display = "none";
      document.body.appendChild(element);

      element.click();
      document.body.removeChild(element);
      // axios
      //   .get(`${process.env.REACT_APP_BIGHORN_SERVER}/api/samlLogin`, {
      //     headers: {
      //       Accept: "application/json",
      //       "Content-Type": "application/json",
      //       Cache: "no-cache"
      //     },
      //     credentials: "same-origin"
      //     //withCredentials: true
      //   })
      //   .then(result => {
      //     console.log(result);
      //   })
      //   .catch(err => {
      //     console.log(err);
      //   });
    } else {
      //This can safely be called whenever, as it doesn't crash if it isn't a callback.
      //I am not sure why I need this here, but without it, nothing works.
      window.authContext.handleWindowCallback();
      let access = localStorage.getItem("access_token");

      if (!window.authContext.isCallback(window.location.hash)) {
        if (access === null) {
        }
        //Having both of these checks is to prevent having a token in localstorage, but no user. Relates to issue #471
        // else if (
        //   !window.authContext.getCachedToken(authContext.config.clientId) ||
        //   !window.authContext.getCachedUser()
        // ) {
        //   window.authContext.login();
        // }
        else {
          // window.authContext._renewIdToken((err, idToken) => {
          //   if (err) {
          //     console.log("Renewal failed: " + err);
          //     // Failed to get the token silently; show the login button
          //     //showLoginButton();
          //     window.authContext.login();
          //     // You could attempt to launch the login popup here, but in browsers this could be blocked by
          //     // a popup blocker, in which case the login attempt will fail with the reason FailedToOpenWindow.
          //   } else {
          //     console.log("ID TOKEN RENEWAL " + JSON.stringify(idToken));
          //   }
          // });
          window.authContext.acquireToken(authContext.config.clientId, (error, token) => {
            if (token !== null) {
              axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
              localStorage.setItem("access_token", token);
            } else {
              axios.defaults.headers.common["Authorization"] = `Bearer ${localStorage.getItem("access_token")}`;
            }
          });
        }
      }
      //authContext.login();
      authContext.config.extraQueryParameter = `login_hint=${username}`;
      runWithAdal(authContext, () => {
        // console.log(authContext);
        //TODO : continue your process
      });
    }
  }
  handleSSOAuth() {
    localStorage.setItem("sso", true);
    let parsedToken = parseToken(window.location.hash);
    localStorage.setItem("user", parsedToken.id);
    localStorage.setItem("client", parsedToken.client);
    this.setSession(window.location.hash.split("=")[1]);
    moment().format("x");
    localStorage.setItem(
      "expires_at",
      moment()
        .add(24, "hours")
        .format("x")
    );
    //history.replace("/reports");
  }
  handleAuthentication() {
    // if (authContext.isCallback(window.location.hash)) {
    //   authContext.handleWindowCallback(window.location.hash);
    // }

    localStorage.setItem("sso", false);
    var requestInfo = authContext.getRequestInfo(window.location.hash);
    if (requestInfo.valid) {
      authContext.saveTokenFromHash(requestInfo);
      if (requestInfo.parameters.id_token) {
        this.setSession(requestInfo.parameters.id_token);

        //history.replace("/reports");
      }
    }

    // localStorage.setItem("sso", false);
    // var requestInfo = authContext.getRequestInfo(window.location.hash);
    // if (requestInfo.valid) {
    //   authContext.saveTokenFromHash(requestInfo);
    //   if (requestInfo.parameters.id_token) {
    //     this.setSession(requestInfo.parameters.id_token);
    //     history.replace("/reports");
    //   }

    //   // sessionStorage.setItem(
    //   //   "adal.access.token.keydb2a3508-ff2d-4d3c-87c7-11fa7a2b3e55",
    //   //   requestInfo.parameters.id_token
    //   // );
    // }
  }
  handleSetClient() {
    localStorage.setItem("sso", false);

    try {
      localStorage.setItem("setClientToken", window.location.hash.replace("#", ""));
      let parsedToken = parseToken(window.location.hash);
      localStorage.setItem("access_token", parsedToken.accessToken);
      localStorage.setItem("expires_at", (parsedToken.iat + 7200) * 1000);
      axios.defaults.headers.common["Authorization"] = `Bearer ${parsedToken.accessToken}`;
      let re = new RegExp(/^live.com#/);
      let oRe = new RegExp(/^mail#/);
      var testUser = parsedToken.user;
      if (re.test(testUser)) {
        testUser = testUser.split("live.com#")[1];
      }
      if (oRe.test(testUser)) {
        testUser = testUser.split("mail#")[1];
      }
      localStorage.setItem("user", testUser);
      // console.log("EXPIREY");
      // console.log((parsedToken.iat + 7200) * 1000);
    } catch (err) {
      // localStorage.setItem("setClientToken", window.location.hash.replace("#", ""));
      // var epochTimeInSeconds = Math.floor(Date.now() / 1000);
      // console.log(epochTimeInSeconds);
      // localStorage.setItem("expires_at", (epochTimeInSeconds + 7200) * 1000);
      // localStorage.setItem("access_token", "");
    }

    //history.replace("/reports");
  }

  setSession(hash) {
    // // Set the time that the access token will expire at
    // this.auth0.client.userInfo(authResult.accessToken, (err, user) => {
    //   //console.log(user);
    // });
    let parsedToken = parseToken(hash);
    let expiresAt = parsedToken.exp * 1000;

    // console.log("THE THING EXPIRES HERE");
    // console.log(expiresAt);
    // var client;
    // //console.log(decodedToken);
    // if (
    //   authResult.idTokenPayload[
    //     "http://portal.tabsanalytics.com/app_metadata"
    //   ] !== undefined
    // ) {
    //   //username/password login
    //   client =
    //     authResult.idTokenPayload[
    //       "http://portal.tabsanalytics.com/app_metadata"
    //     ].client;
    // }
    // if (client === undefined) {
    //   //active directory login
    //   client =
    //     authResult.idTokenPayload["http://portal.tabsanalytics.com/client"];
    // }
    // if (client === undefined) {
    //   //saml login (right now just Diageo)
    //   client = authResult.idTokenPayload.sub.split("|")[1];
    // }
    // localStorage.setItem("client", client);
    localStorage.setItem("access_token", hash);
    if (parsedToken.unique_name !== undefined) {
      let re = new RegExp(/^live.com#/);
      let oRe = new RegExp(/^mail#/);
      var testUser = parsedToken.unique_name;
      if (re.test(testUser)) {
        testUser = testUser.split("live.com#")[1];
      }
      if (oRe.test(testUser)) {
        testUser = testUser.split("mail#")[1];
      }
      localStorage.setItem("user", testUser);
    }

    //localStorage.setItem("id_token", authResult.idToken);
    localStorage.setItem("expires_at", expiresAt);
    // if (
    //   authResult.idTokenPayload[
    //     "http://portal.tabsanalytics.com/user_metadata"
    //   ] !== undefined
    // ) {
    //   //Username/Password login
    //   localStorage.setItem(
    //     "groupId",
    //     JSON.stringify(
    //       authResult.idTokenPayload[
    //         "http://portal.tabsanalytics.com/user_metadata"
    //       ].groups
    //     )
    //   );
    // } else if (
    //   //saml login/ ad login
    //   authResult.idTokenPayload["http://portal.tabsanalytics.com/group"] !==
    //   undefined
    // ) {
    //   localStorage.setItem(
    //     "groupId",
    //     JSON.stringify(
    //       authResult.idTokenPayload["http://portal.tabsanalytics.com/group"]
    //     )
    //   );
    // }
    //this.store.dispatch(setAuthLoaded(true));
  }

  logout() {
    // Clear access token and ID token from local storage
    localStorage.removeItem("access_token");
    localStorage.removeItem("id_token");
    localStorage.removeItem("expires_at");
    localStorage.removeItem("groupId");
    localStorage.removeItem("client");
    localStorage.removeItem("clientToken");
    localStorage.removeItem("setClientToken");
    localStorage.removeItem("sso");
    localStorage.removeItem("user");
    this.store.dispatch(logout(true));
    var i = sessionStorage.length;
    while (i--) {
      var key = sessionStorage.key(i);
      if (/adal/.test(key)) {
        sessionStorage.removeItem(key);
      }
    }
    sessionStorage.removeItem("adal.access.token.keyhttps://graph.microsoft.com");
    //authContext.clearCache();
    // navigate to the home route
    history.replace("/");
  }

  isAuthenticated() {
    // Check whether the current time is past the
    // access token's expiry time

    let sso = localStorage.getItem("sso");
    let expiresAt = localStorage.getItem("expires_at");
    if (history.location.pathname.includes("/uploadFile") || history.location.pathname.includes("/tabsFileShare")) {
      return true;
    } else if (sso === "false") {
      expiresAt = moment(expiresAt, "x");
      const newDate = moment(expiresAt, "x").subtract(25, "minutes");
      const nowTime = moment();
      if (nowTime > newDate && nowTime < expiresAt) {
        window.authContext.acquireToken(authContext.config.clientId, (error, token) => {
          if (token !== null) {
            // console.log("ID TOKEN RENEWAL " + JSON.stringify(token));
            axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
            localStorage.setItem("access_token", token);
          } else {
            axios.defaults.headers.common["Authorization"] = `Bearer ${localStorage.getItem("access_token")}`;
          }
        });
        // window.authContext.acquireTokenSilent({ scopes: ["openid", "profile", "email"] }).then(response => {
        //   //   if (err) {
        //   //     console.log("Renewal failed: " + err);
        //   //     // Failed to get the token silently; show the login button
        //   //     //showLoginButton();
        //   //     window.authContext.login();
        //   //     // You could attempt to launch the login popup here, but in browsers this could be blocked by
        //   //     // a popup blocker, in which case the login attempt will fail with the reason FailedToOpenWindow.
        //   //   } else {
        //   console.log("ID TOKEN RENEWAL " + JSON.stringify(response.idToken));
        //   axios.defaults.headers.common["Authorization"] = `Bearer ${response.idToken}`;
        //   //   }
        // });

        return true;
      } else {
        return nowTime < expiresAt;
      }
    } else {
      return new Date().getTime() < expiresAt;
    }
  }
}

export default Auth;
