import React, { Component } from "react";
import { DotLoader } from "react-spinners";

export class LoadingIndicator extends Component {
  constructor(props) {
    super(props);

    this.state = {
      paddingTop: props.paddingTop !== undefined ? props.paddingTop : "50px",
      textSize: props.textSize !== undefined ? props.textSize : "50px",
      spinnerSize: props.spinnerSize !== undefined ? props.spinnerSize : 50,
      justifyContent: props.justifyContent !== undefined ? props.justifyContent : "center"
    };
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    const newState = {};
    if (nextProps.paddingTop !== prevState.paddingTop) {
      newState.paddingTop = nextProps.paddingTop !== undefined ? nextProps.paddingTop : "50px";
    }
    if (nextProps.textSize !== prevState.textSize) {
      newState.textSize = nextProps.textSize !== undefined ? nextProps.textSize : "50px";
    }
    if (nextProps.spinnerSize !== prevState.spinnerSize) {
      newState.spinnerSize = nextProps.spinnerSize !== undefined ? nextProps.spinnerSize : 50;
    }
    if (nextProps.justifyContent !== prevState.justifyContent) {
      newState.justifyContent = nextProps.justifyContent !== undefined ? nextProps.justifyContent : "center";
    }
    return Object.keys(newState).length ? newState : null;
  }

  render() {
    const { paddingTop, textSize, spinnerSize, justifyContent } = this.state;

    return (
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: justifyContent,
          paddingTop: paddingTop,
          alignItems: "center"
        }}>
        <DotLoader sizeUnit={"px"} size={spinnerSize} color={"rgba(0, 126, 167, 1)"} loading={true} />
        <div
          style={{
            marginLeft: "10px",
            fontSize: textSize,
            fontStyle: "italic",
            color: "rgba(0, 126, 167, 1)"
          }}>
          Loading
        </div>
      </div>
    );
  }
}

LoadingIndicator.defaultProps = {
  paddingTop: "50px",
  textSize: "50px",
  spinnerSize: 50,
  justifyContent: "center"
};
