import React, { Component } from "react";

import NumericEditor from "components/accucast/components/editors/numericEditor";
import TotalRenderer from "components/accucast/components/renderers/totalRenderer";
import { AccucastGrid } from "components/accucast/components/lib/accucastGrid";
import {
  percentFormatter,
  getRowClass
} from "components/accucast/components/formatters";
import { totalValueGetter } from "components/accucast/components/getters";
import { numberNewValueHandler } from "components/accucast/components/handlers";
import { CustomHeaderRenderer } from "components/accucast/components/renderers/customHeaderRenderer";
import { EditableCellPercentRenderer } from "components/accucast/components/renderers/editableCellPercentRenderer";
import axios from "axios";

export class LeWeightingOverrides extends Component {
  constructor(props) {
    super(props);

    this.state = {
      columnDefs: [],
      rowData: this.createRows(),
      frameworkComponents: {
        numericEditor: NumericEditor,
        totalRenderer: TotalRenderer,
        customHeaderRenderer: CustomHeaderRenderer,
        editableCellPercentRenderer: EditableCellPercentRenderer
      }
    };
  }

  componentDidMount() {
    var baseConfig = [
      {
        id: "0",
        headerName: "GEO",
        pinned: "left",
        width: 105,
        field: "geo",
        tooltipField: "geo",
        suppressMovable: true,
        menuTabs: ["filterMenuTab"]
      },
      {
        id: "1",
        headerName: "Item Description",
        pinned: "left",
        width: 375,
        field: "idesc",
        tooltipField: "idesc",
        suppressMovable: true,
        menuTabs: ["filterMenuTab"]
      },
      {
        id: "2",
        headerName: "Prod Key",
        pinned: "left",
        width: 136,
        field: "pk",
        tooltipField: "pk",
        suppressMovable: true,
        menuTabs: ["filterMenuTab"]
      },
      {
        id: "3",
        headerName: "Brand Size",
        pinned: "left",
        width: 138,
        field: "bs",
        tooltipField: "bs",
        suppressMovable: true,
        menuTabs: ["filterMenuTab"]
      },
      {
        id: "4",
        headerName: "Week 23 %D06/10/18",
        width: 85,
        cellClass: "cellCenterClass",
        headerComponent: "customHeaderRenderer",
        headerClass: "headerSize",
        field: "wk23",
        suppressMovable: true,
        menuTabs: ["filterMenuTab"],
        cellEditor: "numericEditor",
        editable: true,
        valueFormatter: percentFormatter,
        newValueHandler: numberNewValueHandler,
        cellRenderer: "editableCellPercentRenderer"
      },
      {
        id: "5",
        headerName: "Week 24 %D06/17/18",
        width: 85,
        cellClass: "cellCenterClass",
        field: "wk24",
        headerComponent: "customHeaderRenderer",
        headerClass: "headerSize",
        suppressMovable: true,
        menuTabs: ["filterMenuTab"],
        cellEditor: "numericEditor",
        editable: true,
        valueFormatter: percentFormatter,
        newValueHandler: numberNewValueHandler,
        cellRenderer: "editableCellPercentRenderer"
      },
      {
        id: "6",
        headerName: "Week 25 %D06/24/18",
        width: 85,
        cellClass: "cellCenterClass",
        headerComponent: "customHeaderRenderer",
        headerClass: "headerSize",
        field: "wk25",
        suppressMovable: true,
        menuTabs: ["filterMenuTab"],
        cellEditor: "numericEditor",
        editable: true,
        valueFormatter: percentFormatter,
        newValueHandler: numberNewValueHandler,
        cellRenderer: "editableCellPercentRenderer"
      },
      {
        id: "7",
        headerName: "Week 26 %D07/01/18",
        width: 85,
        cellClass: "cellCenterClass",
        field: "wk26",
        headerComponent: "customHeaderRenderer",
        headerClass: "headerSize",
        suppressMovable: true,
        menuTabs: ["filterMenuTab"],
        cellEditor: "numericEditor",
        editable: true,
        valueFormatter: percentFormatter,
        newValueHandler: numberNewValueHandler,
        cellRenderer: "editableCellPercentRenderer"
      },
      {
        id: "8",
        headerName: "Week 27 %D07/08/18",
        width: 85,
        cellClass: "cellCenterClass",
        field: "wk27",
        headerComponent: "customHeaderRenderer",
        headerClass: "headerSize",
        suppressMovable: true,
        menuTabs: ["filterMenuTab"],
        cellEditor: "numericEditor",
        editable: true,
        valueFormatter: percentFormatter,
        newValueHandler: numberNewValueHandler,
        cellRenderer: "editableCellPercentRenderer"
      },
      {
        id: "9",
        headerName: "Week 28 %D07/15/18",
        width: 85,
        cellClass: "cellCenterClass",
        field: "wk28",
        headerComponent: "customHeaderRenderer",
        headerClass: "headerSize",
        suppressMovable: true,
        menuTabs: ["filterMenuTab"],
        cellEditor: "numericEditor",
        editable: true,
        valueFormatter: percentFormatter,
        newValueHandler: numberNewValueHandler,
        cellRenderer: "editableCellPercentRenderer"
      },
      {
        id: "10",
        headerName: "Total",
        field: "total",
        cellClass: "cellCenterClass",
        headerClass: "totalClass",
        suppressMovable: true,
        menuTabs: ["filterMenuTab"],
        valueGetter: totalValueGetter,
        cellRenderer: "totalRenderer"
      }
    ];
    axios
      .get("http://localhost:4000/api/accucast/config/leWeightingOverrides", {
        headers: {
          ClientToken: localStorage.getItem("clientToken")
        }
      })
      .then(res => {
        if (res.data.config.length !== 0) {
          let config = baseConfig.map(conf => {
            let id = parseInt(conf.id, 10);
            switch (id) {
              case 0:
              case 1:
              case 2:
              case 3:
                conf.cellClassRules = res.data.config[id].cellClassRules;
                return conf;
              case 4:
              case 5:
              case 6:
              case 7:
              case 8:
              case 9:
                conf.headerName = res.data.config[id].headerName;
                conf.field = res.data.config[id].field;
                conf.cellClassRules = res.data.config[id].cellClassRules;
                return conf;
              case 10:
                conf.headerName = res.data.config[id].headerName;
                conf.field = res.data.config[id].field;
                return conf;
              default:
                return conf;
            }
          });
          this.setState({ columnDefs: config });
        }
      });
  }

  createRows = () => {
    let rows = [];
    rows.push({
      geo: "AHOLD",
      idesc: "89898-NS DIET TURBO CHOS PWD PKT 5CT 1.4OZB",
      pk: "123456789012",
      bs: "SALTY SNACK",
      wk23: "0",
      wk24: "0",
      wk25: "25",
      wk26: "25",
      wk27: "25",
      wk28: "25"
    });
    rows.push({
      geo: "AMAZON",
      idesc: "89898-NS DIET TURBO CHOS PWD PKT 5CT 1.4OZB",
      pk: "123456789012",
      bs: "SALTY SNACK",
      wk23: "0",
      wk24: "0",
      wk25: "25",
      wk26: "25",
      wk27: "25",
      wk28: "25"
    });
    rows.push({
      geo: "AMAZON",
      idesc: "84220-SOUTH BEACH DOUBLE CHOC BAR 30CT",
      pk: "3267484220",
      bs: "BAR",
      wk23: "0",
      wk24: "0",
      wk25: "25",
      wk26: "25",
      wk27: "25",
      wk28: "25"
    });
    rows.push({
      geo: "AMAZON",
      idesc: "84221-SOUTH BEACH PEANUT BUTTER BAR 30CT",
      pk: "3267484221",
      bs: "BAR",
      wk23: "0",
      wk24: "0",
      wk25: "25",
      wk26: "25",
      wk27: "25",
      wk28: "25"
    });
    rows.push({
      geo: "C&S",
      idesc: "84192-NS DIET KIT EVERYDAY 1CT",
      pk: "3267484192",
      bs: "KIT",
      wk23: "0",
      wk24: "0",
      wk25: "25",
      wk26: "25",
      wk27: "25",
      wk28: "25"
    });
    rows.push({
      geo: "DIV1",
      idesc: "83504-NS DIAB VANIL LIQ 4CT 11.0OZB",
      pk: "3267483504",
      bs: "SHAKE",
      wk23: "0",
      wk24: "0",
      wk25: "25",
      wk26: "25",
      wk27: "25",
      wk28: "25"
    });

    return rows;
  };

  render() {
    return (
      <div style={{ height: "100%" }}>
        <AccucastGrid
          columnDefs={this.state.columnDefs}
          rowData={this.state.rowData}
          getRowClass={getRowClass}
          frameworkComponents={this.state.frameworkComponents}
        />
      </div>
    );
  }
}
