import React, { Component } from "react";

import ProjectSelector from "components/accuComponents/projectSelector";
//import FilterPanel from "components/accuComponents/filterPanel";

import { addTabToNav } from "components/topLevelNav/reducers/topLevelNavReducer";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { LoadingIndicator } from "lib/loadingIndicator";

import { AccubaseGrid } from "components/accubase/components/accubaseGrid";
import {
  //EditableCellRenderer,
  EditableCurrencyCellRenderer,
  EditableNumberCellRenderer,
  EditableBooleanCellRenderer,
  EditableCheckboxCellRenderer
} from "components/agGrid/renderers";
import EventFeeCellRenderer from "components/agGrid/renderers/eventFeeCellRenderer";
import { EditableCellPercentRenderer } from "components/accucast/components/renderers/editableCellPercentRenderer";
import { EditableCellRenderer } from "components/accucast/components/renderers/editableCellRenderer";
import WeeklyPromoCellRenderer from "components/agGrid/renderers/weeklyPromoCellRenderer";
import PromoSubsidyCellRenderer from "components/agGrid/renderers/promoSubsidyCellRenderer";
import PromoOptConfigCellRenderer from "./promoOptConfigCellRenderer";
import "./style.css";
import moment from "moment";
import ActionCellRenderer from "components/acculift/components/actionCellRenderer";
import { setAlias } from "../../acculift/reducers/acculiftReducer";
import PromoOptList from "./promoOptList";

import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import axios from "axios";
import ReactDOM from "react-dom";
import { fillSelection, fillDown } from "lib/globalAgGrid";
import { extractFileName } from "lib/lib";
import { save } from "save-file";
import { socket } from "routes";
import {
  numberFormatter,
  usCurrencyFormatter,
  // formatFloatFormatter,
  percentFormatter,
  usCurrencyWholeDollar,
  fixedDecimalFormatter
} from "../../agGrid/formatters/index.js";

import {
  Gear,
  ProjectIcon,
  PromoPlanIcon,
  LatestModel,
  DeleteIcon,
  Pen,
  CollectionIcon,
  RightChevron
} from "lib/icons";

import {
  getProjects,
  setSelectedProject,
  setViewSelected,
  setOldProjectName,
  appendChanged,
  setChanged,
  createPromo,
  selectPromo,
  setSearchProjects
} from "components/promoOpt/reducers/promoOptReducer";
import { DotLoader } from "react-spinners";
import { toast } from "react-toastify";

//Use this to give moment the correct format so it doesn't throw warnings
//Our timestamps look like this: 2020-04-06 14:31:03.000 -0700
const timestampFormat = "YYYY-MM-DD HH:mm:ss.SSS ZZ";

class PromoOpt extends Component {
  constructor(props) {
    super(props);

    this.state = {
      allProjectsOpened: true,
      filterPanelOpened: true,
      viewSelected: 1,
      manageOpened: false,
      showDeleteProjectConfirmModal: false,
      showDeleteBaselineConfirmModal: false,
      showDeleteLiftConfirmModal: false,
      editBaseline: false,
      selectedProject: null,
      changed: [],
      savingPromo: false,
      showConfigCancelModal: false,
      accessPermissions: true
    };
    this.props.addTabToNav({
      title: "Promo Optimization",
      id: "promoOpt",
      type: "promoOpt",
      path: "/promoOpt",
      icon: "promoOpt",
      activeChildren: []
    });
    this.clickHandler = this.clickHandler.bind(this);
    this.manageClickHandler = this.manageClickHandler.bind(this);
    this.allProjectStateChange = this.allProjectStateChange.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.filterPanelStateChange = this.filterPanelStateChange.bind(this);
    this.keyDownHandler = this.keyDownHandler.bind(this);
    this.BaselineNameInput = React.createRef();
    this.Manage = React.createRef();
    this.TPROnly = React.createRef();
    this.Minor = React.createRef();
    this.Major = React.createRef();
    this.Other = React.createRef();
    this.handleClickOutside = this.handleClickOutside.bind(this);
    this.promoCompleteHandler = this.promoCompleteHandler.bind(this);
    this.savePromoConfig = this.savePromoConfig.bind(this);
    this.fillSelection = fillSelection;
    this.fillDown = fillDown;
    this.cancelHandler = this.cancelHandler.bind(this);
    this.downloadHandler = this.downloadHandler.bind(this);
    this.renderPromoOptList = this.renderPromoOptList.bind(this);
  }

  componentWillUnmount() {
    //this.props.setSelectedProject({});

    document.removeEventListener("mousedown", this.handleClickOutside);

    socket.off("PromoComplete", this.promoCompleteHandler);
  }
  handleClickOutside(event) {
    if (
      event.target.className.animVal === null ||
      event.target.className.animVal === undefined
    ) {
      const area = ReactDOM.findDOMNode(this.Manage.current);
      if (area !== null) {
        if (!area.parentElement.contains(event.target)) {
          this.setState({ manageOpened: false });
        }
      }
    }
  }

  componentDidUpdate() {
    if (
      !this.props.loadedProjects &&
      !this.props.loadingProjects &&
      !this.props.loadingError &&
      this.props.apps.length > 0 &&
      this.state.accessPermissions === true
    ) {
      let accessAllowed = this.props.apps.filter(item => {
        return item.type === "promoOpt";
      });

      if (accessAllowed.length > 0) {
        this.props.getProjects();
      } else {
        this.setState({ accessPermissions: false });
      }
    }
    if (this.props.loadedProjects && !this.state.loaded) {
      this.setState({ uploadFolders: this.props.promoProjects, loaded: true });
    }
    if (this.props.reInitProjects) {
      this.props.setReInitProjects(false);
      this.setState({ uploadFolders: this.props.promoProjects });
    }
  }

  componentDidMount() {
    const { selectedProject } = this.props;
    socket.on("PromoComplete", this.promoCompleteHandler);
    let accessAllowed = this.props.apps.filter(item => {
      return item.type === "promoOpt";
    });
    if (accessAllowed.length > 0) {
      this.props.getProjects();
      if (selectedProject !== null) {
        this.props.setAlias(
          selectedProject.ID,
          selectedProject.TACTIC_ALIAS_TPRONLY,
          selectedProject.TACTIC_ALIAS_MINOR,
          selectedProject.TACTIC_ALIAS_MAJOR,
          selectedProject.TACTIC_ALIAS_OTHER
        );
      }
      document.addEventListener("mousedown", this.handleClickOutside);
    }
  }
  promoCompleteHandler(data) {
    this.setState({ loaded: false });
    this.props.getProjects();
  }
  allProjectStateChange() {
    const { allProjectsOpened } = this.state;
    this.setState({ allProjectsOpened: !allProjectsOpened });
  }
  filterPanelStateChange() {
    const { filterPanelOpened } = this.state;
    this.setState({ filterPanelOpened: !filterPanelOpened });
  }
  clickHandler(view) {
    //if (this.props.baselineId !== -1) {
    this.props.setViewSelected(view);

    if (this.props.promoId !== -1) {
      if (
        this.props.promoProjects[0].data[0] !== undefined &&
        this.props.promoProjects[0].data[0].promoPlans.length > 0
      ) {
        let completePromos = this.props.promoProjects[0].data[0].promoPlans.filter(
          item => {
            return item.status === "complete";
          }
        );
        if (completePromos.length > 0) {
          this.props.selectPromo(completePromos[0]);
        }
      }
    }
    //this.setState({ viewSelected: view });
    //}
  }
  manageClickHandler() {
    this.setState({ manageOpened: !this.state.manageOpened });
  }
  handleClose() {
    this.setState({
      showDeleteLiftConfirmModal: false,
      showDeleteBaselineConfirmModal: false,
      showDeleteProjectConfirmModal: false,
      showConfigCancelModal: false
    });
  }
  keyDownHandler(event) {
    if (event.key === "Enter") {
      // this.props.updateGroupName(
      //   this.props.selectedClient.value,
      //   this.props.group.id,
      //   this.textInput.current.value
      // );
      this.props.selectedProject.PROJECT_NAME = this.BaselineNameInput.current.value;
      this.props.setOldProjectName(
        this.props.selectedProject,
        this.BaselineNameInput.current.value
      );
      this.setState({
        editBaseline: false
      });
    } else if (event.key === "Escape") {
      this.props.selectedProject.PROJECT_NAME = this.props.oldProjectName; // this.setState({ groupName: this.props.group.name });
      this.setState({ editBaseline: false });
    }
  }
  renderInfoHeader() {
    const { selectedProject } = this.props;
    let totalBatches = 0;
    let lastPromo = null;
    let totalPromoPlans = 0;
    if (selectedProject.data === undefined) {
      return <div></div>;
    }
    if (selectedProject.data.length === 0) {
      lastPromo = {};
    } else {
      selectedProject.data.forEach(dItem => {
        if (dItem.promoPlans !== undefined) {
          dItem.promoPlans.forEach(promo => {
            if (lastPromo === null) {
              lastPromo = promo;
            } else if (
              moment(lastPromo.lastModified, timestampFormat).isBefore(
                moment(promo.lastModified, timestampFormat)
              )
            ) {
              lastPromo = promo;
            }
          });
          totalPromoPlans = totalPromoPlans + dItem.promoPlans.length;
        }
        //totalBaselines = totalBaselines + batches.baselines.length;
      });
      totalBatches = selectedProject.data.length;
    }

    return (
      <div style={{}} className="project-heading">
        {this.state.editBaseline ? (
          <input
            style={{ width: "50%" }}
            defaultValue={selectedProject.PROJECT_NAME}
            ref={this.BaselineNameInput}
            onChange={evt => {
              if (evt.keyCode === 13) {
                this.setState({ editGroupName: false });
              }
            }}
            onKeyDown={event => {
              selectedProject.PROJECT_NAME = this.BaselineNameInput;
              this.keyDownHandler(event);
            }}
          />
        ) : (
          <div style={{ width: "50%" }}>
            <div style={{}} className="kpi-title">
              Project Name
            </div>
            <div style={{ fontSize: "18px", fontWeight: "600" }}>
              {selectedProject.PROJECT_NAME}
            </div>
          </div>
        )}
        <div className="tabs-vr"></div>
        <div style={{}} className="minor-kpi-container">
          <div style={{}} className="kpi-title">
            Created Date
          </div>
          <div className="minor-kpi">
            {moment(
              selectedProject.PROJECT_DATE_CREATED,
              timestampFormat
            ).format("MM/DD/YY")}
          </div>
        </div>
        <div className="tabs-vr"></div>
        <div style={{ width: "25%" }} className="minor-kpi-container">
          <div className="kpi-title">
            Data Batches with Finalized Baselines and Lift Models
          </div>
          <div className="minor-kpi">{totalBatches}</div>
        </div>
        <div className="tabs-vr"></div>
        <div style={{ marginLeft: "8px" }}>
          <div className="kpi-title">Promo Plans</div>
          <div className="minor-kpi">{totalPromoPlans}</div>
        </div>
        <div
          style={
            {
              // marginLeft: "auto",
              // display: "flex",
              // flexDirection: "row",
              // alignItems: "center"
            }
          }
          className="manage-menu-link"
        >
          <div
            onClick={() => {
              this.manageClickHandler();
            }}
            style={{ cursor: "pointer", display: "flex", flexDirection: "row" }}
          >
            <Gear
              className="selectable gear"
              height={"20px"}
              width={"20px"}
              color={"rgba(0, 126, 167, 1)"}
            />
            <div style={{ color: "rgba(0, 126, 167, 1)", marginLeft: "5px" }}>
              Manage
            </div>
          </div>
          {this.state.manageOpened && (
            <div
              ref={this.Manage}
              onBlur={() => {
                this.setState({ manageOpened: false });
              }}
              className="manage-drop-down"
              style={{
                borderRadius: "0",
                border: "1px solid rgb(229, 230, 232)"
              }}
            >
              <div
                onClick={() => {
                  this.setState({ editBaseline: true, manageOpened: false });
                }}
                className="manage-menu-item menu-item"
              >
                <Pen
                  height={"16px"}
                  width={"16px"}
                  color={"rgba(112, 112, 112, 1)"}
                  style={{ marginRight: "10px", cursor: "pointer" }}
                />
                Rename
              </div>

              <div
                className="manage-menu-item menu-item"
                style={{
                  borderBottom: "none"
                }}
                onClick={() => {
                  this.setState({
                    showDeleteProjectConfirmModal: true,
                    manageOpened: false
                  });
                }}
              >
                <DeleteIcon
                  height={"16px"}
                  width={"16px"}
                  color={"rgba(112, 112, 112, 1)"}
                  style={{
                    cursor: "pointer",
                    marginRight: "10px"
                  }}
                />
                Delete
              </div>
            </div>
          )}
        </div>
      </div>
    );
  }
  renderPromoOptList() {
    if (
      this.props.selectedProject.data !== undefined &&
      this.props.selectedProject.data.length > 0
    ) {
      return <PromoOptList project={this.props.selectedProject} />;
    } else {
      return <div>No finalized lifts or baselines found</div>;
    }
  }
  renderProject() {
    if (this.props.selectedProject !== null) {
      return (
        <div className="accu-project-div">
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              width: "100%",
              height: "100%"
            }}
          >
            {this.renderInfoHeader()}
            <hr style={{ alignContent: "center", width: "100%" }} />
            {this.renderPromoOptList()}
          </div>
        </div>
      );
    } else {
      return (
        <div className="accu-project-div">
          <div>
            No projects found. Create at least one project, one finalized
            baseline, and one finalized lift model before creating promotion
            plans.
          </div>
        </div>
      );
    }
  }
  deleteHandler(type) {
    if (type === "lift") {
      this.setState({ deletingLift: true });
      axios
        .get(
          `${process.env.REACT_APP_BIGHORN_SERVER}/api/acculift/deleteLift/${this.props.selectedLift}`,
          {
            headers: {
              ClientToken: localStorage.getItem("clientToken")
            }
          }
        )
        .then(res => {
          this.props.getProjects();

          this.handleClose();
          this.setState({ deletingLift: false });
          toast.success(this.state.selectedLift + " Deleted");
        });
    } else if (type === "project") {
      this.setState({ deletingProject: true });
      axios
        .get(
          `${process.env.REACT_APP_BIGHORN_SERVER}/api/accubase/deleteProject/${this.props.selectedProject.ID}`,
          {
            headers: {
              ClientToken: localStorage.getItem("clientToken")
            }
          }
        )
        .then(res => {
          this.props.getProjects();
          this.handleClose();
          this.setState({ deletingProject: false });

          toast.success("Project Deleted");
        });
    } else {
      this.setState({ deletingBaseline: true });
      axios
        .get(
          `${process.env.REACT_APP_BIGHORN_SERVER}/api/accubase/deleteBaseline/${this.state.clickedBaselineId}`,
          {
            headers: {
              ClientToken: localStorage.getItem("clientToken")
            }
          }
        )
        .then(res => {
          this.props.getProjects();
          this.handleClose();
          this.setState({ deletingBaseline: false });

          toast.success(this.state.clickedBaselineName + " Deleted");
        });
    }
  }
  downloadHandler() {
    toast.success("Preparing Promo Model Download");
    axios
      .post(
        `${process.env.REACT_APP_BIGHORN_SERVER}/api/promoOpt/downloadPromo`,
        {
          promoId: this.props.selectedPromo.id,
          promoName: this.props.selectedPromo.name
        },
        {
          headers: {
            ClientToken: localStorage.getItem("clientToken")
          }
        }
      )
      .then(res => {
        var filename = extractFileName(res.headers["content-disposition"]);
        console.log("File name", filename);
        //this.setState({ manageOpened: false });
        //invoke 'Save' dialog
        save(res.data, filename);
      });
  }

  renderHeader() {
    const { promoProjects } = this.props;
    const {
      showDeleteProjectConfirmModal,
      showDeleteLiftConfirmModal,
      showDeleteBaselineConfirmModal
    } = this.state;
    let totalProjects = promoProjects.length;

    let totalBaselines = 0;

    let lastPromo = null;
    let totalPromoPlans = 0;
    promoProjects.forEach(item => {
      item.data.forEach(dItem => {
        if (dItem.promoPlans !== undefined) {
          dItem.promoPlans.forEach(promo => {
            if (lastPromo === null) {
              lastPromo = promo;
            } else if (
              moment(lastPromo.lastModified, timestampFormat).isBefore(
                moment(promo.lastModified, timestampFormat)
              )
            ) {
              lastPromo = promo;
            }
          });

          totalPromoPlans = totalPromoPlans + dItem.promoPlans.length;
        }
      });

      totalBaselines = totalBaselines + item.data.length;
    });
    if (lastPromo === null) {
      lastPromo = {};
    }

    return (
      <div
        style={{
          height: "100%",
          display: "flex",
          flexDirection: "row",
          alignItems: "center"
        }}
      >
        <Modal isOpen={showDeleteProjectConfirmModal}>
          <ModalHeader>Delete Project</ModalHeader>
          <ModalBody>
            <div>
              Are you sure you want to delete this project and all associated
              input data sources, baselines, and lift models? WARNING: This
              action cannot be undone.
            </div>
          </ModalBody>

          <ModalFooter>
            {!this.state.deletingProject ? (
              <Button
                color="primary"
                onClick={() => {
                  this.deleteHandler("project");
                }}
              >
                Delete
              </Button>
            ) : (
              <div
                className="accubase-uploading"
                style={{
                  marginLeft: "auto",

                  display: "flex",
                  flexDirection: "row"
                }}
              >
                <DotLoader
                  sizeUnit={"px"}
                  size={15}
                  color={"black"}
                  loading={true}
                />
                <div style={{ marginLeft: "5px" }}>Deleting ...</div>
              </div>
            )}
            <Button onClick={this.handleClose}>Cancel</Button>
          </ModalFooter>
        </Modal>
        <Modal isOpen={showDeleteLiftConfirmModal}>
          <ModalHeader>Delete Lift Model</ModalHeader>
          <ModalBody>
            <div>"Are you sure you want to delete this Lift Model?"</div>
          </ModalBody>

          <ModalFooter>
            {!this.state.deletingBatch ? (
              <Button
                color="primary"
                onClick={() => {
                  this.deleteHandler("lift");
                }}
              >
                Delete
              </Button>
            ) : (
              <div
                className="accubase-uploading"
                style={{
                  marginLeft: "auto",

                  display: "flex",
                  flexDirection: "row"
                }}
              >
                <DotLoader
                  sizeUnit={"px"}
                  size={15}
                  color={"black"}
                  loading={true}
                />
                <div style={{ marginLeft: "5px" }}>Deleting ...</div>
              </div>
            )}
            <Button onClick={this.handleClose}>Cancel</Button>
          </ModalFooter>
        </Modal>
        <Modal isOpen={showDeleteBaselineConfirmModal}>
          <ModalHeader>Delete Baseline</ModalHeader>
          <ModalBody>
            <div>
              Are you sure you want to delete the selected baseline and
              corresponding lift models?
            </div>
          </ModalBody>

          <ModalFooter>
            {!this.state.deletingBaseline ? (
              <Button
                color="primary"
                onClick={() => {
                  this.deleteHandler("baseline");
                }}
              >
                Delete
              </Button>
            ) : (
              <div
                className="accubase-uploading"
                style={{
                  marginLeft: "auto",

                  display: "flex",
                  flexDirection: "row"
                }}
              >
                <DotLoader
                  sizeUnit={"px"}
                  size={15}
                  color={"black"}
                  loading={true}
                />
                <div style={{ marginLeft: "5px" }}>Deleting ...</div>
              </div>
            )}
            <Button onClick={this.handleClose}>Cancel</Button>
          </ModalFooter>
        </Modal>

        <div className="kpi-container">
          <div className="kpi-icon">
            <ProjectIcon height={"26px"} color={"rgba(0, 128, 171, 1)"} />
          </div>
          <div className="kpi-details">
            <div className="kpi-title">Total Projects</div>
            <div className="kpi-value">{totalProjects}</div>
          </div>
        </div>
        <div className="tabs-vr"></div>
        <div className="kpi-container">
          <div className="kpi-icon">
            <CollectionIcon height={"26px"} color={"#666"} />
          </div>
          <div className="kpi-details">
            <div className="kpi-title">
              Data Batches with Finalized Baselines and Lift Models
            </div>
            <div className="kpi-value">{totalBaselines}</div>
          </div>
        </div>

        <div className="tabs-vr"></div>
        <div className="kpi-container">
          <div className="kpi-icon">
            <PromoPlanIcon height="26px" />
          </div>
          <div className="kpi-details">
            <div className="kpi-title">Total Promotion Plans</div>
            <div className="kpi-value">{totalPromoPlans}</div>
          </div>
        </div>
        <div className="tabs-vr"></div>
        <div className="kpi-container" style={{ maxWidth: "20%" }}>
          {" "}
          {/*Try to keep long lift model names from expanding this section too much*/}
          <div className="kpi-icon">
            <LatestModel color={"rgba(0, 128, 171, 1)"} height="26px" />
          </div>
          <div className="kpi-details">
            <div className="kpi-title">Latest Promo Plan</div>
            <div className="kpi-value" style={{ maxWidth: "95%" }}>
              {lastPromo.name}
            </div>
          </div>
        </div>
      </div>
    );
  }
  cellValueChanged(item) {
    this.props.appendChanged(item);
  }
  savePromoConfig() {
    this.setState({ savingPromo: true });
    axios
      .post(
        `${process.env.REACT_APP_BIGHORN_SERVER}/api/promoOpt/savePromoConfig`,
        {
          promoId: this.props.selectedPromo.id,
          changed: this.props.changed
        },
        {
          headers: {
            ClientToken: localStorage.getItem("clientToken")
          }
        }
      )
      .then(res => {
        this.setState({ savingPromo: false });
        this.props.createPromo(this.props.selectedPromo.id);
        this.props.setViewSelected(1);
        this.props.setChanged([]);
        // this.props.getProjects();
        // this.handleClose();
        // this.setState({ deletingLift: false });
        // toast.success(this.state.selectedLift + ' Deleted');
      });
  }
  getContextMenuItems = params => {
    var result = [
      {
        // custom item
        name: "Fill Down ",
        action: function() {
          params.context.thisComponent.fillDown();
        },
        cssClasses: ["redFont", "bold"]
      },
      {
        // custom item
        name: "Fill Selection ",
        action: function() {
          params.context.thisComponent.fillSelection();
        },
        cssClasses: ["redFont", "bold"]
      },
      "separator",
      // built in copy item
      "copy",
      "copyWithHeaders",
      "separator",
      "autoSizeAll"
    ];

    return result;
  };
  getContextMenuItemsReview = params => {
    var result = [
      // built in copy item
      "copy",
      "copyWithHeaders",
      "separator",
      "autoSizeAll"
    ];

    return result;
  };
  onGridReady(params) {
    this.gridApi = params.api;
    this.context = { thisComponent: this };
    this.gridColumnApi = params.columnApi;
    //this.props.gridReady(this.agGrid);
  }
  cancelHandler() {
    this.props.setViewSelected(1);
  }
  renderPromo() {
    const {
      loadedProjects,
      promoProjects,
      searchProjects,
      loadedData
    } = this.props;
    const { allProjectsOpened, accessPermissions } = this.state;
    if (!accessPermissions) {
      return (
        <div className="login-wrapper">
          <div className="login-msg">
            You do not have any permissions for this application. Contact your
            administrator.
          </div>
        </div>
      );
    }
    if (!loadedProjects) {
      return <LoadingIndicator paddingTop={"227px"} />;
    }

    if (this.props.viewSelected === 2) {
      if (loadedData) {
        var promoCols = [
          {
            headerName: "Week Date",
            field: "WEEK_DATE",
            width: 120
          },
          {
            headerName: "Geo Key",
            field: "GEO_KEY"
          },
          {
            headerName: "Category",
            field: "CATEGORY"
          },
          {
            headerName: "Item Key",
            field: "ITEM_KEY",
            width: 300
          },
          {
            headerName: "Incremental Profit",
            field: "INCREMENTAL_PROFIT",
            width: 170,
            valueFormatter: usCurrencyWholeDollar,
            cellClass: ["numeric-cell"]
          },
          {
            headerName: "Incremental Spend",
            field: "INCREMENTAL_SPEND",
            width: 170,
            valueFormatter: usCurrencyWholeDollar,
            cellClass: ["numeric-cell"]
          },
          {
            headerName: "Base Units",
            field: "BASE_UNITS",
            width: 150,
            valueFormatter: numberFormatter,
            cellClass: ["numeric-cell"]
          },
          {
            headerName: "Promo Discount",
            field: "PROMO_DISCOUNT",
            width: 150,
            valueFormatter: percentFormatter,
            cellClass: ["numeric-cell"]
          },
          {
            headerName: "Lift",
            field: "LIFT",
            width: 75,
            valueFormatter: fixedDecimalFormatter,
            cellClass: ["numeric-cell"]
          },
          {
            headerName: "Tactic",
            field: "TACTIC",
            width: 100
          },
          {
            headerName: "Event Fee",
            field: "EVENT_FEE",
            width: 150,
            valueFormatter: usCurrencyFormatter,
            cellClass: ["numeric-cell"]
          }
        ];
        return (
          <div className="acculift-main">
            <div
              className={"all-projects " + (allProjectsOpened ? "open" : "")}
            >
              <ProjectSelector
                opened={allProjectsOpened}
                statusChangedHandler={this.allProjectStateChange}
                setSearchProjects={this.props.setSearchProjects}
                searchProjects={searchProjects}
                projects={promoProjects}
                secondary={true}
                type={"promo"}
                setSelectedProject={this.props.setSelectedProject}
                getProjects={this.props.getProjects}
              />
            </div>
            <div
              style={{
                flexDirection: "column",
                display: "flex",
                // overflowY: "auto",
                width: "100%",
                height: "100%",
                paddingLeft: "20px",
                paddingRight: "20px"
              }}
            >
              <div className="manage-projects-is" style={{ marginLeft: "0px" }}>
                Review Promo Plan
              </div>
              <div
                style={{
                  height: "100px",
                  borderTop: "1px solid rgb(229, 230, 232)",
                  display: "flex",
                  flexDirection: "column",
                  borderBottom: "1px solid #bbbfc4"
                }}
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    //marginLeft: '20px',
                    paddingTop: "15px",
                    paddingBottom: "15px"
                    // paddingLeft:'20px',
                  }}
                >
                  <ProjectIcon height={"26px"} color={"rgba(0, 128, 171, 1)"} />
                  <div style={{ marginLeft: "10px" }}>
                    {this.props.selectedPromo.name}
                  </div>
                </div>
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  height: "50px",
                  paddingTop: "10px"
                }}
              >
                <div className="tabs-vr-promo-config"></div>
                <div style={{}} className="minor-kpi-container">
                  <div style={{}} className="kpi-title">
                    Created Date
                  </div>
                  <div className="minor-kpi">
                    {moment(
                      this.props.selectedPromo.lastModified,
                      timestampFormat
                    ).format("MM/DD/YY")}
                  </div>
                </div>
                <div className="tabs-vr-promo-config"></div>
                <div style={{}} className="minor-kpi-container">
                  <div className="kpi-title">Quarter</div>
                  <div className="minor-kpi">
                    Q{this.props.selectedPromo.quarter}
                  </div>
                </div>
                <div className="tabs-vr-promo-config"></div>
                <div style={{ marginLeft: "8px", marginRight: "8px" }}>
                  <div className="kpi-title">Data Batch</div>
                  <div className="minor-kpi">
                    {this.props.selectedPromo.batchName}
                  </div>
                </div>
                <div className="tabs-vr-promo-config"></div>
                <div style={{ marginLeft: "8px", marginRight: "8px" }}>
                  <div className="kpi-title">Baseline</div>
                  <div className="minor-kpi">
                    {this.props.selectedPromo.baselineName}
                  </div>
                </div>
                <div className="tabs-vr-promo-config"></div>
                <div style={{ marginLeft: "8px" }}>
                  <div className="kpi-title">Lift Model</div>
                  <div className="minor-kpi">
                    {this.props.selectedPromo.liftName}
                  </div>
                </div>
                <div
                  style={{
                    marginLeft: "auto",
                    display: "flex",
                    flexDirection: "row"
                  }}
                >
                  {/* {this.state.savingPromo? 
                    <div
                      className="accubase-uploading"
                      style={{
                        marginLeft: "auto",

                        display: "flex",
                        flexDirection: "row"
                      }}
                    >
                      <DotLoader
                        sizeUnit={"px"}
                        size={15}
                        color={"black"}
                        loading={true}
                      />
                      <div style={{ marginLeft: "5px" }}>Saving Config ...</div>
                    </div>
                    :
                    <div
                      style={{ width: "120px", marginLeft: "20px" }}
                      className={
                        "ta-baseline-button " //+
                        // (changed.length === 0 ? 'disabled' : '')
                      }
                      onClick={() => {
                        if (this.props.changed.length > 0) {
                          this.savePromoConfig();
                        }
                      }}
                    >
                  Create Plan
                    </div>
                  } */}
                  <div
                    style={{ width: "120px", marginLeft: "20px" }}
                    className={
                      "ta-baseline-button " //+
                      // (changed.length === 0 ? 'disabled' : '')
                    }
                    onClick={() => {
                      this.downloadHandler();
                    }}
                  >
                    Download
                  </div>
                  <div
                    style={{ width: "120px", marginLeft: "20px" }}
                    className={
                      "ta-baseline-button " //+
                      // (changed.length === 0 ? 'disabled' : '')
                    }
                    onClick={() => {
                      this.cancelHandler();
                    }}
                  >
                    Back
                  </div>
                </div>
              </div>

              {this.props.loadedData ? (
                <AccubaseGrid
                  columnDefs={promoCols}
                  resizable={false}
                  rowData={this.props.promoData}
                  onFilterChanged={this.filterChanged}
                  context={{ thisComponent: this }}
                  masterDetail={false}
                  suppressContextMenu={false}
                  onGridReady={this.onGridReady.bind(this)}
                  getContextMenuItems={this.getContextMenuItemsReview}
                  suppressRowClickSelection={true}
                  getRowNodeId={data => {
                    return data.ID;
                  }}
                  deltaRowDataMode={true}
                  className="review-bl-grid"
                />
              ) : (
                <LoadingIndicator paddingTop={"227px"} />
              )}
            </div>
          </div>
        );
      } else if (!this.props.loadingData) {
        return (
          <div className="acculift-main">
            <div
              className={"all-projects " + (allProjectsOpened ? "open" : "")}
            >
              <ProjectSelector
                opened={allProjectsOpened}
                statusChangedHandler={this.allProjectStateChange}
                setSearchProjects={this.props.setSearchProjects}
                searchProjects={searchProjects}
                projects={promoProjects}
                type={"promo"}
                secondary={true}
                setSelectedProject={this.props.setSelectedProject}
                getProjects={this.props.getProjects}
              />
            </div>
            {/* <div style={{ height: "100%", width: "100%" }}> */}
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                width: "100%"
              }}
            >
              <div
                style={{
                  width: "100%"
                  //paddingLeft: "20px",
                  // borderBottom: "1px solid rgb(229, 230, 232)"
                }}
                className="manage-projects-is"
              >
                Review Promo Plans
              </div>
              <div
                className={
                  "manage-projects-body " + (allProjectsOpened ? "open" : "")
                }
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "center",
                    paddingTop: this.state.paddingTop
                  }}
                >
                  <div
                    style={{
                      marginLeft: "10px",
                      marginTop: "40px",
                      fontSize: "20px",
                      textAlign: "center"
                    }}
                  >
                    No promo plans found in the selected project<br></br>
                    Please choose a different project
                  </div>
                </div>
              </div>
            </div>
          </div>
        );
      } else {
        return (
          <div className="acculift-main">
            <div
              className={"all-projects " + (allProjectsOpened ? "open" : "")}
            >
              <ProjectSelector
                opened={allProjectsOpened}
                statusChangedHandler={this.allProjectStateChange}
                setSearchProjects={this.props.setSearchProjects}
                searchProjects={searchProjects}
                projects={promoProjects}
                secondary={true}
                type={"promo"}
                setSelectedProject={this.props.setSelectedProject}
                getProjects={this.props.getProjects}
              />
            </div>
            {/* <div style={{ height: "100%", width: "100%" }}> */}
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                width: "100%"
              }}
            >
              <div
                style={{
                  width: "100%"
                }}
                className="manage-projects-is"
              >
                Review Promo Plan
              </div>
              <div
                className={
                  "manage-projects-body " + (allProjectsOpened ? "open" : "")
                }
              >
                <LoadingIndicator paddingTop={"227px"} />
              </div>
            </div>
          </div>
        );
      }
    } else if (this.props.viewSelected === 3) {
      return (
        <div className="acculift-main">
          <div className={"all-projects " + (allProjectsOpened ? "open" : "")}>
            <ProjectSelector
              opened={allProjectsOpened}
              statusChangedHandler={this.allProjectStateChange}
              setSearchProjects={this.props.setSearchProjects}
              searchProjects={searchProjects}
              projects={promoProjects}
              lifts={false}
              setSelectedProject={this.props.setSelectedProject}
              getProjects={this.props.getProjects}
            />
          </div>
          <div
            style={{
              flexDirection: "column",
              display: "flex",
              // overflowY: "auto",
              width: "100%",
              height: "100%",
              paddingLeft: "20px",
              paddingRight: "20px"
            }}
          >
            <div className="manage-projects-is" style={{ marginLeft: "0px" }}>
              Editing Promotion Plan
            </div>
            <div
              style={{
                height: "100px",
                borderTop: "1px solid rgb(229, 230, 232)",
                display: "flex",
                flexDirection: "column",
                borderBottom: "1px solid #bbbfc4"
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  //marginLeft: '20px',
                  paddingTop: "15px",
                  paddingBottom: "15px"
                  // paddingLeft:'20px',
                }}
              >
                <ProjectIcon height={"26px"} color={"rgba(0, 128, 171, 1)"} />
                <div style={{ marginLeft: "10px" }}>
                  {this.props.selectedPromo.name}
                </div>
              </div>
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                height: "50px",
                paddingTop: "10px"
              }}
            >
              <div className="tabs-vr-promo-config"></div>
              <div style={{}} className="minor-kpi-container">
                <div style={{}} className="kpi-title">
                  Created Date
                </div>
                <div className="minor-kpi">
                  {moment(
                    this.props.selectedPromo.lastModified,
                    timestampFormat
                  ).format("MM/DD/YY")}
                </div>
              </div>
              <div className="tabs-vr-promo-config"></div>
              <div style={{}} className="minor-kpi-container">
                <div className="kpi-title">Quarter</div>
                <div className="minor-kpi">
                  Q{this.props.selectedPromo.quarter}
                </div>
              </div>
              <div className="tabs-vr-promo-config"></div>
              <div style={{ marginLeft: "8px", marginRight: "8px" }}>
                <div className="kpi-title">Data Batch</div>
                <div className="minor-kpi">
                  {this.props.selectedPromo.batchName}
                </div>
              </div>
              <div className="tabs-vr-promo-config"></div>
              <div style={{ marginLeft: "8px", marginRight: "8px" }}>
                <div className="kpi-title">Baseline</div>
                <div className="minor-kpi">
                  {this.props.selectedPromo.baselineName}
                </div>
              </div>
              <div className="tabs-vr-promo-config"></div>
              <div style={{ marginLeft: "8px" }}>
                <div className="kpi-title">Lift Model</div>
                <div className="minor-kpi">
                  {this.props.selectedPromo.liftName}
                </div>
              </div>
              <div
                style={{
                  marginLeft: "auto",
                  display: "flex",
                  flexDirection: "row"
                }}
              >
                {this.state.savingPromo ? (
                  <div
                    className="accubase-uploading"
                    style={{
                      marginLeft: "auto",

                      display: "flex",
                      flexDirection: "row"
                    }}
                  >
                    <DotLoader
                      sizeUnit={"px"}
                      size={15}
                      color={"black"}
                      loading={true}
                    />
                    <div style={{ marginLeft: "5px" }}>Saving Config ...</div>
                  </div>
                ) : (
                  <div
                    style={{ width: "120px", marginLeft: "20px" }}
                    className={
                      "ta-baseline-button " +
                      (this.props.changed.length === 0 ? "disabled" : "")
                    }
                    onClick={() => {
                      if (this.props.changed.length > 0) {
                        this.savePromoConfig();
                      }
                    }}
                  >
                    Create Plan
                  </div>
                )}
                <div
                  style={{ width: "120px", marginLeft: "20px" }}
                  className={
                    "ta-baseline-button " //+
                    // (changed.length === 0 ? 'disabled' : '')
                  }
                  onClick={() => {
                    if (this.props.changed.length > 0) {
                      this.setState({ showConfigCancelModal: true });
                    } else {
                      this.cancelHandler();
                    }
                  }}
                >
                  Cancel
                </div>
              </div>
            </div>

            {this.props.loadedData ? (
              <AccubaseGrid
                columnDefs={this.props.config}
                resizable={false}
                rowData={this.props.data}
                onFilterChanged={this.filterChanged}
                context={{ thisComponent: this }}
                masterDetail={true}
                components={{
                  EditableNumberCellRenderer: EditableNumberCellRenderer,
                  EditableBooleanCellRenderer: EditableBooleanCellRenderer,
                  EditableCheckboxCellRenderer: EditableCheckboxCellRenderer,
                  EditableCurrencyCellRenderer: EditableCurrencyCellRenderer
                }}
                frameworkComponents={{
                  actionCellRenderer: ActionCellRenderer,
                  EventFeeCellRenderer: EventFeeCellRenderer,
                  PromoOptConfigCellRenderer: PromoOptConfigCellRenderer,
                  WeeklyPromoCellRenderer: WeeklyPromoCellRenderer,
                  EditableCellRenderer: EditableCellRenderer,
                  editableCellPercentRenderer: EditableCellPercentRenderer,
                  PromoSubsidyCellRenderer: PromoSubsidyCellRenderer
                }}
                getRowHeight={function(params) {
                  if (params.node && params.node.detail) {
                    return 440;
                    //var allDetailRowHeight = params.data.lifts.length * 28;
                    //return allDetailRowHeight + offset;
                  } else if (params.node && params.node.detail) {
                    return 230;
                  } else {
                    // otherwise return fixed master row height
                    return 40;
                  }
                }}
                onGridReady={this.onGridReady.bind(this)}
                detailCellRenderer={"PromoOptConfigCellRenderer"}
                // suppressContextMenu={true}
                getContextMenuItems={this.getContextMenuItems}
                suppressRowClickSelection={true}
                onCellValueChanged={item => {
                  this.cellValueChanged(item);
                }}
                getRowNodeId={data => {
                  return data.ID;
                }}
                deltaRowDataMode={true}
                className="review-bl-grid"
              />
            ) : (
              <LoadingIndicator paddingTop={"227px"} />
            )}
          </div>
        </div>
      );
    } else {
      return (
        <div className="acculift-main">
          <div className={"all-projects " + (allProjectsOpened ? "open" : "")}>
            <ProjectSelector
              opened={allProjectsOpened}
              statusChangedHandler={this.allProjectStateChange}
              setSearchProjects={this.props.setSearchProjects}
              searchProjects={searchProjects}
              projects={promoProjects}
              lifts={false}
              setSelectedProject={this.props.setSelectedProject}
              getProjects={this.props.getProjects}
            />
          </div>
          <div
            style={{
              flexDirection: "column",
              display: "flex",
              // overflowY: "auto",
              width: "100%",
              height: "100%"
            }}
          >
            <div className="manage-projects-is">Manage Promotion Plans</div>
            <div
              className={
                "manage-projects-body " + (allProjectsOpened ? "open" : "")
              }
            >
              <div
                style={{
                  height: "75px",
                  border: "1px solid rgb(217,219,221)"
                }}
              >
                {this.renderHeader()}
              </div>
              <div
                style={{
                  border: "1px solid rgb(217,219,221)",
                  background: "rgb(249,249,249)",
                  width: "100%",
                  height: "100%",
                  display: "flex",
                  flexDirection: "column"
                }}
              >
                {this.renderProject()}
              </div>
            </div>
          </div>
        </div>
      );
    }
  }
  render() {
    const { showConfigCancelModal } = this.state;
    return (
      <div className="acculift">
        <Modal isOpen={showConfigCancelModal}>
          <ModalHeader>Unsaved Changes</ModalHeader>
          <ModalBody>
            <div>
              You have unsaved changes. These changes will be lost if you
              navigate away from this table. Do you want to continue?
            </div>
          </ModalBody>

          <ModalFooter>
            <Button
              onClick={() => {
                this.handleClose();
                this.props.setChanged([]);
                this.props.setViewSelected(1);
              }}
            >
              Yes
            </Button>
            <Button
              onClick={() => {
                this.handleClose();
              }}
            >
              No
            </Button>
          </ModalFooter>
        </Modal>

        <div
          className="ta-second-level-nav-container"
          style={{ paddingLeft: "10px" }}
        >
          <ul className="ta-second-level-nav flex-container">
            <li className="secondary-app-title">
              <div>Promo Optimization</div>
            </li>
            <li className="secondary-nav-chevron">
              <RightChevron height={"20px"} color={"#666"} />
            </li>
            <li>
              <div
                onClick={() => this.clickHandler(1)}
                className={
                  "ta-acculift-nav-link " +
                  (this.props.viewSelected === 1 ? "ta-active" : "")
                }
              >
                Manage Projects and Promo Plans
              </div>
            </li>
            <li style={{ marginLeft: "30px" }}>
              <div
                onClick={() => {
                  this.clickHandler(2);
                }}
                className={
                  "ta-acculift-nav-link " +
                  (this.props.viewSelected === 2 ? "ta-active" : "") +
                  (this.props.baselineId !== -1 ? "" : " nav-disabled")
                }
              >
                Review Promo Plans
              </div>
            </li>
          </ul>
          <div
            style={{
              borderBottom: "1px solid lightgray",
              marginRight: "10px",
              marginLeft: "-10px"
            }}
          ></div>
        </div>
        {this.renderPromo()}
      </div>
    );
  }
}

const mapStateToProps = state => ({
  loadingProjects: state.promo.loadingProjects,
  loadedProjects: state.promo.loadedProjects,
  loadingError: state.promo.loadingError,
  loadingData: state.promo.loadingData,
  changed: state.promo.changed,
  loadedData: state.promo.loadedData,
  promoProjects: state.promo.promoProjects,
  searchProjects: state.promo.searchProjects,
  selectedProject: state.promo.selectedProject,
  selectedPromo: state.promo.selectedPromo,
  error: state.promo.error,
  data: state.promo.data,
  promoData: state.promo.promoData,
  config: state.promo.config,
  filteredData: state.promo.filteredData,
  lifts: state.promo.lifts,
  viewSelected: state.promo.viewSelected,
  promoId: state.promo.promoId,
  selectedLift: state.promo.selectedLift,
  lovs: state.promo.lovs,
  oldProjectName: state.promo.oldProjectName,
  alias: state.promo.alias,
  apps: state.app.apps
});
const mapDispatchToProps = dispatch => ({
  getProjects() {
    dispatch(getProjects());
  },
  addTabToNav(tab) {
    dispatch(addTabToNav(tab));
  },

  setSelectedProject(project) {
    dispatch(setSelectedProject(project));
  },
  setViewSelected(view) {
    dispatch(setViewSelected(view));
  },
  setOldProjectName(name) {
    dispatch(setOldProjectName(name));
  },
  appendChanged(changed) {
    dispatch(appendChanged(changed));
  },
  setChanged(changed) {
    dispatch(setChanged(changed));
  },
  createPromo(id) {
    dispatch(createPromo(id));
  },
  selectPromo(promoId, viewSelected) {
    dispatch(selectPromo(promoId, viewSelected));
  },
  setSearchProjects(filter, projects, includeSecondary) {
    dispatch(setSearchProjects(filter, projects, includeSecondary));
  },
  setAlias(id, tpr, minor, major, other) {
    dispatch(setAlias(id, tpr, minor, major, other));
  }
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(PromoOpt)
);
