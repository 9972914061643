import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import "components/accucast/components/style/style.css";
import "bootstrap/dist/css/bootstrap.css";
import { Disk, Bullseye, Gear } from "lib/icons";
import { UpdateLE } from "components/accucast/components/updateLE";
import { UpdatePlan } from "components/accucast/components/updatePlan";
import AccucastRouter from "components/accucast/components/routes";

class AccucastTopLevelNav extends Component {
  constructor(props) {
    super(props);
    let toOpen = props.history.location.pathname.split("/")[2];
    if (toOpen === undefined) {
      toOpen = "le";
    }

    this.state = {
      opened: toOpen
    };
  }

  clickHandler(clicked) {
    this.setState({
      opened: clicked
    });
    this.props.history.push("/accucast/" + clicked);
  }

  render() {
    return (
      <div style={{ height: "100%" }}>
        <ul className="ta-top-level-nav-container acc-header flex-container">
          <div className="ta-top-level-nav flex-container">
            <div className=" ta-center-box ta-nav-icons">
              <Bullseye
                style={{ zIndex: -1 }}
                height={"20px"}
                width={"20px"}
                color={"rgba(0, 126, 167, 1)"}
              />
            </div>
            <div
              className={
                "selectable " + (this.state.opened === "le" ? "selected" : "")
              }
              onClick={() => this.clickHandler("le")}
              style={{
                borderRadius: "2px",
                borderRight: "1px solid rgba(0, 126, 167, 1)",
                borderLeft: "1px solid rgba(0, 126, 167, 1)"
              }}
            >
              Update LE
            </div>
            <div
              className={
                "selectable " + (this.state.opened === "plan" ? "selected" : "")
              }
              onClick={() => this.clickHandler("plan")}
              style={{ borderRight: "1px solid rgba(0, 126, 167, 1)" }}
            >
              Update Plan
            </div>
            <div
              className={
                "selectable " +
                (this.state.opened === "report" ? "selected" : "")
              }
              onClick={() => this.clickHandler("report")}
              style={{ borderRight: "1px solid rgba(0, 126, 167, 1)" }}
            >
              Reporting
            </div>
            <div className="ta-center-box ta-nav-icons">
              <Gear
                className="selectable gear"
                height={"20px"}
                width={"20px"}
                color={"rgba(0, 126, 167, 1)"}
              />
            </div>
          </div>
          <li className="ta-nav-labels">
            Updating: <i className="ta-update-lbl">Week 29 - 07/22/18</i>
          </li>
          <div className="ta-center-box">
            <Disk height={"20px"} color={"rgba(0, 126, 167, 1)"} className="" />
            <li className="ta-nav-labels ta-unsaved-lbl">Unsaved LE</li>
          </div>
          <div className="ta-data-details">
            <div>Refreshed: 07/14/18 06:24AM</div>
            <div>Latest Lock: LE TEST JEST 07/14/18 06:20AM</div>
          </div>
        </ul>
        <div className="ta-grid-container">
          <AccucastRouter {...this.props} />
          {this.state.opened === "le" ? <UpdateLE {...this.props} /> : null}
          {this.state.opened === "plan" ? <UpdatePlan {...this.props} /> : null}
        </div>
      </div>
    );
  }
}

export default withRouter(AccucastTopLevelNav);
