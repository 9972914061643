import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { Route, Switch, Redirect } from "react-router-dom";
import "components/accucast/components/style/style.css";
import { accucastPlanWorkflow } from "components/accucast/components/lib";
import { setWorkflowStep } from "./reducers/secondLevelPlanNavReducer";
import { connect } from "react-redux";
import { PlanSeasonality } from "./planSeasonality";
import { PlanSeasonalityOverrides } from "./planSeasonalityOverrides";
import { PlanStoreDistribution } from "./planStoreDistribution";
import { PlanIncrementalLift } from "./planIncrementalLift";
import { PlanShipments } from "./planShipments";
import { PlanItemInput } from "./planItemInput";
import { PlanOrganicGrowth } from "./planOrganicGrowth";
import { PlanItemDistribution } from "./planItemDistribution";
import { PlanPricing } from "./planPricing";

class PlanAccucastRouter extends Component {
  constructor(props) {
    super(props);
    let path = this.props.history.location.pathname.split("/")[3];
    if (path === undefined) path = "itemInput";
    this.props.setWorkflowStep(parseFloat(accucastPlanWorkflow[path]));
    this.props.history.listen((location, action) => {
      let path = location.pathname.split("/")[3];
      if (path === undefined) path = "itemInput";
      this.props.setWorkflowStep(parseFloat(accucastPlanWorkflow[path]));
    });
  }
  changeHandler() {
    //this.props.setWorkflowStep(parseFloat(accucastLEWorkflow[path]))
  }

  render() {
    const { match } = this.props;
    return (
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          height: "100%",
          minHeight: "100%"
        }}
      >
        <Switch>
          <Redirect
            from="/accucast/plan"
            exact
            to={match.path + "/plan/itemInput"}
          />
          <Route
            path={match.path + "/plan/itemInput"}
            render={props => <PlanItemInput {...props} />}
          />
          <Route
            path={match.path + "/plan/organicGrowth"}
            render={props => <PlanOrganicGrowth {...props} />}
          />
          <Route
            path={match.path + "/plan/seasonality"}
            render={props => <PlanSeasonality {...props} />}
          />
          <Route
            path={match.path + "/plan/seasonalityOverrides"}
            render={props => <PlanSeasonalityOverrides {...props} />}
          />
          <Route
            path={match.path + "/plan/storeDistribution"}
            render={props => <PlanStoreDistribution {...props} />}
          />
          <Route
            path={match.path + "/plan/incrementalLift"}
            render={props => <PlanIncrementalLift {...props} />}
          />
          <Route
            path={match.path + "/plan/itemDistribution"}
            render={props => <PlanItemDistribution {...props} />}
          />
          <Route
            path={match.path + "/plan/pricing"}
            render={props => <PlanPricing {...props} />}
          />
          <Route
            path={match.path + "/plan/shipments"}
            render={props => <PlanShipments {...props} />}
          />
        </Switch>
      </div>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  setWorkflowStep(curStep) {
    dispatch(setWorkflowStep(curStep));
  }
});
export default withRouter(
  connect(
    "",
    mapDispatchToProps
  )(PlanAccucastRouter)
);
