import React, { Component } from "react";

import { FolderIcon, ReportSvg, RightChevron } from "lib/icons";
import { connect } from "react-redux";
//import {OverlayTrigger,Tooltip} from "react-bootstrap";

class PowerBISubFolder extends Component {
  constructor(props) {
    super(props);
    this.state = {
      collapsed: false
    };
    this.collapseHandler = this.collapseHandler.bind(this);
    this.renderCollapsible = this.renderCollapsible.bind(this);
    this.onClick = this.onClick.bind(this);
  }
  collapseHandler(e) {
    e.stopPropagation();
    this.setState(prevState => ({
      collapsed: !prevState.collapsed
    }));
  }
  onClick(event, report, workspace) {
    this.props.clickReportHandler(event, workspace, report);
  }
  renderCollapsible() {
    return (
      <div className="menu-list-collapse">
        <RightChevron
          transform={this.state.collapsed ? "" : "rotate(90,12,12)"}
          height={"20px"}
          // width={"24px"}
          color={"rgba(88, 89, 91, 1)"}
          viewBox={"-1 2 24 24"}
        />
      </div>
    );
    //}
  }
  renderChildren(nodes, leftCaret = false) {
    let rptPadding = 22 * 2;
    return nodes.map((node, idx) => {
      if (node.fileType === "folder") {
        return (
          <PowerBISubFolder
            clickReportHandler={this.props.clickReportHandler}
            key={idx}
            report={node}
            workspace={this.props.workspace}
            leftCaret={leftCaret}
          />
        );
      }
      if (leftCaret) {
        return (
          // <OverlayTrigger
          //   key={"subrpt" + idx + "tip"}
          //   placement={"top"}
          //   overlay={
          //     <Tooltip id={"subrpt" + idx + "tip"}>
          //       {node.description}
          //     </Tooltip>
          //   }
          // >
          <div
            onClick={event => {
              this.onClick(event, node, this.props.workspace);
            }}
            className="ta-workspace-reports-span powerBISubFolder"
            key={"rpt" + idx}
            style={{ marginLeft: `${rptPadding}px` }}
          >
            <ReportSvg
              height={"16px"}
              className={"ta-pbi-icon"}
              color={"rgba(121, 121, 121, 1)"}
              viewBox={"0 -1 24 24"}
            />
            {node.name}
          </div>
          // </OverlayTrigger>
        );
      }

      return (
        <div
          onClick={event => {
            this.onClick(event, node, this.props.workspace);
          }}
          key={"rpt" + idx}
          style={{ marginLeft: `${rptPadding}px` }}
          className="ta-report-name"
        >
          <ReportSvg
            height={"16px"}
            className={"ta-pbi-icon"}
            color={"rgba(121, 121, 121, 1)"}
            viewBox={"0 -1 24 24"}
          />
          {node.name}
        </div>
      );
    });
  }
  render() {
    const { collapsed } = this.state;
    const { leftCaret } = this.props;

    if (leftCaret !== undefined && leftCaret === true) {
      return (
        <div>
          <div
            className="ta-folder-name-left-caret"
            style={{
              marginLeft: "15px",
              display: "flex",
              width: "95%"
            }}
            onClick={event => this.collapseHandler(event, this.props.report)}
          >
            <div style={{ display: "flex", flexDirection: "row" }}>
              {this.renderCollapsible()}

              {this.props.report.name}
            </div>
          </div>
          {collapsed ? null : (
            <div>{this.renderChildren(this.props.report.children, true)}</div>
          )}
        </div>
      );
    }
    return (
      <div>
        <div
          className="ta-folder-name"
          style={{
            marginLeft: "15px",
            display: "flex",
            flexDirection: "column",
            width: "95%"
          }}
          onClick={event => this.collapseHandler(event, this.props.report)}
        >
          <div style={{ display: "flex", flexDirection: "row" }}>
            <FolderIcon
              height={"14px"}
              className={"ta-folder-icon"}
              color={"rgba(121, 121, 121, 1)"}
            />
            {this.props.report.name}

            {this.renderCollapsible()}
          </div>
        </div>
        {collapsed ? null : (
          <div>{this.renderChildren(this.props.report.children, false)}</div>
        )}
      </div>
    );
  }
}

const mapStateToProps = state => ({
  workspaces: state.reportList.workspaces,
  apps: state.app.apps,
  performCollapseAction: state.app.performCollapseAction,
  collapseFolders: state.app.collapseFolders
});
const mapDispatchToProps = dispatch => ({});

export default connect(mapStateToProps, mapDispatchToProps)(PowerBISubFolder);
