import React, { Component } from "react";
import { Draggable } from "react-beautiful-dnd";
import { DeleteIcon } from "lib/icons";
import { OverlayTrigger, Tooltip } from "react-bootstrap";

const getItemStyle = (isDragging, draggableStyle) => ({
  // some basic styles to make the items look a bit nicer
  userSelect: "none",
  padding: grid * 2,
  margin: `0 0 ${grid}px 0`,
  paddingLeft: "4px",
  paddingRight: "4px",
  paddingTop: "0px",
  paddingBottom: "0px",

  // change background colour if dragging
  background: isDragging ? "rgba(62, 171, 203, 0.3)" : "white",
  // styles we need to apply on draggables
  ...draggableStyle
});
const grid = 8;

export class CartItem extends Component {
  delete() {}
  render() {
    return (
      <Draggable
        key={this.props.item.order}
        index={this.props.index}
        draggableId={this.props.item.order.toString()}
      >
        {(provided, snapshot) => (
          <div
            ref={provided.innerRef}
            {...provided.draggableProps}
            style={getItemStyle(
              snapshot.isDragging,
              provided.draggableProps.style
            )}
          >
            <div className="slide-item">
              <div className="slide-num">
                {(this.props.item.order + 1).toString()}
              </div>
              <div className="slide-title" {...provided.dragHandleProps}>
                {this.props.item.name}
              </div>
              <OverlayTrigger
                key={"removeslide-tip"}
                placement={"top"}
                overlay={<Tooltip id={"removeslide-tip"}>Remove Slide</Tooltip>}
              >
                <div
                  className="slide-delete-icon"
                  onClick={() => this.props.deleteHandler(this.props.item)}
                >
                  <DeleteIcon height={"16"} color={"rgba(88, 89, 91, 1)"} />
                </div>
              </OverlayTrigger>
            </div>
          </div>
        )}
      </Draggable>
    );
  }
}
