import React, { Component } from "react";

import NumericEditor from "components/accucast/components/editors/numericEditor";
import TotalRenderer from "components/accucast/components/renderers/totalRenderer";
import { AccucastGrid } from "components/accucast/components/lib/accucastGrid";
import {
  numberFormatter,
  getRowClass,
  percentFormatter
} from "components/accucast/components/formatters";
import { numberNewValueHandler } from "components/accucast/components/handlers";
import { CustomHeaderRenderer } from "components/accucast/components/renderers/customHeaderRenderer";
import { EditableCellFloatRenderer } from "components/accucast/components/renderers/editableCellFloatRenderer";
import { EditableCellPercentRenderer } from "components/accucast/components/renderers/editableCellPercentRenderer";
import { PlanCellRenderer } from "components/accucast/components/updatePlan/renderers/planCellRenderer";

export class PlanOrganicGrowth extends Component {
  constructor(props) {
    super(props);

    this.state = {
      columnDefs: [
        {
          headerName: "Brand Size",
          pinned: "left",
          width: 138,
          field: "bs",
          tooltipField: "bs",
          suppressMovable: true,
          menuTabs: ["filterMenuTab"],
          cellClassRules: {
            "ta-changed":
              "data.wk25Changed || data.wk26Changed || data.wk27Changed || data.wk28Changed"
          }
        },
        {
          headerName: "GEO",
          pinned: "left",
          width: 105,
          field: "geo",
          tooltipField: "geo",
          suppressMovable: true,
          menuTabs: ["filterMenuTab"],
          cellClassRules: {
            "ta-changed":
              "data.wk25Changed || data.wk26Changed || data.wk27Changed || data.wk28Changed"
          }
        },
        {
          headerName: "Base Dollar YAG",
          headerComponent: "customHeaderRenderer",
          width: 136,
          field: "bdy",
          suppressMovable: true,
          menuTabs: ["filterMenuTab"],
          cellEditor: "numericEditor",
          editable: false,
          valueFormatter: numberFormatter,
          newValueHandler: numberNewValueHandler,
          cellClass: "cellCenterClass",
          headerClass: "headerSize"
        },
        {
          headerName: "Base Units YAG",
          headerComponent: "customHeaderRenderer",
          width: 135,
          field: "buy",
          suppressMovable: true,
          menuTabs: ["filterMenuTab"],
          cellEditor: "numericEditor",
          editable: false,
          valueFormatter: numberFormatter,
          newValueHandler: numberNewValueHandler,
          cellClass: "cellCenterClass",
          headerClass: "headerSize"
        },
        {
          headerName: "Store Count YAG",
          headerComponent: "customHeaderRenderer",
          width: 140,
          field: "scy",
          suppressMovable: true,
          menuTabs: ["filterMenuTab"],
          cellEditor: "numericEditor",
          editable: false,
          valueFormatter: numberFormatter,
          newValueHandler: numberNewValueHandler,
          cellClass: "cellCenterClass",
          headerClass: "headerSize"
        },
        {
          headerName: "Base Dollars CURR",
          headerComponent: "customHeaderRenderer",
          width: 151,
          field: "bdc",
          suppressMovable: true,
          menuTabs: ["filterMenuTab"],
          cellEditor: "numericEditor",
          editable: false,
          valueFormatter: numberFormatter,
          newValueHandler: numberNewValueHandler,
          cellClass: "cellCenterClass",
          headerClass: "headerSize"
        },
        {
          headerName: "Base Units CURR",
          headerComponent: "customHeaderRenderer",
          width: 148,
          field: "buc",
          suppressMovable: true,
          menuTabs: ["filterMenuTab"],
          cellEditor: "numericEditor",
          editable: false,
          valueFormatter: numberFormatter,
          newValueHandler: numberNewValueHandler,
          cellClass: "cellCenterClass",
          headerClass: "headerSize"
        },
        {
          headerName: "Store Count CURR",
          headerComponent: "customHeaderRenderer",
          width: 150,
          field: "scc",
          suppressMovable: true,
          menuTabs: ["filterMenuTab"],
          cellEditor: "numericEditor",
          editable: false,
          valueFormatter: numberFormatter,
          newValueHandler: numberNewValueHandler,
          cellClass: "cellCenterClass",
          headerClass: "headerSize"
        },
        {
          headerName: "Pct Chg in Base Dollars",
          headerComponent: "customHeaderRenderer",
          width: 178,
          field: "pcbd",
          suppressMovable: true,
          menuTabs: ["filterMenuTab"],
          cellEditor: "numericEditor",
          editable: false,
          valueFormatter: percentFormatter,
          newValueHandler: numberNewValueHandler,
          cellClass: "cellCenterClass",
          headerClass: "headerSize"
        },
        {
          headerName: "Pct Chg in Base Units",
          headerComponent: "customHeaderRenderer",
          width: 170,
          field: "pcbu",
          suppressMovable: true,
          menuTabs: ["filterMenuTab"],
          cellEditor: "numericEditor",
          editable: false,
          valueFormatter: percentFormatter,
          newValueHandler: numberNewValueHandler,
          cellClass: "cellCenterClass",
          headerClass: "headerSize"
        },
        {
          headerName: "Pct Chg in Stores",
          headerComponent: "customHeaderRenderer",
          width: 145,
          field: "pcs",
          suppressMovable: true,
          menuTabs: ["filterMenuTab"],
          cellEditor: "numericEditor",
          editable: false,
          valueFormatter: percentFormatter,
          newValueHandler: numberNewValueHandler,
          cellClass: "cellCenterClass",
          headerClass: "headerSize"
        },
        {
          headerName: "Pct Chg in Units Per Store",
          headerComponent: "customHeaderRenderer",
          width: 199,
          field: "pcups",
          suppressMovable: true,
          menuTabs: ["filterMenuTab"],
          cellEditor: "numericEditor",
          editable: false,
          valueFormatter: percentFormatter,
          newValueHandler: numberNewValueHandler,
          cellClass: "cellCenterClass",
          headerClass: "headerSize"
        },
        {
          headerName: "Pct Chg in EDRP",
          headerComponent: "customHeaderRenderer",
          width: 137,
          field: "pce",
          suppressMovable: true,
          menuTabs: ["filterMenuTab"],
          cellEditor: "numericEditor",
          editable: false,
          valueFormatter: percentFormatter,
          newValueHandler: numberNewValueHandler,
          cellClass: "cellCenterClass",
          headerClass: "headerSize"
        },
        {
          headerName: "Price Elasticity",
          headerComponent: "customHeaderRenderer",
          width: 129,
          field: "pe",
          suppressMovable: true,
          menuTabs: ["filterMenuTab"],
          cellEditor: "numericEditor",
          editable: true,
          valueFormatter: percentFormatter,
          newValueHandler: numberNewValueHandler,
          cellClass: "cellCenterClass",
          headerClass: "headerSize",
          cellRenderer: "editableCellFloatRenderer"
        },
        {
          headerName: "Expected Chg in Units with Price Chg Only",
          headerComponent: "customHeaderRenderer",
          width: 292,
          field: "ecupco",
          suppressMovable: true,
          menuTabs: ["filterMenuTab"],
          cellEditor: "numericEditor",
          editable: false,
          valueFormatter: percentFormatter,
          newValueHandler: numberNewValueHandler,
          cellClass: "cellCenterClass",
          headerClass: "headerSize"
        },
        {
          headerName: "Adjusted Velocity",
          headerComponent: "customHeaderRenderer",
          width: 150,
          field: "av",
          suppressMovable: true,
          menuTabs: ["filterMenuTab"],
          cellEditor: "numericEditor",
          editable: false,
          valueFormatter: percentFormatter,
          newValueHandler: numberNewValueHandler,
          cellClass: "cellCenterClass",
          headerClass: "headerSize"
        },
        {
          headerName: "Velocity Override",
          headerComponent: "customHeaderRenderer",
          width: 150,
          field: "vo",
          suppressMovable: true,
          menuTabs: ["filterMenuTab"],
          cellEditor: "numericEditor",
          editable: true,
          valueFormatter: percentFormatter,
          newValueHandler: numberNewValueHandler,
          cellClass: "cellCenterClass",
          headerClass: "headerSize",
          cellRenderer: "editableCellPercentRenderer"
        }
      ],
      rowData: this.createRows(),
      frameworkComponents: {
        numericEditor: NumericEditor,
        totalRenderer: TotalRenderer,
        customHeaderRenderer: CustomHeaderRenderer,
        editableCellFloatRenderer: EditableCellFloatRenderer,
        editableCellPercentRenderer: EditableCellPercentRenderer,
        planCellRenderer: PlanCellRenderer
      }
    };
  }
  createRows = () => {
    let rows = [];
    rows.push({
      upsw: "0.0",
      geo: "AHOLD",
      idesc: "89898-NS DIET TURBO CHOS PWD PKT 5CT 1.4OZB",
      pk: "123456789012",
      bs: "SALTY SNACK",
      bdy: "-",
      buy: "5460",
      scy: "0",
      bdc: "-",
      buc: "0",
      scc: "0",
      pcbd: "0",
      pcbu: "-100",
      pcs: "0",
      pcups: "-100",
      pce: "-",
      pe: "-1.0",
      ecupco: "0",
      av: "-100",
      vo: "0"
    });
    rows.push({
      upsw: "0.0",
      geo: "AMAZON",
      idesc: "84220-SOUTH BEACH DOUBLE CHOC BAR 30CT",
      pk: "3267484220",
      bs: "BAR",
      bdy: "-",
      buy: "6498",
      scy: "0",
      bdc: "-",
      buc: "0",
      scc: "0",
      pcbd: "0",
      pcbu: "-100",
      pcs: "0",
      pcups: "-100",
      pce: "-",
      pe: "-1.0",
      ecupco: "0",
      av: "-100",
      vo: "0"
    });
    rows.push({
      upsw: "0.0",
      geo: "AMAZON",
      idesc: "84221-SOUTH BEACH PEANUT BUTTER BAR 30CT",
      pk: "3267484221",
      bs: "KIT",
      bdy: "-",
      buy: "4500",
      scy: "0",
      bdc: "-",
      buc: "0",
      scc: "0",
      pcbd: "0",
      pcbu: "-100",
      pcs: "0",
      pcups: "-100",
      pce: "-",
      pe: "-1.0",
      ecupco: "0",
      av: "-100",
      vo: "0"
    });
    rows.push({
      upsw: "0.0",
      geo: "C&S",
      idesc: "84192-NS DIET KIT EVERYDAY 1CT",
      pk: "3267484192",
      bs: "KIT",
      bdy: "-",
      buy: "5240",
      scy: "0",
      bdc: "-",
      buc: "0",
      scc: "0",
      pcbd: "0",
      pcbu: "-100",
      pcs: "0",
      pcups: "-100",
      pce: "-",
      pe: "-1.0",
      ecupco: "0",
      av: "-100",
      vo: "0"
    });
    rows.push({
      upsw: "0.0",
      geo: "DIV1",
      idesc: "83504-NS DIAB VANIL LIQ 4CT 11.0OZB",
      pk: "3267483504",
      bs: "SHAKE",
      bdy: "-",
      buy: "4400",
      scy: "0",
      bdc: "-",
      buc: "0",
      scc: "0",
      pcbd: "0",
      pcbu: "-100",
      pcs: "0",
      pcups: "-100",
      pce: "-",
      pe: "-1.0",
      ecupco: "0",
      av: "-100",
      vo: "0"
    });

    return rows;
  };

  render() {
    return (
      <div style={{ height: "100%" }}>
        Organic Growth
        <AccucastGrid
          columnDefs={this.state.columnDefs}
          rowData={this.state.rowData}
          getRowClass={getRowClass}
          headerHeight={30}
          frameworkComponents={this.state.frameworkComponents}
        />
      </div>
    );
  }
}
