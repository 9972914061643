import axios from "axios";
import { cloneDeep } from "lodash";
import { toast } from "react-toastify";
import {
  // usCurrencyFormatter,
  percentFormatter,
  usCurrencyWholeDollar
} from "../../agGrid/formatters/index.js";

import moment from "moment";
const timestampFormat = "YYYY-MM-DD HH:mm:ss.SSS ZZ";
//const timestampFormat = "MM/DD/YY";

/* -------------------- INITIAL STATE -------------------- */
const INITIAL_STATE = {
  loadedProjects: false,
  loadingProjects: false,
  loadingData: false,
  loadingError: false,
  loadedData: false,
  promoProjects: [],
  searchProjects: [],
  error: null,
  data: [],
  config: [],
  filteredData: [],
  promoData: [],
  changed: [],
  reInitChart: false,
  oldProjectName: "",
  selectedProject: null,
  reInitProjects: false,
  viewSelected: 1,
  promoId: -1,
  lifts: [],
  lovs: {},
  alias: {},
  updateFilters: false,
  selectedLift: null,
  dialog: "weeklyPromo",
  creatingDefaults: false,
  selectedPromo: {},
  quarter: []
};

/* -------------------- ACTION TYPES -------------------- */
const FETCH_PROMO_OPT_CONFIG_DATA_BEGIN = "FETCH_PROMO_OPT_CONFIG_DATA_BEGIN";
const FETCH_PROMO_OPT_CONFIG_DATA_SUCCESS = "FETCH_PROMO_OPT_CONFIG_DATA_SUCCESS";
const FETCH_PROMO_OPT_CONFIG_DATA_FAILURE = "FETCH_PROMO_OPT_CONFIG_DATA_FAILURE";
const FETCH_PROMO_OPT_PROJECT_BEGIN = "FETCH_PROMO_OPT_PROJECT_BEGIN";
const FETCH_PROMO_OPT_PROJECT_SUCCESS = "FETCH_PROMO_OPT_PROJECT_SUCCESS";
const FETCH_PROMO_OPT_PROJECT_FAILURE = "FETCH_PROMO_OPT_PROJECT_FAILURE";

const FETCH_PROMO_OPT_DATA_BEGIN = "FETCH_PROMO_OPT_DATA_BEGIN";
const FETCH_PROMO_OPT_DATA_SUCCESS = "FETCH_PROMO_OPT_DATA_SUCCESS";
const FETCH_PROMO_OPT_DATA_FAILURE = "FETCH_PROMO_OPT_DATA_FAILURE";

const SET_SEARCH_PROJECTS = "SET_SEARCH_PROJECTS";
const SET_SELECTED_PROJECT_PROMO = "SET_SELECTED_PROJECT_PROMO";

const SET_SELECTED_PROMO = "SET_SELECTED_PROMO";
const SET_VIEW_SELECTED = "SET_VIEW_SELECTED";

const FETCH_PROMO_DEFAULTS_BEGIN = "FETCH_PROMO_DEFAULTS_BEGIN";
const FETCH_PROMO_DEFAULTS_SUCCESS = "FETCH_PROMO_DEFAULTS_SUCCESS";

/* -------------------- ACTION CREATORS -------------------- */

const fetchPromoOptConfigDataBegin = selectedPromo => {
  return { type: FETCH_PROMO_OPT_CONFIG_DATA_BEGIN, selectedPromo };
};
const fetchPromoOptConfigDataSuccess = (data, config, quarter) => {
  return {
    type: FETCH_PROMO_OPT_CONFIG_DATA_SUCCESS,
    payload: data,
    config,
    quarter
  };
};
const fetchPromoOptConfigDataError = error => {
  return { type: FETCH_PROMO_OPT_CONFIG_DATA_FAILURE, payload: error };
};

const fetchPromoOptDataBegin = () => {
  return { type: FETCH_PROMO_OPT_DATA_BEGIN };
};
const fetchPromoOptDataSuccess = (data, config, quarter) => {
  return {
    type: FETCH_PROMO_OPT_DATA_SUCCESS,
    payload: data,
    config,
    quarter
  };
};
const fetchPromoOptDataError = error => {
  return { type: FETCH_PROMO_OPT_DATA_FAILURE, payload: error };
};

const fetchPromoOptProjectBegin = () => ({
  type: FETCH_PROMO_OPT_PROJECT_BEGIN
});
const fetchPromoOptProjectSuccess = projects => {
  return { type: FETCH_PROMO_OPT_PROJECT_SUCCESS, payload: projects };
};
const fetchPromoOptProjectError = error => {
  return { type: FETCH_PROMO_OPT_PROJECT_FAILURE, payload: error };
};

const doSetSearchProjects = projects => {
  return { type: SET_SEARCH_PROJECTS, payload: projects };
};
const doSetSelectedProject = project => {
  return { type: SET_SELECTED_PROJECT_PROMO, payload: project };
};
const createPromoDefaultsBegin = () => ({
  type: FETCH_PROMO_DEFAULTS_BEGIN
});
const createPromoDefaultsSuccess = projects => {
  return { type: FETCH_PROMO_DEFAULTS_SUCCESS, payload: projects };
};
// const createPromoDefaultsError = error => {
//   return { type: FETCH_PROMO_OPT_PROJECT_FAILURE, payload: error };
// };

/* -------------------- ASYNC ACTION CREATORS -------------------- */

export const getPromoOptConfigData = selectedPromo => {
  return dispatch => {
    dispatch(fetchPromoOptConfigDataBegin(selectedPromo));
    axios
      .get(
        `${process.env.REACT_APP_BIGHORN_SERVER}/api/promoOpt/promoOptConfigData/${selectedPromo.id}/quarter/${selectedPromo.quarter}/year/${selectedPromo.year}`,
        {
          headers: {
            ClientToken: localStorage.getItem("clientToken")
          }
        }
      )
      .then(response => {
        let data = response.data;

        dispatch(fetchPromoOptConfigDataSuccess(data.content, data.config, data.quarter));

        //dispatch(generateLovs(response.data.content));
      })
      .catch(error => {
        dispatch(fetchPromoOptConfigDataError(error));
      });
  };
};
export const getPromoData = promoId => {
  return dispatch => {
    dispatch(fetchPromoOptDataBegin());
    axios
      .get(`${process.env.REACT_APP_BIGHORN_SERVER}/api/promoOpt/promoData/${promoId}`, {
        headers: {
          ClientToken: localStorage.getItem("clientToken")
        }
      })
      .then(response => {
        let data = response.data;
        dispatch(fetchPromoOptDataSuccess(data.content));

        //dispatch(generateLovs(response.data.content));
      })
      .catch(error => {
        dispatch(fetchPromoOptDataError(error));
      });
  };
};
export const createPromoDefaults = (batchId, promoName, quarter, year) => {
  return dispatch => {
    dispatch(createPromoDefaultsBegin());
    axios
      .post(
        `${process.env.REACT_APP_BIGHORN_SERVER}/api/promoOpt/createPromoDefaults`,
        {
          batchId,
          promoName,
          quarter,
          year
        },
        {
          headers: {
            ClientToken: localStorage.getItem("clientToken")
          }
        }
      )
      .then(response => {
        dispatch(createPromoDefaultsSuccess());
        dispatch(setViewSelected(3));
        dispatch(getProjects(false));

        dispatch(getPromoOptConfigData(response.data.promo));

        //let data = response.data;

        //dispatch(createPromoDefaultsSuccess(data.content, data.config));

        //dispatch(generateLovs(response.data.content));
      })
      .catch(error => {
        //dispatch(createPromoDefaultsError(error));
      });
  };
};

export const getProjects = () => {
  return dispatch => {
    dispatch(fetchPromoOptProjectBegin());
    axios
      .get(`${process.env.REACT_APP_BIGHORN_SERVER}/api/promoOpt/projects`, {
        headers: {
          ClientToken: localStorage.getItem("clientToken")
        }
      })
      .then(response => {
        dispatch(fetchPromoOptProjectSuccess(response.data.projects));
      })
      .catch(error => {
        console.log(error);

        dispatch(fetchPromoOptProjectError(error));
      });
  };
};
export const setProjects = projects => {
  return dispatch => {
    dispatch(fetchPromoOptProjectSuccess(projects));
  };
};

export const saveProjects = projects => {
  return dispatch => {
    axios
      .post(
        `${process.env.REACT_APP_BIGHORN_SERVER}/api/promoOpt/createProjects`,
        { projects },
        {
          headers: {
            ClientToken: localStorage.getItem("clientToken")
          }
        }
      )
      .then(response => {
        dispatch(getProjects());
      })
      .catch(error => {});
  };
};

export const setSearchProjects = (filter, projects, includeSecondary) => {
  return dispatch => {
    if (filter === "") {
      dispatch(doSetSearchProjects(projects));
    } else {
      var newProjects = cloneDeep(projects);
      var nProjects = [];

      if (includeSecondary) {
        nProjects = newProjects.map((newProject, newProjectIdx) => {
          newProject.data = newProject.data.map((data, baselineIdx) => {
            data.promoPlans = data.promoPlans.filter(item => {
              if (item.name.toLowerCase().indexOf(filter.toLowerCase()) !== -1) {
                return true;
              } else {
                return false;
              }
            });
            return data;
          });
          return newProject;
        });
      } else {
        newProjects.forEach((newProject, newProjectIdx) => {
          if (newProject.PROJECT_NAME.toLowerCase().indexOf(filter.toLowerCase()) !== -1) {
            nProjects.push(newProject);
          }
        });
      }
      dispatch(doSetSearchProjects(nProjects));
    }
  };
};
export const setSelectedProject = project => {
  return dispatch => {
    dispatch(doSetSelectedProject(project));
  };
};
export const setOldProjectName = (selectedProject, name) => {
  return dispatch => {
    axios
      .post(
        `${process.env.REACT_APP_BIGHORN_SERVER}/api/promoOpt/editProjectName`,
        { selectedProject },
        {
          headers: {
            ClientToken: localStorage.getItem("clientToken")
          }
        }
      )
      .then(response => {
        dispatch(getProjects());
      })
      .catch(error => {});
    dispatch({ type: "SET_OLD_PROJECT_NAME", payload: name });
  };
};
export const renamePromo = (promo, name, bId) => {
  return dispatch => {
    axios
      .post(
        `${process.env.REACT_APP_BIGHORN_SERVER}/api/promoOpt/editPromoName`,
        { promo, name, batchId: bId },
        {
          headers: {
            ClientToken: localStorage.getItem("clientToken")
          }
        }
      )
      .then(response => {
        dispatch(getProjects());
      })
      .catch(error => {});
    //dispatch({ type: "SET_OLD_PROJECT_NAME", payload: name });
  };
};

export const selectPromo = (promo, viewSelected = 1) => {
  return dispatch => {
    dispatch(getPromoData(promo.id));
    dispatch({ type: "SET_SELECTED_PROMO", payload: promo, viewSelected });
  };
};
export const setViewSelected = view => {
  return dispatch => {
    dispatch({ type: "SET_VIEW_SELECTED", payload: view });
  };
};

export const setExpandDialog = dialog => {
  return dispatch => {
    dispatch({ type: "SET_EXPAND_DIALOG", payload: dialog });
  };
};
export const createPromo = promoId => {
  toast.success("Starting Promo Calculation");
  return dispatch => {
    axios
      .post(
        `${process.env.REACT_APP_BIGHORN_SERVER}/api/promoOpt/runPromo`,
        {
          promoId
        },
        {
          headers: {
            ClientToken: localStorage.getItem("clientToken")
          }
        }
      )
      .then(res => {
        dispatch(getProjects(false));
      })
      .catch(err => {
        console.log(err);
      });
  };
};
export const setChanged = changed => {
  return dispatch => {
    dispatch({ type: "SET_CHANGED", payload: changed });
  };
};
export const appendChanged = changed => {
  return dispatch => {
    dispatch({ type: "APPEND_CHANGED", payload: changed });
  };
};

/* -------------------- REDUCER -------------------- */
export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case FETCH_PROMO_DEFAULTS_BEGIN:
      return {
        ...state,
        creatingDefaults: true
      };
    case FETCH_PROMO_DEFAULTS_SUCCESS:
      return {
        ...state,
        creatingDefaults: false
      };
    case FETCH_PROMO_OPT_CONFIG_DATA_BEGIN:
      return {
        ...state,

        data: [],
        loadingData: true,
        selectedPromo: action.selectedPromo,
        loadedData: false,
        error: null
      };
    case FETCH_PROMO_OPT_CONFIG_DATA_SUCCESS:
      let newConfig = action.config.map(item => {
        let confItem = item;
        if (item.cellRenderer === "EditableCurrencyCellRenderer") {
          // item.valueFormatter = usCurrencyFormatter;
          item.valueFormatter = usCurrencyWholeDollar;
        }
        if (item.cellRenderer === "editableCellPercentRenderer") {
          item.valueFormatter = percentFormatter;
          item.cellClass = "numeric-cell";
        }
        if (
          item.cellRenderer !== undefined &&
          (item.cellRenderer.includes("editable") ||
            item.cellRenderer.includes("Editable") ||
            item.cellRenderer.includes("PromoSubsidyCellRenderer"))
        ) {
          confItem.editable = function(params) {
            return Math.sign(params.node.data.ID) === 1;
          };
        }

        return confItem;
      });
      return {
        ...state,
        data: action.payload,
        config: newConfig,
        loadedData: true,
        loadingData: false,
        quarter: action.quarter
      };

    case FETCH_PROMO_OPT_CONFIG_DATA_FAILURE:
      return {
        ...state,
        loadingData: false,
        loadedData: false,

        error: action.payload.error
      };

    case FETCH_PROMO_OPT_DATA_BEGIN:
      return {
        ...state,

        promoData: [],
        loadingData: true,
        selectedPromo: action.selectedPromo,
        loadedData: false,
        error: null
      };
    case FETCH_PROMO_OPT_DATA_SUCCESS:
      return {
        ...state,
        promoData: action.payload,
        config: action.config,
        loadedData: true,
        loadingData: false
      };

    case FETCH_PROMO_OPT_DATA_FAILURE:
      return {
        ...state,
        loadingData: false,
        loadedData: false,
        error: action.payload.error
      };
    case FETCH_PROMO_OPT_PROJECT_BEGIN:
      return { ...state, loadingProjects: true, error: null };
    case FETCH_PROMO_OPT_PROJECT_SUCCESS:
      if (state.selectedProject !== null) {
        let sProject = cloneDeep(action.payload);
        sProject = sProject.filter(item => {
          return item.ID === state.selectedProject.ID;
        });
        let selProject = sProject[0];
        if (sProject[0] === undefined) {
          selProject = action.payload[0];
        }
        return {
          ...state,
          promoProjects: action.payload,
          searchProjects: action.payload,
          selectedProject: selProject,
          loadingProjects: false,
          loadedProjects: true,
          alias: {
            tprAlias: selProject.TACTIC_ALIAS_TPRONLY,
            minorAlias: selProject.TACTIC_ALIAS_MINOR,
            majorAlias: selProject.TACTIC_ALIAS_MAJOR,
            otherAlias: selProject.TACTIC_ALIAS_OTHER
          }
        };
      } else if (action.payload.length > 0) {
        let latestLift = null;
        action.payload.forEach((project, pIdx) => {
          project.data.forEach((item, bIdx) => {
            if (item.promoPlans !== undefined) {
              if (latestLift === null) {
                latestLift = item;
                latestLift.projectIndex = pIdx;
                latestLift.idx = bIdx;
              } else if (
                moment(latestLift.liftLastModified, timestampFormat).isBefore(moment(item.liftLastModified, timestampFormat))
              ) {
                latestLift = item;
                latestLift.projectIndex = pIdx;
                latestLift.idx = bIdx;
              }
            }
          });
        });

        if (latestLift === null) {
          latestLift = {};
          latestLift.projectIndex = 0;
        }
        return {
          ...state,
          promoProjects: action.payload,
          searchProjects: action.payload,
          selectedProject: action.payload[latestLift.projectIndex],
          selectedLift: latestLift,
          loadingProjects: false,
          loadedProjects: true,
          alias: {
            tprAlias: action.payload[latestLift.projectIndex].TACTIC_ALIAS_TPRONLY,
            minorAlias: action.payload[latestLift.projectIndex].TACTIC_ALIAS_MINOR,
            majorAlias: action.payload[latestLift.projectIndex].TACTIC_ALIAS_MAJOR,
            otherAlias: action.payload[latestLift.projectIndex].TACTIC_ALIAS_OTHER
          }
        };
      } else {
        return {
          ...state,
          promoProjects: action.payload,
          searchProjects: action.payload,
          loadingProjects: false,
          loadedProjects: true
        };
      }

    case FETCH_PROMO_OPT_PROJECT_FAILURE:
      return {
        ...state,
        loadingProjects: false,
        loadedProjects: false,
        loadingError: true,
        error: action.payload.error
      };

    case SET_SELECTED_PROMO:
      let viewSelected = action.viewSelected;
      let promoId = -1;
      if (action.payload !== {}) {
        //viewSelected = 2;
        promoId = action.payload.id;
      }

      return {
        ...state,
        selectedPromo: action.payload,
        viewSelected,
        promoId
      };
    case SET_VIEW_SELECTED:
      return {
        ...state,
        viewSelected: action.payload
      };

    case SET_SEARCH_PROJECTS:
      return { ...state, searchProjects: action.payload };
    case "SET_OLD_PROJECT_NAME":
      return { ...state, oldProjectName: action.payload };
    case SET_SELECTED_PROJECT_PROMO:
      let latestLift = null;

      action.payload.data.forEach((item, bIdx) => {
        if (item.promoPlans !== undefined) {
          if (latestLift === null) {
            latestLift = item;
            latestLift.idx = bIdx;
          } else if (
            moment(latestLift.liftLastModified, timestampFormat).isBefore(moment(item.liftLastModified, timestampFormat))
          ) {
            latestLift = item;
            latestLift.idx = bIdx;
          }
        }
      });
      return {
        ...state,
        selectedProject: action.payload,
        selectedLift: latestLift,
        alias: {
          tprAlias: action.payload.TACTIC_ALIAS_TPRONLY,
          minorAlias: action.payload.TACTIC_ALIAS_MINOR,
          majorAlias: action.payload.TACTIC_ALIAS_MAJOR,
          otherAlias: action.payload.TACTIC_ALIAS_OTHER
        },
        oldProjectName: action.payload.PROJECT_NAME
      };

    case "SET_EXPAND_DIALOG":
      return {
        ...state,
        dialog: action.payload
      };
    case "SET_UPDATE_FILTER_STATUS":
      return { ...state, updateFilters: action.payload };
    case "SET_CHANGED":
      let dirty = false;
      if (action.payload.length > 0) {
        dirty = true;
      }
      return { ...state, changed: action.payload, dirty };
    case "APPEND_CHANGED":
      let newChanged = cloneDeep(state.changed);
      let changeIdx = -1;

      newChanged.forEach((cItem, idx) => {
        if (action.payload.data.ID === cItem.ID) {
          changeIdx = idx;
        }
      });
      if (changeIdx === -1) {
        newChanged.push(action.payload.data);
      } else {
        newChanged[changeIdx] = action.payload.data;
      }
      return { ...state, changed: newChanged, dirty: true };
    default:
      return state;
  }
};
