import React, { Component } from "react";
import loading from "img/loading.svg";
import axios from "axios";

class Callback extends Component {
  componentDidMount() {
    //window.authContext.handleWindowCallback();

    let sso = localStorage.getItem("sso");
    if (sso === "false") {
      //console.log(this.props);
      let idToken = this.props.location.hash.split("token=")[1];
      // console.log("ID TOKEN RENEWAL " + JSON.stringify(idToken));
      axios.defaults.headers.common["Authorization"] = `Bearer ${idToken}`;
    }
  }
  render() {
    const style = {
      display: "flex",
      justifyContent: "center",
      height: "100px",
      width: "100px",
      marginLeft: "auto",
      marginRight: "auto",
      marginTop: "300px",
      backgroundColor: "white"
    };

    return (
      <div style={style}>
        <img src={loading} alt="loading" />
      </div>
    );
  }
}

export default Callback;
