import React, { Component } from "react";
import { connect } from "react-redux";

import { toast } from "react-toastify";
import axios from "axios";
import isEmail from "validator/lib/isEmail";
import "./style.css";

const tableContainerStyle = {
  maxHeight: "200px",
  overflowY: "auto"
};
const tableStyle = {
  borderCollapse: "collapse",
  width: "100%"
};
const cellStyle = {
  padding: "5px"
  // height: "30px"
  // border: "1px solid #ccc"
};
class NoAuthUpload extends Component {
  constructor(props) {
    super(props);
    this.state = {
      uploadedFiles: [],
      email: "",
      emailError: "",
      message: "",
      subjectMsg: "",
      uploadingFile: false,
      uploadDone: false,
      uploadSuccess: false
    };
    this.renderUploadedFiles = this.renderUploadedFiles.bind(this);
    this.handleselectedFile = this.handleselectedFile.bind(this);
    this.handleEmailChange = this.handleEmailChange.bind(this);
  }
  componentDidMount() {}

  componentDidUpdate(prevProps, prevState) {
    if (JSON.stringify(this.state.uploadedFiles) !== JSON.stringify(prevState.uploadedFiles)) {
      this.setState({ uploadingFile: false, uploadSuccess: false });
    }
  }

  handleEmailChange = event => {
    const email = event.target.value;
    if (isEmail(email)) {
      this.setState({ email, emailError: "" });
    } else {
      this.setState({ email, emailError: "This looks like an incomplete email address." });
    }
  };
  handleselectedFile = event => {
    // const { uploadFilepath } = this.state;
    event.preventDefault();

    //var data = new FormData();
    const filesArray = Array.from(event.target.files);
    let subjectMsg = "File Uploaded to {inbox_name}";

    let message =
      this.state.email +
      " uploaded the following files to the '{inbox_name}' upload inbox. " +
      filesArray
        .map(item => {
          return `<div>{inbox_name}/${this.state.email.replace(/@/, "_").replace(/\./g, "_")}/${item.name}</div><br/>`;
        })
        .join("");

    const newFiles = Array.from(event.target.files).map(file => {
      return { file, progress: 0, status: "", _id: "", name: file.name };
    });

    this.setState({
      subject: subjectMsg,
      message,
      uploadedFiles: newFiles
    });
  };

  uploadFiles() {
    const { uploadedFiles } = this.state;
    const allowedFileTypes = [
      "xlsb",
      "jpg",
      "png",
      "pptx",
      "ppt",
      "pdf",
      "zip",
      "xlsx",
      "txt",
      "bmp",
      "mp4",
      "docx",
      "xlsm",
      "csv",
      "gif",
      "jpeg",
      "xls",
      "pbix"
    ];
    const inboxId = this.props.history.location.hash.split("#")[1];
    if (uploadedFiles !== null && uploadedFiles.length > 0 && inboxId !== undefined && inboxId !== null) {
      this.setState({ uploadingFile: true, uploadDone: false });
      for (const [index, fileData] of uploadedFiles.entries()) {
        const fileExtension = fileData.file.name.split(".").pop();

        // Check if file type is allowed before uploading
        if (!allowedFileTypes.includes(fileExtension.toLowerCase())) {
          toast.error("Files of this type may not be uploaded");
          continue;
        }

        var data = new FormData();
        data.append("file", fileData.file);
        data.append("email", this.state.email);
        data.append("fileName", encodeURIComponent(fileData.file.name));
        data.append("uploadInboxId", this.props.history.location.hash.split("#")[1]);

        axios
          .post(`${process.env.REACT_APP_BIGHORN_SERVER}/api/files/uploadFileNoAuth`, data, {
            headers: {
              "Content-Type": fileData.file.type
              // ClientToken: localStorage.getItem("clientToken")
            },
            onUploadProgress: progressEvent => {
              const progress = Math.round((progressEvent.loaded / progressEvent.total) * 100);
              // this.setState({ progress });
              // Update progress for this file.
              this.setState(
                prevState => {
                  const newUploadFiles = [...prevState.uploadedFiles];
                  newUploadFiles[index].progress = progress;
                  return { uploadedFiles: newUploadFiles };
                },
                async () => {
                  const allFilesUploaded = this.state.uploadedFiles.every(file => file.progress === 100);
                  if (allFilesUploaded) {
                    // Perform the desired action
                    // console.log("All files uploaded");
                    function sleep(ms) {
                      return new Promise(resolve => setTimeout(resolve, ms));
                    }
                    await sleep(2000);

                    this.setState({ uploadDone: true, uploadFile: false });
                    axios
                      .post(
                        `${process.env.REACT_APP_BIGHORN_SERVER}/api/files/emailUsersNoAuth`,
                        {
                          message: this.state.message,
                          subject: this.state.subject,
                          email: this.state.email,
                          uploadInboxId: this.props.history.location.hash.split("#")[1]
                        },

                        {
                          // headers: {
                          //   ClientToken: localStorage.getItem("clientToken")
                          // }
                        }
                      )
                      .then(res => {
                        toast.success("File(s) Uploaded");
                        this.setState({ uploadSuccess: true });
                      })
                      .catch(err => {
                        toast.error("Error Uploading");
                      });
                  }
                }
              );
            }
          })
          .then(async res => {
            // console.log(res);
            if (!res.data.success) {
              toast.error(res.data.message);
            } else {
              function sleep(ms) {
                return new Promise(resolve => setTimeout(resolve, ms));
              }
              await sleep(1000);
              let runEmail = false;
              let newUploadFiles = [];
              for (var item of this.state.uploadedFiles) {
                if (item.progress !== 100) {
                  runEmail = true;
                  item.progress = 100;
                }
                newUploadFiles.push(item);
              }
              this.setState({ uploadDone: true, uploadFile: false, uploadFiles: newUploadFiles });
              if (runEmail) {
                axios
                  .post(
                    `${process.env.REACT_APP_BIGHORN_SERVER}/api/files/emailUsersNoAuth`,
                    {
                      message: this.state.message,
                      subject: this.state.subject,
                      email: this.state.email,
                      uploadInboxId: this.props.history.location.hash.split("#")[1]
                    },
                    {
                      // headers: {
                      //   ClientToken: localStorage.getItem("clientToken")
                      // }
                    }
                  )
                  .then(res => {
                    toast.success("File(s) Uploaded");
                    this.setState({ uploadSuccess: true });
                  })
                  .catch(err => {
                    toast.error("Error Uploading");
                  });
              }
            }
          });
      }
    } else {
      toast.error("Invalid Inbox");
    }
  }

  renderUploadedFiles() {
    return this.state.uploadedFiles.map(item => {
      return (
        <tr>
          <td style={cellStyle}>{item.name}</td>
          {/* <td style={cellStyle}>{item.progress}</td> */}
          <td className="progress-cell" style={cellStyle}>
            <div className="progress-container">
              <div className="progress-filler" style={{ width: `${item.progress}%` }}></div>
            </div>
            <span className="progress-text">{`${item.progress}%`}</span>
          </td>
        </tr>
      );
    });
  }
  render() {
    if (this.props.error !== null) {
      return (
        <div style={{ display: "flex", justifyContent: "center", marginTop: "50px" }}>
          <div>This upload inbox does not exist.</div>
        </div>
      );
    } else {
      return (
        <div className="no-auth-upload">
          <div style={{ fontSize: "24px", color: "#707070", fontWeight: 500, marginBottom: "10px" }}>
            TABS Analytics File Upload Service
          </div>

          <div>Your Email Address</div>
          <input
            style={{ width: "400px", marginTop: "10px" }}
            value={this.state.email}
            onChange={this.handleEmailChange}></input>
          {this.state.emailError && <div style={{ color: "red" }}>{this.state.emailError}</div>}
          <div className="" style={{ fontWeight: 500, marginTop: "20px" }}>
            Upload Files
          </div>
          <div style={{ fontStyle: "italic", color: "#707070", marginBottom: "10px" }}>
            Select one or more files and click Upload to send your files to TABS Analytics
          </div>
          <div>
            <input
              //hidden={true}
              id="files"
              ref
              type="file"
              name="Upload"
              multiple
              onChange={this.handleselectedFile}
              onClick={event => {
                event.target.value = null;
              }}
            />
            <div style={{ fontSize: "12px", marginTop: "8px", fontStyle: "italic" }}>
              Allowed file types: xlsx, xls, xlsm, xlsb, pdf, docx, pptx, ppt, csv, png, jpg, gif, bmp, txt, mp4, zip,pbix
            </div>

            <div style={tableContainerStyle}>
              <table style={tableStyle}>
                <thead>
                  <tr>
                    <th style={cellStyle}>Filename</th>
                    <th style={cellStyle}>Upload %</th>
                  </tr>
                </thead>
                <tbody>{this.renderUploadedFiles()}</tbody>
              </table>
            </div>
            <div
              style={{
                cursor:
                  this.state.emailError ||
                  this.state.email === "" ||
                  this.state.uploadedFiles.length === 0 ||
                  this.state.uploadingFile
                    ? "not-allowed"
                    : "pointer"
              }}>
              <div
                onClick={() => {
                  if (!this.state.emailError && this.state.email !== "" && this.state.uploadedFiles.length > 0) {
                    this.uploadFiles();
                  }
                }}
                className={`ta-button ta-button-primary ${
                  this.state.emailError ||
                  this.state.email === "" ||
                  this.state.uploadedFiles.length === 0 ||
                  this.state.uploadingFile
                    ? "disabled"
                    : ""
                }`}
                style={{
                  height: "26px",
                  width: "72px",
                  textAlign: "center",
                  pointerEvents:
                    this.state.emailError ||
                    this.state.email === "" ||
                    this.state.uploadedFiles.length === 0 ||
                    this.state.uploadingFile
                      ? "none"
                      : "auto"
                }}>
                Upload
              </div>
            </div>
            {this.state.uploadSuccess && (
              <div style={{ fontSize: "18px", fontWeight: 500, marginTop: "20px", color: "#07bc0c" }}>
                Your upload completed successfully, you can close this window.
              </div>
            )}
          </div>
        </div>
      );
    }
  }
}

const mapStateToProps = state => ({
  inboxId: state.app.inboxId,
  error: state.app.error
  // reportSubLoading: state.rptSubscriptions.loading
});

const mapDispatchToProps = dispatch => ({
  // addTabToNav(tab) {
  //   dispatch(addTabToNav(tab));
  // },
  // getReportSubscriptions(loading, user) {
  //   dispatch(getReportSubscriptions(loading, null, user));
  // },
  // deleteReportSubscription(rptSubId, user) {
  //   dispatch(deleteReportSubscription(rptSubId, null, user));
  // },
  // selectAllSubscriptions(selectAll) {
  //   dispatch(selectAllSubscriptions(selectAll));
  // }
});

export default connect(mapStateToProps, mapDispatchToProps)(NoAuthUpload);
