import axios from "axios";
// import { cloneDeep } from "lodash";

/* -------------------- INITIAL STATE -------------------- */
const INITIAL_STATE = {
  reportSubscriptions: [],
  searchUsers: [],
  loading: true,
  error: null
};

/* -------------------- ACTION TYPES -------------------- */
const FETCH_RPT_SUBS_BEGIN = "FETCH_RPT_SUBS_BEGIN";
const FETCH_RPT_SUBS_SUCCESS = "FETCH_RPT_SUBS_SUCCESS";
const FETCH_RPT_SUBS_FAILURE = "FETCH_RPT_SUBS_FAILURE";
const SET_SELECT_ALL = "SET_SELECT_ALL";
const SET_SELECTED_VALUE = "SET_SELECTED_VALUE";

/* -------------------- ACTION CREATORS -------------------- */

const fetchReportSubscriptionsBegin = () => ({
  type: FETCH_RPT_SUBS_BEGIN
});
const fetchReportSubscriptionsSuccess = users => {
  return { type: FETCH_RPT_SUBS_SUCCESS, payload: users };
};
const fetchReportSubscriptionsError = error => ({
  type: FETCH_RPT_SUBS_FAILURE,
  payload: { error }
});

/* -------------------- ASYNC ACTION CREATORS -------------------- */

export const getReportSubscriptions = (loading = true, reportId = null, user = null) => {
  return dispatch => {
    if (loading) dispatch(fetchReportSubscriptionsBegin());
    axios
      .post(
        `${process.env.REACT_APP_BIGHORN_SERVER}/api/powerBI/getReportSubscriptions`,
        {
          reportId,
          user
          // client: client
          //idToken: localStorage.getItem("id_token")
        },
        {
          headers: {
            //Authorization: "Bearer " + localStorage.getItem("access_token")
            ClientToken: localStorage.getItem("clientToken")
          }
        }
      )
      .then(response => {
        dispatch(fetchReportSubscriptionsSuccess(response.data.subscriptions));
      })
      .catch(error => dispatch(fetchReportSubscriptionsError(error)));
  };
};

export const saveReportSubscription = (rptSub, update = false, reportId = null, user = null) => {
  return dispatch => {
    // dispatch(fetchReportSubscriptionsBegin());
    if (update) {
      axios
        .post(
          `${process.env.REACT_APP_BIGHORN_SERVER}/api/powerBI/updateReportSubscription`,
          {
            rptSub
            // client: client
            //idToken: localStorage.getItem("id_token")
          },
          {
            headers: {
              //Authorization: "Bearer " + localStorage.getItem("access_token")
              ClientToken: localStorage.getItem("clientToken")
            }
          }
        )
        .then(response => {
          console.log("Saved");

          dispatch(getReportSubscriptions(false, reportId, user));

          // dispatch(fetchReportSubscriptionsSuccess(response.data.subscriptions));
        })
        .catch(error => dispatch(fetchReportSubscriptionsError(error)));
    } else {
      axios
        .post(
          `${process.env.REACT_APP_BIGHORN_SERVER}/api/powerBI/saveReportSubscription`,
          {
            rptSub
            // client: client
            //idToken: localStorage.getItem("id_token")
          },
          {
            headers: {
              //Authorization: "Bearer " + localStorage.getItem("access_token")
              ClientToken: localStorage.getItem("clientToken")
            }
          }
        )
        .then(response => {
          console.log("Saved");
          dispatch(getReportSubscriptions(false, reportId, user));
          // dispatch(fetchReportSubscriptionsSuccess(response.data.subscriptions));
        })
        .catch(error => dispatch(fetchReportSubscriptionsError(error)));
    }
  };
};
export const deleteReportSubscription = (rptSubId, reportId = null, user = null) => {
  return dispatch => {
    // dispatch(fetchReportSubscriptionsBegin());

    axios
      .post(
        `${process.env.REACT_APP_BIGHORN_SERVER}/api/powerBI/deleteReportSubscription`,
        {
          rptSubId
          // client: client
          //idToken: localStorage.getItem("id_token")
        },
        {
          headers: {
            //Authorization: "Bearer " + localStorage.getItem("access_token")
            ClientToken: localStorage.getItem("clientToken")
          }
        }
      )
      .then(response => {
        console.log("Deleted");
        dispatch(getReportSubscriptions(false, reportId, user));
        // dispatch(fetchReportSubscriptionsSuccess(response.data.subscriptions));
      })
      .catch(error => dispatch(fetchReportSubscriptionsError(error)));
  };
};
export const selectAllSubscriptions = selectAll => {
  return dispatch => {
    dispatch({ type: SET_SELECT_ALL, payload: selectAll });
  };
};
export const selectSubscription = (subId, select) => {
  return dispatch => {
    dispatch({ type: SET_SELECTED_VALUE, payload: select, subId });
  };
};

/* -------------------- REDUCER -------------------- */
export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case FETCH_RPT_SUBS_BEGIN:
      return { ...state, loading: true, error: null };
    case FETCH_RPT_SUBS_SUCCESS:
      return {
        ...state,
        users: action.payload,
        reportSubscriptions: action.payload,
        loading: false
      };

    case FETCH_RPT_SUBS_FAILURE:
      return { ...state, loading: false, error: action.payload.error };
    case SET_SELECTED_VALUE:
      let selectRptSubs = state.reportSubscriptions;

      selectRptSubs = selectRptSubs.map(item => {
        if (item._id === action.subId) {
          item.selected = action.payload;
        }
        return item;
      });

      return { ...state, reportSubscriptions: selectRptSubs };
    case SET_SELECT_ALL:
      let newRptSubs = state.reportSubscriptions;

      newRptSubs = newRptSubs.map(item => {
        item.selected = action.payload;
        return item;
      });

      return { ...state, reportSubscriptions: newRptSubs };

    default:
      return state;
  }
};
