export function geoComparator(item1, item2, node1, node2) {
  if (node1 === undefined) {
    return item1;
  }
  var a = node1.data.fgeo;
  var b = node2.data.fgeo;
  return a > b ? 1 : a < b ? -1 : 0;
}
export function brandSizeComparator(item1, item2, node1, node2) {
  if (node1 === undefined) {
    return item1;
  }
  var a = node1.data.fbs;
  var b = node2.data.fbs;
  return a > b ? 1 : a < b ? -1 : 0;
}

export function retailComparator(item1, item2, node1, node2) {
  if (node1 === undefined) {
    return item1;
  }
  var a = node1.data.fretail;
  var b = node2.data.fretail;
  return a > b ? 1 : a < b ? -1 : 0;
}
export function baseItemComparator(item1, item2, node1, node2) {
  if (node1 === undefined) {
    return item1;
  }
  var a = node1.data.fbi;
  var b = node2.data.fbi;
  return a > b ? 1 : a < b ? -1 : 0;
}
export function itemDescComparator(item1, item2, node1, node2) {
  if (node1 === undefined) {
    return item1;
  }
  var a = node1.data.fidesc;
  var b = node2.data.fidesc;
  return a > b ? 1 : a < b ? -1 : 0;
}
export function prodKeyComparator(item1, item2, node1, node2) {
  if (node1 === undefined) {
    return item1;
  }
  var a = node1.data.fpk;
  var b = node2.data.fpk;
  return a > b ? 1 : a < b ? -1 : 0;
}
