import React, { Component } from "react";
import {
  POS_UNITS,
  SHIP_UNITS,
  CUME_OVER_UNDER,
  INCREMENTAL_LIFT_UNITS,
  PROMO_STORES
} from "../constants";

export class EditableCellRenderer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      value: props.value,
      type: props.data.type
    };
  }

  // noinspection JSUnusedGlobalSymbols
  refresh(params) {
    if (params.value !== this.state.value) {
      this.setState({
        value: params.value
      });
    }
    return true;
  }

  render() {
    let { value, type } = this.state;
    if(Math.sign(this.props.data.id)===-1 ){
      return <div></div>;
    }else{
      let returned;
      if (
        type === CUME_OVER_UNDER ||
      type === INCREMENTAL_LIFT_UNITS ||
      type === POS_UNITS ||
      type === SHIP_UNITS
      ) {
        returned = <div style={{}}>{value}</div>;
      } else if (type === PROMO_STORES) {
        var row = parseInt(this.props.node.id, 10);
        var currentCol = this.props.colDef.field;
        var storeCount = this.props.api.getRowNode(row - 2).data[currentCol];
        returned = (
          <div
            style={{
              justifyContent: "center",
              display: "flex",
              color: storeCount < value ? "red" : "rgba(88, 89, 91, 1)"
            }}
          >
            <div
              style={{
                width: "44px",
                height: "25px",
                borderBottom: "1px solid rgba(112, 112, 112, 1)"
              }}
            >
              {value}
            </div>
          </div>
        );
      } else {
        returned = (
          <div style={{ justifyContent: "center", display: "flex" }}>
            <div
              style={{
                width: "44px",
                height: "25px",
                borderBottom: "1px solid rgba(112, 112, 112, 1)"
              }}
            >
              {value}
            </div>
          </div>
        );
      }

      return <div>{returned}</div>;
    }
  }
}
