import React, { Component } from "react";

// Declaration of the component as React Class Component
class PercChangeModal extends Component {
  // Init of the component before it is mounted.
  constructor(props) {
    super(props);
    this.state = {
      value: "",
      percChange: 0
    };
    this.handleKeyUp = this.handleKeyUp.bind(this);
    this.handleChange = this.handleChange.bind(this);
  }

  // Add listeners immediately after the component is mounted.
  componentDidMount() {
    window.addEventListener("keyup", this.handleKeyUp, false);
  }

  // Remove listeners immediately before a component is unmounted and destroyed.
  componentWillUnmount() {
    window.removeEventListener("keyup", this.handleKeyUp, false);
  }

  // Handle the key press event.
  handleKeyUp(e) {
    const { onCloseRequest, onOkRequest } = this.props;
    const keys = {
      27: () => {
        e.preventDefault();
        onCloseRequest();
        window.removeEventListener("keyup", this.handleKeyUp, false);
      },
      13: () => {
        e.preventDefault();
        onOkRequest(this.state.value);
      }
    };

    if (keys[e.keyCode]) {
      keys[e.keyCode]();
    }
  }

  handleChange(event) {
    this.setState({ value: event.target.value });
  }

  // Render the component passing onCloseRequest and children as props.
  render() {
    const { onCloseRequest, onOkRequest } = this.props;

    return (
      <div className="ta-modal-overlay">
        <div className={"ta-modal"} ref={node => (this.modal = node)}>
          <div className={"ta-modal-content"}>
            <div className="ta-modal-title">Adjust Selected By %</div>
            <input
              type="text"
              value={this.state.value}
              onChange={this.handleChange}
            />
            <div className="ta-modal-buttons">
              <button
                type="button"
                className={"ta-modal-ok-button"}
                onClick={() => onOkRequest(this.state.value)}
              >
                OK
              </button>
              <button
                type="button"
                className={"ta-close-button"}
                onClick={onCloseRequest}
              >
                CANCEL
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

// Export the component to use it in other components.
export default PercChangeModal;
