import axios from "axios";
import { cloneDeep } from "lodash";

/* -------------------- INITIAL STATE -------------------- */
const INITIAL_STATE = {
  loading: false,
  error: null,
  files: [],
  searchFiles: [],
  unflattenedFiles: [],
  unflattenedsearchFiles: [],
  baseFiles: [],
  sasToken: "",
  editFilePath: "",
  editFileTreeIndex: -1,
  creatingFiles: false
};

/* -------------------- ACTION TYPES -------------------- */
const FETCH_FILE_LIST_BEGIN = "FETCH_FILE_LIST_BEGIN";
const FETCH_FILE_LIST_SUCCESS = "FETCH_FILE_LIST_SUCCESS";
const FETCH_FILE_LIST_FAILURE = "FETCH_FILE_LIST_FAILURE";
const SET_SEARCH_FILES = "SET_SEARCH_FILES";
const SET_EDIT_FILE = "SET_EDIT_FILE";
const FETCH_CREATE_FILES_BEGIN = "FETCH_CREATE_FILES_BEGIN";
const FETCH_CREATE_FILES_END = "FETCH_CREATE_FILES_END";
const FETCH_UNFLATTENED_FILE_LIST_SUCCESS = "FETCH_UNFLATTENED_FILE_LIST_SUCCESS";
const SET_UNFLATTENED_FILE_LIST = "SET_UNFLATTENED_FILE_LIST";
//const DELETE_FILE_SUCCESS = "DELETE_FILE_SUCCESS";

/* -------------------- ACTION CREATORS -------------------- */

const fetchFileListBegin = () => ({
  type: FETCH_FILE_LIST_BEGIN
});
const fetchFileListSuccess = files => {
  return { type: FETCH_FILE_LIST_SUCCESS, payload: files };
};
const fetchUnFlattenedFileListSuccess = files => {
  return { type: FETCH_UNFLATTENED_FILE_LIST_SUCCESS, payload: files };
};
const setSearchFiles = files => {
  return { type: SET_SEARCH_FILES, payload: files };
};

const fetchFileListError = error => ({
  type: FETCH_FILE_LIST_FAILURE,
  payload: { error }
});

const doSetEditFile = (path, ti) => ({
  type: SET_EDIT_FILE,
  payload: path,
  ti
});
const fetchCreateFilesBegin = () => ({
  type: FETCH_CREATE_FILES_BEGIN
});
const fetchCreateFilesEnd = () => ({
  type: FETCH_CREATE_FILES_END
});
const doSetUnflattenedFileList = (files, search = false) => ({
  type: SET_UNFLATTENED_FILE_LIST,
  payload: files,
  search
});

function searchFilter(newFolders, filter, modal) {
  return newFolders.map(newFolder => {
    if (newFolder.children === undefined) {
      if (newFolder.type === "file") {
        return null;
      } else {
        newFolder.reports = newFolder.reports.filter(report => {
          return newFolder.name.toLowerCase().indexOf(filter.toLowerCase()) !== -1;
        });
      }
    } else {
      newFolder.children = newFolder.children.filter((folder, index) => {
        if (folder.children === undefined && folder.type === "folder") {
          return folder.name.toLowerCase().indexOf(filter.toLowerCase()) !== -1;
        } else if (folder.children === undefined) {
          return null;
        } else {
          folder.children = folder.children.filter(child => {
            if (child.fileType === "folder") {
              if (modal) {
                return child.name.toLowerCase().indexOf(filter.toLowerCase()) !== -1;
              } else {
                child.children = child.children.filter(gChild => {
                  return gChild.name.toLowerCase().indexOf(filter.toLowerCase()) !== -1;
                });
                if (child.children.length > 0) {
                  return true;
                } else {
                  return false;
                }
              }
            } else {
              return child.name.toLowerCase().indexOf(filter.toLowerCase()) !== -1;
            }
          });
          if (folder.children.length > 0 || folder.name.toLowerCase().indexOf(filter.toLowerCase()) !== -1) {
            return folder;
          } else {
            return null;
          }
        }
      });
    }
    if (newFolder.children.length === 0 && newFolder.name.toLowerCase().indexOf(filter.toLowerCase()) === -1) {
      return null;
    }
    return newFolder;
  });
}
function sleep(ms) {
  return new Promise(resolve => setTimeout(resolve, ms));
}
/* -------------------- ASYNC ACTION CREATORS -------------------- */
export const getFileList = (flattened = false) => {
  return dispatch => {
    dispatch(fetchFileListBegin());
    axios
      .post(
        `${process.env.REACT_APP_BIGHORN_SERVER}/api/files/listFilesinContainer`,
        {
          flattened
        },
        {
          headers: {
            ClientToken: localStorage.getItem("clientToken")
          }
        }
      )
      .then(response => {
        console.log("FETCH SUCCESS");
        if (response.data.response.length <= 0) {
          dispatch(fetchFileListError("There are no files in this client"));
        } else {
          dispatch(fetchFileListSuccess(response.data.response));
          axios
            .post(
              `${process.env.REACT_APP_BIGHORN_SERVER}/api/files/listFilesinContainer`,
              {
                flattened: false
              },
              {
                headers: {
                  ClientToken: localStorage.getItem("clientToken")
                }
              }
            )
            .then(response => {
              dispatch(fetchUnFlattenedFileListSuccess(response.data.response));
            });
        }
      })
      .catch(async error => {
        console.log("FETCH ERROR Trying again");
        // dispatch(fetchFileListError(error));
        await sleep(2000);
        axios
          .post(
            `${process.env.REACT_APP_BIGHORN_SERVER}/api/files/listFilesinContainer`,
            {
              flattened
            },
            {
              headers: {
                ClientToken: localStorage.getItem("clientToken")
              }
            }
          )
          .then(response => {
            console.log("FETCH SUCCESS");
            if (response.data.response.length <= 0) {
              dispatch(fetchFileListError("There are no files in this client"));
            } else {
              dispatch(fetchFileListSuccess(response.data.response));
              axios
                .post(
                  `${process.env.REACT_APP_BIGHORN_SERVER}/api/files/listFilesinContainer`,
                  {
                    flattened: false
                  },
                  {
                    headers: {
                      ClientToken: localStorage.getItem("clientToken")
                    }
                  }
                )
                .then(response => {
                  dispatch(fetchUnFlattenedFileListSuccess(response.data.response));
                });
            }
          })
          .catch(error => {
            dispatch(fetchFileListError(error));
          });
      });
  };
};
export const getFileListNoLoadingImage = (flattened = false, getUnflatted = true) => {
  return dispatch => {
    axios
      .post(
        `${process.env.REACT_APP_BIGHORN_SERVER}/api/files/listFilesinContainer`,
        {
          flattened
        },
        {
          headers: {
            ClientToken: localStorage.getItem("clientToken")
          }
        }
      )
      .then(response => {
        dispatch(fetchFileListSuccess(response.data.response));
        if (getUnflatted) {
          axios
            .post(
              `${process.env.REACT_APP_BIGHORN_SERVER}/api/files/listFilesinContainer`,
              {
                flattened: false
              },
              {
                headers: {
                  ClientToken: localStorage.getItem("clientToken")
                }
              }
            )
            .then(response => {
              dispatch(fetchUnFlattenedFileListSuccess(response.data.response));
            });
        }
      })
      .catch(error => {
        //dispatch(fetchFileListError(error));
      });
  };
};
export const setFileList = files => {
  return dispatch => {
    //dispatch(fetchFileListBegin());
    dispatch(setSearchFiles(files.treeData));
  };
};
export const setUnflattenedFileList = (files, search = false) => {
  return dispatch => {
    dispatch(doSetUnflattenedFileList(files, search));
  };
};
export const updateFolderStatus = folder => {
  return dispatch => {
    axios
      .post(
        `${process.env.REACT_APP_BIGHORN_SERVER}/api/files/updateFolderStatus`,
        { folder },
        {
          headers: {
            ClientToken: localStorage.getItem("clientToken")
          }
        }
      )
      .then(response => {});
  };
};
export const renameFile = (srcUri, target, folder = false) => {
  return dispatch => {
    axios
      .post(
        `${process.env.REACT_APP_BIGHORN_SERVER}/api/files/renameFile`,
        {
          srcUri,
          target,
          isFolder: folder
        },
        {
          headers: {
            ClientToken: localStorage.getItem("clientToken")
          }
        }
      )
      .then(response => {
        console.log(response);
        dispatch(getFileListNoLoadingImage(true));
      });
  };
};

export const deleteFile = (srcUri, isFolder = false) => {
  return dispatch => {
    axios
      .post(
        `${process.env.REACT_APP_BIGHORN_SERVER}/api/files/deleteFile`,
        {
          srcUri,
          isFolder
        },
        {
          headers: {
            ClientToken: localStorage.getItem("clientToken")
          }
        }
      )
      .then(response => {
        console.log(response);
        dispatch(getFileListNoLoadingImage(true));
      });
  };
};
export const setEditFile = (path, treeIndex) => {
  return dispatch => {
    dispatch(doSetEditFile(path, treeIndex));
  };
};
export const filterFiles = (filter, files) => {
  return dispatch => {
    if (filter === "") {
      dispatch(setSearchFiles(files));
    } else {
      let newFiles = cloneDeep(files);
      let nFiles = [];

      newFiles.forEach((newFile, newFileIdx) => {
        if (newFile.children === undefined) {
          if (newFile.name.toLowerCase().indexOf(filter.toLowerCase()) !== -1) return nFiles.push(newFile);
        } else {
          newFile.children = newFile.children.filter((file, index) => {
            if (file.children === undefined) {
              return file.name.toLowerCase().indexOf(filter.toLowerCase()) !== -1;
            } else {
              return true;
            }
          });
          newFile.children.forEach((file, index) => {
            if (file.children !== undefined) {
              let tempFiles = cloneDeep(file);
              file.children = [];
              tempFiles.children.forEach((cFile, cIndex) => {
                if (cFile.name.toLowerCase().indexOf(filter.toLowerCase()) !== -1) {
                  newFile.children[index].children.push(cFile);
                }
              });
            }
          });
          nFiles.push(newFile);
        }
      });
      dispatch(setSearchFiles(nFiles));
    }
  };
};
export const createFilesFolder = (folder, oldPath = "", getUnflattened = true) => {
  return dispatch => {
    dispatch(fetchCreateFilesBegin());
    axios
      .post(
        `${process.env.REACT_APP_BIGHORN_SERVER}/api/files/createFilesFolder`,
        {
          folder,
          oldPath
        },
        {
          headers: {
            ClientToken: localStorage.getItem("clientToken")
          }
        }
      )
      .then(response => {
        dispatch(getFileListNoLoadingImage(true, getUnflattened));
        dispatch(fetchCreateFilesEnd());
      });
  };
};
export const filterFolders = (filter, folders, modal = false) => {
  return dispatch => {
    if (filter === "") {
      if (modal) {
        dispatch(setUnflattenedFileList(folders, true));
      } else {
        dispatch(setFileList(folders));
      }
    } else {
      let newFolders = cloneDeep(folders);
      if (modal) {
        const newUnflattened = searchFilter(newFolders, filter, true);
        dispatch(setUnflattenedFileList(newUnflattened, true));
        // dispatch(setUnflattenedFileList(searchFilter(newFolders, filter, true)));
      } else {
        dispatch(setFileList(searchFilter(newFolders, filter, false)));
      }
    }
  };
};

// export const deleteReport = (groupId, reportId) => {
//   return dispatch => {
//     axios
//       .post(
//         `${process.env.REACT_APP_BIGHORN_SERVER}/api/powerBI/deleteReport`,
//         {
//           groupId,
//           reportId
//         },
//         {
//           headers: {
//             Authorization: "Bearer " + localStorage.getItem("access_token")
//           }
//         }
//       )
//       .then(response => {
//         dispatch(deleteReportSuccess(response.data.response));
//       });
//   };
// };

/* -------------------- REDUCER -------------------- */
function isObject(value) {
  return value && typeof value === "object" && value.constructor === Object;
}
export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case FETCH_FILE_LIST_BEGIN:
      return { ...state, loading: true, error: null };
    case FETCH_UNFLATTENED_FILE_LIST_SUCCESS:
      return { ...state, unflattenedFiles: action.payload, unflattenedSearchFiles: action.payload };
    case FETCH_FILE_LIST_SUCCESS:
      // console.log(JSON.stringify(action.payload));

      return {
        ...state,
        files: action.payload,
        searchFiles: action.payload,
        baseFiles: action.payload,
        uploadSearchFiles: action.payload,
        loading: false
      };
    case FETCH_FILE_LIST_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
        unflattenedSearchFiles: [],
        unflattenedFiles: [],
        reportConfig: []
      };
    case SET_SEARCH_FILES:
      return {
        ...state,
        searchFiles: action.payload
      };

    case SET_EDIT_FILE:
      return {
        ...state,
        editFilePath: action.payload,
        editFileTreeIndex: action.ti
      };
    case FETCH_CREATE_FILES_BEGIN:
      return { ...state, creatingFiles: true };
    case FETCH_CREATE_FILES_END:
      const nUnFlattenedFiles = state.unflattenedFiles.map(item => {
        item.editing = false;

        if (isObject(item.name)) {
          item.name = item.name.current.value;
        }
        item.path = item.name + "/";
        item.title = item.name;
        return item;
      });

      return {
        ...state,
        creatingFiles: false,
        editFilePath: "",
        editFileTreeIndex: -1,
        unflattenedFiles: nUnFlattenedFiles,
        unflattenedSearchFiles: nUnFlattenedFiles
      };
    case SET_UNFLATTENED_FILE_LIST:
      if (action.search) {
        return { ...state, unflattenedSearchFiles: action.payload };
      } else {
        return { ...state, unflattenedFiles: action.payload, unflattenedSearchFiles: action.payload };
      }
    default:
      return state;
  }
};
