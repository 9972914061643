import React, { Component } from "react";
import { connect } from "react-redux";

import {
  setViewSelected,
  getPromoOptConfigData,
  getProjects,
  renamePromo,
  selectPromo
} from "components/promoOpt/reducers/promoOptReducer";
import ReactDOM from "react-dom";
import { Col } from "react-flexbox-grid";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import {
  Gear,
  //LiftModelIcon,
  DeleteIcon,
  Pen,
  DownloadIcon,
  FinalBaseline,
  EditPromoIcon,
  PromoPlanIcon
} from "lib/icons";
import { DotLoader } from "react-spinners";

import { toast } from "react-toastify";
import { save } from "save-file";
import { extractFileName } from "lib/lib";
import axios from "axios";
import moment from "moment";
import { OverlayTrigger, Tooltip } from "react-bootstrap";

//Use this to give moment the correct format so it doesn't throw warnings
//Our timestamps look like this: 2020-04-06 14:31:03.000 -0700
const timestampFormat = "YYYY-MM-DD HH:mm:ss.SSS ZZ";

class PromoCard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      manageOpened: false,
      editPromo: false,
      showDeletePromoConfirmModal: false,
      deletingPromo: false,
      showCannotDeleteModal: false
    };
    this.reviewPromoModel = this.reviewPromoModel.bind(this);
    this.manageClickHandler = this.manageClickHandler.bind(this);
    this.Manage = React.createRef();
    this.PromoNameInput = React.createRef();
    this.handleClose = this.handleClose.bind(this);
    this.handleClickOutside = this.handleClickOutside.bind(this);
  }
  componentWillUnmount() {
    document.removeEventListener("mousedown", this.handleClickOutside);
  }
  handleClickOutside(event) {
    const area = ReactDOM.findDOMNode(this.Manage.current);
    if (area !== null) {
      if (!area.parentElement.contains(event.target)) {
        this.setState({ manageOpened: false });
      }
    }
  }
  componentDidMount() {
    document.addEventListener("mousedown", this.handleClickOutside);
  }
  reviewPromoModel(promo) {
    this.props.selectPromo(promo, 2);
  }
  manageClickHandler() {
    this.setState({ manageOpened: !this.state.manageOpened });
  }
  renamePromo(promo, name) {
    this.props.renamePromo(promo, name, this.props.baselineId);
  }
  keyDownHandler(event) {
    if (event.key === "Enter") {
      this.props.renamePromo(this.props.promo, this.PromoNameInput.current.value, this.props.promo.batchId);

      this.setState({
        editPromo: false
      });
    } else if (event.key === "Escape") {
      this.setState({ editPromo: false });
    }
  }
  deleteHandler(type) {
    if (type === "promo") {
      this.setState({ deletingPromo: true });
      axios
        .get(`${process.env.REACT_APP_BIGHORN_SERVER}/api/promoOpt/deletePromo/${this.props.promo.id}`, {
          headers: {
            ClientToken: localStorage.getItem("clientToken")
          }
        })
        .then(res => {
          this.props.getProjects();

          this.setState({
            deletingPromo: false,
            showDeletePromoConfirmModal: false
          });
          toast.success("Promo: " + this.props.promo.name + " Deleted");
        });
    }
  }

  downloadHandler() {
    toast.success("Preparing Promo Model Download");
    axios
      .post(
        `${process.env.REACT_APP_BIGHORN_SERVER}/api/promoOpt/downloadPromo`,
        { promoId: this.props.promo.id, promoName: this.props.promo.name },
        {
          headers: {
            ClientToken: localStorage.getItem("clientToken")
          }
        }
      )
      .then(res => {
        var filename = extractFileName(res.headers["content-disposition"]);
        console.log("File name", filename);
        this.setState({ manageOpened: false });
        //invoke 'Save' dialog
        save(res.data, filename);
      });
  }
  handleClose() {
    this.setState({
      showCannotDeleteModal: false,
      showDeletePromoConfirmModal: false
    });
  }
  render() {
    const { promo } = this.props;
    const { showDeletePromoConfirmModal, showCannotDeleteModal } = this.state;

    return (
      <Col
        // key={wIndex}
        // smOffset={2}
        // xs={6}
        // xsOffset={4}
        // sm={4}
        // md={3}
        // lg={2}
        // xl={3}
        key={this.props.index}
        className="card">
        <Modal isOpen={showCannotDeleteModal}>
          <ModalHeader>Cannot Delete Promo</ModalHeader>
          <ModalBody>
            <div>Cannot delete a promo that is marked as final. Remove the final flag and try again.</div>
          </ModalBody>

          <ModalFooter>
            <Button onClick={this.handleClose}>Close</Button>
          </ModalFooter>
        </Modal>

        <Modal isOpen={showDeletePromoConfirmModal}>
          <ModalHeader>Delete Promo</ModalHeader>
          <ModalBody>
            <div>Are you sure you want to delete this promo and the data associated with it?</div>
          </ModalBody>

          <ModalFooter>
            {!this.state.deletingPromo ? (
              <Button
                color="primary"
                onClick={() => {
                  this.deleteHandler("promo");
                }}>
                Delete
              </Button>
            ) : (
              <div
                className="accubase-uploading"
                style={{
                  marginLeft: "auto",

                  display: "flex",
                  flexDirection: "row"
                }}>
                <DotLoader sizeUnit={"px"} size={15} color={"black"} loading={true} />
                <div style={{ marginLeft: "5px" }}>Deleting ...</div>
              </div>
            )}
            <Button onClick={this.handleClose}>Cancel</Button>
          </ModalFooter>
        </Modal>
        <div
          style={{
            width: "100%",
            height: "30px",
            display: "flex",
            flexDirection: "row",
            paddingTop: "2px"
          }}>
          {promo.status === "running" && (
            <div
              style={{
                color: "rgb(196,152,108)",
                border: "1px solid rgb(251,238,223)",
                paddingTop: "3px",
                height: "23px",
                marginLeft: "-8px",
                paddingLeft: "5px",
                width: "144px",
                background: "rgb(251,238,223)"
              }}>
              CALC IN PROGRESS
            </div>
          )}
          {promo.status === "failed" && (
            <div
              style={{
                color: "#721c24",
                border: "1px solid #f5c6cb",
                paddingTop: "3px",
                height: "23px",
                marginLeft: "-8px",
                paddingLeft: "5px",
                width: "50px",
                background: "#f8d7da"
              }}>
              Failed
            </div>
          )}
          {promo.status === null && (
            <div
              style={{
                color: "rgb(196,152,108)",
                border: "1px solid rgb(251,238,223)",
                paddingTop: "3px",
                height: "23px",
                marginLeft: "-8px",
                paddingLeft: "5px",
                width: "110px",
                background: "rgb(251,238,223)"
              }}>
              Not Calculated
            </div>
          )}
          {promo.finalVersion === true && (
            <OverlayTrigger
              key={"finalpromo-tip"}
              placement={"top"}
              overlay={<Tooltip id={"finalpromo-tip"}>Final Promo Plan</Tooltip>}>
              <div>
                <FinalBaseline
                  height={"20px"}
                  width={"20px"}
                  color={"rgba(126, 126, 126, 1)"}
                  className="final-baseline"
                  style={{
                    marginBottom: "3px",
                    marginRight: "20px"
                  }}

                  // onClick={item => {
                  // }}
                />
              </div>
            </OverlayTrigger>
          )}
          <div
            onClick={() => {
              this.manageClickHandler();
            }}
            style={{
              marginLeft: "auto",
              cursor: "pointer",
              display: "flex",
              paddingTop: "1px",
              flexDirection: "row"
            }}>
            <Gear
              className={"selectable gear gearId" + this.props.promo.id}
              height={"20px"}
              width={"20px"}
              color={"rgba(165, 165, 165, 1)"}
            />
          </div>
          {this.state.manageOpened && (
            <div
              className="gear-menu"
              ref={this.Manage}
              style={{
                right: "4px",
                top: "34px",
                position: "absolute",
                zIndex: 1,
                background: "white",
                borderRadius: "0",
                border: "1px solid rgb(229, 230, 232)"
              }}>
              <div
                style={{
                  padding: "5px",
                  cursor: "pointer",
                  borderBottom: "1px solid rgb(229, 230, 232)"
                }}
                className={"menu-item " + (promo.status === "complete" ? "" : "disabled-card-menu-item")}
                onClick={() => {
                  if (promo.status === "complete") {
                    this.downloadHandler();
                    this.setState({ manageOpened: false });
                  }
                }}>
                <DownloadIcon
                  height={"16px"}
                  width={"16px"}
                  color={"rgba(165, 165, 165, 1)"}
                  style={{
                    cursor: "pointer",
                    marginRight: "10px",
                    marginBottom: "3px"
                  }}
                />
                Download
              </div>
              <div
                style={{
                  cursor: "pointer",
                  padding: "5px",
                  borderBottom: "1px solid rgb(229, 230, 232)"
                }}
                className="menu-item"
                onClick={() => {
                  this.setState({ editPromo: true, manageOpened: false });
                }}>
                <Pen
                  height={"16px"}
                  width={"16px"}
                  color={"rgba(165, 165, 165, 1)"}
                  style={{ marginRight: "10px", cursor: "pointer" }}
                />
                Rename
              </div>

              <div
                style={{
                  cursor: "pointer",
                  padding: "5px",
                  borderBottom: "1px solid rgb(229, 230, 232)"
                }}
                className="menu-item"
                onClick={() => {
                  this.setState({
                    showDeletePromoConfirmModal: true,
                    manageOpened: false
                  });
                }}>
                <DeleteIcon
                  height={"16px"}
                  width={"16px"}
                  color={"rgba(165, 165, 165, 1)"}
                  style={{
                    cursor: "pointer",
                    marginBottom: "3px",
                    marginRight: "10px"
                  }}
                />
                Delete
              </div>

              <div
                style={{
                  cursor: "pointer",
                  padding: "5px"
                }}
                className="menu-item"
                onClick={() => {
                  this.props.setViewSelected(3);
                  // console.log(this.props.promo);
                  this.props.getPromoOptConfigData(this.props.promo);
                  this.setState({ manageOpened: false });
                  // axios
                  //   .get(
                  //     `${process.env.REACT_APP_BIGHORN_SERVER}/api/promoOpt/promoOptConfigData/${this.props.promo.id}`,
                  //     {
                  //       headers: {
                  //         ClientToken: localStorage.getItem('clientToken')
                  //       }
                  //     }
                  //   )
                  //   .then(res => {
                  //     //this.setState({ manageOpened: false });
                  //     //this.props.getProjects();
                  //   });
                  //this.setState({ showDeletePromoConfirmModal: true });
                }}>
                <EditPromoIcon
                  height={"16px"}
                  width={"16px"}
                  color={"rgba(165, 165, 165, 1)"}
                  style={{
                    cursor: "pointer",
                    marginBottom: "3px",
                    marginRight: "10px"
                  }}
                />
                Edit
              </div>
            </div>
          )}
        </div>

        <div style={{ display: "flex", flexDirection: "row" }}>
          <div style={{}}>
            <PromoPlanIcon height="20px" color="#666" />
          </div>
          <div
            style={{
              flexDirection: "column",
              marginLeft: "10px",
              overflow: "hidden",
              width: "100%"
            }}>
            {this.state.editPromo ? (
              <input
                style={{ width: "100%" }}
                defaultValue={promo.name}
                ref={this.PromoNameInput}
                onChange={evt => {
                  if (evt.keyCode === 13) {
                    this.setState({ editPromo: false });
                  }
                }}
                onKeyDown={event => {
                  promo.name = this.PromoNameInput.current.value;
                  this.keyDownHandler(event);
                }}
              />
            ) : (
              <div className="card-title">{promo.name}</div>
            )}

            {/* <div className="card-details card-detail-value">
              Estimate Tactics by Model
            </div> */}
            <div className="card-details">
              <span className="card-detail-heading">Quarter:</span> <span className="card-detail-value">{promo.quarter}</span>
            </div>
            <div className="card-details">
              <span className="card-detail-heading">Year: </span>
              <span className="card-detail-value">{promo.year}</span>
            </div>
            <div className="card-details">
              <span className="card-detail-heading">Last Modified: </span>
              <span className="card-detail-value">{moment(promo.lastModified, timestampFormat).format("MM/DD/YYYY")}</span>
            </div>
          </div>
        </div>
        <div
          style={{
            borderBottom: "1px solid #DBDDDE",
            marginTop: "10px",
            marginBottom: "10px"
          }}></div>
        {/* <hr /> */}
        <div
          style={{
            cursor: "pointer",
            textAlign: "center",
            fontSize: "14px",
            fontWeight: "bold",
            color: "#0080AB"
          }}
          className={"review-promo " + (promo.status === "complete" ? "" : "disabled-card")}
          onClick={() => {
            if (promo.status === "complete") {
              this.reviewPromoModel(promo);
            }
          }}>
          Review + {">"}
        </div>
      </Col>
    );
  }
}

const mapStateToProps = state => ({});
const mapDispatchToProps = dispatch => ({
  // selectPromo(promo, viewSelected) {
  //   dispatch(selectPromo(promo, viewSelected));
  // },
  renamePromo(promo, name, baselineId) {
    dispatch(renamePromo(promo, name, baselineId));
  },
  getProjects() {
    dispatch(getProjects());
  },
  selectPromo(promoId, view) {
    dispatch(selectPromo(promoId, view));
  },
  setViewSelected(view) {
    dispatch(setViewSelected(view));
  },
  getPromoOptConfigData(selectedPromo) {
    dispatch(getPromoOptConfigData(selectedPromo));
  }
});

export default connect(mapStateToProps, mapDispatchToProps)(PromoCard);
