import React, { Component } from "react";
import MdmRouter from "components/mdm/routes";
import TopLevelNav from "components/topLevelNav";
import { connect } from "react-redux";
import { setAuthLoaded } from "reducers/authReducer";

class Mdm extends Component {
  componentDidMount() {
    const { isAuthenticated } = this.props.auth;
    if (isAuthenticated()) {
      this.props.setAuthLoaded(true);
    }
  }
  login() {
    this.props.auth.login();
  }
  render() {
    const { isAuthenticated } = this.props.auth;
    if (isAuthenticated() && this.props.loaded) {
      return (
        <div
          style={{
            width: "100%",
            position: "absolute",
            top: "44px",
            bottom: "0px",
            left: "0px",
            right: "0px"
          }}
        >
          <div className="ta-mdm" style={{ height: "100%", minHeight: "100%" }}>
            <TopLevelNav />
            <MdmRouter />
          </div>
        </div>
      );
    } else {
      return <div />;
    }
  }
}

const mapStateToProps = state => ({
  loaded: state.auth.loaded
});
const mapDispatchToProps = dispatch => ({
  setAuthLoaded(value) {
    dispatch(setAuthLoaded(value));
  }
});

export default connect(mapStateToProps, mapDispatchToProps)(Mdm);

// import React, { Component } from "react";

// import TopLevelNav from "components/topLevelNav";
// import { connect } from "react-redux";
// import { setAuthLoaded } from "reducers/authReducer";
// import { getMdmConfig } from "components/mdm/reducers/mdmReducer";

// class Mdm extends Component {
//   login() {
//     this.props.auth.login();
//   }
//   componentDidMount() {
//     const { isAuthenticated } = this.props.auth;
//     if (isAuthenticated() && this.props.loaded) {
//     } else if (isAuthenticated()) {
//       this.props.setAuthLoaded(true);
//       this.props.getMdmConfig();
//     }
//   }
//   render() {
//     const { isAuthenticated } = this.props.auth;
//     if (isAuthenticated() && this.props.loaded) {
//       return (
//         <div
//           style={{
//             // height: "100%",
//             // minHeight: "100%",
//             width: "100%",
//             position: "absolute",
//             top: "44px",
//             bottom: "0px",
//             left: "0px",
//             right: "0px"
//           }}
//         >
//           <div
//             className="ta-report"
//             style={{ height: "100%", display: "flex", flexDirection: "column" }}
//           />
//         </div>
//       );
//     } else if (isAuthenticated()) {
//       return <div />;
//     } else {
//       return <div />;
//     }
//   }
// }

// const mapStateToProps = state => ({
//   // loaded: state.auth.loaded,
//   // showBanner: state.app.showBanner,
//   // reportListType: state.app.reportListType
// });
// const mapDispatchToProps = dispatch => ({
//   setAuthLoaded(value) {
//     dispatch(setAuthLoaded(value));
//   },
//   getMdmConfig() {
//     dispatch(getMdmConfig());
//   }
// });

// export default connect(
//   mapStateToProps,
//   mapDispatchToProps
// )(Mdm);
