import axios from "axios";
//import { cloneDeep } from "lodash";

/* -------------------- INITIAL STATE -------------------- */
const INITIAL_STATE = {
  ticket: "",
  loading: true,
  loaded: false,
  error: null
};

/* -------------------- ACTION TYPES -------------------- */
const FETCH_TICKET_BEGIN = "FETCH_TICKET_BEGIN";
const FETCH_TICKET_SUCCESS = "FETCH_TICKET_SUCCESS";
const FETCH_TICKET_FAILURE = "FETCH_TICKET_FAILURE";

//const DELETE_FILE_SUCCESS = "DELETE_FILE_SUCCESS";

/* -------------------- ACTION CREATORS -------------------- */

const fetchTicketBegin = () => ({
  type: FETCH_TICKET_BEGIN
});
const fetchTicketSuccess = ticket => {
  return { type: FETCH_TICKET_SUCCESS, payload: ticket };
};

const fetchTicketError = error => ({
  type: FETCH_TICKET_FAILURE,
  payload: { error }
});

/* -------------------- ASYNC ACTION CREATORS -------------------- */
export const getTicket = user => {
  return dispatch => {
    dispatch(fetchTicketBegin());
    axios
      .post(
        `${process.env.REACT_APP_BIGHORN_SERVER}/api/tableau/getTicket`,
        { user },
        {
          headers: {
            //Authorization: "Bearer " + localStorage.getItem("access_token")
            ClientToken: localStorage.getItem("clientToken")
          }
        }
      )
      .then(response => {
        dispatch(fetchTicketSuccess(response.data.ticket));
      })
      .catch(error => {
        dispatch(fetchTicketError(error));
      });
  };
};

/* -------------------- REDUCER -------------------- */
export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case FETCH_TICKET_BEGIN:
      return { ...state, loading: true, loaded: false, error: null };
    case FETCH_TICKET_SUCCESS:
      return {
        ...state,
        ticket: action.payload,
        loading: false,
        loaded: true
      };
    case FETCH_TICKET_FAILURE:
      return {
        ...state,
        loading: false,
        loaded: false,
        error: action.payload.error
      };

    default:
      return state;
  }
};
