import axios from "axios";
import {
  numberFormatter,
  usCurrencyFormatter
} from "../../agGrid/formatters/index.js";
import { cloneDeep, find } from "lodash";
import moment from "moment";
const timestampFormat = "MM/DD/YY";
/* -------------------- INITIAL STATE -------------------- */
const INITIAL_STATE = {
  loadedProjects: false,
  loadingProjects: false,
  loadingData: false,
  loadingError: false,
  loadedData: false,
  projects: [],
  searchProjects: [],
  error: null,
  data: [],
  chartData: [],
  changed: [],
  reInitChart: false,
  config: [],
  loadingConfig: true,
  loadedConfig: false,
  selectedProject: null,
  reInitProjects: false,
  selectedBaseline: {},
  defaultCalendarExists: false,
  baselineId: -1,
  selectedBatch: {},
  countries: [],
  selectedBatchIdx: -1
};

/* -------------------- ACTION TYPES -------------------- */
const FETCH_ACCUBASE_DATA_BEGIN = "FETCH_ACCUBASE_DATA_BEGIN";
const FETCH_ACCUBASE_DATA_SUCCESS = "FETCH_ACCUBASE_DATA_SUCCESS";
const FETCH_ACCUBASE_DATA_FAILURE = "FETCH_ACCUBASE_DATA_FAILURE";
const FETCH_ACCUBASE_CONFIG_BEGIN = "FETCH_ACCUBASE_CONFIG_BEGIN";
const FETCH_ACCUBASE_CONFIG_SUCCESS = "FETCH_ACCUBASE_CONFIG_SUCCESS";
const FETCH_ACCUBASE_CONFIG_FAILURE = "FETCH_ACCUBASE_CONFIG_FAILURE";
const FETCH_ACCUBASE_PROJECT_BEGIN = "FETCH_ACCUBASE_PROJECT_BEGIN";
const FETCH_ACCUBASE_PROJECT_SUCCESS = "FETCH_ACCUBASE_PROJECT_SUCCESS";
const FETCH_ACCUBASE_PROJECT_FAILURE = "FETCH_ACCUBASE_PROJECT_FAILURE";
const SET_CHART_REINIT = "SET_CHART_REINIT";
const SET_PROJ_REINIT = "SET_PROJ_REINIT";
const SET_CHART_DATA = "SET_CHART_DATA";
const SET_CHANGED = "SET_CHANGED";
const SET_SEARCH_PROJECTS = "SET_SEARCH_PROJECTS";
const SET_SELECTED_PROJECT_ACCUBASE = "SET_SELECTED_PROJECT_ACCUBASE";
const UPDATE_DATA_FROM_CHART = "UPDATE_DATA_FROM_CHART";
const SET_ACCUBASE_VIEW_SELECTED = "SET_ACCUBASE_VIEW_SELECTED";

/* -------------------- ACTION CREATORS -------------------- */

const fetchAccubaseDataBegin = () => ({
  type: FETCH_ACCUBASE_DATA_BEGIN
});
const fetchAccubaseDataSuccess = (files, config) => {
  return { type: FETCH_ACCUBASE_DATA_SUCCESS, payload: files, config };
};
const fetchAccubaseDataError = error => {
  return { type: FETCH_ACCUBASE_DATA_FAILURE, payload: error };
};
const fetchAccubaseConfigBegin = () => ({
  type: FETCH_ACCUBASE_CONFIG_BEGIN
});
const fetchAccubaseConfigSuccess = files => {
  return { type: FETCH_ACCUBASE_CONFIG_SUCCESS, payload: files };
};
const fetchAccubaseConfigError = error => {
  return { type: FETCH_ACCUBASE_CONFIG_FAILURE, payload: error };
};
const fetchAccubaseProjectBegin = () => ({
  type: FETCH_ACCUBASE_PROJECT_BEGIN
});
const fetchAccubaseProjectSuccess = (
  projects,
  defaultCalendarExists,
  selectProject,
  countries
) => {
  return {
    type: FETCH_ACCUBASE_PROJECT_SUCCESS,
    payload: projects,
    defaultCalendarExists,
    selectProject,
    countries
  };
};
const fetchAccubaseProjectError = error => {
  return { type: FETCH_ACCUBASE_PROJECT_FAILURE, payload: error };
};
const setProjReinit = status => {
  return { type: SET_PROJ_REINIT, payload: status };
};
const setChartReinit = status => {
  return { type: SET_CHART_REINIT, payload: status };
};
const doSetfilteredData = data => {
  return { type: SET_CHART_DATA, payload: data };
};
const doSetChartData = data => {
  return { type: SET_CHART_DATA, payload: data };
};
const setChanged = changed => {
  return { type: SET_CHANGED, payload: changed };
};
const doSetSearchProjects = projects => {
  return { type: SET_SEARCH_PROJECTS, payload: projects };
};
const doSetSelectedProject = project => {
  return { type: SET_SELECTED_PROJECT_ACCUBASE, payload: project };
};

/* -------------------- ASYNC ACTION CREATORS -------------------- */
export const getAccubaseData = baseline => {
  return dispatch => {
    dispatch(fetchAccubaseDataBegin());
    axios
      .get(
        `${process.env.REACT_APP_BIGHORN_SERVER}/api/accubase/accubaseData/${baseline}`,
        {
          headers: {
            ClientToken: localStorage.getItem("clientToken")
          }
        }
      )
      .then(response => {
        dispatch(
          fetchAccubaseDataSuccess(response.data.content, response.data.config)
        );
      })
      .catch(error => {
        dispatch(fetchAccubaseDataError(error));
      });
  };
};
export const getAccubaseConfig = baseline => {
  return dispatch => {
    dispatch(fetchAccubaseConfigBegin());
    axios
      .get(
        `${process.env.REACT_APP_BIGHORN_SERVER}/api/accubase/accubaseConfig/${baseline}`,
        {
          headers: {
            ClientToken: localStorage.getItem("clientToken")
          }
        }
      )
      .then(response => {
        dispatch(fetchAccubaseConfigSuccess(response.data.config));
      })
      .catch(error => {
        dispatch(fetchAccubaseConfigError(error));
      });
  };
};

export const getProjects = (selectProject = true) => {
  return dispatch => {
    dispatch(fetchAccubaseProjectBegin());
    axios
      .get(`${process.env.REACT_APP_BIGHORN_SERVER}/api/accubase/projects`, {
        headers: {
          ClientToken: localStorage.getItem("clientToken")
        }
      })
      .then(response => {
        dispatch(
          fetchAccubaseProjectSuccess(
            response.data.projects,
            response.data.defaultCalendar,
            selectProject,
            response.data.countries
          )
        );
        dispatch(setProjReinit(true));
      })
      .catch(error => {
        dispatch(fetchAccubaseProjectError(error));
      });
  };
};
export const setProjects = projects => {
  return dispatch => {
    dispatch(fetchAccubaseProjectSuccess(projects));
  };
};

// export const saveProjects = projects => {
//   return dispatch => {
//     axios
//       .post(
//         `${process.env.REACT_APP_BIGHORN_SERVER}/api/accubase/createProjects`,
//         { projects },
//         {
//           headers: {
//             ClientToken: localStorage.getItem("clientToken")
//           }
//         }
//       )
//       .then(response => {
//         dispatch(getProjects());
//       })
//       .catch(error => {});
//   };
// };
export const setReInitProjects = state => {
  return dispatch => {
    dispatch(setProjReinit(state));
  };
};
export const setReinitChart = state => {
  return dispatch => {
    dispatch(setChartReinit(state));
  };
};
export const setChartData = data => {
  return dispatch => {
    dispatch(doSetChartData(data));
  };
};
export const setFilteredData = data => {
  return dispatch => {
    dispatch(doSetfilteredData(data));
  };
};
export const setAccubaseData = (oldData, newData, config, changed) => {
  return dispatch => {
    newData.forEach(item => {
      let result = find(oldData, { ID: item.ID });

      if (result !== undefined) {
        result.BASE_EDRP = item.BASE_EDRP;
        result.BASE_UNITS = item.BASE_UNITS;
        dispatch({ type: UPDATE_DATA_FROM_CHART, payload: oldData });
      } else {
        console.error(
          "Unable to Set Accubase Data because the chart has different data than the Grid"
        );
      }
    });
  };
};
export const setChangedData = data => {
  return dispatch => {
    //var k1 = performance.now();
    dispatch(setChanged(data));
    //var k2 = performance.now();
    //console.log("Dispatch To Changed " + (k2 - k1) + " milliseconds.");
  };
};
export const setSearchProjects = (filter, projects, includeSecondary) => {
  return dispatch => {
    if (filter === "") {
      dispatch(doSetSearchProjects(projects));
    } else {
      var newProjects = cloneDeep(projects);
      var nProjects = [];

      if (includeSecondary) {
        nProjects = newProjects.map((newProject, newProjectIdx) => {
          newProject.batch = newProject.batch.map((batch, batchIdx) => {
            batch.baselines = batch.baselines.filter(item => {
              if (
                item.name.toLowerCase().indexOf(filter.toLowerCase()) !== -1
              ) {
                return true;
              } else {
                return false;
              }
            });
            return batch;
          });
          return newProject;
        });
      } else {
        newProjects.forEach((newProject, newProjectIdx) => {
          if (
            newProject.PROJECT_NAME.toLowerCase().indexOf(
              filter.toLowerCase()
            ) !== -1
          ) {
            nProjects.push(newProject);
          }
        });
      }
      dispatch(doSetSearchProjects(nProjects));
    }
  };
};
export const selectBaseline = (baseline, viewSelected) => {
  return dispatch => {
    dispatch(getAccubaseData(baseline.id));
    dispatch({
      type: "SET_SELECTED_BASELINE",
      payload: baseline,
      viewSelected
    });
  };
};
export const setSelectedProject = project => {
  return dispatch => {
    dispatch(doSetSelectedProject(project));
  };
};
export const setOldProjectName = (selectedProject, name) => {
  return dispatch => {
    axios
      .post(
        `${process.env.REACT_APP_BIGHORN_SERVER}/api/acculift/editProjectName`,
        { selectedProject },
        {
          headers: {
            ClientToken: localStorage.getItem("clientToken")
          }
        }
      )
      .then(response => {
        dispatch(getProjects());
      })
      .catch(error => {});
    dispatch({ type: "SET_OLD_PROJECT_NAME", payload: name });
  };
};
export const renameBaseline = (id, name, bId) => {
  return dispatch => {
    axios
      .post(
        `${process.env.REACT_APP_BIGHORN_SERVER}/api/acculift/editBaselineName`,
        { id, name, batchId: bId },
        {
          headers: {
            ClientToken: localStorage.getItem("clientToken")
          }
        }
      )
      .then(response => {
        dispatch(getProjects(false));
      })
      .catch(error => {});
  };
};
export const renameBatch = (id, name, pId) => {
  return dispatch => {
    axios
      .post(
        `${process.env.REACT_APP_BIGHORN_SERVER}/api/accubase/editBatchName`,
        { id, name, projectId: pId },
        {
          headers: {
            ClientToken: localStorage.getItem("clientToken")
          }
        }
      )
      .then(response => {
        dispatch(getProjects(false));
      })
      .catch(error => {});
    //dispatch({ type: "SET_OLD_PROJECT_NAME", payload: name });
  };
};

export const setViewSelected = view => {
  return dispatch => {
    dispatch({ type: "SET_ACCUBASE_VIEW_SELECTED", payload: view });
  };
};
export const selectBatch = batch => {
  return dispatch => {
    dispatch({ type: "SET_SELECTED_BATCH", payload: batch });
  };
};

/* -------------------- REDUCER -------------------- */
export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case FETCH_ACCUBASE_DATA_BEGIN:
      return {
        ...state,
        config: [],
        data: [],
        chatData: [],
        loadingData: true,
        loadedData: false,
        loadedConfig: false,
        loadingConfig: true,
        error: null
      };
    case FETCH_ACCUBASE_DATA_SUCCESS:
      let chartData = cloneDeep(action.payload);
      let newConfig = action.config.map(item => {
        let confItem = item;
        if (
          item.field === "UNITS" ||
          item.field === "BASE_UNITS" ||
          item.field === "ELAS_FIT_UNITS"
        ) {
          confItem.valueFormatter = numberFormatter;
          confItem.cellClass = ["numeric-cell"];
        }
        if (
          item.field === "BASE_EDRP" ||
          item.field === "ARP" ||
          item.field === "VALUE"
        ) {
          confItem.valueFormatter = usCurrencyFormatter;
          confItem.cellClass = ["numeric-cell"];
        }
        if (item.field === "ID") {
          confItem.hide = true;
          confItem.suppressColumnsToolPanel = true;
          confItem.suppressFiltersToolPanel = true;
        }
        if (
          item.cellRenderer !== undefined &&
          item.cellRenderer.includes("EditableCheckboxCellRenderer")
        ) {
          confItem.onCellClicked = function(event) {
            if (event.value) {
              event.value = 0;
            } else {
              event.value = 1;
            }
            event.data[event.colDef.headerName] = event.value;

            var itemsToUpdate = [];
            event.api.forEachNodeAfterFilterAndSort(function(rowNode, index) {
              if (!rowNode.selected) {
                return;
              }
              var data = rowNode.data;
              // data.status.name = value;
              itemsToUpdate.push(data);
            });
            event.api.updateRowData({ update: itemsToUpdate });
            let newEvent = event;
            newEvent.type = "cellValueChanged";

            //event.api.dispatchEvent({type:"cellValueChanged",node:event.node,colDef:event.colDef});
            event.api.dispatchEvent(newEvent);
          };
        }

        return confItem;
      });
      return {
        ...state,
        data: action.payload,
        chartData,
        config: newConfig,
        loadingData: false,
        loadedData: true,
        loadingConfig: false,
        loadedConfig: true
      };
    case UPDATE_DATA_FROM_CHART:
      return { ...state, data: action.payload };
    case FETCH_ACCUBASE_DATA_FAILURE:
      return {
        ...state,
        loadingData: false,
        loadedData: false,
        loadingConfig: false,
        loadedConfig: false,
        error: action.payload.error
      };
    case FETCH_ACCUBASE_CONFIG_BEGIN:
      return { ...state, loadingConfig: true, error: null };
    case FETCH_ACCUBASE_CONFIG_SUCCESS:
      return {
        ...state,
        config: action.payload,
        loadingConfig: false,
        loadedConfig: true
      };
    case FETCH_ACCUBASE_CONFIG_FAILURE:
      return {
        ...state,
        loadingConfig: false,
        loadedConfig: false,
        error: action.payload.error
      };
    case FETCH_ACCUBASE_PROJECT_BEGIN:
      return { ...state, loadingProjects: true, error: null };
    case FETCH_ACCUBASE_PROJECT_SUCCESS:
      if (action.selectProject === true) {
        let latestBatch = null;
        action.payload.forEach((project, pIdx) => {
          project.batch.forEach((sBatch, bIdx) => {
            if (sBatch.baselines !== undefined && sBatch.baselines.length > 0) {
              if (latestBatch === null) {
                latestBatch = sBatch;
                latestBatch.projectIndex = pIdx;
                latestBatch.idx = bIdx;
              } else if (
                moment(latestBatch.dateCreated, timestampFormat).isBefore(
                  moment(sBatch.dateCreated, timestampFormat)
                )
              ) {
                latestBatch = sBatch;
                latestBatch.projectIndex = pIdx;
                latestBatch.idx = bIdx;
              }
            }
          });
        });
        if (latestBatch === null) {
          //add this to make this work with a new site that does not have any projects.
          latestBatch = { projectIndex: 0, batch: [] };
        }

        if (latestBatch === null) {
          //add this to make this work with a new site that does not have any projects.
          latestBatch = { projectIndex: 0, batch: [] };
        }
        return {
          ...state,
          projects: action.payload,
          searchProjects: action.payload,
          selectedProject: action.payload[latestBatch.projectIndex],
          selectedBatch: latestBatch,
          defaultCalendarExists: action.defaultCalendarExists,
          countries: action.countries,
          loadingProjects: false,
          loadedProjects: true
        };
      } else {
        let selectedProject = state.projects.filter(item => {
          return item.ID === state.selectedProject.ID;
        });
        return {
          ...state,
          projects: action.payload,
          searchProjects: action.payload,
          selectedProject: selectedProject[0],
          defaultCalendarExists: action.defaultCalendarExists,
          loadingProjects: false,
          loadedProjects: true
        };
      }
    case FETCH_ACCUBASE_PROJECT_FAILURE:
      return {
        ...state,
        loadingProjects: false,
        loadedProjects: false,
        loadingError: true,
        error: action.payload.error
      };
    case SET_CHART_REINIT:
      return {
        ...state,
        reInitChart: action.payload
      };
    case SET_PROJ_REINIT:
      let selectedProject = state.projects.filter(item => {
        return item.ID === state.selectedProject.ID;
      });
      return {
        ...state,
        reInitProjects: action.payload,
        selectedProject: selectedProject[0]
      };
    case SET_CHART_DATA:
      return {
        ...state,
        chartData: action.payload,
        reInitChart: true
      };
    case SET_CHANGED:
      let changed = [];
      if (action.payload.length === 0) {
      } else if (state.changed.length > 0) {
        changed = [...state.changed];
        action.payload.forEach(item => {
          let result = find(state.changed, { ID: item.ID });

          if (result === undefined) {
            changed.push(item);
          }
        });
      } else {
        changed = action.payload;
      }

      return {
        ...state,
        changed
      };

    case SET_SEARCH_PROJECTS:
      return { ...state, searchProjects: action.payload };
    case SET_SELECTED_PROJECT_ACCUBASE:
      let latestBatch = null;
      action.payload.batch.forEach((sBatch, bIdx) => {
        if (sBatch.baselines !== undefined && sBatch.baselines.length > 0) {
          if (latestBatch === null) {
            latestBatch = sBatch;
            latestBatch.idx = bIdx;
          } else if (
            moment(latestBatch.dateCreated, timestampFormat).isBefore(
              moment(sBatch.dateCreated, timestampFormat)
            )
          ) {
            latestBatch = sBatch;
            latestBatch.idx = bIdx;
          }
        }
      });

      return {
        ...state,
        selectedBatch: latestBatch,
        selectedProject: action.payload
      };
    case SET_ACCUBASE_VIEW_SELECTED:
      return {
        ...state,
        viewSelected: action.payload
      };
    case "SET_SELECTED_BASELINE":
      let viewSelected = action.viewSelected;
      let baselineId = -1;
      if (action.payload !== {}) {
        baselineId = action.payload.id;
      }

      return {
        ...state,
        selectedBaseline: action.payload,
        viewSelected,
        baselineId
      };
    case "SET_SELECTED_BATCH":
      return { ...state, selectedBatch: action.payload };
    default:
      return state;
  }
};
