import React, { Component } from "react";
import { connect } from "react-redux";

import { DeleteIcon } from "lib/icons";
import history from "lib/history";
import Checkbox from "rc-checkbox";
import "./style.css";
import {
  selectSubscription,
  saveReportSubscription,
  deleteReportSubscription
} from "components/reportSubscriptions/reducers/reportSubscriptionReducer";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import axios from "axios";
import ToggleButton from "react-bootstrap/ToggleButton";
import ToggleButtonGroup from "react-bootstrap/ToggleButtonGroup";
import Select from "react-select";
import { addTabToNav } from "components/topLevelNav/reducers/topLevelNavReducer";
import { toast } from "react-toastify";

class RptSubscriptionItem extends Component {
  constructor(props) {
    super(props);
    let user;
    if (localStorage.getItem("user").includes("#")) {
      user = localStorage.getItem("user").split("#")[1];
    } else {
      user = localStorage.getItem("user");
    }
    this.state = {
      showNewReportSubDialog: false,
      toggleSubActive: "on",
      bookmarks: [],
      bookmarkName: "",
      selectedBookmark: { label: "Select Bookmark", value: "Select Bookmark" },
      selectedRadioOption: 1,
      selectedFrequency: {
        label: "On Data Refresh",
        value: "On Data Refresh",
        id: 1
      },
      frequencyOptions: [
        { label: "On Data Refresh", value: "On Data Refresh", id: 1 },
        { label: "Weekly", value: "Weekly", id: 2 },
        { label: "Monthly", value: "Monthly", id: 3 }
      ],
      selectedWeekly: [],
      selectedMonthly: { label: "1", value: "1" },
      monthlyOptions: [
        { label: "1", value: "1" },
        { label: "2", value: "2" },
        { label: "3", value: "3" },
        { label: "4", value: "4" },
        { label: "5", value: "5" },
        { label: "6", value: "6" },
        { label: "7", value: "7" },
        { label: "8", value: "8" },
        { label: "9", value: "9" },
        { label: "10", value: "10" },
        { label: "11", value: "11" },
        { label: "12", value: "12" },
        { label: "13", value: "13" },
        { label: "14", value: "14" },
        { label: "15", value: "15" },
        { label: "16", value: "16" },
        { label: "17", value: "17" },
        { label: "18", value: "18" },
        { label: "19", value: "19" },
        { label: "20", value: "20" },
        { label: "21", value: "21" },
        { label: "22", value: "22" },
        { label: "23", value: "23" },
        { label: "24", value: "24" },
        { label: "25", value: "25" },
        { label: "26", value: "26" },
        { label: "27", value: "27" },
        { label: "28", value: "28" },
        { label: "29", value: "29" },
        { label: "30", value: "30" },
        { label: "31", value: "31" }
      ],
      user,
      rptSubName: "",
      subscriptionId: "",
      subscriptionRptTitle: "",
      deleteRptSubItem: {},
      showDeleteReportSubscription: false,
      running: false
    };
    this.textReportNameInput = React.createRef();
    this.toggleChangeReportSubscription = this.toggleChangeReportSubscription.bind(
      this
    );
  }
  componentDidMount() {}
  handleClose = () => {
    this.setState({
      showNewReportSubDialog: false,
      showDeleteReportSubscription: false
    });
  };
  renderDeleteButton() {
    const { subscriptionId } = this.state;
    if (subscriptionId === "") {
      return null;
    } else {
      return (
        <div
          style={{ marginTop: "-2px", marginLeft: "10px", cursor: "pointer" }}
          onClick={() => {
            this.setState({ showDeleteReportSubscription: true });
          }}
        >
          <DeleteIcon
            height={"24px"}
            width={"24px"}
            color={"rgba(88, 89, 91, 1)"}
          />
        </div>
      );
    }
  }
  renderRptSubTextInput() {
    const { subscriptionRptTitle } = this.state;

    if (subscriptionRptTitle !== "") {
      return (
        <input
          style={{ width: "100%" }}
          defaultValue={subscriptionRptTitle}
          ref={this.textReportNameInput}
        />
      );
    } else {
      return (
        <input
          style={{ width: "100%" }}
          defaultValue={this.props.item.name}
          ref={this.textReportNameInput}
        />
      );
    }
  }
  renderFrequencyExtras() {
    const { selectedFrequency } = this.state;
    if (selectedFrequency.id === 1) {
      return null;
    } else if (selectedFrequency.id === 2) {
      return (
        <div
          style={{ display: "flex", flexDirection: "row", marginTop: "10px" }}
        >
          <div className="daily-select-group">
            <Checkbox
              onChange={() => {
                this.setState({ sunChecked: !this.state.sunChecked });
              }}
              checked={this.state.sunChecked}
              className="daily-selection"
            />
            Sun
          </div>
          <div className="daily-select-group">
            <Checkbox
              onChange={() => {
                this.setState({ monChecked: !this.state.monChecked });
              }}
              checked={this.state.monChecked}
              className="daily-selection"
            />
            Mon
          </div>
          <div className="daily-select-group">
            <Checkbox
              onChange={() => {
                this.setState({ tueChecked: !this.state.tueChecked });
              }}
              checked={this.state.tueChecked}
              className="daily-selection"
            />
            Tue
          </div>
          <div className="daily-select-group">
            <Checkbox
              onChange={() => {
                this.setState({ wedChecked: !this.state.wedChecked });
              }}
              checked={this.state.wedChecked}
              className="daily-selection"
            />
            Wed
          </div>
          <div className="daily-select-group">
            <Checkbox
              onChange={() => {
                this.setState({ thuChecked: !this.state.thuChecked });
              }}
              checked={this.state.thuChecked}
              className="daily-selection"
            />
            Thu
          </div>
          <div className="daily-select-group">
            <Checkbox
              onChange={() => {
                this.setState({ friChecked: !this.state.friChecked });
              }}
              checked={this.state.friChecked}
              className="daily-selection"
            />
            Fri
          </div>
          <div className="daily-select-group">
            <Checkbox
              onChange={() => {
                this.setState({ satChecked: !this.state.satChecked });
              }}
              checked={this.state.satChecked}
              className="daily-selection"
            />
            Sat
          </div>
        </div>
      );
    } else {
      return (
        <div style={{ marginTop: "10px" }}>
          <Select
            className="bookmarks-dropdown"
            // classNamePrefix="react-select"
            placeholder={"Select Day"}
            value={
              this.state.selectedMonthly //isClearable
            }
            onChange={selectedOption => {
              this.setState({
                selectedMonthly: selectedOption
              });
            }}
            options={this.state.monthlyOptions}
          />
        </div>
      );
    }
  }
  toggleChangeReportSubscription() {
    if (this.state.toggleSubActive === "off") {
      this.setState({ toggleSubActive: "on" });
    } else {
      this.setState({ toggleSubActive: "off" });
    }
  }
  saveAndCloseRptSub() {
    let rptType = "PPTX";
    if (this.state.selectedRadioOption === 2) {
      rptType = "PDF";
    }
    let workspaceId = this.props.item.workspaceId;
    if (workspaceId === undefined || !workspaceId.includes("-")) {
      workspaceId = this.props.item.webUrl.split("/groups/")[1].split("/")[0];
    }
    let status = "Active";
    if (this.state.toggleSubActive !== "on") {
      status = "Disabled";
    }
    // let sBookmark = this.state.selectedBookmark.name;
    let sBookmarkName = this.state.selectedBookmark.label;
    if (sBookmarkName === "Select Bookmark") {
      // sBookmark = "";
      sBookmarkName = "";
    }

    let rptSub = {
      rptName: this.textReportNameInput.current.value,
      rptId: this.props.item.reportId,
      status: status,
      email: this.state.user,
      bookmark: this.props.item.bookmark,
      bookmarkName: sBookmarkName,
      rptType,
      freq: this.state.selectedFrequency.label,
      datasetId: this.props.item.datasetId,
      workspaceId
    };
    if (this.state.selectedFrequency.label === "Weekly") {
      let selectedWeekly = [];
      if (this.state.sunChecked) {
        selectedWeekly.push("sun");
      }
      if (this.state.monChecked) {
        selectedWeekly.push("mon");
      }
      if (this.state.tueChecked) {
        selectedWeekly.push("tue");
      }
      if (this.state.wedChecked) {
        selectedWeekly.push("wed");
      }
      if (this.state.thuChecked) {
        selectedWeekly.push("thu");
      }
      if (this.state.friChecked) {
        selectedWeekly.push("fri");
      }
      if (this.state.satChecked) {
        selectedWeekly.push("sat");
      }
      rptSub.wFreq = selectedWeekly;
    } else if (this.state.selectedFrequency.label === "Monthly") {
      rptSub.mFreq = this.state.selectedMonthly.value;
    }

    if (this.state.subscriptionId !== "") {
      rptSub._id = this.state.subscriptionId;

      this.props.saveReportSubscription(rptSub, true, this.state.user);
    } else {
      this.props.saveReportSubscription(rptSub, false, this.state.user);
    }
    this.setState({ showNewReportSubDialog: false });
  }
  renderRunNowText() {
    if (this.state.running === true) {
      return "Running...";
    } else {
      return "Run Now";
    }
  }
  render() {
    const { idx, item } = this.props;
    const { showNewReportSubDialog, showDeleteReportSubscription } = this.state;
    let bookmarkName = item.bookmarkName;
    if (bookmarkName === "Select Bookmark") {
      bookmarkName = "";
    }
    return (
      <tr className="sub-tr" key={idx}>
        <Modal isOpen={showNewReportSubDialog} className="rpt-sub-modal">
          <ModalHeader>
            <div style={{ display: "flex", flexDirection: "row" }}>
              <div>Subscribe to Report Email</div>
              <div
                className={
                  this.state.running === true
                    ? "run-now-btn  run-now-btn-disabled"
                    : "run-now-btn"
                }
                onClick={e => {
                  let rptType = "PPTX";
                  if (this.state.selectedRadioOption === 2) {
                    rptType = "PDF";
                  }
                  let wsId = this.props.item.workspaceId;
                  if (wsId === undefined || !wsId.includes("-")) {
                    wsId = this.props.item.webUrl
                      .split("/groups/")[1]
                      .split("/")[0];
                  }
                  let rptName = "";
                  if (this.state.subscriptionRptTitle !== "") {
                    rptName = this.state.subscriptionRptTitle;
                  } else {
                    rptName = this.props.item.name;
                  }
                  this.setState({ running: true });

                  axios
                    .post(
                      `${process.env.REACT_APP_BIGHORN_SERVER}/api/powerBI/downloadReport`,
                      {
                        groupId: wsId,
                        reportId: this.props.item.reportId,
                        datasetId: this.props.item.datasetId,
                        selectedTab: this.props.item.pages,
                        selectedTabDisplayName: "",
                        selectedReport: rptName,
                        filters: this.props.item.filters,
                        slicers: this.props.item.slicers,
                        type: rptType,
                        bookmark: this.props.item.bookmark,
                        rptSubscription: true,
                        email: this.state.user
                      },
                      {
                        headers: {
                          ClientToken: localStorage.getItem("clientToken")
                        }
                      }
                    )
                    .then(res => {
                      toast.success("Running subscription...");
                      setTimeout(() => {
                        this.setState({ running: false });
                      }, 4000);
                    });
                }}
              >
                {this.renderRunNowText()}
              </div>
              <ToggleButtonGroup
                className="sub-toggle-button-group"
                name="viewEdit"
                type="radio"
                value={this.state.toggleSubActive}
                onChange={this.toggleChangeReportSubscription}
                defaultValue={"on"}
              >
                <ToggleButton
                  // classes={{
                  //   selected: "button-override",
                  //   root: "button-override-root"
                  // }}
                  className="sub-toggle-button button-override on-off-button"
                  value="on"
                >
                  <div>ON</div>
                </ToggleButton>
                <ToggleButton
                  className="sub-toggle-button on-off-button"
                  value="off"
                >
                  <div>OFF</div>
                </ToggleButton>
              </ToggleButtonGroup>
              {this.renderDeleteButton()}
            </div>
          </ModalHeader>
          <ModalBody>
            <div style={{ width: "100%" }}>{this.renderRptSubTextInput()}</div>

            <div style={{ paddingTop: "5px" }}>Emailing to</div>
            <div style={{ paddingTop: "5px" }}>
              <i>{localStorage.getItem("user")}</i>
            </div>
            <div style={{ paddingTop: "20px", paddingBottom: "5px" }}>
              Report Bookmarks
            </div>
            {this.state.selectedBookmark.value !== "Select Bookmark" ? (
              <div>{this.state.selectedBookmark.value}</div>
            ) : (
              <div>none</div>
            )}
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                cursor: "pointer"
              }}
              onClick={() => {
                // let sWorkspace = this.props.item.url.split("+")[0].split("report/")[1];
                // let activeReportId = this.props.item.url.split("+")[1];
                // this.props.addTabToNav({
                //   title: "SOME STUFF",
                //   type: "powerBI",
                //   icon: "powerBI",
                //   path: "powerBI/report/" + sWorkspace + "+" + activeReportId,
                //   id: sWorkspace + "+" + activeReportId,
                //   activeChildren: [],
                //   workspaceId: sWorkspace,
                //   children: []
                // });
                history.replace(this.props.item.url + "+newTab");
              }}
            >
              <i style={{ color: "#007ea7" }}>Click here to edit bookmark.</i>
            </div>
            {/* <Select
              className="bookmarks-dropdown"
              classNamePrefix="react-select"
              placeholder={"Bookmarks"}
              value={
                this.state.selectedBookmark //isClearable
              }
              onChange={selectedOption => {
                this.setState({
                  selectedBookmark: selectedOption
                });
              }}
              options={this.state.bookmarks}
            /> */}
            <div style={{ paddingTop: "20px", paddingBottom: "5px" }}>
              Report Type
            </div>
            <div style={{ display: "flex", flexDirection: "row" }}>
              <input
                checked={this.state.selectedRadioOption === 1}
                onChange={event => {
                  this.setState({
                    selectedRadioOption: parseInt(event.target.value, 10)
                  });
                }}
                type="radio"
                style={{ marginRight: "5px" }}
                value={1}
              />
              <div style={{ marginTop: "0px", paddingBottom: "3px" }}>
                PowerPoint Attachment
              </div>
            </div>
            <div style={{ display: "flex", flexDirection: "row" }}>
              <input
                checked={this.state.selectedRadioOption === 2}
                onChange={event => {
                  this.setState({
                    selectedRadioOption: parseInt(event.target.value, 10)
                  });
                }}
                type="radio"
                style={{ marginRight: "5px" }}
                value={2}
              />
              <div style={{ marginTop: "0px", paddingBottom: "3px" }}>
                PDF Attachment
              </div>
            </div>
            <div style={{ marginTop: "20px", paddingBottom: "5px" }}>
              Frequency
            </div>
            <Select
              className="frequency-dropdown"
              // classNamePrefix="react-select"
              placeholder={"Frequency"}
              value={
                this.state.selectedFrequency //isClearable
              }
              onChange={selectedOption => {
                this.setState({
                  selectedFrequency: selectedOption
                });
              }}
              options={this.state.frequencyOptions}
            />
            {this.renderFrequencyExtras()}
          </ModalBody>

          <ModalFooter>
            <Button
              // style={{ background: "#007ea7" }}
              // bsstyle="primary"
              className="ta-button ta-button-primary"
              onClick={() => {
                this.setState({ deleteRptSubItem: {} });
                this.saveAndCloseRptSub();
              }}
            >
              Save & Close
            </Button>
            <Button
              className="ta-button ta-button-secondary"
              onClick={() => {
                this.setState({ deleteRptSubItem: {} });
                this.handleClose();
              }}
            >
              Cancel
            </Button>
          </ModalFooter>
        </Modal>
        <Modal isOpen={showDeleteReportSubscription}>
          <ModalHeader>Delete Report Subscription</ModalHeader>
          <ModalBody>
            <div>Are you sure you want to delete this report subscription?</div>
          </ModalBody>

          <ModalFooter>
            <Button
              // bsstyle="primary"
              className="ta-button ta-button-danger"
              onClick={() => {
                this.props.deleteReportSubscription(
                  this.state.deleteRptSubItem._id,
                  null,
                  this.state.user
                );
                this.setState({ deleteRptSubItem: {} });
                this.handleClose();
              }}
            >
              Delete
            </Button>
            <Button
              className="ta-button ta-button-secondary"
              onClick={this.handleClose}
            >
              Cancel
            </Button>
          </ModalFooter>
        </Modal>

        <td>
          <div style={{ display: "flex", flexDirection: "row" }}>
            <Checkbox
              style={{ marginRight: "10px" }}
              onChange={() => {
                this.props.selectSubscription(item._id, !this.props.selected);
                //   this.setState({ selected: !this.state.selected });
              }}
              checked={this.props.selected}
              className="selected-item"
            />
            <div
              className="rpt-subscription-item"
              style={{ cursor: "pointer", color: "#007ea7" }}
              onClick={() => {
                let rptType = 1;

                let selectedBookmark = {
                  label: item.bookmarkName,
                  value: item.bookmarkName
                };
                if (item.bookmarkName === "") {
                  selectedBookmark = {
                    label: "Select Bookmark",
                    value: "Select Bookmark"
                  };
                }
                if (item.reportType === "PDF") {
                  rptType = 2;
                }
                let status = "on";
                if (item.status !== "Active") {
                  status = "off";
                }
                let selectedFrequency = {
                  id: 1,
                  label: "On Data Refresh",
                  value: "On Data Refresh"
                };
                let sunChecked = false,
                  monChecked = false,
                  tueChecked = false,
                  wedChecked = false,
                  thuChecked = false,
                  friChecked = false,
                  satChecked = false,
                  selectedMonthly;
                if (item.frequency === "weekly") {
                  selectedFrequency = {
                    id: 2,
                    label: "Weekly",
                    value: "Weekly"
                  };

                  item.weeklyFreq.forEach(item => {
                    switch (item) {
                      case "sun":
                        sunChecked = true;
                        break;
                      case "mon":
                        monChecked = true;
                        break;
                      case "tue":
                        tueChecked = true;
                        break;
                      case "wed":
                        wedChecked = true;
                        break;
                      case "thu":
                        thuChecked = true;
                        break;
                      case "fri":
                        friChecked = true;
                        break;
                      case "sat":
                        satChecked = true;
                        break;
                      default:
                        break;
                    }
                  });
                } else if (item.frequency === "monthly") {
                  selectedFrequency = {
                    id: 3,
                    label: "Monthly",
                    value: "Monthly"
                  };
                  selectedMonthly = {
                    label: item.monthlyFreq,
                    name: item.monthlyFreq
                  };
                }

                this.setState({
                  subscriptionRptTitle: item.name,
                  subscriptionId: item._id,
                  showNewReportSubDialog: true,
                  selectedRadioOption: rptType,
                  toggleSubActive: status,
                  selectedFrequency,
                  monChecked,
                  tueChecked,
                  wedChecked,
                  thuChecked,
                  friChecked,
                  satChecked,
                  sunChecked,
                  selectedMonthly,
                  selectedBookmark,
                  deleteRptSubItem: item
                });
              }}
            >
              {item.name}
            </div>
          </div>
        </td>
        <td>{item.path}</td>
        <td>{bookmarkName}</td>
        <td>{item.status}</td>
        <td className="delete-item">
          <div
            className="delete-div-item"
            style={{ cursor: "pointer" }}
            onClick={() => {
              this.setState({
                deleteRptSubItem: item,
                showDeleteReportSubscription: true
              });
              //this.props.deleteReportSubscription(item._id);
            }}
          >
            <DeleteIcon
              className={"delete-item"}
              height={"22px"}
              width={"28px"}
              color={"rgba(88, 89, 91, 1)"}
            />
          </div>
        </td>
        <td></td>
      </tr>
    );
  }
}

const mapStateToProps = state => ({
  reportSubscriptions: state.rptSubscriptions.reportSubscriptions,
  reportSubLoading: state.rptSubscriptions.loading
});

const mapDispatchToProps = dispatch => ({
  selectSubscription(subId, selected) {
    dispatch(selectSubscription(subId, selected));
  },
  saveReportSubscription(rptSub, update, user) {
    dispatch(saveReportSubscription(rptSub, update, null, user));
  },
  deleteReportSubscription(rptSubId, rptId, user) {
    dispatch(deleteReportSubscription(rptSubId, rptId, user));
  },
  addTabToNav(tab) {
    dispatch(addTabToNav(tab));
  }
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(RptSubscriptionItem);
