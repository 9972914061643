import React, { Component } from "react";
import ReportItem from "./reportItem";
import { RightChevron, CloseIcon } from "lib/icons";
import { connect } from "react-redux";
import { filterApps, setPerformCollapse, setCustomGroup, setReportGroup } from "reducers/appReducer";
import { removeTabFromNav, setSelectedTab } from "components/topLevelNav/reducers/topLevelNavReducer";
import { withRouter } from "react-router-dom";
import MDMItem from "./mdmItem";
import { DotLoader } from "react-spinners";
import {
  LiftModelIcon,
  PromoPlanIcon,
  BaselineIcon,
  InputSourceIcon,
  ReportSvg,
  FolderMgmtIcon,
  ExcelIcon,
  AppChartAnalytics,
  AppChartUser,
  AppChartBar,
  AppChartLine,
  AppChartLineDown,
  AppPie,
  FolderIcon,
  ManageUsersIcon,
  EnvelopeIcon
} from "lib/icons";

//import { LoadingIndicator } from "lib/loadingIndicator";

class AppsItem extends Component {
  constructor(props) {
    super(props);
    this.state = {
      collapsed: false,
      collapseReportItems: true
    };
    this.collapseHandler = this.collapseHandler.bind(this);
    this.renderCollapsible = this.renderCollapsible.bind(this);
    this.closeReportGroup = this.closeReportGroup.bind(this);
    this.goTo = this.goTo.bind(this);
  }
  collapseHandler() {
    this.setState(prevState => ({
      collapsed: !prevState.collapsed
    }));
  }
  componentDidUpdate() {
    const { performCollapseAction } = this.props;
    if (performCollapseAction) {
      // this.setState({ collapseReportItems: collapseFolders });
      this.props.setPerformCollapse(false);
    }
  }
  renderCollapsible(item) {
    if (item.collapsible) {
      return (
        <div className="menu-list-collapse">
          <RightChevron
            transform={this.state.collapsed ? "" : "rotate(90,12,12)"}
            height={"20px"}
            // width={"24px"}
            color={"rgba(88, 89, 91, 1)"}
            viewBox={"-1 2 24 24"}
          />
        </div>
      );
    }
  }

  renderReportItem(item) {
    const { collapsed } = this.state;
    if (!collapsed) {
      if (item.groups !== undefined) {
        return item.groups.map((workspace, wIndex) => {
          return (
            <ReportItem key={wIndex} workspace={workspace} wIndex={wIndex} handleClickOutside={this.props.handleClickOutside} />
          );
        }, this);
      } else {
        return (
          <div
            style={{
              display: "flex",
              flexDirection: "row"
            }}>
            <DotLoader
              // css={override}
              sizeUnit={"px"}
              size={15}
              color={"black"}
              loading={true}
            />
            <div
              style={{
                marginLeft: "10px",
                fontSize: "15px",
                fontStyle: "italic"
              }}>
              Loading Reports
            </div>
          </div>
        );
      }
    }

    //return appArr;
  }
  renderMdm(item) {
    const { collapsed } = this.state;
    if (!collapsed) {
      return <MDMItem handleClickOutside={this.props.handleClickOutside} mdmApp={item} />;
    }
  }
  goTo(route) {
    this.props.handleClickOutside();
    this.props.filterApps("", this.props.apps);
    this.props.history.push(route);
  }
  closeReportGroup(e, item) {
    e.preventDefault();
    e.stopPropagation();
    this.props.removeTabFromNav(item, 1);
    if (this.props.selectedTab[0] === -1) {
      //if selectedTab[0] is -1 that means you are on the home page so dont do anything
    } else if (this.props.tabs.length > 1) {
      let tabLocation = 1;
      this.props.tabs.forEach((tab, idx) => {
        if (tab.workspaceId === undefined) {
          if (item.type === tab.type) {
            tabLocation = idx;
          }
        } else {
          if (item.workspaceId === tab.workspaceId) {
            tabLocation = idx;
          }
        }
      });
      if (tabLocation === 0 && this.props.tabs.length > 2) {
        this.props.setSelectedTab([1, 0]);
        this.goTo(this.props.tabs[1].path);
      } else if (tabLocation === 0) {
        this.props.setSelectedTab([0, 0]);
        if (this.props.tabs[1].path === undefined) {
          this.goTo("/reports/landing/" + this.props.tabs[1].workspaceId);
        } else {
          this.goTo(this.props.tabs[1].path);
        }
      } else {
        if (this.props.tabs[tabLocation - 1].path === undefined) {
          this.goTo("/reports/landing/" + this.props.tabs[tabLocation - 1].workspaceId);
        } else {
          this.goTo(this.props.tabs[tabLocation - 1].path);
        }
        this.props.setSelectedTab([tabLocation - 1, 0]);
      }
    } else {
      this.goTo("/reports/landing");
      this.props.setCustomGroup("");
      this.props.setReportGroup("");
    }
  }

  render() {
    const { item } = this.props;
    let title;
    let selectItem = false;
    let selectedItem = [];
    let icon = null;

    if (item === undefined) {
      return <div />;
    } else {
      selectedItem = this.props.tabs.filter(tab => {
        return tab.type === item.type;
      });
      selectItem = selectedItem.length > 0;
      let color = "";
      switch (item.type) {
        case "acculift":
          color = "#008F7E";
          break;
        case "files":
          color = "rgba(31,109,78,1)";
          break;
        case "mdh":
          color = "#193259";
          break;
        case "tableau":
        case "reporting":
        case "powerBI":
          color = "#2E5CA6";
          break;
        case "promoOpt":
          color = "#521C3A";
          break;
        case "accubase":
          color = "#138CFF";
          break;
        case "userManagement":
          color = "red";
          break;
        case "reportSubscriptions":
          //TODO CHANGE THIS to proper color
          color = "#614996";
          break;
        default:
          break;
      }
      if (item.icon !== undefined) {
        switch (item.icon) {
          case 1:
            if (color === "") {
              icon = (
                <div className="dd-icon-list-item">
                  <AppChartAnalytics
                    height={"18px"}
                    // width={"24px"}
                    color={"#2E5CA6"}
                    viewBox={"-1 2 24 24"}
                  />
                </div>
              );
            } else {
              icon = (
                <div className="dd-icon-list-item">
                  <AppChartAnalytics
                    height={"18px"}
                    // width={"24px"}
                    color={color}
                    viewBox={"-1 2 24 24"}
                  />
                </div>
              );
            }
            break;
          case 2:
            if (color === "") {
              icon = (
                <div className="dd-icon-list-item">
                  <AppChartUser
                    height={"18px"}
                    // width={"24px"}
                    color={"#2E5CA6"}
                    viewBox={"-1 2 24 24"}
                  />
                </div>
              );
            } else {
              icon = (
                <div className="dd-icon-list-item">
                  <AppChartUser
                    height={"18px"}
                    // width={"24px"}
                    color={color}
                    viewBox={"-1 2 24 24"}
                  />
                </div>
              );
            }
            break;
          case 3:
            if (color === "") {
              icon = (
                <div className="dd-icon-list-item">
                  <AppChartBar
                    height={"18px"}
                    // width={"24px"}
                    color={"#2E5CA6"}
                    viewBox={"-1 2 24 24"}
                  />
                </div>
              );
            } else {
              icon = (
                <div className="dd-icon-list-item">
                  <AppChartBar
                    height={"18px"}
                    // width={"24px"}
                    color={color}
                    viewBox={"-1 2 24 24"}
                  />
                </div>
              );
            }
            break;
          case 4:
            if (color === "") {
              icon = (
                <div className="dd-icon-list-item">
                  <AppChartLine
                    height={"18px"}
                    // width={"24px"}
                    color={"#2E5CA6"}
                    viewBox={"-1 2 24 24"}
                  />
                </div>
              );
            } else {
              icon = (
                <div className="dd-icon-list-item">
                  <AppChartLine
                    height={"18px"}
                    // width={"24px"}
                    color={color}
                    viewBox={"-1 2 24 24"}
                  />
                </div>
              );
            }
            break;
          case 5:
            if (color === "") {
              icon = (
                <div className="dd-icon-list-item">
                  <AppChartLineDown
                    height={"18px"}
                    // width={"24px"}
                    color={"#2E5CA6"}
                    viewBox={"-1 2 24 24"}
                  />
                </div>
              );
            } else {
              icon = (
                <div className="dd-icon-list-item">
                  <AppChartLineDown
                    height={"18px"}
                    // width={"24px"}
                    color={color}
                    viewBox={"-1 2 24 24"}
                  />
                </div>
              );
            }
            break;
          case 6:
            if (color === "") {
              icon = (
                <div className="dd-icon-list-item">
                  <AppPie
                    height={"18px"}
                    // width={"24px"}
                    color={"#2E5CA6"}
                    viewBox={"-1 2 24 24"}
                  />
                </div>
              );
            } else {
              icon = (
                <div className="dd-icon-list-item">
                  <AppPie
                    height={"18px"}
                    // width={"24px"}
                    color={color}
                    viewBox={"-1 2 24 24"}
                  />
                </div>
              );
            }
            break;
          case 7:
          case "files":
            if (color === "") {
              icon = (
                <div className="dd-icon-list-item">
                  <FolderIcon
                    height={"18px"}
                    // width={"24px"}
                    color={"rgba(31,109,78,1)"}
                    viewBox={"-1 2 24 24"}
                  />
                </div>
              );
            } else {
              icon = (
                <div className="dd-icon-list-item">
                  <FolderIcon
                    height={"18px"}
                    // width={"24px"}
                    color={color}
                    viewBox={"-1 2 24 24"}
                  />
                </div>
              );
            }
            break;
          case 8:
          case "acculift":
            if (color === "") {
              icon = (
                <div className="dd-icon-list-item">
                  <LiftModelIcon
                    height={"18px"}
                    // width={"24px"}
                    color={"#008F7E"}
                    viewBox={"-1 2 24 24"}
                  />
                </div>
              );
            } else {
              icon = (
                <div className="dd-icon-list-item">
                  <LiftModelIcon
                    height={"18px"}
                    // width={"24px"}
                    color={color}
                    viewBox={"-1 2 24 24"}
                  />
                </div>
              );
            }
            break;
          case 9:
          case "promoOpt":
            if (color === "") {
              icon = (
                <div className="dd-icon-list-item">
                  <PromoPlanIcon
                    height={"18px"}
                    // width={"24px"}
                    color={"#521C3A"}
                    fill={"#521C3A"}
                    viewBox={"-1 2 24 24"}
                  />
                </div>
              );
            } else {
              icon = (
                <div className="dd-icon-list-item">
                  <PromoPlanIcon
                    height={"18px"}
                    // width={"24px"}
                    color={color}
                    fill={color}
                    viewBox={"-1 2 24 24"}
                  />
                </div>
              );
            }
            break;
          case 10:
          case "accubase":
            if (color === "") {
              icon = (
                <div className="dd-icon-list-item">
                  <BaselineIcon
                    height={"18px"}
                    // width={"24px"}
                    color={"#138CFF"}
                    viewBox={"-1 2 24 24"}
                  />
                </div>
              );
            } else {
              icon = (
                <div className="dd-icon-list-item">
                  <BaselineIcon
                    height={"18px"}
                    // width={"24px"}
                    color={color}
                    viewBox={"-1 2 24 24"}
                  />
                </div>
              );
            }
            break;
          case 11:
          case "userManagement":
          case "mdm":
            if (color === "") {
              icon = (
                <div className="dd-icon-list-item">
                  <InputSourceIcon
                    height={"18px"}
                    // width={"24px"}
                    color={"#193259"}
                    fill={"#193259"}
                    viewBox={"-1 2 24 24"}
                  />
                </div>
              );
            } else {
              icon = (
                <div className="dd-icon-list-item">
                  <InputSourceIcon
                    height={"18px"}
                    // width={"24px"}
                    color={color}
                    fill={color}
                    viewBox={"-1 2 24 24"}
                  />
                </div>
              );
            }
            break;
          case 12:
          case "powerBI":
          case "tableau":
            if (color === "") {
              icon = (
                <div className="dd-icon-list-item" style={{ marginTop: "-3px" }}>
                  <ReportSvg
                    height={"16px"}
                    // width={"24px"}
                    color={"#2E5CA6"}
                    fill={"#2E5CA6"}
                    // viewBox={"0 -1 24 24"}
                  />
                </div>
              );
            } else {
              icon = (
                <div className="dd-icon-list-item" style={{ marginTop: "-3px" }}>
                  <ReportSvg
                    height={"16px"}
                    // width={"24px"}
                    color={color}
                    fill={color}
                    // viewBox={"0 -1 24 24"}
                  />
                </div>
              );
            }
            break;
          case "powerBIMgmt":
            if (color === "") {
              icon = (
                <div className="dd-icon-list-item" style={{ marginTop: "1px" }}>
                  <FolderMgmtIcon
                    height={"18px"}
                    // width={"24px"}
                    color={"#2E5CA6"}
                    fill={"#2E5CA6"}
                    viewBox={"-1 2 24 24"}
                  />
                </div>
              );
            } else {
              icon = (
                <div className="dd-icon-list-item" style={{ marginTop: "1px" }}>
                  <FolderMgmtIcon
                    height={"18px"}
                    // width={"24px"}
                    color={color}
                    fill={color}
                    viewBox={"-1 2 24 24"}
                  />
                </div>
              );
            }
            break;
          case "reportSubscriptions":
            if (color === "") {
              icon = (
                <div className="dd-icon-list-item">
                  <EnvelopeIcon
                    height={"18px"}
                    // width={"24px"}
                    color={"#138CFF"}
                    viewBox={"-1 2 24 24"}
                  />
                </div>
              );
            } else {
              icon = (
                <div className="dd-icon-list-item">
                  <EnvelopeIcon
                    height={"18px"}
                    // width={"24px"}
                    color={color}
                    viewBox={"-1 2 24 24"}
                  />
                </div>
              );
            }
            break;
          default:
            break;
        }
      }
      switch (item.type) {
        case "reporting":
          return (
            <div className="dd-list-item " key={item.id}>
              <div className="dd-list-title" onClick={() => this.collapseHandler()}>
                {icon === null ? (
                  <div className="dd-icon-list-item">
                    <ReportSvg height={"14px"} color={"#2E5CA6"} className="noHover" />
                  </div>
                ) : (
                  icon
                )}
                {item.title}
                {this.renderCollapsible(item)}
              </div>
              <div className="dd-list-subtitle">{this.renderReportItem(item)}</div>
            </div>
          );

        case "files":
          title = item.alias;
          if (title === undefined) {
            title = item.title;
          }
          return (
            <div
              className="dd-list-item hover-state"
              style={{ display: "flex" }}
              key={item.id}
              onClick={() => this.goTo(item.link)}>
              <div className="dd-list-title">
                {icon === null ? (
                  <div className="dd-icon-list-item">
                    <ExcelIcon height={"20px"} color={"rgba(31,109,78,1)"} viewBox={"-1 2 24 24"} className="noHover" />
                  </div>
                ) : (
                  icon
                )}
                {title}
              </div>
              {selectItem && (
                <div
                  onClick={e => {
                    this.closeReportGroup(e, item);
                  }}
                  className="close-x">
                  <CloseIcon height={"10px"} viewBox={"0 0 20 20"} className="close-icon" />
                </div>
              )}
            </div>
          );
        case "powerBIMgmt":
          return (
            <div
              className="dd-list-item hover-state"
              key={item.id}
              onClick={() => this.goTo("/powerBIMgmt")}
              style={{ display: "flex" }}>
              <div className="dd-list-title">
                {icon === null ? (
                  <div className="dd-icon-list-item">
                    <FolderMgmtIcon height={"16px"} color={"#997000"} viewBox={"-1 2 24 24"} className="noHover" />
                  </div>
                ) : (
                  icon
                )}
                {item.title}
              </div>
              {selectItem && (
                <div
                  onClick={e => {
                    this.closeReportGroup(e, item);
                  }}
                  className="close-x">
                  <CloseIcon height={"10px"} viewBox={"0 0 20 20"} className="close-icon" />
                </div>
              )}
            </div>
          );
        case "accucast":
          title = item.alias;
          if (title === undefined) {
            title = item.title;
          }
          return (
            <div
              className="dd-list-item hover-state"
              style={{ display: "flex" }}
              key={item.id}
              onClick={() => this.goTo(item.link)}>
              <div className="dd-list-title">{title}</div>
              {selectItem && (
                <div
                  onClick={e => {
                    this.closeReportGroup(e, item);
                  }}
                  className="close-x">
                  <CloseIcon height={"10px"} viewBox={"0 0 20 20"} className="close-icon" />
                </div>
              )}
            </div>
          );
        case "accubase":
          title = item.alias;
          if (title === undefined) {
            title = item.title;
          }
          return (
            <div
              className="dd-list-item hover-state"
              style={{ display: "flex" }}
              key={item.id}
              onClick={() => this.goTo(item.link)}>
              {" "}
              <div className="dd-list-title">
                {icon === null ? (
                  <div className="dd-icon-list-item">
                    <BaselineIcon height={"18px"} color={"#138CFF"} viewBox={"-1 2 24 24"} className="noHover" />
                  </div>
                ) : (
                  icon
                )}
                {title}
              </div>
              {selectItem && (
                <div
                  onClick={e => {
                    this.closeReportGroup(e, item);
                  }}
                  className="close-x">
                  <CloseIcon height={"10px"} viewBox={"0 0 20 20"} className="close-icon" />
                </div>
              )}
            </div>
          );
        case "acculift":
          title = item.alias;
          if (title === undefined) {
            title = item.title;
          }
          return (
            <div
              className="dd-list-item hover-state"
              style={{ display: "flex" }}
              key={item.id}
              onClick={() => this.goTo(item.link)}>
              <div className="dd-list-title">
                {icon === null ? (
                  <div className="dd-icon-list-item">
                    <LiftModelIcon
                      height={"18px"}
                      color={"#008F7E"}
                      fill={"black"}
                      viewBox={"-1 2 24 24"}
                      className="noHover"
                    />
                  </div>
                ) : (
                  icon
                )}
                {title}
              </div>
              {selectItem && (
                <div
                  onClick={e => {
                    this.closeReportGroup(e, item);
                  }}
                  className="close-x">
                  <CloseIcon height={"10px"} viewBox={"0 0 20 20"} className="close-icon" />
                </div>
              )}
            </div>
          );
        case "promoOpt":
          title = item.alias;
          if (title === undefined) {
            title = item.title;
          }
          return (
            <div
              className="dd-list-item hover-state"
              style={{ display: "flex" }}
              key={item.id}
              onClick={() => this.goTo(item.link)}>
              <div className="dd-list-title">
                {icon === null ? (
                  <div className="dd-icon-list-item">
                    <PromoPlanIcon height={"18px"} color={"#521C3A"} viewBox={"-1 2 24 24"} className="noHover" />
                  </div>
                ) : (
                  icon
                )}
                {title}
              </div>
              {selectItem && (
                <div
                  onClick={e => {
                    this.closeReportGroup(e, item);
                  }}
                  className="close-x">
                  <CloseIcon height={"10px"} viewBox={"0 0 20 20"} className="close-icon" />
                </div>
              )}
            </div>
          );
        case "mdh":
          title = item.alias;
          if (title === undefined) {
            title = item.title;
          }
          return (
            <div className="dd-list-item " key={item.id}>
              <div className="dd-list-title" onClick={() => this.collapseHandler()}>
                {icon === null ? (
                  <div className="dd-icon-list-item">
                    <InputSourceIcon height={"18px"} color={"#193259"} viewBox={"-1 2 24 24"} className="noHover" />
                  </div>
                ) : (
                  icon
                )}
                {title}
                {this.renderCollapsible(item)}
              </div>
              <div className="dd-list-subtitle">{this.renderMdm(item)}</div>
            </div>
          );
        case "userManagement":
          title = item.alias;
          if (title === undefined) {
            title = item.title;
          }
          return (
            <div
              className="dd-list-item hover-state"
              style={{ display: "flex" }}
              key={item.id}
              onClick={() => this.goTo(item.link)}>
              <div className="dd-list-title">
                {icon === null ? (
                  <div className="dd-icon-list-item" style={{ marginRight: "8px" }}>
                    <ManageUsersIcon height={"17px"} color={"#521C3A"} viewBox={"0 0 18 18"} className="noHover" />
                  </div>
                ) : (
                  icon
                )}
                {title}
              </div>
              {selectItem && (
                <div
                  onClick={e => {
                    this.closeReportGroup(e, item);
                  }}
                  className="close-x">
                  <CloseIcon height={"10px"} viewBox={"0 0 20 20"} className="close-icon" />
                </div>
              )}
            </div>
          );

        case "reportSubscriptions":
          title = item.alias;
          if (title === undefined) {
            title = item.title;
          }
          return (
            <div
              className="dd-list-item hover-state"
              style={{ display: "flex" }}
              key={item.id}
              onClick={() => this.goTo("/reportSubscriptions")}>
              <div className="dd-list-title">
                {icon === null ? (
                  <div className="dd-icon-list-item" style={{ marginRight: "8px" }}>
                    <EnvelopeIcon height={"17px"} color={color} viewBox={"0 0 18 18"} className="noHover" />
                  </div>
                ) : (
                  icon
                )}
                {title}
              </div>
              {selectItem && (
                <div
                  onClick={e => {
                    this.closeReportGroup(e, item);
                  }}
                  className="close-x">
                  <CloseIcon height={"10px"} viewBox={"0 0 20 20"} className="close-icon" />
                </div>
              )}
            </div>
          );

        default:
          return <div />;
      }
      // if (item.type === "powerBI") {
      //   return (
      //     <li className="dd-list-item" key={item.id}>
      //       <div className="dd-list-title" onClick={() => this.collapseHandler()}>
      //         {item.title}
      //         {this.renderCollapsible(item)}
      //       </div>
      //       <div className="dd-list-subtitle">{this.renderPowerBIItem(item)}</div>
      //     </li>
      //   );
      // } else if (item.type === "files" || item.type === "accucast") {
      //   return (
      //     <li
      //       className="dd-list-item"
      //       key={item.id}
      //       onClick={() => this.goTo(item.link)}
      //     >
      //       <div className="dd-list-title">{item.title}</div>
      //     </li>
      //   );
      // }
    }
  }
}

const mapStateToProps = state => ({
  apps: state.app.apps,
  collapseFolders: state.app.collapseFolders,
  defaultApp: state.app.defaultApp,
  tabs: state.topLevelNav.tabs,
  performCollapseAction: state.app.performCollapseAction,
  selectedTab: state.topLevelNav.selectedTab
});
const mapDispatchToProps = dispatch => ({
  filterApps(filter, apps) {
    dispatch(filterApps(filter, apps));
  },
  setPerformCollapse(status) {
    dispatch(setPerformCollapse(status));
  },
  removeTabFromNav(tab, index) {
    dispatch(removeTabFromNav(tab, index));
  },
  setSelectedTab(tab) {
    dispatch(setSelectedTab(tab));
  },
  setReportGroup(group) {
    dispatch(setReportGroup(group));
  },
  setCustomGroup(group) {
    dispatch(setCustomGroup(group));
  }
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(AppsItem));
