import { accucastLEWorkflow } from "components/accucast/components/lib";
import history from "lib/history";

/* -------------------- INITIAL STATE -------------------- */
const INITIAL_STATE = {
  currentStep: 1,
  leWeightingStatus: "incomplete",
  leWeightingOverridesStatus: "incomplete",
  leSeasonalityStatus: "incomplete",
  leSeasonalityOverridesStatus: "incomplete",
  storeDistributionStatus: "incomplete",
  incrementalLiftStatus: "incomplete",
  shipmentsStatus: "incomplete"
};

/* -------------------- ACTION TYPES -------------------- */
const NEXT_STEP = "NEXT_STEP";
const RESET_STEPS = "RESET_STEPS";
const SET_STEP = "SET_STEP";

/* -------------------- ACTION CREATORS -------------------- */
const nextStep = curStep => {
  return { type: NEXT_STEP, curStep };
};
const resetSteps = () => {
  return { type: RESET_STEPS };
};
const setStep = curStep => {
  return { type: SET_STEP, curStep };
};

/* -------------------- ASYNC ACTION CREATORS -------------------- */
export const nextWorkflowStep = curStep => {
  return dispatch => {
    history.push(accucastLEWorkflow[Math.floor(curStep) + 1]);
    dispatch(nextStep(curStep));
  };
};

export const resetWorkflowSteps = () => {
  return dispatch => {
    history.push(accucastLEWorkflow[1]);
    dispatch(resetSteps());
  };
};

export const setWorkflowStep = curStep => {
  return dispatch => {
    dispatch(setStep(curStep));
  };
};

/* -------------------- REDUCER -------------------- */
export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case NEXT_STEP:
      return { ...state, currentStep: Math.floor(action.curStep) + 1 };
    case RESET_STEPS:
      return { ...state, currentStep: 1 };
    case SET_STEP:
      return { ...state, currentStep: action.curStep };
    default:
      return state;
  }
};
