import React, { Component } from "react";
import { Gear } from "lib/icons";
import "./style.css";
import { DeleteIcon, Pen, DownloadIcon } from "lib/icons";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";

import { DotLoader } from "react-spinners";
import { connect } from "react-redux";
import { save } from "save-file";
import axios from "axios";
import ReactDOM from "react-dom";
import { extractFileName } from "lib/lib";
import { toast } from "react-toastify";

import { renameBaseline, getProjects } from "components/acculift/reducers/acculiftReducer";

class ActionCellRenderer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      opened: false,
      showRenameModal: false,
      showDeleteBaselineConfirmModal: false,
      deletingBaseline: false
    };

    this.BaselineNameInput = React.createRef();
    this.clickHandler = this.clickHandler.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.downloadHandler = this.downloadHandler.bind(this);
    this.Manage = React.createRef();
    this.handleClickOutside = this.handleClickOutside.bind(this);
  }

  componentWillUnmount() {
    document.removeEventListener("mousedown", this.handleClickOutside);
  }
  handleClickOutside(event) {
    if (event.target.className.animVal === null || event.target.className.animVal === undefined) {
      const area = ReactDOM.findDOMNode(this.Manage.current);

      if (area !== null) {
        if (!area.parentElement.contains(event.target)) {
          this.setState({ opened: false });
        }
      }
    }
    // else if(event.target.className.animVal.includes("gear-action-cell"+this.props.rowIndex)){
    //   //this.setState({ opened: !opened });
    // }
  }
  componentDidMount() {
    document.addEventListener("mousedown", this.handleClickOutside);
  }

  handleClose() {
    this.setState({
      showRenameModal: false,
      showDeleteBaselineConfirmModal: false
    });
  }
  clickHandler() {
    this.setState({ opened: !this.state.opened });
  }

  renameHandler(id, batchId) {
    this.props.renameBaseline(id, this.BaselineNameInput.current.value, batchId);
    this.handleClose();
  }

  deleteHandler() {
    this.setState({ deletingBaseline: true });
    axios
      .get(`${process.env.REACT_APP_BIGHORN_SERVER}/api/accubase/deleteBaseline/${this.props.data.id}`, {
        headers: {
          ClientToken: localStorage.getItem("clientToken")
        }
      })
      .then(res => {
        this.props.getProjects(false);

        this.handleClose();
        this.setState({ deletingBaseline: false });
        toast.success(this.props.data.name + " Deleted");
      });
  }
  downloadHandler() {
    axios
      .post(
        `${process.env.REACT_APP_BIGHORN_SERVER}/api/accubase/downloadBaseline`,
        { baselineId: this.props.data.id, baselineName: this.props.data.name },
        {
          headers: {
            ClientToken: localStorage.getItem("clientToken")
          }
        }
      )
      .then(res => {
        var filename = extractFileName(res.headers["content-disposition"]);
        console.log("File name", filename);
        //invoke 'Save' dialog
        save(res.data, filename);
      });
  }
  render() {
    const { showRenameModal, showDeleteBaselineConfirmModal } = this.state;

    return (
      <div className="dropdown-wrapper">
        <Modal isOpen={showRenameModal}>
          <ModalHeader>Rename Baseline</ModalHeader>
          <ModalBody>
            <div>
              <input style={{ width: "100%" }} defaultValue={this.props.data.name} ref={this.BaselineNameInput} />
            </div>
          </ModalBody>

          <ModalFooter>
            {!this.state.deletingProject ? (
              <Button
                color="primary"
                onClick={() => {
                  this.renameHandler(this.props.data.id, this.props.data.batchId);
                }}>
                Rename
              </Button>
            ) : (
              <div
                className="accubase-uploading"
                style={{
                  marginLeft: "auto",

                  display: "flex",
                  flexDirection: "row"
                }}>
                <DotLoader sizeUnit={"px"} size={15} color={"black"} loading={true} />
                <div style={{ marginLeft: "5px" }}>Updating ...</div>
              </div>
            )}
            <Button onClick={this.handleClose}>Cancel</Button>
          </ModalFooter>
        </Modal>
        <Modal isOpen={showDeleteBaselineConfirmModal}>
          <ModalHeader>Delete Baseline </ModalHeader>
          <ModalBody>
            <div>
              Are you sure you want to delete this Baseline ({this.props.data.name})? All Corresponding Lifts will be deleted as
              well. WARNING: This cannot be undone.
            </div>
          </ModalBody>

          <ModalFooter>
            {!this.state.deletingBaseline ? (
              <Button
                color="primary"
                onClick={() => {
                  this.deleteHandler("baseline");
                }}>
                Delete
              </Button>
            ) : (
              <div
                className="accubase-uploading"
                style={{
                  marginLeft: "auto",

                  display: "flex",
                  flexDirection: "row"
                }}>
                <DotLoader sizeUnit={"px"} size={15} color={"black"} loading={true} />
                <div style={{ marginLeft: "5px" }}>Deleting ...</div>
              </div>
            )}
            <Button onClick={this.handleClose}>Cancel</Button>
          </ModalFooter>
        </Modal>

        <div
          style={{ cursor: "pointer" }}
          onClick={() => {
            this.clickHandler();
          }}>
          <Gear
            className={"selectable gear-action-cell" + this.props.rowIndex}
            height={"20px"}
            width={"20px"}
            color={"rgba(165, 165, 165, 1)"}
          />
        </div>
        {this.state.opened && (
          <ul
            className="dropdown-menu"
            ref={this.Manage}
            style={{
              borderRadius: "0",
              borderBottom: "1px solid rgb(229, 230, 232)",
              position: "absolute",
              left: "-128px",
              display: "inline-block",
              paddingTop: "0px",
              paddingBottom: "0px",
              boxShadow: "0px 3px 6px rgba(0, 0, 0, 0.16)"
            }}>
            <li
              style={{
                cursor: "pointer",
                borderBottom: "1px solid rgb(229, 230, 232)",
                paddingLeft: "10px",
                paddingRight: "10px"
              }}
              className="menu-item"
              onClick={() => {
                this.downloadHandler();
                this.setState({ opened: false });
              }}>
              <DownloadIcon
                height={"16px"}
                width={"16px"}
                color={"rgba(165, 165, 165, 1)"}
                style={{
                  cursor: "pointer",
                  marginRight: "10px",
                  marginBottom: "3px"
                }}
              />
              Download
            </li>
            <li
              style={{
                cursor: "pointer",
                borderBottom: "1px solid rgb(229, 230, 232)",
                paddingLeft: "10px",
                paddingRight: "10px"
              }}
              className="menu-item"
              onClick={() => {
                this.setState({ showRenameModal: true, opened: false });
              }}>
              <Pen
                height={"16px"}
                width={"16px"}
                color={"rgba(165, 165, 165, 1)"}
                style={{ marginRight: "10px", cursor: "pointer" }}
              />
              Rename
            </li>
            <li
              style={{
                cursor: "pointer",
                paddingLeft: "10px",
                paddingRight: "10px"
              }}
              className="menu-item"
              onClick={() => {
                this.setState({
                  showDeleteBaselineConfirmModal: true,
                  opened: false
                });
              }}>
              <DeleteIcon
                height={"16px"}
                width={"16px"}
                color={"rgba(165, 165, 165, 1)"}
                style={{
                  cursor: "pointer",
                  marginBottom: "3px",
                  marginRight: "10px"
                }}
              />
              Delete
            </li>
          </ul>
        )}
      </div>
    );
  }
}

const mapStateToProps = state => ({});
const mapDispatchToProps = dispatch => ({
  renameBaseline(id, name, bId) {
    dispatch(renameBaseline(id, name, bId));
  },
  getProjects(selectProject = true) {
    dispatch(getProjects(selectProject));
  }
});

export default connect(mapStateToProps, mapDispatchToProps)(ActionCellRenderer);
