import React, { Component } from "react";

export class EditableCellPercentRenderer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      value: props.value,
      type: props.data.type
    };
  }

  // noinspection JSUnusedGlobalSymbols
  refresh(params) {
    if (params.value !== this.state.value) {
      this.setState({
        value: params.value
      });
    }
    return true;
  }

  render() {
    let { value } = this.state;
    if(Math.sign(this.props.data.id)===-1 ){
      return <div></div>;
    }else{
      let returned;
      returned = (
        <div style={{ justifyContent: "center", display: "flex" }}>
          <div
            style={{
              width: "44px",
              height: "25px",
              borderBottom: "1px solid rgba(112, 112, 112, 1)"
            }}
          >
            {value}%
          </div>
        </div>
      );

      return <div>{returned}</div>;
    }
  }
}
