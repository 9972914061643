import React, { Component } from "react";
import { connect } from "react-redux";
import { selectLift, renameLift, getProjects } from "components/acculift/reducers/acculiftReducer";
import ReactDOM from "react-dom";
import { Col } from "react-flexbox-grid";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import { Gear, LiftModelIcon, DeleteIcon, Pen, DownloadIcon, FinalBaseline } from "lib/icons";
import { DotLoader } from "react-spinners";

import { toast } from "react-toastify";
import { save } from "save-file";
import { extractFileName } from "lib/lib";
import axios from "axios";
import { OverlayTrigger, Tooltip } from "react-bootstrap";

class LiftCard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      manageOpened: false,
      editLift: false,
      showDeleteLiftConfirmModal: false,
      deletingLift: false,
      showCannotDeleteModal: false,
      showFinalToolTip: false
    };
    this.reviewLiftModel = this.reviewLiftModel.bind(this);
    this.manageClickHandler = this.manageClickHandler.bind(this);
    this.Manage = React.createRef();
    this.liftNameInput = React.createRef();
    this.handleClose = this.handleClose.bind(this);
    this.handleClickOutside = this.handleClickOutside.bind(this);
  }
  componentWillUnmount() {
    document.removeEventListener("mousedown", this.handleClickOutside);
  }
  handleClickOutside(event) {
    const area = ReactDOM.findDOMNode(this.Manage.current);
    if (area !== null) {
      if (!area.parentElement.contains(event.target)) {
        this.setState({ manageOpened: false });
      }
    }
  }
  componentDidMount() {
    document.addEventListener("mousedown", this.handleClickOutside);
  }
  reviewLiftModel(lift) {
    this.props.selectLift(lift, 2);
  }
  manageClickHandler() {
    this.setState({ manageOpened: !this.state.manageOpened });
  }
  renameLift(lift, name) {
    this.props.renameLift(lift.id, name, this.props.baseline.id);
  }
  keyDownHandler(event) {
    if (event.key === "Enter") {
      this.props.renameLift(this.props.lift.id, this.liftNameInput.current.value, this.props.baseline.id);

      this.setState({
        editLift: false
      });
    } else if (event.key === "Escape") {
      this.setState({ editLift: false });
    }
  }
  deleteHandler(type) {
    if (type === "lift") {
      this.setState({ deletingLift: true });
      axios
        .get(`${process.env.REACT_APP_BIGHORN_SERVER}/api/acculift/deleteLift/${this.props.lift.id}`, {
          headers: {
            ClientToken: localStorage.getItem("clientToken")
          }
        })
        .then(res => {
          this.props.getProjects();

          this.setState({
            deletingLift: false,
            showDeleteLiftConfirmModal: false
          });
          toast.success("Lift: " + this.props.lift.name + " Deleted");
        });
    }
  }

  downloadHandler() {
    toast.success("Preparing Lift Model Download");
    axios
      .post(
        `${process.env.REACT_APP_BIGHORN_SERVER}/api/acculift/downloadLift`,
        { liftId: this.props.lift.id, liftName: this.props.lift.name },
        {
          headers: {
            ClientToken: localStorage.getItem("clientToken")
          }
        }
      )
      .then(res => {
        var filename = extractFileName(res.headers["content-disposition"]);
        console.log("File name", filename);
        this.setState({ manageOpened: false });
        //invoke 'Save' dialog
        save(res.data, filename);
      });
  }
  handleClose() {
    this.setState({
      showCannotDeleteModal: false,
      showDeleteLiftConfirmModal: false
    });
  }
  renderFinalVersion() {
    const { lift, baseline } = this.props;
    let finalCount = {};

    if (baseline.lifts !== undefined) {
      finalCount = baseline.lifts.filter(item => {
        return item.finalVersion === true;
      });
    }

    if (lift.finalVersion === false) {
      if (baseline.final) {
        if (finalCount.length === 0) {
          return (
            <div
              style={{
                cursor: "pointer",
                padding: "5px"
              }}
              className={"menu-item " + (finalCount.length === 0 && baseline.final === true ? "" : "disabled-card-menu-item")}
              onClick={() => {
                if (finalCount.length === 0 && baseline.final === true) {
                  this.props.lift.finalVersion = true;
                  this.setState({ manageOpened: false });
                  axios
                    .post(
                      `${process.env.REACT_APP_BIGHORN_SERVER}/api/acculift/makeFinal`,
                      { liftId: this.props.lift.id, status: true },
                      {
                        headers: {
                          ClientToken: localStorage.getItem("clientToken")
                        }
                      }
                    )
                    .then(res => {
                      //this.setState({ manageOpened: false });
                      //this.props.getProjects();
                    });
                  //this.setState({ showDeleteLiftConfirmModal: true });
                }
              }}>
              <FinalBaseline
                height={"16px"}
                width={"16px"}
                color={"rgba(165, 165, 165, 1)"}
                style={{
                  cursor: "pointer",
                  marginBottom: "3px",
                  marginRight: "10px"
                }}
              />
              Make Final
            </div>
          );
        } else {
          return (
            <div
              onMouseEnter={() => {
                this.setState({ showFinalToolTip: true });
              }}
              onMouseLeave={() => {
                this.setState({ showFinalToolTip: false });
              }}
              style={{
                cursor: "pointer",
                padding: "5px"
              }}
              className={"menu-item disabled-card-menu-item"}>
              {this.state.showFinalToolTip ? (
                <div
                  className="tool-tip"
                  style={{
                    padding: "10px",
                    position: "absolute",
                    top: "80px",
                    left: "110px",
                    background: "white"
                  }}>
                  This baseline already has a <br /> lift model marked as final.
                </div>
              ) : (
                <div></div>
              )}
              <FinalBaseline
                height={"16px"}
                width={"16px"}
                color={"rgba(165, 165, 165, 1)"}
                style={{
                  cursor: "pointer",
                  marginBottom: "3px",
                  marginRight: "10px"
                }}
              />
              Make Final
            </div>
          );
        }
      } else {
        return (
          <div
            onMouseEnter={() => {
              this.setState({ showFinalToolTip: true });
            }}
            onMouseLeave={() => {
              this.setState({ showFinalToolTip: false });
            }}
            style={{
              cursor: "pointer",
              padding: "5px"
            }}
            className={"menu-item disabled-card-menu-item"}>
            {this.state.showFinalToolTip ? (
              <div
                className="tool-tip"
                style={{
                  padding: "10px",
                  position: "absolute",
                  top: "80px",
                  left: "110px",
                  background: "white"
                }}>
                Mark the source baseline as final
                <br /> before finalizing lift model
              </div>
            ) : (
              <div></div>
            )}
            <FinalBaseline
              height={"16px"}
              width={"16px"}
              color={"rgba(165, 165, 165, 1)"}
              style={{
                cursor: "pointer",
                marginBottom: "3px",
                marginRight: "10px"
              }}
            />
            Make Final
          </div>
        );
      }
    } else {
      return (
        <div
          style={{
            cursor: "pointer",
            padding: "5px"
          }}
          className="menu-item"
          onClick={() => {
            this.props.lift.finalVersion = false;
            this.setState({ manageOpened: false });
            axios
              .post(
                `${process.env.REACT_APP_BIGHORN_SERVER}/api/acculift/makeFinal`,
                { liftId: this.props.lift.id, status: false },
                {
                  headers: {
                    ClientToken: localStorage.getItem("clientToken")
                  }
                }
              )
              .then(res => {
                // this.setState({ manageOpened: false });
                // this.props.getProjects();
              });
            //this.setState({ showDeleteLiftConfirmModal: true });
          }}>
          <FinalBaseline
            height={"16px"}
            width={"16px"}
            color={"rgba(165, 165, 165, 1)"}
            style={{
              cursor: "pointer",
              marginBottom: "3px",
              marginRight: "10px"
            }}
          />
          Unset Final
        </div>
      );
    }
  }
  render() {
    const { lift } = this.props;
    const { showDeleteLiftConfirmModal, showCannotDeleteModal } = this.state;

    return (
      <Col
        // key={wIndex}
        // smOffset={2}
        // xs={6}
        // xsOffset={4}
        // sm={4}
        // md={3}
        // lg={2}
        // xl={3}
        key={this.props.index}
        className="card">
        <Modal isOpen={showCannotDeleteModal}>
          <ModalHeader>Cannot Delete Lift</ModalHeader>
          <ModalBody>
            <div>Cannot delete a lift that is marked as final. Remove the final flag and try again.</div>
          </ModalBody>

          <ModalFooter>
            <Button onClick={this.handleClose}>Close</Button>
          </ModalFooter>
        </Modal>

        <Modal isOpen={showDeleteLiftConfirmModal}>
          <ModalHeader>Delete Lift</ModalHeader>
          <ModalBody>
            <div>Are you sure you want to delete this lift and the data associated with it?</div>
          </ModalBody>

          <ModalFooter>
            {!this.state.deletingLift ? (
              <Button
                color="primary"
                onClick={() => {
                  this.deleteHandler("lift");
                }}>
                Delete
              </Button>
            ) : (
              <div
                className="accubase-uploading"
                style={{
                  marginLeft: "auto",

                  display: "flex",
                  flexDirection: "row"
                }}>
                <DotLoader sizeUnit={"px"} size={15} color={"black"} loading={true} />
                <div style={{ marginLeft: "5px" }}>Deleting ...</div>
              </div>
            )}
            <Button onClick={this.handleClose}>Cancel</Button>
          </ModalFooter>
        </Modal>
        <div
          style={{
            width: "100%",
            height: "30px",
            display: "flex",
            flexDirection: "row",
            paddingTop: "2px"
          }}>
          {lift.status === "running" && (
            <div
              style={{
                color: "rgb(196,152,108)",
                border: "1px solid rgb(251,238,223)",
                paddingTop: "3px",
                height: "23px",
                marginLeft: "-8px",
                paddingLeft: "5px",
                width: "136px",
                background: "rgb(251,238,223)"
              }}>
              CALC IN PROGRESS
            </div>
          )}
          {(lift.status === "failed" || lift.status === null) && (
            <div
              style={{
                color: "#721c24",
                border: "1px solid #f5c6cb",
                paddingTop: "3px",
                height: "23px",
                marginLeft: "-8px",
                paddingLeft: "5px",
                width: "50px",
                background: "#f8d7da"
              }}>
              Failed
            </div>
          )}
          {lift.finalVersion === true && (
            <OverlayTrigger key={"final-tip"} placement={"top"} overlay={<Tooltip id={"final-tip"}>Final Lift Model</Tooltip>}>
              <div>
                <FinalBaseline
                  height={"20px"}
                  width={"20px"}
                  color={"rgba(126, 126, 126, 1)"}
                  className="final-baseline"
                  style={{
                    marginBottom: "3px",
                    marginRight: "20px"
                  }}

                  // onClick={item => {
                  // }}
                />
              </div>
            </OverlayTrigger>
          )}
          <OverlayTrigger key={"manage-tip"} placement={"top"} overlay={<Tooltip id={"manage-tip"}>Manage Lift Model</Tooltip>}>
            <div
              onClick={() => {
                this.manageClickHandler();
              }}
              style={{
                marginLeft: "auto",
                cursor: "pointer",
                display: "flex",
                paddingTop: "1px",
                flexDirection: "row"
              }}>
              <Gear
                className={"selectable gear gearId" + this.props.lift.id}
                height={"20px"}
                width={"20px"}
                color={"rgba(165, 165, 165, 1)"}
              />
            </div>
          </OverlayTrigger>
          {this.state.manageOpened && (
            <div
              className="gear-menu"
              ref={this.Manage}
              style={{
                right: "4px",
                top: "34px",
                position: "absolute",
                zIndex: 1,
                background: "white",
                borderRadius: "0",
                border: "1px solid rgb(229, 230, 232)"
              }}>
              <div
                style={{
                  padding: "5px",
                  cursor: "pointer",
                  borderBottom: "1px solid rgb(229, 230, 232)"
                }}
                className={"menu-item " + (this.props.lift.status === "complete" ? "" : "disabled-card-menu-item")}
                onClick={() => {
                  if (this.props.lift.status === "complete") {
                    this.downloadHandler();
                    this.setState({ manageOpened: false });
                  }
                }}>
                <DownloadIcon
                  height={"16px"}
                  width={"16px"}
                  color={"rgba(165, 165, 165, 1)"}
                  style={{
                    cursor: "pointer",
                    marginRight: "10px",
                    marginBottom: "3px"
                  }}
                />
                Download
              </div>
              <div
                style={{
                  cursor: "pointer",
                  padding: "5px",
                  borderBottom: "1px solid rgb(229, 230, 232)"
                }}
                className="menu-item"
                onClick={() => {
                  this.setState({ editLift: true, manageOpened: false });
                }}>
                <Pen
                  height={"16px"}
                  width={"16px"}
                  color={"rgba(165, 165, 165, 1)"}
                  style={{ marginRight: "10px", cursor: "pointer" }}
                />
                Rename
              </div>

              <div
                style={{
                  cursor: "pointer",
                  padding: "5px",
                  borderBottom: "1px solid rgb(229, 230, 232)"
                }}
                className="menu-item"
                onClick={() => {
                  if (lift.finalVersion) {
                    this.setState({
                      showCannotDeleteModal: true,
                      manageOpened: false
                    });
                  } else {
                    this.setState({
                      showDeleteLiftConfirmModal: true,
                      manageOpened: false
                    });
                  }
                }}>
                <DeleteIcon
                  height={"16px"}
                  width={"16px"}
                  color={"rgba(165, 165, 165, 1)"}
                  style={{
                    cursor: "pointer",
                    marginBottom: "3px",
                    marginRight: "10px"
                  }}
                />
                Delete
              </div>
              {this.renderFinalVersion()}
            </div>
          )}
        </div>

        <div style={{ display: "flex", flexDirection: "row" }}>
          <div style={{}}>
            <LiftModelIcon height="20px" />
          </div>
          <div
            style={{
              flexDirection: "column",
              marginLeft: "10px",
              overflow: "hidden"
            }}>
            {this.state.editLift ? (
              <input
                style={{ width: "95%" }}
                defaultValue={lift.name}
                ref={this.liftNameInput}
                onChange={evt => {
                  if (evt.keyCode === 13) {
                    this.setState({ editLift: false });
                  }
                }}
                onKeyDown={event => {
                  lift.name = this.liftNameInput.current.value;
                  this.keyDownHandler(event);
                }}
              />
            ) : (
              <div className="card-title">{lift.name}</div>
            )}

            {/* <div className="card-details card-detail-value">
              Estimate Tactics by Model
            </div> */}
            {/* <div className="card-details">
              <span className="card-detail-heading">Histogram:</span>{' '}
              <span className="card-detail-value">{lift.histogram}</span>
            </div>
            <div className="card-details">
              <span className="card-detail-heading">Product Level: </span>
              <span className="card-detail-value">{lift.productLevel}</span>
            </div>
            <div className="card-details">
              <span className="card-detail-heading">Min sale: </span>
              <span className="card-detail-value">{lift.geoLevel}</span>
            </div> */}
          </div>
        </div>
        <div
          style={{
            borderBottom: "1px solid #DBDDDE",
            marginTop: "10px",
            marginBottom: "10px"
          }}></div>
        {/* <hr /> */}
        <div
          style={{
            cursor: "pointer",
            textAlign: "center",
            fontSize: "14px",
            fontWeight: "bold",
            color: "#0080AB"
          }}
          className={this.props.lift.status === "complete" ? "" : "disabled-card"}
          onClick={() => {
            if (this.props.lift.status === "complete") {
              this.reviewLiftModel(lift);
            }
          }}>
          Review + {">"}
        </div>
      </Col>
    );
  }
}

const mapStateToProps = state => ({});
const mapDispatchToProps = dispatch => ({
  selectLift(lift, viewSelected) {
    dispatch(selectLift(lift, viewSelected));
  },
  renameLift(id, name, baselineId) {
    dispatch(renameLift(id, name, baselineId));
  },
  getProjects() {
    dispatch(getProjects());
  }
});

export default connect(mapStateToProps, mapDispatchToProps)(LiftCard);
