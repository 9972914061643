import React, { Component } from "react";
import { connect } from "react-redux";
import { setExpandDialog } from "components/promoOpt/reducers/promoOptReducer";
import "./style.css";
import Select from "react-select";

class PromoSubsidyCellRenderer extends Component {
  constructor(props) {
    super(props);

    this.state = {
      value: props.value,
      expanded: false,
      subsidyOpts: [
        { id: 1, value: "manual", label: "Manual Entry" },
        { id: 2, value: "full", label: "Full Subsidy" },
        { id: 3, value: "maintain", label: "Maintain Retailer Margin" },
        { id: 4, value: "none", label: "No Subsidy" }
      ],
      selectedSubsidy: { id: 1, value: "manual", label: "Manual Entry" }
    };
    this.clickHandler = this.clickHandler.bind(this);
  }

  // noinspection JSUnusedGlobalSymbols
  refresh(params) {
    if (params.value !== this.state.value) {
      this.setState({
        value: params.value
      });
    }
    return true;
  }
  clickHandler() {
    //const {expanded} = this.state;
    //this.props.setExpandDialog('weeklyPromo');
    // let expanded = false;
    // if (this.props.api.getDisplayedRowAtIndex(this.props.rowIndex).master) {
    //   this.props.api.getDisplayedRowAtIndex(this.props.rowIndex).data.DIALOG =
    //     'weeklyPromo';
    //   expanded = this.props.api.getDisplayedRowAtIndex(this.props.rowIndex)
    //     .expanded;
    //   this.props.api
    //     .getDisplayedRowAtIndex(this.props.rowIndex)
    //     .setExpanded(!expanded);
    // } else {
    //   this.props.api.getDisplayedRowAtIndex(
    //     this.props.rowIndex + 1
    //   ).data.DIALOG = 'weeklyPromo';
    //   expanded = this.props.api.getDisplayedRowAtIndex(this.props.rowIndex + 1)
    //     .expanded;
    //   this.props.api
    //     .getDisplayedRowAtIndex(this.props.rowIndex + 1)
    //     .setExpanded(!expanded);
    // }
    // this.setState({ expanded: !expanded });
  }
  handleSubsidyChange = selectedOption => {
    let retailMargin = parseInt(this.props.data.PCT_RETAIL_MARGIN, 10);
    if (selectedOption.value === "maintain") {
      this.props.data.PCT_DISCOUNT_SUBSIDIZED = 100 - retailMargin;
    } else if (selectedOption.value === "full") {
      this.props.data.PCT_DISCOUNT_SUBSIDIZED = 100;
    } else if (selectedOption.value === "none") {
      this.props.data.PCT_DISCOUNT_SUBSIDIZED = 0;
    } else {
      this.props.data.PCT_DISCOUNT_SUBSIDIZED = 50;
    }
    this.setState({
      selectedSubsidy: selectedOption
    });
  };
  render() {
    if(Math.sign(this.props.data.id)===-1 ){
      return <div></div>;
    }else{
      let promoSubsidy = parseInt(this.props.data.PCT_DISCOUNT_SUBSIDIZED, 10);
      let retailMargin = parseInt(this.props.data.PCT_RETAIL_MARGIN, 10);

      let selectSub = this.state.selectedSubsidy;
      if (promoSubsidy === 100 - retailMargin) {
        selectSub = {
          id: 3,
          value: "maintain",
          label: "Maintain Retailer Margin"
        };
      } else if (promoSubsidy === 0) {
        selectSub = { id: 4, value: "none", label: "No Subsidy" };
      } else if (promoSubsidy === 100) {
        selectSub = { id: 2, value: "full", label: "Full Subsidy" };
      } else {
        selectSub = { id: 1, value: "manual", label: "Manual Entry" };
      }

      return (
        <div style={{ display: "flex", flexDirection: "row" }}>
          <Select
            className="begin-end-dropdown"
            classNamePrefix="react-select"
            placeholder={"Actions"}
            value={
              selectSub //isClearable
            }
            onChange={this.handleSubsidyChange}
            options={this.state.subsidyOpts}
          />
          <div>{promoSubsidy}%</div>
        </div>
      );
    }
  }
}

const mapStateToProps = state => ({
  dialog: state.promo.dialog
});
const mapDispatchToProps = dispatch => ({
  setExpandDialog(dialog) {
    dispatch(setExpandDialog(dialog));
  }
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PromoSubsidyCellRenderer);
