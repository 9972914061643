import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { Switch, Route } from "react-router-dom";
//import "components/accucast/components/style/style.css";
import Acculift from "./components/acculift";

class AcculiftRouter extends Component {
  render() {
    //const { match } = this.props;
    return (
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          // height: "100%",
          // minHeight: "100%",
          // width: "100%"
          position: "absolute",
          bottom: "0px",
          top: "60px",
          right: "0px",
          left: "0px"
          //height: "100%"
        }}
        className="acculift-router"
      >
        <Switch>
          <Route path={"/acculift"} render={props => <Acculift {...props} />} />
          {/* <Route path={match.path+"/le"} render={(props) => <UpdateLE {...props} />} />
          <Route path={match.path+"/plan"} render={(props) => <UpdatePlan {...props} />} /> */}
        </Switch>
      </div>
    );
  }
}

// const mapDispatchToProps = dispatch =>({
//   setWorkflowStep(curStep){
//     dispatch(setWorkflowStep(curStep));
//   }
// });
export default withRouter(AcculiftRouter);
