import React, { Component } from "react";
import "./style.css";

import { FilterIcon, RightChevron } from "lib/icons";
import { connect } from "react-redux";
import FilterItem from "./filterItem";

class FilterPanel extends Component {
  constructor(props) {
    super(props);

    this.renderFilterItems = this.renderFilterItems.bind(this);
  }

  orderFilterItems (filters){
    let retArr = [];
    let idx = 4; //We have 4 intentional filter item positions, anything else goes after
    filters.forEach(function(item) {
      switch (item.name) {
        case "GEO_KEY":
          retArr[0] = item;
          break;
        case "ITEM_PERCENTILE":
          retArr[1] = item;
          break;
        case "ITEM_KEY":
          retArr[2] = item;
          break;
        case "PRIMARY_TACTIC_ALIAS":
          retArr[3] = item;
          break;
        default: //Capture new filters in case we forget to add them to the defined filter order
          retArr[idx]= item;
          idx++;
      }
    });
    return retArr;
  }

  renderFilterItems() {
    return (
      <div style={{ padding: "5px" }}>
        {this.orderFilterItems(this.props.filters).map((item, idx) => {
        // {this.props.filters.map((item, idx) => {
          return <FilterItem filter={item} key={idx} />;
        })}
      </div>
    );
  }
  render() {
    const { opened, statusChangedHandler } = this.props;
    if (Object.entries(this.props.filters).length > 0) {
      //if (opened) {
      return (
        <div className={"filter-panel-div " + (opened ? "open" : "")}>
          {/* <div className="filter-panel-header open"> */}
          <div style={{ display: "flex", flexDirection: "row" }}>
            <div
              onClick={statusChangedHandler}
              className="icon-wrapper"
            >
              <FilterIcon
                className={"panel-title-icon noHover"}
                height={"24px"}
                width={"24px"}
                color={"white"}
                viewBox={"0 -2 24 24"}
              />
            </div>
            <div
              className={
                "all-projects-header panel-title-bar " +
                (opened ? "panel-title-bar-open" : "panel-title-bar-closed")
              }
            >
              <div style={{ marginLeft: "20px", whiteSpace: "nowrap" }}>
                Filters
              </div>
              <div
                style={{
                  cursor: "pointer",
                  marginLeft: "auto",
                  paddingRight: "20px"
                }}
                onClick={statusChangedHandler}
              >
                <RightChevron
                  className="noHover"
                  transform={"rotate(-90,12,12)"}
                  height={"24px"}
                  width={"24px"}
                  color={"white"}
                  viewBox={"0 -2 24 24"}
                />
              </div>
            </div>
            {/* </div> */}
          </div>
          <div
            style={{
              overflowY: "auto",
              display: "flex",
              flexDirection: "column"
            }}
          >
            {this.renderFilterItems()}
          </div>
        </div>
      );
      // } else {
      //   return (
      //     <div className="all-projects-div">
      //       <div className="all-projects-header">
      //         <div
      //           style={{
      //             cursor: "pointer",
      //             marginLeft: "auto",
      //             paddingRight: "20px"
      //           }}
      //           onClick={statusChangedHandler}
      //         >
      //           <ItemAttributes
      //             className={"noHover"}
      //             height={"24px"}
      //             width={"24px"}
      //             color={"white"}
      //             viewBox={"0 -2 24 24"}
      //           />
      //         </div>
      //       </div>
      //     </div>
      //   );
      //}
    } else {
      return <div />;
    }
  }
}

const mapStateToProps = state => ({});
const mapDispatchToProps = dispatch => ({});

export default connect(mapStateToProps, mapDispatchToProps)(FilterPanel);
