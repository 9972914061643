import React, { Component } from "react";

import { AgGridReact } from "ag-grid-react";
//import "ag-grid/dist/styles/ag-grid.css";
//import "ag-grid/dist/styles/ag-theme-balham.css";
import "../style/agGrid.css";
import "ag-grid-enterprise";
import {
  POS_UNITS,
  CUME_OVER_UNDER,
  INCREMENTAL_LIFT_UNITS,
  SHIP_UNITS
} from "../constants";
import PercChangeModal from "./percChangeModal";

export function checkForImmutableCell(_this, pinned, curRow) {
  //Do not edit cells that are pinned or cells that are of type OVER/UNDER Cume Over Under and Incremental Lift (uneditable cells)
  return (
    pinned != null ||
    _this.gridApi.getRowNode(curRow).data.type === SHIP_UNITS ||
    _this.gridApi.getRowNode(curRow).data.type === CUME_OVER_UNDER ||
    _this.gridApi.getRowNode(curRow).data.type === INCREMENTAL_LIFT_UNITS ||
    _this.gridApi.getRowNode(curRow).data.type === POS_UNITS
  );
}

export function changeByPercent() {
  //show change by % modal
  this.handleToggleModal();
}
export function increaseXPercent(percent) {
  if (this.gridApi.rangeController.isEmpty()) {
    return;
  }

  var _this = this;
  var rangeSelections = this.gridApi.rangeController.getCellRanges();
  var beginRow = rangeSelections[0].start;
  var beginRowIdx = beginRow.rowIndex;
  var endRow = rangeSelections[0].end;
  var endRowIdx = endRow.rowIndex;
  let curRowIdx = beginRowIdx;

  rangeSelections[0].columns.forEach((value, index) => {
    while (curRowIdx <= endRowIdx) {
      let colId = value.colId;
      if (checkForImmutableCell(this, value.pinned, beginRowIdx)) {
        //do Nothing
      } else {
        let fillValue = parseFloat(
          _this.gridApi.getRowNode(curRowIdx).data[value.colId],
          10
        );
        _this.gridApi
          .getRowNode(curRowIdx)
          .setDataValue(colId, fillValue + fillValue * parseFloat(percent, 10));
      }
      curRowIdx = curRowIdx + 1;
    }
    curRowIdx = beginRowIdx;
  });
}

export function fillDown() {
  if (this.gridApi.rangeController.isEmpty()) {
    return;
  }
  var rangeSelections = this.gridApi.rangeController.getCellRanges();
  var beginRow = rangeSelections[0].start;
  var beginRowIdx = beginRow.rowIndex;
  // var endRow = rangeSelections[0].end;
  // var endRowIdx = endRow.rowIndex;
  var endRow = this.gridApi.rowModel.rowsToDisplay.length;

  var curRow = beginRowIdx;
  var fillValue = parseFloat(
    this.gridApi.getRowNode(beginRowIdx).data[beginRow.column.colId],
    10
  );
  while (curRow < endRow) {
    if (checkForImmutableCell(this, beginRow.column.colDef.pinned, curRow)) {
      //do Nothing
    } else {
      this.gridApi
        .getRowNode(curRow)
        .setDataValue(rangeSelections[0].columns[0].colId, fillValue);
    }
    curRow = curRow + 1;
  }
  //console.log(selectedArr);
}

export function fillAcross() {
  if (this.gridApi.rangeController.isEmpty()) {
    return;
  }
  var _this = this;
  var rangeSelections = this.gridApi.rangeController.getCellRanges();
  var beginRow = rangeSelections[0].start;
  var beginRowIdx = beginRow.rowIndex;
  var endRow = rangeSelections[0].end;
  var endRowIdx = endRow.rowIndex;
  var fillValue = parseFloat(
    this.gridApi.getRowNode(beginRowIdx).data[beginRow.column.colId],
    10
  );
  if (beginRowIdx === endRowIdx) {
    var totColumns = this.gridApi.columnController.columnApi.getAllDisplayedColumns();
    let start = false;
    totColumns.forEach(value => {
      if (beginRow.column.colId === value.colId) start = true;
      if (start) {
        if (checkForImmutableCell(this, value.pinned, beginRowIdx)) {
          //do Nothing
        } else {
          _this.gridApi
            .getRowNode(beginRowIdx)
            .setDataValue(value.colId, fillValue);
        }
      }
    });
  }
}

export function fillSelection() {
  if (this.gridApi.rangeController.isEmpty()) {
    return;
  }
  var _this = this;
  var rangeSelections = this.gridApi.rangeController.getCellRanges();
  var beginRow = rangeSelections[0].start;
  var beginRowIdx = beginRow.rowIndex;
  var endRow = rangeSelections[0].end;
  var endRowIdx = endRow.rowIndex;
  let curRowIdx = beginRowIdx;
  var fillValue = parseFloat(
    this.gridApi.getRowNode(beginRowIdx).data[beginRow.column.colId],
    10
  );

  rangeSelections[0].columns.forEach((value, index) => {
    while (curRowIdx <= endRowIdx) {
      let colId = value.colId;
      if (checkForImmutableCell(this, value.pinned, beginRowIdx)) {
        //do Nothing
      } else {
        _this.gridApi.getRowNode(curRowIdx).setDataValue(colId, fillValue);
      }
      curRowIdx = curRowIdx + 1;
    }
    curRowIdx = beginRowIdx;
  });
}

export class AccucastGrid extends Component {
  constructor(props) {
    super(props);
    this.fillAcross = fillAcross;
    this.fillDown = fillDown;
    this.increaseXPercent = increaseXPercent;
    this.fillSelection = fillSelection;
    this.changeByPercent = changeByPercent;
    this.handleToggleModal = this.handleToggleModal.bind(this);
    this.state = {
      showModal: false
    };
  }
  // Handle the visibility of the modal.
  // If `state.showModal` is false, sets it to true,
  // if is true, sets it to false.
  handleToggleModal() {
    this.setState({ showModal: !this.state.showModal });
  }
  handleValue(value) {
    this.increaseXPercent(value / 100);
    this.setState({ showModal: !this.state.showModal });
  }
  onGridReady(params) {
    this.gridApi = params.api;
    this.context = { thisComponent: this };
    this.gridColumnApi = params.columnApi;
    //this.gridApi.sizeColumnsToFit();
  }

  getContextMenuItems = params => {
    var result = [
      {
        // custom item
        name: "Adjust by %",
        action: function() {
          params.context.thisComponent.changeByPercent();
        },
        cssClasses: ["redFont", "bold"]
      },
      {
        // custom item
        name: "Fill Down ",
        action: function() {
          params.context.thisComponent.fillDown();
        },
        cssClasses: ["redFont", "bold"]
      },
      {
        // custom item
        name: "Fill Across ",
        action: function() {
          params.context.thisComponent.fillAcross();
        },
        cssClasses: ["redFont", "bold"]
      },
      {
        // custom item
        name: "Fill Selection ",
        action: function() {
          params.context.thisComponent.fillSelection();
        },
        cssClasses: ["redFont", "bold"]
      },
      "separator",
      // built in copy item
      "copy",
      "excelExport"
    ];

    return result;
  };
  getMainMenuItems(params) {
    //remove the tool panel from the menu
    let finalMenuItem = [];
    let itemsToExclude = "toolPanel";
    params.defaultItems.forEach(function(item) {
      if (itemsToExclude.indexOf(item) < 0) {
        finalMenuItem.push(item);
      }
    });
    return finalMenuItem;
  }
  render() {
    const { showModal } = this.state;
    return (
      <div
        className="ag-theme-balham"
        style={{
          height: "95%",
          width: "100%"
        }}
      >
        <AgGridReact
          enableSorting={true}
          enableFilter={true}
          enableColResize={true}
          enableRangeSelection={true}
          headerHeight={50}
          rowSelection={"multiple"}
          rowDeselection={true}
          //refreshCells={true}
          //suppressContextMenu={true}
          toolPanelSuppressSideButtons={true}
          getMainMenuItems={this.getMainMenuItems}
          //singleClickEdit={true}
          context={{ thisComponent: this }}
          getContextMenuItems={this.getContextMenuItems}
          //allowContextMenuWithControlKey={false}
          onGridReady={this.onGridReady.bind(this)}
          {...this.props}
        />
        {showModal && (
          <PercChangeModal
            onCloseRequest={() => this.handleToggleModal()}
            onOkRequest={value => {
              this.handleValue(value);
            }}
          >
            <div>Adjust Selected by %</div>
          </PercChangeModal>
        )}
      </div>
    );
  }
}
