import * as d3 from "d3";

import * as d3Scale from "d3-scale";
import * as d3Regression from "d3-regression";

import { axisBottom, axisLeft } from "d3-axis";
import { select } from "d3-selection";
import React, { Component } from "react";
import { connect } from "react-redux";
import { LoadingIndicator } from "lib/loadingIndicator";

import { setReinitChart } from "components/acculift/reducers/acculiftReducer";

//import Legend from "./legend";
var margin = { top: 10, right: 40, bottom: 60, left: 50 },
  fullWidth = 900,
  fullHeight = 450,
  width = fullWidth - margin.left - margin.right - 20,
  height = fullHeight - margin.top - margin.bottom;

var x = d3Scale.scaleLinear().range([0, width]);
var y = d3Scale.scaleLinear().range([height, 0]);

var starGenerator = d3
  .symbol()
  .type(d3.symbolStar)
  .size(30);
var diamondGenerator = d3
  .symbol()
  .type(d3.symbolDiamond)
  .size(30);
var triangleGenerator = d3
  .symbol()
  .type(d3.symbolTriangle)
  .size(30);
var crossGenerator = d3
  .symbol()
  .type(d3.symbolCross)
  .size(30);
var wyeGenerator = d3
  .symbol()
  .type(d3.symbolWye)
  .size(30);
var squareGenerator = d3
  .symbol()
  .type(d3.symbolSquare)
  .size(30);
var circleGenerator = d3
  .symbol()
  .type(d3.symbolCircle)
  .size(30);

var starPathData = starGenerator();
var diamondPath = diamondGenerator();
var trianglePath = triangleGenerator();
var crossPath = crossGenerator();
var wyePath = wyeGenerator();

//var formatDate = d3.timeFormat("%d-%b");
class ScatterPlotChart extends Component {
  constructor(props) {
    super(props);
    this.state = {
      //data: this.props.data,
      reDistributeSvg: false,
      svgList: {},
      svgColor: {},
      tacticsArr: [],
      colorOptions: [
        "#5899DA",
        "#E8743B",
        "#19A979",
        "#ED4A7B",
        "#945ECF",
        "#13A4B4",
        "#525DF4",
        "#BF399E",
        "#6C8893",
        "#EE6868",
        "#2F6497",
        "#1866b4",
        "#f5b04d",
        "#a1dbb1",
        "#f99494",
        "#5860DB",
        "#0CCCAA",
        "#49B2FA",
        "#8D33CC",
        "#748DAB",
        "#D66754",
        "#2D91AD"
      ],
      svgOptions: [
        "circle",
        "rect",
        "star",
        "diamond",
        "triangle",
        "cross",
        "wye"
      ],
      bucketedData: {},
      selectedRegColors: {}
    };
    this.drawGrid = this.drawGrid.bind(this);
    this.renderSymbols = this.renderSymbols.bind(this);
    this.renderRegColors = this.renderRegColors.bind(this);
  }
  componentDidUpdate() {
    if (this.props.reInitChart) {
      let geo_count = 0;
      let item_count = 0;
      let geo_selected = [];
      let item_selected = [];
      let tactic_selected = [];
      this.props.lovs[0].values.forEach(item => {
        if (item.selected) {
          geo_count = geo_count + 1;
          geo_selected.push(item.value);
        }
      });
      this.props.lovs[1].values.forEach(item => {
        if (item.selected) {
          item_count = item_count + 1;
          item_selected.push(item.value);
        }
      });
      this.props.lovs[2].values.forEach(item => {
        if (item.selected) {
          tactic_selected.push(item.value);
        }
      });
      d3.selectAll(".histogram").remove();

      this.props.setReinitChart(false);

      this.setState({
        reDistributeSvg: true,
        svgList: {},
        bucketedData: {},
        countGeo: geo_selected.length,
        countItem: item_selected.length
      });

      this.drawGrid();
    }
  }

  componentDidMount() {
    this.drawGrid();
  }

  tipMouseover(event,d) {
    let tooltip = d3.select(".tool-tip");
    var html =
      "<div><table class='tool-tip-div'>" +
      "<tr class='tool-tip-group'>" +
      "<td>ITEM_KEY:</td>" +
      "<td>" +
      d.ITEM_KEY +
      "</td>" +
      "</tr>" +
      "<tr class='tool-tip-group'>" +
      "<td>GEO:</td>" +
      "<td>" +
      d.GEO_KEY +
      "</td>" +
      "</tr>" +
      "<tr class='tool-tip-group'>" +
      "<td>TACTIC:</td>" +
      "<td>" +
      d.PRIMARY_TACTIC_ALIAS +
      "</td>" +
      "</tr>" +
      "<tr class='tool-tip-group'>" +
      "<td>PERIOD:</td>" +
      "<td>" +
      d.WEEK_DATE +
      "</td>" +
      "</tr>" +
      "<tr class='tool-tip-group'>" +
      "<td>WOS:</td>" +
      "<td>" +
      d.WOS_LIFT +
      "</td>" +
      "</tr>" +
      "<tr class='tool-tip-group'>" +
      "<td>DISCOUNT:</td>" +
      "<td>" +
      d.DISCOUNT +
      "%</td>" +
      "</tr>" +
      "<tr class='tool-tip-group'>" +
      "<td>Item Percentile:</td>" +
      "<td>" +
      d.ITEM_PERCENTILE +
      "</td>" +
      "</tr>" +
      "</table>" +
      "<div class='arrow-down'><div class='inner-arrow'/></div>" +
      "</div>";
    tooltip
      .html(html)
      .style("left", event.pageX - 140 + "px")
      .style("top", event.pageY - 300 + "px")
      .transition()
      .duration(200) // ms
      .style("opacity", 0.9); // started as 0!
  }
  tipMouseout(d) {
    let tooltip = d3.select(".tool-tip");
    tooltip
      .transition()
      .duration(300) // ms
      .style("opacity", 0); // don't care about position!
  }

  drawGrid() {
    if (this.props.chartData.length > 0) {
      x.domain([
        0,
        d3.max(this.props.chartData, function(d) {
          //return d.DISCOUNT+10;
          return d.DISCOUNT;
        })
      ]);

      y.domain([
        // d3.min(this.state.baseUnitCount, function(d) {
        //   return Math.min(d.value.valuesUnitsOut, d.value.baseUnit);
        // }),
        0,
        d3.max(this.props.chartData, function(d) {
          return d.WOS_LIFT;
        })
      ]);
    } else {
      x.domain([0, 100]);
      y.domain([0, 100]);
    }
    let tacticsArr = [];
    tacticsArr = d3
      .group()
      .key(d => {
        return d.PRIMARY_TACTIC;
      })
      .key(d => {
        return d.PRIMARY_TACTIC_ALIAS;
      })
      .entries(this.props.chartData);

    tacticsArr = tacticsArr.filter(item => {
      return item.key !== "undefined" && item.values[0].values.length > 2;
    });

    let colors = {
      MAJOR: "red",
      MINOR: "green",
      TPR_ONLY: "blue",
      OTHER: "black"
    };
    let selectedColors = {};
    tacticsArr.forEach((item, idx) => {
      selectedColors[item.key] = colors[item.key];
      let linearRegression = d3Regression
        .regressionQuad()
        .x(d => d.DISCOUNT)
        .y(d => d.WOS_LIFT)
        .domain([
          0,
          d3.max(this.props.chartData, function(d) {
            //return d.DISCOUNT+10;
            return d.DISCOUNT;
          })
        ]);
      let res = linearRegression(item.values[0].values);

      var line = d3
        .line()
        .x(function(d) {
          return x(d[0]);
        })
        .y(function(d) {
          return y(d[1]);
        });
      d3.select(".chartBox")
        .append("path")
        .attr("class", "histogram " + item.key)
        .attr("stroke", colors[item.key])
        .attr("fill", "none")
        .datum(res)
        .attr("d", line);
    });

    this.setState({ selectedRegColors: selectedColors, tacticsArr });

    //var _this = this;
    let xAxisFunction = d3
      .axisBottom()
      .scale(x)
      .ticks(5, "s")
      .tickFormat((d, i) => {
        return d + "%";
      });

    let yAxisFunction = d3
      .axisLeft()
      .scale(y)
      .ticks(5, "s");

    d3.select(this.xAxis).call(xAxisFunction);

    d3.select(this.yAxis).call(yAxisFunction);
    d3.select(".xAxisGrid").call(
      axisBottom()
        .scale(x)
        .ticks(8)
        .tickSize(-height, 0, 0)
        .tickFormat("")
    );
    d3.select(".yAxisGrid").call(
      axisLeft()
        .scale(y)
        .ticks(8)
        .tickSize(-width, 0, 0)
        .tickFormat("")
    );
    d3.select("#viz-container")
      .append("div")
      .attr("class", "tool-tip")
      .style("opacity", 0);
    //var (bucketData,svgColor) = this.setChartSvgIconAndColor();
    var result = this.setChartSvgIconAndColor();
    var bucketData = result[0];
    var svgColor = result[1];

    d3.select(".chartBox")
      .selectAll(".marker")
      .remove();
    d3.select(".wos").style("font-size", "15px");
    d3.select(".discount").style("font-size", "15px");
    Object.entries(bucketData).forEach(item => {
      let generator;
      switch (item[0]) {
        case "circle":
          generator = circleGenerator;
          break;
        case "rect":
          generator = squareGenerator;
          break;
        case "star":
          generator = starGenerator;
          break;
        case "diamond":
          generator = diamondGenerator;
          break;
        case "cross":
          generator = crossGenerator;
          break;
        case "triangle":
          generator = triangleGenerator;
          break;
        case "wye":
          generator = wyeGenerator;
          break;
        default:
          generator = circleGenerator;
          break;
      }
      d3.select(".chartBox")
        .selectAll("." + item[0])
        .data(item[1])
        .enter()
        .append("path")
        .attr("class", item[0])
        .attr("class", "marker")
        .attr("transform", d => {
          return `translate(${x(d.DISCOUNT)},${y(d.WOS_LIFT)}) `;
        })
        .attr("d", generator)
        .style("fill", "white")
        .style("stroke", d => {
          return svgColor[d.ITEM_KEY];
        })
        .on("mouseover", this.tipMouseover)
        .on("mouseout", this.tipMouseout);
    });
  }
  setChartSvgIconAndColor() {
    const { svgOptions, colorOptions } = this.state;
    let tmpBucketData = {};
    let tmpSvgList = {};
    let tmpSvgColor = {};

    var svgCount = 1;

    var colorCount = 1;

    this.props.chartData.forEach((datum, i) => {
      let svgType;
      let color;

      if (svgCount > svgOptions.length) {
        svgCount = 1;
      }
      if (colorCount > colorOptions.length) {
        colorCount = 1;
      }
      svgType = svgOptions[svgCount - 1];
      color = colorOptions[colorCount - 1];
      if (tmpSvgColor[datum.ITEM_KEY] === undefined) {
        tmpSvgColor[datum.ITEM_KEY] = color;
        colorCount = colorCount + 1;
      } else {
        color = tmpSvgColor[datum.ITEM_KEY];
      }
      if (tmpSvgList[datum.GEO_KEY] === undefined) {
        tmpSvgList[datum.GEO_KEY] = svgType;
        tmpBucketData[svgType] = [datum];

        svgCount = svgCount + 1;
      } else {
        svgType = tmpSvgList[datum.GEO_KEY];
        tmpBucketData[svgType].push(datum);
      }
    });

    this.setState({
      svgList: tmpSvgList,
      svgColor: tmpSvgColor,
      bucketedData: tmpBucketData
    });
    return [tmpBucketData, tmpSvgColor];
  }
  renderSymbols() {
    if (Object.entries(this.state.svgList).length > 0) {
      var symbols = Object.entries(this.state.svgList).map((item, idx) => {
        switch (item[1]) {
          case "circle":
            return (
              <div style={{ display: "flex", flexDirection: "row" }} key={idx}>
                <svg style={{}} className="legend-svg">
                  <circle
                    cx={5}
                    cy={5}
                    r="4"
                    style={{ stroke: "black", fill: "white" }}
                    key={idx}
                  />
                </svg>
                <div className="legend-labels" x={10} y={11}>
                  {item[0]}
                </div>
              </div>
            );
          case "rect":
            return (
              <div style={{ display: "flex", flexDirection: "row" }} key={idx}>
                <svg style={{}} className="legend-svg">
                  <rect
                    x={1}
                    y={2}
                    width="7"
                    height="7"
                    style={{ stroke: "black", fill: "white" }}
                    key={idx}
                  />
                </svg>
                <div className="legend-labels" x={10} y={11}>
                  {item[0]}
                </div>
              </div>
            );

          case "star":
            return (
              <div style={{ display: "flex", flexDirection: "row" }} key={idx}>
                <svg style={{}} className="legend-svg">
                  <path
                    transform={"translate(5,5)"}
                    d={starPathData}
                    style={{ stroke: "black", fill: "white" }}
                    key={idx}
                  />
                </svg>
                <div className="legend-labels" x={10} y={11}>
                  {item[0]}
                </div>
              </div>
            );
          case "diamond":
            return (
              <div style={{ display: "flex", flexDirection: "row" }} key={idx}>
                <svg style={{}} className="legend-svg">
                  <path
                    transform={"translate(5,5)"}
                    d={diamondPath}
                    style={{ stroke: "black", fill: "white" }}
                    key={idx}
                  />
                </svg>
                <div className="legend-labels" x={10} y={11}>
                  {item[0]}
                </div>
              </div>
            );
          case "cross":
            return (
              <div style={{ display: "flex", flexDirection: "row" }} key={idx}>
                <svg style={{}} className="legend-svg">
                  <path
                    transform={"translate(5,5)"}
                    d={crossPath}
                    style={{ stroke: "black", fill: "white" }}
                    key={idx}
                  />
                </svg>
                <div className="legend-labels" x={10} y={11}>
                  {item[0]}
                </div>
              </div>
            );
          case "triangle":
            return (
              <div style={{ display: "flex", flexDirection: "row" }} key={idx}>
                <svg style={{}} className="legend-svg">
                  <path
                    transform={"translate(5,5)"}
                    d={trianglePath}
                    style={{ stroke: "black", fill: "white" }}
                    key={idx}
                  />
                </svg>
                <div className="legend-labels" x={10} y={11}>
                  {item[0]}
                </div>
              </div>
            );
          case "wye":
            return (
              <div style={{ display: "flex", flexDirection: "row" }} key={idx}>
                <svg style={{}} className="legend-svg">
                  <path
                    transform={"translate(5,5)"}
                    d={wyePath}
                    style={{ stroke: "black", fill: "white" }}
                    key={idx}
                  />
                </svg>
                <div className="legend-labels" x={10} y={11}>
                  {item[0]}
                </div>
              </div>
            );

          default:
            return (
              <div style={{ display: "flex", flexDirection: "row" }} key={idx}>
                <svg style={{}} className="legend-svg">
                  <circle
                    cx={10}
                    cy={10}
                    r="4"
                    style={{ stroke: "black", fill: "white" }}
                  />
                </svg>
                <div className="legend-labels">{item[0]}</div>
              </div>
            );
        }
      });

      return (
        <div className="render-symbols" transform={"translate(0, 25)"}>
          {symbols}
        </div>
      );
    } else {
      return <div />;
    }
  }
  renderColors() {
    if (Object.entries(this.state.svgColor).length > 0) {
      var colors = Object.entries(this.state.svgColor).map((item, idx) => {
        return (
          <div key={idx} style={{ display: "flex", flexDirection: "row" }}>
            <div
              style={{
                marginTop: "4px",
                marginRight: "3px",
                width: "10px",
                height: "10px",
                background: item[1]
              }}
            />
            <div>{item[0]}</div>
          </div>
        );
      });
      return <div>{colors}</div>;
    }
  }
  renderRegColors() {
    if (Object.entries(this.state.selectedRegColors).length > 0) {
      var colors = Object.entries(this.state.selectedRegColors).map(
        (item, idx) => {
          let tactic = this.state.tacticsArr.filter(tactic => {
            return item[0] === tactic.key;
          });

          return (
            <div key={idx} style={{ display: "flex", flexDirection: "row" }}>
              <div
                style={{
                  marginTop: "4px",
                  marginRight: "3px",
                  width: "10px",
                  height: "10px",
                  background: item[1]
                }}
              />
              <div>{tactic[0].values[0].key}</div>
            </div>
          );
        },
        this
      );
      return <div>{colors}</div>;
    }
  }
  render() {
    if (this.props.agGridApi === null) {
      return <LoadingIndicator paddingTop={"227px"} />;
    }

    return (
      // <div className="chart review-lift-chart" style={{ overflowY: "visible" }}>
      <div className="chart review-lift-chart" style={{ overflowY: "visible" }}>
        <div style={{ fontWeight: "bold", marginBottom: "8px" }}>
          Lift Model Fit
        </div>
        <div
          className="tabs-hr"
          style={{ width: "100%", marginBottom: "8px" }}
        ></div>
        <div
          style={{
            display: "flex",
            height: "100%",
            flexDirection: "row-reverse"
          }}
        >
          <div
            id="viz-container"
            style={{ overflow: "hidden", width: "100%", height: "85%" }}
          >
            <svg
              height="100%"
              width="100%"
              className="svg-container"
              style={{ overflow: "visible" }}
              ref={r => {
                this.svg = select(r);
              }}
              preserveAspectRatio="xMidYMid meet"
              viewBox={`0 0 ${fullWidth} ${fullHeight}`}
              id="chartObj"
            >
              <g
                ref={r => {
                  this.chartBox = select(r);
                }}
                className="chartBox"
                transform={`translate(${margin.left + 10},${margin.top})`}
              >
                <g
                  className="xAxisGrid grid"
                  ref={r => (this.xAxisGrid = select(r))}
                  transform={`translate(0, ${height})`}
                ></g>
                <g
                  className="yAxisGrid grid"
                  ref={r => (this.yAxisGrid = select(r))}
                ></g>
                <g
                  ref={node => {
                    this.xAxis = node;
                  }}
                  transform={`translate(0, ${height})`}
                >
                  {" "}
                  <text
                    fill={"#000"}
                    transform-origin={"left"}
                    className="discount"
                    // transform={`rotate(-90) translate(${150},${130}) `}
                    transform={`translate(${440},${20}) `}
                    y={6}
                    dy={"0.71em"}
                    textAnchor={"end"}
                  >
                    Discount %
                  </text>
                </g>
                <g
                  ref={node => {
                    this.yAxis = node;
                  }}
                  transform={"translate(0,0)"}
                >
                  {" "}
                  <text
                    fill={"#000"}
                    transform-origin={"left"}
                    className="wos"
                    transform={`rotate(-90) translate(${90},${180}) `}
                    //transform={`translate(${30},${-25}) `}
                    y={6}
                    dy={"0.71em"}
                    textAnchor={"end"}
                  >
                    Weeks of Supply
                  </text>
                </g>
              </g>
            </svg>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              marginTop: "20px"
            }}
          >
            <div className="scatter-legend">
              <div>
                <span className="legend-heading">Geo </span>
                <span className="legend-desc">(shape)</span>
              </div>
              <div className="legend-labels">{this.renderSymbols()}</div>
            </div>
            <div className="scatter-legend color" style={{ marginTop: "5px" }}>
              <div>
                <span className="legend-heading">ITEM_KEY </span>
                <span className="legend-desc">(color)</span>
              </div>
              <div className="legend-labels">{this.renderColors()}</div>
            </div>

            <div className="scatter-legend color" style={{ marginTop: "5px" }}>
              <div>
                <span className="legend-heading">Histogram </span>
                <span className="legend-desc">(color)</span>
              </div>
              <div className="legend-labels">{this.renderRegColors()}</div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  reInitChart: state.acculift.reInitChart,
  filteredData: state.acculift.filteredData,
  lovs: state.acculift.lovs
});
const mapDispatchToProps = dispatch => ({
  setReinitChart(state) {
    dispatch(setReinitChart(state));
  }
});
export default connect(mapStateToProps, mapDispatchToProps)(ScatterPlotChart);
