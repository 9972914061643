export function percentFormatter(params) {
  if (params.value === "-") {
    return params.value;
  } else {
    return formatNumber(params.value) + "%";
  }
}

function formatNumber(number) {
  // this puts commas into the number eg 1000 goes to 1,000,
  // i pulled this from stack overflow, i have no idea how it works
  return Math.floor(number)
    .toString()
    .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
}

export function numberFormatter(params) {
  if (params.value) {
    if (params.value === "-") {
      return params.value;
    } else {
      return formatNumber(params.value);
    }
  } else return "";
}

export function floatFormatter(params) {
  if (params.value) {
    let rValue = Number.parseFloat(params.value)
      .toFixed(3)
      .toString()
      .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
    return rValue;
  } else {
    return "";
  }
}

export function getRowClass(params) {
  var idx = params.node.rowIndex;
  var classes = [];
  switch (idx % 2) {
    case 0:
      classes.push("ta-even-row");
      break;
    case 1:
      classes.push("ta-odd-row");
      break;
    default:
      classes.push("");
      break;
  }

  return classes.join(" ");
}
