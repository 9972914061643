import React, { Component } from "react";

import NumericEditor from "components/accucast/components/editors/numericEditor";
import TotalRenderer from "components/accucast/components/renderers/totalRenderer";
import { AccucastGrid } from "components/accucast/components/lib/accucastGrid";
import {
  numberFormatter,
  getRowClass
} from "components/accucast/components/formatters";
import { numberNewValueHandler } from "components/accucast/components/handlers";
import { CustomHeaderRenderer } from "components/accucast/components/renderers/customHeaderRenderer";
import { EditableCellFloatRenderer } from "components/accucast/components/renderers/editableCellFloatRenderer";
import { EditableCellDateRenderer } from "components/accucast/components/renderers/editableCellDateRenderer";

export class PlanPricing extends Component {
  constructor(props) {
    super(props);

    this.state = {
      columnDefs: [
        {
          headerName: "Customer Type",
          pinned: "left",
          width: 131,
          field: "geo",
          tooltipField: "geo",
          suppressMovable: true,
          menuTabs: ["filterMenuTab"],
          cellClassRules: {
            "ta-changed":
              "data.wk25Changed || data.wk26Changed || data.wk27Changed || data.wk28Changed"
          }
        },
        {
          headerName: "Base Description",
          pinned: "left",
          tooltipField: "idesc",
          width: 375,
          field: "idesc",
          suppressMovable: true,
          menuTabs: ["filterMenuTab"],
          cellClassRules: {
            "ta-changed":
              "data.wk25Changed || data.wk26Changed || data.wk27Changed || data.wk28Changed"
          }
        },
        {
          headerName: "Prod Key",
          pinned: "left",
          width: 136,
          field: "pk",
          tooltipField: "pk",
          suppressMovable: true,
          menuTabs: ["filterMenuTab"],
          cellClassRules: {
            "ta-changed":
              "data.wk25Changed || data.wk26Changed || data.wk27Changed || data.wk28Changed"
          }
        },
        {
          headerName: "Brand Size",
          pinned: "left",
          width: 138,
          field: "bs",
          tooltipField: "bs",
          suppressMovable: true,
          menuTabs: ["filterMenuTab"],
          cellClassRules: {
            "ta-changed":
              "data.wk25Changed || data.wk26Changed || data.wk27Changed || data.wk28Changed"
          }
        },
        {
          headerName: "Net Price",
          headerComponent: "customHeaderRenderer",
          width: 95,
          field: "np",
          suppressMovable: true,
          menuTabs: ["filterMenuTab"],
          cellEditor: "numericEditor",
          editable: true,
          valueFormatter: numberFormatter,
          newValueHandler: numberNewValueHandler,
          cellClass: "cellCenterClass",
          headerClass: "headerSize",
          cellRenderer: "editableCellFloatRenderer"
        },
        {
          headerName: "Start Date",
          headerComponent: "customHeaderRenderer",
          width: 110,
          field: "sd",
          suppressMovable: true,
          menuTabs: ["filterMenuTab"],
          cellEditor: "numericEditor",
          editable: true,
          valueFormatter: numberFormatter,
          newValueHandler: numberNewValueHandler,
          cellClass: "cellCenterClass",
          headerClass: "headerSize",
          cellRenderer: "editableCellDateRenderer"
        },
        {
          headerName: "End Date",
          headerComponent: "customHeaderRenderer",
          width: 95,
          field: "ed",
          suppressMovable: true,
          menuTabs: ["filterMenuTab"],
          cellEditor: "numericEditor",
          editable: true,
          valueFormatter: numberFormatter,
          newValueHandler: numberNewValueHandler,
          cellClass: "cellCenterClass",
          headerClass: "headerSize",
          cellRenderer: "editableCellFloatRenderer"
        }
      ],
      rowData: this.createRows(),
      frameworkComponents: {
        numericEditor: NumericEditor,
        totalRenderer: TotalRenderer,
        customHeaderRenderer: CustomHeaderRenderer,
        editableCellFloatRenderer: EditableCellFloatRenderer,
        editableCellDateRenderer: EditableCellDateRenderer
      }
    };
  }
  createRows = () => {
    let rows = [];
    rows.push({
      upsw: "0.0",
      geo: "AHOLD",
      idesc: "89898-NS DIET TURBO CHOS PWD PKT 5CT 1.4OZB",
      pk: "123456789012",
      bs: "SALTY SNACK",
      np: "22.43",
      sd: "01/01/2018",
      ed: ""
    });
    rows.push({
      upsw: "0.0",
      geo: "AMAZON",
      idesc: "84220-SOUTH BEACH DOUBLE CHOC BAR 30CT",
      pk: "3267484220",
      bs: "BAR",
      np: "22.43",
      sd: "01/01/2018",
      ed: ""
    });
    rows.push({
      upsw: "0.0",
      geo: "AMAZON",
      idesc: "84221-SOUTH BEACH PEANUT BUTTER BAR 30CT",
      pk: "3267484221",
      bs: "BAR",
      np: "22.43",
      sd: "01/01/2018",
      ed: ""
    });
    rows.push({
      upsw: "0.0",
      geo: "C&S",
      idesc: "84192-NS DIET KIT EVERYDAY 1CT",
      pk: "3267484192",
      bs: "KIT",
      np: "22.43",
      sd: "01/01/2018",
      ed: ""
    });
    rows.push({
      upsw: "0.0",
      geo: "DIV1",
      idesc: "83504-NS DIAB VANIL LIQ 4CT 11.0OZB",
      pk: "3267483504",
      bs: "SHAKE",
      np: "22.43",
      sd: "01/01/2018",
      ed: ""
    });

    return rows;
  };

  render() {
    return (
      <div style={{ height: "100%" }}>
        Pricing
        <AccucastGrid
          columnDefs={this.state.columnDefs}
          rowData={this.state.rowData}
          getRowClass={getRowClass}
          headerHeight={30}
          frameworkComponents={this.state.frameworkComponents}
        />
      </div>
    );
  }
}
