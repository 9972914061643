import React, { Component } from "react";
import BaselineCard from "./baselineCard";
import { Grid, Row } from "react-flexbox-grid";
import Checkbox from "rc-checkbox";
import { toast } from "react-toastify";
import axios from "axios";
import { getProjects, selectBatch } from "components/accubase/reducers/accubaseReducer";
import { connect } from "react-redux";
import { OverlayTrigger, Popover } from "react-bootstrap";

class BaselineDetailCellRenderer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      baselines: props.data.baselines,
      baselineName: "",
      showNewBaselineDialog: false,
      selectedRadioOption: 1,
      baselineId: -1,
      inOutWeeks: this.props.selectedProject.DEFAULT_IN_OUT_WEEK_COUNT,
      minUnits: this.props.selectedProject.DEFAULT_MIN_UNITS_PER_WEEK,
      minCat: this.props.selectedProject.DEFAULT_MIN_CATEGORY_SHARE_THRESHOLD,
      basePassedValidation: false,
      minCatPassedValidation: true, //validation is true because it has a default value defined above
      minUnitsPassedValidation: true, //validation is true because it has a default value defined above
      weeksPassedValidation: true //validation is true because it has a default value defined above
    };
    this.BaselineName = React.createRef();
    this.renderAdvancedOptions = this.renderAdvancedOptions.bind(this);
    this.validateInputs = this.validateInputs.bind(this);
    this.runCreateBaseline = this.runCreateBaseline.bind(this);
  }
  componentDidMount() {
    this.props.selectBatch(this.props.data);
  }

  validateInputs(field, val) {
    if (val === undefined || val === null) {
      return;
    }
    switch (field) {
      case "baselineName":
        if (val.length > 0 && !/^\s+$/.test(val)) {
          this.setState({ basePassedValidation: true });
        } else {
          this.setState({ basePassedValidation: false });
        }
        break;
      case "inOutWeeks":
        if (val.toString().length > 0 && !/^\s+$/.test(val.toString()) && val >= 0) {
          this.setState({ weeksPassedValidation: true });
        } else {
          this.setState({ weeksPassedValidation: false });
        }
        break;
      case "minUnits":
        if (val.toString().length > 0 && !/^\s+$/.test(val.toString()) && val >= 0) {
          this.setState({ minUnitsPassedValidation: true });
        } else {
          this.setState({ minUnitsPassedValidation: false });
        }
        break;
      case "minCat":
        if (val.toString().length > 0 && !/^\s+$/.test(val.toString()) && val >= 0) {
          this.setState({ minCatPassedValidation: true });
        } else {
          this.setState({ minCatPassedValidation: false });
        }
        break;
      default:
    }
  }
  renderAdvancedOptions() {
    let list = [
      {
        text: "Min. Unit Sales Per Week",
        value: "60",
        type: "number",
        tooltip:
          "Weeks with very low sales are used to identify certain sales profiles used for modeling such as Out-of-Stock, In-and-Out or Discontinued.  This parameter defines the Very Low Sales threshold.",
        key: "minUnits"
      },
      {
        text: "In/Out Week Count",
        value: "4",
        type: "number",
        tooltip:
          "This parameter defines how many weeks are required to be below the Min. Unit Sales threshold both before and after a sales period to be defined as an In/Out item.  Baselines for In/Out items are assumed to be zero.",
        key: "inOutWeeks"
      },
      {
        text: "Minor Sales Percent",
        value: "2.0%",
        type: "number",
        tooltip:
          // eslint-disable-next-line quotes
          'Baselines on low sales items tend to be more volatile and less accurate.  This parameter identifies products with aggregated Units Sales in the bottom x.x% of a CATEGORY within a Geo, where x.x is the percentage defined by the user.  For these items, Baseline values will usually equal Actual.  These items (referred to as "E ITEMS") will also receive special treatment when Lift Models are created. The default value for this setting is 2.0%.',
        key: "minCat"
      }
    ];
    return (
      <div style={{ display: "flex", flexDirection: "row" }}>
        <div style={{ marginTop: "5px" }}>
          {list.map((item, idx) => {
            if (item.value.includes("%")) {
              return (
                <div
                  key={idx}
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    // marginTop: "5px",
                    // marginBottom: "5px",
                    height: "30px",
                    paddingTop: "5px",
                    paddingBottom: "5px",
                    marginBottom: "5px"
                  }}
                  onChange={event => {
                    if (item.key === "minCat") {
                      this.setState({ minCat: Math.round(event.target.value * 10) / 1000 });
                    } else {
                      this.setState({ [item.key]: event.target.value });
                    }
                    this.validateInputs(item.key, event.target.value);
                    //this.handleInputChange(event, item.key);
                  }}>
                  <div style={{ width: "160px" }}>{item.text}</div>
                  <div
                    style={{
                      width: "80px",
                      height: "20px",
                      display: "flex",
                      flexDirection: "row"
                    }}>
                    <input
                      type={item.type}
                      style={{
                        width: "50px",
                        textAlign: "right",
                        paddingRight: "2px"
                      }}
                      defaultValue={this.state[item.key] * 100}></input>

                    <div
                      style={{
                        position: "relative",
                        top: "3px",
                        marginLeft: "2px"
                      }}>
                      %
                    </div>
                  </div>
                  <OverlayTrigger
                    key={item.key}
                    placement={"left"}
                    overlay={
                      <Popover id={item.key}>
                        <Popover.Title as="h3">{item.text}</Popover.Title>
                        <Popover.Content>{item.tooltip}</Popover.Content>
                      </Popover>
                    }>
                    <div className="ta-info-circle">i</div>
                  </OverlayTrigger>
                </div>
              );
            } else if (item.value === "true" || item.value === "false") {
              return (
                <div
                  key={idx}
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    // marginTop: "5px",
                    // marginBottom: "5px",
                    height: "30px",
                    paddingTop: "5px",
                    paddingBottom: "5px",
                    marginBottom: "5px"
                  }}>
                  <div style={{ width: "160px" }}>{item.text}</div>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      width: "80px",
                      justifyContent: "center"
                    }}>
                    <Checkbox defaultChecked={true} style={{ textAlign: "center", paddingRight: "25px" }} />
                  </div>
                  <OverlayTrigger
                    key={item.key}
                    placement={"left"}
                    overlay={
                      <Popover id={item.key}>
                        <Popover.Title as="h3">{item.text}</Popover.Title>
                        <Popover.Content>{item.tooltip}</Popover.Content>
                      </Popover>
                    }>
                    <div className="ta-info-circle">i</div>
                  </OverlayTrigger>
                </div>
              );
            } else {
              return (
                <div
                  key={idx}
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    // marginTop: "5px",
                    // marginBottom: "5px",
                    height: "30px",
                    paddingTop: "5px",
                    paddingBottom: "5px",
                    marginBottom: "5px"
                  }}
                  onChange={event => {
                    if (item.key === "minCat") {
                      this.setState({ minCat: Math.round(event.target.value * 10) / 1000 });
                    } else {
                      this.setState({ [item.key]: event.target.value });
                    }
                    this.validateInputs(item.key, event.target.value);

                    //this.handleInputChange(event, item.key);
                  }}>
                  <div style={{ width: "160px" }}>{item.text}</div>
                  <div
                    style={{
                      width: "80px",
                      height: "20px",
                      display: "flex",
                      flexDirection: "row"
                    }}>
                    <input
                      type={item.type}
                      style={{
                        width: "50px",
                        textAlign: "right",
                        paddingRight: "2px"
                      }}
                      defaultValue={this.state[item.key]}></input>
                  </div>
                  <OverlayTrigger
                    key={item.key}
                    placement={"left"}
                    overlay={
                      <Popover id={item.key}>
                        <Popover.Title as="h3">{item.text}</Popover.Title>
                        <Popover.Content>{item.tooltip}</Popover.Content>
                      </Popover>
                    }>
                    <div className="ta-info-circle">i</div>
                  </OverlayTrigger>
                </div>
              );
            }
          })}
        </div>
      </div>
    );
  }
  runCreateBaseline() {
    const { baselineName, inOutWeeks, minUnits, minCat } = this.state;

    if (
      this.state.basePassedValidation &&
      this.state.minCatPassedValidation &&
      this.state.minUnitsPassedValidation &&
      this.state.weeksPassedValidation
    ) {
      let versionName = baselineName;
      let batchId = this.props.data.id;

      this.BaselineName.current.value = "";
      // this.setState({
      //   selectedPath: "",
      //   baselineName: "",
      //   selectedBatchId: -1,
      //   selectedBatch: ""
      // });
      let dupeCheck = this.props.data.baselines.filter(item => {
        return item.name === versionName;
      });

      if (dupeCheck.length > 0) {
        toast.error("That baseline name is already being used");
      } else if (batchId !== -1) {
        toast.success("Baseline Calculation Submitted for: " + versionName);
        this.setState({ showNewBaselineDialog: false });
        axios
          .post(
            `${process.env.REACT_APP_BIGHORN_SERVER}/api/accubase/runBaseline`,
            {
              versionName,
              batchId,
              inOutWeeks,
              minUnits,
              minCat
            },
            {
              headers: {
                ClientToken: localStorage.getItem("clientToken")
              }
            }
          )
          .then(res => {
            this.setState({
              loaded: false,
              inOutWeeks: this.props.selectedProject.DEFAULT_IN_OUT_WEEK_COUNT,
              minUnits: this.props.selectedProject.DEFAULT_MIN_UNITS_PER_WEEK,
              minCat: this.props.selectedProject.DEFAULT_MIN_CATEGORY_SHARE_THRESHOLD
            });
            this.props.getProjects(false);
            console.log(res);
          })
          .catch(err => {
            toast.error("There was an error creating your baseline");
          });
      } else {
        toast.error("No batch selected for baseline processing");
      }
    }
  }
  render() {
    const { baselines } = this.state;

    return (
      <div
        style={{
          display: "flex",
          height: "100%",
          flexDirection: "column",
          padding: "20px",
          background: "rgb(247,248,250)"
        }}>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            fontWeight: "bold",
            fontSize: "14px"
          }}>
          <div>Select an available baseline to review</div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              width: "100%"
            }}>
            <div
              onClick={() => {
                this.setState({
                  showNewBaselineDialog: !this.state.showNewBaselineDialog
                });
              }}
              style={{
                marginLeft: "auto",
                cursor: "pointer",
                fontSize: "14px"
              }}
              className="btn btn-high-viz">
              + Add New Baseline
            </div>
            {this.state.showNewBaselineDialog && (
              <div style={{}}>
                <div className="add-new-baseline-container">
                  <div>Baseline Name</div>
                  <input
                    ref={this.BaselineName}
                    onChange={event => {
                      this.setState({ baselineName: this.BaselineName.current.value });
                      this.validateInputs("baselineName", event.target.value);
                    }}
                    style={{ width: "100%", marginBottom: "5px" }}
                  />

                  <div>Advanced Options</div>

                  {this.renderAdvancedOptions()}
                  <hr />
                  <div
                    style={{
                      justifyContent: "center",
                      paddingLeft: "15px",
                      display: "flex",
                      flexDirection: "row"
                    }}>
                    <div
                      className={
                        "ta-button " +
                        (this.state.basePassedValidation &&
                        this.state.minCatPassedValidation &&
                        this.state.minUnitsPassedValidation &&
                        this.state.weeksPassedValidation
                          ? "ta-baseline-button"
                          : "ta-baseline-button disabled")
                      }
                      style={{
                        marginTop: "0px",
                        marginLeft: "10px",
                        height: "25px",
                        lineHeight: "25px"
                      }}
                      onClick={() => {
                        this.runCreateBaseline();
                      }}

                      // style={{
                      //   height: "25px",
                      //   width: "60px",
                      //   textAlign: "center",
                      //   marginRight: "20px",
                      //   paddingTop: "4px"
                      // }}
                    >
                      Create
                    </div>
                    <div
                      style={{
                        height: "25px",
                        width: "60px",
                        textAlign: "center",
                        marginRight: "20px",
                        paddingTop: "4px"
                      }}
                      className="cancel-button"
                      onClick={() => {
                        this.setState({
                          showNewBaselineDialog: false,
                          inOutWeeks: this.props.selectedProject.DEFAULT_IN_OUT_WEEK_COUNT,
                          minUnits: this.props.selectedProject.DEFAULT_MIN_UNITS_PER_WEEK,
                          minCat: this.props.selectedProject.DEFAULT_MIN_CATEGORY_SHARE_THRESHOLD
                        });
                      }}>
                      Cancel
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
        <hr style={{ width: "100%", height: "5px" }} />
        <div
          className="full-width-panel"
          style={{
            display: "flex",
            flexDirection: "row",
            width: "100%"
          }}>
          <Grid className="report-icon-list">
            <Row center="xl" style={{ width: "100%" }}>
              {baselines.map((item, idx) => {
                return <BaselineCard baseline={item} key={idx} />;
              })}
            </Row>
          </Grid>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  selectedProject: state.accubase.selectedProject
});
const mapDispatchToProps = dispatch => ({
  getProjects(selectProject = true) {
    dispatch(getProjects(selectProject));
  },
  selectBatch(batch) {
    dispatch(selectBatch(batch));
  }
});

export default connect(mapStateToProps, mapDispatchToProps)(BaselineDetailCellRenderer);
