import React, { Component } from "react";

import NumericEditor from "components/accucast/components/editors/numericEditor";
import TotalRenderer from "components/accucast/components/renderers/totalRenderer";
import { AccucastGrid } from "components/accucast/components/lib/accucastGrid";
import {
  floatFormatter,
  getRowClass
} from "components/accucast/components/formatters";
import { numberNewValueHandler } from "components/accucast/components/handlers";
import { CustomHeaderRenderer } from "components/accucast/components/renderers/customHeaderRenderer";
import { EditableCellFloatRenderer } from "components/accucast/components/renderers/editableCellFloatRenderer";
import { GroupFilterer } from "components/accucast/components/filterers/groupFilterer";
import {
  geoComparator,
  itemDescComparator,
  prodKeyComparator,
  brandSizeComparator
} from "components/accucast/components/comparators";
import axios from "axios";

export class PlanSeasonalityOverrides extends Component {
  constructor(props) {
    super(props);

    this.state = {
      columnDefs: [],
      rowData: this.createRows(),
      frameworkComponents: {
        numericEditor: NumericEditor,
        totalRenderer: TotalRenderer,
        customHeaderRenderer: CustomHeaderRenderer,
        editableCellFloatRenderer: EditableCellFloatRenderer,
        groupFilterer: GroupFilterer
      }
    };
  }

  componentDidMount() {
    var baseConfig = [
      {
        id: "0",
        headerName: "GEO",
        pinned: "left",
        width: 105,
        field: "geo",
        tooltipField: "geo",
        comparator: geoComparator,
        filter: "groupFilterer",
        suppressMovable: true,
        menuTabs: ["filterMenuTab"]
      },
      {
        id: "1",
        headerName: "Item Description",
        pinned: "left",
        width: 375,
        field: "idesc",
        comparator: itemDescComparator,
        filter: "groupFilterer",
        tooltipField: "idesc",
        suppressMovable: true,
        menuTabs: ["filterMenuTab"]
      },
      {
        id: "2",
        headerName: "Prod Key",
        pinned: "left",
        width: 136,
        field: "pk",
        tooltipField: "pk",
        comparator: prodKeyComparator,
        filter: "groupFilterer",
        suppressMovable: true,
        menuTabs: ["filterMenuTab"]
      },
      {
        id: "3",
        headerName: "Brand Size",
        pinned: "left",
        field: "bs",
        tooltipField: "bs",
        comparator: brandSizeComparator,
        filter: "groupFilterer",
        suppressMovable: true,
        menuTabs: ["filterMenuTab"]
      },
      {
        id: "4",
        headerName: "Type",
        pinned: "left",
        field: "type",
        tooltipField: "type",
        suppressFilter: true,
        suppressSorting: true,
        suppressMovable: true,
        menuTabs: []
      }
    ];
    var defaultWkCellConf = {
      width: 95,
      suppressMovable: true,
      menuTabs: ["filterMenuTab"],
      cellEditor: "numericEditor",
      editable: true,
      valueFormatter: floatFormatter,
      newValueHandler: numberNewValueHandler,
      headerComponent: "customHeaderRenderer",
      cellClass: "cellCenterClass",
      cellRenderer: "editableCellFloatRenderer"
    };
    axios
      .get("http://localhost:4000/api/accucast/config/seasonalityOverrides", {
        headers: {
          ClientToken: localStorage.getItem("clientToken")
        }
      })
      .then(res => {
        if (res.data.config.length !== 0) {
          let config = res.data.config.map(conf => {
            let id = parseInt(conf.id, 10);
            if (id === 0 || id === 1 || id === 2 || id === 3 || id === 4) {
              let pinConf = baseConfig[id];
              pinConf.cellClassRules = conf.cellClassRules;
              return pinConf;
            } else {
              conf = { ...conf, ...defaultWkCellConf };
              return conf;
            }
          });
          this.setState({ columnDefs: config });
        }
      });
  }

  createRows = () => {
    let rows = [];

    rows.push({
      fgeo: "AMAZON",
      fidesc: "84220-SOUTH BEACH DOUBLE CHOC BAR 30CT",
      fpk: "3267484220",
      geo: "AMAZON",
      idesc: "84220-SOUTH BEACH DOUBLE CHOC BAR 30CT",
      pk: "3267484220",
      fbs: "Bar",
      bs: "Bar",
      type: "Baseline",
      wk21: "1.2",
      wk22: "1.2",
      wk23: "1.2",
      wk24: "1.2",
      wk25: "1.2",
      wk26: "1.2",
      wk27: "1.2",
      wk28: "1.2",
      wk29: "1.2",
      wk30: "1.2",
      wk31: "1.2"
    });
    rows.push({
      fgeo: "AMAZON",
      fidesc: "84220-SOUTH BEACH DOUBLE CHOC BAR 30CT",
      fpk: "3267484220",
      geo: "",
      idesc: "",
      pk: "",
      fbs: "Bar",
      bs: "",
      type: "Shock Factor",
      wk21: "1.2",
      wk22: "1.2",
      wk23: "1.2",
      wk24: "1.2",
      wk25: "1.2",
      wk26: "1.2",
      wk27: "1.2",
      wk28: "1.2",
      wk29: "1.2",
      wk30: "1.2",
      wk31: "1.2"
    });
    rows.push({
      fgeo: "AMAZON",
      fidesc: "84220-SOUTH BEACH DOUBLE CHOC BAR 30CT",
      fpk: "3267484220",
      geo: "",
      idesc: "",
      pk: "",
      fbs: "Bar",
      bs: "",
      type: "Drift Factor",
      wk21: "1.2",
      wk22: "1.2",
      wk23: "1.2",
      wk24: "1.2",
      wk25: "1.2",
      wk26: "1.2",
      wk27: "1.2",
      wk28: "1.2",
      wk29: "1.2",
      wk30: "1.2",
      wk31: "1.2"
    });
    rows.push({
      fgeo: "AMAZON",
      fidesc: "84220-SOUTH BEACH DOUBLE CHOC BAR 30CT",
      fpk: "3267484220",
      geo: "",
      idesc: "",
      pk: "",
      fbs: "Bar",
      bs: "",
      type: "Adjusted Seasonality",
      wk21: "1.2",
      wk22: "1.2",
      wk23: "1.2",
      wk24: "1.2",
      wk25: "1.2",
      wk26: "1.2",
      wk27: "1.2",
      wk28: "1.2",
      wk29: "1.2",
      wk30: "1.2",
      wk31: "1.2"
    });
    rows.push({
      fgeo: "C&S",
      fidesc: "84192-NS DIET KIT EVERYDAY 1CT",
      fpk: "3267484192",
      geo: "C&S",
      idesc: "84192-NS DIET KIT EVERYDAY 1CT",
      pk: "3267484192",
      fbs: "Kit",
      bs: "Kit",
      type: "Baseline",
      wk21: ".96",
      wk22: ".96",
      wk23: ".96",
      wk24: ".96",
      wk25: ".96",
      wk26: ".96",
      wk27: ".96",
      wk28: ".96",
      wk29: ".96",
      wk30: ".96",
      wk31: ".96"
    });
    rows.push({
      fgeo: "C&S",
      fidesc: "84192-NS DIET KIT EVERYDAY 1CT",
      fpk: "3267484192",
      geo: "",
      idesc: "",
      pk: "",
      fbs: "Kit",
      bs: "",
      type: "Shock Factor",
      wk21: ".96",
      wk22: ".96",
      wk23: ".96",
      wk24: ".96",
      wk25: ".96",
      wk26: ".96",
      wk27: ".96",
      wk28: ".96",
      wk29: ".96",
      wk30: ".96",
      wk31: ".96"
    });
    rows.push({
      fgeo: "C&S",
      fidesc: "84192-NS DIET KIT EVERYDAY 1CT",
      fpk: "3267484192",
      geo: "",
      idesc: "",
      pk: "",
      fbs: "Kit",
      bs: "",
      type: "Drift Factor",
      wk21: ".96",
      wk22: ".96",
      wk23: ".96",
      wk24: ".96",
      wk25: ".96",
      wk26: ".96",
      wk27: ".96",
      wk28: ".96",
      wk29: ".96",
      wk30: ".96",
      wk31: ".96"
    });
    rows.push({
      fgeo: "C&S",
      fidesc: "84192-NS DIET KIT EVERYDAY 1CT",
      fpk: "3267484192",
      geo: "",
      idesc: "",
      pk: "",
      fbs: "Kit",
      bs: "",
      type: "Adjusted Seasonality",
      wk21: ".96",
      wk22: ".96",
      wk23: ".96",
      wk24: ".96",
      wk25: ".96",
      wk26: ".96",
      wk27: ".96",
      wk28: ".96",
      wk29: ".96",
      wk30: ".96",
      wk31: ".96"
    });
    rows.push({
      fgeo: "AHOLD",
      fidesc: "89898-NS DIET TURBO CHOS PWD PKT 5CT 1.4OZB",
      fpk: "123456789012",
      geo: "AHOLD",
      idesc: "89898-NS DIET TURBO CHOS PWD PKT 5CT 1.4OZB",
      pk: "123456789012",
      fbs: "Salty Snack",
      bs: "Salty Snack",
      type: "Baseline",
      wk21: ".96",
      wk22: ".96",
      wk23: ".96",
      wk24: ".96",
      wk25: ".96",
      wk26: ".96",
      wk27: ".96",
      wk28: ".96",
      wk29: ".96",
      wk30: ".96",
      wk31: ".96"
    });
    rows.push({
      fgeo: "AHOLD",
      fidesc: "89898-NS DIET TURBO CHOS PWD PKT 5CT 1.4OZB",
      fpk: "123456789012",
      geo: "",
      idesc: "",
      pk: "",
      fbs: "Salty Snack",
      bs: "",
      type: "Shock Factor",
      wk21: ".96",
      wk22: ".96",
      wk23: ".96",
      wk24: ".96",
      wk25: ".96",
      wk26: ".96",
      wk27: ".96",
      wk28: ".96",
      wk29: ".96",
      wk30: ".96",
      wk31: ".96"
    });
    rows.push({
      fgeo: "AHOLD",
      fidesc: "89898-NS DIET TURBO CHOS PWD PKT 5CT 1.4OZB",
      fpk: "123456789012",
      geo: "",
      idesc: "",
      pk: "",
      fbs: "Salty Snack",
      bs: "",
      type: "Drift Factor",
      wk21: ".96",
      wk22: ".96",
      wk23: ".96",
      wk24: ".96",
      wk25: ".96",
      wk26: ".96",
      wk27: ".96",
      wk28: ".96",
      wk29: ".96",
      wk30: ".96",
      wk31: ".96"
    });
    rows.push({
      fgeo: "AHOLD",
      fidesc: "89898-NS DIET TURBO CHOS PWD PKT 5CT 1.4OZB",
      fpk: "123456789012",
      geo: "",
      idesc: "",
      pk: "",
      fbs: "Salty Snack",
      bs: "",
      type: "Adjusted Seasonality",
      wk21: ".96",
      wk22: ".96",
      wk23: ".96",
      wk24: ".96",
      wk25: ".96",
      wk26: ".96",
      wk27: ".96",
      wk28: ".96",
      wk29: ".96",
      wk30: ".96",
      wk31: ".96"
    });
    rows.push({
      fgeo: "DIV1",
      fidesc: "83504-NS DIAB VANIL LIQ 4CT 11.0OZB",
      fpk: "3267483504",
      geo: "DIV1",
      idesc: "83504-NS DIAB VANIL LIQ 4CT 11.0OZB",
      pk: "3267483504",
      fbs: "Shake",
      bs: "Shake",
      type: "Baseline",
      wk21: ".96",
      wk22: ".96",
      wk23: ".96",
      wk24: ".96",
      wk25: ".96",
      wk26: ".96",
      wk27: ".96",
      wk28: ".96",
      wk29: ".96",
      wk30: ".96",
      wk31: ".96"
    });
    rows.push({
      fgeo: "DIV1",
      fidesc: "83504-NS DIAB VANIL LIQ 4CT 11.0OZB",
      fpk: "3267483504",
      geo: "",
      idesc: "",
      pk: "",
      fbs: "Shake",
      bs: "",
      type: "Shock Factor",
      wk21: ".96",
      wk22: ".96",
      wk23: ".96",
      wk24: ".96",
      wk25: ".96",
      wk26: ".96",
      wk27: ".96",
      wk28: ".96",
      wk29: ".96",
      wk30: ".96",
      wk31: ".96"
    });
    rows.push({
      fgeo: "DIV1",
      fidesc: "83504-NS DIAB VANIL LIQ 4CT 11.0OZB",
      fpk: "3267483504",
      geo: "",
      idesc: "",
      pk: "",
      fbs: "Shake",
      bs: "",
      type: "Drift Factor",
      wk21: ".96",
      wk22: ".96",
      wk23: ".96",
      wk24: ".96",
      wk25: ".96",
      wk26: ".96",
      wk27: ".96",
      wk28: ".96",
      wk29: ".96",
      wk30: ".96",
      wk31: ".96"
    });
    rows.push({
      fgeo: "DIV1",
      fidesc: "83504-NS DIAB VANIL LIQ 4CT 11.0OZB",
      fpk: "3267483504",
      geo: "",
      idesc: "",
      pk: "",
      fbs: "Shake",
      bs: "",
      type: "Adjusted Seasonality",
      wk21: ".96",
      wk22: ".96",
      wk23: ".96",
      wk24: ".96",
      wk25: ".96",
      wk26: ".96",
      wk27: ".96",
      wk28: ".96",
      wk29: ".96",
      wk30: ".96",
      wk31: ".96"
    });

    return rows;
  };

  render() {
    return (
      <div style={{ height: "100%" }}>
        Seasonality Overrides
        <AccucastGrid
          columnDefs={this.state.columnDefs}
          rowData={this.state.rowData}
          getRowClass={getRowClass}
          frameworkComponents={this.state.frameworkComponents}
        />
      </div>
    );
  }
}
