import axios from "axios";
import { cloneDeep } from "lodash";
import { parseToken } from "lib/helpers";
import { setActiveWS } from "components/reports/components/reportList/reducers/reportListReducer";

/* -------------------- INITIAL STATE -------------------- */
const INITIAL_STATE = {
  apps: [],
  loading: true,
  error: "",
  searchApps: [],
  landingApps: [],
  appsOrder: [],
  banner: "",
  showBanner: false,
  defaultBannerOpen: false,
  reportListType: "",
  fullAppsLoaded: false,
  logo: "",
  defaultApp: null,
  collapseFolders: true,
  performCollapseAction: false,
  filter: "",
  landingFilter: "",
  reportAppLoaded: false,
  getFavoritesAndRecents: false,
  reportGroup: "",
  customGroup: "",
  gotClient: false,
  customAppSasToken: "",
  setAllowHomePage: false,
  homePage: null,
  isSearching: false,
  inboxId: "",
  useFileFolderPermissions: false,
  fileId: "",
  fileGuid: ""
};

/* -------------------- ACTION TYPES -------------------- */
const GET_APPS_BEGIN = "GET_APPS_BEGIN";
const GET_APPS_FAIL = "GET_APPS_FAIL";
const GET_APPS_SUCCESS = "GET_APPS_SUCCESS";
const SET_APPS = "SET_APPS";
const SET_SEARCH_APPS = "SET_SEARCH_APPS";
const SET_MDM_APPS = "SET_MDM_APPS";
const SET_COLLAPSED_FOLDERS = "SET_COLLAPSED_FOLDERS";
const SET_DO_COLLAPSE = "SET_DO_COLLAPSE";
const SET_RPT_APP_STATUS = "SET_RPT_APP_STATUS";
const SET_RPT_GROUP = "SET_RPT_GROUP";
const SET_LAYOUT = "SET_LAYOUT";
const SET_GOT_CLIENT = "SET_GOT_CLIENT";
const SET_CUSTOM_GROUP = "SET_CUSTOM_GROUP";
const SET_GET_FAV_AND_REC = "SET_GET_FAV_AND_REC";
const SET_LANDING_APPS = "SET_LANDING_APPS";
const SET_HOME_PAGE = "SET_HOME_PAGE";

/* -------------------- ACTION CREATORS -------------------- */

const getAppsBegin = () => ({
  type: GET_APPS_BEGIN
});

const getAppsSuccess = (
  apps,
  banner,
  showBanner,
  bannerOpen,
  reportListType,
  logo,
  defaultApp,
  caSasToken,
  appsOrder,
  homePage,
  inboxId = "",
  useFileFolderPermissions,
  fileId = "",
  fileGuid = ""
) => {
  return {
    type: GET_APPS_SUCCESS,
    apps,
    banner,
    showBanner,
    bannerOpen,
    reportListType,
    logo,
    defaultApp,
    caSasToken,
    appsOrder,
    homePage,
    inboxId,
    useFileFolderPermissions,
    fileId,
    fileGuid
  };
};
const getAppsError = error => ({
  type: GET_APPS_FAIL,
  payload: error
});
const setFullApps = (workspaces, banner) => {
  return {
    type: SET_APPS,
    workspaces,
    banner
  };
};
const setFullAppsMdm = items => {
  return { type: SET_MDM_APPS, items };
};
const setSearchApps = (apps, filter) => ({
  type: SET_SEARCH_APPS,
  payload: apps,
  filter
});
const setLandingApps = (apps, filter, reports) => ({
  type: SET_LANDING_APPS,
  payload: apps,

  filter,
  reports
});

const setCollapsedFolders = status => {
  return { type: SET_COLLAPSED_FOLDERS, payload: status };
};
const setDoCollapse = status => {
  return { type: SET_DO_COLLAPSE, payload: status };
};
const setRptAppStatus = status => {
  return { type: SET_RPT_APP_STATUS, payload: status };
};

/* -------------------- ASYNC ACTION CREATORS -------------------- */
export const getApps = (isSSO = false, isUploadOnly = false, isFileShare = false) => {
  let setClientToken = localStorage.getItem("setClientToken");
  let userId = localStorage.getItem("user");
  // let access_token = localStorage.getItem("access_token");
  //console.log(setClientToken);

  return dispatch => {
    dispatch(getAppsBegin());
    let uploadOnly = isUploadOnly;
    if (uploadOnly) {
      setClientToken = window.location.hash.replace("#", "");
    }
    let fileShare = isFileShare;
    if (fileShare) {
      setClientToken = window.location.hash.replace("#", "");
    }

    axios
      .post(
        `${process.env.REACT_APP_BIGHORN_SERVER}/api/app/applicationAuth`,
        {
          sso: isSSO,
          setClientToken,
          userId,
          uploadOnly,
          fileShare
        },
        {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("access_token")
          }
        }
      )
      .then(response => {
        //console.log(response.data);

        if (!uploadOnly && !fileShare) {
          localStorage.setItem("clientToken", response.data.clientToken);
          let parsedToken = parseToken(response.data.clientToken);
          if (parsedToken.client === undefined) {
            console.log("PARSED TOKEN CLIENT IS UNDEFINED");
          }
          localStorage.setItem("client", parsedToken.client.split("+")[0]);
          let logo = "";
          if (response.data.logo !== undefined && response.data.logo !== "") {
            logo = response.data.logo + "?" + response.data.sasToken;
          }

          let banner = response.data.banner;
          function count(main_str, sub_str) {
            main_str += "";
            sub_str += "";

            if (sub_str.length <= 0) {
              return main_str.length + 1;
            }

            let subStr = sub_str.replace(/[.*+?^${}()|[\]\\]/g, "\\$&");
            return (main_str.match(new RegExp(subStr, "gi")) || []).length;
          }
          let sasToken = response.data.sasToken;

          let rmCount = count(banner, "src=");
          let x = 1;
          if (banner !== undefined) {
            while (x <= rmCount) {
              let img = banner.split("src='")[x].split("'")[0];
              banner = banner.replace(img, img + "?" + sasToken);
              x = x + 1;
            }
          } else {
            console.log("BANNER IS UNDEFINED");
            banner = "";
          }
          let newApps = [];
          if (response.data.applications !== undefined) {
            newApps = response.data.applications;
          }

          let appsType = newApps.map(item => {
            return item.type;
          });
          if (appsType.includes("powerBI") || appsType.includes("tableau")) {
            //BIG-294 back this out
            let clientToken = localStorage.getItem("clientToken");

            let groups = parseToken(clientToken).groups;

            let createMode = groups.filter(group => {
              return group === "pbi-creator";
            });

            if (createMode.length >= 1) {
              newApps.push({
                type: "powerBIMgmt",
                title: "Power BI Folder Management"
              });
            }
            // if (appsType.includes("reportSubscriptions")) {
            //   newApps.push({ type: "rptSubscriptions", title: "Report Subscriptions" });
            // }
            newApps.push({ type: "reporting", title: "TABS Insight" });
          }

          dispatch(
            getAppsSuccess(
              newApps,
              banner,
              response.data.showBanner,
              response.data.bannerOpen,
              response.data.reportListType,
              logo,
              response.data.defaultApp,
              response.data.customAppSasToken,
              response.data.appsOrder,
              response.data.homePage,
              response.data.inboxId,
              response.data.useFileFolderPermissions
            )
          );
        } else {
          const fileId = setClientToken.split("+")[0] || "";
          const guid = setClientToken.split("+")[1] || "";
          dispatch(
            getAppsSuccess(
              [],
              "",
              false,
              response.data.reportListType,
              response.data.bannerOpen,
              "",
              response.data.defaultApp,
              response.data.customAppSasToken,
              response.data.appsOrder,
              response.data.homePage,
              response.data.inboxId,
              response.data.useFileFolderPermissions,
              fileId,
              guid
            )
          );
        }
      })
      .catch(error => {
        dispatch(getAppsError(error.response.data.message));
      });
  };
};

export const setApps = workspaces => {
  return dispatch => {
    dispatch(setFullApps(workspaces));
  };
};
export const setMdmApps = apps => {
  return dispatch => {
    dispatch(setFullAppsMdm(apps));
  };
};

export const filterApps = (filter, apps) => {
  return dispatch => {
    if (filter === "") {
      dispatch(setSearchApps(apps, filter));
      dispatch(setCollapsedFolders(true));
      dispatch(setDoCollapse(true));
    } else {
      let newApps = cloneDeep(apps);
      dispatch(
        setSearchApps(
          newApps.map(app => {
            if (app.groups !== undefined && app.groups.length > 0) {
              if (app.groups[0].items === undefined) {
                app.groups = app.groups.filter(group => {
                  if (group.APP_NAME !== undefined) {
                    return group.APP_NAME.toLowerCase().indexOf(filter.toLowerCase()) !== -1;
                  }
                  return group.toLowerCase().indexOf(filter.toLowerCase()) !== -1;
                });
              } else {
                if (app.type === "reporting") {
                  app.groups = app.groups.filter(group => {
                    if (group.alias !== undefined) {
                      return (
                        group.name.toLowerCase().indexOf(filter.toLowerCase()) !== -1 ||
                        group.alias.toLowerCase().indexOf(filter.toLowerCase()) !== -1
                      );
                    } else {
                      return group.name.toLowerCase().indexOf(filter.toLowerCase()) !== -1;
                    }
                  });
                }
              }

              return app;
            }
            return app;
          }),
          filter
        )
      );
      dispatch(setCollapsedFolders(false));
      dispatch(setDoCollapse(true));
    }
  };
};
export const filterAppLanding = (filter, apps) => {
  return dispatch => {
    let cApps = cloneDeep(apps);
    let newApps = [];
    let searchRpts = [];
    if (filter === "") {
      cApps.forEach(item => {
        if (item.type === "reporting") {
          if (item.groups) {
            newApps.push(...item.groups);
          }
        } else {
          newApps.push(item);
        }
      });
      dispatch(setLandingApps(newApps, filter));
    } else {
      cApps.forEach(item => {
        if (item.type === "reporting" && item.groups !== undefined) {
          for (let iGroup of item.groups) {
            if (iGroup.hidden !== true) searchRpts = [...searchRpts, searchItems(iGroup.items, filter, iGroup)];
          }

          item.groups = item.groups.filter(group => {
            if (group.alias !== undefined && group.description !== undefined) {
              return (
                group.name.toLowerCase().indexOf(filter.toLowerCase()) !== -1 ||
                group.alias.toLowerCase().indexOf(filter.toLowerCase()) !== -1 ||
                group.description.toLowerCase().indexOf(filter.toLowerCase()) !== -1
              );
            } else if (group.alias !== undefined) {
              return (
                group.name.toLowerCase().indexOf(filter.toLowerCase()) !== -1 ||
                group.alias.toLowerCase().indexOf(filter.toLowerCase()) !== -1
              );
            } else if (group.description !== undefined) {
              return (
                group.name.toLowerCase().indexOf(filter.toLowerCase()) !== -1 ||
                group.description.toLowerCase().indexOf(filter.toLowerCase()) !== -1
              );
            } else {
              return group.name.toLowerCase().indexOf(filter.toLowerCase()) !== -1;
            }
          });
          newApps.push(...item.groups);
        } else if (item.type === "mdh") {
          if (item.title.toLowerCase().indexOf(filter.toLowerCase()) !== -1) {
            newApps.push(item);
          } else if ("master data harmonization".indexOf(filter.toLowerCase()) !== -1) {
            newApps.push(item);
          }
        } else if (item.title.toLowerCase().indexOf(filter.toLowerCase()) !== -1) {
          newApps.push(item);
        }
      });
      // console.log("SEARCH REPORTS");
      // console.log(searchRpts);
      let concatArray = [].concat(...searchRpts);
      // console.log(concatArray);
      dispatch(setLandingApps(newApps, filter, concatArray));
    }
  };
};
function searchItems(items, filter, grouping) {
  let matchedItems = [];

  for (let item of items) {
    if (
      item.name &&
      item.name.toLowerCase().includes(filter.toLowerCase()) &&
      item.type !== "folder" &&
      item.type !== "customApplication"
    ) {
      if (grouping.type === "customApplication") {
        item.workspace = grouping;
        item.path = grouping.name + "/" + item.name;
      }
      matchedItems.push(item);
    } else if (
      item.description &&
      item.description.toLowerCase().includes(filter.toLowerCase()) &&
      item.type !== "folder" &&
      item.type !== "customApplication"
    ) {
      if (grouping.type === "customApplication") {
        item.workspace = grouping;
        item.path = grouping.name + "/" + item.name;
      }
      matchedItems.push(item);
    } else if ((item.type === "grouping" || item.type === "folder") && item.children) {
      let matchedChildren = searchItems(item.children, filter, grouping);

      matchedItems.push(...matchedChildren);
    } else if (item.fileType && item.fileType === "folder") {
      let matchedChildren = searchItems(item.children, filter, grouping);

      matchedItems.push(...matchedChildren);
    }
  }

  return matchedItems;
}
export const setPerformCollapse = status => {
  return dispatch => {
    dispatch(setDoCollapse(status));
  };
};
export const setCollapsed = status => {
  return dispatch => {
    dispatch(setCollapsedFolders(status));
  };
};

export const setReportAppStatus = status => {
  return dispatch => {
    dispatch(setRptAppStatus(status));
  };
};
export const setReportGroup = (group, ws = null) => {
  return dispatch => {
    dispatch({ type: SET_RPT_GROUP, payload: group });
    if (ws !== null) {
      dispatch(setActiveWS(ws));
    } else {
      dispatch(setActiveWS(group));
    }
  };
};
export const setGetFavAndRec = status => {
  return dispatch => {
    dispatch({ type: SET_GET_FAV_AND_REC, payload: status });
    //dispatch(setActiveWS(group));
  };
};
export const setCustomGroup = group => {
  return dispatch => {
    dispatch({ type: SET_CUSTOM_GROUP, payload: group });
    //dispatch(setActiveWS(group));
  };
};
export const setLayout = layout => {
  return dispatch => {
    axios
      .post(
        `${process.env.REACT_APP_BIGHORN_SERVER}/api/app/setReportListType`,
        {
          reportListType: layout,
          user: localStorage.getItem("user")
        },
        {
          headers: {
            ClientToken: localStorage.getItem("clientToken")
          }
        }
      )
      .then(response => {
        dispatch({ type: SET_LAYOUT, payload: layout });
      });
  };
};
export const setGotClient = client => {
  return dispatch => {
    dispatch({ type: SET_GOT_CLIENT, payload: client });
  };
};

export const setUserHomepage = obj => {
  return dispatch => {
    axios
      .post(
        `${process.env.REACT_APP_BIGHORN_SERVER}/api/app/setUserHomePage`,
        {
          homePage: obj,
          user: localStorage.getItem("user")
        },
        {
          headers: {
            ClientToken: localStorage.getItem("clientToken")
          }
        }
      )
      .then(response => {
        dispatch({ type: SET_HOME_PAGE, payload: response.data });
      });
  };
};
/* -------------------- REDUCER -------------------- */
export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case GET_APPS_BEGIN:
      return { ...state, loading: true, error: null };
    case GET_APPS_SUCCESS:
      return {
        ...state,
        searchApps: action.apps,
        landingApps: action.apps,
        appsOrder: action.appsOrder,
        apps: action.apps,
        banner: action.banner,
        showBanner: action.showBanner,
        defaultBannerOpen: action.bannerOpen,
        logo: action.logo,
        loading: false,
        customAppSasToken: action.caSasToken,
        defaultApp: action.defaultApp,
        reportAppLoaded: true,
        reportListType: action.reportListType,
        homePage: action.homePage,
        inboxId: action.inboxId,
        useFileFolderPermissions: action.useFileFolderPermissions,
        fileId: action.fileId,
        fileGuid: action.fileGuid
      };
    case GET_APPS_FAIL:
      return {
        ...state,
        loading: false,
        reportAppLoaded: false,
        error: action.payload,
        apps: []
      };
    case SET_MDM_APPS:
      let fullAppList = state.searchApps.slice();
      fullAppList.forEach((item, idx) => {
        if (item.type === "mdh") {
          fullAppList[idx].groups = action.items.map(app => {
            let newApp = { APP_NAME: app.APP_NAME, ID: app.ID, PROJECTS: [] };
            app.PROJECTS.forEach(item => {
              newApp.PROJECTS.push(item);
            });
            return newApp;
          });
        }
      });

      return {
        ...state,
        apps: fullAppList,
        searchApps: fullAppList,
        landingApps: fullAppList,
        fullAppsLoaded: true
      };
    case SET_APPS:
      let fullList = state.searchApps.slice();
      let fullListLanding = cloneDeep(fullList);
      fullListLanding.pop();
      let wsID;
      var tempObj = {};
      fullList.forEach((item, idx) => {
        if (item.type === "reporting") {
          fullList[idx].groups = action.workspaces.map((workspace, wIndex) => {
            let type = "tableau";
            if (workspace.type === undefined || workspace.type === "powerBI") {
              type = "powerBI";
            } else if (workspace.type === "tableau") {
              wsID = workspace.workspaceId !== undefined ? workspace.workspaceId : workspace._id;

              tempObj = {
                name: workspace.name,
                type,
                icon: workspace.icon,
                workspaceId: wsID,
                alias: workspace.alias,
                hidden: workspace.hidden,
                thumbnails: workspace.thumbnails,
                description: workspace.description,
                _id: workspace._id
              };

              tempObj.items = workspace.reports.map((report, rIndex) => {
                return report;
              });
              fullListLanding.push(tempObj);
              return tempObj;
            } else if (workspace.type === "customApplication") {
              type = "customApplication";
              // let tempObj = {
              //   name: workspace.name,
              //   type,
              //   workspaceId: wsID,
              //   alias: workspace.alias,
              //   hidden: workspace.hidden,

              // };
            }
            wsID = workspace.workspaceId !== undefined ? workspace.workspaceId : workspace._id;

            //PowerBi now has a heirarchy so need to deal with this differently
            tempObj = {
              name: workspace.name,
              type,
              icon: workspace.icon,
              workspaceId: wsID,
              alias: workspace.alias,
              hidden: workspace.hidden,
              thumbnails: workspace.thumbnails,
              description: workspace.description,
              _id: workspace._id
            };

            if (workspace.children === null || workspace.children === undefined) {
              tempObj.items = workspace.reports.map((report, rIndex) => {
                return report;
              });
            } else {
              tempObj.items = workspace.children.map((report, rIndex) => {
                return report;
              });
            }
            fullListLanding.push(tempObj);
            return tempObj;
          });
        }
      });
      let tmpAppsOrder = cloneDeep(state.appsOrder);
      // let fullListIds = fullListLanding.map(item => {
      //   return item._id;
      // });

      fullListLanding.forEach(fList => {
        if (!tmpAppsOrder.includes(fList._id)) {
          if (fList.type !== "powerBIMgmt") {
            tmpAppsOrder.push(fList._id);
          }
        }
      });
      if (state.appsOrder !== undefined && state.appsOrder.length > 0) {
        var c = [];

        tmpAppsOrder.forEach(el =>
          c.push(
            fullListLanding.find(e => {
              return e._id === el;
            })
          )
        );

        c = c.filter(item => {
          return item !== undefined && item.hidden !== true;
        });
        // appsOrder = sortBy(appsOrder, [
        //   function (o) {
        //     return o.appOrder;
        //   }
        // ]);
        fullListLanding = c;
      }

      return {
        ...state,
        apps: fullList,
        searchApps: fullList,
        landingApps: fullListLanding,
        fullAppsLoaded: true
      };
    case SET_SEARCH_APPS:
      return {
        ...state,
        searchApps: action.payload,
        reportAppLoaded: true,
        filter: action.filter
      };
    case SET_LANDING_APPS:
      let setListLanding = [];
      // let setReportListLanding = [];
      var isSearching = false;

      if (action.filter !== "") {
        isSearching = true;
      }

      if (state.appsOrder !== undefined && state.appsOrder.length > 0) {
        var z = [];

        state.appsOrder.forEach(el =>
          z.push(
            action.payload.find(e => {
              return e._id === el;
            })
          )
        );

        z = z.filter(item => {
          return item !== undefined && item.hidden !== true;
        });
        // appsOrder = sortBy(appsOrder, [
        //   function (o) {
        //     return o.appOrder;
        //   }
        // ]);
        setListLanding = z;
      }

      return {
        ...state,
        landingApps: setListLanding,
        landingReports: action.reports,
        landingFilter: action.filter,
        isSearching: isSearching
      };
    case SET_COLLAPSED_FOLDERS:
      return { ...state, collapseFolders: action.payload };
    case SET_DO_COLLAPSE:
      return { ...state, performCollapseAction: action.payload };
    case SET_RPT_APP_STATUS:
      return { ...state, reportAppLoaded: action.payload };
    case SET_RPT_GROUP:
      return {
        ...state,
        reportGroup: action.payload,
        getFavoritesAndRecents: true
      };
    case SET_GET_FAV_AND_REC:
      return {
        ...state,
        getFavoritesAndRecents: action.payload
      };
    case SET_CUSTOM_GROUP:
      return { ...state, customGroup: action.payload };
    case SET_LAYOUT:
      return { ...state, reportListType: action.payload };
    case SET_GOT_CLIENT:
      return { ...state, gotClient: action.payload };
    case SET_HOME_PAGE:
      return { ...state, homePage: action.payload };
    default:
      return state;
  }
};
