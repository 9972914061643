import React, { Component } from "react";
import { connect } from "react-redux";
import "./style.css";

import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-balham.css";
import "ag-grid-community";
import "ag-grid-enterprise";

import PromoOptDetailCellRenderer from "./promoOptDetailCellRenderer";
import PromoActionRenderer from "./promoActionRenderer";

class PromoOptList extends Component {
  constructor(props) {
    super(props);
    this.state = { api: null };
  }
  onGridReady(event) {
    var that = this;
    setTimeout(function() {
      if (that.props.selectedLift !== null) {
        event.api.getDisplayedRowAtIndex(that.props.selectedLift.idx).setExpanded(true);
      }
    }, 0);
    this.setState({ api: event.api });
    event.api.sizeColumnsToFit();
  }
  componentDidUpdate() {
    var that = this;
    if (this.state.api !== null && this.props.selectedLift !== null && this.props.selectedLift.promoPlans !== undefined) {
      setTimeout(function() {
        if (that.props.selectedProject.data.length > 0) {
          if (that.props.selectedLift !== null) {
            if (that.state.api.getDisplayedRowAtIndex(that.props.selectedLift.idx) !== undefined) {
              that.state.api.getDisplayedRowAtIndex(that.props.selectedLift.idx).setExpanded(true);
            }
          }
        }
      }, 0);
    }
  }
  render() {
    return (
      <div
        className="ag-theme-balham project-grid"
        style={{
          display: "flex",
          flexDirection: "column",
          width: "100%",
          height: "100%",
          fontSize: "14px"
        }}>
        <AgGridReact
          columnDefs={[
            {
              headerName: "Data Batch",
              field: "batchName",
              cellRenderer: "agGroupCellRenderer"
            },
            {
              headerName: "Final Baseline",
              field: "finalBaselineName",
              width: 150
            },
            {
              headerName: "Final Lift Model",
              field: "finalLiftName",
              width: 150
            },
            {
              headerName: "Available Promo Plans",
              field: "promoCount",
              width: 150
            }
          ]}
          rowData={this.props.project.data}
          masterDetail={true}
          getRowHeight={function(params) {
            if (
              params.node &&
              params.node.detail &&
              params.data.promoPlans !== undefined &&
              params.data.promoPlans.length > 0
            ) {
              var offset = 280;
              let rows = Math.ceil(params.data.promoPlans.length / 3);
              //console.log(rows);
              return rows * offset;
              //var allDetailRowHeight = params.data.lifts.length * 28;
              //return allDetailRowHeight + offset;
            } else if (params.node && params.node.detail) {
              return 280;
            } else {
              // otherwise return fixed master row height
              return 30;
            }
          }}
          detailCellRenderer={"PromoOptDetailCellRenderer"}
          frameworkComponents={{
            PromoOptDetailCellRenderer: PromoOptDetailCellRenderer,
            PromoActionRenderer: PromoActionRenderer
          }}
          getRowNodeId={data => {
            return data.id;
          }}
          suppressContextMenu={true}
          sortable={true}
          deltaRowDataMode={true}
          filter={true}
          onRowSelected={this.onRowSelected}
          onGridReady={event => this.onGridReady(event)}
        />
      </div>
    );
  }
}

const mapStateToProps = state => ({
  lifts: state.acculift.lifts,
  projects: state.acculift.projects,
  searchProjects: state.acculift.searchProjects,
  selectedProject: state.promo.selectedProject,
  selectedLift: state.promo.selectedLift
});
const mapDispatchToProps = dispatch => ({});

export default connect(mapStateToProps, mapDispatchToProps)(PromoOptList);
