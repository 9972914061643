import axios from "axios";
import { cloneDeep } from "lodash";

/* -------------------- INITIAL STATE -------------------- */
const INITIAL_STATE = {
  slides: [],
  count: 0,
  loaded: false,
  loading: true
};

/* -------------------- ACTION TYPES -------------------- */
const SET_IMAGE_SUCCESS = "SET_IMAGE_SUCCESS";
const SET_SLIDES = "SET_SLIDES";

/* -------------------- ACTION CREATORS -------------------- */

const setImageSuccess = img => {
  return { type: SET_IMAGE_SUCCESS, payload: img };
};

const setSlides = slides => {
  return { type: SET_SLIDES, payload: slides };
};

/* -------------------- ASYNC ACTION CREATORS -------------------- */
export const saveCroppedImage = img => {
  return dispatch => {
    //dispatch(fetchReportListBegin());
    axios
      .post(
        `${process.env.REACT_APP_BIGHORN_SERVER}/api/app/saveCroppedImage`,
        {
          img
        },
        {
          headers: {
            ClientToken: localStorage.getItem("clientToken")
          }
        }
      )
      .then(response => {
        let image = response.data.img;
        image.data = img.data;
        dispatch(setImageSuccess(image));
      })
      .catch(error => {
        //dispatch(fetchReportListError(error));
      });
  };
};
export const updateSlidesOrder = slides => {
  return dispatch => {
    let serverSlides = cloneDeep(slides);
    dispatch(setSlides(slides));

    serverSlides.forEach(item => {
      delete item.data;
    });
    axios
      .post(
        `${process.env.REACT_APP_BIGHORN_SERVER}/api/app/updateSlides`,
        {
          slides: serverSlides
        },
        {
          headers: {
            ClientToken: localStorage.getItem("clientToken")
          }
        }
      )
      .then(response => {
        console.log(response);
      })
      .catch(error => {
        //dispatch(fetchReportListError(error));
      });
  };
};
export const deleteSlides = (slides, item) => {
  return dispatch => {
    dispatch(setSlides(slides));
    let newSlides = cloneDeep(slides);
    newSlides.forEach(item => {
      delete item.data;
    });
    axios
      .post(
        `${process.env.REACT_APP_BIGHORN_SERVER}/api/app/deleteSlides`,
        {
          slides: newSlides,
          item
        },
        {
          headers: {
            ClientToken: localStorage.getItem("clientToken")
          }
        }
      )
      .then(response => {
        //console.log(response);
      })
      .catch(error => {
        //dispatch(fetchReportListError(error));
      });
  };
};
export const getSlides = () => {
  return dispatch => {
    axios
      .post(
        `${process.env.REACT_APP_BIGHORN_SERVER}/api/app/getSlides`,
        {},
        {
          headers: {
            ClientToken: localStorage.getItem("clientToken")
          }
        }
      )
      .then(response => {
        dispatch(setSlides(response.data.slides));
        //dispatch(setImageSuccess(img));
      })
      .catch(error => {
        //dispatch(fetchReportListError(error));
      });
  };
};

/* -------------------- REDUCER -------------------- */
export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case SET_IMAGE_SUCCESS:
      let newSlides = state.slides;

      newSlides.push(action.payload);

      return {
        ...state,
        slides: newSlides,
        loading: false,
        loaded: true,
        count: state.count + 1
      };
    case SET_SLIDES:
      return {
        ...state,
        slides: action.payload,
        loaded: true,
        loading: false,
        count: action.payload.length
      };
    default:
      return state;
  }
};
