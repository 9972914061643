import React, { Component } from "react";
import "./style.css";

import { RightChevron, ProjectIcon, LiftModelIcon } from "lib/icons";
import { connect } from "react-redux";
// import { setSearchProjects } from "components/accubase/reducers/accubaseReducer";
import { selectLift } from "components/acculift/reducers/acculiftReducer";
import { selectBaseline } from "components/accubase/reducers/accubaseReducer";
import { selectPromo } from "components/promoOpt/reducers/promoOptReducer";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import Select from "react-select";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { DotLoader } from "react-spinners";
import Checkbox from "rc-checkbox";
import axios from "axios";
import moment from "moment";
import { OverlayTrigger, Tooltip } from "react-bootstrap";

class ProjectSelector extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open: true,
      showDeleteProjectConfirmModal: false,
      selectedAction: { label: "Ends", value: "Ends", id: 1 },
      showSaveErrorModal: false,
      savingError: "",
      showCreateNewProjModal: false,
      selectedRadioOption: 2,
      startDate: moment().startOf("year")._d,
      makeDefault: false,
      dupe: "",
      actions: [
        { label: "Ends", value: 1, id: 1 },
        { label: "Begins", value: 2, id: 2 }
      ],
      selectedCountry: null
    };
    this.handleInputChange = this.handleInputChange.bind(this);
    this.collapseHandler = this.collapseHandler.bind(this);
    this.removeErrorDiv = this.removeErrorDiv.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.handleOptionChange = this.handleOptionChange.bind(this);
    this.ProjectName = React.createRef();
    this.checkBoxChanged = this.checkBoxChanged.bind(this);
    this.createProject = this.createProject.bind(this);
  }
  handleOptionChange(event) {
    this.setState({
      selectedRadioOption: parseInt(event.target.value, 10)
    });
  }
  handleWeekChange = selectedOption => {
    this.setState({
      selectedAction: selectedOption
    });
  };
  handleCountryChange = selectedOption => {
    this.setState({
      selectedCountry: selectedOption
    });
  };
  handleCalendarChange = date => {
    this.setState({
      startDate: date
    });
  };
  checkBoxChanged() {
    const { makeDefault } = this.state;
    this.setState({ makeDefault: !makeDefault });
  }
  createProject() {
    const {
      startDate,
      selectedAction,
      makeDefault,
      selectedRadioOption,
      selectedCountry
    } = this.state;
    let useDefault = false;
    if (selectedRadioOption === 1) {
      useDefault = true;
    }

    this.setState({ creatingCalendar: true });
    axios
      .post(
        `${process.env.REACT_APP_BIGHORN_SERVER}/api/accubase/createProject`,
        {
          projectName: this.ProjectName.current.value,
          startDate,
          selectedAction,
          makeDefault,
          useDefault,
          country: selectedCountry
        },
        {
          headers: {
            ClientToken: localStorage.getItem("clientToken")
          }
        }
      )
      .then(res => {
        this.setState({ creatingCalendar: false });
        this.props.getProjects();
        this.handleClose();
      })
      .catch(err => {
        console.log(err);
      });
  }
  handleClose() {
    this.setState({
      showCreateNewProjModal: false,
      selectedRadioOption: 2,
      showTemplateModal: false,
      showSaveErrorModal: false
    });
  }
  removeErrorDiv() {
    this.setState({
      dupe: "",
      showNeedFolderAndInputDialog: false,
      invalidFileMessage: []
    });
  }
  handleInputChange(event, item) {
    this.props.setSearchProjects(
      this.projectSearch.value,
      this.props.projects,
      this.props.secondary
    );
  }
  projectClick(project) {
    this.props.setSelectedProject(project);
  }
  renderItem(item, idx) {
    if (this.props.type === "lift") {
      return this.renderLifts(item, idx);
    } else {
      return this.renderBaselines(item, idx);
    }
  }
  renderBaselines(item, idx) {
    if (this.props.secondary === true) {
      let baselines = [];
      if (item.batch !== undefined) {
        item.batch.forEach((sBatch, sIdx) => {
          if (sBatch.baseline !== undefined && sBatch.baselines.length > 0) {
            sBatch.baselines.forEach((baseline, bIdx) => {
              baselines.push(
                <div
                  key={"baseline" + sIdx + idx + bIdx}
                  style={{ marginLeft: "20px" }}
                  onClick={() => {
                    this.props.selectBaseline(baseline, 2);
                  }}
                >
                  {baseline.name}
                </div>
              );
            });
          }
        });
      }
      return (
        <div key={idx} style={{ display: "flex", flexDirection: "column" }}>
          {baselines}
        </div>
      );
    } else {
      return <div />;
    }
  }

  renderSecondary(item, index) {
    if (this.props.type === "lift") {
      if (item.open === undefined || item.open === true) {
        let lifts = [];
        if (item.baselines !== undefined) {
          item.baselines.forEach((baseline, bIdx) => {
            if (baseline.lifts !== undefined && baseline.lifts.length > 0) {
              baseline.lifts.forEach((lift, idx) => {
                lifts.push(
                  <div
                    key={"baseline" + idx + bIdx}
                    style={{ marginLeft: "15px" }}
                    className={
                      "lift-item " +
                      (this.props.liftId === lift.id ? "selectedLift" : "")
                    }
                    onClick={() => {
                      this.props.selectLift(lift, 2);
                    }}
                  >
                    {lift.name}
                  </div>
                );
              }, this);
            }
          }, this);
        }
        return (
          <div
            key={"lift" + index}
            style={{ display: "flex", flexDirection: "column" }}
          >
            {lifts}
          </div>
        );
      } else {
        return <div></div>;
      }
    } else if (this.props.type === "promo") {
      if (item.open === undefined || item.open === true) {
        let promos = [];
        if (item.data !== undefined) {
          item.data.forEach((datum, bIdx) => {
            if (datum.promoPlans !== undefined && datum.promoPlans.length > 0) {
              datum.promoPlans.forEach((promo, idx) => {
                if (promo.status === "complete") {
                  promos.push(
                    <div
                      key={"promoPlan" + idx + bIdx}
                      style={{ marginLeft: "15px" }}
                      className={
                        "lift-item " +
                        (this.props.selectedPromo.id === promo.id
                          ? "selectedLift"
                          : "")
                      }
                      onClick={() => {
                        this.props.selectPromo(promo, 2);
                      }}
                    >
                      {promo.name}
                    </div>
                  );
                }
              }, this);
            }
          }, this);
        }
        return (
          <div
            key={"promo" + index}
            style={{ display: "flex", flexDirection: "column" }}
          >
            {promos}
          </div>
        );
      } else {
        return <div></div>;
      }
    } else {
      if (item.open === undefined || item.open === true) {
        let baselines = [];
        if (item.batch !== undefined) {
          item.batch.forEach((batch, bIdx) => {
            if (batch.baselines !== undefined && batch.baselines.length > 0) {
              batch.baselines.forEach((baseline, idx) => {
                if (baseline.status === "complete") {
                  baselines.push(
                    <div
                      key={"batch" + idx + bIdx}
                      style={{ marginLeft: "15px" }}
                      className={
                        "baseline-item " +
                        (this.props.selectedBaseline.id === baseline.id
                          ? "selectedBaseline"
                          : "")
                      }
                      onClick={() => {
                        this.props.selectBaseline(baseline, 2);
                      }}
                    >
                      {baseline.name}
                    </div>
                  );
                }
              }, this);
            }
          }, this);
        }
        return (
          <div
            key={"baseline" + index}
            style={{ display: "flex", flexDirection: "column" }}
          >
            {baselines}
          </div>
        );
      } else {
        return <div></div>;
      }
    }
  }
  collapseHandler(item) {
    if (item.open === undefined) {
      item.open = false;
    } else {
      item.open = !item.open;
    }
    this.setState({ opened: !this.state.open });
  }
  renderProjects() {
    const { searchProjects } = this.props;

    if (this.props.secondary === true) {
      return searchProjects.map((item, idx) => {
        return (
          <div key={idx}>
            <div
              onClick={() => {
                this.projectClick(item);
              }}
              className="project-list-item"
              style={{ cursor: "pointer", marginLeft: "5%" }}
            >
              <div style={{ display: "flex", flexDirection: "row" }}>
                {item.PROJECT_NAME}
                <div
                  style={{
                    cursor: "pointer",
                    marginLeft: "auto",
                    paddingRight: "20px"
                  }}
                  onClick={() => {
                    this.collapseHandler(item);
                  }}
                  // data-tip="Collapse project"
                >
                  {item.open === undefined || item.open === true ? (
                    <RightChevron
                      className="noHover"
                      transform={"rotate(-90,12,12)"}
                      height={"24px"}
                      width={"24px"}
                      color={"black"}
                      viewBox={"0 -2 24 24"}
                    />
                  ) : (
                    <RightChevron
                      className="noHover"
                      transform={"rotate(0,12,12)"}
                      height={"24px"}
                      width={"24px"}
                      color={"black"}
                      viewBox={"0 -2 24 24"}
                    />
                  )}
                </div>
              </div>
              <div>{this.renderSecondary(item, idx)}</div>
            </div>
            <hr style={{ alignContent: "center", width: "90%" }} />
          </div>
        );
      });
    } else {
      return searchProjects.map((item, idx) => {
        return (
          <div key={idx}>
            <div
              onClick={() => {
                this.projectClick(item);
              }}
              className="project-list-item"
              style={{ cursor: "pointer", marginLeft: "5%" }}
            >
              {item.PROJECT_NAME}
            </div>
            <hr style={{ alignContent: "center", width: "90%" }} />
          </div>
        );
      });
    }
  }
  render() {
    const { opened, statusChangedHandler, countries } = this.props;
    const {
      showCreateNewProjModal,
      actions,
      selectedAction,
      selectedCountry,
      showSaveErrorModal,
      savingError
    } = this.state;
    var activeCreateButton = false;
    if (countries.length > 0) {
      if (selectedCountry === null) {
        let findUS = countries.filter(item => {
          return (
            item.value.toLowerCase() === "us" ||
            item.value.toLowerCase() === "united states" ||
            item.value.toLowerCase() === "united states of america" ||
            item.value.toLowerCase() === "usa"
          );
        });
        if (findUS.length > 0) {
          this.setState({ selectedCountry: findUS[0] });
        } else {
          this.setState({ selectedCountry: countries[0] });
        }
      }
    }
    if (this.ProjectName.current !== null) {
      if (this.ProjectName.current.value !== "") {
        let dupeCheck = this.props.projects.filter(item => {
          if (item.PROJECT_NAME === undefined) {
            return false;
          } else {
            return (
              item.PROJECT_NAME.toLowerCase() ===
              this.ProjectName.current.value.toLowerCase()
            );
          }
        });
        if (dupeCheck.length === 0) {
          activeCreateButton = true;
        }
      }
    }
    return (
      <div className={"all-projects-div " + (opened ? "open" : "")}>
        <Modal
          style={{ width: "550px", maxWidth: "550px" }}
          isOpen={showSaveErrorModal}
        >
          <ModalHeader>Error While Saving</ModalHeader>
          <ModalBody>
            <div>{savingError}</div>
          </ModalBody>
          <ModalFooter className="project-dialog">
            <Button
              onClick={() => {
                this.handleClose();
              }}
              style={{ border: "none" }}
            >
              Ok
            </Button>
          </ModalFooter>
        </Modal>
        <Modal
          style={{ width: "550px", maxWidth: "550px" }}
          isOpen={showCreateNewProjModal}
        >
          <ModalHeader>Create New Project</ModalHeader>
          <ModalBody>
            {this.state.dupe !== "" && (
              <div
                className="error-div validation-error-div"
                style={{
                  position: "absolute",
                  display: "flex",
                  flexDirection: "row",
                  width: "93%",
                  padding: "5px"
                }}
              >
                {this.state.dupe}
                <div
                  style={{ cursor: "pointer", marginLeft: "auto" }}
                  onClick={this.removeErrorDiv}
                >
                  x
                </div>
              </div>
            )}
            <div>
              <div>Project Name</div>
              <input
                onChange={() => {
                  let dupeCheck = this.props.projects.filter(item => {
                    if (item.PROJECT_NAME === undefined) {
                      return false;
                    } else {
                      return (
                        item.PROJECT_NAME.toLowerCase() ===
                        this.ProjectName.current.value.toLowerCase()
                      );
                    }
                  });
                  if (dupeCheck.length > 0) {
                    this.setState({
                      projName: this.ProjectName.current.value,
                      dupe:
                        "A project with that name already exists, please enter a different name."
                    });
                  } else {
                    this.setState({
                      projName: this.ProjectName.current.value,
                      dupe: ""
                    });
                  }
                }}
                style={{ width: "100%" }}
                ref={this.ProjectName}
              ></input>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  marginTop: "5px"
                }}
              >
                <div>Country</div>
                <Select
                  className="country-dropdown"
                  classNamePrefix="react-select"
                  placeholder={"Country"}
                  value={
                    selectedCountry //isClearable
                  }
                  onChange={this.handleCountryChange}
                  options={countries}
                />
                <div style={{ fontSize: "9px", marginTop: "7px" }}>
                  Select the country for this project to apply holiday dates
                </div>
              </div>
            </div>
            <hr />
            <div
              style={{
                marginTop: "10px",
                display: "flex",
                flexDirection: "column"
              }}
            >
              <label
                style={{
                  display: "flex",
                  flexDirection: "row",
                  marginBottom: "5px",
                  alignItems: "center"
                }}
                key={"label" + 1}
              >
                <input
                  checked={this.state.selectedRadioOption === 1}
                  onChange={this.handleOptionChange}
                  type="radio"
                  style={{ marginRight: "5px" }}
                  value={1}
                  disabled={!this.props.defaultCalendarExists}
                />
                <div
                  className={
                    !this.props.defaultCalendarExists ? "disabled-text" : ""
                  }
                >
                  Use Default Calendar
                </div>
                {!this.props.defaultCalendarExists && (
                  <div style={{ fontStyle: "italic", marginLeft: "20px" }}>
                    No default calendar found
                  </div>
                )}
              </label>
              <label
                style={{ marginTop: "10px", marginBottom: "5px" }}
                key={"label" + 2}
              >
                <input
                  checked={this.state.selectedRadioOption === 2}
                  onChange={this.handleOptionChange}
                  type="radio"
                  style={{ marginRight: "5px" }}
                  value={2}
                />
                Create Calendar
              </label>
            </div>
            <div style={{ marginTop: "10px" }} className="info-text">
              This tool will create a calendar in this project for the selected
              year, three years into the past, and three years into the future.
            </div>
            <div style={{ marginTop: "10px" }} className="info-text">
              This setting also determines the day for week-begin or week-end
              for baseline calculations.
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                marginTop: "20px",
                alignItems: "center"
              }}
            >
              <div
                className={
                  this.state.selectedRadioOption === 1 ? "disabled-text" : ""
                }
                style={{}}
              >
                The first week of the year
              </div>
              <Select
                className="begin-end-dropdown"
                classNamePrefix="react-select"
                placeholder={"Actions"}
                value={
                  selectedAction //isClearable
                }
                onChange={this.handleWeekChange}
                options={actions}
                isDisabled={this.state.selectedRadioOption === 1}
              />
              <div
                className={
                  this.state.selectedRadioOption === 1 ? "disabled-text" : ""
                }
                style={{}}
              >
                on
              </div>
              <div style={{ marginLeft: "5px" }}>
                <DatePicker
                  className="datepicker"
                  selected={this.state.startDate}
                  onChange={this.handleCalendarChange}
                  disabled={this.state.selectedRadioOption === 1}
                />
              </div>
            </div>
            <div style={{ marginTop: "40px" }} className="info-text">
              Making this calendar the default will make it available to other
              projects as their default calendar and extends future calendars to
              twenty years.
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                marginTop: "5px"
              }}
            >
              <div
                className={
                  this.state.selectedRadioOption === 1 ? "disabled-text" : ""
                }
                style={{ marginRight: "10px" }}
              >
                Make Default Calendar
              </div>
              <Checkbox
                onChange={this.checkBoxChanged}
                checked={this.state.makeDefault}
                style={{ textAlign: "center", paddingRight: "25px" }}
                disabled={this.state.selectedRadioOption === 1}
              />
            </div>
          </ModalBody>
          <ModalFooter className="project-dialog">
            {!this.state.creatingCalendar ? (
              <Button
                className={
                  "" + (activeCreateButton ? "btn-primary" : "disabled")
                }
                style={{ border: "none" }}
                onClick={() => {
                  if (activeCreateButton) {
                    this.createProject();
                  }
                }}
              >
                Create
              </Button>
            ) : (
              <div
                className="accubase-uploading"
                style={{
                  marginLeft: "auto",

                  display: "flex",
                  flexDirection: "row"
                }}
              >
                <DotLoader
                  sizeUnit={"px"}
                  size={15}
                  color={"black"}
                  loading={true}
                />
                <div style={{ marginLeft: "5px" }}>Creating Project...</div>
              </div>
            )}
            <Button
              onClick={() => {
                this.handleClose();
              }}
              style={{ border: "none" }}
            >
              Cancel
            </Button>
          </ModalFooter>
        </Modal>
        <div style={{ display: "flex", flexDirection: "row" }}>
          <div
            onClick={statusChangedHandler}
            className="icon-wrapper"
            // data-tip={
            //   opened ? "Collapse project panel" : "Expand project panel"
            // }
          >
            {this.props.secondary === true && this.props.type === "lift" ? (
              <LiftModelIcon
                className={"panel-title-icon noHover"}
                height={"24px"}
                width={"24px"}
                color={"white"}
                viewBox={"0 -2 24 24"}
              />
            ) : (
              <ProjectIcon
                className={"panel-title-icon noHover"}
                height={"24px"}
                width={"24px"}
                color={"white"}
                viewBox={"0 -2 24 24"}
              />
            )}
          </div>
          <div
            className={
              "all-projects-header panel-title-bar " +
              (opened ? "panel-title-bar-open" : "panel-title-bar-closed")
            }
          >
            {this.props.lifts === true ? (
              <div style={{ marginLeft: "18px", whiteSpace: "nowrap" }}>
                Lift Models
              </div>
            ) : (
              <div style={{ marginLeft: "20px", whiteSpace: "nowrap" }}>
                All Projects
              </div>
            )}
            <OverlayTrigger
              key={"collapse-tip"}
              placement={"left"}
              overlay={
                <Tooltip id={"collapse-tip"}>Collapse project panel</Tooltip>
              }
            >
              <div
                style={{
                  cursor: "pointer",
                  marginLeft: "auto",
                  paddingRight: "20px"
                }}
                onClick={statusChangedHandler}
              >
                <RightChevron
                  className="noHover"
                  transform={"rotate(-90,12,12)"}
                  height={"24px"}
                  width={"24px"}
                  color={"white"}
                  viewBox={"0 -2 24 24"}
                />
              </div>
            </OverlayTrigger>
          </div>
        </div>
        <div
          style={{
            display: "flex",
            width: "100%",
            paddingLeft: "10px",
            paddingRight: "10px",
            paddingTop: "20px",
            marginBottom: "20px"
          }}
        >
          <div
            onClick={() => {
              this.setState({ showCreateNewProjModal: true });
            }}
            className="create-project btn btn-high-viz"
            style={{
              marginLeft: "auto",
              marginRight: "auto",
              cursor: "pointer",
              width: "100%"
            }}
          >
            + Create Project
          </div>
        </div>
        <div
          className={"search " + (opened ? "search-open" : "search-closed")}
          style={{ paddingLeft: "10px", paddingRight: "10px" }}
        >
          <input
            className="input-box"
            placeholder="search"
            ref={input => (this.projectSearch = input)}
            onChange={event => {
              this.handleInputChange(event, "search");
            }}
            style={{
              width: "100%",
              position: "relative"
            }}
          />
        </div>
        <hr style={{ alignContent: "center", width: "90%" }} />
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            overflowY: "auto"
            // height: "86%"
          }}
        >
          {this.renderProjects()}
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  selectedLift: state.acculift.selectedLift,
  selectedBaseline: state.accubase.selectedBaseline,
  defaultCalendarExists: state.accubase.defaultCalendarExists,
  countries: state.accubase.countries,
  selectedPromo: state.promo.selectedPromo,
  liftId: state.acculift.liftId
});
const mapDispatchToProps = dispatch => ({
  selectLift(lift, viewSelected) {
    dispatch(selectLift(lift, viewSelected));
  },
  selectBaseline(baseline, viewSelected) {
    dispatch(selectBaseline(baseline, viewSelected));
  },
  selectPromo(promoId, view) {
    dispatch(selectPromo(promoId, view));
  }
});

export default connect(mapStateToProps, mapDispatchToProps)(ProjectSelector);
