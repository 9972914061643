import React, { Component } from "react";
import "components/tabsApps/style/style.css";
import onClickOutside from "react-onclickoutside";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { NotificationsIcon } from "lib/icons";
import { createNotification, getNotifications, deleteNotifications, clearNotifications } from "./notificationsReducer";
import { getSlides } from "components/pptCart/pptCartReducer";
import "components/profile/style/style.css";
import "./style.css";
import { socket } from "routes";
import { DotLoader } from "react-spinners";

import axios from "axios";

import { NotificationsItem } from "components/notifications/notificationsItem";

class Notifications extends Component {
  constructor(props) {
    super(props);
    this.state = {
      listOpen: false
      //items: [{ name: "Download To Powerpoint",icon:"DownloadIcon" action: "logout" }]
    };

    this.deleteAllHandler = this.deleteAllHandler.bind(this);
    this.downloadActionHandler = this.downloadActionHandler.bind(this);
    this.toggleList = this.toggleList.bind(this);
    this.displayBadges = this.displayBadges.bind(this);
    this.checkForIframeClick = this.checkForIframeClick.bind(this);
    this.handleClickOutside = this.handleClickOutside.bind(this);
  }
  componentWillUnmount() {
    socket.off("DownloadAction", this.downloadActionHandler);
    window.removeEventListener("blur", this.checkForIframeClick, false);
  }
  componentDidMount() {
    // console.log("ADDING LISTENERES");
    socket.on("DownloadAction", this.downloadActionHandler);
    window.addEventListener("blur", this.checkForIframeClick, false);
  }
  checkForIframeClick = event => {
    if (document.activeElement.tagName === "IFRAME") {
      this.setState({
        listOpen: false
      });
    }
  };
  async downloadActionHandler(action) {
    // console.log("GOT ACTION");
    // var blob = new Blob([action.data],{type:"application/octet-stream"});
    // var buffer = new Buffer(action.data, "binary");
    // let buffObj = {type:"Buffer",data:buffer};
    // save(buffObj, action.fileName);
    this.props.getNotifications();
    this.props.getSlides();
  }

  componentDidUpdate() {
    const { apps, loaded } = this.props;
    if (apps.length > 0 && !loaded) {
      this.props.getNotifications();
    }
  }
  handleClickOutside() {
    if (this.state.listOpen) {
      this.props.clearNotifications();
      this.setState({
        listOpen: false
      });
    }
  }

  deleteAllHandler() {
    axios
      .post(
        `${process.env.REACT_APP_BIGHORN_SERVER}/api/app/removeAllNotifcations`,
        {},
        {
          headers: {
            ClientToken: localStorage.getItem("clientToken")
          }
        }
      )
      .then(response => {})
      .catch(error => {
        //dispatch(fetchReportListError(error));
      });
  }
  toggleList() {
    const { listOpen } = this.state;
    if (listOpen) {
      this.props.clearNotifications();
    }
    this.setState(prevState => ({
      listOpen: !prevState.listOpen
    }));
  }
  renderNotificationsItem(notifications) {
    return notifications.map((item, index) => {
      return <NotificationsItem item={item} key={index} index={index} />;
    });
  }
  displayBadges() {
    const { notifications } = this.props;
    var showRunning = false;
    let running = notifications.filter(item => {
      return item.status === "running";
    });
    if (running.length > 0) {
      showRunning = true;
    }
    if (this.props.count > 0 && !showRunning && this.props.unviewedCount > 0) {
      return (
        <div className="notifications notifications-with-count " onClick={() => this.toggleList()}>
          <NotificationsIcon color={"rgba(88, 89, 91, 1)"} height={"20"} className="notifications-icon" />

          <span className="notifications-badge">{this.props.unviewedCount}</span>
        </div>
      );
    } else if (this.props.count > 0 && showRunning) {
      return (
        <div className="notifications notifications-with-count notifications-with-spinner" onClick={() => this.toggleList()}>
          <NotificationsIcon
            color={"rgba(88, 89, 91, 1)"}
            height={"20"}
            className="notifications-icon"
            style={{ marginBottom: "6px" }}
          />

          <span
            style={{
              position: "relative",
              top: "-34px",
              left: "7px"
            }}>
            <DotLoader sizeUnit={"px"} size={15} color={"#CC7E00"} loading={true} />
          </span>
        </div>
      );
    } else {
      return (
        <div className="notifications" onClick={() => this.toggleList()}>
          <NotificationsIcon color={"rgba(88, 89, 91, 1)"} height={"20"} className="notifications-icon" />
        </div>
      );
    }
  }
  render() {
    const { notifications } = this.props;

    return (
      <div className={"flex-container"}>
        <div className="notifications-parent">
          {this.state.listOpen ? (
            <div style={{ height: "100%", width: "100%" }}>
              <div className="notifications-selected" onClick={() => this.toggleList()}>
                <NotificationsIcon color={"white"} height={"20"} className="notifications-icon-selected notifications-icon" />
              </div>
              <div style={{ position: "absolute" }}>
                <div className="notifications-dd-items">
                  <div className="notifications-title-container">
                    <div className="notifications-title">Notifications</div>

                    <div className="clear-all" onClick={this.deleteAllHandler}>
                      clear all
                    </div>
                  </div>
                  <hr />
                  <div className="notification-message-div">{this.renderNotificationsItem(notifications)}</div>
                </div>
              </div>
            </div>
          ) : (
            <div>{this.displayBadges()}</div>
          )}
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  notifications: state.notifications.notifications,
  count: state.notifications.count,
  unviewedCount: state.notifications.unviewedCount,
  apps: state.app.apps,
  loading: state.notifications.loading,
  isAuthed: state.auth.isAuthed,
  loaded: state.notifications.loaded
});
const mapDispatchToProps = dispatch => ({
  createNotification(notification) {
    dispatch(createNotification(notification));
  },
  getNotifications() {
    dispatch(getNotifications());
  },
  deleteNotifications(slides, item = null) {
    dispatch(deleteNotifications(slides, item));
  },
  getSlides() {
    dispatch(getSlides());
  },
  clearNotifications() {
    dispatch(clearNotifications());
  }
});

Notifications = onClickOutside(Notifications);
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Notifications));
