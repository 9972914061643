import * as d3 from "d3";

import * as d3Scale from "d3-scale";
import * as d3Symbol from "d3-symbol-extra";
import * as d3Shape from "d3-shape";
import { axisBottom, axisLeft, axisRight } from "d3-axis";
import { select } from "d3-selection";
import React, { Component } from "react";
import { connect } from "react-redux";
import {
  setReinitChart,
  setAccubaseData,
  setChangedData
} from "components/accubase/reducers/accubaseReducer";
//import Legend from "./legend";

var margin = { top: 30, right: 40, bottom: 60, left: 50 },
  fullWidth = 900,
  fullHeight = 300,
  width = fullWidth - margin.left - margin.right - 20,
  height = fullHeight - margin.top - margin.bottom,
  legendRectSize = 5,
  legendSpacing = 2;

const baseUnitColor = "rgb(79, 129, 189)";
const baseUnitTextColor = "rgb(48,79,117)";
const valueUnitColor = "rgb(192, 80, 77)";
const valueUnitTextColor = "rgb(123,50,48)";
const edrpWtdColor = "rgb(128, 100, 162)";
const edrpWtdTextColor = "rgb(91,65,123)";
const adrpWtdColor = "rgb(155, 187, 89)";
const adrpWtdTextColor = "rgb(114,143,54)";
const elasFitUnitsTextColor = "rgb(235,186,78)";
// const whiteStrokeOpacity = 0.95;
// const whiteStrokeWidth = "4px";
const tooltipFontSize = "10px";

function formatNumber(num) {
  return num
    .toFixed(2)
    .toString()
    .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
}
function formatCurrency(num) {
  return (
    parseFloat(num)
      .toFixed(2)
      .toString()
      .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")
  );
}
var x = d3.scaleTime().rangeRound([0, width]);
var y0 = d3Scale.scaleLinear().rangeRound([height, 0]);
var y1 = d3Scale.scaleLinear().rangeRound([height, 0]);
var bisectDate = d3.bisector(function(d) {
  return parseTime(d.key);
}).left;
var valueline = d3
  .line()
  .x(function(d) {
    if (isNaN(x(parseTime(d.key)))) {
      return 0;
    } else {
      return x(parseTime(d.key));
    }
  })
  .y(function(d) {
    return y0(d.value.baseUnit);
  });

var valueLineValueUnits = d3
  .line()
  .x(d => {
    if (isNaN(x(parseTime(d.key)))) {
      return 0;
    } else {
      return x(parseTime(d.key));
    }
  })
  .y(d => {
    return y0(d.value.valuesUnitsOut);
  });
var valueElasFitUnits = d3
  .line()
  .x(d => {
    if (isNaN(x(parseTime(d.key)))) {
      return 0;
    } else {
      return x(parseTime(d.key));
    }
  })
  .y(d => {
    return y0(d.value.elasFitUnits);
  });

var valueline2 = d3
  .line()
  .x(function(d) {
    if (isNaN(x(parseTime(d.key)))) {
      return 0;
    } else {
      return x(parseTime(d.key));
    }
  })
  .y(function(d) {
    return y1(d.value.edrpWtd);
  });
var valuelineArpWtd = d3
  .line()
  .x(function(d) {
    if (isNaN(x(parseTime(d.key)))) {
      return 0;
    } else {
      return x(parseTime(d.key));
    }
  })
  .y(function(d) {
    return y1(d.value.arpWtd);
  });

var parseTime = d3.timeParse("%Y-%m-%d");
//var formatDate = d3.timeFormat("%d-%b");
class DualAxisLineChart extends Component {
  constructor(props) {
    super(props);
    this.state = {
      //data: this.props.data,
      baseUnitCount: [],
      average: [],
      circlesSetup: false,
      activeData: null,
      activeNode: null,
      activeAvgNode: null,
      activeNodeIndex: -1,
      oldDataValue: -1,
      masterItems: [],
      geo2Items: [],
      baseUnitActive: false,
      edrpWtdActive: false,
      domainY0: [0, 0],
      domainY1: [0, 0],
      modelFitChecked: true
    };
    // this.drawChart();

    this.mousemoveUnits = this.mousemoveUnits.bind(this);
    this.mousemoveAvg = this.mousemoveAvg.bind(this);
    this.baseLineClick = this.baseLineClick.bind(this);
    this.avgClick = this.avgClick.bind(this);
  }
  componentDidUpdate() {
    const { baseUnitCount } = this.state;
    // console.log("Component Did Update");
    if (baseUnitCount.length > 0) {
      this.drawAxis();
    } else {
      this.svg.selectAll(".edrpWtdCircle").remove();
      this.svg.selectAll(".arpWtdCircle").remove();
      this.svg.selectAll(".baseUnitCircle").remove();
      this.svg.selectAll(".elasFitUnitsCircle").remove();
      this.svg.selectAll(".valueUnitsCircle").remove();
      this.svg.selectAll(".focusUnits").remove();
      this.svg.selectAll(".focusAvg").remove();
      d3.select(".unitsLines")
        .on("mouseover", null)
        .on("mouseout", null)
        .on("mousemove", null);
      d3.select(".avgLines")
        .on("mouseover", null)
        .on("mouseout", null)
        .on("mousemove", null);
    }
    if (this.props.reInitChart) {
      var bUnitCount = d3
        .group()
        .key(function(d) {
          return d.WEEK_DATE;
        })
        .rollup(function(v) {
          return {
            baseUnit: d3.sum(v, function(d) {
              return d.BASE_UNITS;
            }),
            elasFitUnits: d3.sum(v, function(d) {
              return d.ELAS_FIT_UNITS;
            }),
            valuesUnitsOut: d3.sum(v, d => {
              return d.UNITS;
            }),
            id: d3.max(v, d => {
              return d.ID;
            })
          };
        })
        .entries(this.props.chartData);
      var average = d3
        .group()
        .key(d => {
          return d.WEEK_DATE;
        })
        .rollup(v => {
          return {
            baseSales: d3
              .sum(v, d => {
                return d.BASE_EDRP * d.BASE_UNITS;
              })
              .toFixed(2),
            baseUnit: d3.sum(v, function(d) {
              return d.BASE_UNITS;
            }),
            elasFitUnits: d3.sum(v, function(d) {
              return d.ELAS_FIT_UNITS;
            }),
            unitsOut: d3.sum(v, function(d) {
              return d.UNITS;
            }),
            salesOut: d3.sum(v, function(d) {
              return d.VALUE;
            }),
            edrpWtd: d3
              .median(v, d => {
                return d.BASE_EDRP;
              })
              .toFixed(2),
            arpWtd: d3
              .median(v, d => {
                return d.ARP;
              })
              .toFixed(2)
          };
        })
        .entries(this.props.chartData);
      let newAverage = d3
        .group()
        .key(d => {
          return d.key;
        })
        .rollup(v => {
          return {
            edrpWtd: d3
              .median(v, d => {
                if (d.value.baseUnit === 0) return 0;
                return d.value.baseSales / d.value.baseUnit;
              })
              .toFixed(2),
            arpWtd: d3
              .median(v, d => {
                if (d.value.unitsOut === 0) return 0;
                return d.value.salesOut / d.value.unitsOut;
              })
              .toFixed(2)
          };
        })
        .entries(average);

      var masterItems = d3
        .group()
        .key(d => {
          return d.ITEM_KEY;
        })
        .entries(this.props.chartData);
      var geo2Items = d3
        .group()
        .key(d => {
          return d.GEO_KEY;
        })
        .entries(this.props.chartData);

      this.setState({
        baseUnitCount: bUnitCount,
        average: newAverage,
        masterItems,
        geo2Items,
        circlesSetup: false
      });

      // this.svg.selectAll(".baseUnit").attr("d", valueline(bUnitCount));
      // this.svg
      //   .selectAll(".valueLineValueUnits")
      //   .attr("d", valueLineValueUnits(bUnitCount));
      // this.svg.selectAll(".edrpWtd").attr("d", valueline2(average));
      // this.svg.selectAll(".arpWtd").attr("d", valuelineArpWtd(average));
      this.props.setReinitChart(false);
    }
    // this.svg
    //   .selectAll(".dot")
    //   .data(this.props.data)
    //   .enter()
    //   .append("circle") // Uses the enter().append() method
    //   .attr("class", "dot") // Assign a class for styling
    //   .attr("cx", function(d, i) {
    //     return x(i);
    //   })
    //   .attr("cy", function(d) {
    //     return y0(d.y);
    //   })
    //   .attr("r", 5)
    //   .on("mouseover", function(a, b, c) {
    //     console.log(a);
    //     this.attr("class", "focus");
    //   })
    //   .on("mouseout", function() {});
  }
  componentDidMount() {
    var baseUnitCount = d3
      .group()
      .key(function(d) {
        return d.WEEK_DATE;
      })
      .rollup(function(v) {
        return {
          baseUnit: d3.sum(v, function(d) {
            return d.BASE_UNITS;
          }),
          elasFitUnits: d3.sum(v, function(d) {
            return d.ELAS_FIT_UNITS;
          }),
          valuesUnitsOut: d3.sum(v, d => {
            return d.UNITS;
          }),
          id: d3.max(v, d => {
            return d.ID;
          })
        };
      })
      .entries(this.props.chartData);

    var average = d3
      .group()
      .key(d => {
        return d.WEEK_DATE;
      })
      .rollup(v => {
        return {
          baseSales: d3
            .sum(v, d => {
              return d.BASE_EDRP * d.BASE_UNITS;
            })
            .toFixed(2),
          baseUnit: d3.sum(v, function(d) {
            return d.BASE_UNITS;
          }),
          elasFitUnits: d3.sum(v, function(d) {
            return d.ELAS_FIT_UNITS;
          }),
          unitsOut: d3.sum(v, function(d) {
            return d.UNITS;
          }),
          salesOut: d3.sum(v, function(d) {
            return d.VALUE;
          }),
          edrpWtd: d3
            .median(v, d => {
              return d.BASE_EDRP;
            })
            .toFixed(2),
          arpWtd: d3
            .median(v, d => {
              return d.ARP;
            })
            .toFixed(2)
        };
      })
      .entries(this.props.chartData);

    let newAverage = d3
      .group()
      .key(d => {
        return d.key;
      })
      .rollup(v => {
        return {
          edrpWtd: d3
            .median(v, d => {
              if (d.value.baseUnit === 0) return 0;
              return d.value.baseSales / d.value.baseUnit;
            })
            .toFixed(2),
          arpWtd: d3
            .median(v, d => {
              if (d.value.unitsOut === 0) return 0;
              return d.value.salesOut / d.value.unitsOut;
            })
            .toFixed(2)
        };
      })
      .entries(average);

    var masterItems = d3
      .group()
      .key(d => {
        return d.ITEM_KEY;
      })
      .entries(this.props.chartData);
    var geo2Items = d3
      .group()
      .key(d => {
        return d.GEO_KEY;
      })
      .entries(this.props.chartData);

    this.setState({
      baseUnitCount: baseUnitCount,
      average: newAverage,
      masterItems,
      geo2Items
    });
  }

  mousemoveUnits(event,d, focus) {
    let baseUnit = false;
    let elasUnit = false;
    let valueUnit = false;
    let change;

    if (event.target.className.baseVal.includes("baseUnitCircleHit")) {
      baseUnit = true;
    } else if (
      event.target.className.baseVal.includes("elasFitUnitsCircle")
    ) {
      elasUnit = true;
    } else if (event.target.className.baseVal.includes("valueUnitsCircle")) {
      valueUnit = true;
    }
    console.log("TARGET CLASSNAME");
    console.log(event.target.className.baseVal);
    console.log(event.target.className);
    console.log("*******************************");

    d3.select(".unitsLines").raise();
    d3.selectAll(".focusUnits").raise();
    var x0 = x.invert(d3.pointer(this.unitsLines.node())[0]);
    var i = bisectDate(
      this.state.baseUnitCount,
      x0,
      1,
      this.state.baseUnitCount.length - 1
    );
    var avgD;
    var d0 = this.state.baseUnitCount[i - 1];
    var d1 = this.state.baseUnitCount[i];
    var avgD0 = this.state.average[i - 1];
    var avgD1 = this.state.average[i];
    if (avgD0 === undefined) {
      avgD = avgD1;
    } else if (avgD1 === undefined) {
      avgD = avgD0;
    } else {
      avgD =
        x0 - parseTime(avgD0.key) > parseTime(avgD1.key) - x0 ? avgD1 : avgD0;
    }
    if (d0 === undefined) {
      d = d1;
    } else if (d1 === undefined) {
      d = d0;
    } else {
      d = x0 - parseTime(d0.key) > parseTime(d1.key) - x0 ? d1 : d0;
    }
    var offset = x(parseTime(d.key)) - 85;
    if (baseUnit) {
      focus.select("#crossHairY").attr("display", null);
      focus.select(".x").attr("display", null);
      focus.select("#unitsOutCrossHairX").attr("display", "none");
      focus.select("#unitsOutCrossHairY").attr("display", "none");
      focus.select("circle.unitsOutY").attr("display", "none");
      d3.select(".baseToolTipTri").attr(
        "transform",
        "translate(" + (offset + 84.5) + "," + (y0(d.value.baseUnit) - 11) + ")"
      );
      focus
        .selectAll(".bgUnitsRect")
        .attr(
          "transform",
          "translate(" +
            (offset + 12) +
            "," +
            (y0(d.value.baseUnit) - 119) +
            ")"
        );
      focus
        .select("circle.y") // **********
        .attr("display", null)
        .attr(
          "transform",
          "translate(" + x(parseTime(d.key)) + "," + y0(d.value.baseUnit) + ")"
        );
      focus
        .select("text.y4")
        .attr(
          "transform",
          "translate(" +
            (offset + 75) +
            "," +
            (y0(d.value.baseUnit) - 110) +
            ")"
        )
        .text(d.key);
      focus
        .select("text.y4Label")
        .attr(
          "transform",
          "translate(" +
            (offset - 55) +
            "," +
            (y0(d.value.baseUnit) - 110) +
            ")"
        )
        .text("Period:");
      focus
        .select("text.y2")
        .attr(
          "transform",
          "translate(" + (offset + 75) + "," + (y0(d.value.baseUnit) - 95) + ")"
        )
        .text(formatNumber(d.value.baseUnit));
      focus
        .select("text.y2Label")
        .attr(
          "transform",
          "translate(" + (offset - 55) + "," + (y0(d.value.baseUnit) - 95) + ")"
        )
        .text("Base Unit:");
      focus
        .select("text.unitsOutY2")
        .attr(
          "transform",
          "translate(" + (offset + 75) + "," + (y0(d.value.baseUnit) - 80) + ")"
        )
        .text(formatNumber(d.value.valuesUnitsOut));
      focus
        .select("text.unitsOutY2Label")
        .attr(
          "transform",
          "translate(" + (offset - 55) + "," + (y0(d.value.baseUnit) - 80) + ")"
        )
        .text("Units Out:");
      focus
        .select("text.baseWAvgY2")
        .attr(
          "transform",
          "translate(" + (offset + 75) + "," + (y0(d.value.baseUnit) - 65) + ")"
        )
        .text(formatCurrency(avgD.value.edrpWtd));
      focus
        .select("text.baseWAvgY2Label")
        .attr(
          "transform",
          "translate(" + (offset - 55) + "," + (y0(d.value.baseUnit) - 65) + ")"
        )
        .text("EDRP:");
      focus
        .select("text.baseWArpY2")
        .attr(
          "transform",
          "translate(" + (offset + 75) + "," + (y0(d.value.baseUnit) - 50) + ")"
        )
        .text(formatCurrency(avgD.value.arpWtd));
      focus
        .select("text.baseWArpY2Label")
        .attr(
          "transform",
          "translate(" + (offset - 55) + "," + (y0(d.value.baseUnit) - 50) + ")"
        )
        .text("Avg Price:");

      focus
        .select("text.elasFit")
        .attr(
          "transform",
          "translate(" + (offset + 75) + "," + (y0(d.value.baseUnit) - 35) + ")"
        )
        .text(formatNumber(d.value.elasFitUnits));
      focus
        .select("text.elasFitLabel")
        .attr(
          "transform",
          "translate(" + (offset - 55) + "," + (y0(d.value.baseUnit) - 35) + ")"
        )
        .text("Elas Fit Units:");
      focus
        .select(".x")
        .attr(
          "transform",
          "translate(" + x(parseTime(d.key)) + "," + y0(d.value.baseUnit) + ")"
        )
        .attr("y2", height - y0(d.value.baseUnit));

      change = x(parseTime(d.key));

      focus
        .select("#crossHairY")
        .attr("transform", `translate(${change},${y0(d.value.baseUnit)} )`)
        .attr("x2", 0)
        .attr("x1", -x(parseTime(d.key)));
    } else if (elasUnit) {
      focus.select("#crossHairY").attr("display", "none");
      focus.select(".x").attr("display", "none");
      focus.select("#unitsOutCrossHairX").attr("display", null);
      focus.select("#unitsOutCrossHairY").attr("display", null);
      focus.select("circle.y").attr("display", "none"); // **********
      d3.select(".baseToolTipTri").attr(
        "transform",
        "translate(" +
          (offset + 84.5) +
          "," +
          (y0(d.value.elasFitUnits) - 11) +
          ")"
      );
      focus
        .selectAll(".bgUnitsRect")
        .attr(
          "transform",
          "translate(" +
            (offset + 12) +
            "," +
            (y0(d.value.elasFitUnits) - 119) +
            ")"
        );

      // focus
      //   .select("circle.unitsOutY") // **********
      //   .attr("display", null)
      //   .attr(
      //     "transform",
      //     "translate(" +
      //       x(parseTime(d.key)) +
      //       "," +
      //       y0(d.value.elasFitUnits) +
      //       ")"
      //   );
      focus
        .select("text.y4")
        .attr(
          "transform",
          "translate(" +
            (offset + 75) +
            "," +
            (y0(d.value.elasFitUnits) - 110) +
            ")"
        )
        .text(d.key);
      focus
        .select("text.y4Label")
        .attr(
          "transform",
          "translate(" +
            (offset - 55) +
            "," +
            (y0(d.value.elasFitUnits) - 110) +
            ")"
        )
        .text("Period:");
      focus
        .select("text.y2")
        .attr(
          "transform",
          "translate(" +
            (offset + 75) +
            "," +
            (y0(d.value.elasFitUnits) - 95) +
            ")"
        )
        .text(formatNumber(d.value.baseUnit));
      focus
        .select("text.y2Label")
        .attr(
          "transform",
          "translate(" +
            (offset - 55) +
            "," +
            (y0(d.value.elasFitUnits) - 95) +
            ")"
        )
        .text("Base Unit:");
      focus
        .select("text.unitsOutY2")
        .attr(
          "transform",
          "translate(" +
            (offset + 75) +
            "," +
            (y0(d.value.elasFitUnits) - 80) +
            ")"
        )
        .text(formatNumber(d.value.valuesUnitsOut));
      focus
        .select("text.unitsOutY2Label")
        .attr(
          "transform",
          "translate(" +
            (offset - 55) +
            "," +
            (y0(d.value.elasFitUnits) - 80) +
            ")"
        )
        .text("Units Out:");
      focus
        .select("text.baseWAvgY2")
        .attr(
          "transform",
          "translate(" +
            (offset + 75) +
            "," +
            (y0(d.value.elasFitUnits) - 65) +
            ")"
        )
        .text(formatCurrency(avgD.value.edrpWtd));
      focus
        .select("text.baseWAvgY2Label")
        .attr(
          "transform",
          "translate(" +
            (offset - 55) +
            "," +
            (y0(d.value.elasFitUnits) - 65) +
            ")"
        )
        .text("EDRP:");
      focus
        .select("text.baseWArpY2")
        .attr(
          "transform",
          "translate(" +
            (offset + 75) +
            "," +
            (y0(d.value.elasFitUnits) - 50) +
            ")"
        )
        .text(formatCurrency(avgD.value.arpWtd));
      focus
        .select("text.baseWArpY2Label")
        .attr(
          "transform",
          "translate(" +
            (offset - 55) +
            "," +
            (y0(d.value.elasFitUnits) - 50) +
            ")"
        )
        .text("Avg Price:");
      focus
        .select("text.elasFit")
        .attr(
          "transform",
          "translate(" +
            (offset + 75) +
            "," +
            (y0(d.value.elasFitUnits) - 35) +
            ")"
        )
        .text(formatNumber(d.value.elasFitUnits));
      focus
        .select("text.elasFitLabel")
        .attr(
          "transform",
          "translate(" +
            (offset - 55) +
            "," +
            (y0(d.value.elasFitUnits) - 35) +
            ")"
        )
        .text("Elas Fit Units:");
      focus
        .select("#unitsOutCrossHairX")
        .attr(
          "transform",
          "translate(" +
            x(parseTime(d.key)) +
            "," +
            y0(d.value.elasFitUnits) +
            ")"
        )
        .style("stroke", elasFitUnitsTextColor)
        .attr("y2", height - y0(d.value.elasFitUnits));

      change = x(parseTime(d.key));

      focus
        .select("#unitsOutCrossHairY")
        .attr("transform", `translate(${change},${y0(d.value.elasFitUnits)} )`)
        .attr("x2", 0)
        .attr("x1", -x(parseTime(d.key)))
        .style("stroke", elasFitUnitsTextColor);
    } else if (valueUnit) {
      ///////
      focus.select("#crossHairY").attr("display", "none");
      focus.select(".x").attr("display", "none");
      focus.select("#unitsOutCrossHairX").attr("display", null);
      focus.select("#unitsOutCrossHairY").attr("display", null);
      focus.select("circle.y").attr("display", "none"); // **********
      d3.select(".baseToolTipTri").attr(
        "transform",
        "translate(" +
          (offset + 84.5) +
          "," +
          (y0(d.value.valuesUnitsOut) - 11) +
          ")"
      );
      focus
        .selectAll(".bgUnitsRect")
        .attr(
          "transform",
          "translate(" +
            (offset + 12) +
            "," +
            (y0(d.value.valuesUnitsOut) - 119) +
            ")"
        );

      focus
        .select("circle.unitsOutY") // **********
        .attr("display", null)
        .attr(
          "transform",
          "translate(" +
            x(parseTime(d.key)) +
            "," +
            y0(d.value.valuesUnitsOut) +
            ")"
        );
      focus
        .select("text.y4")
        .attr(
          "transform",
          "translate(" +
            (offset + 75) +
            "," +
            (y0(d.value.valuesUnitsOut) - 110) +
            ")"
        )
        .text(d.key);
      focus
        .select("text.y4Label")
        .attr(
          "transform",
          "translate(" +
            (offset - 55) +
            "," +
            (y0(d.value.valuesUnitsOut) - 110) +
            ")"
        )
        .text("Period:");
      focus
        .select("text.y2")
        .attr(
          "transform",
          "translate(" +
            (offset + 75) +
            "," +
            (y0(d.value.valuesUnitsOut) - 95) +
            ")"
        )
        .text(formatNumber(d.value.baseUnit));
      focus
        .select("text.y2Label")
        .attr(
          "transform",
          "translate(" +
            (offset - 55) +
            "," +
            (y0(d.value.valuesUnitsOut) - 95) +
            ")"
        )
        .text("Base Unit:");
      focus
        .select("text.unitsOutY2")
        .attr(
          "transform",
          "translate(" +
            (offset + 75) +
            "," +
            (y0(d.value.valuesUnitsOut) - 80) +
            ")"
        )
        .text(formatNumber(d.value.valuesUnitsOut));
      focus
        .select("text.unitsOutY2Label")
        .attr(
          "transform",
          "translate(" +
            (offset - 55) +
            "," +
            (y0(d.value.valuesUnitsOut) - 80) +
            ")"
        )
        .text("Units Out:");
      focus
        .select("text.baseWAvgY2")
        .attr(
          "transform",
          "translate(" +
            (offset + 75) +
            "," +
            (y0(d.value.valuesUnitsOut) - 65) +
            ")"
        )
        .text(formatCurrency(avgD.value.edrpWtd));
      focus
        .select("text.baseWAvgY2Label")
        .attr(
          "transform",
          "translate(" +
            (offset - 55) +
            "," +
            (y0(d.value.valuesUnitsOut) - 65) +
            ")"
        )
        .text("EDRP:");
      focus
        .select("text.baseWArpY2")
        .attr(
          "transform",
          "translate(" +
            (offset + 75) +
            "," +
            (y0(d.value.valuesUnitsOut) - 50) +
            ")"
        )
        .text(formatCurrency(avgD.value.arpWtd));
      focus
        .select("text.baseWArpY2Label")
        .attr(
          "transform",
          "translate(" +
            (offset - 55) +
            "," +
            (y0(d.value.valuesUnitsOut) - 50) +
            ")"
        )
        .text("Avg Price:");
      focus
        .select("text.elasFit")
        .attr(
          "transform",
          "translate(" +
            (offset + 75) +
            "," +
            (y0(d.value.valuesUnitsOut) - 35) +
            ")"
        )
        .text(formatNumber(d.value.elasFitUnits));
      focus
        .select("text.elasFitLabel")
        .attr(
          "transform",
          "translate(" +
            (offset - 55) +
            "," +
            (y0(d.value.valuesUnitsOut) - 35) +
            ")"
        )
        .text("Elas Fit Units:");
      focus
        .select("#unitsOutCrossHairX")
        .attr(
          "transform",
          "translate(" +
            x(parseTime(d.key)) +
            "," +
            y0(d.value.valuesUnitsOut) +
            ")"
        )
        .attr("y2", height - y0(d.value.valuesUnitsOut))
        .style("stroke", valueUnitColor);

      change = x(parseTime(d.key));

      focus
        .select("#unitsOutCrossHairY")
        .attr(
          "transform",
          `translate(${change},${y0(d.value.valuesUnitsOut)} )`
        )
        .attr("x2", 0)
        .attr("x1", -x(parseTime(d.key)))
        .style("stroke", valueUnitColor);
    }
    d3.selectAll(".bgUnitsRect").raise();
    d3.selectAll(".bgValueUnitsRect").raise();
    d3.selectAll("text").raise();
    d3.selectAll(".baseUnitCircleHit").raise();
  }
  mousemoveAvg(event,d, focus) {
    let edrp = false;
    let change;
    if (event.target.className.baseVal.includes("edrpWtdCircleHit")) {
      edrp = true;
    }
    d3.select(".avgLines").raise();
    d3.select(".focusAvg").raise();
    var x0 = x.invert(d3.pointer(this.chartBox.node())[0]);
    var i = bisectDate(
      this.state.average,
      x0,
      1,
      this.state.average.length - 1
    );
    var baseD;
    var d0 = this.state.average[i - 1];
    var d1 = this.state.average[i];
    var baseD0 = this.state.baseUnitCount[i - 1];
    var baseD1 = this.state.baseUnitCount[i];
    if (baseD0 === undefined) {
      baseD = baseD1;
    } else if (baseD1 === undefined) {
      baseD = baseD0;
    } else {
      baseD =
        x0 - parseTime(baseD0.key) > parseTime(baseD1.key) - x0
          ? baseD1
          : baseD0;
    }
    if (d0 === undefined) {
      d = d1;
    } else if (d1 === undefined) {
      d = d0;
    } else {
      d = x0 - parseTime(d0.key) > parseTime(d1.key) - x0 ? d1 : d0;
    }
    var offset = x(parseTime(d.key)) - 85;
    if (edrp) {
      focus.select("#arpCrossHairY").attr("display", "none");
      focus.select("#arpCrossHairX").attr("display", "none");
      focus.select("#avgCrossHairX").attr("display", null);
      focus.select("#avgCrossHairY").attr("display", null);
      focus.select("circle.arpY").attr("display", "none");
      d3.select(".avgToolTipTri").attr(
        "transform",
        "translate(" + (offset + 84.5) + "," + (y1(d.value.edrpWtd) - 9) + ")"
      );

      focus
        .select("circle.avgY") // **********
        .attr(
          "transform",
          "translate(" + x(parseTime(d.key)) + "," + y1(d.value.edrpWtd) + ")"
        );
      focus
        .select("#avgCrossHairX")
        .attr(
          "transform",
          "translate(" + x(parseTime(d.key)) + "," + y1(d.value.edrpWtd) + ")"
        )
        .attr("y2", height - y1(d.value.edrpWtd));

      focus
        .selectAll(".bgAvgRect")
        .attr(
          "transform",
          "translate(" + (offset + 10) + "," + (y1(d.value.edrpWtd) - 115) + ")"
        );

      focus
        .select("text.avgY4")
        .attr(
          "transform",
          "translate(" + (offset + 75) + "," + (y1(d.value.edrpWtd) - 110) + ")"
        )
        .text(d.key);

      focus
        .select("text.avgY4Label")
        .attr(
          "transform",
          "translate(" + (offset - 55) + "," + (y1(d.value.edrpWtd) - 110) + ")"
        )
        .text("Period:");

      focus
        .select("text.avgBaseUnitY2")
        .attr(
          "transform",
          "translate(" + (offset + 75) + "," + (y1(d.value.edrpWtd) - 95) + ")"
        )
        .text(formatNumber(baseD.value.baseUnit));
      focus
        .select("text.avgBaseUnitY2Label")
        .attr(
          "transform",
          "translate(" + (offset - 55) + "," + (y1(d.value.edrpWtd) - 95) + ")"
        )
        .text("Base Unit:");

      focus
        .select("text.avgUnitsOutY2")
        .attr(
          "transform",
          "translate(" + (offset + 75) + "," + (y1(d.value.edrpWtd) - 80) + ")"
        )
        .text(formatNumber(baseD.value.valuesUnitsOut));
      focus
        .select("text.avgUnitsOutY2Label")
        .attr(
          "transform",
          "translate(" + (offset - 55) + "," + (y1(d.value.edrpWtd) - 80) + ")"
        )
        .text("Units Out:");

      focus
        .select("text.avgY2")
        .attr(
          "transform",
          "translate(" + (offset + 75) + "," + (y1(d.value.edrpWtd) - 65) + ")"
        )
        .text(formatCurrency(d.value.edrpWtd));
      focus
        .select("text.avgY2Label")
        .attr(
          "transform",
          "translate(" + (offset - 55) + "," + (y1(d.value.edrpWtd) - 65) + ")"
        )
        .text("EDRP:");
      focus
        .select("text.arpY2")
        .attr(
          "transform",
          "translate(" + (offset + 75) + "," + (y1(d.value.edrpWtd) - 50) + ")"
        )
        .text(formatCurrency(d.value.arpWtd));
      focus
        .select("text.arpY2Label")
        .attr(
          "transform",
          "translate(" + (offset - 55) + "," + (y1(d.value.edrpWtd) - 50) + ")"
        )
        .text("Avg Price:");
      focus
        .select("text.elasFit")
        .attr(
          "transform",
          "translate(" + (offset + 75) + "," + (y1(d.value.edrpWtd) - 35) + ")"
        )
        .text(formatNumber(baseD.value.elasFitUnits));
      focus
        .select("text.elasFitLabel")
        .attr(
          "transform",
          "translate(" + (offset - 55) + "," + (y1(d.value.edrpWtd) - 35) + ")"
        )
        .text("Elas Fit Units:");

      let change = x(parseTime(d.key));

      focus
        .select("#avgCrossHairY")
        .attr("transform", `translate(${change},${y1(d.value.edrpWtd)} )`)
        .attr("x2", 0)
        .attr("x1", width - x(parseTime(d.key)));
    } else {
      ////////
      focus.select("#arpCrossHairY").attr("display", null);
      focus.select("#arpCrossHairX").attr("display", null);
      focus.select("#avgCrossHairX").attr("display", "none");
      focus.select("#avgCrossHairY").attr("display", "none");
      focus.select("circle.avgY").attr("display", "none");
      d3.select(".avgToolTipTri").attr(
        "transform",
        "translate(" + (offset + 84.5) + "," + (y1(d.value.arpWtd) - 9) + ")"
      );
      focus
        .selectAll(".bgAvgRect")
        .attr(
          "transform",
          "translate(" + (offset + 10) + "," + (y1(d.value.arpWtd) - 115) + ")"
        );
      focus
        .select("circle.arpY") // **********
        .attr(
          "transform",
          "translate(" + x(parseTime(d.key)) + "," + y1(d.value.arpWtd) + ")"
        );

      focus
        .select("text.avgY4")
        .attr(
          "transform",
          "translate(" + (offset + 75) + "," + (y1(d.value.arpWtd) - 110) + ")"
        )
        .text(d.key);

      focus
        .select("text.avgY4Label")
        .attr(
          "transform",
          "translate(" + (offset - 55) + "," + (y1(d.value.arpWtd) - 110) + ")"
        )
        .text("Period:");

      focus
        .select("text.avgBaseUnitY2")
        .attr(
          "transform",
          "translate(" + (offset + 75) + "," + (y1(d.value.arpWtd) - 95) + ")"
        )
        .text(formatNumber(baseD.value.baseUnit));
      focus
        .select("text.avgBaseUnitY2Label")
        .attr(
          "transform",
          "translate(" + (offset - 55) + "," + (y1(d.value.arpWtd) - 95) + ")"
        )
        .text("Base Unit:");

      focus
        .select("text.avgUnitsOutY2")
        .attr(
          "transform",
          "translate(" + (offset + 75) + "," + (y1(d.value.arpWtd) - 80) + ")"
        )
        .text(formatNumber(baseD.value.valuesUnitsOut));
      focus
        .select("text.avgUnitsOutY2Label")
        .attr(
          "transform",
          "translate(" + (offset - 55) + "," + (y1(d.value.arpWtd) - 80) + ")"
        )
        .text("Units Out:");

      focus
        .select("text.avgY2")
        .attr(
          "transform",
          "translate(" + (offset + 75) + "," + (y1(d.value.arpWtd) - 65) + ")"
        )
        .text(formatCurrency(d.value.edrpWtd));
      focus
        .select("text.avgY2Label")
        .attr(
          "transform",
          "translate(" + (offset - 55) + "," + (y1(d.value.arpWtd) - 65) + ")"
        )
        .text("EDRP:");
      focus
        .select("text.arpY2")
        .attr(
          "transform",
          "translate(" + (offset + 75) + "," + (y1(d.value.arpWtd) - 50) + ")"
        )
        .text(formatCurrency(d.value.arpWtd));
      focus
        .select("text.arpY2Label")
        .attr(
          "transform",
          "translate(" + (offset - 55) + "," + (y1(d.value.arpWtd) - 50) + ")"
        )
        .text("Avg Price:");

      focus
        .select("text.elasFit")
        .attr(
          "transform",
          "translate(" + (offset + 75) + "," + (y1(d.value.arpWtd) - 35) + ")"
        )
        .text(formatNumber(baseD.value.elasFitUnits));
      focus
        .select("text.elasFitLabel")
        .attr(
          "transform",
          "translate(" + (offset - 55) + "," + (y1(d.value.arpWtd) - 35) + ")"
        )
        .text("Elas Fit Units:");
      // focus
      //   .select("text.arpY4")
      //   .attr("transform", "translate(" + offset + "," + y1(d.value.arpWtd) + ")")
      //   .text(d.key);

      focus
        .select("#arpCrossHairX")
        .attr(
          "transform",
          "translate(" + x(parseTime(d.key)) + "," + y1(d.value.arpWtd) + ")"
        )
        .attr("y2", height - y1(d.value.arpWtd));

      change = x(parseTime(d.key));

      focus
        .select("#arpCrossHairY")
        .attr("transform", `translate(${change},${y1(d.value.arpWtd)} )`)
        .attr("x2", 0)
        .attr("x1", width - x(parseTime(d.key)));
    }
    d3.selectAll(".bgArpRect").raise();
    d3.selectAll(".bgAvgRect").raise();
    d3.selectAll("text").raise();

    d3.selectAll(".edrpWtdCircleHit").raise();
  }

  drawBaseUnitHover(focus) {
    const { modelFitChecked } = this.state;
    focus
      .append("circle") // **********
      .attr("class", "y") // **********
      .style("fill", "none") // **********
      .style("stroke", baseUnitColor) // **********
      .attr("r", 4)
      .attr("transform", "translate(" + -10000 + "," + -10000 + ")");

    focus

      .append("path")
      .attr(
        "d",
        d3Shape
          .symbol()
          .type(d3Symbol.symbolTriangleDown)
          .size(50)
      )
      .attr("class", "baseToolTipTri")
      .attr("fill", "white")
      .style("stroke", "lightgray")
      .style("stroke-width", 1)
      .attr("transform", "translate(" + -10000 + "," + -10000 + ")");
    focus
      .append("line")
      .attr("class", "x")
      .style("stroke", baseUnitColor)
      .style("stroke-dasharray", "3,3")
      .style("opacity", 0.5)
      .attr("y1", 0)
      .attr("y2", height)
      .attr("transform", "translate(" + -10000 + "," + -10000 + ")");

    focus
      .append("line")
      .attr("class", "y")
      .attr("id", "crossHairY")
      .style("stroke", baseUnitColor)
      .style("stroke-dasharray", "3,3")
      .style("opacity", 0.5)
      .attr("x1", 0)
      .attr("x2", width)
      .attr("transform", "translate(" + -10000 + "," + -10000 + ")");

    focus
      .append("rect")
      .attr("rx", 2)
      .attr("ry", 2)
      .attr("class", "bgUnitsRect")
      .attr("fill", "white")
      .style("stroke", "lightgray")
      .style("stroke-width", 1)
      .attr("dx", 74)
      .attr("dy", "-.3em")
      .attr("height", 105)
      .attr("width", 145)
      .attr("transform", "translate(" + -10000 + "," + -10000 + ")");

    focus
      .append("text")
      .attr("class", "y2")
      .style("fill", baseUnitTextColor)
      .attr("height", 8)
      .attr("dx", 74)
      .attr("dy", "1em")
      .attr("font-size", tooltipFontSize)
      .attr("text-anchor", "end");
    focus
      .append("text")
      .attr("class", "y2Label")
      .style("fill", baseUnitTextColor)
      .attr("height", 8)
      .attr("dx", 74)
      .attr("dy", "1em")
      .attr("font-size", tooltipFontSize)
      .attr("text-anchor", "start");

    focus
      .append("text")
      .attr("class", "y4")
      .style("fill", baseUnitTextColor)
      .attr("dx", 74)
      .attr("dy", "1em")
      .attr("font-size", tooltipFontSize)
      .attr("text-anchor", "end");
    focus
      .append("text")
      .attr("class", "y4Label")
      .style("fill", baseUnitTextColor)
      .attr("dx", 74)
      .attr("dy", "1em")
      .attr("font-size", tooltipFontSize)
      .attr("text-anchor", "start");

    focus
      .append("circle") // **********
      .attr("class", "unitsOutY") // **********
      .style("fill", "none") // **********
      .style("stroke", valueUnitColor) // **********
      .attr("r", 4)
      .attr("transform", "translate(" + -10000 + "," + -10000 + ")");

    focus //vertical dash for Units Out
      .append("line")
      .attr("class", "x")
      .style("stroke", valueUnitColor)
      .style("stroke-dasharray", "3,3")
      .style("opacity", 0.5)
      .attr("y1", 0)
      .attr("y2", height)
      .attr("id", "unitsOutCrossHairX")
      .attr("transform", "translate(" + -10000 + "," + -10000 + ")");

    focus
      .append("line")
      .attr("class", "y")
      .attr("id", "unitsOutCrossHairY")
      .style("stroke", valueUnitColor)
      .style("stroke-dasharray", "3,3")
      .style("opacity", 0.5)
      .attr("x1", 0)
      .attr("x2", width)
      .attr("transform", "translate(" + -10000 + "," + -10000 + ")");

    focus
      .append("text")
      .attr("class", "unitsOutY2")
      .style("fill", valueUnitTextColor)
      .attr("dx", 74)
      .attr("dy", "1em")
      .attr("font-size", tooltipFontSize)
      .attr("text-anchor", "end");
    focus
      .append("text")
      .attr("class", "unitsOutY2Label")
      .style("fill", valueUnitTextColor)
      .attr("dx", 74)
      .attr("dy", "1em")
      .attr("font-size", tooltipFontSize)
      .attr("text-anchor", "start");
    focus
      .append("text")
      .attr("class", "baseWAvgY2")
      .style("fill", edrpWtdTextColor)
      .attr("dx", 74)
      .attr("dy", "1em")
      .attr("font-size", tooltipFontSize)
      .attr("text-anchor", "end");
    focus
      .append("text")
      .attr("class", "baseWAvgY2Label")
      .style("fill", edrpWtdTextColor)
      .attr("dx", 74)
      .attr("dy", "1em")
      .attr("font-size", tooltipFontSize)
      .attr("text-anchor", "start");
    focus
      .append("text")
      .attr("class", "baseWArpY2")
      .style("fill", adrpWtdTextColor)
      .attr("dx", 74)
      .attr("dy", "1em")
      .attr("font-size", tooltipFontSize)
      .attr("text-anchor", "end");
    focus
      .append("text")
      .attr("class", "baseWArpY2Label")
      .style("fill", adrpWtdTextColor)
      .attr("dx", 74)
      .attr("dy", "1em")
      .attr("font-size", tooltipFontSize)
      .attr("text-anchor", "start");
    if (modelFitChecked) {
      focus
        .append("text")
        .attr("class", "elasFitLabel")
        .style("fill", elasFitUnitsTextColor)
        .attr("dx", 74)
        .attr("dy", "1em")
        .attr("font-size", tooltipFontSize)
        .attr("text-anchor", "start");
      focus
        .append("text")
        .attr("class", "elasFit")
        .style("fill", elasFitUnitsTextColor)
        .attr("dx", 74)
        .attr("dy", "1em")
        .attr("font-size", tooltipFontSize)
        .attr("text-anchor", "end");
    }

    d3.selectAll(".circle").raise();
  }
  drawEdrpWrtdHover(focus) {
    const { modelFitChecked } = this.state;
    focus
      .append("circle") // **********
      .attr("class", "avgY") // **********
      .style("fill", "none") // **********
      .style("stroke", edrpWtdColor) // **********
      .attr("r", 4)
      .attr("transform", "translate(" + -10000 + "," + -10000 + ")");
    focus

      .append("path")
      .attr(
        "d",
        d3Shape
          .symbol()
          .type(d3Symbol.symbolTriangleDown)
          .size(50)
      )
      .attr("class", "avgToolTipTri")
      .attr("fill", "white")
      .style("stroke", "lightgray")
      .style("stroke-width", 1)
      .attr("transform", "translate(" + -10000 + "," + -10000 + ")");
    focus
      .append("rect")
      .attr("rx", 2)
      .attr("ry", 2)
      .attr("class", "bgAvgRect")
      .attr("fill", "white")
      .style("stroke", "lightgray")
      .style("stroke-width", 1)
      .attr("dx", 74)
      .attr("dy", "-.3em")
      .attr("height", 105)
      .attr("width", 145)
      .attr("transform", "translate(" + -1000 + "," + -10 + ")");
    focus
      .append("line")
      .attr("class", "avgX")
      .attr("id", "avgCrossHairX")
      .style("stroke", edrpWtdColor)
      .style("stroke-dasharray", "3,3")
      .style("opacity", 0.5)
      .attr("y1", 0)
      .attr("y2", height)
      .attr("transform", "translate(" + -10000 + "," + -10000 + ")");

    focus
      .append("line")
      .attr("class", "avgY")
      .attr("id", "avgCrossHairY")
      .style("stroke", edrpWtdColor)
      .style("stroke-dasharray", "3,3")
      .style("opacity", 0.5)
      .attr("x1", 0)
      .attr("x2", width)
      .attr("transform", "translate(" + -10000 + "," + -10000 + ")");
    //EDRP
    focus
      .append("text")
      .attr("class", "avgY2")
      .style("fill", edrpWtdTextColor)
      .attr("dx", 74)
      .attr("dy", "1em")
      .attr("font-size", tooltipFontSize)
      .attr("text-anchor", "end");
    focus
      .append("text")
      .attr("class", "avgY2Label")
      .style("fill", edrpWtdTextColor)
      .attr("dx", 74)
      .attr("dy", "1em")
      .attr("font-size", tooltipFontSize)
      .attr("text-anchor", "start");
    //BASE UNITS
    focus
      .append("text")
      .attr("class", "avgBaseUnitY2")
      .style("fill", baseUnitTextColor)
      .attr("height", 8)
      .attr("dx", 74)
      .attr("dy", "1em")
      .attr("font-size", tooltipFontSize)
      .attr("text-anchor", "end");
    focus
      .append("text")
      .attr("class", "avgBaseUnitY2Label")
      .style("fill", baseUnitTextColor)
      .attr("dx", 74)
      .attr("dy", "1em")
      .attr("font-size", tooltipFontSize)
      .attr("text-anchor", "start");
    //Units Out
    focus
      .append("text")
      .attr("class", "avgUnitsOutY2")
      .style("fill", valueUnitTextColor)
      .attr("dx", 74)
      .attr("dy", "1em")
      .attr("font-size", tooltipFontSize)
      .attr("text-anchor", "end");
    focus
      .append("text")
      .attr("class", "avgUnitsOutY2Label")
      .style("fill", valueUnitTextColor)
      .attr("dx", 74)
      .attr("dy", "1em")
      .attr("font-size", tooltipFontSize)
      .attr("text-anchor", "start");
    //PERIOD
    focus
      .append("text")
      .attr("class", "avgY4")
      .style("fill", edrpWtdTextColor)
      .attr("dx", 74)
      .attr("dy", "1em")
      .attr("font-size", tooltipFontSize)
      .attr("text-anchor", "end");
    //PERIOD LABEL
    focus
      .append("text")
      .attr("class", "avgY4Label")
      .style("fill", edrpWtdTextColor)
      .attr("dx", 74)
      .attr("dy", "1em")
      .attr("font-size", tooltipFontSize)
      .attr("text-anchor", "start");
    //ADRP
    focus
      .append("text")
      .attr("class", "arpY2")
      .style("fill", adrpWtdTextColor)
      .attr("dx", 74)
      .attr("dy", "1em")
      .attr("font-size", tooltipFontSize)
      .attr("text-anchor", "end");
    focus
      .append("text")
      .attr("class", "arpY2Label")
      .style("fill", adrpWtdTextColor)
      .attr("dx", 74)
      .attr("dy", "1em")
      .attr("font-size", tooltipFontSize)
      .attr("text-anchor", "start");
    if (modelFitChecked) {
      focus
        .append("text")
        .attr("class", "elasFitLabel")
        .style("fill", elasFitUnitsTextColor)
        .attr("dx", 74)
        .attr("dy", "1em")
        .attr("font-size", tooltipFontSize)
        .attr("text-anchor", "start");
      focus
        .append("text")
        .attr("class", "elasFit")
        .style("fill", elasFitUnitsTextColor)
        .attr("dx", 74)
        .attr("dy", "1em")
        .attr("font-size", tooltipFontSize)
        .attr("text-anchor", "end");
    }
    /////////

    focus
      .append("circle") // **********
      .attr("class", "arpY") // **********
      .style("fill", "none") // **********
      .style("stroke", adrpWtdColor) // **********
      .attr("r", 4)
      .attr("transform", "translate(" + -10000 + "," + -10000 + ")");

    focus
      .append("line")
      .attr("class", "arpX")
      .attr("id", "arpCrossHairX")
      .style("stroke", adrpWtdColor)
      .style("stroke-dasharray", "3,3")
      .style("opacity", 0.5)
      .attr("y1", 0)
      .attr("y2", height)
      .attr("transform", "translate(" + -10000 + "," + -10000 + ")");

    focus
      .append("line")
      .attr("class", "arpY")
      .attr("id", "arpCrossHairY")
      .style("stroke", adrpWtdColor)
      .style("stroke-dasharray", "3,3")
      .style("opacity", 0.5)
      .attr("x1", 0)
      .attr("x2", width)
      .attr("transform", "translate(" + -10000 + "," + -10000 + ")");

    d3.selectAll(".circle").raise();
  }
  setupHover() {
    var focusUnits = this.unitsLines
      .append("g") // **********
      .attr("class", "focusUnits")
      .style("display", "none");

    var focusAvg = this.avgLines
      .append("g") // **********
      .attr("class", "focusAvg")
      .style("display", "none");

    d3.selectAll(".unitsLines")
      .on("mouseover", () => {
        let test = d3.select(".activeCirc");

        if (test._groups[0][0] === null) {
          focusUnits.style("display", null);
          focusAvg.style("display", "none");
        } else {
          focusAvg.style("display", "none");
          focusUnits.style("display", "none");
        }
      })
      .on("mouseout", () => {
        focusUnits.style("display", "none");
        focusAvg.style("display", "none");
      })
      .on("mousemove", (event,d) => {
        this.mousemoveUnits(event,d, focusUnits);
        
      });

    d3.select(".avgLines")
      .on("mouseover", () => {
        let test = d3.select(".activeCirc");

        if (test._groups[0][0] === null) {
          focusAvg.style("display", null);
          focusUnits.style("display", "none");
        } else {
          focusAvg.style("display", "none");
          focusUnits.style("display", "none");
        }
      })
      .on("mouseout", () => {
        focusAvg.style("display", "none");
        focusUnits.style("display", "none");
      })
      .on("mousemove", (event,d) => {
        
        this.mousemoveAvg(event,d, focusAvg);
      });
    this.drawBaseUnitHover(focusUnits);
    this.drawEdrpWrtdHover(focusAvg);
  }
  setupCircles(data, valueName, parent, className, setClickHandler = false) {
    let newG = parent
      .selectAll(".circle")
      .data(data)
      .enter()
      .append("circle")
      .style("cursor", "pointer")
      .attr("class", className)
      .attr("r", 2.0)
      .attr("cx", function(d) {
        if (isNaN(x(parseTime(d.key)))) {
          return 0;
        } else {
          return x(parseTime(d.key));
        }
      });
    if (valueName === "edrpWtd" || valueName === "arpWtd") {
      newG.attr("cy", function(d) {
        return y1(d.value[valueName]);
      });
    } else {
      newG.attr("cy", function(d) {
        return y0(d.value[valueName]);
      });
    }
    if (setClickHandler) {
      let clickG = parent
        .selectAll(".focusCircle")
        .data(data)
        .enter()
        .append("circle")
        .style("cursor", "pointer")
        .attr("class", className + "Hit")
        .attr("r", 4.0)
        .raise()
        .attr("cx", function(d) {
          if (isNaN(x(parseTime(d.key)))) {
            return 0;
          } else {
            return x(parseTime(d.key));
          }
        });

      if (valueName === "edrpWtd") {
        clickG.attr("cy", function(d) {
          return y1(d.value[valueName]);
        });
        clickG.on("click", (event,data, index, nodes) => {
          this.avgClick(event,data, index, nodes);
        });
        d3.selectAll("." + className + "Hit").raise();
      } else {
        clickG.attr("cy", function(d) {
          return y0(d.value[valueName]);
        });
        clickG.on("click", (event,data, index, nodes) => {
          this.baseLineClick(event,data, index, nodes);
        });
      }
    }

    return newG;
  }
  dragstarted(d, index, node, name) {
    //("drag started");
    d3.selectAll(".focusUnits").style("display", "none");
    d3.selectAll(".focusAvg").style("display", "none");
    const { activeNodeIndex, baseUnitActive, edrpWtdActive } = this.state;
    if (name === "baseUnit" && baseUnitActive) {
      if (activeNodeIndex !== index && activeNodeIndex !== -1) {
        let newY = Math.round(y0.invert(node[index].cy.baseVal.value));

        this.setState({ oldDataValue: newY });
        let circle = d3.selectAll(".baseUnitCircle");

        d3.select(circle._groups[0][index])
          .raise()
          .classed("active", true)
          .classed("activeCirc", true);
      }
    } else if (name === "edrpWtd" && edrpWtdActive) {
      if (activeNodeIndex !== index && activeNodeIndex !== -1) {
        let newY = Math.round(y1.invert(node[index].cy.baseVal.value));

        this.setState({ oldDataValue: newY });
        let circle = d3.selectAll(".edrpWtdCircle");

        d3.select(circle._groups[0][index])
          .raise()
          .classed("active", true)
          .classed("activeCirc", true);
      }
    }
  }
  dragged(event,d, index, node, name) {
    
    d3.selectAll(".focusUnits").style("display", "none");
    d3.selectAll(".focusAvg").style("display", "none");
    const { activeNodeIndex, baseUnitActive, edrpWtdActive } = this.state;
    if (name === "baseUnit" && baseUnitActive) {
      if (activeNodeIndex !== -1 && activeNodeIndex !== index) {
        let newY = y0.invert(event.y);

       
        let hitCircle = d3.selectAll(".baseUnitCircleHit");
        d3.select(".activeCirc")
          .attr("cx", x(parseTime(d.key)))
          .attr("cy", Math.round(y0(newY)));
        d3.select(hitCircle._groups[0][index])
          .attr("cx", x(parseTime(d.key)))
          .attr("cy", Math.round(y0(newY)));
      }
    } else if (name === "edrpWtd" && edrpWtdActive) {
      if (activeNodeIndex !== -1 && activeNodeIndex !== index) {
        let newY = y1.invert(event.y);

      
        let hitCircle = d3.selectAll(".edrpWtdCircleHit");
        d3.select(".activeCirc")
          .attr("cx", x(parseTime(d.key)))
          .attr("cy", Math.round(y1(newY)));
        d3.select(hitCircle._groups[0][index])
          .attr("cx", x(parseTime(d.key)))
          .attr("cy", Math.round(y1(newY)));
      }
    }
    //this.baseUnit.select("path").attr("d", valueline(this.state.baseUnitCount));
  }
  dragend(d, index, node, name) {
    //console.log("drag ended");
    const {
      //activeData,
      activeNodeIndex,
      baseUnitCount,
      average
    } = this.state;
    var changed = [];
    var changedObj = {};
    //var tStart = performance.now();
    if (activeNodeIndex !== -1 && activeNodeIndex !== index) {
      let target = d3.select(".activeCirc");
      //let newX = x.invert(d3.event.x);
      let newY, circle, hitCircle, change;
      if (name === "baseUnit") {
        newY = Math.round(y0.invert(target.attr("cy")));
        circle = d3.selectAll(".baseUnitCircle");
        hitCircle = d3.selectAll(".baseUnitCircleHit");
        change = newY - baseUnitCount[activeNodeIndex].value.baseUnit;
      } else {
        newY = y1.invert(target.attr("cy")).toFixed(2);
        circle = d3.selectAll(".edrpWtdCircle");
        hitCircle = d3.selectAll(".edrpWtdCircleHit");

        change = newY - average[activeNodeIndex].value.edrpWtd;
      }

      let numDataPoints = Math.abs(activeNodeIndex - index);

      let decrease = false;
      if (Math.sign(change) === -1) {
        decrease = true;
      }
      let modification = Math.abs(change) / numDataPoints;

      let prevValue = modification;
      let newValue = -1;
      let _this = this;
      //var t0 = performance.now();
      //console.log("Beginning took " + (t0 - tStart) + " milliseconds.");
      if (activeNodeIndex > index) {
        for (let x = activeNodeIndex - 1; x > index; x--) {
          if (decrease) {
            // if (x === index) {
            //   d3.select(".activeCirc").attr(
            //     "cy",
            //     y0(baseUnitCount[x].value.baseUnit - modification)
            //   );
            // }
            if (name === "baseUnit") {
              newValue = Math.round(
                baseUnitCount[x + 1].value.baseUnit - prevValue
              );
            } else {
              newValue = (
                parseFloat(average[x + 1].value.edrpWtd) - prevValue
              ).toFixed(2);
            }
          } else {
            if (name === "baseUnit") {
              newValue = Math.round(
                baseUnitCount[x + 1].value.baseUnit + prevValue
              );
            } else {
              newValue = (
                parseFloat(average[x + 1].value.edrpWtd) + prevValue
              ).toFixed(2);
            }
          }
          if (name === "baseUnit") {
            baseUnitCount[x].value.baseUnit = newValue;
            _this.props.chartData[x].BASE_UNITS = newValue;
            changedObj = _this.props.chartData[x];

            changed.push(changedObj);
            d3.select(circle._groups[0][x]).attr("cy", y0(newValue));
            d3.select(hitCircle._groups[0][x]).attr("cy", y0(newValue));
          } else {
            average[x].value.edrpWtd = newValue;
            _this.props.chartData[x].BASE_EDRP = newValue;
            changedObj = _this.props.chartData[x];

            changed.push(changedObj);
            d3.select(circle._groups[0][x]).attr("cy", y1(newValue));
            d3.select(hitCircle._groups[0][x]).attr("cy", y1(newValue));
          }
          //prevValue = prevValue + modification;
        }
      } else {
        for (let x = activeNodeIndex + 1; x < index; x++) {
          if (decrease) {
            // if (x === index) {
            //   d3.select(".activeCirc").attr(
            //     "cy",
            //     y0(baseUnitCount[x].value.baseUnit - modification)
            //   );
            // }
            if (name === "baseUnit") {
              newValue = Math.round(
                baseUnitCount[x - 1].value.baseUnit - prevValue
              );
            } else {
              newValue = (
                parseFloat(average[x - 1].value.edrpWtd) - prevValue
              ).toFixed(2);
            }
          } else {
            if (name === "baseUnit") {
              newValue = Math.round(
                baseUnitCount[x - 1].value.baseUnit + prevValue
              );
            } else {
              newValue = (
                parseFloat(average[x - 1].value.edrpWtd) + prevValue
              ).toFixed(2);
            }
          }
          if (name === "baseUnit") {
            baseUnitCount[x].value.baseUnit = newValue;
            _this.props.chartData[x].BASE_UNITS = newValue;
            changedObj = _this.props.chartData[x];

            changed.push(changedObj);
            d3.select(circle._groups[0][x]).attr("cy", y0(newValue));
            d3.select(hitCircle._groups[0][x]).attr("cy", y0(newValue));
          } else {
            average[x].value.edrpWtd = newValue;
            _this.props.chartData[x].BASE_EDRP = newValue;
            changedObj = _this.props.chartData[x];

            changed.push(changedObj);
            d3.select(circle._groups[0][x]).attr("cy", y1(newValue));
            d3.select(hitCircle._groups[0][x]).attr("cy", y1(newValue));
          }
          //prevValue = prevValue + modification;
        }
      }
      //var t1 = performance.now();
      //console.log("Chart Edit For Loop took " + (t1 - t0) + " milliseconds.");
      //var t3 = performance.now();
      if (name === "baseUnit") {
        baseUnitCount[index].value.baseUnit = newY;
        this.props.chartData[index].BASE_UNITS = newY;
        changedObj = _this.props.chartData[index];

        changed.push(changedObj);
        this.setState({
          baseUnitCount: baseUnitCount,
          oldDataValue: -1
          // activeData: null,
          // activeNodeIndex: -1,
          //activeNode: null
        });
        //d3.selectAll(".selectedCircle").remove();
        d3.selectAll(".baseUnitCircle").remove();
        this.setupCircles(
          this.state.baseUnitCount,
          "baseUnit",
          this.unitsLines,
          "baseUnitCircle",
          false
        );
      } else {
        average[index].value.edrpWtd = newY;
        this.props.chartData[index].BASE_EDRP = newY;
        changedObj = this.props.chartData[index];

        changed.push(changedObj);
        this.setState({
          average: average,
          oldDataValue: -1
          // activeData: null,
          // activeNodeIndex: -1,
          //activeNode: null
        });
        //d3.selectAll(".selectedCircle").remove();
        d3.selectAll(".edrpWtdCircle").remove();
        this.setupCircles(
          this.state.average,
          "edrpWtd",
          this.avgLines,
          "edrpWtdCircle",
          false
        );
      }
      //var t4 = performance.now();
      // console.log(
      //   "Chart Edit Updating State took " + (t4 - t3) + " milliseconds."
      // );

      this.props.setAccubaseData(
        this.props.data,
        this.props.chartData,
        this.props.config,
        changed
      );
      //var t41 = performance.now();
      //console.log("Set AccubaseData took " + (t41 - t4) + " milliseconds.");
      //let change =
      // let newBaseUnit = this.state.baseUnitCount.map(item => {
      //   if (item.value.id === newD.value.id) {
      //     item.value.baseUnit = newY;
      //   }
      //   return item;
      // });

      d3.selectAll(".focusUnits").style("display", "none");
      d3.selectAll(".focusAvg").style("display", "none");
      this.setState({
        baseUnitCount: baseUnitCount,
        oldDataValue: -1
        // activeData: null,
        // activeNodeIndex: -1,
        //activeNode: null
      });
      //d3.selectAll(".selectedCircle").remove();
      d3.selectAll(".baseUnitCircle").remove();

      //var t5 = performance.now();
      //console.log("Middle Part took " + (t5 - t4) + " milliseconds.");

      this.setupCircles(
        this.state.baseUnitCount,
        "baseUnit",
        this.unitsLines,
        "baseUnitCircle",
        false
      );
      //var t6 = performance.now();
      // console.log(
      //   "Chart Edit Setting up circles " + (t6 - t5) + " milliseconds."
      // );

      target.classed("active", false).classed("activeCirc", false);
      //var t6P = performance.now();

      this.props.setChangedData(changed);
      //var t7 = performance.now();
      // console.log("Set Changed Data " + (t7 - t6P) + " milliseconds.");
      // console.log("Total Time " + (t7 - tStart) + " milliseconds.");
    }
  }
  baseLineClick(event,data, index, nodes) {
    event.stopPropagation();
    event.preventDefault();

    const {
      activeNodeIndex,
      masterItems,
      geo2Items,
      baseUnitActive
    } = this.state;
    if (masterItems.length === 1 && geo2Items.length === 1) {
      if (!baseUnitActive) {
        d3.select(".unitsLines").raise();
        d3.selectAll(".baseUnitCircleHit").raise();
        d3.selectAll(".selectedCircle").remove();
        this.chartBox
          .append("circle") // **********
          .attr("cx", nodes[index].cx.baseVal.value)
          .attr("cy", nodes[index].cy.baseVal.value)
          .attr("class", "selectedCircle") // **********
          .style("fill", "none") // **********
          .style("stroke", valueUnitColor) // **********
          .attr("r", 4);
        var that = this;
        let drag = d3
          .drag()

          .on("start", (d, i, n) => this.dragstarted(d, i, n, "baseUnit"))
          .on("drag", function(event,d, i, n) {
            that.dragged(event,d, i, n, "baseUnit");
          })
          .on("end", (d, i, n) => this.dragend(d, i, n, "baseUnit"));
        d3.selectAll(".focusUnits").style("display", "none");
        d3.selectAll(".focusAvg").style("display", "none");
        this.svg.selectAll(".baseUnitCircleHit").call(drag);

        this.setState({
          activeNode: nodes[index],
          activeData: data,
          activeNodeIndex: index,
          baseUnitActive: true
        });
      } else if (activeNodeIndex === index) {
        d3.selectAll(".selectedCircle").remove();
        this.setState({
          activeNode: null,
          activeData: null,
          activeNodeIndex: -1,
          baseUnitActive: false
        });
        d3.selectAll(".focusUnits").style("display", "none");
        d3.selectAll(".focusAvg").style("display", "none");
      }
    }
  }
  avgClick(event,data, index, nodes) {
    event.stopPropagation();
    event.preventDefault();

    const {
      activeNodeIndex,
      masterItems,
      geo2Items,
      edrpWtdActive
    } = this.state;

    if (masterItems.length === 1 && geo2Items.length === 1) {
      if (!edrpWtdActive) {
        d3.select(".avgLines").raise();
        d3.selectAll(".edrpWtdCircleHit").raise();
        d3.selectAll(".selectedCircle").remove();
        this.chartBox
          .append("circle") // **********
          .attr("cx", nodes[index].cx.baseVal.value)
          .attr("cy", nodes[index].cy.baseVal.value)
          .attr("class", "selectedCircle") // **********
          .style("fill", "none") // **********
          .style("stroke", valueUnitColor) // **********
          .attr("r", 4);
        var that = this;
        let dragEdrpWtd = d3
          .drag()

          .on("start", (d, i, n) => this.dragstarted(d, i, n, "edrpWtd"))
          .on("drag", function(event,d, i, n) {
            that.dragged(event,d, i, n, "edrpWtd");
          })
          .on("end", (d, i, n) => this.dragend(d, i, n, "edrpWtd"));
        this.svg.selectAll(".edrpWtdCircleHit").call(dragEdrpWtd);
        d3.selectAll(".focusUnits").style("display", "none");
        d3.selectAll(".focusAvg").style("display", "none");

        this.setState({
          activeNode: nodes[index],
          activeData: data,
          activeNodeIndex: index,
          edrpWtdActive: true,
          baseUnitActive: false
        });
      } else if (activeNodeIndex === index) {
        d3.selectAll(".selectedCircle").remove();
        this.setState({
          activeNode: null,
          activeData: null,
          activeNodeIndex: -1,
          edrpWtdActive: false
        });
        d3.selectAll(".focusUnits").style("display", "none");
        d3.selectAll(".focusAvg").style("display", "none");
      }
    }
  }
  drawAxis() {
    const { circlesSetup, domainY1, domainY0, modelFitChecked } = this.state;

    this.xAxis.call(
      axisBottom()
        .scale(x)
        .ticks(d3.timeWeek.every(8))
        .tickFormat((d, i) => {
          return d3.timeFormat("%m/%d/%y")(d);
        })
    );

    this.yAxis.call(
      axisLeft()
        .scale(y0)
        .ticks(8)
        .tickFormat(d => {
          return d3.format(".2s")(d);
        })
    );
    this.yAxis2.call(
      axisRight()
        .scale(y1)
        .ticks(5)
    );
    var ticks = d3.selectAll(".tick text");

    ticks.attr("font-size", "0.9em");

    var runInit = false;

    if (y1.domain()[0] === domainY1[0] && y1.domain()[1] === domainY1[1]) {
    } else {
      runInit = true;
    }
    if (y0.domain()[0] === domainY0[0] && y0.domain()[1] === domainY0[1]) {
    } else {
      runInit = true;
    }
    this.svg.selectAll(".focusUnits").remove();
    this.svg.selectAll(".focusAvg").remove();
    this.setupHover();

    if (modelFitChecked) {
      this.setupCircles(
        this.state.baseUnitCount,
        "elasFitUnits",
        this.unitsLines,
        "elasFitUnitsCircle"
      );
      this.svg.selectAll(".baseUnitCircle").raise();
    } else {
      this.svg.selectAll(".elasFitUnitsCircle").remove();
    }
    if (!circlesSetup || this.props.reInitChart || runInit) {
      this.svg.selectAll(".edrpWtdCircle").remove();
      this.svg.selectAll(".arpWtdCircle").remove();
      this.svg.selectAll(".baseUnitCircle").remove();
      this.svg.selectAll(".elasFitUnitsCircle").remove();
      this.svg.selectAll(".valueUnitsCircle").remove();
      this.svg.selectAll(".baseUnitCircleHit").remove();
      this.svg.selectAll(".edrpWtdCircleHit").remove();
      if (runInit) {
        d3.selectAll(".selectedCircle").remove();
        this.setState({
          activeNode: null,
          activeData: null,
          activeNodeIndex: -1,
          edrpWtdActive: false,
          baseUnitActive: false
        });
        //d3.selectAll("circle.selectedCircle").attr("cy", 100);
      }
      this.setupCircles(
        this.state.baseUnitCount,
        "baseUnit",
        this.unitsLines,
        "baseUnitCircle",
        true
      );
      this.setupCircles(
        this.state.baseUnitCount,
        "valuesUnitsOut",
        this.unitsLines,
        "valueUnitsCircle"
      );

      this.setupCircles(
        this.state.average,
        "arpWtd",
        this.avgLines,
        "arpWtdCircle"
      );
      this.setupCircles(
        this.state.average,
        "edrpWtd",
        this.avgLines,
        "edrpWtdCircle",
        true
      );
      this.setState({
        circlesSetup: true,
        domainY1: y1.domain(),
        domainY0: y0.domain()
      });
    }
    d3.selectAll(".edrpWtdCircleHit").raise();
  }

  modelFitClick(state) {
    this.setState({ modelFitChecked: state });
  }
  render() {
    const { modelFitChecked } = this.state;
    if (this.props.chartData.length > 0) {
      x.domain(
        d3.extent(this.props.chartData, function(d) {
          return parseTime(d.WEEK_DATE);
        })
      );

      y0.domain([
        // d3.min(this.state.baseUnitCount, function(d) {
        //   return Math.min(d.value.valuesUnitsOut, d.value.baseUnit);
        // }),
        0,
        d3.max(this.state.baseUnitCount, function(d) {
          return Math.max(d.value.valuesUnitsOut, d.value.baseUnit) + 1000;
        })
      ]);
      y1.domain([
        // d3.min(this.state.average, function(d) {
        //   return Math.min(d.value.edrpWtd, d.value.arpWtd) - 100;
        // }),
        0,
        d3.max(this.state.average, function(d) {
          return Math.max(d.value.edrpWtd, d.value.arpWtd);
        })
      ]);
    } else {
      x.domain(0, 100);

      y0.domain([0, 100]);
      y1.domain([0, 100]);
    }

    return (
      <div className="chart review-bl-chart" style={{ overflowY: "visible" }}>
        <svg
          height="100%"
          width="100%"
          style={{ overflow: "visible" }}
          ref={r => {
            this.svg = select(r);
          }}
          viewBox={`0 0 ${fullWidth} ${fullHeight}`}
          id="chartObj"
        >
          {/* <g
            className="tooltip"
            style={{}}
            ref={r => (this.tooltip = select(r))}
          ></g> */}
          <g
            ref={r => {
              this.chartBox = select(r);
            }}
            className="chartBox"
            transform={`translate(${margin.left + 10},${margin.top})`}
          >
            {/* <Legend
              colors={props.colors}
              colorAccessor={props.colorAccessor}
              data={this.state.average}
              legendPosition={props.legendPosition}
              margins={props.margins}
              width={props.sideOffset}
            /> */}
            <g transform={"translate(200,250)"}>
              <g className="legend" transform={"translate(0, 0)"}>
                <rect
                  width={legendRectSize}
                  height={legendRectSize}
                  className="baseUnit"
                ></rect>
                <text
                  x={legendRectSize + legendSpacing + 2}
                  y={legendRectSize - legendSpacing + 4}
                  className="legend-labels"
                  fontSize="0.9em"
                >
                  Base Unit
                </text>
              </g>
              <g className="legend" transform={"translate(70, 0)"}>
                <rect
                  width={legendRectSize}
                  height={legendRectSize}
                  className="valueUnits"
                ></rect>
                <text
                  x={legendRectSize + legendSpacing + 2}
                  y={legendRectSize - legendSpacing + 4}
                  className="legend-labels"
                  fontSize="0.9em"
                >
                  Units Out
                </text>
              </g>
              <g className="legend" transform={"translate(140, 0)"}>
                {modelFitChecked ? (
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    x="-2"
                    y="-1"
                    width="7"
                    height="7"
                    viewBox="0 0 24 24"
                    ref={ref => (this.uncheckModelFit = ref)}
                    transform={"translate(10,0)"}
                    style={{
                      background: "white",
                      pointerEvents: "bounding-box",
                      fill: "#F4B92F"
                    }}
                    onClick={() => {
                      this.modelFitClick(false);
                    }}
                  >
                    <path d="M9.87,19.23l-7.12-7.54l1.99-2.04l5.09,5.32L19.18,5l2.07,2L9.87,19.23z M24,0H0v24h24V0z" />
                  </svg>
                ) : (
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    x="-2"
                    y="-1"
                    width="7"
                    height="7"
                    viewBox="0 0 24 24"
                    ref={ref => (this.checkModelFit = ref)}
                    style={{ pointerEvents: "bounding-box" }}
                    onClick={() => {
                      this.modelFitClick(true);
                    }}
                  >
                    <path d="M22 2v20h-20v-20h20zm2-2h-24v24h24v-24z" />
                  </svg>
                )}
                <text
                  x={legendRectSize + legendSpacing + 2}
                  y={legendRectSize - legendSpacing + 4}
                  className="legend-labels"
                >
                  Model Fit
                </text>
              </g>
              <g className="legend" transform={"translate(210, 0)"}>
                <rect
                  width={legendRectSize}
                  height={legendRectSize}
                  className="edrpWtd"
                ></rect>
                <text
                  x={legendRectSize + legendSpacing + 2}
                  y={legendRectSize - legendSpacing + 4}
                  className="legend-labels"
                  fontSize="0.9em"
                >
                  EDRP Wtd
                </text>
              </g>

              <g className="legend" transform={"translate(285, 0)"}>
                <rect
                  width={legendRectSize}
                  height={legendRectSize}
                  className="arpWtd"
                  fontSize="0.9em"
                ></rect>
                <text
                  x={legendRectSize + legendSpacing + 2}
                  y={legendRectSize - legendSpacing + 4}
                  className="legend-labels"
                >
                  ARP Wtd
                </text>
              </g>
            </g>
            <g
              className="axis"
              ref={r => (this.xAxis = select(r))}
              style={{ textAnchor: "middle" }}
              transform={`translate(0, ${height})`}
            ></g>
            <g
              className="axis axisSteelBlue"
              ref={r => (this.yAxis = select(r))}
            >
              <text
                fill={"#000"}
                // transform={"rotate(-90)"}
                transform={`translate(${15},${-25}) `}
                y={6}
                dy={"0.71em"}
                textAnchor={"end"}
              >
                Sum (Units)
              </text>
            </g>
            <g className="unitsLines" ref={r => (this.unitsLines = select(r))}>
              <path
                className="line units baseUnit"
                style={{ fill: "none", strokeWidth: "1px" }}
                ref={r => (this.baseUnit = select(r))}
                d={valueline(this.state.baseUnitCount)}
              ></path>
              <path
                className="line units valueUnits"
                style={{ fill: "none", strokeWidth: "1px" }}
                ref={r => (this.valueUnits = select(r))}
                d={valueLineValueUnits(this.state.baseUnitCount)}
              ></path>
              {modelFitChecked ? (
                <path
                  className="line units elasFitUnits"
                  style={{ fill: "none", strokeWidth: "1px" }}
                  ref={r => (this.elasFitUnits = select(r))}
                  d={valueElasFitUnits(this.state.baseUnitCount)}
                ></path>
              ) : null}
            </g>
            <g className="avgLines" ref={r => (this.avgLines = select(r))}>
              <path
                style={{
                  fill: "none",
                  strokeWidth: "1px"
                }}
                ref={r => (this.arpWtd = select(r))}
                className="line avgs arpWtd"
                d={valuelineArpWtd(this.state.average)}
              ></path>
              <path
                style={{
                  fill: "none",
                  strokeWidth: "1px"
                }}
                ref={r => (this.edrpWtd = select(r))}
                className="line avgs edrpWtd"
                d={valueline2(this.state.average)}
              ></path>
            </g>
            <g
              className="axis axisRed"
              transform={`translate(${width})`}
              style={{ textAnchor: "left" }}
              ref={r => (this.yAxis2 = select(r))}
            >
              {" "}
              <text
                fill={"#000"}
                transform-origin={"left"}
                // transform={`rotate(-90) translate(${150},${130}) `}
                transform={`translate(${30},${-25}) `}
                y={6}
                dy={"0.71em"}
                textAnchor={"end"}
              >
                Avg (Price)
              </text>
            </g>
          </g>
        </svg>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  loading: state.accubase.loading,
  loaded: state.accubase.loaded,
  reInitChart: state.accubase.reInitChart,
  error: state.accubase.error,
  config: state.accubase.config,
  chartData: state.accubase.chartData,
  data: state.accubase.data
});
const mapDispatchToProps = dispatch => ({
  setReinitChart(state) {
    dispatch(setReinitChart(state));
  },
  setAccubaseData(oldData, newData, config, changed) {
    dispatch(setAccubaseData(oldData, newData, config, changed));
  },
  setChangedData(data) {
    dispatch(setChangedData(data));
  }
});
export default connect(mapStateToProps, mapDispatchToProps)(DualAxisLineChart);
