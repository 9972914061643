import React, { Component } from "react";

import NumericEditor from "components/accucast/components/editors/numericEditor";
import TotalRenderer from "components/accucast/components/renderers/totalRenderer";
import { AccucastGrid } from "components/accucast/components/lib/accucastGrid";
import { numberFormatter } from "components/accucast/components/formatters";
import { numberNewValueHandler } from "components/accucast/components/handlers";
import { CustomHeaderRenderer } from "components/accucast/components/renderers/customHeaderRenderer";
import { INCREMENTAL_LIFT_UNITS } from "components/accucast/components/constants";
import { incrementalLiftGetter } from "components/accucast/components/getters";
import { EditableCellRenderer } from "components/accucast/components/renderers/editableCellRenderer";
import { GroupFilterer } from "components/accucast/components/filterers/groupFilterer";
import {
  brandSizeComparator,
  geoComparator
} from "components/accucast/components/comparators";
import axios from "axios";

var editableField = params => {
  if (params.data.type === INCREMENTAL_LIFT_UNITS) return false;
  return true;
};

var getRowClass = params => {
  var idx = params.node.rowIndex;
  var classes = [];
  switch (idx % 10) {
    case 0:
    case 1:
    case 2:
    case 3:
      classes.push("ta-even-row");
      break;
    case 4:
      classes.push("ta-final-group-row-even");
      break;
    case 5:
    case 6:
    case 7:
    case 8:
      classes.push("ta-odd-row");
      break;
    case 9:
      classes.push("ta-final-group-row-odd");
      break;
    default:
      classes.push("");
      break;
  }

  return classes.join(" ");
};

export class PlanIncrementalLift extends Component {
  constructor(props) {
    super(props);

    this.state = {
      columnDefs: [],
      rowData: this.createRows(),
      frameworkComponents: {
        numericEditor: NumericEditor,
        totalRenderer: TotalRenderer,
        customHeaderRenderer: CustomHeaderRenderer,
        editableCellRenderer: EditableCellRenderer,
        groupFilterer: GroupFilterer
      }
    };
  }

  componentDidMount() {
    var baseConfig = [
      {
        id: "0",
        headerName: "GEO",
        pinned: "left",
        width: 105,
        field: "geo",
        tooltipField: "geo",
        comparator: geoComparator,
        filter: "groupFilterer",
        suppressMovable: true,
        menuTabs: ["filterMenuTab"]
      },
      {
        id: "1",
        headerName: "Brand Size",
        pinned: "left",
        width: 138,
        field: "bs",
        tooltipField: "bs",
        comparator: brandSizeComparator,
        filter: "groupFilterer",
        suppressMovable: true,
        menuTabs: ["filterMenuTab"]
      },
      {
        id: "2",
        headerName: "Type",
        pinned: "left",
        width: 142,
        field: "type",
        tooltipField: "type",
        suppressFilter: true,
        suppressSorting: true,
        suppressMovable: true,
        menuTabs: []
      }
    ];
    var defaultWkCellConf = {
      width: 95,
      suppressMovable: true,
      menuTabs: ["filterMenuTab"],
      cellEditor: "numericEditor",
      editable: editableField,
      valueFormatter: numberFormatter,
      newValueHandler: numberNewValueHandler,
      headerComponent: "customHeaderRenderer",
      cellClass: "cellCenterClass",
      valueGetter: incrementalLiftGetter,
      cellRenderer: "editableCellRenderer"
    };
    axios
      .get("http://localhost:4000/api/accucast/config/incrementalLift", {
        headers: {
          ClientToken: localStorage.getItem("clientToken")
        }
      })
      .then(res => {
        if (res.data.config.length !== 0) {
          let config = res.data.config.map(conf => {
            let id = parseInt(conf.id, 10);
            if (id === 0 || id === 1 || id === 2) {
              let pinConf = baseConfig[id];
              pinConf.cellClassRules = conf.cellClassRules;
              return pinConf;
            } else {
              conf = { ...conf, ...defaultWkCellConf };
              return conf;
            }
          });
          this.setState({ columnDefs: config });
        }
      });
  }

  onGridReady(params) {
    this.gridApi = params.api;
    this.context = { thisComponent: this };
    this.gridColumnApi = params.columnApi;
    //this.gridApi.sizeColumnsToFit();
  }

  sortChanged() {
    this.gridApi.redrawRows();
  }

  createRows = () => {
    let rows = [];
    rows.push({
      fgeo: "AMAZON",
      fbs: "BAR",
      geo: "AMAZON",
      bs: "BAR",
      type: "Store Count",
      wk25: "25",
      wk26: "25",
      wk27: "25",
      wk28: "25",
      wk29: "0",
      wk30: "0"
    });
    rows.push({
      fgeo: "AMAZON",
      fbs: "BAR",
      geo: "",
      bs: "",
      type: "BUPSW",
      wk25: "25",
      wk26: "25",
      wk27: "25",
      wk28: "25",
      wk29: "0",
      wk30: "0"
    });
    rows.push({
      fgeo: "AMAZON",
      fbs: "BAR",
      geo: "",
      bs: "",
      type: "Promo Stores",
      wk25: "25",
      wk26: "25",
      wk27: "25",
      wk28: "25",
      wk29: "0",
      wk30: "0"
    });
    rows.push({
      fgeo: "AMAZON",
      fbs: "BAR",
      geo: "",
      bs: "",
      type: "Increm. Lift (WOS)",
      wk25: "1.5",
      wk26: "1.5",
      wk27: "1.5",
      wk28: "1.5",
      wk29: "1.5",
      wk30: "1.5"
    });
    rows.push({
      fgeo: "AMAZON",
      fbs: "BAR",
      geo: "",
      bs: "",
      type: "Increm. Lift (Units)",
      wk25: "25",
      wk26: "25",
      wk27: "25",
      wk28: "25",
      wk29: "0",
      wk30: "0"
    });
    rows.push({
      fgeo: "AMAZON",
      fbs: "SHAKE",
      geo: "AMAZON",
      bs: "SHAKE",
      type: "Store Count",
      wk25: "25",
      wk26: "25",
      wk27: "25",
      wk28: "25",
      wk29: "0",
      wk30: "0"
    });
    rows.push({
      fgeo: "AMAZON",
      fbs: "SHAKE",
      geo: "",
      bs: "",
      type: "BUPSW",
      wk25: "25",
      wk26: "25",
      wk27: "25",
      wk28: "25",
      wk29: "0",
      wk30: "0"
    });
    rows.push({
      fgeo: "AMAZON",
      fbs: "SHAKE",
      geo: "",
      bs: "",
      type: "Promo Stores",
      wk25: "25",
      wk26: "25",
      wk27: "25",
      wk28: "25",
      wk29: "0",
      wk30: "0"
    });
    rows.push({
      fgeo: "AMAZON",
      fbs: "SHAKE",
      geo: "",
      bs: "",
      type: "Increm. Lift (WOS)",
      wk25: "1.5",
      wk26: "1.5",
      wk27: "1.5",
      wk28: "1.5",
      wk29: "1.5",
      wk30: "1.5"
    });
    rows.push({
      fgeo: "AMAZON",
      fbs: "SHAKE",
      geo: "",
      bs: "",
      type: "Increm. Lift (Units)",
      wk25: "25",
      wk26: "25",
      wk27: "25",
      wk28: "25",
      wk29: "0",
      wk30: "0"
    });
    rows.push({
      fgeo: "WALMART",
      fbs: "MEAL",
      geo: "WALMART",
      bs: "MEAL",
      type: "Store Count",
      wk25: "25",
      wk26: "25",
      wk27: "25",
      wk28: "25",
      wk29: "0",
      wk30: "0"
    });
    rows.push({
      fgeo: "WALMART",
      fbs: "MEAL",
      geo: "",
      bs: "",
      type: "BUPSW",
      wk25: "25",
      wk26: "25",
      wk27: "25",
      wk28: "25",
      wk29: "0",
      wk30: "0"
    });
    rows.push({
      fgeo: "WALMART",
      fbs: "MEAL",
      geo: "",
      bs: "",
      type: "Promo Stores",
      wk25: "25",
      wk26: "25",
      wk27: "25",
      wk28: "25",
      wk29: "0",
      wk30: "0"
    });
    rows.push({
      fgeo: "WALMART",
      fbs: "MEAL",
      geo: "",
      bs: "",
      type: "Increm. Lift (WOS)",
      wk25: "1.5",
      wk26: "1.5",
      wk27: "1.5",
      wk28: "1.5",
      wk29: "1.5",
      wk30: "1.5"
    });
    rows.push({
      fgeo: "WALMART",
      fbs: "MEAL",
      geo: "",
      bs: "",
      type: "Increm. Lift (Units)",
      wk25: "25",
      wk26: "25",
      wk27: "25",
      wk28: "25",
      wk29: "0",
      wk30: "0"
    });

    return rows;
  };

  render() {
    return (
      <div style={{ height: "100%" }}>
        IncrementalLift
        <AccucastGrid
          columnDefs={this.state.columnDefs}
          rowData={this.state.rowData}
          getRowClass={getRowClass}
          frameworkComponents={this.state.frameworkComponents}
          onGridReady={this.onGridReady.bind(this)}
          onSortChanged={this.sortChanged.bind(this)}
        />
      </div>
    );
  }
}
