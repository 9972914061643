import React, { Component } from "react";
import { connect } from "react-redux";
import { setExpandDialog } from "components/promoOpt/reducers/promoOptReducer";
import { RightChevron } from "lib/icons";

class EventFeeCellRenderer extends Component {
  constructor(props) {
    super(props);

    this.state = {
      value: props.value,
      expanded: false
    };
    this.clickHandler = this.clickHandler.bind(this);
  }

  // noinspection JSUnusedGlobalSymbols
  refresh(params) {
    if (params.value !== this.state.value) {
      this.setState({
        value: params.value
      });
    }
    return true;
  }
  clickHandler() {
    //const {expanded} = this.state;
    // this.props.setExpandDialog('eventFee');

    let expanded = false;
    if (Math.sign(this.props.data.id) === -1) {
      return <div></div>;
    }
    if (this.props.api.getDisplayedRowAtIndex(this.props.rowIndex).master) {
      this.props.api.getDisplayedRowAtIndex(this.props.rowIndex).data.DIALOG = "eventFee";
      expanded = this.props.api.getDisplayedRowAtIndex(this.props.rowIndex).expanded;
      this.props.api.getDisplayedRowAtIndex(this.props.rowIndex).setExpanded(!expanded);
    } else {
      this.props.api.getDisplayedRowAtIndex(this.props.rowIndex + 1).data.DIALOG = "eventFee";
      expanded = this.props.api.getDisplayedRowAtIndex(this.props.rowIndex + 1).expanded;
      this.props.api.getDisplayedRowAtIndex(this.props.rowIndex + 1).setExpanded(!expanded);
    }
    this.setState({ expanded: !expanded });
    //let expand = !expanded;
    //this.setState({expanded:expand});
  }
  render() {
    let expanded = false;

    if (Math.sign(this.props.data.id) === -1) {
      return <div></div>;
    } else {
      if (this.props.api.getDisplayedRowAtIndex(this.props.rowIndex) !== undefined) {
        if (this.props.api.getDisplayedRowAtIndex(this.props.rowIndex).master) {
          expanded = this.props.api.getDisplayedRowAtIndex(this.props.rowIndex).expanded;
        } else {
          expanded = this.props.api.getDisplayedRowAtIndex(this.props.rowIndex + 1).expanded;
        }
      }

      if (expanded && this.props.data.DIALOG === "eventFee") {
        return (
          <div
            style={{ display: "flex", flexDirection: "row" }}
            onClick={() => {
              this.clickHandler();
            }}>
            <div>Event Fee</div>
            <RightChevron
              className="noHover"
              transform={"rotate(90,12,12)"}
              height={"24px"}
              width={"24px"}
              color={"black"}
              viewBox={"0 -2 24 24"}
            />
          </div>
        );
      } else {
        return (
          <div
            style={{ display: "flex", flexDirection: "row" }}
            onClick={() => {
              this.clickHandler();
            }}>
            <div>Event Fee</div>
            <RightChevron
              className="noHover"
              transform={"rotate(-90,12,12)"}
              height={"24px"}
              width={"24px"}
              color={"black"}
              viewBox={"0 -2 24 24"}
            />
          </div>
        );
      }
    }
  }
}

const mapStateToProps = state => ({
  dialog: state.promo.dialog
});
const mapDispatchToProps = dispatch => ({
  setExpandDialog(dialog) {
    dispatch(setExpandDialog(dialog));
  }
});

export default connect(mapStateToProps, mapDispatchToProps)(EventFeeCellRenderer);
