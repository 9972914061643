import React, { Component } from "react";
import AccucastTopLevelNav from "components/accucast/components/accucastTopLevelNav";

export class Accucast extends Component {
  login() {
    this.props.auth.login();
  }
  render() {
    const { isAuthenticated } = this.props.auth;

    return (
      <div style={{ height: "100%", minHeight: "100%" }}>
        {isAuthenticated() && (
          <div style={{ height: "100%", minHeight: "100%" }}>
            <AccucastTopLevelNav />
          </div>
        )}
      </div>
    );
  }
}
