import React, { Component } from "react";

import NumericEditor from "components/accucast/components/editors/numericEditor";
import TotalRenderer from "components/accucast/components/renderers/totalRenderer";
import { AccucastGrid } from "components/accucast/components/lib/accucastGrid";
import {
  numberFormatter,
  getRowClass,
  floatFormatter
} from "components/accucast/components/formatters";
import { numberNewValueHandler } from "components/accucast/components/handlers";

import { PlanCellRenderer } from "components/accucast/components/updatePlan/renderers/planCellRenderer";
import { CustomHeaderRenderer } from "components/accucast/components/renderers/customHeaderRenderer";
import { EditableCellFloatRenderer } from "components/accucast/components/renderers/editableCellFloatRenderer";

export class PlanItemInput extends Component {
  constructor(props) {
    super(props);

    this.state = {
      columnDefs: [
        {
          headerName: "Item Description",
          pinned: "left",
          width: 375,
          field: "idesc",
          tooltipField: "idesc",
          suppressMovable: true,
          menuTabs: ["filterMenuTab"],
          cellClassRules: {
            "ta-changed":
              "data.storeCountOverrideChanged || data.UPSWOverrideChanged || data.assumptionsChanged"
          }
        },
        {
          headerName: "Prod Key",
          pinned: "left",
          width: 136,
          field: "pk",
          tooltipField: "pk",
          suppressMovable: true,
          menuTabs: ["filterMenuTab"],
          cellClassRules: {
            "ta-changed":
              "data.storeCountOverrideChanged || data.UPSWOverrideChanged || data.assumptionsChanged"
          }
        },
        {
          headerName: "Brand Size",
          pinned: "left",
          width: 138,
          field: "bs",
          tooltipField: "bs",
          suppressMovable: true,
          menuTabs: ["filterMenuTab"],
          cellClassRules: {
            "ta-changed":
              "data.storeCountOverrideChanged || data.UPSWOverrideChanged || data.assumptionsChanged"
          }
        },
        {
          headerName: "GEO",
          pinned: "left",
          width: 105,
          field: "geo",
          tooltipField: "geo",
          suppressMovable: true,
          menuTabs: ["filterMenuTab"],
          cellClassRules: {
            "ta-changed":
              "data.storeCountOverrideChanged || data.UPSWOverrideChanged || data.assumptionsChanged"
          }
        },
        {
          headerName: "Base Unit POS-CURR",
          headerComponent: "customHeaderRenderer",
          width: 167,
          field: "bu",
          suppressMovable: true,
          menuTabs: ["filterMenuTab"],
          cellEditor: "numericEditor",
          editable: false,
          valueFormatter: numberFormatter,
          newValueHandler: numberNewValueHandler,
          cellClass: "cellCenterClass",
          headerClass: "headerSize"
        },
        {
          headerName: "Store Count",
          headerComponent: "customHeaderRenderer",
          width: 112,
          field: "sc",
          suppressMovable: true,
          menuTabs: ["filterMenuTab"],
          cellEditor: "numericEditor",
          editable: false,
          valueFormatter: numberFormatter,
          newValueHandler: numberNewValueHandler,
          cellClass: "cellCenterClass",
          headerClass: "headerSize"
        },
        {
          headerName: "Store Count Override",
          headerComponent: "customHeaderRenderer",
          width: 166,
          field: "sco",
          suppressMovable: true,
          menuTabs: ["filterMenuTab"],
          cellEditor: "numericEditor",
          editable: true,
          valueFormatter: numberFormatter,
          newValueHandler: numberNewValueHandler,
          cellClass: "cellCenterClass",
          headerClass: "headerSize",
          cellRenderer: "planCellRenderer"
        },
        {
          headerName: "UPSW",
          headerComponent: "customHeaderRenderer",
          width: 77,
          field: "upsw",
          suppressMovable: true,
          menuTabs: ["filterMenuTab"],
          cellEditor: "numericEditor",
          editable: false,
          valueFormatter: numberFormatter,
          newValueHandler: numberNewValueHandler,
          cellClass: "cellCenterClass",
          headerClass: "headerSize"
        },
        {
          headerName: "UPSW Override",
          headerComponent: "customHeaderRenderer",
          width: 132,
          field: "upswo",
          suppressMovable: true,
          menuTabs: ["filterMenuTab"],
          cellEditor: "numericEditor",
          editable: true,
          valueFormatter: numberFormatter,
          newValueHandler: numberNewValueHandler,
          cellClass: "cellCenterClass",
          headerClass: "headerSize",
          cellRenderer: "planCellRenderer"
        },
        {
          headerName: "Store Elasticity",
          headerComponent: "customHeaderRenderer",
          width: 133,
          field: "se",
          suppressMovable: true,
          menuTabs: ["filterMenuTab"],
          cellEditor: "numericEditor",
          editable: false,
          valueFormatter: floatFormatter,
          newValueHandler: numberNewValueHandler,
          cellClass: "cellCenterClass",
          headerClass: "headerSize"
        },
        {
          headerName: "Assumptions",
          headerComponent: "customHeaderRenderer",
          width: 120,
          field: "ass",
          suppressMovable: true,
          menuTabs: ["filterMenuTab"],
          cellEditor: "numericEditor",
          editable: true,
          valueFormatter: numberFormatter,
          newValueHandler: numberNewValueHandler,
          cellClass: "cellCenterClass",
          headerClass: "headerSize",
          cellRenderer: "planCellRenderer"
        },
        {
          headerName: "POS Store Count",
          headerComponent: "customHeaderRenderer",
          width: 141,
          field: "psc",
          suppressMovable: true,
          menuTabs: ["filterMenuTab"],
          cellEditor: "numericEditor",
          editable: false,
          valueFormatter: numberFormatter,
          newValueHandler: numberNewValueHandler,
          cellClass: "cellCenterClass",
          headerClass: "headerSize"
        },
        {
          headerName: "Authorized Store Count",
          headerComponent: "customHeaderRenderer",
          width: 180,
          field: "asc",
          suppressMovable: true,
          menuTabs: ["filterMenuTab"],
          cellEditor: "numericEditor",
          editable: false,
          valueFormatter: numberFormatter,
          newValueHandler: numberNewValueHandler,
          cellClass: "cellCenterClass",
          headerClass: "headerSize"
        },
        {
          headerName: "Store Count Diff",
          headerComponent: "customHeaderRenderer",
          width: 140,
          field: "scd",
          suppressMovable: true,
          menuTabs: ["filterMenuTab"],
          cellEditor: "numericEditor",
          editable: false,
          valueFormatter: numberFormatter,
          newValueHandler: numberNewValueHandler,
          cellClass: "cellCenterClass",
          headerClass: "headerSize"
        }
      ],
      rowData: this.createRows(),
      frameworkComponents: {
        numericEditor: NumericEditor,
        totalRenderer: TotalRenderer,
        planCellRenderer: PlanCellRenderer,
        customHeaderRenderer: CustomHeaderRenderer,
        editableCellFloatRenderer: EditableCellFloatRenderer
      }
    };
  }
  createRows = () => {
    let rows = [];
    rows.push({
      geo: "AHOLD",
      idesc: "89898-NS DIET TURBO CHOS PWD PKT 5CT 1.4OZB",
      pk: "123456789012",
      bs: "SALTY SNACK",
      bu: "1",
      sc: "0",
      upsw: "",
      se: "1.0",
      psc: "0",
      asc: "0",
      scd: "0"
    });
    rows.push({
      geo: "AMAZON",
      idesc: "89898-NS DIET TURBO CHOS PWD PKT 5CT 1.4OZB",
      pk: "123456789012",
      bs: "SALTY SNACK",
      bu: "1",
      sc: "0",
      upsw: "",
      se: "1.0",
      psc: "0",
      asc: "0",
      scd: "0"
    });
    rows.push({
      geo: "AMAZON",
      idesc: "84220-SOUTH BEACH DOUBLE CHOC BAR 30CT",
      pk: "3267484220",
      bs: "BAR",
      bu: "1",
      sc: "0",
      upsw: "",
      se: "1.0",
      psc: "0",
      asc: "0",
      scd: "0"
    });
    rows.push({
      geo: "AMAZON",
      idesc: "84221-SOUTH BEACH PEANUT BUTTER BAR 30CT",
      pk: "3267484221",
      bs: "BAR",
      bu: "1",
      sc: "0",
      upsw: "",
      se: "1.0",
      psc: "0",
      asc: "0",
      scd: "0"
    });
    rows.push({
      geo: "C&S",
      idesc: "84192-NS DIET KIT EVERYDAY 1CT",
      pk: "3267484192",
      bs: "KIT",
      bu: "1",
      sc: "0",
      upsw: "",
      se: "1.0",
      psc: "0",
      asc: "0",
      scd: "0"
    });
    rows.push({
      geo: "DIV1",
      idesc: "83504-NS DIAB VANIL LIQ 4CT 11.0OZB",
      pk: "3267483504",
      bs: "SHAKE",
      bu: "1",
      sc: "0",
      upsw: "",
      se: "1.0",
      psc: "0",
      asc: "0",
      scd: "0"
    });

    return rows;
  };

  render() {
    return (
      <div style={{ height: "100%" }}>
        Item Input
        <AccucastGrid
          columnDefs={this.state.columnDefs}
          rowData={this.state.rowData}
          getRowClass={getRowClass}
          headerHeight={30}
          frameworkComponents={this.state.frameworkComponents}
        />
      </div>
    );
  }
}
