import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { Switch, Redirect, Route } from "react-router-dom";
import DataManagement from "components/mdm/components/dataManagement";

class MdmRouter extends Component {
  render() {
    const { match } = this.props;
    return (
      <div
        className="reports-router-div"
        style={{
          display: "flex",
          flexDirection: "column",
          position: "fixed",
          top: "100px",
          height: "100%",
          //minHeight: "100%",
          width: "100%",
          paddingBottom: "130px",
          paddingLeft: "10px",
          paddingRight: "10px"
        }}
      >
        <Switch>
          <Redirect from="/mdm" exact to={"/mdm/dataManagement/"} />
          <Route
            path={match.path + "/dataManagement"}
            render={props => <DataManagement {...props} />}
          />
        </Switch>
      </div>
    );
  }
}

export default withRouter(MdmRouter);
