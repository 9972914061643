import React, { Component } from "react";

import { connect } from "react-redux";

import {
  createPromo,
  getPromoOptConfigData,
  setViewSelected,
  appendChanged
} from "components/promoOpt/reducers/promoOptReducer";

class PromoOptConfigCellRenderer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      promoPlans: props.data.promoPlans,
      showNewPromoDialog: false,
      selectedRadioOption: 1,
      liftName: "",
      dialog: "weeklyPromo",
      errorMessage: "",
      quarters: [
        { id: 1, value: "Q1", label: "Q1" },
        { id: 2, value: "Q2", label: "Q2" },
        { id: 3, value: "Q3", label: "Q3" },
        { id: 4, value: "Q4", label: "Q4" }
      ],
      years: [
        { id: 1, value: "2017", label: "2017" },
        { id: 2, value: "2018", label: "2018" },
        { id: 3, value: "2019", label: "2019" },
        { id: 4, value: "2020", label: "2020" }
      ],
      selectedQtr: { id: 1, value: "Q1", label: "Q1" },
      selectedYear: { id: 4, value: "2020", label: "2020" },
      creatingProject: false
    };
    this.LiftName = React.createRef();
    this.PromoName = React.createRef();
    this.handleInputChange = this.handleInputChange.bind(this);
    this.renderWeeks = this.renderWeeks.bind(this);
    this.createHandler = this.createHandler.bind(this);
    this.changeWeekInputHandler = this.changeWeekInputHandler.bind(this);
  }

  handleInputChange() {
    this.setState({ liftName: this.LiftName.current.value });
  }

  createHandler() {
    this.props.getPromoOptConfigData("1", this.props.data.finalBaselineId);
    this.props.setViewSelected(3);
  }
  handleQtrChange = selectedOption => {
    this.setState({
      selectedQtr: selectedOption
    });
  };
  handleChange = selectedOption => {
    this.setState({
      selectedYear: selectedOption
    });
  };
  handleClose = () => {
    this.setState({ showNewPromoDialog: false });
  };
  renderFees() {
    const { data, alias } = this.props;
    let retVal = [];

    retVal.push(
      <tr key={"MAJOR"}>
        <th className="fee-item">{alias.majorAlias}</th>
        <th className="fee-item">
          <input
            style={{ width: "80px", textAlign: "right" }}
            onChange={value => {
              this.changeWeekInputHandler(value, "EVENT_FEE_MAJOR");
            }}
            defaultValue={data.EVENT_FEE_MAJOR}></input>
        </th>
      </tr>
    );
    retVal.push(
      <tr key={"MINOR"}>
        <th className="fee-item">{alias.minorAlias}</th>
        <th className="fee-item">
          <input
            style={{ width: "80px", textAlign: "right" }}
            onChange={value => {
              this.changeWeekInputHandler(value, "EVENT_FEE_MINOR");
            }}
            defaultValue={data.EVENT_FEE_MINOR}></input>
        </th>
      </tr>
    );
    retVal.push(
      <tr key={"TPR"}>
        <th className="fee-item">{alias.tprAlias}</th>
        <th className="fee-item">
          <input
            style={{ width: "80px", textAlign: "right" }}
            onChange={value => {
              this.changeWeekInputHandler(value, "EVENT_FEE_TPR");
            }}
            defaultValue={data.EVENT_FEE_TPR}></input>
        </th>
      </tr>
    );
    retVal.push(
      <tr key={"OTHER"}>
        <th className="fee-item">{alias.otherAlias}</th>
        <th className="fee-item">
          <input
            style={{ width: "80px", textAlign: "right" }}
            onChange={value => {
              this.changeWeekInputHandler(value, "EVENT_FEE_OTHER");
            }}
            defaultValue={data.EVENT_FEE_OTHER}></input>
        </th>
      </tr>
    );
    return retVal;
  }
  changeWeekInputHandler(value, weekKey) {
    const { data } = this.props;
    let newData = {};
    newData.data = data;

    newData.data[weekKey] = value.currentTarget.value;
    this.props.appendChanged(newData);
  }

  renderWeeks() {
    const { quarter, data } = this.props;
    let retVal = [];

    for (var x = 0; x < 13; x++) {
      let wkPri = "";
      let newX = x + 1;
      if (newX < 10) {
        wkPri = "WK_PRIORITY_0" + newX;
      } else {
        wkPri = "WK_PRIORITY_" + newX;
      }
      retVal.push(
        <tr key={x}>
          <th className="week-pri-item">{x + 1}</th>
          <th className="week-pri-item">{quarter[x]}</th>
          <th className="week-pri-item">
            <input
              style={{ width: "40px", textAlign: "right" }}
              onChange={value => {
                this.changeWeekInputHandler(value, wkPri);
              }}
              defaultValue={data[wkPri]}></input>
          </th>
        </tr>
      );
    }
    return retVal;
  }
  render() {
    const { data } = this.props;

    if (data.DIALOG === "eventFee") {
      return (
        <div
          style={{
            padding: "5px",
            display: "flex",
            flexDirection: "row",
            height: "100%",
            background: "rgb(247, 248, 250)",
            justifyContent: "center"
          }}>
          {/* <div
            style={{
              width: "50%",
              border: "1px solid #bbbfc4",
              marginRight: "20px",
              padding: "10px"
            }}
          > */}
          <div
            style={{
              border: "1px solid #bbbfc4",
              paddingTop: "10px",
              paddingRight: "20px",
              paddingLeft: "20px",
              backgroundColor: "white",
              maxHeight: "210px"
            }}>
            <div
              style={{
                textAlign: "center",
                fontSize: "16px",
                marginBottom: "20px"
              }}>
              Event Fees
            </div>
            <table style={{ width: "50%" }}>
              <thead>
                <tr>
                  <th className="fee-item fee-header" style={{ padding: "2px !important" }}>
                    Promo Type
                  </th>
                  <th className="week-pri-item week-header" style={{ padding: "2px !important" }}>
                    Fee
                  </th>
                </tr>
              </thead>
              <tbody>{this.renderFees()}</tbody>
            </table>
          </div>
        </div>
      );
    } else {
      return (
        <div
          style={{
            padding: "5px",
            display: "flex",
            flexDirection: "row",
            background: "rgb(247, 248, 250)",
            justifyContent: "center"
          }}>
          <div
            style={{
              border: "1px solid #bbbfc4",
              marginRight: "40px",
              padding: "10px",
              backgroundColor: "white"
            }}>
            <div
              style={{
                textAlign: "center",
                fontSize: "16px",
                marginBottom: "20px"
              }}>
              Week Priority
            </div>
            <table style={{ width: "50%" }}>
              <thead>
                <tr>
                  <th className="week-pri-item week-header" style={{}}>
                    Week
                  </th>
                  <th className="week-pri-item week-header" style={{}}>
                    Week Date
                  </th>
                  <th className="week-pri-item week-header" style={{}}>
                    Priority
                  </th>
                </tr>
              </thead>
              <tbody>{this.renderWeeks()}</tbody>
            </table>
          </div>
          <div
            style={{
              border: "1px solid #bbbfc4",
              paddingTop: "10px",
              paddingRight: "20px",
              paddingLeft: "20px",
              backgroundColor: "white",
              maxHeight: "210px"
            }}>
            <div
              style={{
                textAlign: "center",
                fontSize: "16px",
                marginBottom: "20px"
              }}>
              Weekly Promotional Settings
            </div>
            <div style={{ display: "flex", flexDirection: "row" }}>
              <div className="weekly-promo-col">Max Number of Weeks An Item Can be Promoted</div>
              <div className="weekly-promo-col">
                <input
                  className="weekly-promo-input"
                  onChange={value => {
                    this.changeWeekInputHandler(value, "MAX_TOTAL_WEEKS_ON_PROMO");
                  }}
                  defaultValue={data.MAX_TOTAL_WEEKS_ON_PROMO}></input>
              </div>
            </div>
            <div style={{ display: "flex", flexDirection: "row" }}>
              <div className="weekly-promo-col">Max Number of Continuous Weeks An Item Can be Promoted</div>
              <div className="weekly-promo-col">
                <input
                  className="weekly-promo-input"
                  onChange={value => {
                    this.changeWeekInputHandler(value, "MAX_CONTINUOUS_WEEKS_ON_PROMO");
                  }}
                  defaultValue={data.MAX_CONTINUOUS_WEEKS_ON_PROMO}></input>
              </div>
            </div>
            <div style={{ display: "flex", flexDirection: "row" }}>
              <div className="weekly-promo-col">Max Number of Items That Can be Promoted Each Week</div>
              <div className="weekly-promo-col">
                <input
                  className="weekly-promo-input"
                  onChange={value => {
                    this.changeWeekInputHandler(value, "MAX_PROMO_ITEMS_PER_WEEK");
                  }}
                  defaultValue={data.MAX_PROMO_ITEMS_PER_WEEK}></input>
              </div>
            </div>
          </div>
        </div>
      );
    }
  }
}

const mapStateToProps = state => ({
  dialog: state.promo.dialog,
  quarter: state.promo.quarter,
  alias: state.promo.alias
});
const mapDispatchToProps = dispatch => ({
  createPromo(baselineId, versionName) {
    dispatch(createPromo(baselineId, versionName));
  },
  getPromoOptConfigData(promoId, baselineId) {
    dispatch(getPromoOptConfigData(promoId, baselineId));
  },
  setViewSelected(view) {
    dispatch(setViewSelected(view));
  },
  appendChanged(changed) {
    dispatch(appendChanged(changed));
  }
});

export default connect(mapStateToProps, mapDispatchToProps)(PromoOptConfigCellRenderer);
