import React, { Component } from "react";

export default class NumericEditor extends Component {
  constructor(props) {
    super(props);
    //console.log(props.node)
    this.cancelBeforeStart =
      this.props.charPress && "1234567890".indexOf(this.props.charPress) < 0;

    let value = this.props.value;
    if (!this.cancelBeforeStart && this.props.charPress) {
      value = value + this.props.charPress;
    }
    this.state = {
      value,
      changed: false
    };

    this.onKeyDown = this.onKeyDown.bind(this);
    this.handleChange = this.handleChange.bind(this);
  }

  componentDidMount() {
    this.refs.input.addEventListener("keydown", this.onKeyDown);
    this.focus();
  }

  componentDidUpdate() {
    this.focus();
  }

  componentWillUnmount() {
    this.refs.input.removeEventListener("keydown", this.onKeyDown);
  }

  focus() {
    // setTimeout(() => {
    //   this.refs.input.focus();
    //   this.refs.input.setSelectionRange(this.state.value.length, this.state.value.length);
    // })
  }

  getValue() {
    return this.state.value;
  }

  isCancelBeforeStart() {
    //this.state.value = this.state.value.split("%")[0];
    return this.cancelBeforeStart;
  }

  //Reject if the number has more than one decimal place.
  isCancelAfterEnd() {
    let match = this.state.value.toString().match(/\./g);
    if (match == null) return false;
    else return match.length > 1;
  }

  onKeyDown(event) {
    if (this.isLeftRightOrBackSpace(event)) {
      event.stopPropagation();
      return;
    }

    if (!this.isKeyPressedNumeric(event)) {
      if (event.preventDefault) event.preventDefault();
    }
  }

  isLeftRightOrBackSpace(event) {
    return [37, 39, 8, 190].indexOf(event.keyCode) > -1;
  }

  handleChange(event) {
    this.setState({ value: event.target.value });
  }

  getCharCodeFromEvent(event) {
    event = event || window.event;
    return typeof event.which === "undefined" ? event.keyCode : event.which;
  }

  isCharNumeric(charStr) {
    return !!/\d/.test(charStr);
  }

  isKeyPressedNumeric(event) {
    const charCode = this.getCharCodeFromEvent(event);
    const charStr = event.key ? event.key : String.fromCharCode(charCode);
    return this.isCharNumeric(charStr);
  }

  render() {
    return (
      <input
        ref="input"
        value={this.state.value}
        onChange={this.handleChange}
        style={{ width: "100%" }}
      />
    );
  }
}
