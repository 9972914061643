import React, { Component } from "react";
import "./style/style.css";
import { RightChevron, ReportSvg, FolderIcon, CloseReportIcon } from "lib/icons";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import PowerBIFolder from "components/tabsApps/components/powerBIFolder";

export class TopLevelNavItem extends Component {
  constructor(props) {
    super(props);
    this.handleClick = this.handleClick.bind(this);
    this.state = {
      collapsed: false
    };
  }
  handleClick(e, type, workspace, report) {
    if (type === "folder") {
      e.stopPropagation();
      e.preventDefault();
      this.setState(prevState => ({
        collapsed: !prevState.collapsed
      }));
    } else {
      if (report === undefined) {
        this.props.clickHandler(this.props, [this.props.parentIndex, this.props.index], this.props.type);
      } else {
        report.workspaceId = workspace;
        let findParentArr = report.path.split("/");
        let folderIdx = 0;
        let subFolderIdx = 0;
        let reportIdx = 0;

        if (this.props.tab.activeChildren !== undefined && this.props.tab.activeChildren.length > 0) {
          this.props.tab.activeChildren.forEach((item, idx) => {
            if (item.path === findParentArr[0] + "/" + findParentArr[1] + "/") {
              folderIdx = idx;
            }
            if (item.activeChildren !== undefined && item.activeChildren.length > 0) {
              item.activeChildren.forEach((cItem, cIdx) => {
                if (cItem.activeChildren !== undefined && cItem.path.split("/").length >= 3) {
                  if (cItem.path === findParentArr[0] + "/" + findParentArr[1] + "/" + findParentArr[2] + "/") {
                    subFolderIdx = cIdx;
                  }
                  cItem.activeChildren.forEach((gItem, gIdx) => {
                    if (
                      gItem.path ===
                      findParentArr[0] + "/" + findParentArr[1] + "/" + findParentArr[2] + "/" + findParentArr[3] + "/"
                    ) {
                      reportIdx = gIdx;
                    }
                  });
                }
              });
            }
          });
        }
        if (report.path.split("/").length > 3) {
          this.props.clickHandler(report, [this.props.parentIndex, folderIdx, subFolderIdx, reportIdx], type);
        } else if (report.path.split("/").length === 3) {
          reportIdx = 0;
          if (
            this.props.tab.activeChildren !== undefined &&
            this.props.tab.activeChildren.length > 0 &&
            this.props.tab.activeChildren[folderIdx].activeChildren !== undefined
          ) {
            if (this.props.tab.activeChildren[folderIdx].activeChildren.length > 0) {
              reportIdx = this.props.tab.activeChildren[folderIdx].activeChildren.length;
            }
            this.props.tab.activeChildren[folderIdx].activeChildren.forEach((item, rIdx) => {
              if (item.fileType === undefined || item.fileType === "folder") {
              } else {
                if (item.name === report.name) {
                  reportIdx = rIdx;
                }
              }
            });
          }

          this.props.clickHandler(report, [this.props.parentIndex, folderIdx, reportIdx], type);
        } else {
          this.props.clickHandler(report, [this.props.parentIndex, this.props.index], type);
        }
      }
    }
  }
  renderCollapsible() {
    return (
      <div className="menu-list-collapse">
        <RightChevron
          transform={this.state.collapsed ? "" : "rotate(90,12,12)"}
          height={"20px"}
          // width={"24px"}
          color={"rgba(88, 89, 91, 1)"}
          viewBox={"-1 2 24 24"}
        />
      </div>
    );
  }
  itemIcon(type) {
    switch (type) {
      case "powerBI":
        return <ReportSvg height={"16px"} className={"ta-pbi-icon"} color={"rgba(121, 121, 121, 1)"} viewBox={"0 -1 24 24"} />;
      case "tableau":
        return <ReportSvg height={"16px"} className={"ta-pbi-icon"} color={"rgba(121, 121, 121, 1)"} viewBox={"0 -1 24 24"} />;
      case "Files":
        return (
          <FolderIcon
            height={"12px"}
            className={"ta-pbi-icon"}
            color={"rgba(121, 121, 121, 1)"}
            style={{ marginBottom: "4px" }}
          />
        );
      default:
        return "";
    }
  }
  render() {
    if (this.props.fileType === "folder" || this.props.type === "grouping") {
      let selectedReports = [];

      if (this.props.tab.activeChildren !== undefined && this.props.tab.activeChildren.length > 0) {
        // selectedReports = this.props.tab.activeChildren[
        //   this.props.index
        // ].children.map(item => {
        //   return item.id;
        // });
        if (
          this.props.type === "grouping" ||
          (this.props.tab.activeChildren[this.props.index] !== undefined &&
            this.props.tab.activeChildren[this.props.index].children !== undefined &&
            this.props.tab.activeChildren[this.props.index].children.length > 0)
        ) {
          this.props.tab.activeChildren.forEach(child => {
            if (child.type === "grouping") {
              if (child.activeChildren !== undefined) {
                child.activeChildren.forEach(gChild => {
                  if (gChild.id.includes("+")) {
                    selectedReports.push(gChild._id);
                  } else {
                    selectedReports.push(gChild.id);
                  }
                });
              }
            } else {
              selectedReports.push(child.id);
            }
          });
        }
      }

      return (
        <PowerBIFolder
          clickReportHandler={(e, workspace, report) => {
            e.stopPropagation();
            e.preventDefault();
            this.handleClick(e, report.type, workspace, report);
          }}
          key={this.props.index}
          report={this.props.tab.children[this.props.index]}
          selectedReports={selectedReports}
          workspace={this.props.tab.workspaceId}
          removeHandler={this.props.removeHandler}
          //selectedReports={this.props.tab}
          leftSideCaret={true}
          dontShowDates={true}
        />
      );
    } else {
      let active = false;
      if (this.props.selectedTab[2] === undefined || this.props.selectedTab[2] === -1) {
        if (this.props.tab.activeChildren !== undefined && this.props.tab.activeChildren.length > 0) {
          if (this.props.selectedTab[1] !== -1) {
            if (
              this.props.tab.activeChildren[this.props.selectedTab[1]] !== undefined &&
              this.props.tab.activeChildren[this.props.selectedTab[1]].id !== undefined &&
              this.props.tab.activeChildren[this.props.selectedTab[1]].id.includes("+")
            ) {
              if (
                this.props.tab.activeChildren[this.props.selectedTab[1]] !== undefined &&
                this.props.tab.activeChildren[this.props.selectedTab[1]].id.split("+")[1] === this.props.id
              ) {
                active = true;
              }
            } else {
              if (
                this.props.tab.activeChildren[this.props.selectedTab[1]] !== undefined &&
                this.props.tab.activeChildren[this.props.selectedTab[1]].id === this.props.id
              ) {
                active = true;
              }
            }
          }
        }
      }
      return (
        <div
          className={
            active
              ? "tab tab-selected ta-workspace-reports-span reportListFolders topLevelNav"
              : "tab tab-unselected ta-workspace-reports-span reportListFolders topLevelNav"
          }
          onClick={e => {
            e.stopPropagation();
            e.preventDefault();
            this.props.clickHandler(this.props, this.props.index, this.props.type);
          }}
          key={this.props.index}>
          {this.itemIcon(this.props.type)}
          {this.props.text}
          {this.props.selected && (
            <OverlayTrigger
              key={"closereport-tip"}
              placement={"right"}
              overlay={<Tooltip id={"closereport-tip"}>Close Report</Tooltip>}>
              <div
                className={this.props.selected ? "close-selected" : "close-unselected"}
                style={{
                  display: "inline",
                  paddingLeft: "13px",
                  cursor: "pointer"
                }}
                onClick={event => {
                  event.stopPropagation();
                  event.preventDefault();

                  this.props.removeHandler(event, this.props);
                }}>
                {/* <CloseIcon height={"12px"} viewBox={"0 0 20 20"} /> */}
                <CloseReportIcon height={"15px"} viewBox={"0 0 20 20"} color={"#58595B"} />
              </div>
            </OverlayTrigger>
          )}
        </div>
      );
    }
  }
}
