import React, { Component } from "react";
import { BarMenuIcon } from "lib/icons";
import "components/tabsApps/style/style.css";
import onClickOutside from "react-onclickoutside";
import { withRouter } from "react-router-dom";
import { getReports, getAppMenu } from "components/reports/components/reportList/reducers/reportListReducer";
import { addTabToNav } from "components/topLevelNav/reducers/topLevelNavReducer";
import { connect } from "react-redux";
import AppsItem from "components/tabsApps/components/appsItem";
import { getMdmApps } from "components/mdm/reducers/mdmReducer";
import { cloneDeep } from "lodash";

import { filterApps } from "reducers/appReducer";

class TabsApps extends Component {
  constructor(props) {
    super(props);
    this.state = {
      listOpen: false,
      loadedApps: false
    };
    this.clickHandler = this.clickHandler.bind(this);
    this.handleClickOutside = this.handleClickOutside.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this);
  }

  componentDidUpdate() {
    const { apps, mdmAppsLoaded, mdmAppsLoading, rlLoaded, rlLoading, rlError, auth } = this.props;

    if (!mdmAppsLoaded && !mdmAppsLoading) {
      let mdmAccess = apps.filter(app => {
        return app.type === "mdh";
      });
      if (mdmAccess.length > 0) {
        this.props.getMdmApps();
      }
    }

    if (!rlLoaded && !rlLoading && this.props.isAuthenticated()) {
      if (rlError !== null && rlError.response !== undefined && rlError.response.data.message === "Invalid Token") {
        auth.logout();
      } else {
        let reportAccess = apps.filter(app => {
          return app.type === "powerBI" || app.type === "tableau";
        });
        if (reportAccess.length > 0) {
          this.props.getReports();
        }
      }
    }

    if (
      rlLoaded &&
      this.props.tabs.length === 0 //&&
      // Object.keys(this.props.activeWS).length !== 0 &&
      // this.props.activeWS.constructor === Object
    ) {
      let sWorkspace = this.props.workspaces.filter(item => {
        if (item.workspaceId === undefined) {
          return item._id === this.props.activeWS;
        } else {
          return item.workspaceId === this.props.activeWS;
        }
      });
      sWorkspace = sWorkspace[0];
      if (sWorkspace !== undefined) {
        let icon = sWorkspace.icon;
        if (sWorkspace.icon === undefined) {
          icon = sWorkspace.type;
        }
        let aChildren = [];
        if (sWorkspace.workspaceId === undefined) {
          if (sWorkspace.type === "customApplication") {
            let checkGroup = this.props.customGroup;
            if (checkGroup === undefined) {
              checkGroup = this.props.history.location.pathname.split("/")[4];
            }

            if (checkGroup !== undefined && checkGroup !== null) {
              let findGroup = cloneDeep(sWorkspace);
              findGroup = findGroup.groupings.filter(item => {
                return item.id === checkGroup;
              });

              if (findGroup.length > 0) {
                findGroup = findGroup[0];
                // findGroup.children = [];
                findGroup.activeChildren = [];
                aChildren = [findGroup];
              }
            }

            this.props.addTabToNav({
              title: sWorkspace.name,
              workspaceName: sWorkspace.name,
              workspaceId: sWorkspace._id,
              children: sWorkspace.reports,
              activeChildren: aChildren,
              icon,
              type: sWorkspace.type,
              groupings: sWorkspace.groupings,
              applications: sWorkspace.applications,
              order: sWorkspace.order,
              alias: sWorkspace.alias
            });
          } else {
            this.props.addTabToNav({
              title: sWorkspace.name,
              workspaceName: sWorkspace.name,
              workspaceId: sWorkspace._id,
              children: sWorkspace.children,
              icon,
              activeChildren: aChildren,
              type: sWorkspace.type,
              alias: sWorkspace.alias
            });
          }
        } else {
          this.props.addTabToNav({
            title: sWorkspace.name,
            workspaceName: sWorkspace.name,
            workspaceId: sWorkspace.workspaceId,
            icon,
            children: sWorkspace.children,
            activeChildren: [],
            type: sWorkspace.type,
            alias: sWorkspace.alias
          });
          // }
        }
      }
    }

    if (this.appSearch !== undefined && this.appSearch !== null) {
      if (this.appSearch.value !== this.props.filter) {
        this.appSearch.value = this.props.filter;
      }
    }
  }

  clickHandler(link) {
    this.props.history.push(link);
    this.toggleList();
  }

  handleClickOutside() {
    this.setState({
      listOpen: false
    });
  }
  toggleList() {
    this.setState(prevState => ({
      listOpen: !prevState.listOpen
    }));
  }
  renderAppsItem() {
    const { searchApps } = this.props;

    if (searchApps !== undefined) {
      return searchApps.map(
        (item, index) => <AppsItem item={item} key={index} handleClickOutside={this.handleClickOutside} />,
        this
      );
    } else {
      return <div />;
    }
  }
  handleInputChange() {
    this.props.filterApps(this.search.value, this.props.apps);
  }
  render() {
    const { listOpen } = this.state;
    return (
      <div className={this.props.className + " flex-container"}>
        <div className="ta-app-parent" onClick={() => this.toggleList()}>
          {listOpen ? (
            <div className="app-menu app-menu-open">
              <BarMenuIcon style={{ position: "relative" }} height={"20px"} color={"white"} className={"app-menu-svg"} />
            </div>
          ) : (
            <div className="app-menu">
              <BarMenuIcon
                style={{ position: "relative" }}
                height={"20px"}
                color={"rgba(0, 126, 167, 1)"}
                className={"app-menu-svg"}
              />
            </div>
          )}
        </div>

        {listOpen && (
          <div style={{ position: "relative", top: "30px", left: "-38px" }}>
            <div className="dd-list-container">
              <input
                className="input-box"
                placeholder="search"
                ref={input => (this.search = input)}
                onChange={this.handleInputChange}
                style={{ width: "100%", marginBottom: "5px" }}
              />
              {/* <ul className="dd-list">{this.renderAppsItem()}</ul> */}
              <div className="dd-list">{this.renderAppsItem()}</div>
            </div>
          </div>
        )}
      </div>
    );
  }
}

const mapStateToProps = state => ({
  apps: state.app.apps,
  searchApps: state.app.searchApps,
  activeWS: state.reportList.activeWS,
  tabs: state.topLevelNav.tabs,
  workspaces: state.reportList.workspaces,
  rlLoading: state.reportList.loading,
  rlLoaded: state.reportList.loaded,
  rlError: state.reportList.error,
  fullAppsLoaded: state.app.fullAppsLoaded,
  mdmApps: state.mdm.mdmApps,
  mdmAppsLoading: state.mdm.mdmAppsLoading,
  mdmAppsLoaded: state.mdm.mdmAppsLoaded,
  filter: state.app.filter
});
const mapDispatchToProps = dispatch => ({
  filterApps(filter, apps) {
    dispatch(filterApps(filter, apps));
  },
  getMdmApps() {
    dispatch(getMdmApps());
  },
  getReports() {
    dispatch(getReports());
  },
  getAppMenu() {
    dispatch(getAppMenu());
  },
  addTabToNav(tab) {
    dispatch(addTabToNav(tab));
  }
});
TabsApps = onClickOutside(TabsApps);
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(TabsApps));
