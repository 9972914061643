import { combineReducers } from "redux";
import accucastLEReducer from "components/accucast/components/updateLE/reducers/secondLevelLENavReducer";
import accucastPlanReducer from "components/accucast/components/updatePlan/reducers/secondLevelPlanNavReducer";
import reportListReducer from "components/reports/components/reportList/reducers/reportListReducer";
import pptCartReducer from "components/pptCart/pptCartReducer";
import powerBIReportReducer from "components/powerBI/components/report/reducers/reportReducer";
import staticContentReducer from "components/staticContent/reducers/staticContentReducer";
import topLevelNavReducer from "components/topLevelNav/reducers/topLevelNavReducer";
import authReducer from "reducers/authReducer";
import appReducer from "reducers/appReducer";
import filesReducer from "components/files/reducers/filesReducer";
import mdmReducer from "components/mdm/reducers/mdmReducer";
import tableauReducer from "components/tableau/reducers/tableauReducer";
import pbiMgmtReducer from "components/pbiMgmt/pbiMgmtReducer";
import accubaseReducer from "components/accubase/reducers/accubaseReducer";
import acculiftReducer from "components/acculift/reducers/acculiftReducer";
import promoOptReducer from "components/promoOpt/reducers/promoOptReducer";
import notificationsReducer from "components/notifications/notificationsReducer";
import userManagementReducer from "components/userManagement/reducers/userManagementReducer";
import groupReducer from "components/userManagement/reducers/groupReducer";
import rptSubscriptionReducer from "components/reportSubscriptions/reducers/reportSubscriptionReducer";

export default combineReducers({
  app: appReducer,
  auth: authReducer,
  accucastLE: accucastLEReducer,
  accucastPlan: accucastPlanReducer,
  reportList: reportListReducer,
  powerBIReport: powerBIReportReducer,
  scReport: staticContentReducer,
  topLevelNav: topLevelNavReducer,
  files: filesReducer,
  mdm: mdmReducer,
  tableau: tableauReducer,
  pptCart: pptCartReducer,
  notifications: notificationsReducer,
  pbiMgmt: pbiMgmtReducer,
  accubase: accubaseReducer,
  acculift: acculiftReducer,
  promo: promoOptReducer,
  userManagementReducer: userManagementReducer,
  groupReducer: groupReducer,
  rptSubscriptions: rptSubscriptionReducer
});
