import axios from "axios";

/* -------------------- INITIAL STATE -------------------- */
const INITIAL_STATE = {
  notifications: [],
  count: 0,
  loaded: false,
  loading: true,
  unviewedCount: 0
};

/* -------------------- ACTION TYPES -------------------- */
const SET_NOTIFICATION_SUCCESS = "SET_NOTIFICATION_SUCCESS";
const SET_NOTIFICATIONS = "SET_NOTIFICATIONS";
const CLEAR_COUNT = "CLEAR_COUNT";

/* -------------------- ACTION CREATORS -------------------- */

const setCreateNotificationSuccess = img => {
  return { type: SET_NOTIFICATION_SUCCESS, payload: img };
};

const setNotifications = notifications => {
  return { type: SET_NOTIFICATIONS, payload: notifications };
};
const clearCount = () => {
  return { type: CLEAR_COUNT };
};

/* -------------------- ASYNC ACTION CREATORS -------------------- */
export const createNotification = name => {
  return dispatch => {
    //dispatch(fetchReportListBegin());
    axios
      .post(
        `${process.env.REACT_APP_BIGHORN_SERVER}/api/app/createNotification`,
        {
          name
        },
        {
          headers: {
            ClientToken: localStorage.getItem("clientToken")
          }
        }
      )
      .then(response => {
        let notification = response.data.notification;
        //image.data = img.data;
        dispatch(setCreateNotificationSuccess(notification));
      })
      .catch(error => {
        //dispatch(fetchReportListError(error));
      });
  };
};
export const updateNotification = id => {
  return dispatch => {
    //dispatch(fetchReportListBegin());
    axios
      .post(
        `${process.env.REACT_APP_BIGHORN_SERVER}/api/app/updateNotification`,
        {
          notification: id
        },
        {
          headers: {
            ClientToken: localStorage.getItem("clientToken")
          }
        }
      )
      .then(response => {
        // let notification = response.data.notification;
        // //image.data = img.data;
        // dispatch(setCreateNotificationSuccess(notification));
        dispatch(getNotifications());
      })
      .catch(error => {
        //dispatch(fetchReportListError(error));
      });
  };
};

export const deleteNotifications = (notifications, item) => {
  return dispatch => {
    dispatch(setNotifications(notifications));
    notifications.forEach(item => {
      delete item.data;
    });
    axios
      .post(
        `${process.env.REACT_APP_BIGHORN_SERVER}/api/app/deleteNotifications`,
        {
          notifications,
          item
        },
        {
          headers: {
            ClientToken: localStorage.getItem("clientToken")
          }
        }
      )
      .then(response => {
        //console.log(response);
      })
      .catch(error => {
        //dispatch(fetchReportListError(error));
      });
  };
};
export const getNotifications = () => {
  return dispatch => {
    axios
      .post(
        `${process.env.REACT_APP_BIGHORN_SERVER}/api/app/getNotifications`,
        {},
        {
          headers: {
            ClientToken: localStorage.getItem("clientToken")
          }
        }
      )
      .then(response => {
        dispatch(setNotifications(response.data.notifications));
        //dispatch(setImageSuccess(img));
      })
      .catch(error => {
        //dispatch(fetchReportListError(error));
      });
  };
};
export const clearNotifications = () => {
  return dispatch => {
    axios
      .post(
        `${process.env.REACT_APP_BIGHORN_SERVER}/api/app/clearNotificationsForUser`,
        {},
        {
          headers: {
            ClientToken: localStorage.getItem("clientToken")
          }
        }
      )
      .then(response => {
        dispatch(clearCount());
      })
      .catch(error => {
        //dispatch(fetchReportListError(error));
      });
  };
};

/* -------------------- REDUCER -------------------- */
export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case SET_NOTIFICATION_SUCCESS:
      let newNotifys = state.notifications;

      newNotifys.push(action.payload);

      return {
        ...state,
        notifications: newNotifys,
        loading: false,
        loaded: true,
        count: state.count + 1,
        unviewedCount: state.unviewedCount + 1
      };
    case SET_NOTIFICATIONS:
      let newCount = action.payload.filter(item => {
        if (item.viewed === undefined || item.viwed === null) {
          return true;
        }
        return !item.viewed;
      });

      return {
        ...state,
        notifications: action.payload,
        loaded: true,
        loading: false,
        count: action.payload.length,
        unviewedCount: newCount.length
      };
    case CLEAR_COUNT:
      return {
        ...state,
        unviewedCount: 0
      };
    default:
      return state;
  }
};
