import axios from "axios";

/* -------------------- INITIAL STATE -------------------- */
const INITIAL_STATE = {
  loading: true,
  error: null,
  sasToken: ""
};

/* -------------------- ACTION TYPES -------------------- */

const FETCH_SAS_TOKEN_BEGIN = "FETCH_SAS_TOKEN_BEGIN";
const FETCH_SAS_TOKEN_SUCCESS = "FETCH_SAS_TOKEN_SUCCESS";
//const FETCH_SAS_TOKEN_FAILURE = "FETCH_SAS_TOKEN_FAILURE";

/* -------------------- ACTION CREATORS -------------------- */

const fetchSASTokenBegin = () => ({
  type: FETCH_SAS_TOKEN_BEGIN
});
const fetchSASTokenSuccess = sasToken => {
  return { type: FETCH_SAS_TOKEN_SUCCESS, payload: sasToken };
};
// const fetchSASTokenError = error => ({
//   type: FETCH_SAS_TOKEN_FAILURE,
//   payload: { error }
// });

/* -------------------- ASYNC ACTION CREATORS -------------------- */

export const getSASToken = (
  containerName,
  blobName = null,
  readOnly = true
) => {
  return dispatch => {
    dispatch(fetchSASTokenBegin());
    axios
      .post(
        `${process.env.REACT_APP_BIGHORN_SERVER}/api/files/getSasToken`,
        {
          containerName,
          blobName,
          readOnly
        },
        {
          headers: {
            ClientToken: localStorage.getItem("clientToken")
          }
        }
      )
      .then(result => {
        dispatch(fetchSASTokenSuccess(result.data.response));
      });
  };
};
// export const clearAccessToken = () => {
//   return dispatch => {
//     dispatch(clear_access_token());
//   };
// };

/* -------------------- REDUCER -------------------- */
export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    // case FETCH_ACCESS_TOKEN_FAILURE:
    //   return {
    //     ...state,
    //     loading: false,
    //     error: action.payload.error,
    //     reportConfig: []
    //   };
    case FETCH_SAS_TOKEN_BEGIN:
      return { ...state, loading: true, error: null };
    case FETCH_SAS_TOKEN_SUCCESS:
      return { ...state, sasToken: action.payload, loading: false };
    default:
      return state;
  }
};
