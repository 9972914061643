export function percentFormatter(params) {
  if (params.value === "-") {
    return params.value;
  } else {
    return formatNumber(params.value) + "%";
  }
}

export function usCurrencyFormatter(params) {
  return formatMoney(params.value);
}

export function usCurrencyWholeDollar(params) {
  return formatMoney(params.value, 0);
}

export function otherCurrencyFormatter(params) {
  return formatMoney(params.value, 2, ",");
}

export function formatFloatFormatter(params) {
  return formatFloat(params.value);
}

export function fixedDecimalFormatter(params) {
  return formatMoney(params.value); //use the money formatter to guarantee 2 decimals, just omit the $
}

function formatNumber(number) {
  // this puts commas into the number eg 1000 goes to 1,000,
  // i pulled this from stack overflow, i have no idea how it works

  return Math.floor(number)
    .toString()
    .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
}

function formatFloat(number) {
  // this puts commas into the number eg 1000 goes to 1,000,
  // i pulled this from stack overflow, i have no idea how it works
  if (number === undefined) {
    return 0;
  }
  return number.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
}

function formatMoney(amount, decimalCount = 2, decimal = ".", thousands = ",") {
  try {
    decimalCount = Math.abs(decimalCount);
    decimalCount = isNaN(decimalCount) ? 2 : decimalCount;

    const negativeSign = amount < 0 ? "-" : "";

    let i = parseInt(
      (amount = Math.abs(Number(amount) || 0).toFixed(decimalCount)),
      10
    ).toString();
    let j = i.length > 3 ? i.length % 3 : 0;

    return (
      negativeSign +
      (j ? i.substr(0, j) + thousands : "") +
      i.substr(j).replace(/(\d{3})(?=\d)/g, "$1" + thousands) +
      (decimalCount
        ? decimal +
          Math.abs(amount - i)
            .toFixed(decimalCount)
            .slice(2)
        : "")
    );
  } catch (e) {
    console.log(e);
  }
}

export function numberFormatter(params) {
  if (params.value !== null) {
    if (params.value === "-") {
      return params.value;
    } else {
      return formatNumber(params.value);
    }
  } else return "";
}

export function floatFormatter(params) {
  if (params.value) {
    let rValue = Number.parseFloat(params.value)
      .toFixed(3)
      .toString()
      .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
    return rValue;
  } else {
    return "";
  }
}

export function getRowClass(params) {
  var idx = params.node.rowIndex;
  var classes = [];
  switch (idx % 2) {
    case 0:
      classes.push("ta-even-row");
      break;
    case 1:
      classes.push("ta-odd-row");
      break;
    default:
      classes.push("");
      break;
  }

  return classes.join(" ");
}
