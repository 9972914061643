import React, { Component } from "react";
import { connect } from "react-redux";

import "bootstrap/dist/css/bootstrap.min.css";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import {
  getGroups,
  addUserGroup,
  addUserGroupSuccess
} from "../reducers/groupReducer";
//import { resetAllStatus } from "reducers/workspaceReducer";
import {
  getUsers,
  addNewUser,
  inviteUsers,
  inviteSpecifiedUsers,
  deleteUsers,
  addUserToGroup,
  updateUserGroups,
  graphUpdateUserGroups,
  filterUsers,
  clearToasts,
  updateUser
} from "../reducers/userManagementReducer";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-balham.css";
import "ag-grid-community";
import "./style.css";
import _ from "lodash";
import axios from "axios";

import { toast } from "react-toastify";
import validator from "validator";
import { addTabToNav } from "components/topLevelNav/reducers/topLevelNavReducer";
import { LicenseManager } from "ag-grid-enterprise";

LicenseManager.setLicenseKey(
  "Using_this_{AG_Grid}_Enterprise_key_{AG-065063}_in_excess_of_the_licence_granted_is_not_permitted___Please_report_misuse_to_legal@ag-grid.com___For_help_with_changing_this_key_please_contact_info@ag-grid.com___{TELUS_Agriculture_Solutions_Inc.}_is_granted_a_{Single_Application}_Developer_License_for_the_application_{TABSInsight}_only_for_{1}_Front-End_JavaScript_developer___All_Front-End_JavaScript_developers_working_on_{TABSInsight}_need_to_be_licensed___{TABSInsight}_has_been_granted_a_Deployment_License_Add-on_for_{1}_Production_Environment___This_key_works_with_{AG_Grid}_Enterprise_versions_released_before_{14_October_2025}____[v3]_[01]_MTc2MDM5NjQwMDAwMA==07fb06b515a537484f5c2ab1d7f57d4b"
);

// simple function cellRenderer, just returns back the name of the group
function GroupCellRenderer() {}
GroupCellRenderer.prototype.init = function(params) {
  this.eGui = document.createElement("span");
  var text = "";
  if (params.data.groups.length > 0) {
    text = params.data.groups.map(group => {
      return group.name;
    });
  }
  this.eGui.innerHTML = text.toString();
};
GroupCellRenderer.prototype.getGui = function() {
  return this.eGui;
};

var groupComparator = (item1, item2, node1, node2) => {
  if (node1 === undefined) {
    return item1;
  }
  var a = node1.data.groups[0].name.toLowerCase();
  var b = node2.data.groups[0].name.toLowerCase();
  return a > b ? 1 : a < b ? -1 : 0;
};

class UserManagement extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedFile: null,
      loaded: 0,
      client: this.props.clients,
      inputValue: "",
      showAddUserModal: false,
      showDeleteConfirmModal: false,
      showAssignGroupsModal: false,
      showGroupsModal: false,
      firstName: "",
      lastName: "",
      userName: "",
      groups: [],
      selectedUsers: [],
      invitedCheckRan: false,
      uninvited: false,
      columnDefs: [
        {
          headerName: "Username",
          field: "userName",
          checkboxSelection: true,
          sortable: true
        },
        {
          headerName: "First Name",
          field: "firstName",
          editable: true,
          sortable: true
        },
        {
          headerName: "Last Name",
          field: "lastName",
          editable: true,
          sortable: true
        },
        {
          headerName: "Groups",
          field: "group",
          cellRenderer: GroupCellRenderer,
          filter: "agTextColumnFilter",
          comparator: groupComparator,
          filterParams: {
            textCustomComparator: function(filter, value, filterText) {
              var filterTextLoweCase = filterText.toLowerCase();
              var valueLowerCase = value.toString().toLowerCase();
              switch (filter) {
                case "contains":
                  return valueLowerCase.indexOf(filterTextLoweCase) >= 0;
                case "notContains":
                  return valueLowerCase.indexOf(filterTextLoweCase) === -1;
                case "equals":
                  return valueLowerCase === filterTextLoweCase;
                case "notEqual":
                  return valueLowerCase !== filterTextLoweCase;
                case "startsWith":
                  return valueLowerCase.indexOf(filterTextLoweCase) === 0;
                case "endsWith":
                  var index = valueLowerCase.lastIndexOf(filterTextLoweCase);
                  return (
                    index >= 0 &&
                    index === valueLowerCase.length - filterTextLoweCase.length
                  );
                default:
                  // should never happen
                  console.warn("invalid filter type " + filter);
                  return false;
              }
            },

            valueGetter: function getter(params) {
              var text = "";
              if (params.data.groups.length > 0) {
                text = params.data.groups.map(group => {
                  return group.name;
                });
              }
              return text;
            }
          }
        },
        { headerName: "Status", field: "status" }
      ]
    };
    this.props.addTabToNav({
      title: "User Management",
      name: "User Management",
      id: "userManagement",
      type: "userManagement",
      path: "/userManagement",
      icon: "userManagement",
      activeChildren: []
    });
    this.handleClose = this.handleClose.bind(this);
    this.cellEditStopped = this.cellEditStopped.bind(this);
    this.updateInputValue = this.updateInputValue.bind(this);
    this.onRowSelected = this.onRowSelected.bind(this);
    this.addUser = this.addUser.bind(this);
    this.inviteUsers = _.debounce(this.inviteUsers.bind(this), 1000, {
      leading: true,
      trailing: false
    });
    this.deleteUsers = _.debounce(this.deleteUsers.bind(this), 1000, {
      leading: true,
      trailing: false
    });
    this.onGridReady = this.onGridReady.bind(this);
    this.updateGroups = this.updateGroups.bind(this);
    this.showAssignModal = this.showAssignModal.bind(this);
    this.handleSave = this.handleSave.bind(this);

    this.handleInputChange = this.handleInputChange.bind(this);
    this.cellValueChanged = this.cellValueChanged.bind(this);
  }

  componentDidMount() {
    this.props.getUsers();
    this.props.getGroups();
  }

  onInputChange(option) {
    return option.replace(/[\W_]+/g, "");
  }
  componentDidUpdate() {
    if (!this.props.loading && !this.state.invitedCheckRan) {
      let uninvited = this.props.users.filter(user => {
        return user.status === "Uninvited";
      });
      if (uninvited.length > 0) {
        this.setState({ invitedCheckRan: true, uninvited: true });
      }
    }
    if (this.props.inviteToastMessage != null) {
      if (this.props.inviteToastMessage.pass) {
        toast.success(this.props.inviteToastMessage.message);
      } else {
        toast.error(this.props.inviteToastMessage.message);
      }
      this.props.clearToasts();
    }
  }

  updateInputValue(evt, source = null) {
    if (source == null) {
      this.setState({
        inputValue: evt.target.value
      });
    } else {
      this.setState({
        [source]: evt.target.value
      });
    }
  }

  handleClose() {
    this.setState({
      showAddUserModal: false,
      showGroupsModal: false,
      inputValue: "",
      userName: "",
      firstName: "",
      lastName: "",
      groups: [],
      showDeleteConfirmModal: false,
      showAssignGroupsModal: false
    });
  }
  cellEditStopped(event) {
    const { users } = this.props;
    if (event.colDef.field === "lastName") {
      users[event.rowIndex].lastName = event.value;
    } else if (event.colDef.field === "firstName") {
      users[event.rowIndex].firstName = event.value;
    }
  }
  cellValueChanged(item) {
    var user = this.props.searchUsers[item.rowIndex];
    if (item.colDef.field === "firstName") {
      user.firstName = item.newValue;
    } else if (item.colDef.field === "lastName") {
      user.lastName = item.newValue;
    }

    this.props.updateUser(user);
  }

  onRowSelected(event, grid = "") {
    if (grid === "main") {
      let selectedUsers = this.state.selectedUsers;
      let runPush = true;
      if (event.node.selected) {
        this.state.selectedUsers.forEach(user => {
          if (user.id === event.data._id) {
            runPush = false;
          }
        });
        if (event.data._id && runPush)
          selectedUsers.push({
            id: event.data._id,
            graphId: event.data.graphId,
            name: event.data.userName,
            groups: event.data.groups,
            status: event.data.status
          });
      } else {
        selectedUsers = selectedUsers.filter(user => {
          return user.id !== event.data._id;
        });
      }
      this.setState({ selectedUsers });
    } else {
      let groups = this.state.groups;
      if (event.node.selected) {
        groups.push(event.data.id);
      } else {
        groups = groups.filter(group => {
          return group !== event.data.id;
        });
      }
      this.setState({ groups });
    }
  }

  addUser() {
    const { userName, lastName, firstName, groups } = this.state;
    if (userName === "") {
      window.alert("You need to specify a User Name");
    } else if (!validator.isEmail(userName)) {
      window.alert("Your User Name is required to be a Email Address");
    } else {
      this.props.addNewUser({
        userName,
        lastName,
        firstName,
        groups
      });
      // eslint-disable-next-line quotes

      this.setState({ invitedCheckRan: false, uninvited: true });
      this.handleClose();
    }
  }
  updateGroups() {
    const { groups } = this.state;
    let selectedUsers = this.state.selectedUsers;
    let graphUsers = [];
    let nonGraphUsers = [];

    selectedUsers = selectedUsers.map(sUser => {
      sUser.groups = groups;
      return sUser;
    });

    selectedUsers.forEach(sUser => {
      if (sUser.status === "Uninvited") {
        nonGraphUsers.push(sUser);
      } else {
        graphUsers.push(sUser);
      }
    });
    if (nonGraphUsers.length > 0) {
      this.props.updateUserGroups(nonGraphUsers);
    }
    if (graphUsers.length > 0) {
      this.props.graphUpdateUserGroups(graphUsers);
    }
    toast.success("Selected Users updated.");
    this.setState({ selectedUsers: [] });
    this.handleClose();
  }
  deleteUsers() {
    const { selectedUsers } = this.state;
    if (selectedUsers.length > 0) {
      this.props.deleteUsers(selectedUsers);

      this.setState({
        selectedUsers: [],
        invitedCheckRan: false,
        uninvited: false
      });
      this.handleClose();
    }
  }
  inviteUsers() {
    if (this.state.selectedUsers.length > 0) {
      this.props.inviteSpecifiedUsers(this.state.selectedUsers);
      // toast.success("Specified Users invited.");
    } else {
      this.props.inviteUsers();
      // toast.success("All Users invited.");
    }

    this.setState({ invitedCheckRan: true, uninvited: false });
  }

  addUserModal() {
    const { showAddUserModal } = this.state;
    return (
      <Modal
        className="user-mgmt-dialog"
        style={{ height: "80%" }}
        isOpen={showAddUserModal}
      >
        <ModalHeader>Add User</ModalHeader>

        <ModalBody>
          <div style={{ display: "flex" }}>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                marginRight: "8px"
              }}
            >
              <div style={{ width: "195px" }}>Username (email address)</div>
              <input
                ref={this.userName}
                value={this.state.userName}
                onChange={evt => this.updateInputValue(evt, "userName")}
              />
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                marginRight: "8px"
              }}
            >
              <div>First Name</div>
              <input
                ref={this.firstName}
                value={this.state.firstName}
                onChange={evt => this.updateInputValue(evt, "firstName")}
              />
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                overflow: "auto"
              }}
            >
              <div>Last Name</div>
              <input
                ref={this.lastName}
                value={this.state.lastName}
                onChange={evt => this.updateInputValue(evt, "lastName")}
              />
            </div>
          </div>
          <div
            style={{
              height: "85%",
              display: "flex",
              flexDirection: "column",
              marginTop: "16px"
            }}
          >
            <div style={{ marginBottom: "8px" }}>Assign User Groups</div>
            <div
              style={{ height: "100%", width: "100%" }}
              className="ag-theme-balham"
            >
              <AgGridReact
                columnDefs={[
                  {
                    headerName: "Group",
                    field: "name",
                    checkboxSelection: true
                  }
                ]}
                rowData={this.props.userGroups}
                rowSelection={"multiple"}
                enableSorting={true}
                enableFilter={true}
                onRowSelected={this.onRowSelected}
                onGridReady={event => this.onGridReady(event)}
              />
            </div>
          </div>
        </ModalBody>

        <ModalFooter>
          <Button bsstyle="primary" onClick={this.addUser}>
            Save changes
          </Button>
          <Button onClick={this.handleClose}>Close</Button>
        </ModalFooter>
      </Modal>
    );
  }

  deleteConfirmModal() {
    const { showDeleteConfirmModal } = this.state;
    return (
      <Modal isOpen={showDeleteConfirmModal}>
        <ModalHeader>Delete Users</ModalHeader>

        <ModalBody>
          <div style={{ display: "flex" }}>
            Are you sure you want to delete the selected user(s)?
          </div>
        </ModalBody>

        <ModalFooter>
          <Button bsstyle="primary" onClick={this.deleteUsers}>
            Delete
          </Button>
          <Button onClick={this.handleClose}>Close</Button>
        </ModalFooter>
      </Modal>
    );
  }

  onGridReady(event, populateChecks = false) {
    var selectedUsers = this.state.selectedUsers;
    if (populateChecks) {
      event.api.forEachNode(node => {
        selectedUsers[0].groups.forEach(group => {
          if (node.data.id === group._id) {
            node.setSelected(true);
            return;
          } else if (node.data.id === group) {
            node.setSelected(true);
            return;
          }
        });
      }, this);
    }
    event.api.sizeColumnsToFit();
  }
  showAssignModal(event) {
    if (this.state.selectedUsers.length >= 1) {
      this.setState({ showAssignGroupsModal: true });
    }
  }

  assignGroupsModal() {
    const { showAssignGroupsModal } = this.state;
    return (
      <Modal style={{ height: "80%" }} isOpen={showAssignGroupsModal}>
        <ModalHeader>Assign User Groups</ModalHeader>

        <ModalBody>
          <div
            style={{ height: "100%", width: "100%" }}
            className="ag-theme-balham"
          >
            <AgGridReact
              columnDefs={[
                {
                  headerName: "Group",
                  field: "name",
                  checkboxSelection: true
                }
              ]}
              rowData={this.props.userGroups}
              rowSelection={"multiple"}
              enableSorting={true}
              enableFilter={true}
              onRowSelected={this.onRowSelected}
              onGridReady={event => this.onGridReady(event, true)}
            />
          </div>
        </ModalBody>

        <ModalFooter>
          <Button bsstyle="primary" onClick={this.updateGroups}>
            Save changes
          </Button>
          <Button onClick={this.handleClose}>Close</Button>
        </ModalFooter>
      </Modal>
    );
  }
  addGroupModal() {
    const { showGroupsModal } = this.state;
    return (
      <Modal isOpen={showGroupsModal}>
        <ModalHeader>Add User Group</ModalHeader>

        <ModalBody>
          <input
            value={this.state.inputValue}
            onChange={evt => this.updateInputValue(evt)}
          />
        </ModalBody>

        <ModalFooter>
          <Button bsstyle="primary" onClick={this.handleSave}>
            Save changes
          </Button>
          <Button onClick={this.handleClose}>Close</Button>
        </ModalFooter>
      </Modal>
    );
  }
  handleSave() {
    var value = this.state.inputValue;
    axios
      .post(
        `${process.env.REACT_APP_BIGHORN_SERVER}/api/userManagement/addGroup`,
        {
          groupName: value
          //idToken: localStorage.getItem("id_token")
        },
        {
          headers: {
            //Authorization: "Bearer " + localStorage.getItem("access_token")
            ClientToken: localStorage.getItem("clientToken")
          }
        }
      )
      .then(response => {
        // eslint-disable-next-line quotes
        toast.success('Group "' + value + '" added.');
        this.props.addUserGroupSuccess(response.data.userGroup);
      })
      .catch(error => {
        // eslint-disable-next-line quotes
        toast.error('Group "' + value + '" Already Exists');
      });

    this.setState({ showGroupsModal: false, inputValue: "" });
  }

  handleInputChange() {
    this.props.filterUsers(this.userSearch.value, this.props.users);
  }

  render() {
    const { searchUsers } = this.props;
    const { uninvited } = this.state;

    return (
      <div style={{ display: "flex", height: "74%", width: "77%" }}>
        {this.addUserModal()}
        {this.deleteConfirmModal()}
        {this.assignGroupsModal()}
        {this.addGroupModal()}
        <div style={{ width: "100%" }}>
          <div style={{ height: "100%" }}>
            <div style={{ height: "100%" }} className="ovis-tab-view">
              <div style={{ height: "100%" }}>
                <div
                  className="client"
                  style={{ display: "flex", paddingBottom: "20px" }}
                >
                  <div className="app-page-title">User Management</div>
                  <div className="spacer" style={{ width: "10%" }} />

                  <div
                    style={{
                      marginLeft: "auto",
                      display: "flex",
                      flexDirection: "row"
                    }}
                  >
                    {uninvited && (
                      <div style={{ marginRight: "10px", marginTop: "4px" }}>
                        You have uninvited users:
                      </div>
                    )}
                    {!this.props.isInviting ? (
                      <div
                        style={{ marginLeft: "auto" }}
                        className="ta-button ta-button-secondary"
                        onClick={this.inviteUsers}
                      >
                        Invite Users
                      </div>
                    ) : (
                      <div
                        style={{ marginLeft: "auto" }}
                        className="ta-button ta-button-disabled"
                      >
                        Inviting
                        <span style={{ color: "white" }} className="dot">
                          .
                        </span>
                        <span className="dot">.</span>
                        <span className="dot">.</span>
                      </div>
                    )}
                  </div>
                </div>
                <div className="user-group-title-div">
                  <input
                    className="input-box"
                    placeholder="search"
                    ref={input => (this.userSearch = input)}
                    onChange={this.handleInputChange}
                    style={{ width: "20%", marginTop: "auto" }}
                  />
                  <div style={{ marginLeft: "auto", display: "flex" }}>
                    <div
                      onClick={event => {
                        this.setState({ showAddUserModal: true });
                      }}
                      className="ta-button ta-button-primary user-button"
                      style={{ marginLeft: "8px", marginRight: "8px" }}
                    >
                      +Add User
                    </div>

                    <div
                      onClick={event => {
                        this.showAssignModal(event);
                      }}
                      className="ta-button ta-button-secondary user-button"
                      style={{ marginRight: "8px" }}
                    >
                      Assign Group
                    </div>

                    <div
                      onClick={event => {
                        if (this.state.selectedUsers.length > 0) {
                          this.setState({ showDeleteConfirmModal: true });
                        }
                      }}
                      className="ta-button ta-button-danger user-button"
                      style={{}}
                    >
                      Delete User
                    </div>
                  </div>
                </div>
                <div
                  className="ag-theme-balham"
                  style={{
                    height: "99%",
                    width: "100%"
                  }}
                >
                  <AgGridReact
                    columnDefs={this.state.columnDefs}
                    rowData={searchUsers}
                    enableSorting={true}
                    enableFilter={true}
                    onCellEditingStopped={this.cellEditStopped}
                    deltaRowDataMode={false}
                    rowSelection={"multiple"}
                    defaultColDef={{
                      resizable: true,
                      comparator: function(a, b) {
                        if (typeof a === "string") {
                          return a.localeCompare(b);
                        } else {
                          return a > b ? 1 : a < b ? -1 : 0;
                        }
                      }
                    }}
                    getRowNodeId={data => {
                      return data._id;
                    }}
                    onRowSelected={event => {
                      this.onRowSelected(event, "main");
                    }}
                    onCellValueChanged={event => {
                      this.cellValueChanged(event);
                    }}
                    components={{ GroupCellRenderer: GroupCellRenderer }}
                    onGridReady={event => this.onGridReady(event)}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  users: state.userManagementReducer.users,
  inviteToastMessage: state.userManagementReducer.inviteToastMessage,
  searchUsers: state.userManagementReducer.searchUsers,
  loading: state.userManagementReducer.loading,
  isInviting: state.userManagementReducer.isInviting,
  userGroups: state.groupReducer.userGroups
});

const mapDispatchToProps = dispatch => ({
  getUsers(client) {
    dispatch(getUsers(client));
  },
  getGroups() {
    dispatch(getGroups());
  },
  addNewUser(client, user) {
    dispatch(addNewUser(client, user));
  },
  addUserToGroup(client, user) {
    dispatch(addUserToGroup(client, user));
  },
  graphUpdateUserGroups(client, user) {
    dispatch(graphUpdateUserGroups(client, user));
  },
  updateUserGroups(client, user) {
    dispatch(updateUserGroups(client, user));
  },
  inviteUsers(client) {
    dispatch(inviteUsers(client));
  },
  inviteSpecifiedUsers(client, users) {
    dispatch(inviteSpecifiedUsers(client, users));
  },
  deleteUsers(client, users) {
    dispatch(deleteUsers(client, users));
  },
  addUserGroup(client, groupName) {
    dispatch(addUserGroup(client, groupName));
  },
  filterUsers(filter, users) {
    dispatch(filterUsers(filter, users));
  },
  addUserGroupSuccess(group) {
    dispatch(addUserGroupSuccess(group));
  },
  clearToasts() {
    dispatch(clearToasts());
  },
  addTabToNav(tab) {
    dispatch(addTabToNav(tab));
  },
  updateUser(user) {
    dispatch(updateUser(user));
  }
});

export default connect(mapStateToProps, mapDispatchToProps)(UserManagement);
