import React, { Component } from "react";
import "components/tabsApps/style/style.css";
import onClickOutside from "react-onclickoutside";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { PowerPointIcon, DownloadIcon } from "lib/icons";
import {
  saveCroppedImage,
  getSlides,
  updateSlidesOrder,
  deleteSlides
} from "./pptCartReducer";
import PptxGenJS from "pptxgenjs";
import "components/profile/style/style.css";
import "./style.css";
import { DragDropContext, Droppable } from "react-beautiful-dnd";
import { CartItem } from "components/pptCart/components/cartItem";
import { OverlayTrigger, Tooltip } from "react-bootstrap";

const grid = 8;

const getListStyle = isDraggingOver => ({
  background: isDraggingOver ? "white" : "white",
  padding: grid,
  paddingLeft: "0px",
  paddingRight: "0px",
  width: "100%",
  maxHeight: "580px",
  overflowY: "scroll"
});

// a little function to help us with reordering the result
const reorder = (list, startIndex, endIndex) => {
  var result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);
  result = result.map((item, index) => {
    item.order = index;
    return item;
  });
  return result;
};

class PPTCart extends Component {
  constructor(props) {
    super(props);
    this.state = {
      listOpen: false
      //items: [{ name: "Download To Powerpoint",icon:"DownloadIcon" action: "logout" }]
    };
    //this.login = this.login().bind(this);
    this.downloadPPT = this.downloadPPT.bind(this);
    this.onDragEnd = this.onDragEnd.bind(this);
    this.deleteHandler = this.deleteHandler.bind(this);
    this.deleteAllHandler = this.deleteAllHandler.bind(this);
    this.checkForIframeClick = this.checkForIframeClick.bind(this);
    window.addEventListener("blur", this.checkForIframeClick, false);
  }
  componentDidMount() {}
  componentDidUpdate() {
    const { apps, loaded } = this.props;
    if (apps.length > 0 && !loaded) {
      this.props.getSlides();
    }
  }
  componentWillUnmount() {
    window.removeEventListener("blur", this.checkForIframeClick, false);
  }
  checkForIframeClick = event => {
    if (document.activeElement.tagName === "IFRAME") {
      this.setState({
        listOpen: false
      });
    }
  };

  handleClickOutside() {
    this.setState({
      listOpen: false
    });
  }
  downloadPPT() {
    const { slides } = this.props;
    let dpi_y = document.getElementById("testdiv").offsetHeight;
    let dpi_x = document.getElementById("testdiv").offsetWidth;
    var pptx = new PptxGenJS();

    slides.forEach(result => {
      let height, width, options, x, y;

      if (result.type !== undefined && result.type === "pbi") {
        height = result.h;
        width = result.w;
        options = {
          data: result.data,
          x: 0,
          y: 0,
          h: "100%",
          w: "100%",
          sizing: { type: "contain" }
        };
        let slide = pptx.addNewSlide();
        slide.addImage(options);
      } else {
        const max_width = 10; //slide width (in) for 16:9
        const max_height = 5.625;  //slide height (in) for 16:9
        const scale_factor = ("scaleFactor" in result) ? result.scaleFactor : 1;
        x = result.x / dpi_x;
        y = result.y / dpi_y;
        height = result.h / dpi_y;
        width = result.w / dpi_y;
        let size_width = 0;
        let size_height = 0;
        if (width > max_width || height > max_height) {
          //check to see if the image is too big for our slide size
          let aspectRatio = (width/height);
          if (aspectRatio === 0 || !aspectRatio) { 
            aspectRatio = 1;
          }
          //figure out which dimension is most out-of-bounds
          if (height/max_height > width/max_width) {
            //height is furthest out of bounds so use it as reference to scale
            size_height = height / (height / max_height);
            size_width = size_height * aspectRatio; //multiply by aspectRatio because we computed aspectRatio as width/height
          } else if (height/max_height < width/max_width) {
            //width is furthest out of bounds so use it as reference to scale
            size_width = width / (width / max_width);
            size_height = size_width / aspectRatio; //divide by aspectRatio because we computed aspectRatio as width/height
          } else {
            //ignore the aspect ratio if perfect square
            size_height = height / (height / max_height);
            size_width = width / (width / max_width);
          }
        } else {
          //don't scale if it is within slide bounds
          size_width = width;
          size_height = height;
        }
        size_width = size_width * scale_factor;
        size_height = size_height * scale_factor;
        
        if (!result.useManualCrop || result.userCrop === true) { //if were not using manual settings, center the image, including userCrop
          x = (max_width - size_width) / 2; 
          y = (max_height - size_height) / 2;  
        }
        options = {
          data: result.data,
          x,
          y,
          w: size_width,
          h: size_height,
        };
        let slide = pptx.addNewSlide();
        slide.addImage(options);
      }
    });

    pptx.save("TABS-PPT-Export");
  }
  toggleList() {
    this.setState(prevState => ({
      listOpen: !prevState.listOpen
    }));
  }
  onDragEnd = result => {
    const { destination, source } = result;

    if (!destination) {
      return;
    }
    // user drop the item back into the position that is started, so we don't need to do anything
    if (
      destination.droppableId === source.droppableId &&
      destination.index === source.index
    ) {
      return;
    }
    const slides = reorder(
      this.props.slides,
      result.source.index,
      result.destination.index
    );
    this.props.updateSlidesOrder(slides);
    // this.setState({
    //   items
    // });
  };
  deleteHandler(item) {
    let slides = Array.from(this.props.slides);
    slides.splice(item.order, 1);
    slides = slides.map((item, index) => {
      item.order = index;
      return item;
    });
    this.props.deleteSlides(slides, item);
    //console.log(item);
  }
  deleteAllHandler() {
    this.props.deleteSlides([]);
  }

  render() {
    const { listOpen } = this.state;
    const { slides } = this.props;
    let showPPTCart = false;
    let proceed = this.props.apps.filter(application => {
      return application.type === "tableau" || application.type === "powerBI";
    });
    if (proceed.length >= 1) {
      showPPTCart = true;
    }
    if (showPPTCart) {
      return (
        <div className={"flex-container"}>
          <OverlayTrigger
            key={"ppt-tip"}
            placement={"left"}
            overlay={<Tooltip id={"ppt-tip"}>Manage PowerPoint Deck</Tooltip>}
          >
            <div className="ppt-cart-parent" onClick={() => this.toggleList()}>
              {this.state.listOpen ? (
                <div className="ppt-cart-selected">
                  <PowerPointIcon
                    color={"white"}
                    height={"20"}
                    className="power-point-icon-selected"
                  />
                </div>
              ) : (
                <div className="ppt-cart">
                  <PowerPointIcon
                    color={"rgba(88, 89, 91, 1)"}
                    height={"20"}
                    className="power-point-icon"
                  />
                </div>
              )}
              {this.props.count > 0 ? (
                <span className="ppt-cart-badge">{this.props.count}</span>
              ) : null}
            </div>
          </OverlayTrigger>

          {listOpen && (
            <div className="ppt-cart-dd-items">
              <div className="ppt-btn-container">
                <div
                  className="ppt-download-button"
                  onClick={() => this.downloadPPT()}
                >
                  <DownloadIcon
                    height={"18"}
                    // color={"rgba(88, 89, 91, 1)"}
                    color={"rgba(255, 255, 255, 1)"}
                    className="download-ppt-icon"
                    onClick={() => this.downloadPPT()}
                  />
                  Download PowerPoint
                </div>
              </div>
              <div className="slides-container">
                <div className="slides-header">
                  <div>Slides</div>
                  <div className="clear-all" onClick={this.deleteAllHandler}>
                    clear all
                  </div>
                </div>
                <DragDropContext
                  onDragEnd={this.onDragEnd}
                  className="slide-list"
                >
                  <Droppable droppableId="droppable">
                    {(provided, snapshot) => (
                      <div
                        ref={provided.innerRef}
                        style={getListStyle(snapshot.isDraggingOver)}
                      >
                        {slides.map((item, index) => (
                          <CartItem
                            deleteHandler={this.deleteHandler}
                            key={item.order}
                            item={item}
                            index={index}
                          />
                        ))}
                        {provided.placeholder}
                      </div>
                    )}
                  </Droppable>
                </DragDropContext>
              </div>
            </div>
          )}
        </div>
      );
    } else {
      return <div></div>;
    }
  }
}

const mapStateToProps = state => ({
  slides: state.pptCart.slides,
  count: state.pptCart.count,
  apps: state.app.apps,
  loading: state.pptCart.loading,
  isAuthed: state.auth.isAuthed,
  loaded: state.pptCart.loaded
});
const mapDispatchToProps = dispatch => ({
  saveCroppedImage(img) {
    dispatch(saveCroppedImage(img));
  },
  getSlides() {
    dispatch(getSlides());
  },
  updateSlidesOrder(slides) {
    dispatch(updateSlidesOrder(slides));
  },
  deleteSlides(slides, item = null) {
    dispatch(deleteSlides(slides, item));
  }
});

PPTCart = onClickOutside(PPTCart);
export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(PPTCart)
);
