import React, { Component } from "react";

import NumericEditor from "components/accucast/components/editors/numericEditor";
import TotalRenderer from "components/accucast/components/renderers/totalRenderer";
import { AccucastGrid } from "components/accucast/components/lib/accucastGrid";
import {
  percentFormatter,
  getRowClass
} from "components/accucast/components/formatters";
import { totalValueGetter } from "components/accucast/components/getters";
import { numberNewValueHandler } from "components/accucast/components/handlers";
import { CustomHeaderRenderer } from "components/accucast/components/renderers/customHeaderRenderer";
import { EditableCellFloatRenderer } from "components/accucast/components/renderers/editableCellFloatRenderer";
import { EditableCellPercentRenderer } from "components/accucast/components/renderers/editableCellPercentRenderer";
import axios from "axios";

export class LeWeighting extends Component {
  constructor(props) {
    super(props);
    this.state = {
      columnDefs: [],
      rowData: this.createRows(),
      frameworkComponents: {
        numericEditor: NumericEditor,
        totalRenderer: TotalRenderer,
        customHeaderRenderer: CustomHeaderRenderer,
        editableCellFloatRenderer: EditableCellFloatRenderer,
        editableCellPercentRenderer: EditableCellPercentRenderer
      }
    };
  }
  componentDidMount() {
    var baseConfig = [
      {
        id: 0,
        headerName: "Brand Size",
        pinned: "left",
        field: "bs",
        tooltipField: "bs",
        suppressMovable: true,
        menuTabs: ["filterMenuTab"]
      },
      {
        id: 1,
        headerName: "Week 23 %D06/10/18",
        width: 125,
        cellClass: "cellCenterClass",
        headerComponent: "customHeaderRenderer",
        headerClass: "headerSize",
        field: "wk23",
        suppressMovable: true,
        menuTabs: ["filterMenuTab"],
        cellEditor: "numericEditor",
        editable: true,
        valueFormatter: percentFormatter,
        newValueHandler: numberNewValueHandler,
        cellRenderer: "editableCellPercentRenderer"
      },
      {
        id: 2,
        headerName: "Week 24 %D06/17/18",
        width: 125,
        cellClass: "cellCenterClass",
        field: "wk24",
        headerComponent: "customHeaderRenderer",
        headerClass: "headerSize",
        suppressMovable: true,
        menuTabs: ["filterMenuTab"],
        cellEditor: "numericEditor",
        editable: true,
        valueFormatter: percentFormatter,
        newValueHandler: numberNewValueHandler,
        cellRenderer: "editableCellPercentRenderer"
      },
      {
        id: 3,
        headerName: "Week 25 %D06/24/18",
        width: 125,
        cellClass: "cellCenterClass",
        headerComponent: "customHeaderRenderer",
        headerClass: "headerSize",
        field: "wk25",
        suppressMovable: true,
        menuTabs: ["filterMenuTab"],
        cellEditor: "numericEditor",
        editable: true,
        valueFormatter: percentFormatter,
        newValueHandler: numberNewValueHandler,
        cellRenderer: "editableCellPercentRenderer"
      },
      {
        id: 4,
        headerName: "Week 26 %D07/01/18",
        width: 125,
        cellClass: "cellCenterClass",
        field: "wk26",
        headerComponent: "customHeaderRenderer",
        headerClass: "headerSize",
        suppressMovable: true,
        menuTabs: ["filterMenuTab"],
        cellEditor: "numericEditor",
        editable: true,
        valueFormatter: percentFormatter,
        newValueHandler: numberNewValueHandler,
        cellRenderer: "editableCellPercentRenderer"
      },
      {
        id: 5,
        headerName: "Week 27 %D07/08/18",
        width: 125,
        cellClass: "cellCenterClass",
        field: "wk27",
        headerComponent: "customHeaderRenderer",
        headerClass: "headerSize",
        suppressMovable: true,
        menuTabs: ["filterMenuTab"],
        cellEditor: "numericEditor",
        editable: true,
        valueFormatter: percentFormatter,
        newValueHandler: numberNewValueHandler,
        cellRenderer: "editableCellPercentRenderer"
      },
      {
        id: 6,
        headerName: "Week 28 %D07/15/18",
        width: 125,
        cellClass: "cellCenterClass",
        field: "wk28",
        headerComponent: "customHeaderRenderer",
        headerClass: "headerSize",
        suppressMovable: true,
        menuTabs: ["filterMenuTab"],
        cellEditor: "numericEditor",
        editable: true,
        valueFormatter: percentFormatter,
        newValueHandler: numberNewValueHandler,
        cellRenderer: "editableCellPercentRenderer"
      },
      {
        id: 7,
        headerName: "Total",
        field: "total",
        width: 125,
        cellClass: "cellCenterClass",
        headerClass: "totalClass",
        suppressMovable: true,
        menuTabs: ["filterMenuTab"],
        valueGetter: totalValueGetter,
        cellRenderer: "totalRenderer"
      }
    ];
    axios
      .get("http://localhost:4000/api/accucast/config/leWeighting", {
        headers: {
          ClientToken: localStorage.getItem("clientToken")
        }
      })
      .then(res => {
        let config = baseConfig.map(conf => {
          let id = parseInt(conf.id, 10);
          switch (id) {
            case 0:
              conf.cellClassRules = res.data.config[id].cellClassRules;
              return conf;
            case 1:
            case 2:
            case 3:
            case 4:
            case 5:
            case 6:
              conf.headerName = res.data.config[id].headerName;
              conf.field = res.data.config[id].field;
              conf.cellClassRules = res.data.config[id].cellClassRules;
              return conf;
            case 7:
              conf.headerName = res.data.config[id].headerName;
              conf.field = res.data.config[id].field;
              return conf;
            default:
              return conf;
          }
        });
        this.setState({ columnDefs: config });
      });
  }
  createRows = () => {
    let rows = [];
    rows.push({
      bs: "Bar",
      wk23: "0",
      wk24: "0",
      wk25: "25",
      wk26: "25",
      wk27: "25",
      wk28: "25"
    });
    rows.push({
      bs: "Kit",
      wk23: "0",
      wk24: "0",
      wk25: "20",
      wk26: "25",
      wk27: "30",
      wk28: "25"
    });
    rows.push({
      bs: "Meal",
      wk23: "0",
      wk24: "0",
      wk25: "15",
      wk26: "25",
      wk27: "25",
      wk28: "35"
    });
    rows.push({
      bs: "Salty Snack",
      wk23: "0",
      wk24: "0",
      wk25: "10",
      wk26: "30",
      wk27: "35",
      wk28: "25"
    });
    rows.push({
      bs: "Shake",
      wk23: "0",
      wk24: "0",
      wk25: "25",
      wk26: "25",
      wk27: "25",
      wk28: "25"
    });

    return rows;
  };

  render() {
    return (
      <div style={{ height: "100%" }}>
        <AccucastGrid
          columnDefs={this.state.columnDefs}
          rowData={this.state.rowData}
          getRowClass={getRowClass}
          frameworkComponents={this.state.frameworkComponents}
        />
      </div>
    );
  }
}
