import {
  CUME_OVER_UNDER,
  INCREMENTAL_LIFT_UNITS,
  SHIP_UNITS
} from "../constants";

var SortedArray = require("sorted-array");
export function totalValueGetter(params) {
  var total = 0;
  var filtered = new SortedArray([]);
  for (var key in params.data) {
    //figure out what (wk) fields are in the grid
    if (key.match(/^(wk)..$/)) filtered.insert(key.split("wk")[1]);
  }
  filtered.array.forEach(item => {
    total = total + parseInt(params.data["wk" + item], 10);
  });
  return total;
}

export function incrementalLiftGetter(params) {
  if (params.data.type === INCREMENTAL_LIFT_UNITS) {
    var row = parseInt(params.node.id, 10);
    var currentCol = params.colDef.field;
    let bupsw = parseFloat(params.api.getRowNode(row - 3).data[currentCol], 10);
    let promo_stores = parseInt(
      params.api.getRowNode(row - 2).data[currentCol],
      10
    );
    let incremental_lift = parseFloat(
      params.api.getRowNode(row - 1).data[currentCol],
      10
    );
    return (bupsw * promo_stores * incremental_lift).toString();
  }
  return params.data[params.colDef.field];
}

export function shipmentGetter(params) {
  var row;
  if (params.data.type === CUME_OVER_UNDER) {
    var filtered = new SortedArray([]);
    for (var key in params.data) {
      //figure out what (wk) fields are in the grid
      if (key.match(/^(wk)../)) filtered.insert(key.split("wk")[1]);
    }
    var currentColInt = parseInt(params.colDef.field.split("wk")[1], 10);
    var first = parseInt(filtered.array[0], 10);
    var last = parseInt(filtered.array[filtered.array.length - 1], 10);
    row = parseInt(params.node.id, 10);
    if (currentColInt === first) {
      //need to use the cache because the grid has not been updated yet
      return params.node.valueCache.getValue(
        params.api.getRowNode(row - 2),
        "wk" + first
      );
    } else if (currentColInt <= last) {
      let refColumn = currentColInt - 1;
      let lastColumnValue = parseInt(
        params.node.valueCache.getValue(params.node, "wk" + refColumn),
        10
      );
      let overUnderValue = parseInt(
        params.node.valueCache.getValue(
          params.api.getRowNode(row - 2),
          "wk" + currentColInt
        ),
        10
      );
      return (lastColumnValue + overUnderValue).toString();
    } else {
      return params.data[params.colDef.field];
    }
  } else if (params.data.type === SHIP_UNITS) {
    row = parseInt(params.node.id, 10);
    var currentCol = params.colDef.field;
    let overUnder = parseInt(
      params.api.getRowNode(row - 1).data[currentCol],
      10
    );
    let posUnits = parseInt(
      params.api.getRowNode(row - 2).data[currentCol],
      10
    );
    return (posUnits + overUnder).toString();
  } else {
    return params.data[params.colDef.field];
  }
}
