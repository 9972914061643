import React, { Component } from "react";
import { connect } from "react-redux";

import axios from "axios";

import "./style.css";

class NoAuthFileShare extends Component {
  constructor(props) {
    super(props);
    this.state = {
      notDownloaded: true,
      fileName: ""
    };
  }
  componentDidMount() {
    let qFileGuid, qFileId;
    if (this.props.fileGuid !== undefined && this.props.fileGuid !== "") {
      qFileGuid = this.props.fileGuid;
      qFileId = this.props.fileId;
    } else {
      qFileGuid = this.props.history.location.hash.split("#")[1].split("+")[1];
      qFileId = this.props.history.location.hash.split("#")[1].split("+")[0];
    }
    axios
      .post(
        `${process.env.REACT_APP_BIGHORN_SERVER}/api/files/getDownloadInfo`,
        {
          fileGuid: qFileGuid,
          fileId: qFileId
        },
        {
          headers: {
            ClientToken: localStorage.getItem("clientToken")
          }
        }
      )
      .then(res => {
        this.setState({ fileName: res.data.fileName });
      });
  }
  componentDidUpdate() {
    let qFileGuid, qFileId;
    if (this.props.fileGuid !== undefined && this.props.fileGuid !== "") {
      qFileGuid = this.props.fileGuid;
      qFileId = this.props.fileId;
    } else {
      qFileGuid = this.props.history.location.hash.split("#")[1].split("+")[1];
      qFileId = this.props.history.location.hash.split("#")[1].split("+")[0];
    }
    if (qFileGuid && this.state.notDownloaded) {
      axios
        .post(
          `${process.env.REACT_APP_BIGHORN_SERVER}/api/files/downloadFileShare`,
          {
            fileGuid: qFileGuid,
            fileId: qFileId
          },
          {
            headers: {
              ClientToken: localStorage.getItem("clientToken")
            },
            responseType: "arraybuffer"
          }
        )
        .then(res => {
          console.log(res.headers);
          const fileName = decodeURIComponent(res.headers["content-disposition"].split("=")[1]);
          const contentType = res.headers["content-type"] || "application/octet-stream"; // get content-type from response headers or set default value
          console.log("CONTENT TYPE " + contentType);
          const blob = new Blob([res.data], { type: contentType }); // Create Blob from arraybuffer response
          const url = window.URL.createObjectURL(blob);
          const link = document.createElement("a");
          link.href = url;

          link.setAttribute("download", fileName);
          document.body.appendChild(link);
          link.click();
        });
      this.setState({ notDownloaded: false });
    }
  }

  render() {
    if (this.props.error !== null) {
      return (
        <div style={{ display: "flex", justifyContent: "center", marginTop: "50px" }}>
          <div>File Share Error!</div>
        </div>
      );
    } else {
      return (
        <div className="no-auth-file-share" style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
          <div style={{ marginTop: "30px", fontSize: "30px" }}>Downloading File...</div>
          <div>{this.state.fileName}</div>
          <div style={{ marginTop: "40px", fontSize: "20px" }}>Download didn't start?</div>
          <div
            className="download-link"
            style={{}}
            onClick={() => {
              this.setState({ notDownloaded: true });
            }}>
            Click here to download
          </div>
        </div>
      );
    }
  }
}

const mapStateToProps = state => ({
  fileId: state.app.fileId,
  fileGuid: state.app.fileGuid,
  error: state.app.error
  // reportSubLoading: state.rptSubscriptions.loading
});

const mapDispatchToProps = dispatch => ({
  // addTabToNav(tab) {
  //   dispatch(addTabToNav(tab));
  // },
  // getReportSubscriptions(loading, user) {
  //   dispatch(getReportSubscriptions(loading, null, user));
  // },
  // deleteReportSubscription(rptSubId, user) {
  //   dispatch(deleteReportSubscription(rptSubId, null, user));
  // },
  // selectAllSubscriptions(selectAll) {
  //   dispatch(selectAllSubscriptions(selectAll));
  // }
});

export default connect(mapStateToProps, mapDispatchToProps)(NoAuthFileShare);
