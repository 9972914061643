import React, { Component } from "react";
import PromoCard from "./promoCard";
import { connect } from "react-redux";
import { Grid, Row } from "react-flexbox-grid";
//import moment from "moment";
import {
  createPromo,
  getPromoOptConfigData,
  setViewSelected,
  createPromoDefaults
} from "components/promoOpt/reducers/promoOptReducer";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import { DotLoader } from "react-spinners";

import Select from "react-select";

class PromoOptDetailCellRenderer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      promoPlans: props.data.promoPlans,
      showNewPromoDialog: false,
      selectedRadioOption: 1,
      liftName: "",
      errorMessage: "",
      quarters: [
        { id: 1, value: "Q1", label: "Q1" },
        { id: 2, value: "Q2", label: "Q2" },
        { id: 3, value: "Q3", label: "Q3" },
        { id: 4, value: "Q4", label: "Q4" }
      ],
      years: [
        { id: 1, value: "2020", label: "2020" },
        { id: 2, value: "2021", label: "2021" },
        { id: 3, value: "2022", label: "2022" },
        { id: 4, value: "2023", label: "2023" }
      ],
      selectedQtr: { id: 1, value: "Q1", label: "Q1" },
      selectedYear: { id: 4, value: "2020", label: "2020" },
      creatingProject: false
    };
    this.LiftName = React.createRef();
    this.PromoName = React.createRef();
    this.handleInputChange = this.handleInputChange.bind(this);

    this.createHandler = this.createHandler.bind(this);
  }

  handleInputChange() {
    this.setState({ liftName: this.LiftName.current.value });
  }

  createHandler() {
    //batchId,promoName,quarter,year
    if (this.PromoName.current.value !== "") {
      this.props.createPromoDefaults(
        this.props.data.id,
        this.PromoName.current.value,
        this.state.selectedQtr.value.split("Q")[1],
        this.state.selectedYear.value
      );
    }
    //this.props.getPromoOptConfigData('1', this.props.data.finalBaselineId);
    //this.props.setViewSelected(3);
  }
  handleQtrChange = selectedOption => {
    this.setState({
      selectedQtr: selectedOption
    });
  };
  handleChange = selectedOption => {
    this.setState({
      selectedYear: selectedOption
    });
  };
  handleClose = () => {
    this.setState({ showNewPromoDialog: false });
  };
  renderPromoPlans(promoPlans) {
    if (promoPlans !== undefined) {
      return promoPlans.map((item, idx) => {
        return (
          <PromoCard baselineId={this.props.data.id} promo={item} key={idx} />
        );
      });
    } else {
      return <div />;
    }
  }
  render() {
    const {
      promoPlans,
      showNewPromoDialog,
      quarters,
      years,
      selectedQtr,
      selectedYear
    } = this.state;
    let enableCreatePromo = false;
    if (this.PromoName.current !== null) {
      if (this.PromoName.current.value !== "") {
        enableCreatePromo = true;
      }
    }
    return (
      <div
        style={{
          display: "flex",
          height: "100%",
          flexDirection: "column",
          padding: "20px",
          background: "rgb(247,248,250)"
        }}
      >
        <Modal isOpen={showNewPromoDialog}>
          <ModalHeader>Create New Promo Plan</ModalHeader>
          <ModalBody>
            <div style={{ display: "flex", flexDirection: "column" }}>
              <div style={{ fontWeight: "600" }}>
                Promo Plan Name
                <div>
                  <input
                    style={{
                      marginLeft: "10px",
                      marginTop: "10px",
                      width: "95%",
                      height: "38px"
                    }}
                    defaultValue={""}
                    ref={this.PromoName}
                    onChange={() => {
                      this.setState({
                        promoName: this.PromoName.current.value
                      });
                    }}
                  ></input>
                </div>
              </div>
              <div style={{ fontWeight: "600", marginTop: "20px" }}>
                Quarter
              </div>
              <div style={{}}>
                <Select
                  className="begin-end-dropdown"
                  // classNamePrefix="react-select"
                  placeholder={"Actions"}
                  value={
                    selectedQtr //isClearable
                  }
                  onChange={this.handleQtrChange}
                  options={quarters}
                />
              </div>
              <div style={{ fontWeight: "600", marginTop: "20px" }}>Year</div>
              <div>
                <Select
                  className="begin-end-dropdown"
                  // classNamePrefix="react-select"
                  placeholder={"Actions"}
                  value={
                    selectedYear //isClearable
                  }
                  onChange={this.handleChange}
                  options={years}
                />
              </div>
            </div>
          </ModalBody>

          <ModalFooter>
            {!this.props.creatingDefaults ? (
              <Button
                color="primary"
                className={enableCreatePromo ? "" : "disabled"}
                onClick={() => {
                  this.createHandler("project");
                }}
              >
                Create
              </Button>
            ) : (
              <div
                className="accubase-uploading"
                style={{
                  marginLeft: "auto",

                  display: "flex",
                  flexDirection: "row"
                }}
              >
                <DotLoader
                  sizeUnit={"px"}
                  size={15}
                  color={"black"}
                  loading={true}
                />
                <div style={{ marginLeft: "5px" }}>Creating Promo Plan ...</div>
              </div>
            )}
            <Button onClick={this.handleClose}>Cancel</Button>
          </ModalFooter>
        </Modal>

        <div
          style={{
            display: "flex",
            flexDirection: "row",
            fontWeight: "bold",
            fontSize: "14px"
          }}
        >
          <div>Select an available Promo Plan to review</div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              width: "100%"
            }}
          >
            <div
              onClick={() => {
                console.log(this.props);
                this.setState({ showNewPromoDialog: true });
                // this.props.getPromoOptConfigData(
                //   this.props.data.finalBaselineId
                // );
                // this.props.setViewSelected(3);
              }}
              // className={
              //   this.props.data.status === 'complete' ? '' : 'disableLift'
              // }
              style={{
                marginLeft: "auto",
                cursor: "pointer",
                fontSize: "14px"
              }}
              className="btn btn-high-viz"
            >
              + Add New Promo Plan
            </div>
            {this.state.showNewLiftDialog && (
              <div style={{}}>
                <div className="add-new-lift-container">
                  {this.state.errorMessage !== "" && (
                    <div style={{ display: "flex", flexDirection: "row" }}>
                      <div style={{ fontSize: "11px" }}>
                        {this.state.errorMessage}
                      </div>
                      <div
                        style={{ display: "pointer", marginLeft: "10px" }}
                        onClick={() => {
                          this.setState({ errorMessage: "" });
                        }}
                      >
                        X
                      </div>
                    </div>
                  )}
                  <div>Lift Model Name</div>
                  <input
                    ref={this.promoName}
                    onChange={this.handleInputChange}
                    style={{ width: "100%", marginBottom: "5px" }}
                  />

                  <hr />
                  <div
                    style={{
                      justifyContent: "center",
                      paddingLeft: "15px",
                      display: "flex",
                      flexDirection: "row"
                    }}
                  >
                    <div
                      className="ta-button"
                      style={{
                        height: "25px",
                        width: "60px",
                        textAlign: "center",
                        marginRight: "20px",
                        paddingTop: "4px"
                      }}
                      onClick={() => {
                        this.createLift();
                      }}
                    >
                      Create
                    </div>
                    <div
                      style={{
                        height: "25px",
                        width: "60px",
                        textAlign: "center",
                        marginRight: "20px",
                        paddingTop: "4px"
                      }}
                      className="cancel-button"
                      onClick={() => {
                        this.setState({ showNewLiftDialog: false });
                      }}
                    >
                      Cancel
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
        <hr style={{ width: "100%", height: "5px" }} />
        <div
          className="full-width-panel"
          style={{
            display: "flex",
            flexDirection: "row",
            width: "100%"
          }}
        >
          <Grid fluid className="report-icon-list">
            <Row center="xl" style={{ width: "100%" }}>
              {this.renderPromoPlans(promoPlans)}
            </Row>
          </Grid>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  creatingDefaults: state.promo.creatingDefaults,
  lifts: state.acculift.lifts,
  projects: state.acculift.projects,
  searchProjects: state.acculift.searchProjects
});
const mapDispatchToProps = dispatch => ({
  createPromo(baselineId, versionName) {
    dispatch(createPromo(baselineId, versionName));
  },
  createPromoDefaults(batchId, promoName, quarter, year) {
    dispatch(createPromoDefaults(batchId, promoName, quarter, year));
  },
  getPromoOptConfigData(promoId, baselineId) {
    dispatch(getPromoOptConfigData(promoId, baselineId));
  },
  setViewSelected(view) {
    dispatch(setViewSelected(view));
  }
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PromoOptDetailCellRenderer);
