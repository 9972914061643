import React, { Component } from "react";

import { FolderIcon, ReportSvg, RightChevron, CloseIcon } from "lib/icons";
import { connect } from "react-redux";
import PowerBISubFolder from "./powerBISubFolder";
import { OverlayTrigger, Tooltip, Popover } from "react-bootstrap";
import moment from "moment";

class PowerBIFolder extends Component {
  constructor(props) {
    super(props);
    this.state = {
      collapsed: false
    };
    this.collapseHandler = this.collapseHandler.bind(this);
    this.renderCollapsible = this.renderCollapsible.bind(this);
    this.onClick = this.onClick.bind(this);
  }
  collapseHandler(e, rpt) {
    e.stopPropagation();
    e.preventDefault();
    this.setState(prevState => ({
      collapsed: !prevState.collapsed
    }));
  }
  renderCollapsible() {
    return (
      <div className="menu-list-collapse">
        <RightChevron
          transform={this.state.collapsed ? "" : "rotate(90,12,12)"}
          height={"20px"}
          // width={"24px"}
          color={"rgba(88, 89, 91, 1)"}
          viewBox={"-1 2 24 24"}
        />
      </div>
    );
    //}
  }
  onClick(event, report, workspace) {
    // let wIndex;
    // let rIndex;
    // this.props.workspaces.forEach((ws, idx) => {
    //   if (ws._id === report.workspace) {
    //     wIndex = idx;
    //   }
    // });
    this.props.clickReportHandler(event, workspace, report);
  }
  renderTableItem(report) {
    if (report.pbiCreatedDate === undefined || report.pbiCreatedDate === null) {
      return (
        <div style={{ display: "flex", flexDirection: "row" }}>
          <div style={{ paddingRight: "310px" }}>{moment(report.pbiModifiedDate).format("MM/DD/YY")}</div>
          <div>-</div>
        </div>
      );
    } else {
      return (
        <div style={{ display: "flex", flexDirection: "row" }}>
          <div style={{ paddingRight: "250px" }}>{moment(report.pbiModifiedDate).format("MM/DD/YY")}</div>
          <div>{moment(report.pbiCreatedDate).format("MM/DD/YY")}</div>
        </div>
      );
    }
  }
  renderChildren(nodes, leftCaret = false, dontShowDates) {
    const { selectedTab, tabs } = this.props;
    let rptPadding = 22;

    return nodes.map((node, idx) => {
      if (node.fileType === "folder") {
        return (
          <PowerBISubFolder
            clickReportHandler={this.props.clickReportHandler}
            key={idx}
            report={node}
            workspace={this.props.workspace}
            leftCaret={leftCaret}
          />
        );
      }

      if (leftCaret) {
        var active = false;
        if (selectedTab[2] !== undefined && selectedTab[2] !== -1 && selectedTab[1] !== -1) {
          if (
            tabs[selectedTab[0]].activeChildren[selectedTab[1]] !== undefined &&
            tabs[selectedTab[0]].activeChildren[selectedTab[1]].activeChildren[selectedTab[2]] !== undefined
          ) {
            if (node._id === undefined) {
              if (
                tabs[selectedTab[0]].activeChildren[selectedTab[1]].activeChildren[selectedTab[2]].id === node.id &&
                tabs[selectedTab[0]].activeChildren[selectedTab[1]].activeChildren[selectedTab[2]].workspaceId ===
                  node.workspaceId
              ) {
                active = true;
              }
            } else if (
              tabs[selectedTab[0]].activeChildren[selectedTab[1]].activeChildren[selectedTab[2]]._id === node.id &&
              tabs[selectedTab[0]].activeChildren[selectedTab[1]].activeChildren[selectedTab[2]].workspaceId ===
                node.workspaceId
            ) {
              active = true;
            }
          }
        }

        return (
          <OverlayTrigger
            key={idx + "-tip1"}
            placement={"top"}
            rootClose={true}
            trigger={["hover", "focus"]}
            overlay={
              <Popover id={idx + "-tip1"}>
                <Popover.Title as="h3">{node.workspaceName}</Popover.Title>
                <Popover.Content>
                  {node.name}
                  {node.description ? (
                    <div>
                      <hr></hr> {node.description}
                    </div>
                  ) : (
                    ""
                  )}
                  {node !== undefined && node.pbiModifiedDate ? (
                    <div style={{ display: "flex", flexDirectin: "row" }}>
                      <div style={{ paddingRight: "5px" }}>Modified:</div> {moment(node.pbiModifiedDate).format("MM/DD/YY")}
                    </div>
                  ) : null}
                  {node !== undefined && node.pbiCreatedDate ? (
                    <div style={{ display: "flex", flexDirectin: "row" }}>
                      <div style={{ paddingRight: "5px" }}>Created:</div> {moment(node.pbiCreatedDate).format("MM/DD/YY")}
                    </div>
                  ) : null}
                </Popover.Content>
              </Popover>
            }>
            <div
              onClick={event => {
                this.onClick(event, node, this.props.workspace);
              }}
              className={"ta-workspace-reports-span powerBIFolder"}
              key={"rpt" + idx}
              style={{
                marginLeft: `${rptPadding}px`,
                display: "flex",
                flexDirection: "row"
              }}>
              <ReportSvg
                height={"16px"}
                style={{ display: "flex", flexDirection: "row" }}
                className={"ta-pbi-icon"}
                color={"rgba(121, 121, 121, 1)"}
                viewBox={"0 -1 24 24"}
              />
              <div
                className={active ? "tab tab-selected" : "tab hey tab-unselected"}
                style={{
                  paddingLeft: "0px",
                  marginLeft: "1px",
                  marginTop: "-6px",
                  display: "flex",
                  flexDirection: "row",
                  width: "100%"
                }}>
                {node.name}
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    marginLeft: "auto",
                    marginRight: "65px"
                  }}>
                  {!dontShowDates && this.renderTableItem(node)}
                </div>
              </div>
              {this.props.selectedReports.includes(node.id) && (
                <OverlayTrigger
                  key={"closereport-tip"}
                  placement={"right"}
                  overlay={<Tooltip id={"closereport-tip"}>Close Report</Tooltip>}>
                  <div
                    className={this.props.selected ? "close-selected" : "close-unselected"}
                    style={{
                      display: "inline",
                      paddingLeft: "13px",
                      cursor: "pointer",
                      marginTop: "-6px"
                    }}
                    onClick={event => {
                      console.log(this.props);
                      event.stopPropagation();
                      event.preventDefault();
                      this.props.removeHandler(event, node);
                    }}>
                    <CloseIcon height={"12px"} viewBox={"0 0 20 20"} />
                  </div>
                </OverlayTrigger>
              )}
            </div>
          </OverlayTrigger>
        );
      }
      return (
        <div
          onClick={event => {
            this.onClick(event, node, this.props.workspace);
          }}
          key={"rpt" + idx}
          style={{ marginLeft: `${rptPadding}px` }}
          className="ta-report-name">
          <ReportSvg height={"16px"} className={"ta-pbi-icon"} color={"rgba(121, 121, 121, 1)"} viewBox={"0 -1 24 24"} />
          {node.name}
        </div>
      );
    });
  }
  render() {
    const { collapsed } = this.state;
    const { leftSideCaret, dontShowDates } = this.props;
    if (dontShowDates !== undefined && dontShowDates !== null && dontShowDates) {
      return (
        <div style={{ marginLeft: "-4px" }}>
          <div className="ta-folder-name-left-caret" onClick={e => this.collapseHandler(e, this.props.report)}>
            <div style={{ display: "flex", flexDirection: "row" }}>
              {this.renderCollapsible()}
              {this.props.report.name}
            </div>
          </div>
          {collapsed ? null : <div>{this.renderChildren(this.props.report.children, true, true)}</div>}
        </div>
      );
    }
    if (leftSideCaret !== undefined && leftSideCaret === true) {
      return (
        <div style={{ marginLeft: "-4px" }}>
          <div className="ta-folder-name-left-caret" onClick={e => this.collapseHandler(e, this.props.report)}>
            <div style={{ display: "flex", flexDirection: "row" }}>
              {this.renderCollapsible()}
              {this.props.report.name}
              {this.props.report.pbiModifiedDate}
            </div>
          </div>
          {collapsed ? null : <div>{this.renderChildren(this.props.report.children, true)}</div>}
        </div>
      );
    }
    return (
      <div>
        <div
          className="ta-folder-name"
          style={{ display: "flex", flexDirection: "column" }}
          onClick={() => this.collapseHandler(this.props.report)}>
          <div style={{ display: "flex", flexDirection: "row" }}>
            <FolderIcon height={"14px"} className={"ta-folder-icon"} color={"rgba(121, 121, 121, 1)"} />
            {this.props.report.name}
            {this.props.report.pbiModifiedDate}

            {this.renderCollapsible()}
          </div>
        </div>
        {/* {collapsed ? null : (
          <div>{this.renderChildren(this.props.report.children)}</div>
        )} */}
      </div>
    );
  }
}

const mapStateToProps = state => ({
  workspaces: state.reportList.workspaces,
  apps: state.app.apps,
  performCollapseAction: state.app.performCollapseAction,
  collapseFolders: state.app.collapseFolders,
  selectedTab: state.topLevelNav.selectedTab,
  tabs: state.topLevelNav.tabs
});
const mapDispatchToProps = dispatch => ({});

export default connect(mapStateToProps, mapDispatchToProps)(PowerBIFolder);
