import React, { Component } from "react";

import NumericEditor from "components/accucast/components/editors/numericEditor";
import TotalRenderer from "components/accucast/components/renderers/totalRenderer";
import { AccucastGrid } from "components/accucast/components/lib/accucastGrid";
import {
  numberFormatter,
  getRowClass
} from "components/accucast/components/formatters";
import { numberNewValueHandler } from "components/accucast/components/handlers";
import { CustomHeaderRenderer } from "components/accucast/components/renderers/customHeaderRenderer";
import { EditableCellRenderer } from "components/accucast/components/renderers/editableCellRenderer";
import axios from "axios";

export class PlanStoreDistribution extends Component {
  constructor(props) {
    super(props);

    this.state = {
      columnDefs: [],
      rowData: this.createRows(),
      frameworkComponents: {
        numericEditor: NumericEditor,
        totalRenderer: TotalRenderer,
        customHeaderRenderer: CustomHeaderRenderer,
        editableCellRenderer: EditableCellRenderer
      }
    };
  }

  componentDidMount() {
    var baseConfig = [
      {
        id: "0",
        headerName: "GEO",
        pinned: "left",
        width: 105,
        field: "geo",
        tooltipField: "geo",
        suppressMovable: true,
        menuTabs: ["filterMenuTab"]
      },
      {
        id: "1",
        headerName: "Item Description",
        pinned: "left",
        tooltipField: "idesc",
        width: 375,
        field: "idesc",
        suppressMovable: true,
        menuTabs: ["filterMenuTab"]
      },
      {
        id: "2",
        headerName: "Prod Key",
        pinned: "left",
        width: 136,
        field: "pk",
        tooltipField: "pk",
        suppressMovable: true,
        menuTabs: ["filterMenuTab"]
      },
      {
        id: "3",
        headerName: "Brand Size",
        pinned: "left",
        width: 138,
        field: "bs",
        tooltipField: "bs",
        suppressMovable: true,
        menuTabs: ["filterMenuTab"]
      },
      {
        id: "4",
        hide: true,
        headerName: "UPSW",
        pinned: "left",
        width: 80,
        field: "upsw",
        tooltipField: "upsw",
        suppressMovable: true,
        menuTabs: ["filterMenuTab"]
      }
    ];
    var defaultWkCellConf = {
      width: 95,
      suppressMovable: true,
      menuTabs: ["filterMenuTab"],
      cellEditor: "numericEditor",
      editable: true,
      valueFormatter: numberFormatter,
      newValueHandler: numberNewValueHandler,
      headerComponent: "customHeaderRenderer",
      headerClass: "headerSize",
      cellClass: "cellCenterClass",
      cellRenderer: "editableCellRenderer"
    };
    axios
      .get("http://localhost:4000/api/accucast/config/storeDistribution", {
        headers: {
          ClientToken: localStorage.getItem("clientToken")
        }
      })
      .then(res => {
        if (res.data.config.length !== 0) {
          let config = res.data.config.map(conf => {
            let id = parseInt(conf.id, 10);
            if (id === 0 || id === 1 || id === 2 || id === 3 || id === 4) {
              let pinConf = baseConfig[id];
              pinConf.cellClassRules = conf.cellClassRules;
              return pinConf;
            } else {
              conf = { ...conf, ...defaultWkCellConf };
              return conf;
            }
          });
          this.setState({ columnDefs: config });
        }
      });
  }

  createRows = () => {
    let rows = [];
    rows.push({
      upsw: "0.0",
      geo: "AHOLD",
      idesc: "89898-NS DIET TURBO CHOS PWD PKT 5CT 1.4OZB",
      pk: "123456789012",
      bs: "SALTY SNACK",
      wk25: "25",
      wk26: "25",
      wk27: "25",
      wk28: "25",
      wk29: "25"
    });
    rows.push({
      upsw: "0.0",
      geo: "AMAZON",
      idesc: "84220-SOUTH BEACH DOUBLE CHOC BAR 30CT",
      pk: "3267484220",
      bs: "BAR",
      wk25: "25",
      wk26: "25",
      wk27: "25",
      wk28: "25",
      wk29: "25"
    });
    rows.push({
      upsw: "0.0",
      geo: "AMAZON",
      idesc: "84221-SOUTH BEACH PEANUT BUTTER BAR 30CT",
      pk: "3267484221",
      bs: "BAR",
      wk25: "25",
      wk26: "25",
      wk27: "25",
      wk28: "25",
      wk29: "25"
    });
    rows.push({
      upsw: "0.0",
      geo: "C&S",
      idesc: "84192-NS DIET KIT EVERYDAY 1CT",
      pk: "3267484192",
      bs: "KIT",
      wk25: "25",
      wk26: "25",
      wk27: "25",
      wk28: "25",
      wk29: "25"
    });
    rows.push({
      upsw: "0.0",
      geo: "DIV1",
      idesc: "83504-NS DIAB VANIL LIQ 4CT 11.0OZB",
      pk: "3267483504",
      bs: "SHAKE",
      wk25: "25",
      wk26: "25",
      wk27: "25",
      wk28: "25",
      wk29: "25"
    });

    return rows;
  };

  render() {
    return (
      <div style={{ height: "100%" }}>
        Store Distributions
        <AccucastGrid
          columnDefs={this.state.columnDefs}
          rowData={this.state.rowData}
          getRowClass={getRowClass}
          frameworkComponents={this.state.frameworkComponents}
        />
      </div>
    );
  }
}
